import React from 'react'

const Yoga = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`toaster`}</title>
    <path d="M12 2a1.999 1.999 0 100 4 1.999 1.999 0 100-4zm-1.188 5c-1.214 0-2.265.754-2.718 1.813l-.063.124-.687 2.688c-.086.305-.375.594-.782.719-.007.004-.023-.004-.03 0l-2.782.687.5 1.938 2.781-.688h.032v-.031c.976-.281 1.875-.977 2.187-2.063v-.03h.031l.219-.876V14l-1.5.406 2.375 1.75-3.063-1.062c-.5-.2-1.113.094-1.312.594-.2.5.094 1.113.594 1.312l2.343.781-.937.313L8.688 20l8.624-3c.5-.2.793-.71.594-1.313-.199-.5-.71-.792-1.312-.593l-2.969 1.031L16 14.406 14.5 14v-2.719l.219.875h.031v.031c.27.95 1.04 1.868 2.219 2.063v.031l2.781.688.5-1.938-2.813-.687-.062-.031h-.063c-.355-.043-.535-.254-.656-.657v-.031l-.687-2.688-.063-.124C15.453 7.752 14.402 7 13.187 7zM16 18.094l-3.094 1.093 2.406.813zM4 21v1h16v-1z" />
  </svg>
)

export default Yoga
