import React from 'react'

const Speaker = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`speaker`}</title>
    <path d="M12 12c1.641 0 3 1.359 3 3s-1.359 3-3 3-3-1.359-3-3 1.359-3 3-3zm0 8.016c2.766 0 5.016-2.25 5.016-5.016S14.766 9.984 12 9.984 6.984 12.234 6.984 15s2.25 5.016 5.016 5.016zm0-16.032c-1.125 0-2.016.938-2.016 2.016S10.875 8.016 12 8.016c1.078 0 2.016-.938 2.016-2.016S13.078 3.984 12 3.984zm5.016-1.968c1.078 0 1.969.891 1.969 1.969v16.031a1.981 1.981 0 01-1.969 1.969H6.985a1.981 1.981 0 01-1.969-1.969V3.985c0-1.078.891-1.969 1.969-1.969h10.031z" />
  </svg>
)

export default Speaker
