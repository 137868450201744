import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, intlShape } from 'react-intl'
import CopyToClipboard from 'react-copy-to-clipboard'
import { addToast } from 'redux/modules/toast'
import { CardText, FontIcon, List, ListItem } from 'react-md'
import AsHtml from 'components/Translator/AsHtml'
import injectTranslator from 'components/Translator/injectTranslator'
import currentUser from 'utils/CurrentUser'
// import { trackEvent } from 'utils/Segment';
import { wifiShape } from 'constants/SharedPropTypes'
import { wifiDefaults } from 'constants/SharedPropDefaults'
import { securityMessages } from 'constants/UIConstants'

const propTypes = {
  wifi: wifiShape.isRequired,
  isSecure: PropTypes.bool,
  intl: intlShape.isRequired,
  nested: PropTypes.bool
}

const defaultProps = {
  wifi: wifiDefaults,
  isSecure: false,
  nested: true
}

class NetworkDetails extends PureComponent {
  formatMessage = this.props.intl.formatMessage

  messages = defineMessages({
    dontProvideBut: {
      id: 'gb.dontProvideBut',
      defaultMessage:
        "We don't provide a wifi network but you can attempt to join a nearby free network."
    },
    selectNetwork: {
      id: 'gb.selectNetwork',
      defaultMessage:
        'To attempt to gain access to this free network, select the {networkName} network on your device.',
      values: { networkName: this.props.wifi.networkName }
    },
    cantHelpWithNetwork: {
      id: 'gb.cantHelpWithNetwork',
      defaultMessage:
        "Unfortunately we can't help with any issues you may have with this network."
    },
    passwordCopiedToClipboard: {
      id: 'gb.CopiedToClipboard',
      defaultMessage: 'The Wifi password has been copied to the clipboard.'
    },
    networkDetails: {
      id: 'gb.NetworkDetails',
      defaultMessage: 'Network Name'
    },
    passwordText: {
      id: 'gb.PasswordText',
      defaultMessage: 'Password'
    }
  })

  trackCopy = () => {
    // OLD SEGMENT CODE
    // let eventData = {
    //   category: 'Guidebook'
    // }
    // trackEvent('wifiPasswordCopied', eventData);
    this.props.dispatch(
      addToast(this.formatMessage(this.messages.passwordCopiedToClipboard))
    )
  }

  renderWifiDetails = () => {
    const { isSecure, wifi } = this.props
    const user = currentUser()
    const canAccessWifi =
      (wifi.wifi_secure && isSecure) ||
      !wifi.wifi_secure ||
      (this.props.viewMode === 'print' && user.isAdmin)
    const network_name = canAccessWifi
      ? wifi.network_name
      : this.formatMessage(securityMessages.noAccess)
    const wifi_password = canAccessWifi
      ? wifi.wifi_password
      : this.formatMessage(securityMessages.noAccess)
    if (wifi.login_provided_online) {
      if (this.props.viewMode === 'print') {
        return (
          <div className="md-grid md-grid--no-spacing">
            <div className="md-cell--3">
              <p>{this.formatMessage(this.messages.networkDetails)}</p>
            </div>
            <div className="md-cell--9">
              <p className="hf-print-wifi">{network_name}</p>
            </div>
            <div className="md-cell--3">
              <p>{this.formatMessage(this.messages.passwordText)}</p>
            </div>
            <div className="md-cell--9">
              <p className="hf-print-wifi">{wifi_password}</p>
            </div>
          </div>
        )
      } else {
        return (
          <div className="md-grid">
            <div className="md-cell md-cell--8 md-cell--8-tablet md-cell--4-phone">
              <ListItem
                className="no-click"
                leftIcon={<FontIcon>wifi</FontIcon>}
                primaryText={this.formatMessage(this.messages.networkDetails)}
                secondaryText={network_name}
              />
              <ListItem
                className="no-click"
                leftIcon={<FontIcon>star</FontIcon>}
                primaryText={this.formatMessage(this.messages.passwordText)}
                secondaryText={wifi_password}
                rightIcon={
                  <CopyToClipboard
                    className="show-pointer"
                    text={wifi.wifi_password}
                    onCopy={() => {
                      this.trackCopy()
                    }}
                  >
                    <FontIcon>content_copy</FontIcon>
                  </CopyToClipboard>
                }
              />
            </div>
          </div>
        )
      }
    }
    return null
  }

  renderWifiOffline = () => {
    const wifi = this.props.wifi
    if (wifi.login_provided_offline) {
      return (
        <CardText>
          <AsHtml
            text={wifi.login_offline_text}
            translations={wifi.login_offline_text_txn}
          />
        </CardText>
      )
    }
    return null
  }

  renderNoWifi = () => {
    const wifi = this.props.wifi
    if (!wifi.wifi_provided && wifi.use_external_network) {
      return (
        <List>
          <ListItem
            className="no-click"
            primaryText=""
            secondaryText={this.formatMessage(this.messages.dontProvideBut)}
          />
          <ListItem
            className="no-click"
            primaryText=""
            secondaryText={this.formatMessage(this.messages.selectNetwork, {
              networkName: wifi.external_network_name
            })}
          />
          <ListItem
            className="no-click"
            primaryText=""
            secondaryText={this.formatMessage(
              this.messages.cantHelpWithNetwork
            )}
          />
        </List>
      )
    }
    return null
  }

  render() {
    return (
      <div>
        {this.renderWifiDetails()}
        {this.renderWifiOffline()}
        {this.renderNoWifi()}
      </div>
    )
  }
}

NetworkDetails.propTypes = propTypes
NetworkDetails.defaultProps = defaultProps

export default injectTranslator(NetworkDetails)
