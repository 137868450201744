import { getToken } from 'utils/Auth'

import 'whatwg-fetch'

export function generateRecs(guidebook_id) {
  const path = '/api/v1/recommendations/generate_recommendations'
  const method = 'POST'
  const url = process.env.REACT_APP_API_URL + path
  const body = {}
  body['guidebook_id'] = guidebook_id
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
}

export function checkStatus(guidebook_id) {
  const path = '/api/v1/recommendations/check_generation_status/' + guidebook_id
  const method = 'GET'
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
}
