import React from 'react'

const Work = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`work`}</title>
    <path d="M14.016 6V3.984H9.985V6h4.031zm6 0c1.125 0 1.969.891 1.969 2.016v10.969c0 1.125-.844 2.016-1.969 2.016H3.985c-1.125 0-1.969-.891-1.969-2.016V8.016C2.016 6.891 2.86 6 3.985 6h4.031V3.984c0-1.125.844-1.969 1.969-1.969h4.031c1.125 0 1.969.844 1.969 1.969V6h4.031z" />
  </svg>
)

export default Work
