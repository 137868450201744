import React from 'react'

const Class = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`class`}</title>
    <path d="M6 3.984V12l2.484-1.5 2.531 1.5V3.984H5.999zm12-1.968c1.078 0 2.016.891 2.016 1.969v16.031c0 1.078-.938 1.969-2.016 1.969H6c-1.078 0-2.016-.891-2.016-1.969V3.985c0-1.078.938-1.969 2.016-1.969h12z" />
  </svg>
)

export default Class
