import React from 'react'

const Motorcycle = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`motorcycle`}</title>
    <path d="M18.984 17.016c1.641 0 3-1.359 3-3s-1.359-3-3-3-3 1.359-3 3 1.359 3 3 3zM7.828 15H5.015v-2.016h2.813a2.992 2.992 0 00-2.813-1.969c-1.641 0-3 1.359-3 3s1.359 3 3 3c1.266 0 2.391-.844 2.813-2.016zm11.625-5.953C22.031 9.235 24 11.344 24 14.016c0 2.813-2.203 4.969-5.016 4.969s-4.969-2.156-4.969-4.969c0-.609.094-1.219.281-1.781l-2.766 2.766H9.889c-.469 2.297-2.438 3.984-4.875 3.984-2.813 0-5.016-2.156-5.016-4.969S2.201 9 5.014 9h11.578l-2.016-2.016h-3.563V5.015h4.406z" />
  </svg>
)

export default Motorcycle
