import React from 'react'

const Accessible = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`accessible`}</title>
    <path d="M12.844 18h2.063c-.469 2.297-2.484 3.984-4.922 3.984a4.941 4.941 0 01-4.969-4.969c0-2.438 1.688-4.453 3.984-4.922v2.063a3.05 3.05 0 00-2.016 2.859c0 1.641 1.359 3 3 3a3.047 3.047 0 002.859-2.016zm-2.86-8.906c0-1.406 1.564-2.649 3-1.828h.047v.047c.234.094.422.234.609.422l1.313 1.453c.984 1.078 2.484 1.828 4.031 1.828v1.969c-1.734 0-3.656-.844-4.969-1.922v3.422h3c1.078 0 1.969.938 1.969 2.016v5.484h-1.969v-4.969h-5.016c-1.078 0-2.016-.938-2.016-2.016V9.094zm0-5.11c0-1.125.891-1.969 2.016-1.969s2.016.844 2.016 1.969S13.125 6 12 6s-2.016-.891-2.016-2.016z" />
  </svg>
)

export default Accessible
