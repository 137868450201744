import React, { Component } from 'react'
import PropTypes from 'prop-types'
import logo from 'components/Header/logo.png'
import PrintReadyImage from 'components/Print/PrintReadyImage'

class Logo extends Component {
  render() {
    const { theme } = this.props
    const logoImg = theme && theme.logo ? theme.logo : logo
    const logoAlt =
      theme && theme.logo ? theme.name + ' logo' : 'Hostfully logo'

    return (
      <PrintReadyImage
        imageId="theme-logo"
        imageUrl={logoImg}
        imageAlt={logoAlt}
        className={this.props.className}
      />
    )
  }
}

Logo.propTypes = {
  theme: PropTypes.object,
  className: PropTypes.string
}

Logo.defaultProps = {
  theme: {},
  className: ''
}

export default Logo
