import React from 'react'

const Bbq = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`bbq`}</title>
    <path d="M10.1 11.2h-.3l.6-.7c.3-.4.6-.9.4-1.3-.1-.4-.4-.6-.9-.9l-.2-.4c-.2-.3.1-.6.3-.6l.6-.6h.1l-.6.6v.3h.1c.4.3.9.6 1 1 .1.4-.1 1.2-.6 1.6l-.5 1zM13.1 11H13l.6-.7c.3-.4.6-.9.4-1.3-.1-.4-.4-.6-.9-.9l-.3-.3c-.1-.3.1-.6.3-.6l.6-.6h.1l-.6.6v.3h.1c.4.3.9.6 1 1 .1.4-.1 1.2-.6 1.6l-.6.9zM16 11h-.3l.6-.7c.3-.4.6-.9.4-1.3s-.4-.7-.9-1l-.3-.3c-.1-.3.1-.6.3-.6l.6-.6h.1l-.5.6v.3h.1c.4.3.9.6 1 1s-.1 1.2-.6 1.6l-.5 1z" />
    <path d="M21.1 20.5l.4-.4-4.5-4.6c1-1 1.6-2.4 1.6-3.9H7.9V1c-3 0-5.4 2.4-5.4 5.4s2.4 5.4 5.4 5.4c0 1.5.7 2.8 1.6 3.7L4.9 20l.4.4L9.8 16c.8.7 1.8 1.1 3 1.2V23h.6v-5.8c1.2-.1 2.3-.5 3.2-1.2l4.5 4.5z" />
  </svg>
)

export default Bbq
