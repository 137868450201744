import React from 'react'

const Bunks = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`bunks`}</title>
    <path d="M2.5 4.3c-.2 0-.3.1-.3.3v14.7c-.1.1-.1.1-.1.2 0 .2.1.3.3.3s.3-.1.3-.3c0-.1 0-.2-.1-.2V4.7c.1-.2 0-.3-.1-.4zM21.5 4.3c-.2 0-.3.1-.3.3V6c0-.3-.2-.6-.5-.6h-2.8c-.3.1-.5.3-.5.6v.3c0 .3.2.6.5.6H2.7v3.3h1.5V15H2.7v3.3h18.7v1c-.1.1-.1.1-.1.2 0 .2.1.3.3.3s.3-.1.3-.3c0-.1 0-.2-.1-.2V4.7c0-.2-.1-.4-.3-.4zm-.3 2V7h-.5c.4 0 .7-.3.7-.7h-.2zm-16.7 6h1.7v.7H4.5v-.7zm1.7-.3H4.5v-.7h1.7v.7zm-1.7 1.3h1.7v.5H4.5v-.5zm1.7-3v.7H4.5v-.7h1.7zm-1.7 4h1.7v.7H4.5v-.7zm13.3.7h2.8c.3-.1.5-.3.5-.6v.6H6.5v-4.7h14.8V14c0-.3-.2-.6-.5-.6h-3c-.3.1-.5.3-.5.6v.3c0 .4.2.6.5.7z" />
  </svg>
)

export default Bunks
