import React, { PureComponent } from 'react'
import { Card, Media } from 'react-md'

class Blurb extends PureComponent {
  handleClick = (e) => {
    const blurbUrl = 'https://www.blurb.com/partner/hostfully'
    window.open(blurbUrl, '_blank')
  }

  render() {
    const imageUrl = '/images/blurb-icon.png'
    return (
      <div className="blurb-advert">
        <h6 className="hf-control-padding">High Quality Printing with Blurb</h6>
        <Card raise={true}>
          <div className="md-grid " onClick={(e) => this.handleClick(e)}>
            <div className="md-cell md-cell--3">
              <Media aspectRatio="1-1">
                <img src={imageUrl} alt="" />
              </Media>
            </div>
            <div className="md-cell md-cell--9">
              Create a professional-looking hard copy through our partnership
              with Blurb. Choose from multiple magazine-type formats to provide
              a premium in-destination print version to your guests.
            </div>
          </div>
        </Card>
      </div>
    )
  }
}

export default Blurb
