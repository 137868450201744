import Accessible from 'components/InlineSvg/Accessible'
import Activity from 'components/InlineSvg/Activity'
import AcUnit from 'components/InlineSvg/AcUnit'
import AccessAlarms from 'components/InlineSvg/AccessAlarms'
import AidKit from 'components/InlineSvg/AidKit'
import AirportShuttle from 'components/InlineSvg/AirportShuttle'
import Ambulance from 'components/InlineSvg/Ambulance'
import Anchor from 'components/InlineSvg/Anchor'
import Ants from 'components/InlineSvg/Ants'
import Apps from 'components/InlineSvg/Apps'
import Assignment from 'components/InlineSvg/Assignment'
import Audiotrack from 'components/InlineSvg/Audiotrack'
import BabyNeeds from 'components/InlineSvg/BabyNeeds'
import Balloons from 'components/InlineSvg/Balloons'
import Bathroom from 'components/InlineSvg/Bathroom'
import Bbq from 'components/InlineSvg/Bbq'
import BeachAccess from 'components/InlineSvg/BeachAccess'
import BeachChair from 'components/InlineSvg/BeachChair'
import BeachTree from 'components/InlineSvg/BeachTree'
import Bedroom from 'components/InlineSvg/Bedroom'
import Blinds from 'components/InlineSvg/Blinds'
import Book from 'components/InlineSvg/Book'
import Brightness1 from 'components/InlineSvg/Brightness1'
import Brightness3 from 'components/InlineSvg/Brightness3'
import BrightnessHigh from 'components/InlineSvg/BrightnessHigh'
import BugReport from 'components/InlineSvg/BugReport'
import Build from 'components/InlineSvg/Build'
import Bunks from 'components/InlineSvg/Bunks'
import BusinessCenter from 'components/InlineSvg/BusinessCenter'
import Camping from 'components/InlineSvg/Camping'
import Canoe from 'components/InlineSvg/Canoe'
import CardTravel from 'components/InlineSvg/CardTravel'
import Carport from 'components/InlineSvg/Carport'
import Cat from 'components/InlineSvg/Cat'
import Chat from 'components/InlineSvg/Chat'
import CheckBox from 'components/InlineSvg/CheckBox'
import ChildCare from 'components/InlineSvg/ChildCare'
import ChildFriendly from 'components/InlineSvg/ChildFriendly'
import ChromeReaderMode from 'components/InlineSvg/ChromeReaderMode'
import Class from 'components/InlineSvg/Class'
import Cleaning from 'components/InlineSvg/Cleaning'
import CleaningUtensils from 'components/InlineSvg/CleaningUtensils'
import CloudQueue from 'components/InlineSvg/CloudQueue'
import CloudSunLightning from 'components/InlineSvg/CloudSunLightning'
import Clubhouse from 'components/InlineSvg/Clubhouse'
import Compost from 'components/InlineSvg/Compost'
import ContentPaste from 'components/InlineSvg/ContentPaste'
import Cot from 'components/InlineSvg/Cot'
import Courtyard from 'components/InlineSvg/Courtyard'
import Deck from 'components/InlineSvg/Deck'
import Delete from 'components/InlineSvg/Delete'
import Dining from 'components/InlineSvg/Dining'
import DirectionsBike from 'components/InlineSvg/DirectionsBike'
import DirectionsBoat from 'components/InlineSvg/DirectionsBoat'
import DirectionsBus from 'components/InlineSvg/DirectionsBus'
import DirectionsCar from 'components/InlineSvg/DirectionsCar'
import DirectionsRailway from 'components/InlineSvg/DirectionsRailway'
import DirectionsRun from 'components/InlineSvg/DirectionsRun'
import DirectionsTransit from 'components/InlineSvg/DirectionsTransit'
import DisabledAccess from 'components/InlineSvg/DisabledAccess'
import Dishwasher from 'components/InlineSvg/Dishwasher'
import Disney from 'components/InlineSvg/Disney'
import Dog from 'components/InlineSvg/Dog'
import EditLocation from 'components/InlineSvg/EditLocation'
import EventSeat from 'components/InlineSvg/EventSeat'
import EvStation from 'components/InlineSvg/EvStation'
import Extension2 from 'components/InlineSvg/Extension2'
import Face from 'components/InlineSvg/Face'
import Favorite from 'components/InlineSvg/Favorite'
import FavoriteBorder from 'components/InlineSvg/FavoriteBorder'
import FerrisWheel from 'components/InlineSvg/FerrisWheel'
import FestivalTent from 'components/InlineSvg/FestivalTent'
import FilterVintage from 'components/InlineSvg/FilterVintage'
import FireExtinguisher from 'components/InlineSvg/Fireextinguisher'
import Firepit from 'components/InlineSvg/Firepit'
import Fireplace from 'components/InlineSvg/Fireplace'
import FishFood from 'components/InlineSvg/FishFood'
import FishHook from 'components/InlineSvg/FishHook'
import FishingBobber from 'components/InlineSvg/FishingBobber'
import Flag from 'components/InlineSvg/Flag'
import Flight from 'components/InlineSvg/Flight'
import FreeBreakfast from 'components/InlineSvg/FreeBreakfast'
import Fridge from 'components/InlineSvg/Fridge'
import FrontDesk from 'components/InlineSvg/FrontDesk'
import Garage from 'components/InlineSvg/Garage'
import Gate from 'components/InlineSvg/Gate'
import Gift from 'components/InlineSvg/Gift'
import Goat from 'components/InlineSvg/Goat'
import GolfCourse from 'components/InlineSvg/GolfCourse'
import Gondola from 'components/InlineSvg/Gondola'
import Grapes from 'components/InlineSvg/Grapes'
import Hairdryer from 'components/InlineSvg/Hairdryer'
import Headset from 'components/InlineSvg/Headset'
import Healing from 'components/InlineSvg/Healing'
import Health from 'components/InlineSvg/Health'
import Hearing from 'components/InlineSvg/Hearing'
import Help from 'components/InlineSvg/Help'
import Highlight from 'components/InlineSvg/Highlight'
import Hiking from 'components/InlineSvg/Hiking'
import Hiking2 from 'components/InlineSvg/Hiking2'
import Home from 'components/InlineSvg/Home'
import Horseback from 'components/InlineSvg/Horseback'
import Horseshoe from 'components/InlineSvg/Horseshoe'
import HotTub from 'components/InlineSvg/HotTub'
import Info from 'components/InlineSvg/Info'
import Iron from 'components/InlineSvg/Iron'
import Kayak from 'components/InlineSvg/Kayak'
import Keyboard from 'components/InlineSvg/Keyboard'
import Kitchen from 'components/InlineSvg/Kitchen'
import Kitchenwares from 'components/InlineSvg/Kitchenwares'
import LaptopMac from 'components/InlineSvg/LaptopMac'
import Library from 'components/InlineSvg/Library'
import LibraryBooks from 'components/InlineSvg/LibraryBooks'
import LifeJacket from 'components/InlineSvg/Lifejacket'
import LightbulbOutline from 'components/InlineSvg/LightbulbOutline'
import LiveTv from 'components/InlineSvg/LiveTv'
import LocalBar from 'components/InlineSvg/LocalBar'
import LocalDrink from 'components/InlineSvg/LocalDrink'
import LocalFlorist from 'components/InlineSvg/LocalFlorist'
import LocalGasStation from 'components/InlineSvg/LocalGasStation'
import LocalHotel from 'components/InlineSvg/LocalHotel'
import LocalLaundryService from 'components/InlineSvg/LocalLaundryService'
import LocalLibrary from 'components/InlineSvg/LocalLibrary'
import LocalParking from 'components/InlineSvg/LocalParking'
import LocalPizza from 'components/InlineSvg/LocalPizza'
import Lock from 'components/InlineSvg/Lock'
import Loyalty from 'components/InlineSvg/Loyalty'
import MailOutline from 'components/InlineSvg/MailOutline'
import Map from 'components/InlineSvg/Map'
import Mickey from 'components/InlineSvg/Mickey'
import Microwave from 'components/InlineSvg/Microwave'
import MonetizationOn from 'components/InlineSvg/MonetizationOn'
import Mosquito from 'components/InlineSvg/Mosquito'
import Motorcycle from 'components/InlineSvg/Motorcycle'
import MovieCreation from 'components/InlineSvg/MovieCreation'
import MusicFestival from 'components/InlineSvg/MusicFestival'
import Nature from 'components/InlineSvg/Nature'
import NaturePeople from 'components/InlineSvg/NaturePeople'
import Needle from 'components/InlineSvg/Needle'
import Note from 'components/InlineSvg/Note'
import Notifications from 'components/InlineSvg/Notifications'
import Oven from 'components/InlineSvg/Oven'
import PaddleBoard from 'components/InlineSvg/Paddleboard'
import PanTool from 'components/InlineSvg/PanTool'
import People from 'components/InlineSvg/People'
import Pets from 'components/InlineSvg/Pets'
import Phone from 'components/InlineSvg/Phone'
import PhoneIphone from 'components/InlineSvg/PhoneIphone'
import PhotoCamera from 'components/InlineSvg/PhotoCamera'
import Picnic from 'components/InlineSvg/Picnic'
import PizzaOven from 'components/InlineSvg/Pizzaoven'
import PlaylistAddCheck from 'components/InlineSvg/PlaylistAddCheck'
import Pool from 'components/InlineSvg/Pool'
import PowerCord from 'components/InlineSvg/PowerCord'
import Power from 'components/InlineSvg/Power'
import Public from 'components/InlineSvg/Public'
import Printer from 'components/InlineSvg/Printer'
import Recycle from 'components/InlineSvg/Recycle'
import Refresh from 'components/InlineSvg/Refresh'
import Restaurant from 'components/InlineSvg/Restaurant'
import RestaurantMenu from 'components/InlineSvg/RestaurantMenu'
import RollerCoaster from 'components/InlineSvg/RollerCoaster'
import RoomService from 'components/InlineSvg/RoomService'
import Router from 'components/InlineSvg/Router'
import Rowing from 'components/InlineSvg/Rowing'
import Sailing from 'components/InlineSvg/Sailing'
import Scooter from 'components/InlineSvg/Scooter'
import Security from 'components/InlineSvg/Security'
import Settings from 'components/InlineSvg/Settings'
import SettingsRemote from 'components/InlineSvg/SettingsRemote'
import Share from 'components/InlineSvg/Share'
import ShoePolish from 'components/InlineSvg/ShoePolish'
import ShoppingBasket from 'components/InlineSvg/ShoppingBasket'
import ShoppingCart from 'components/InlineSvg/ShoppingCart'
import SimCardAlert from 'components/InlineSvg/SimCardAlert'
import Skiing from 'components/InlineSvg/Skiing'
import SmartHome from 'components/InlineSvg/SmartHome'
import SmokeFree from 'components/InlineSvg/SmokeFree'
import SmokingRooms from 'components/InlineSvg/SmokingRooms'
import Spa from 'components/InlineSvg/Spa'
import Speaker from 'components/InlineSvg/Speaker'
import SpeakerNotes from 'components/InlineSvg/SpeakerNotes'
import Star from 'components/InlineSvg/Star'
import StoreMallDirectory from 'components/InlineSvg/StoreMallDirectory'
import Surfing from 'components/InlineSvg/Surfing'
import SurroundSound from 'components/InlineSvg/SurroundSound'
import Target from 'components/InlineSvg/Target'
import Terrain from 'components/InlineSvg/Terrain'
import Thermostat from 'components/InlineSvg/Thermostat'
import ThumbUp from 'components/InlineSvg/ThumbUp'
import Toaster from 'components/InlineSvg/Toaster'
import Toilet from 'components/InlineSvg/Toilet'
import TouchApp from 'components/InlineSvg/TouchApp'
import Towels from 'components/InlineSvg/Towels'
import Toys from 'components/InlineSvg/Toys'
import Treadmill from 'components/InlineSvg/Treadmill'
import Tv from 'components/InlineSvg/Tv'
import Usb from 'components/InlineSvg/Usb'
import Videocam from 'components/InlineSvg/Videocam'
import VideogameAsset from 'components/InlineSvg/VideogameAsset'
import VirtualAssistant from 'components/InlineSvg/VirtualAssistant'
import VolumeUp from 'components/InlineSvg/VolumeUp'
import VpnKey from 'components/InlineSvg/VpnKey'
import Warning from 'components/InlineSvg/Warning'
import Weekend from 'components/InlineSvg/Weekend'
import Weight from 'components/InlineSvg/Weight'
import Whatshot from 'components/InlineSvg/Whatshot'
import Wheelchair from 'components/InlineSvg/Wheelchair'
import Wifi from 'components/InlineSvg/Wifi'
import Wildlife from 'components/InlineSvg/Wildlife'
import Wine from 'components/InlineSvg/Wine'
import Work from 'components/InlineSvg/Work'
import Yoga from 'components/InlineSvg/Yoga'

export const allIcons = [
  {
    field: 'Accessible',
    tags: 'accessible access  ',
    v1_name: '',
    icon: Accessible
  },
  { field: 'Activity', tags: 'activity ticket', v1_name: '', icon: Activity },
  {
    field: 'AcUnit',
    tags: 'airconditioning snow cold temperature',
    v1_name: '',
    icon: AcUnit
  },
  {
    field: 'AccessAlarms',
    tags: 'reminder wake time checkout',
    v1_name: '',
    icon: AccessAlarms
  },
  {
    field: 'AidKit',
    tags: 'aid-kit health medicine medical',
    v1_name: 'hf-medical',
    icon: AidKit
  },
  {
    field: 'AirportShuttle',
    tags: 'airport_shuttle airport bus shuttle',
    v1_name: '',
    icon: AirportShuttle
  },
  {
    field: 'Ambulance',
    tags: 'ambulance fire police help',
    v1_name: 'hf-ambulance',
    icon: Ambulance
  },
  {
    field: 'Anchor',
    tags: 'marina harbour boat sailing',
    v1_name: '',
    icon: Anchor
  },
  { field: 'Ants', tags: 'insects ant  ', v1_name: '', icon: Ants },
  { field: 'Apps', tags: 'more information  ', v1_name: '', icon: Apps },
  {
    field: 'Assignment',
    tags: 'assignment rules information manuals',
    v1_name: 'hf-notes',
    icon: Assignment
  },
  {
    field: 'Audiotrack',
    tags: 'music sound system ',
    v1_name: '',
    icon: Audiotrack
  },
  {
    field: 'BabyNeeds',
    tags: 'baby toddlers cot crib',
    v1_name: '',
    icon: BabyNeeds
  },
  {
    field: 'Balloons',
    tags: 'balloon balloons party festival',
    v1_name: '',
    icon: Balloons
  },
  {
    field: 'Bathroom',
    tags: 'shower bath water ',
    v1_name: '',
    icon: Bathroom
  },
  {
    field: 'Bbq',
    tags: 'griller Barbecue weber ',
    v1_name: 'hf-bbq',
    icon: Bbq
  },
  {
    field: 'BeachAccess',
    tags: 'beach_access beach umbrella ',
    v1_name: '',
    icon: BeachAccess
  },
  { field: 'BeachChair', tags: 'beach chair', v1_name: '', icon: BeachChair },
  { field: 'BeachTree', tags: 'beach palm tree', v1_name: '', icon: BeachTree },
  {
    field: 'Bedroom',
    tags: 'bed double queen king',
    v1_name: '',
    icon: Bedroom
  },
  {
    field: 'Blinds',
    tags: 'blinds window covers sunlight',
    v1_name: '',
    icon: Blinds
  },
  { field: 'Book', tags: 'book reading library', v1_name: '', icon: Book },
  {
    field: 'Brightness1',
    tags: 'full moon after dark circle',
    v1_name: '',
    icon: Brightness1
  },
  {
    field: 'Brightness3',
    tags: 'after dark moon crescent',
    v1_name: '',
    icon: Brightness3
  },
  {
    field: 'BrightnessHigh',
    tags: 'solar sun light bright',
    v1_name: '',
    icon: BrightnessHigh
  },
  {
    field: 'BugReport',
    tags: 'roaches bugs insects pests',
    v1_name: '',
    icon: BugReport
  },
  { field: 'Build', tags: 'tools fix maintenance ', v1_name: '', icon: Build },
  { field: 'Bunks', tags: 'bed bunk bedroom kids', v1_name: '', icon: Bunks },
  {
    field: 'BusinessCenter',
    tags: 'business work  ',
    v1_name: '',
    icon: BusinessCenter
  },
  { field: 'Camping', tags: 'tent camping camp', v1_name: '', icon: Camping },
  { field: 'Canoe', tags: 'paddle ski kayak water', v1_name: '', icon: Canoe },
  {
    field: 'CardTravel',
    tags: 'card_travel card brief case ',
    v1_name: '',
    icon: CardTravel
  },
  {
    field: 'Carport',
    tags: 'parking garage car ',
    v1_name: 'hf-carport',
    icon: Carport
  },
  { field: 'Cat', tags: 'pets feline cats ', v1_name: '', icon: Cat },
  {
    field: 'Chat',
    tags: 'feedback suggestion comment ',
    v1_name: '',
    icon: Chat
  },
  {
    field: 'CheckBox',
    tags: 'mark check tick tic',
    v1_name: '',
    icon: CheckBox
  },
  { field: 'ChildCare', tags: 'child care baby', v1_name: '', icon: ChildCare },
  {
    field: 'ChildFriendly',
    tags: 'child_friendly child friendly pram stroller',
    v1_name: '',
    icon: ChildFriendly
  },
  {
    field: 'ChromeReaderMode',
    tags: 'rules notes information guestboook',
    v1_name: '',
    icon: ChromeReaderMode
  },
  {
    field: 'Class',
    tags: 'notes information books manuals',
    v1_name: '',
    icon: Class
  },
  {
    field: 'Cleaning',
    tags: 'spray cleaner clean cleaning',
    v1_name: '',
    icon: Cleaning
  },
  {
    field: 'CleaningUtensils',
    tags: 'broom mop bucket dustpan',
    v1_name: '',
    icon: CleaningUtensils
  },
  {
    field: 'CloudQueue',
    tags: 'rainy days cloud',
    v1_name: '',
    icon: CloudQueue
  },
  {
    field: 'CloudSunLightning',
    tags: 'cloudy cloud storm lightning',
    v1_name: '',
    icon: CloudSunLightning
  },
  {
    field: 'Clubhouse',
    tags: 'recreation facilities club golf',
    v1_name: '',
    icon: Clubhouse
  },
  {
    field: 'Compost',
    tags: 'compost recycle food garbage waste',
    v1_name: '',
    icon: Compost
  },
  { field: 'Cot', tags: 'crib baby toddler kids', v1_name: '', icon: Cot },
  {
    field: 'ContentPaste',
    tags: 'rules notes clipboard paste ',
    v1_name: '',
    icon: ContentPaste
  },
  {
    field: 'Courtyard',
    tags: 'yard garden outdoor trees',
    v1_name: '',
    icon: Courtyard
  },
  {
    field: 'Deck',
    tags: 'verandah veranda patio balcony outside furniture',
    v1_name: '',
    icon: Deck
  },
  {
    field: 'Delete',
    tags: 'recycling trash bin garbage recycle',
    v1_name: 'hf-trash',
    icon: Delete
  },
  {
    field: 'Dining',
    tags: 'room table chairs food',
    v1_name: '',
    icon: Dining
  },
  {
    field: 'DirectionsBike',
    tags: 'cycling getting around hire fitness bike cycle',
    v1_name: 'hf-bike',
    icon: DirectionsBike
  },
  {
    field: 'DirectionsBoat',
    tags: 'boat getting around marina ferries ferry ship',
    v1_name: '',
    icon: DirectionsBoat
  },
  {
    field: 'DirectionsBus',
    tags: 'directions getting around public transport bus',
    v1_name: '',
    icon: DirectionsBus
  },
  {
    field: 'DirectionsCar',
    tags: 'directions getting around hire car uber drive',
    v1_name: '',
    icon: DirectionsCar
  },
  {
    field: 'DirectionsRailway',
    tags: 'directions getting around public transport tram rail train transit',
    v1_name: '',
    icon: DirectionsRailway
  },
  {
    field: 'DirectionsRun',
    tags: 'run track fitness walk',
    v1_name: 'hf-activity',
    icon: DirectionsRun
  },
  {
    field: 'DirectionsTransit',
    tags: 'directions getting around public transport tram rail transit',
    v1_name: '',
    icon: DirectionsTransit
  },
  {
    field: 'DisabledAccess',
    tags: 'disabled disability accessible blind',
    v1_name: '',
    icon: DisabledAccess
  },
  {
    field: 'Dishwasher',
    tags: 'dishes cleaning supplies appliances',
    v1_name: '',
    icon: Dishwasher
  },
  {
    field: 'Disney',
    tags: 'disney castle magic kingdom',
    v1_name: '',
    icon: Disney
  },
  { field: 'Dog', tags: 'pet pets canine dog dogs', v1_name: '', icon: Dog },
  {
    field: 'EditLocation',
    tags: 'suggestion direction information ',
    v1_name: '',
    icon: EditLocation
  },
  {
    field: 'EventSeat',
    tags: 'furniture chair seating seat',
    v1_name: '',
    icon: EventSeat
  },
  {
    field: 'EvStation',
    tags: 'electric charging station ',
    v1_name: '',
    icon: EvStation
  },
  {
    field: 'Extension2',
    tags: 'jigsaw puzzle game',
    v1_name: '',
    icon: Extension2
  },
  { field: 'Face', tags: 'face welcome introduction', v1_name: '', icon: Face },
  {
    field: 'Favorite',
    tags: 'other recommended about us ',
    v1_name: '',
    icon: Favorite
  },
  {
    field: 'FavoriteBorder',
    tags: 'other recommended about us ',
    v1_name: '',
    icon: FavoriteBorder
  },
  {
    field: 'FestivalTent',
    tags: 'festival circus tent amusement',
    v1_name: '',
    icon: FestivalTent
  },
  {
    field: 'FerrisWheel',
    tags: 'ferris wheel amusement park fair',
    v1_name: '',
    icon: FerrisWheel
  },
  {
    field: 'FilterVintage',
    tags: 'plants outside florist ',
    v1_name: '',
    icon: FilterVintage
  },
  {
    field: 'FireExtinguisher',
    tags: 'extinguisher fire safety equipment',
    v1_name: 'hf-extinguisher',
    icon: FireExtinguisher
  },
  { field: 'Firepit', tags: 'fire pit outdoors ', v1_name: '', icon: Firepit },
  {
    field: 'Fireplace',
    tags: 'fire firepit pizza oven heating',
    v1_name: 'hf-fire',
    icon: Fireplace
  },
  {
    field: 'FishFood',
    tags: 'fish food fishing seafood',
    v1_name: '',
    icon: FishFood
  },
  {
    field: 'FishHook',
    tags: 'fishhook hook fishing fish',
    v1_name: '',
    icon: FishHook
  },
  {
    field: 'FishingBobber',
    tags: 'bobber fishing fish',
    v1_name: '',
    icon: FishingBobber
  },
  { field: 'Flag', tags: 'attention other  ', v1_name: '', icon: Flag },
  { field: 'Flight', tags: 'shuttle airline  ', v1_name: '', icon: Flight },
  {
    field: 'FreeBreakfast',
    tags: 'tea kitchen supplies  ',
    v1_name: '',
    icon: FreeBreakfast
  },
  {
    field: 'Fridge',
    tags: 'kitchen refridgerator fridge food supplied',
    v1_name: '',
    icon: Fridge
  },
  {
    field: 'FrontDesk',
    tags: 'front desk concierge bell desk batch-6-6',
    v1_name: '',
    icon: FrontDesk
  },
  {
    field: 'Garage',
    tags: 'parking carport car vehicle',
    v1_name: 'hf-garage',
    icon: Garage
  },
  { field: 'Gate', tags: 'fence locked access path', v1_name: '', icon: Gate },
  { field: 'Gift', tags: 'gift wrapping  ', v1_name: 'hf-present', icon: Gift },
  { field: 'Goat', tags: 'livestock cattle  ', v1_name: '', icon: Goat },
  {
    field: 'GolfCourse',
    tags: 'golf_course golf  club activity',
    v1_name: '',
    icon: GolfCourse
  },
  {
    field: 'Gondola',
    tags: 'gondola keel bateau barque dory batch-6-6',
    v1_name: '',
    icon: Gondola
  },
  {
    field: 'Grapes',
    tags: 'grapes vineyard wine grape',
    v1_name: '',
    icon: Grapes
  },
  {
    field: 'Hairdryer',
    tags: 'applicances hair dryer beauty',
    v1_name: '',
    icon: Hairdryer
  },
  {
    field: 'Headset',
    tags: 'noise neighbors headphones ',
    v1_name: '',
    icon: Headset
  },
  {
    field: 'Healing',
    tags: 'healing first aid bandaids ',
    v1_name: '',
    icon: Healing
  },
  {
    field: 'Health',
    tags: 'health emergency medicine ambulance',
    v1_name: 'hf-emergency',
    icon: Health
  },
  { field: 'Hearing', tags: 'access   ', v1_name: '', icon: Hearing },
  { field: 'Help', tags: 'question help  ', v1_name: '', icon: Help },
  {
    field: 'Highlight',
    tags: 'blackout emergency supplies  ',
    v1_name: '',
    icon: Highlight
  },
  { field: 'Hiking', tags: 'hike bush walk walk ', v1_name: '', icon: Hiking },
  {
    field: 'Hiking2',
    tags: 'hiking trekking hike trek',
    v1_name: '',
    icon: Hiking2
  },
  { field: 'Home', tags: 'house rules  ', v1_name: '', icon: Home },
  {
    field: 'Horseback',
    tags: 'horse horseback riding equestrian',
    v1_name: '',
    icon: Horseback
  },
  {
    field: 'Horseshoe',
    tags: 'horse horseshoe luck lucky',
    v1_name: '',
    icon: Horseshoe
  },
  {
    field: 'HotTub',
    tags: 'hot_tub spa bath bathroom',
    v1_name: '',
    icon: HotTub
  },
  {
    field: 'Info',
    tags: 'info about us host information',
    v1_name: '',
    icon: Info
  },
  {
    field: 'Iron',
    tags: 'appliances cleaning press steam',
    v1_name: '',
    icon: Iron
  },
  {
    field: 'Kayak',
    tags: 'water row activity sport',
    v1_name: '',
    icon: Kayak
  },
  { field: 'Keyboard', tags: 'computer    ', v1_name: '', icon: Keyboard },
  {
    field: 'Kitchen',
    tags: 'cooking stove refigerator fridge',
    v1_name: 'hf-kitchen',
    icon: Kitchen
  },
  {
    field: 'Kitchenwares',
    tags: 'kitchen kitchenwares dishes',
    v1_name: '',
    icon: Kitchenwares
  },
  {
    field: 'LaptopMac',
    tags: 'laptop_mac laptop computer office',
    v1_name: '',
    icon: LaptopMac
  },
  { field: 'Library', tags: 'books reading room ', v1_name: '', icon: Library },
  {
    field: 'LibraryBooks',
    tags: 'manuals books library ',
    v1_name: '',
    icon: LibraryBooks
  },
  {
    field: 'LifeJacket',
    tags: 'water sports safety sailing',
    v1_name: '',
    icon: LifeJacket
  },
  {
    field: 'LightbulbOutline',
    tags: 'suggestions idea supplies ',
    v1_name: '',
    icon: LightbulbOutline
  },
  {
    field: 'LiveTv',
    tags: 'video play apple tv netflix',
    v1_name: '',
    icon: LiveTv
  },
  {
    field: 'LocalBar',
    tags: 'bar cocktails drinks glasses',
    v1_name: '',
    icon: LocalBar
  },
  {
    field: 'LocalDrink',
    tags: 'drink glasses water ',
    v1_name: '',
    icon: LocalDrink
  },
  {
    field: 'LocalFlorist',
    tags: 'florist garden flower flora',
    v1_name: '',
    icon: LocalFlorist
  },
  {
    field: 'LocalGasStation',
    tags: 'local_gas_station petrol deisel ',
    v1_name: '',
    icon: LocalGasStation
  },
  {
    field: 'LocalHotel',
    tags: 'local_hotel bedroom bed ',
    v1_name: '',
    icon: LocalHotel
  },
  {
    field: 'LocalLaundryService',
    tags: 'local_laundry_service laundry washing machine dryer',
    v1_name: 'hf-washing',
    icon: LocalLaundryService
  },
  {
    field: 'LocalLibrary',
    tags: 'local_library books book ',
    v1_name: '',
    icon: LocalLibrary
  },
  {
    field: 'LocalParking',
    tags: 'car garage carport ',
    v1_name: '',
    icon: LocalParking
  },
  {
    field: 'LocalPizza',
    tags: 'local_pizza pizza takeaway ',
    v1_name: '',
    icon: LocalPizza
  },
  { field: 'Lock', tags: 'lock access security ', v1_name: '', icon: Lock },
  { field: 'Loyalty', tags: 'loyalty bookmark', v1_name: '', icon: Loyalty },
  {
    field: 'MailOutline',
    tags: 'email mailing list mail ',
    v1_name: '',
    icon: MailOutline
  },
  {
    field: 'Map',
    tags: 'directions walking tracks walks ',
    v1_name: '',
    icon: Map
  },
  { field: 'Mickey', tags: 'mickey mouse disney', v1_name: '', icon: Mickey },
  {
    field: 'Microwave',
    tags: 'micro microwave oven nuke zap cooker batch-6-6',
    v1_name: '',
    icon: Microwave
  },
  {
    field: 'MonetizationOn',
    tags: 'funds refund fines fees',
    v1_name: '',
    icon: MonetizationOn
  },
  {
    field: 'Mosquito',
    tags: 'mosquito bugs bug spray insect',
    v1_name: '',
    icon: Mosquito
  },
  { field: 'Motorcycle', tags: 'motorbike   ', v1_name: '', icon: Motorcycle },
  {
    field: 'MovieCreation',
    tags: 'dvds cable  ',
    v1_name: '',
    icon: MovieCreation
  },
  {
    field: 'MusicFestival',
    tags: 'music guitar electric festival',
    v1_name: '',
    icon: MusicFestival
  },
  { field: 'Nature', tags: 'outside   ', v1_name: '', icon: Nature },
  {
    field: 'NaturePeople',
    tags: 'picnic park garden ',
    v1_name: '',
    icon: NaturePeople
  },
  {
    field: 'Needle',
    tags: 'needle sewing kit sew thread batch-6-6',
    v1_name: '',
    icon: Needle
  },
  { field: 'Note', tags: 'rules note  ', v1_name: '', icon: Note },
  {
    field: 'Notifications',
    tags: 'alarm smoke reminder wakeup',
    v1_name: '',
    icon: Notifications
  },
  {
    field: 'Oven',
    tags: 'kitchen cooking stove stovetop',
    v1_name: 'hf-oven',
    icon: Oven
  },
  {
    field: 'PaddleBoard',
    tags: 'sup stand water activity',
    v1_name: '',
    icon: PaddleBoard
  },
  { field: 'PanTool', tags: 'hello wave  ', v1_name: '', icon: PanTool },
  {
    field: 'People',
    tags: 'neighbours neighbors next door ',
    v1_name: '',
    icon: People
  },
  {
    field: 'Pets',
    tags: 'pet cat dog friendly',
    v1_name: 'hf-pets',
    icon: Pets
  },
  {
    field: 'Phone',
    tags: 'contact phone telephone ',
    v1_name: '',
    icon: Phone
  },
  {
    field: 'PhoneIphone',
    tags: 'phone reception  ',
    v1_name: 'hf-mobile',
    icon: PhoneIphone
  },
  {
    field: 'PhotoCamera',
    tags: 'photo photos  ',
    v1_name: '',
    icon: PhotoCamera
  },
  {
    field: 'Picnic',
    tags: 'table outdoor entertaining ',
    v1_name: '',
    icon: Picnic
  },
  {
    field: 'PizzaOven',
    tags: 'pizza fire oven ',
    v1_name: 'hf-pizza-oven',
    icon: PizzaOven
  },
  {
    field: 'PlaylistAddCheck',
    tags: 'notes information check rules',
    v1_name: '',
    icon: PlaylistAddCheck
  },
  { field: 'Pool', tags: 'swim swimming  ', v1_name: 'hf-pool', icon: Pool },
  {
    field: 'PowerCord',
    tags: 'cord power leads extension',
    v1_name: '',
    icon: PowerCord
  },
  {
    field: 'Power',
    tags: 'power lightning bolt electricity',
    v1_name: '',
    icon: Power
  },
  { field: 'Printer', tags: 'printer   ', v1_name: '', icon: Printer },
  {
    field: 'Public',
    tags: 'recycling earth world ',
    v1_name: 'hf-world',
    icon: Public
  },
  {
    field: 'Recycle',
    tags: 'recycle compost garbage waste',
    v1_name: '',
    icon: Recycle
  },
  {
    field: 'Refresh',
    tags: 'recycling rubbish  ',
    v1_name: 'hf-recycle',
    icon: Refresh
  },
  {
    field: 'Restaurant',
    tags: 'take away menu recipes restaurant',
    v1_name: 'hf-recipes',
    icon: Restaurant
  },
  {
    field: 'RestaurantMenu',
    tags: 'food dining takeout dinner',
    v1_name: '',
    icon: RestaurantMenu
  },
  {
    field: 'RollerCoaster',
    tags: 'roller coaster amusement park',
    v1_name: '',
    icon: RollerCoaster
  },
  {
    field: 'RoomService',
    tags: 'service bell alarm smoke',
    v1_name: '',
    icon: RoomService
  },
  {
    field: 'Router',
    tags: 'troubleshooting reset wifi ',
    v1_name: '',
    icon: Router
  },
  { field: 'Rowing', tags: 'canoe paddle rowing ', v1_name: '', icon: Rowing },
  {
    field: 'Sailing',
    tags: 'sailing boat water sport',
    v1_name: '',
    icon: Sailing
  },
  { field: 'Scooter', tags: 'scooter moped', v1_name: '', icon: Scooter },
  {
    field: 'Security',
    tags: 'safety sheild  ',
    v1_name: 'hf-sheild',
    icon: Security
  },
  { field: 'Settings', tags: 'cogs   ', v1_name: '', icon: Settings },
  {
    field: 'SettingsRemote',
    tags: 'control settings  ',
    v1_name: '',
    icon: SettingsRemote
  },
  {
    field: 'Share',
    tags: 'facebook pinterest twitter instagram',
    v1_name: '',
    icon: Share
  },
  {
    field: 'ShoePolish',
    tags: 'shoe polish shine batch-6-6',
    v1_name: '',
    icon: ShoePolish
  },
  {
    field: 'ShoppingBasket',
    tags: 'shops nearest  grocery ',
    v1_name: '',
    icon: ShoppingBasket
  },
  {
    field: 'ShoppingCart',
    tags: 'store mall shops trolley',
    v1_name: '',
    icon: ShoppingCart
  },
  {
    field: 'SimCardAlert',
    tags: 'important information  ',
    v1_name: '',
    icon: SimCardAlert
  },
  {
    field: 'Skiing',
    tags: 'skiing ski hill downhill sport',
    v1_name: '',
    icon: Skiing
  },
  {
    field: 'SmartHome',
    tags: 'smart home alexa google echo dot virtual concierge assistant',
    v1_name: '',
    icon: SmartHome
  },
  {
    field: 'SmokeFree',
    tags: 'rooms smoke free ',
    v1_name: '',
    icon: SmokeFree
  },
  { field: 'SmokingRooms', tags: 'rooms   ', v1_name: '', icon: SmokingRooms },
  { field: 'Spa', tags: 'massage spa  ', v1_name: '', icon: Spa },
  { field: 'Speaker', tags: 'speaker audio  ', v1_name: '', icon: Speaker },
  {
    field: 'SpeakerNotes',
    tags: 'contact information chat contact',
    v1_name: '',
    icon: SpeakerNotes
  },
  { field: 'Star', tags: 'review like favorite ', v1_name: '', icon: Star },
  {
    field: 'StoreMallDirectory',
    tags: 'shops mall store closest',
    v1_name: '',
    icon: StoreMallDirectory
  },
  { field: 'Surfing', tags: 'surfing beach ocean', v1_name: '', icon: Surfing },
  {
    field: 'SurroundSound',
    tags: 'alexa Google Home  ',
    v1_name: '',
    icon: SurroundSound
  },
  {
    field: 'Target',
    tags: 'shooting archery activity hunting',
    v1_name: '',
    icon: Target
  },
  {
    field: 'Terrain',
    tags: 'terrain outdoors mountain ',
    v1_name: '',
    icon: Terrain
  },
  {
    field: 'Thermostat',
    tags: 'thermostat temperature control thermometer',
    v1_name: 'hf-thermometer',
    icon: Thermostat
  },
  { field: 'ThumbUp', tags: 'up ok thumb', v1_name: '', icon: ThumbUp },
  {
    field: 'Toaster',
    tags: 'toaster toast oven bread batch-6-6',
    v1_name: '',
    icon: Toaster
  },
  {
    field: 'Toilet',
    tags: 'toilet seat wc water closet bathroom restroom',
    v1_name: '',
    icon: Toilet
  },
  {
    field: 'TouchApp',
    tags: 'heat temperature  ',
    v1_name: '',
    icon: TouchApp
  },
  {
    field: 'Towels',
    tags: 'towels sheets blankets ',
    v1_name: 'hf-towels',
    icon: Towels
  },
  { field: 'Toys', tags: 'kids toys  ', v1_name: '', icon: Toys },
  {
    field: 'Treadmill',
    tags: 'treadmill run facilities ',
    v1_name: 'hf-gym',
    icon: Treadmill
  },
  {
    field: 'Tv',
    tags: 'tv television monitor screen',
    v1_name: 'hf-tv',
    icon: Tv
  },
  { field: 'Usb', tags: 'plugs chargers usb phone', v1_name: '', icon: Usb },
  {
    field: 'Videocam',
    tags: 'security camera surveillance video',
    v1_name: '',
    icon: Videocam
  },
  {
    field: 'VideogameAsset',
    tags: 'game kids video toy',
    v1_name: '',
    icon: VideogameAsset
  },
  {
    field: 'VirtualAssistant',
    tags: 'smart home alexa google echo dot virtual concierge assistant',
    v1_name: '',
    icon: VirtualAssistant
  },
  {
    field: 'VolumeUp',
    tags: 'volume up sound audio music ',
    v1_name: '',
    icon: VolumeUp
  },
  {
    field: 'VpnKey',
    tags: 'vpn vpn_key check in keys lock',
    v1_name: 'hf-check-in',
    icon: VpnKey
  },
  {
    field: 'Warning',
    tags: 'warning alert hazard ',
    v1_name: '',
    icon: Warning
  },
  {
    field: 'Weekend',
    tags: 'weekend lounge room couch seating',
    v1_name: '',
    icon: Weekend
  },
  {
    field: 'Weight',
    tags: 'weight fitness weightlifting gym',
    v1_name: '',
    icon: Weight
  },
  {
    field: 'Whatshot',
    tags: 'hot fire fireplace firepit matches',
    v1_name: '',
    icon: Whatshot
  },
  {
    field: 'Wheelchair',
    tags: 'wheelchair access accessible disabled disability',
    v1_name: '',
    icon: Wheelchair
  },
  {
    field: 'Wifi',
    tags: 'wifi router access password network',
    v1_name: '',
    icon: Wifi
  },
  {
    field: 'Wildlife',
    tags: 'wildlife bear paw predator',
    v1_name: '',
    icon: Wildlife
  },
  { field: 'Wine', tags: 'wine vineyard vino glass', v1_name: '', icon: Wine },
  {
    field: 'Work',
    tags: 'work brief case luggage baggage ',
    v1_name: '',
    icon: Work
  },
  {
    field: 'Yoga',
    tags: 'yoga floating guru yogi pose batch-6-6',
    v1_name: '',
    icon: Yoga
  }
]
