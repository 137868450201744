export const MAP_MARKERS_UPDATE = 'MAP_MARKERS_UPDATE'
export const VISIBLE_MAP_MARKERS_UPDATE = 'VISIBLE_MAP_MARKERS_UPDATE'
export const MAP_OPTIONS_UPDATE = 'MAP_OPTIONS_UPDATE'
export const MAP_BOUNDS_UPDATE = 'MAP_BOUNDS_UPDATE'

const initialState = {
  markers: [],
  visibleMarkerIdsByCategory: {},
  options: {},
  bounds: {}
}

/* Reducers */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MAP_MARKERS_UPDATE:
      return Object.assign({}, state, { markers: action.markers })
    case VISIBLE_MAP_MARKERS_UPDATE:
      const markerIdsByCategory = {}
      markerIdsByCategory[action.mapId] = action.visibleMarkerIds
      const newVal = Object.assign(
        {},
        state.visibleMarkerIdsByCategory,
        markerIdsByCategory
      )
      return Object.assign({}, state, { visibleMarkerIdsByCategory: newVal })
    case MAP_OPTIONS_UPDATE:
      return Object.assign({}, state, { options: action.options })
    case MAP_BOUNDS_UPDATE:
      const mapIdBounds = {}
      mapIdBounds[action.mapId] = action.bounds
      const newBounds = Object.assign({}, state.bounds, mapIdBounds)
      return Object.assign({}, state, { bounds: newBounds })
    default:
      return state
  }
}

/* Actions */
export function updateMapMarkers(mapMarkers) {
  return {
    type: MAP_MARKERS_UPDATE,
    markers: mapMarkers
  }
}
export function updateVisibleMapMarkerIds(mapId, visibleMarkerIds) {
  return {
    type: VISIBLE_MAP_MARKERS_UPDATE,
    mapId,
    visibleMarkerIds: visibleMarkerIds
  }
}
export function updateMapOptions(mapOptions) {
  return {
    type: MAP_OPTIONS_UPDATE,
    options: mapOptions
  }
}

export function setMapBounds(mapId, lat, lng, zoom) {
  return {
    type: MAP_BOUNDS_UPDATE,
    mapId,
    bounds: {
      lat,
      lng,
      zoom
    }
  }
}
export function clearMapBounds(mapId) {
  return {
    type: MAP_BOUNDS_UPDATE,
    mapId,
    bounds: null
  }
}
