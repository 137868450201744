import { getToken } from 'utils/Auth'

import 'whatwg-fetch'

export function fetchStats(statsName, startDate, endDate, userId, key) {
  let url = process.env.REACT_APP_API_URL + '/api/v1/stats/' + statsName

  const sDate = startDate || null
  const eDate = endDate || null
  const uId = userId || null
  const lookupKey = key || 'unknown'

  // add start date if relevant
  if (sDate && !eDate) {
    url = url.concat('/date/').concat(sDate)
  }

  // add user if relevant
  if (statsName === 'customer_completion' && uId) {
    url = url.concat('/user/').concat(uId)
  }

  if (statsName === 'user_by_guidebook' && lookupKey) {
    url = `${url}/key/${lookupKey}`
  }

  const token = getToken()
  const config = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }

  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
}
