import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, injectIntl, intlShape } from 'react-intl'
import { Avatar, Card, CardText, CardTitle } from 'react-md'
import { guidebookShape } from 'constants/SharedPropTypes'
import { matchData } from 'utils/Data'
import { allIcons } from 'constants/UIIcons'

const propTypes = {
  guidebook: guidebookShape.isRequired,
  custom_color: PropTypes.string,
  intl: intlShape.isRequired
}
const defaultProps = {}

class Weather extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  formatMessage = this.props.intl.formatMessage

  messages = defineMessages({
    weather: {
      id: 'partners.weather.title',
      defaultMessage: 'Current Weather'
    }
  })

  render() {
    const { address } = this.props.guidebook
    let showIcon = null
    let iconMatch = matchData(
      allIcons,
      'Icons',
      ['field', 'v1_name'],
      'CloudQueue'
    )
    const IconComponent = iconMatch.icon
    showIcon = <IconComponent fill="#ffffff" />
    const avatarStyle = { background: this.props.custom_color || '#f44336' }
    const gbCC = address.country_code.toLowerCase().trim()
    const usCountryCodes = [
      'us',
      'unitedstates',
      'united states',
      'untied states',
      'united states of america',
      'usa',
      'usvi',
      'u.s. virgin islands',
      'america'
    ]
    const caCountryCodes = ['ca', 'canada']
    const ukCountryCodes = [
      'united kingdom',
      'scotland',
      'northern ireland',
      'scotland, uk',
      'ireland',
      'england',
      'uk'
    ]
    let tempFormat = 'si'
    if (usCountryCodes.indexOf(gbCC) !== -1) {
      tempFormat = 'us'
    } else if (caCountryCodes.indexOf(gbCC) !== -1) {
      tempFormat = 'ca'
    } else if (ukCountryCodes.indexOf(gbCC) !== -1) {
      tempFormat = 'uk'
    }
    let widgetName = address.locality ? address.locality : ''
    return (
      <Card className="md-cell md-cell--12 weatherContainer" raise={true}>
        <CardTitle
          avatar={
            <Avatar icon={showIcon} role="presentation" style={avatarStyle} />
          }
          title={this.formatMessage(this.messages.weather)}
        ></CardTitle>
        <CardText>
          <iframe
            title="Current weather"
            id="forecast_embed"
            key={this.state.lat}
            type="text/html"
            frameBorder="0"
            height="245"
            width="100%"
            src={`https://forecast.io/embed/#lat=${address.lat}&lon=${address.lng}&name=${widgetName}&units=${tempFormat}`}
          >
            Loading...
          </iframe>
        </CardText>
      </Card>
    )
  }
}
Weather.propTypes = propTypes
Weather.defaultProps = defaultProps

export default injectIntl(Weather)
