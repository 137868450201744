import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { nl2br } from 'utils/Strings'
import { findGuidebookUrl } from 'utils/Urls'
import { transformCustomVariables } from 'utils/Strings'
import cn from 'classnames'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
  title_txn: PropTypes.object,
  styles: PropTypes.object,
  translations: PropTypes.object,
  className: PropTypes.string,
  variableData: PropTypes.object
}

const defaultProps = {
  className: '',
  splitBr: true,
  variableData: {}
}

class PrintHtml extends Component {
  getTitleHTMLAsText = () => {
    const { title, title_txn, styles } = this.props
    let text
    if (
      this.props.intl.locale === 'en' ||
      !this.props.translations ||
      !this.props.translations[this.props.intl.locale]
    ) {
      text = title
    } else {
      text = title_txn[this.props.intl.locale]
    }
    const titleClass = cn(this.props.titleClass, 'content-title')
    const style = styles ? 'color: ' + styles.textColor.color : null
    return (
      '<span class="' +
      titleClass +
      '" style="' +
      style +
      '">' +
      text +
      '</span>'
    )
  }

  //get content in HTML format
  // createYoutubeEmbed = (key) => {
  //   return '<iframe width="420" height="345" src="https://www.youtube.com/embed/' + key + '?playsinline=1&fs=0" frameBorder="0" seamless="seamless"></iframe><br/>';
  // };

  // createVimeoEmbed = (key) => {
  //   return '<iframe src="https://player.vimeo.com/video/377207719" width="420" height="345" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe><br/>';
  // };

  // transformYoutubeLinks = (text) => {
  //   if (!text) return text;

  //   const self = this;
  //   const linkreg = /(?:)<a([^>]+)>(.+?)<\/a>/g;
  //   const fullreg = /(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([^&^" \n<]+)(?:[^ \n"<]+)?/g;
  //   const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^& \n<]+)(?:[^ \n<]+)?/g;

  //   let resultHtml = text;

  //   // get all the matches for youtube links using the first regex
  //   const match = text.match(fullreg);
  //   if (match && match.length > 0) {
  //     // get all links and put in placeholders
  //     const matchlinks = text.match(linkreg);
  //     if (matchlinks && matchlinks.length > 0) {
  //       for (var i=0; i < matchlinks.length; i++) {
  //         resultHtml = resultHtml.replace(matchlinks[i], "#placeholder" + i + "#");
  //       }
  //     }

  //     // now go through the matches one by one
  //     for (var j=0; j < match.length; j++) {
  //       // get the key out of the match using the second regex
  //       let matchParts = match[j].split(regex);
  //       // replace the full match with the embedded youtube code
  //       resultHtml = resultHtml.replace(match[j], self.createYoutubeEmbed(matchParts[1]));
  //     }

  //     // ok now put our links back where the placeholders were.
  //     if (matchlinks && matchlinks.length > 0) {
  //       for (var k=0; k < matchlinks.length; k++) {
  //         resultHtml = resultHtml.replace("#placeholder" + k + "#", matchlinks[k]);
  //       }
  //     }
  //   }
  //   return resultHtml;
  // };

  // transformVimeoLinks = (text) => {
  //   if (!text) return text;

  //   const self = this;
  //   const linkreg = /(?:)<a([^>]+)>(.+?)<\/a>/g;
  //   const fullreg = /(https?:\/\/)?(vimeo\.com\/)([^&^" \n<]+)(?:[^ \n"<]+)?/g;
  //   const regex = /(?:https?:\/\/)?(vimeo\.com\/)([^& \n<]+)(?:[^ \n<]+)?/g;

  //   let resultHtml = text;

  //   // get all the matches for youtube links using the first regex
  //   const match = text.match(fullreg);
  //   if (match && match.length > 0) {
  //     // get all links and put in placeholders
  //     const matchlinks = text.match(linkreg);
  //     if (matchlinks && matchlinks.length > 0) {
  //       for (var i=0; i < matchlinks.length; i++) {
  //         resultHtml = resultHtml.replace(matchlinks[i], "#placeholder" + i + "#");
  //       }
  //     }

  //     // now go through the matches one by one
  //     for (var j=0; j < match.length; j++) {
  //       // get the key out of the match using the second regex
  //       let matchParts = match[j].split(regex);
  //       // replace the full match with the embedded youtube code
  //       resultHtml = resultHtml.replace(match[j], self.createVimeoEmbed(matchParts[1]));
  //     }

  //     // ok now put our links back where the placeholders were.
  //     if (matchlinks && matchlinks.length > 0) {
  //       for (var k=0; k < matchlinks.length; k++) {
  //         resultHtml = resultHtml.replace("#placeholder" + k + "#", matchlinks[k]);
  //       }
  //     }
  //   }
  //   return resultHtml;
  // }

  transformCardLinks = (text) => {
    if (!text) return text

    let resultHtml = text
    let replaceLink = ''
    const cardLinkReg = /(?:)\[hf-card-link([^[]+)\](.+?)\[\/hf-card-link\]/g
    const matchlinks = text.match(cardLinkReg)
    if (matchlinks && matchlinks.length > 0) {
      for (var i = 0; i < matchlinks.length; i++) {
        // trim off what we need
        let scratch = matchlinks[i].substring(15)
        let url = scratch.split('"', 1)[0]
        let urlSplit = url.split('/', 2)
        scratch = scratch.substring(0, scratch.length - 15)
        let text = scratch.substring(scratch.indexOf(']') + 1)
        let { guidebookUrl, recsOnly } = findGuidebookUrl(this.props.domain)
        // if we have a recs-only link make sure not to build links to non-rec links
        if (
          guidebookUrl &&
          recsOnly &&
          urlSplit.length > 1 &&
          ['arrival', 'house_manual', 'departure'].indexOf(urlSplit[1]) !== -1
        ) {
          replaceLink = `<a href="javascript:null" title="link not allowed in recs-only guidebooks" style="cursor: not-allowed;">${text}</a>`
          resultHtml = resultHtml.replace(matchlinks[i], replaceLink)
        } else if (guidebookUrl) {
          replaceLink = `<a href="${guidebookUrl}${url}">${text}</a>`
          resultHtml = resultHtml.replace(matchlinks[i], replaceLink)
        } else {
          replaceLink = `<a href="javascript:null" title="invalid link" style="cursor: not-allowed;">${text}</a>`
          resultHtml = resultHtml.replace(matchlinks[i], replaceLink)
        }
      }
    }
    return resultHtml
  }

  render() {
    let text
    if (
      this.props.intl.locale === 'en' ||
      !this.props.translations ||
      !this.props.translations[this.props.intl.locale]
    ) {
      text = this.props.text
    } else {
      text = this.props.translations[this.props.intl.locale]
    }
    if (text && this.props.splitBr) {
      text = nl2br(text)
    }
    // text = this.transformYoutubeLinks(text);
    // text = this.transformVimeoLinks(text);
    text = this.transformCardLinks(text)
    text = transformCustomVariables(text, this.props.variableData)

    //create a template to structure the HTML as required for print
    var template = document.createElement('div')
    template.innerHTML =
      '<div class="title-content-wrapper-avoid">' +
      this.getTitleHTMLAsText() +
      '</div><div></div>'

    //convert text to HTML so we can insert the child nodes into the template
    const parser = new DOMParser()
    const textToDoc = parser.parseFromString(text, 'text/html')
    const body = textToDoc.getElementsByTagName('body')
    var firstChild = body[0].childNodes[0].cloneNode(true)

    //create virtual fragment we can use to build new nodes using the text and template and then use its innerHTML to dangerously set the real component with
    var virtualFragment = document.createDocumentFragment()
    virtualFragment.appendChild(template)
    //append first child node with title inside break avoid wrapper
    virtualFragment.childNodes[0].childNodes[0].appendChild(firstChild)
    //now add any remaning nodes to the other div
    if (body[0].childNodes.length > 1) {
      for (var i = 1; i < body[0].childNodes.length; i++) {
        virtualFragment.childNodes[0].appendChild(
          body[0].childNodes[i].cloneNode(true)
        )
      }
    }

    const innerHtml = { __html: virtualFragment.childNodes[0].innerHTML }
    const textHtml = (
      <span
        className={cn('ashtml-span', this.props.className)}
        dangerouslySetInnerHTML={innerHtml}
      />
    )

    return textHtml
  }
}

PrintHtml.propTypes = propTypes
PrintHtml.defaultProps = defaultProps

function mapStateToProps(state, props) {
  const customDomain =
    state.guidebook &&
    state.guidebook.data &&
    state.guidebook.data.domain &&
    state.guidebook.data.domain.status &&
    state.guidebook.data.domain.status === 'approved'
      ? state.guidebook.data.domain.domain
      : null
  return {
    intl: state.intl,
    domain: customDomain
  }
}

export default connect(mapStateToProps)(PrintHtml)
