import React from 'react'

const VideogameAsset = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`videogame_asset`}</title>
    <path d="M19.5 12c.844 0 1.5-.656 1.5-1.5S20.344 9 19.5 9s-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm-3.984 3c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm-4.5-2.016v-1.969h-3v-3H6v3H3v1.969h3v3h2.016v-3h3zM21 6c1.078 0 2.016.938 2.016 2.016v7.969c0 1.078-.938 2.016-2.016 2.016H3c-1.078 0-2.016-.938-2.016-2.016V8.016C.984 6.938 1.922 6 3 6h18z" />
  </svg>
)

export default VideogameAsset
