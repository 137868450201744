import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { Divider, FontIcon, ListItem } from 'react-md'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'react-router-redux'

import TooltipFontIcon from 'components/TooltipFontIcon'
import {
  allNavIndexes,
  filterNavIndexes,
  filterPageAccess,
  hostNavIcons,
  hostNavMessages
} from 'constants/UIConstants'
import currentUser from 'utils/CurrentUser'

class HostNavItem extends Component {
  constructor(props) {
    super(props)
    this.activeRoute = this.props.location.pathname.split('/')[2]
  }

  showHostItem = (tab) => {
    this.props.dispatch(push('/host/' + tab))
  }

  showBookingSync = () => {
    this.props.dispatch(push('/host/bookingsync'))
  }

  componentDidMount() {
    const handler = this.handleRouteChange.bind(this)

    this.props.history.listen(handler)
  }

  handleRouteChange(location) {
    this.activeRoute = location.pathname.split('/')[2]
    this.forceUpdate()
  }

  buildItems = () => {
    const self = this
    const { showTooltips } = this.props
    const { formatMessage } = this.props.intl
    const user = currentUser()
    const indexes = allNavIndexes // use filters instead of separate indexes
    const icons = hostNavIcons

    const items = indexes
      .filter(function (tab) {
        return filterNavIndexes(tab, user)
      })
      .map(function (tab) {
        const msg = formatMessage(hostNavMessages[tab])
        const icon = icons[tab]
        const fontIcon = showTooltips ? (
          <TooltipFontIcon
            iconClassName="hf-icon-color"
            tooltipLabel={msg}
            tooltipPosition="right"
          >
            {icon}
          </TooltipFontIcon>
        ) : (
          icon
        )
        const hasAccess = filterPageAccess(tab, user)

        return (
          <ListItem
            key={tab}
            id={tab + '_icon'}
            onClick={() => self.showHostItem(tab)}
            leftIcon={fontIcon}
            active={tab === self.activeRoute}
            primaryText={msg}
            className="nav-link"
          >
            {hasAccess || showTooltips ? null : (
              <div className="nav-upgrade">
                <FontIcon className="upgrade-icon">https</FontIcon>{' '}
                <span>Upgrade</span>
              </div>
            )}
          </ListItem>
        )
      })

    // check for integrations
    if (['bookingsync', 'orbirental'].indexOf(user.user.provider) !== -1) {
      let providerTitle = null,
        hostItem = null,
        tooltipLabel = 'Import'

      if (user.user.provider === 'bookingsync') {
        providerTitle = 'BookingSync Imports'
        hostItem = 'bookingsyncrentals'
        tooltipLabel = 'Import from BookingSync'
      }

      if (user.user.provider === 'orbirental') {
        providerTitle = 'PMP Imports'
        hostItem = 'orbirentalproperties'
        tooltipLabel = 'Import from PMP'
      }

      items.unshift(<Divider key="bs_div" />)

      const fontIcon = showTooltips ? (
        <TooltipFontIcon
          iconClassName="hf-icon-color"
          tooltipLabel={tooltipLabel}
          tooltipPosition="right"
        >
          queue
        </TooltipFontIcon>
      ) : (
        <FontIcon iconClassName="material-icons hf-icon-color">queue</FontIcon>
      )

      items.unshift(
        <ListItem
          key="bookingsync"
          id={'bookingsync_icon'}
          onClick={() => self.showHostItem(hostItem)}
          active={hostItem === self.activeRoute}
          leftIcon={fontIcon}
          primaryText={providerTitle}
        />
      )
    }

    return items
  }

  render() {
    return <div>{this.buildItems()}</div>
  }
}

HostNavItem.propTypes = {
  intl: intlShape.isRequired,
  showTooltips: PropTypes.bool.isRequired
}

export default connect(null)(injectIntl(withRouter(HostNavItem)))
