import React from 'react'

const SmokeFree = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`smoke_free`}</title>
    <path d="M17.016 15.938l-2.953-2.953h2.953v2.953zm-2.532-7.219c-1.828 0-3.328-1.547-3.328-3.375s1.5-3.328 3.328-3.328v1.5c-1.031 0-1.828.703-1.828 1.734s.797 2.016 1.828 2.016h1.547c1.875 0 3.469 1.359 3.469 3.141v1.594H18v-1.266c0-1.313-.938-2.016-1.969-2.016h-1.547zm4.36-3.844c1.875.891 3.141 2.813 3.141 5.063v2.063h-1.5V9.938c0-2.25-1.734-4.078-3.984-4.078v-1.5c1.031 0 1.828-.844 1.828-1.875h1.5c0 .938-.375 1.781-.984 2.391zM18 12.984h1.5v3H18v-3zm2.484 0h1.5v3h-1.5v-3zM2.016 6l1.219-1.266L20.251 21.75l-1.266 1.266-6.984-7.031H2.017v-3h6.984z" />
  </svg>
)

export default SmokeFree
