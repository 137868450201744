import React from 'react'

const Wildlife = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`wildlife`}</title>
    <path d="M10 2L8 4c.48 0 .902-.035 1 0 .477.172.773.578 1 1zm5 0l-2 2c.445 0 .691-.027.969.063.41.132.804.515 1.031.937zm-1.781 2.969c-.957.031-1.938 1.039-2.313 2.437-.43 1.602.082 3.117 1.156 3.407 1.075.289 2.29-.774 2.72-2.376.429-1.601-.083-3.117-1.157-3.406a1.444 1.444 0 00-.406-.062zM7.969 5c-.953.035-1.907 1.04-2.282 2.438-.43 1.601.09 3.12 1.157 3.406 1.066.285 2.258-.774 2.687-2.375.43-1.602-.09-3.121-1.156-3.406A1.444 1.444 0 007.969 5zM19 5l-2 2c.406 0 .797-.008 1.031.063.414.125.742.515.969.937zM3.5 8l-1.156 2.531c.324-.34.73-.531 1.156-.531.434 0 .828.18 1.156.531zm13.938 0c-.72.047-1.47.895-1.782 2.063-.355 1.332.012 2.597.813 2.812.8.215 1.738-.7 2.093-2.031.356-1.332-.011-2.598-.812-2.813A.946.946 0 0017.437 8zM22 10l-2 2c.563 0 .734-.02 1.031.063.414.117.742.39.969.812zM3.5 11c-.828 0-1.5.895-1.5 2s.672 2 1.5 2S5 14.105 5 13s-.672-2-1.5-2zm6.5 1c-.824 0-1.547.48-2 1.219-.145.238-1.973 2.437-2.844 3C4.027 16.945 3 17.754 3 19c0 1.656 1.566 3 3.5 3 1.035 0 2.605-.824 3.5-1a3.573 3.573 0 011 0c.895.176 2.465 1 3.5 1 1.934 0 3.5-1.344 3.5-3 0-1.246-.977-2.145-2.156-2.781-1.074-.578-3.7-2.766-3.844-3C11.547 12.48 10.824 12 10 12zm10.438 1c-.708.004-1.407.652-1.657 1.594-.289 1.074.137 2.129.938 2.344.8.214 1.68-.489 1.968-1.563.29-1.074-.136-2.129-.937-2.344a1.213 1.213 0 00-.313-.031z" />
  </svg>
)

export default Wildlife
