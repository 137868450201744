import React from 'react'

const LocalPizza = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`local_pizza`}</title>
    <path d="M12 15c1.078 0 2.016-.938 2.016-2.016s-.938-1.969-2.016-1.969-2.016.891-2.016 1.969S10.922 15 12 15zM6.984 6.984C6.984 8.062 7.922 9 9 9s2.016-.938 2.016-2.016S10.078 5.015 9 5.015s-2.016.891-2.016 1.969zM12 2.016c3.563 0 6.797 1.547 9 3.984l-9 15.984L3 6a12.135 12.135 0 019-3.984z" />
  </svg>
)

export default LocalPizza
