import React, { Component } from 'react'
import {
  Button,
  DataTable,
  LinearProgress,
  Paper,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'

import CsvCreator from 'components/react-csv-creator'
import { fetchTerms } from 'redux/modules/reports'
import { sortData } from 'utils/Data'
import { idToTitle, ucFirst } from 'utils/Strings'

class GuestTerms extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      terms_headers: this.defaultTermsHeaders(),
      custom_keys: [],
      sort_by: 'guidebook_key',
      sort_order: 'desc',
      data: []
    }
  }

  componentDidMount() {
    this.getTermsData()
  }

  defaultTermsHeaders = () => {
    return [
      { id: 'guidebook_key', header: 'Guidebook' },
      { id: 'guidebook_title', header: 'Title' },
      { id: 'first_name', header: 'First' },
      { id: 'last_name', header: 'Last' },
      { id: 'email', header: 'Email' },
      { id: 'browser', header: 'Browser' },
      { id: 'platform', header: 'Platform' },
      { id: 'device_type', header: 'Device' },
      { id: 'ip', header: 'IP' },
      { id: 'date', header: 'Date' }
    ]
  }

  /**
   * Make api call to get stat data and set to state
   */
  getTermsData = () => {
    fetchTerms().then((data) => {
      this.setState({ data: data, loading: false }, (data) => {
        this.updateHeadersAndData()
      })
    })
  }

  renderLoading = () => {
    return (
      <div>
        <LinearProgress />
        <div className="md-text-center">Loading guest terms...</div>
      </div>
    )
  }

  renderNoData = () => {
    return (
      <Paper style={{ margin: '16px', padding: '12px 16px' }}>
        <p>
          No terms approval data found.
          <br />
          <br />
          Fill out the splash screen section of a theme and apply this theme to
          a guidebook.
        </p>
      </Paper>
    )
  }

  updateHeadersAndData = () => {
    this.updateTermsHeaders(() => {
      this.updateTermsData()
    })
  }

  // after fetching data, we need to go through all the custom fields
  // make a list of the custom field types we find
  // and add them to the list of headers
  updateTermsHeaders = (cb) => {
    const { data } = this.state
    if (data.length > 0) {
      const updatedHeaders = Object.assign([], this.state.terms_headers)
      const addedHeaders = []
      // go through each item of data -
      for (var i = 0, len = data.length; i < len; i++) {
        const record = data[i]
        const keys = Object.keys(record.custom_data) || []
        if (keys.length > 0) {
          for (var k = 0, keyNum = keys.length; k < keyNum; k++) {
            const key = keys[k]
            if (addedHeaders.indexOf(key) === -1) {
              const newHeader = {
                id: key,
                header: ucFirst(idToTitle(key))
              }
              updatedHeaders.push(newHeader)
              addedHeaders.push(key)
            }
          }
        }
      }
      this.setState(
        { terms_headers: updatedHeaders, custom_keys: addedHeaders },
        () => {
          if (typeof cb === 'function') {
            cb()
          }
        }
      )
    }
  }

  updateTermsData = () => {
    const newData = Object.assign([], this.state.data)
    for (var i = 0, len = newData.length; i < len; i++) {
      const updatedRow = newData[i]
      const customKeys = this.state.custom_keys
      if (customKeys.length) {
        for (var k = 0, keyNum = customKeys.length; k < keyNum; k++) {
          updatedRow[customKeys[k]] =
            updatedRow.custom_data[customKeys[k]] || ''
        }
      }
      delete updatedRow.custom_data
    }
    this.setState({ data: newData })
  }

  sortTable = (key) => {
    let { sort_order, data } = this.state
    // if the same key is already sorted, just flip the direction
    if (key === this.state.sort_by) {
      sort_order = sort_order === 'asc' ? 'desc' : 'asc'
    } else {
      sort_order = 'asc'
    }
    data = sortData(data, key, sort_order)
    this.setState({ sort_order: sort_order, sort_by: key, data: data })
  }

  renderDataTable = () => {
    const { data } = this.state
    return (
      <Paper style={{ margin: '16px' }}>
        <DataTable baseId="terms-data" plain>
          <TableHeader>
            <TableRow>
              {this.state.terms_headers.map(({ id, header }) => (
                <TableColumn
                  key={id}
                  className="clickable"
                  sorted={
                    id === this.state.sort_by
                      ? this.state.sort_order === 'asc'
                      : undefined
                  }
                  onClick={() => {
                    this.sortTable(id)
                  }}
                  title={`Click to sort by ${header}`}
                  role="button"
                >
                  {header}
                </TableColumn>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((dataItem) => {
              const formattedDate = new Date(dataItem.date)
              return (
                <TableRow key={dataItem.id}>
                  {this.state.terms_headers.map((header) => {
                    const cellId = `data-${dataItem.id}-${header.id}`
                    let dataValue = null
                    if (header.id === 'guidebook_key') {
                      dataValue = (
                        <a
                          href={`/${dataItem.guidebook_key}`}
                          target="newExternaalTab"
                          rel="noopener noreferrer"
                        >
                          {dataItem.guidebook_key}
                        </a>
                      )
                    } else if (header.id === 'date') {
                      dataValue = (
                        <span title={formattedDate}>
                          {formattedDate.toLocaleDateString()}
                        </span>
                      )
                    } else {
                      dataValue = dataItem[header.id]
                    }

                    return <TableColumn key={cellId}>{dataValue}</TableColumn>
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </DataTable>
      </Paper>
    )
  }

  renderDownload = () => {
    if (!this.state.data || this.state.data.length === 0) return null

    const terms_filename = 'guest_terms'

    const headers = Object.assign([], this.state.terms_headers)
    headers.push({ id: 'id', header: 'Term Id' })
    headers.push({ id: 'guidebook_id', header: 'Guidebook Id' })

    return (
      <div className="md-cell md-cell--12">
        <CsvCreator
          filename={terms_filename}
          headers={headers}
          rows={this.state.data}
        >
          <Button
            floating
            mini
            primary
            tooltipLabel="Download this data as a CSV"
          >
            file_download
          </Button>
        </CsvCreator>
      </div>
    )
  }

  render() {
    let report = null
    if (this.state.loading) {
      report = this.renderLoading()
    } else if (this.state.data && this.state.data.length) {
      report = this.renderDataTable()
    } else {
      report = this.renderNoData()
    }
    const exportButton = this.renderDownload()

    return (
      <div className="hf-main-container">
        <Toolbar
          actions={exportButton}
          className={'hf-host-toolbar hf-reports'}
          colored
          title="Guest Terms Approval Data"
        />
        {report}
      </div>
    )
  }
}

export default connect()(GuestTerms)
