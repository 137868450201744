import React from 'react'

const Home = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`home`}</title>
    <path d="M9.984 20.016H5.015V12h-3l9.984-9 9.984 9h-3v8.016h-4.969v-6H9.983v6z" />
  </svg>
)

export default Home
