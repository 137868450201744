import PickerOverlay from 'filestack-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ChromePicker } from 'react-color'
import DocumentTitle from 'react-document-title'
import {
  Avatar,
  Button,
  Card,
  CardText,
  CardTitle,
  Checkbox,
  FontIcon,
  Paper,
  Switch,
  TextField,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'
import { actions, Errors, Form } from 'react-redux-form'
import { push } from 'react-router-redux'

import CheckBoxForm from 'components/CheckBoxForm'
import InputTextField from 'components/InputTextField'
import LocaleForm from 'components/LocaleForm'
import MultilangWysiwyg from 'components/MultilangWysiwyg'
import TooltipButton from 'components/TooltipButton'
import VoiceForm from 'components/VoiceForm'
import {
  hostUIMessages,
  prefixFields,
  tokenFields
} from 'constants/UIConstants'
import { copyItem, saveItem, showItems } from 'redux/modules/crud'
import blankEditStates from 'redux/modules/crud/blankEditStates'
import { fixRotation } from 'redux/modules/filestack'
import currentUser from 'utils/CurrentUser'

import FormCardActions from './FormCardActions'
import ManageSelectedGuidebooks from './ManageSelectedCards'
import ManageGuidebooksAndTemplates from './ManageSelectedCards/ManageGuidebooksAndTemplates'

class HostIntroForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showContactChecked: false,
      showAddressChecked: false
    }
    this.goBack = this.goBack.bind(this)
    this.imageUploaded = this.imageUploaded.bind(this)
    this.hostPhoneChange = this.hostPhoneChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
    this.copyCard = this.copyCard.bind(this)
    this.saveAndAddAnother = this.saveAndAddAnother.bind(this)
  }

  goBack = () => {
    const { dispatch, pluralName, guidebookId, ownerPluralName } = this.props
    if (guidebookId) {
      const path = '#hostintros#hostintro'
      dispatch(push(`/host/${ownerPluralName}/${guidebookId}${path}`))
    } else if (window.isIframe) {
      window.history.back()
    } else {
      dispatch(showItems(pluralName))
    }
  }

  copyCard = (id) => {
    const { dispatch, pluralName } = this.props
    dispatch(copyItem(pluralName, id))
  }

  formatMessage = this.props.intl.formatMessage

  componentDidMount() {
    const { dispatch, itemId } = this.props
    if (itemId === 'create') {
      let newItem = blankEditStates.hostintro.data
      // also if we're making a new one and our "owner" has locales, default to those
      if (this.props.owner_data && this.props.owner_data.locales.length > 0) {
        newItem = Object.assign({}, newItem, {
          locales: this.props.owner_data.locales
        })
      }
      dispatch(actions.load('edit_hostintro', newItem))
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    // ugly hack as we don't store a boolean for show_early_checkin_text
    const { edit_hostintro } = nextProps
    // console.log(edit_hostintro.landing_show_contact);
    if (
      typeof this.props.edit_hostintro.landing_show_contact === 'undefined' &&
      edit_hostintro.landing_show_contact
    ) {
      this.setState({ showContactChecked: edit_hostintro.landing_show_contact })
    }
    if (
      typeof this.props.edit_hostintro.landing_show_address === 'undefined' &&
      edit_hostintro.landing_show_address
    ) {
      this.setState({ showAddressChecked: edit_hostintro.landing_show_address })
    }
  }

  handleEnter = (e) => {
    const { dispatch } = this.props
    e.preventDefault()
    dispatch(actions.submit('edit_hostintro'))
  }

  handleSubmit(item) {
    const { itemId, dispatch, guidebookId, copy } = this.props
    const id = copy ? null : itemId
    const addAnother = this.addAnother
    this.addAnother = false
    const currentGuidebook = guidebookId ? this.props.owner_data : null
    dispatch(
      saveItem(
        'hostintros',
        'hostintro',
        id,
        item,
        addAnother,
        currentGuidebook
      )
    )
  }

  handleIntroHasSecure = (value) => {
    const { dispatch } = this.props
    dispatch(actions.change('edit_hostintro.host_intro_has_secure_info', value))
  }

  formHasErrors = (formErrors) => {
    const edit_form = this.props.edit_form
    if (edit_form.$form.touched) {
      for (var field in formErrors) {
        if (edit_form[field] && !edit_form[field].valid) {
          return false
        }
      }
    }
    return true
  }

  saveAndAddAnother = (e) => {
    const { dispatch } = this.props
    this.addAnother = true
    dispatch(actions.submit('edit_hostintro'))
  }

  imageUploaded(uploadResult) {
    const { dispatch } = this.props
    if (uploadResult.filesUploaded && uploadResult.filesUploaded.length > 0) {
      const file = uploadResult.filesUploaded[0]
      const filestackUrl = file.url
      fixRotation(filestackUrl).then((data) => {
        if (data.url && data.url.length > 0) {
          dispatch(actions.change('edit_hostintro.image', data.url))
        }
      })
    }
  }

  removeImage = () => {
    const { dispatch } = this.props
    dispatch(actions.change('edit_hostintro.image', null))
  }

  backgroundImageUploaded = (uploadResult) => {
    const { dispatch } = this.props
    if (uploadResult.filesUploaded && uploadResult.filesUploaded.length > 0) {
      const file = uploadResult.filesUploaded[0]
      const filestackUrl = file.url
      fixRotation(filestackUrl).then((data) => {
        if (data.url && data.url.length > 0) {
          dispatch(
            actions.change('edit_hostintro.landing_background_image', data.url)
          )
        }
      })
    }
  }

  removeBackgroundImage = () => {
    const { dispatch } = this.props
    dispatch(actions.change('edit_hostintro.landing_background_image', null))
  }

  logoImageUploaded = (uploadResult) => {
    const { dispatch } = this.props
    if (uploadResult.filesUploaded && uploadResult.filesUploaded.length > 0) {
      const file = uploadResult.filesUploaded[0]
      const filestackUrl = file.url
      fixRotation(filestackUrl).then((data) => {
        if (data.url && data.url.length > 0) {
          dispatch(
            actions.change('edit_hostintro.landing_logo_image', data.url)
          )
        }
      })
    }
  }

  removeLogoImage = () => {
    const { dispatch } = this.props
    dispatch(actions.change('edit_hostintro.landing_logo_image', null))
  }

  hostPhoneChange(value, idx) {
    const { dispatch } = this.props
    dispatch(actions.change('edit_hostintro.host_phone', [value]))
  }

  handleShowContactChange = (checked) => {
    const { dispatch } = this.props
    this.setState({ showContactChecked: checked })
    dispatch(actions.change('edit_hostintro.landing_show_contact', checked))
  }

  handleShowAddressChange = (checked) => {
    const { dispatch } = this.props
    this.setState({ showAddressChecked: checked })
    dispatch(actions.change('edit_hostintro.landing_show_address', checked))
  }

  handleLandingHeaderTextColorChange = (color) => {
    this.props.dispatch(
      actions.change(`edit_hostintro.landing_header_text_color`, color.hex)
    )
  }

  handleLandingButtonColorChange = (color) => {
    this.props.dispatch(
      actions.change(`edit_hostintro.landing_button_color`, color.hex)
    )
  }

  componentWillUnmount() {
    //clear redux state for next item
    this.props.dispatch(actions.reset('edit_hostintro'))
  }

  render() {
    const self = this
    const { edit_hostintro, itemId, copy, guidebookId, ownerPluralName } =
      this.props
    const locales = edit_hostintro.locales
    const image = edit_hostintro.image
    const title = copy
      ? 'Copy host card'
      : itemId === 'create'
        ? 'New host card'
        : 'Edit host card'
    const nav = (
      <TooltipButton
        key="nav"
        onClick={self.goBack}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>keyboard_backspace</FontIcon>
      </TooltipButton>
    )
    const actions = []
    const formErrors = []
    const errorMessages = {
      formwide: {
        valid: 'Oops! Unable to save:'
      },
      label: {
        required: 'Please provide a label'
      },
      host_name: {
        required: 'Please provide the name of the host'
      },
      host_email: {
        valid: 'Please provide a valid email address'
      },
      host_intro: {
        required: 'Please provide an Introduction'
      }
    }
    formErrors['formwide'] = (
      <Errors
        model="edit_hostintro"
        messages={errorMessages.formwide}
        wrapper="h2"
        className="md-text-field-message title hf-error"
        show={(form) => form.submitFailed}
      />
    )
    formErrors['label'] = (
      <Errors
        model=".label"
        messages={errorMessages.label}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['host_name'] = (
      <Errors
        model=".host_name"
        messages={errorMessages.host_name}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['host_email'] = (
      <Errors
        model=".host_email"
        messages={errorMessages.host_email}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['host_intro'] = (
      <Errors
        model=".host_intro"
        messages={errorMessages.host_intro}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )

    const user = currentUser()
    if (edit_hostintro.id && user.canCopy && !copy && !guidebookId) {
      actions.push(
        <TooltipButton
          key="copy"
          onClick={() => {
            self.copyCard(edit_hostintro.id)
          }}
          tooltipLabel="Copy"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>content_copy</FontIcon>
        </TooltipButton>
      )
    }
    const host_phone = edit_hostintro.host_phone || ['']
    const filestackOptions = {
      accept: 'image/*',
      maxFiles: 1,
      fromSources: ['local_file_system', 'url', 'imagesearch'],
      storeTo: {
        location: 'gcs'
      },
      imageMax: [600, 600],
      transformations: {
        crop: {
          aspectRatio: 1 / 1,
          force: true
        },
        rotate: true
      }
    }

    const filestackKey = process.env.REACT_APP_FILESTACK_KEY
    const preview_image = image ? (
      <Avatar
        src={image}
        role="presentation"
        className="hf-hostintroslist-item"
      />
    ) : (
      <Avatar
        icon={<FontIcon className="hf-icon-color">face</FontIcon>}
        className="hf-hostintroslist-item"
      />
    )

    const removeStyle = { marginLeft: '12px' }
    const removeButton = image ? (
      <Button style={removeStyle} onClick={this.removeImage} raised secondary>
        Remove Image
      </Button>
    ) : null

    const phone_numbers = host_phone.map(function (ph, idx) {
      const value = host_phone[idx]
      return (
        <TextField
          key={idx}
          id="host_phone"
          label="Host phone"
          value={value}
          onChange={(e) => {
            self.hostPhoneChange(e, idx)
          }}
        />
      )
    })

    const topMargin = { marginTop: '12px' }
    const largeTopMargin = { marginTop: '24px' }
    const landing_header_text_color =
      edit_hostintro.landing_header_text_color || '#03a9f4'
    const landing_button_color =
      edit_hostintro.landing_button_color || '#40CAA1'

    const background = edit_hostintro.landing_background_image
    const backgroundStyle = { height: 'auto', width: '100%' }
    const preview_bg = background ? (
      <img src={background} alt="" style={backgroundStyle} />
    ) : (
      <img
        src="https://storage.googleapis.com/hostfully-wp-1/blank-state-images.png"
        alt=""
      />
    )
    const removeBackgroundButton = background ? (
      <Button
        style={removeStyle}
        onClick={this.removeBackgroundImage}
        raised
        secondary
      >
        Remove Image
      </Button>
    ) : null

    const logo = edit_hostintro.landing_logo_image
    const logoStyle = { height: '100%', maxHeight: '110px', width: 'auto' }
    const preview_logo = logo ? (
      <img src={logo} alt="" style={logoStyle} />
    ) : (
      <img
        src="https://storage.googleapis.com/hostfully-wp-1/blank_state_logo.png"
        alt=""
        style={logoStyle}
      />
    )
    const removeLogoButton = logo ? (
      <Button
        style={removeStyle}
        onClick={this.removeLogoImage}
        raised
        secondary
      >
        Remove Image
      </Button>
    ) : null

    const bgFilestackOptions = {
      accept: 'image/*',
      maxFiles: 1,
      fromSources: ['local_file_system', 'url', 'imagesearch'],
      storeTo: {
        location: 'gcs'
      },
      imageMax: [1920, 1440],
      transformations: {
        crop: {
          aspectRatio: 4 / 3,
          force: false
        },
        rotate: true
      }
    }

    const logoFilestackOptions = {
      accept: 'image/*',
      maxFiles: 1,
      fromSources: ['local_file_system', 'url', 'imagesearch'],
      storeTo: {
        location: 'gcs'
      },
      imageMax: [800, 400],
      transformations: {
        rotate: true
      }
    }

    const landingPageFields = edit_hostintro.show_landing
      ? [
          <div className="md-cell md-cell--12" key="splash_email">
            <Checkbox
              id="landing_show_contact"
              name="landing_show_contact"
              label="Show contact information on Landing Page"
              className="hf-taller-selection-control-container hf-wider-selection-control-container"
              onChange={this.handleShowContactChange}
              checked={this.state.showContactChecked}
            />
          </div>,
          <div className="md-cell md-cell--12" key="splash_email_required">
            <Checkbox
              id="landing_show_address"
              name="landing_show_address"
              label="Show address on Landing Page"
              className="hf-taller-selection-control-container hf-wider-selection-control-container"
              onChange={this.handleShowAddressChange}
              checked={this.state.showAddressChecked}
            />
          </div>,
          <div
            className="md-cell md-cell--12"
            key="landing_header_text"
            style={topMargin}
          >
            <h3>Landing Page Header (defaults to guidebook name)</h3>
            <MultilangWysiwyg
              simpleEditor
              model="edit_hostintro"
              field="landing_header_text"
              txn_field="landing_header_text_txn"
              locales={locales}
            />
          </div>,
          <div
            className="md-cell md-cell--12"
            key="landing_subheader_text"
            style={topMargin}
          >
            <h3 className="yes-wrap">Landing Page Subheader (optional)</h3>
            <MultilangWysiwyg
              simpleEditor
              model="edit_hostintro"
              field="landing_subheader_text"
              txn_field="landing_subheader_text_txn"
              locales={locales}
            />
          </div>,
          <div
            className="md-cell md-cell--5 md-cell--3-tablet hf-headline-margin"
            key="landing_header_text_color"
            style={largeTopMargin}
          >
            <h3 className="yes-wrap">Text Color for landing page text</h3>
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--8">
                <ChromePicker
                  color={landing_header_text_color}
                  onChangeComplete={this.handleLandingHeaderTextColorChange}
                  disableAlpha
                />
              </div>
            </div>
          </div>,
          <div
            className="md-cell md-cell--5 md-cell--1-desktop-offset md-cell--3-tablet md-cell--1-tablet-offset hf-headline-margin"
            key="landing_button_color"
            style={largeTopMargin}
          >
            <h3 className="yes-wrap">Text Color for landing page CTA button</h3>
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--8">
                <ChromePicker
                  color={landing_button_color}
                  onChangeComplete={this.handleLandingButtonColorChange}
                  disableAlpha
                />
              </div>
            </div>
          </div>,
          <div
            className="md-cell md-cell--5 md-cell--3-tablet hf-headline-margin"
            key="landing_background_image"
            style={largeTopMargin}
          >
            <h3 className="yes-wrap">Upload a background image</h3>
            <p>Not auto-cropped, but scaled down to max of 1920 x 1440</p>
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--12">
                <PickerOverlay
                  apikey={filestackKey}
                  componentDisplayMode={{
                    type: 'button',
                    customText: 'Select Image',
                    customClass:
                      'md-inline-block md-btn md-btn--raised md-background--primary md-background--primary-hover md-pointer--hover md-btn--text md-btn--raised-pressed'
                  }}
                  actionOptions={bgFilestackOptions}
                  onSuccess={this.backgroundImageUploaded}
                />
                {removeBackgroundButton}
              </div>
              <div className="md-cell md-cell--12" style={topMargin}>
                {preview_bg}
              </div>
            </div>
          </div>,
          <div
            className="md-cell md-cell--5 md-cell--1-desktop-offset md-cell--3-tablet md-cell--1-tablet-offset hf-headline-margin"
            key="landing_logo_image"
            style={largeTopMargin}
          >
            <h3 className="yes-wrap">Upload a logo image</h3>
            <p>Not auto-cropped, but scaled down to max of 800 x 400</p>
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--12">
                <PickerOverlay
                  apikey={filestackKey}
                  componentDisplayMode={{
                    type: 'button',
                    customText: 'Select Image',
                    customClass:
                      'md-inline-block md-btn md-btn--raised md-background--primary md-background--primary-hover md-pointer--hover md-btn--text md-btn--raised-pressed'
                  }}
                  actionOptions={logoFilestackOptions}
                  onSuccess={this.logoImageUploaded}
                />
                {removeLogoButton}
              </div>
              <div className="md-cell md-cell--12" style={topMargin}>
                {preview_logo}
              </div>
            </div>
          </div>
        ]
      : null

    const validators = {
      '': {
        // Form-level validator
        valid: () => this.formHasErrors(formErrors)
      },
      label: {
        required: (val) => val && val.length
      },
      host_name: {
        required: (val) => val && val.length
      },
      host_email: {
        valid: (val) => !val || (val.length && /^.+@.+\..+$/.test(val))
      },
      host_intro: {
        required: (val) => val && val.length
      }
    }

    const availableVariables = user.canUseSecurity
      ? prefixFields(tokenFields, 'token')
      : []
    const welcomePrompt =
      user.canUseSecurity && edit_hostintro.host_intro_has_secure_info
        ? 'Enter your public welcome message here:'
        : 'How would this host introduce themselves, and your business, to your guests?'

    return (
      <DocumentTitle title="Host Intro">
        <div className="hf-hostintros-paper">
          <Toolbar
            colored
            className="hf-edit-toolbar hf-hostintros"
            title={title}
            nav={nav}
            actions={actions}
          />
          <Paper key="category" className="hf-form-wrapper">
            <Form
              model="edit_hostintro"
              onSubmit={(v) => this.handleSubmit(v)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  return this.handleEnter(e)
                }
              }}
              validators={validators}
            >
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {this.formatMessage(hostUIMessages['nameHeading'])}
                  </h2>
                  <InputTextField
                    model=".label"
                    id="label"
                    label="Card name *"
                  />
                  {formErrors['label']}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {this.formatMessage(hostUIMessages['languageHeading'])}
                  </h2>
                  <p className="md-body-1">
                    {this.formatMessage(hostUIMessages['languageSubHeading'])}
                  </p>
                  <LocaleForm editModel="edit_hostintro" />
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    What name should guests use to address this host?
                  </h2>
                  <InputTextField
                    model=".host_name"
                    id="host_name"
                    label="Host name *"
                  />
                  {formErrors['host_name']}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    How should guests contact this host if they need to?
                  </h2>
                  <div className="md-grid md-grid--no-spacing">
                    <div className="md-cell md-cell--6">{phone_numbers}</div>
                    <div className="md-cell md-cell--6">
                      <InputTextField
                        model=".host_email"
                        id="host_email"
                        label="Host email"
                      />
                      {formErrors['host_email']}
                    </div>
                  </div>
                </div>
              </div>
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    Would you like to upload an image of this host to use as an
                    avatar?
                  </h2>
                  <p className="md-body-1">
                    Avatars are designed to share a visual of a person's face to
                    help guests feel more connected to that person. While you
                    can use a logo here if you choose it may not work well in
                    the space provided because it is designed for a face
                  </p>
                  <div className="md-grid">
                    <div className="md-cell md-cell--2">{preview_image}</div>
                    <div className="md-cell md-cell--4">
                      <PickerOverlay
                        apikey={filestackKey}
                        componentDisplayMode={{
                          type: 'button',
                          customText: 'Select Image',
                          customClass:
                            'md-inline-block md-btn md-btn--raised md-background--primary md-background--primary-hover md-pointer--hover md-btn--text md-btn--raised-pressed'
                        }}
                        actionOptions={filestackOptions}
                        onSuccess={this.imageUploaded}
                      />
                      {removeButton}
                    </div>
                  </div>
                </div>
              </div>
              <div className="md-grid">
                {user.canUseSecurity ? (
                  <div className="md-cell md-cell--12 hf-headline-margin">
                    <h2 className="md-headline">
                      Do you want to show a separate welcome message for guests
                      viewing the guidebook securely?
                    </h2>
                    <p>
                      If you choose yes, you can define a separate message for
                      guests who are viewing the guidebook with a secure link.
                    </p>
                    <div className="md-grid md-grid--no-spacing">
                      <div className="md-cell md-cell--1 md-cell--1-phone">
                        <p className="md-text md-text-right hf-switch-left">
                          No
                        </p>
                      </div>
                      <div className="md-cell md-cell--11 md-cell--7-tablet md-cell--3-phone">
                        <Switch
                          id="host_intro_has_secure_info"
                          name="host_intro_has_secure_info"
                          checked={edit_hostintro.host_intro_has_secure_info}
                          onChange={this.handleIntroHasSecure}
                          label="Yes"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                {user.canUseSecurity &&
                edit_hostintro.host_intro_has_secure_info ? (
                  <div className="md-cell md-cell--12 hf-headline-margin">
                    <h2 className="md-headline">
                      Enter your secure welcome message here:
                    </h2>
                    <p className="md-body-1">
                      Guests viewing your guidebook with a secure link will see
                      this message, while guests viewing your guidebook with the
                      normal link will see the public message (next section
                      below this one). &nbsp;
                      <a
                        className="hf-grey-link"
                        target="_blank"
                        href="/host/tokens"
                      >
                        You can create and edit secure links here.
                      </a>
                    </p>
                    <MultilangWysiwyg
                      model="edit_hostintro"
                      field="host_intro_secure"
                      txn_field="host_intro_secure_txn"
                      locales={locales}
                      availableVariables={availableVariables}
                    />
                  </div>
                ) : null}
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">{welcomePrompt}</h2>
                  <p>
                    Do not include any secure information in this field, as this
                    info will be seen by guests who are <strong>NOT</strong>{' '}
                    viewing the guidebook securely.
                  </p>
                  <MultilangWysiwyg
                    model="edit_hostintro"
                    field="host_intro"
                    txn_field="host_intro_txn"
                    locales={locales}
                  />
                  {formErrors['host_intro']}
                </div>
              </div>
              <div className="md-cell md-cell--12 hf-headline-margin">
                <h2 className="md-headline">
                  Do you want new visitors to see a custom landing page?
                </h2>
                <Card className="hf-wider-selection-control-container">
                  <CardTitle
                    avatar={
                      <Avatar
                        icon={<FontIcon>thumbs_up_down</FontIcon>}
                        alt=""
                        suffix="hfprimaryavatar"
                      />
                    }
                    title="Create a landing page"
                  />
                  <CardText>
                    <div className="md-grid md-grid--no-spacing">
                      <div className="md-cell md-cell--12">
                        <CheckBoxForm
                          id="show_landing"
                          name="show_landing"
                          model="edit_hostintro.show_landing"
                          label="Show landing page to new visitors"
                          className="hf-taller-selection-control-container hf-wider-selection-control-container"
                        />
                      </div>
                      {landingPageFields}
                    </div>
                  </CardText>
                </Card>
              </div>
              <VoiceForm editModel="edit_hostintro" locales={locales} />
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  {user.isEnterprise ? (
                    <ManageGuidebooksAndTemplates
                      ownerPluralName="hostintros"
                      ownerSingularName="hostintro"
                      guidebookId={guidebookId}
                      cardType="Host Intro"
                      activeTab={ownerPluralName}
                      replaceWarning={true}
                      active={true}
                    />
                  ) : (
                    <ManageSelectedGuidebooks
                      ownerPluralName="hostintros"
                      ownerSingularName="hostintro"
                      guidebookId={guidebookId}
                      cardType="Host Intro"
                      replaceWarning={true}
                      active={true}
                    />
                  )}
                </div>
              </div>
              <div className="md-grid hf-headline-margin">
                <div className="md-cell md-cell--12">
                  <div>{formErrors['formwide']}</div>
                  <div>{formErrors['label']}</div>
                  <div>{formErrors['host_name']}</div>
                  <div>{formErrors['host_email']}</div>
                  <div>{formErrors['host_intro']}</div>
                </div>
              </div>
              <FormCardActions
                guidebookId={guidebookId}
                saveAndAdd={this.saveAndAddAnother}
              />
            </Form>
          </Paper>
        </div>
      </DocumentTitle>
    )
  }
}

HostIntroForm.propTypes = {
  ownerSingularName: PropTypes.string,
  ownerPluralName: PropTypes.string
}

function mapStateToProps(state, props) {
  const { ownerSingularName } = props
  const edit_hostintro = state.edit_hostintro
  const owner_data = state[`edit_${ownerSingularName}`]
  const edit_form = state.forms.edit_hostintro
  return {
    edit_hostintro,
    owner_data,
    edit_form
  }
}

export default connect(mapStateToProps)(HostIntroForm)
