import React from 'react'

const Lock = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`lock`}</title>
    <path d="M15.094 8.016V6c0-1.688-1.406-3.094-3.094-3.094S8.906 4.312 8.906 6v2.016h6.188zm-3.094 9c1.078 0 2.016-.938 2.016-2.016s-.938-2.016-2.016-2.016-2.016.938-2.016 2.016.938 2.016 2.016 2.016zm6-9c1.078 0 2.016.891 2.016 1.969v10.031c0 1.078-.938 1.969-2.016 1.969H6c-1.078 0-2.016-.891-2.016-1.969V9.985c0-1.078.938-1.969 2.016-1.969h.984V6C6.984 3.234 9.234.984 12 .984S17.016 3.234 17.016 6v2.016H18z" />
  </svg>
)

export default Lock
