import { getToken } from 'utils/Auth'

import 'whatwg-fetch'

export function buildItinerary(request) {
  const path = '/api/v1/guidebooks/itinerary'
  const method = 'POST'
  const url = process.env.REACT_APP_API_URL + path
  const body = request
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
}

export function fetchAiLog(key) {
  const path = `/api/v1/ailogs/key/${key}`
  const method = 'GET'
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
}
