import React from 'react'

const Share = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`share`}</title>
    <path d="M18 16.078c1.594 0 2.906 1.313 2.906 2.906S19.593 21.937 18 21.937s-2.906-1.359-2.906-2.953c0-.234 0-.469.047-.656l-7.078-4.125A3.02 3.02 0 013 12a3.02 3.02 0 015.063-2.203l7.031-4.078A3.608 3.608 0 0115 5.016c0-1.641 1.359-3 3-3s3 1.359 3 3-1.359 3-3 3c-.797 0-1.5-.328-2.063-.844l-7.031 4.125c.047.234.094.469.094.703s-.047.469-.094.703l7.125 4.125a2.934 2.934 0 011.969-.75z" />
  </svg>
)

export default Share
