import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router'

import { filterCRUDAccess, filterPageAccess } from 'constants/UIConstants'
import SalesforceExports from 'pages/Admin/pages/Exports'
import IconViewer from 'pages/Admin/pages/Icons'
import SlugBlacklist from 'pages/Admin/pages/SlugBlacklist'
import HostStats from 'pages/Admin/pages/Stats'
import currentUser from 'utils/CurrentUser'

import ItemEditor from './components/ItemEditor'
import ItemList from './components/ItemList'
import {
  CategoryForm,
  CheckinForm,
  CheckoutForm,
  DirectionsForm,
  DomainForm,
  GuidebookEditor,
  HostIntroForm,
  InformationForm,
  ParkingForm,
  RecommendationForm,
  ThemeForm,
  TokenForm,
  UserSettingForm,
  WifiForm
} from './components/ModelForms'
import {
  CategoryListItem,
  CheckinListItem,
  CheckoutListItem,
  DirectionsListItem,
  DomainListItem,
  GuidebookListItem,
  HostIntroListItem,
  InformationListItem,
  ParkingListItem,
  RecommendationListItem,
  ThemeListItem,
  TokenListItem,
  UserSettingListItem,
  WifiListItem
} from './components/ModelItemRenderers'
import HostAccount from './pages/Account'
import BookingSyncRental from './pages/BookingSyncRental'
import BookingSyncRentals from './pages/BookingSyncRentals'
import MarketplaceDashboard from './pages/MarketplaceDashboard'
import MarketplaceItem from './pages/MarketplaceItem'
import MarketplaceProvider from './pages/MarketplaceProvider'
import OrbirentalProperties from './pages/OrbirentalProperties'
import OrbirentalProperty from './pages/OrbirentalProperty'
import Print from './pages/Print'
import ReferralProgram from './pages/ReferralProgram'
import ReorderCategories from './pages/ReorderCategories'
import GuestTerms from './pages/Reports/GuestTerms'

class HostRoutes extends Component {
  constructor(props) {
    super(props)
    const self = this
    this.lists = {
      categories: {
        title: 'Categories',
        pluralName: 'categories',
        singularName: 'category',
        defaultSort: 'order',
        defaultSortDirection: 'asc',
        filterFields: ['name', 'type'],
        editComponent: CategoryForm,
        listComponent: CategoryListItem,
        copyFunction: (item) => {
          delete item.id
          item.name = ''
          return item
        }
      },
      checkins: {
        title: 'Check-ins',
        pluralName: 'checkins',
        singularName: 'checkin',
        defaultSort: 'label',
        defaultSortDirection: 'asc',
        filterFields: [
          'label',
          'checkin_time',
          'checkin_end_time',
          'checkin_text'
        ],
        editComponent: CheckinForm,
        listComponent: CheckinListItem,
        copyFunction: (item) => {
          delete item.id
          item.label = ''
          item = self.removeGBAssociations(item)
          return item
        }
      },
      checkouts: {
        title: 'Checkouts',
        pluralName: 'checkouts',
        singularName: 'checkout',
        defaultSort: 'label',
        defaultSortDirection: 'asc',
        filterFields: ['label', 'checkout_time', 'checkout_text'],
        editComponent: CheckoutForm,
        listComponent: CheckoutListItem,
        copyFunction: (item) => {
          delete item.id
          item.label = ''
          item = self.removeGBAssociations(item)
          return item
        }
      },
      destinations: {
        title: 'Destinations',
        pluralName: 'destinations',
        singularName: 'destination',
        defaultSort: 'name',
        defaultSortDirection: 'asc',
        filterFields: ['name'],
        editComponent: null,
        listComponent: null,
        copyFunction: () => {}
      },
      directions: {
        title: 'Directions',
        pluralName: 'directions',
        singularName: 'directions',
        defaultSort: 'label',
        defaultSortDirection: 'asc',
        filterFields: [
          'label',
          'additional_directions',
          'airport_text',
          'taxi_text',
          'uber_text',
          'lyft_text',
          'bus_text',
          'train_text',
          'pickup_text',
          'driving_text'
        ],
        editComponent: DirectionsForm,
        listComponent: DirectionsListItem,
        copyFunction: (item) => {
          delete item.id
          item.label = ''
          item = self.removeGBAssociations(item)
          return item
        }
      },
      domains: {
        title: 'Domains',
        pluralName: 'domains',
        singularName: 'domain',
        defaultSort: 'domain',
        defaultSortDirection: 'asc',
        filterFields: ['domain', 'status'],
        editComponent: DomainForm,
        listComponent: DomainListItem,
        copyFunction: () => {}
      },
      guidebooks: {
        title: 'Guidebooks',
        pluralName: 'guidebooks',
        singularName: 'guidebook',
        defaultSort: 'name',
        defaultSortDirection: 'asc',
        filterFields: [
          'name',
          'key',
          'address.formatted_address',
          'address.street',
          'address.locality',
          'address.state',
          'address.post_code',
          'address.country_code'
        ],
        editComponent: GuidebookEditor,
        listComponent: GuidebookListItem,
        copyFunction: (item) => {
          delete item.id
          delete item.recs_only_key
          delete item.key
          item.name = 'Copy of ' + item.name
          item.label = ''
          item.is_published = false
          delete item.address.id
          return item
        }
      },
      hostintros: {
        title: 'Hosts',
        pluralName: 'hostintros',
        singularName: 'hostintro',
        defaultSort: 'label',
        defaultSortDirection: 'asc',
        filterFields: ['label', 'host_name', 'host_email', 'host_intro'],
        editComponent: HostIntroForm,
        listComponent: HostIntroListItem,
        copyFunction: (item) => {
          delete item.id
          item.label = ''
          item = self.removeGBAssociations(item)
          return item
        }
      },
      informations: {
        title: 'House manual',
        pluralName: 'informations',
        singularName: 'information',
        defaultSort: 'name',
        defaultSortDirection: 'asc',
        filterFields: ['name', 'title', 'content', 'category.name'],
        editComponent: InformationForm,
        listComponent: InformationListItem,
        copyFunction: (item) => {
          delete item.id
          item.name = ''
          item = self.removeGBAssociations(item)
          return item
        }
      },
      parkings: {
        title: 'Parking',
        pluralName: 'parkings',
        singularName: 'parking',
        defaultSort: 'label',
        defaultSortDirection: 'asc',
        filterFields: ['label', 'parking_text'],
        editComponent: ParkingForm,
        listComponent: ParkingListItem,
        copyFunction: (item) => {
          delete item.id
          item.label = ''
          item = self.removeGBAssociations(item)
          return item
        }
      },
      recommendations: {
        title: 'Recommendations',
        pluralName: 'recommendations',
        singularName: 'recommendation',
        defaultSort: 'name',
        defaultSortDirection: 'asc',
        filterFields: [
          'name',
          'key',
          'why_recommended',
          'content',
          'address.formatted_address',
          'address.street',
          'address.locality',
          'address.state',
          'address.post_code',
          'address.country_code',
          'category.name',
          'batch_ref',
          'sponsor_source',
          'sponsor_ref'
        ],
        editComponent: RecommendationForm,
        listComponent: RecommendationListItem,
        copyFunction: (item) => {
          delete item.id
          delete item.key
          item.name = ''
          delete item.address.id
          return item
        }
      },
      templates: {
        title: 'Templates',
        pluralName: 'templates',
        singularName: 'template',
        defaultSort: 'name',
        defaultSortDirection: 'asc',
        filterFields: [
          'name',
          'key',
          'address.formatted_address',
          'address.street',
          'address.locality',
          'address.state',
          'address.post_code',
          'address.country_code'
        ],
        editComponent: GuidebookEditor,
        listComponent: GuidebookListItem,
        copyFunction: (item) => {
          delete item.id
          delete item.key
          item.name = ''
          item.label = ''
          delete item.recs_only_key
          item.recs_only_enabled = false
          delete item.address.id
          item.guidebooks = []
          return item
        }
      },
      themes: {
        title: 'Themes',
        pluralName: 'themes',
        singularName: 'theme',
        defaultSort: 'name',
        defaultSortDirection: 'asc',
        filterFields: ['name', 'website'],
        editComponent: ThemeForm,
        listComponent: ThemeListItem,
        copyFunction: (item) => {
          delete item.id
          item.name = ''
          item = self.removeGBAssociations(item)
          return item
        }
      },
      tokens: {
        title: 'Secure Links',
        pluralName: 'tokens',
        singularName: 'token',
        defaultSort: 'end_date',
        defaultSortDirection: 'desc',
        filterFields: [
          'label',
          'token',
          'guidebook.key',
          'guidebook.name',
          'data.guest_first',
          'data.guest_last',
          'data.checkin_date',
          'data.checkout_date',
          'data.access_code'
        ],
        editComponent: TokenForm,
        listComponent: TokenListItem,
        copyFunction: (item) => {
          delete item.id
          delete item.token
          delete item.guidebook_id
          item.label = 'Copy of ' + item.label
          return item
        }
      },
      user_settings: {
        title: 'User Settings',
        pluralName: 'user_settings',
        singularName: 'user_setting',
        defaultSort: 'inserted_at',
        defaultSortDirection: 'desc',
        filterFields: ['viator_partner_id'],
        editComponent: UserSettingForm,
        listComponent: UserSettingListItem,
        copyFunction: () => {}
      },
      wifis: {
        title: 'Wifi',
        pluralName: 'wifis',
        singularName: 'wifi',
        defaultSort: 'label',
        defaultSortDirection: 'asc',
        filterFields: [
          'label',
          'network_name',
          'wifi_password',
          'external_network_name',
          'login_offline_text',
          'reset_text',
          'cafe_address'
        ],
        editComponent: WifiForm,
        listComponent: WifiListItem,
        copyFunction: (item) => {
          delete item.id
          item.label = ''
          item = self.removeGBAssociations(item)
          return item
        }
      }
    }
  }

  removeGBAssociations = (item) => {
    item.guidebooks = []
    item.templates = []
    return item
  }

  render() {
    // get current user
    const user = currentUser()
    return (
      <Switch>
        <Route
          exact
          path="/admin/stats"
          render={({ match }) => {
            if (user && user.isAdmin) {
              return <HostStats />
            } else {
              return <Redirect to="/403" />
            }
          }}
        />
        <Route
          exact
          path="/admin/exports"
          render={({ match }) => {
            if (user && user.isAdmin) {
              return <SalesforceExports />
            } else {
              return <Redirect to="/403" />
            }
          }}
        />
        <Route
          exact
          path="/admin/icons"
          render={({ match }) => {
            if (user && user.isAdmin) {
              return <IconViewer />
            } else {
              return <Redirect to="/403" />
            }
          }}
        />
        <Route
          exact
          path="/admin/slugblacklist"
          render={({ match }) => {
            if (user && user.isAdmin) {
              return <SlugBlacklist />
            } else {
              return <Redirect to="/403" />
            }
          }}
        />
        <Route exact path="/host/reports/terms" component={GuestTerms} />
        <Route exact path="/host/account" component={HostAccount} />
        <Route exact path="/host/referrals" component={ReferralProgram} />
        <Route
          exact
          path="/host/categories/reorder"
          component={ReorderCategories}
        />
        <Route
          exact
          path="/host/bookingsyncrentals/:itemId"
          render={({ match }) => {
            const { itemId } = match.params
            return <BookingSyncRental itemId={itemId} />
          }}
        />
        <Route
          exact
          path="/host/bookingsyncrentals"
          component={BookingSyncRentals}
        />
        <Route
          exact
          path="/host/orbirentalproperties/:itemId/:gbId"
          render={({ match }) => {
            const { itemId, gbId } = match.params
            return <OrbirentalProperty itemId={itemId} gbId={gbId} />
          }}
        />
        <Route
          exact
          path="/host/orbirentalproperties/:itemId"
          render={({ match }) => {
            const { itemId } = match.params
            return <OrbirentalProperty itemId={itemId} />
          }}
        />
        <Route
          exact
          path="/host/orbirentalproperties"
          component={OrbirentalProperties}
        />
        <Route
          exact
          path="/host/guidebooks/:guidebookKey/print"
          render={({ match }) => {
            const { guidebookKey } = match.params
            return <Print guidebookKey={guidebookKey} />
          }}
        />
        <Route
          exact
          path="/host/marketplaceProviders/:providerUid"
          render={({ match }) => {
            const { providerUid } = match.params
            return <MarketplaceProvider uid={providerUid} />
          }}
        />
        <Route
          exact
          path="/host/marketplaceItems/:itemUid"
          render={({ match }) => {
            const { itemUid } = match.params
            return <MarketplaceItem uid={itemUid} />
          }}
        />
        <Route
          exact
          path="/host/marketplace/stripeConnect"
          component={MarketplaceDashboard}
        />
        <Route
          exact
          path="/host/marketplace"
          render={() => {
            return <MarketplaceDashboard />
          }}
        />
        <Route
          exact
          path="/host/settings"
          render={() => {
            const type = 'user_settings'
            const itemId = 'create'
            const cfg = this.lists[type]
            if (!filterPageAccess(type, user)) {
              return <Redirect to="/403" />
            }
            if (cfg) {
              return (
                <ItemEditor
                  key={itemId}
                  pluralName={cfg.pluralName}
                  singularName={cfg.singularName}
                  form={cfg.editComponent}
                  itemId={itemId}
                  {...this.props}
                />
              )
            } else {
              return <Redirect to="/404" />
            }
          }}
        />

        <Route
          exact
          path="/host/marketplace/providers"
          render={() => {
            return <MarketplaceDashboard initialTab="providers" />
          }}
        />
        <Route
          exact
          path="/host/:ownerType(guidebooks|templates)/:guidebookId/:type/:itemId"
          render={({ match }) => {
            const { type, itemId, guidebookId, ownerType } = match.params
            const ownerCfg = this.lists[ownerType]
            if (!filterCRUDAccess(type, user)) {
              return <Redirect to="/403" />
            }
            if (type === 'marketplaceItems') {
              return (
                <MarketplaceItem
                  uid={itemId}
                  ownerPluralName={ownerCfg.pluralName}
                  ownerSingularName={ownerCfg.singularName}
                  guidebookId={guidebookId}
                />
              )
            }
            const cfg = this.lists[type]
            if (cfg) {
              return (
                <ItemEditor
                  key={guidebookId}
                  pluralName={cfg.pluralName}
                  singularName={cfg.singularName}
                  ownerPluralName={ownerCfg.pluralName}
                  ownerSingularName={ownerCfg.singularName}
                  form={cfg.editComponent}
                  itemId={itemId}
                  guidebookId={guidebookId}
                  {...this.props}
                />
              )
            } else {
              return <Redirect to="/404" />
            }
          }}
        />
        <Route
          exact
          path="/host/templates/guidebook/:itemId"
          render={({ match }) => {
            // this route is when a host creates a template from an existing guidebook
            const { itemId } = match.params
            const cfg = this.lists.templates
            if (!filterCRUDAccess('templates', user)) {
              return <Redirect to="/403" />
            }
            if (cfg) {
              return (
                <ItemEditor
                  key={itemId}
                  pluralName={cfg.pluralName}
                  singularName={cfg.singularName}
                  form={cfg.editComponent}
                  itemId={itemId}
                  createTemplate={true}
                  {...this.props}
                />
              )
            } else {
              return <Redirect to="/404" />
            }
          }}
        />
        <Route
          exact
          path="/host/:type/:itemId"
          render={({ match }) => {
            const { type, itemId } = match.params
            const cfg = this.lists[type]
            if (!filterPageAccess(type, user)) {
              return <Redirect to="/403" />
            }
            if (cfg) {
              return (
                <ItemEditor
                  key={itemId}
                  pluralName={cfg.pluralName}
                  singularName={cfg.singularName}
                  form={cfg.editComponent}
                  itemId={itemId}
                  {...this.props}
                />
              )
            } else {
              return <Redirect to="/404" />
            }
          }}
        />
        <Route
          exact
          path="/host/:type/:itemId/copy"
          render={({ match }) => {
            const { type, itemId } = match.params
            const cfg = this.lists[type]
            if (!user.isEnterprise && type === 'templates') {
              return <Redirect to="/403" />
            }
            if (cfg) {
              return (
                <ItemEditor
                  key={itemId}
                  copy={cfg.copyFunction}
                  pluralName={cfg.pluralName}
                  singularName={cfg.singularName}
                  form={cfg.editComponent}
                  itemId={itemId}
                  {...this.props}
                />
              )
            } else {
              return <Redirect to="/404" />
            }
          }}
        />
        <Route
          exact
          path="/host/:type"
          render={({ match }) => {
            const { type } = match.params
            const cfg = this.lists[type]
            if (cfg) {
              return (
                <ItemList
                  title={cfg.title}
                  pluralName={cfg.pluralName}
                  singularName={cfg.singularName}
                  defaultSort={cfg.defaultSort}
                  defaultSortDirection={cfg.defaultSortDirection}
                  filterFields={cfg.filterFields}
                  rowRenderer={cfg.listComponent}
                />
              )
            } else {
              return <Redirect to="/404" />
            }
          }}
        />
        <Route
          exact
          path="/host"
          component={() => <Redirect to={{ pathname: "/host/guidebooks", search: window.location.search }} /> }
        />
        <Redirect to="/404" />
      </Switch>
    )
  }
}

HostRoutes.propTypes = {}

export default HostRoutes
