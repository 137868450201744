import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { injectTooltip } from 'react-md'

const styles = {
  tooltipContainer: {
    position: 'relative',
    display: 'inline-block'
  }
}

class TooltipSpan extends PureComponent {
  render() {
    const { tooltip, children, ...rest } = this.props

    return (
      <span style={styles.tooltipContainer} {...rest}>
        {tooltip}
        {children}
      </span>
    )
  }
}

TooltipSpan.propTypes = {
  tooltip: PropTypes.node,
  children: PropTypes.node
}

export default injectTooltip(TooltipSpan)
