import React from 'react'
import { Button } from 'react-md'

export default function FormCardActions({ guidebookId, saveAndAdd }) {
  return (
    <div className="hf-sticky">
      <Button type="submit" primary raised>
        Save card
      </Button>
      {!guidebookId && (
        <Button type="button" onClick={saveAndAdd} primary raised>
          Save & Add Another
        </Button>
      )}
    </div>
  )
}
