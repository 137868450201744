import React from 'react'

const Horseshoe = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`horseshoe`}</title>
    <path d="M19.875 19.777h-1.074C20.168 17.18 21 14.086 21 11.445 21 5.613 16.973 2 12 2s-9 3.613-9 9.445c0 2.64.832 5.735 2.2 8.332H4.124c-.621 0-1.125.496-1.125 1.114C3 21.504 3.504 22 4.125 22H7.5c.621 0 1.125-.496 1.125-1.11v-.445c0-.433-.145-.851-.371-1.222-.996-1.618-1.613-3.743-1.613-5.856 0-4.273 2.254-6.922 5.359-6.922s5.36 2.649 5.36 6.922c0 2.113-.618 4.238-1.614 5.856-.226.37-.371.789-.371 1.222v.446c0 .613.504 1.109 1.125 1.109h3.375c.621 0 1.125-.496 1.125-1.11 0-.617-.504-1.113-1.125-1.113zM5.523 13.11h-1.28a13.02 13.02 0 01-.118-1.664c0-.023.004-.047.004-.066l1.543.16a11.83 11.83 0 00-.149 1.57zm1.649-5.37c-.23.308-.442.64-.625.995l-1.797-.77c.46-1.19 1.164-2.206 2.066-2.995l1.38 1.617c-.387.32-.727.71-1.024 1.152zm6.23-2.274a7.317 7.317 0 00-1.394-.13h-.004H12c-.332 0-.652.032-.965.08-.144.015-.293.022-.43.05l-.437-2.156c.316-.07.64-.125.977-.157.28-.023.566-.039.855-.039.164 0 .32.012.477.02.128.008.253.008.378.02.34.03.665.09.989.16zm3.422 2.27a5.371 5.371 0 00-1.012-1.141l1.38-1.617c.898.789 1.601 1.808 2.062 3l-1.797.765a7.316 7.316 0 00-.633-1.008zm1.504 3.804l1.543-.16c0 .02.004.043.004.066 0 .532-.043 1.094-.117 1.664h-1.281a11.83 11.83 0 00-.149-1.57z" />
  </svg>
)

export default Horseshoe
