import React from 'react'
import { Button } from 'react-md'

import Image404 from './sloth_heart.svg'

import ErrorScreen from 'components/ErrorScreen'

export default function NotFound() {
  const goBack = () => {
    window.history.back()
  }

  return (
    <ErrorScreen
      title="We're sorry, we've taken you to a dead end!"
      description="Please make sure you have entered the exact URL for the guidebook you are looking for!"
      image={<img id="sloth-image" src={Image404} alt="sloth" height="256px" />}
      actions={
        <Button
          onClick={goBack}
          className="error-page__button"
          primary
          flat
          swapTheming
        >
          Go Back
        </Button>
      }
    />
  )
}
