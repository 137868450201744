import { getToken } from 'utils/Auth'

import { addToast } from './toast'

export const SET_PREFERENCES = 'SET_PREFERENCES'
export const SET_SECTION = 'SET_SECTION'
export const SET_SETTING = 'SET_SETTING'
export const RESET_PRINT_PREFERENCES_STATE = 'RESET_PRINT_PREFERENCES_STATE'

const initialState = {
  cover: 1,
  color: '#0091ea',
  sections: [
    {
      name: 'welcome',
      selected: true,
      label: 'Welcome',
      icon: 'Face',
      color: '#ff9800',
      group: 'essentials'
    },
    {
      name: 'arrival',
      selected: true,
      label: 'Arrival',
      icon: 'VpnKey',
      color: '#f44336',
      group: 'essentials'
    },
    {
      name: 'house_manual',
      selected: true,
      label: 'House Manual',
      icon: 'Book',
      color: '#ff5722',
      group: 'essentials'
    },
    {
      name: 'departure',
      selected: true,
      label: 'Departure',
      icon: 'Assignment',
      color: '#ff9800',
      group: 'essentials'
    }
  ],
  settings: {
    hideImages: {
      selected: false
    },
    combineMaps: {
      selected: false
    },
    fullRecommendations: {
      selected: false
    },
    shortenRecommendationLinks: {
      selected: true
    },
    printLinkHrefs: {
      selected: true
    },
    showQRCode: {
      selected: true
    }
  },
  font: 'lato',
  guestSections: [
    {
      name: 'welcome',
      selected: true,
      label: 'Welcome',
      icon: 'Face',
      color: '#ff9800',
      group: 'essentials'
    },
    {
      name: 'arrival',
      selected: true,
      label: 'Arrival',
      icon: 'VpnKey',
      color: '#f44336',
      group: 'essentials'
    },
    {
      name: 'house_manual',
      selected: true,
      label: 'House Manual',
      icon: 'Book',
      color: '#ff5722',
      group: 'essentials'
    },
    {
      name: 'departure',
      selected: true,
      label: 'Departure',
      icon: 'Assignment',
      color: '#ff9800',
      group: 'essentials'
    }
  ]
}

/* Reducers */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PREFERENCES:
      return Object.assign({}, state, action.data)
    case SET_SECTION:
      const sections = state.sections.map((section) =>
        section.name === action.data.sectionName
          ? { ...section, selected: !section.selected }
          : section
      )
      return Object.assign({}, state, { sections: sections })
    case SET_SETTING:
      const settings = {}
      Object.entries(state.settings).forEach(([key, value]) => {
        const newValue =
          key === action.data.settingName ? !value.selected : value.selected
        Object.assign(settings, { [key]: { selected: newValue } })
      })
      return Object.assign({}, state, { settings: settings })
    case RESET_PRINT_PREFERENCES_STATE:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

/* Actions */
export function initPrintPreferences(state) {
  return (dispatch, getState) => {
    let { printPreferences, list } = getState()
    const categories = list.categories.data
    const allSections = []
    // get the icons and colors so we can fix legacy saved essentials
    const iconMap = {}
    const colorMap = {}
    for (var h = 0; h < initialState.sections.length; h++) {
      if (initialState.sections[h].group === 'essentials') {
        iconMap[initialState.sections[h].name] = initialState.sections[h].icon
        colorMap[initialState.sections[h].name] = initialState.sections[h].color
      }
    }
    const originalSections = printPreferences.sections
    // keeping track of which sections were checked go through the old sections
    // keep the essentials, and rebuild the recommendations
    const uncheckedRecSections = []
    for (var i = 0; i < originalSections.length; i++) {
      if (originalSections[i].group === 'essentials') {
        const rebuiltSection = originalSections[i]
        rebuiltSection.icon = iconMap[rebuiltSection.name]
        rebuiltSection.color = colorMap[rebuiltSection.name]
        allSections.push(rebuiltSection)
      } else if (
        originalSections[i].group === 'recommendation' &&
        originalSections[i].selected === false
      ) {
        uncheckedRecSections.push(originalSections[i].name)
      }
    }
    // ok now go through the categories and build sections
    for (var j = 0; j < categories.length; j++) {
      const cat = categories[j]
      const cat_key = cat.name.toLowerCase().replace(/ /g, '_')
      // console.log(cat);
      const section = {
        name: cat_key,
        selected: uncheckedRecSections.indexOf(cat_key) === -1 ? true : false,
        label: cat.name.toLowerCase(),
        icon: cat.icon,
        color: cat.color,
        group: 'recommendation'
      }
      allSections.push(section)
    }
    printPreferences.sections = printPreferences.guestSections = allSections

    // add the fullRecommendations setting
    if (typeof printPreferences.settings.fullRecommendations === 'undefined') {
      printPreferences.settings.fullRecommendations = { selected: false }
    }
    if (
      typeof printPreferences.settings.shortenRecommendationLinks ===
      'undefined'
    ) {
      printPreferences.settings.shortenRecommendationLinks = { selected: true }
    }
    if (typeof printPreferences.settings.showQRCode === 'undefined') {
      printPreferences.settings.showQRCode = { selected: true }
    }
    if (typeof printPreferences.settings.printLinkHrefs === 'undefined') {
      printPreferences.settings.printLinkHrefs = { selected: true }
    }

    dispatch(loadPrintPreferences(printPreferences))
  }
}

export function setPrintPreferences() {
  return (dispatch, getState) => {
    const guidebook = getState().guidebook
    const themeId =
      guidebook.data && guidebook.data.theme && guidebook.data.theme.id
        ? guidebook.data.theme.id
        : null
    if (themeId) {
      //get all current printPreferences
      const preferencesToSave = Object.assign({}, getState().printPreferences)
      //delete those we dont want to save
      delete preferencesToSave.data
      const path = '/api/v1/themes/' + themeId + '/print_preferences'
      const method = 'POST'
      const url = process.env.REACT_APP_API_URL + path
      const token = getToken()
      const config = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        body: JSON.stringify({ print_preferences: preferencesToSave })
      }
      fetch(url, config)
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText)
          }
          dispatch(
            addToast("We've saved your choices to the Theme for this Guidebook")
          )
        })
        .catch((e) => {
          dispatch(addToast('Error saving print preferences to Theme'))
        })
    }
  }
}

export function loadPrintPreferences(printPreferences) {
  return {
    type: SET_PREFERENCES,
    data: printPreferences
  }
}

export function loadPrintPreference(printPreference) {
  return {
    type: SET_PREFERENCES,
    data: printPreference
  }
}

export function setPrintSection(selectedSection) {
  return {
    type: SET_SECTION,
    data: selectedSection
  }
}

export function setPrintSetting(selectedSetting) {
  return {
    type: SET_SETTING,
    data: selectedSetting
  }
}

export function setGuestPrintSections(selectedSections) {
  return {
    type: SET_PREFERENCES,
    data: selectedSections
  }
}

export function resetPrintPreferencesState(state) {
  return {
    type: RESET_PRINT_PREFERENCES_STATE,
    state
  }
}
