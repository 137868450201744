import React from 'react'

const ChildCare = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`child_care`}</title>
    <path d="M7.5 14.016h9a4.873 4.873 0 01-9 0zm4.5 4.968a6.94 6.94 0 006.703-5.016c.094 0 .188.047.281.047 1.078 0 2.016-.938 2.016-2.016s-.938-2.016-2.016-2.016c-.094 0-.188.047-.281.047C17.87 7.116 15.188 5.014 12 5.014S6.13 7.115 5.297 10.03c-.094 0-.188-.047-.281-.047-1.078 0-2.016.938-2.016 2.016s.938 2.016 2.016 2.016c.094 0 .188-.047.281-.047A6.941 6.941 0 0012 18.984zm10.922-6.328c-.234 1.5-1.313 2.766-2.766 3.188C18.831 18.615 15.711 21 12 21c-3.698 0-6.821-2.346-8.109-5.156a4.01 4.01 0 01-2.813-3.188c-.047-.188-.094-.422-.094-.656s.047-.469.094-.656a4.006 4.006 0 012.813-3.188C4.407 7.031 5.11 6.047 6.047 5.25A8.924 8.924 0 0112 3a8.949 8.949 0 018.109 5.156 4.01 4.01 0 012.813 3.188c.047.188.094.422.094.656s-.047.469-.094.656zM8.25 10.5c0-.703.563-1.266 1.266-1.266s1.219.563 1.219 1.266-.516 1.266-1.219 1.266S8.25 11.203 8.25 10.5zm5.016 0c0-.703.516-1.266 1.219-1.266s1.266.563 1.266 1.266-.563 1.266-1.266 1.266-1.219-.563-1.219-1.266z" />
  </svg>
)

export default ChildCare
