import { getToken } from 'utils/Auth'

import 'whatwg-fetch'

export function translateText(text, source, target) {
  let url = process.env.REACT_APP_API_URL + `/api/v1/translate`

  let body = {
    text: text,
    source: source,
    target: target
  }

  const token = getToken()
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }

  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.translation
    })
}
