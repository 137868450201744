import React from 'react'

const Courtyard = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`courtyard`}</title>
    <path d="M4.2 7.3C2.4 7.3 1 8.8 1 10.6s1.1 2.9 2.6 3.2v6.5h1.1v-6.5c1.5-.3 2.6-1.7 2.6-3.2S6 7.3 4.2 7.3zM23 15.3L20.6 3.7l-2.1 11.6h1.7v5h1.1v-5H23zM8.6 17h.6v.6h-.6v2.6h.6v-1.5h6.6v1.7h.6v-2.6h-.6v-.6h.6v-2.4H8.6V17zm6.7 0v.6H9.7V17h5.6z" />
  </svg>
)

export default Courtyard
