import React from 'react'

const BusinessCenter = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`business_center`}</title>
    <path d="M14.016 6.984V5.015H9.985v1.969h4.031zm6 0c1.078 0 1.969.938 1.969 2.016v3c0 1.078-.891 2.016-1.969 2.016h-6V12H9.985v2.016h-6c-1.125 0-1.969-.891-1.969-2.016V9c0-1.078.891-2.016 1.969-2.016h3.984V5.015l2.016-2.016h3.984l2.016 2.016v1.969h4.031zm-10.032 9h4.031V15h6.984v3.984A2.001 2.001 0 0118.983 21H5.014a2.001 2.001 0 01-2.016-2.016V15h6.984v.984z" />
  </svg>
)

export default BusinessCenter
