import React from 'react'

const NaturePeople = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`nature_people`}</title>
    <path d="M4.5 11.016c-.844 0-1.5-.656-1.5-1.5s.656-1.5 1.5-1.5 1.5.656 1.5 1.5-.656 1.5-1.5 1.5zm17.672-1.828c0 3.609-2.719 6.516-6.188 6.938v3.891h3v1.969H3v-4.969h-.984v-4.031A.96.96 0 013 12.002h3a.96.96 0 01.984.984v4.031H6v3h8.016v-3.938c-3.328-.563-5.859-3.422-5.859-6.891 0-3.891 3.141-7.031 7.031-7.031s6.984 3.141 6.984 7.031z" />
  </svg>
)

export default NaturePeople
