import React from 'react'

const EditLocation = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`edit_location`}</title>
    <path d="M14.906 7.547a.368.368 0 000-.516l-.938-.938a.368.368 0 00-.516 0l-.703.703 1.453 1.453zM10.453 12l3.328-3.328-1.453-1.453L9 10.547V12h1.453zM12 2.016c3.844 0 6.984 3.141 6.984 6.984 0 5.25-6.984 12.984-6.984 12.984S5.016 14.25 5.016 9c0-3.844 3.141-6.984 6.984-6.984z" />
  </svg>
)

export default EditLocation
