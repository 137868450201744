import React from 'react'

const FishingBobber = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`fishing_bobber`}</title>
    <path d="M11 2v2.063C7.586 4.48 5 6.992 5 10c0 .102.004.215.031.313.18 3.054 2.934 8.46 5.969 9.5V22h2v-2.188c3.035-1.039 5.79-6.445 5.969-9.5.027-.097.031-.21.031-.312 0-3.008-2.586-5.52-6-5.938V2zm-3.625 9.906c2.582.754 6.668.754 9.25 0C15.754 14.676 13.441 18 12 18c-1.441 0-3.754-3.324-4.625-6.094z" />
  </svg>
)

export default FishingBobber
