import React, { Component } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { FontIcon, ListItem } from 'react-md'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'react-router-redux'

import TooltipFontIcon from 'components/TooltipFontIcon'

class ReportNavItem extends Component {
  constructor(props) {
    super(props)
    this.activeRoute = this.props.location.pathname.split('/')[3]
  }

  showHostItem = (tab) => {
    this.props.dispatch(push('/host/reports/' + tab))
  }

  componentDidMount() {
    const handler = this.handleRouteChange.bind(this)

    this.props.history.listen(handler)
  }

  handleRouteChange(location) {
    this.activeRoute = location.pathname.split('/')[3]
    this.forceUpdate()
  }

  render() {
    const nestedItem = (
      <ListItem
        key="terms"
        onClick={() => this.showHostItem('terms')}
        active={'terms' === this.activeRoute}
        leftIcon={
          <FontIcon className="hf-icon-color">assignment_turned_in</FontIcon>
        }
        primaryText="Guest Terms"
      />
    )
    const rIcon = 'description'
    const reportIcon = this.props.showTooltips ? (
      <TooltipFontIcon tooltipLabel="Reports" tooltipPosition="right">
        {rIcon}
      </TooltipFontIcon>
    ) : (
      <FontIcon className="hf-icon-color">{rIcon}</FontIcon>
    )

    return (
      <ListItem
        key="Reports"
        tileClassName="main-navigation-list"
        leftIcon={reportIcon}
        primaryText="Reports"
        onClick={this.props.openNavDrawer}
        active={['terms'].includes(this.activeRoute)}
        nestedItems={this.props.showTooltips ? null : [nestedItem]}
        defaultVisible={!this.props.showTooltips}
      />
    )
  }
}

ReportNavItem.propTypes = {
  intl: intlShape.isRequired
}

export default connect(null)(injectIntl(withRouter(ReportNavItem)))
