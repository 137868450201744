import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import { injectIntl } from 'react-intl'
import LoginWrapper from './LoginWrapper'
import { push } from 'react-router-redux'
import UAParser from 'ua-parser-js'

class LoginContainer extends Component {
  componentWillMount() {
    if (this.props.auth && this.props.auth.isAuthenticated) {
      this.loginRedirect('/host', this.props.auth)
    }
  }
  componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    if (nextProps.auth && nextProps.auth.isAuthenticated) {
      // console.log('here');
      this.loginRedirect('/host', nextProps.auth)
    }
  }

  componentDidMount() {
    // if there isn't already a hf-user-meta cookie, let's grab any utm params and stuff
    const { cookies } = this.props
    const has_utms = this.checkUtm()
    if (
      !cookies.cookies ||
      (cookies.cookies && !cookies.cookies.hasOwnProperty('hf-user-meta')) ||
      has_utms
    ) {
      // console.log('setting meta cookie');
      this.setUserMetaCookie()
    } else if (
      cookies.cookies &&
      cookies.cookies.hasOwnProperty('hf-user-meta') &&
      document.referrer
    ) {
      // if we have an existing cookie, but it's got an empty referrer, and we now have one, fill it
      var cookieData = JSON.parse(atob(cookies.cookies['hf-user-meta']))
      if (!cookieData['hf_mktng_referrer']) {
        cookieData['hf_mktng_referrer'] = document.referrer
        this.saveMetaCookie(cookieData)
      }
    }
  }

  loginRedirect = (fallback, auth) => {
    var redirectfound = false
    if (auth) {
      // see if we have a redirect query in the querystring
      const query = window.location.search.substring(1)
      const switched = auth && auth.user && auth.user.switched
      // don't do this if we've "switched" users
      if (query && !switched) {
        // grab querystring, loop through looking for redirect param
        var vars = query.split('&')
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=')
          if (pair[0] === 'redirect') {
            if (pair[1].charAt(0) === '/') {
              // it's an internal link, just dispatch(push(pair[1]))
              redirectfound = true
              this.props.dispatch(push(pair[1]))
            } else {
              redirectfound = true
              window.location.href = pair[1]
            }
            break
          }
        }
      }
    }
    if (!redirectfound) {
      this.props.dispatch(push(fallback))
    }
  }

  checkUtm = () => {
    var query = window.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      if (pair[0].indexOf('utm_') !== -1) {
        return true
      } else if (pair[0].indexOf('fbclid') !== -1) {
        return true
      }
    }
    return false
  }

  setUserMetaCookie = () => {
    /* define the params we're looking to capture and what we want to store them as */
    var acceptedParams = {
      product_instance_id: 'booking_com_id',
      utm_source: 'hf_mktng_utm_source',
      utm_campaign: 'hf_mktng_utm_campaign',
      utm_medium: 'hf_mktng_utm_medium',
      utm_term: 'hf_mktng_utm_term',
      utm_content: 'hf_mktng_utm_content',
      utm_keyword: 'hf_mktng_utm_keyword',
      fbclid: 'hf_mktng_fbclid'
    }

    var storedData = {}
    var query = window.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      if (acceptedParams.hasOwnProperty(pair[0])) {
        storedData[acceptedParams[pair[0]]] = pair[1]
      }
    }
    // put together our calculated params here -
    storedData['hf_mktng_landing'] = window.location.pathname
    storedData['hf_mktng_referrer'] = document.referrer

    var ua = new UAParser()
    storedData['hf_mktng_platform'] = ua.getOS().name
    storedData['hf_mktng_platform_version'] = ua.getOS().version
    storedData['hf_mktng_browser'] = ua.getBrowser().name
    storedData['hf_mktng_browser_version'] = ua.getBrowser().version
    storedData['hf_mktng_device_type'] = ua.getDevice().type

    this.saveMetaCookie(storedData)
  }

  saveMetaCookie = (data) => {
    // save data to JSON string and save to cookie
    var jsonString = JSON.stringify(data)
    var base64string = btoa(jsonString)
    // don't escape characters since we're saving base64 data and that screws things up
    var timestamp = new Date().getTime()
    var expire = timestamp + 60 * 60 * 24 * 1000 * 90 // 90 days
    var expireDate = new Date(expire)
    var cookieOptions = {
      path: '/',
      expires: expireDate,
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      secure: true
    }
    this.props.cookies.set('hf-user-meta', base64string, cookieOptions)
  }

  render() {
    return <LoginWrapper {...this.props} />
  }
}

LoginContainer.propTypes = {
  dispatch: PropTypes.func
}

function mapStateToProps(state, props) {
  return {
    auth: state.auth
  }
}

export default withCookies(connect(mapStateToProps)(injectIntl(LoginContainer)))
