import 'whatwg-fetch'
import querystring from 'querystring'

export function timezoneFromLatLng(lat, lng) {
  const params = {
    key: 'RCGUOKCTV17G',
    format: 'json',
    by: 'position',
    lat: lat,
    lng: lng
  }
  const url = `https://api.timezonedb.com/v2.1/get-time-zone?${querystring.stringify(params)}`

  return fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
}
