import React from 'react'

const AidKit = (props) => (
  <svg width={24} height={24} viewBox="0 0 192 192" {...props}>
    <title>{`aid-kit`}</title>
    <path d="M152 50h-28V31a9 9 0 00-9-9H78a9 9 0 00-9 9v19H41a18 18 0 00-18 18v73a18 18 0 0018 18h111a18 18 0 0018-18V68a18 18 0 00-18-18zM78 31h37v19H78zm55 83h-27v28H87v-28H60V96h27V68h18v28h28z" />
  </svg>
)

export default AidKit
