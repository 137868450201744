import React from 'react'
import { Media } from 'react-md'

const getContent = (key) => {
  switch (key) {
    case 'guidebooks':
      return {
        title: 'No guidebooks, yet',
        message:
          "Guidebooks are made up of cards which contain your detailed content.  You can get started by creating some cards or creating your first guidebook. It doesn't matter what you start with but if you only have one property we recommend you start by creating a guidebook and adding cards to it as you go.",
        image:
          'https://storage.googleapis.com/hostfully-test/blank-state-cards.png'
      }

    case 'templates':
      return {
        title: 'No templates, yet',
        message:
          "Templates are a set of cards that can be used as a starting point when creating a new guidebook or added to an existing guidebook. If you have more than one property, chances are you'll want to create a template, add common cards to it and then leverage it in your guidebooks.",
        image:
          'https://storage.googleapis.com/v2-static/templates-blank-state.gif'
      }

    case 'images':
      return {
        title: 'No images, yet',
        message:
          "When you add an image, or we find some for your location, you'll see them here.",
        image:
          'https://storage.googleapis.com/hostfully-wp-1/blank-state-images.png'
      }

    case 'cards':
      return {
        title: 'No cards, yet',
        message: 'When you have selected some cards, you can sort them here.',
        image:
          'https://storage.googleapis.com/hostfully-test/blank-state-cards.png'
      }

    default:
      return {
        title: 'No cards, yet',
        message:
          'Cards store information about a specific topic, like wifi, check-in or how to use the BBQ.  You can use cards to store this content and then display it in any guidebook.',
        image:
          'https://storage.googleapis.com/hostfully-test/blank-state-cards.png'
      }
  }
}

export default function EmptyState({ type }) {
  const { title, message, image } = getContent(type)

  return (
    <div className="md-grid" style={{ margin: '24px' }}>
      <div className="md-cell md-cell--6 md-cell--4-tablet md-cell--2-phone md-cell--middle">
        <h6 className="hf-title">{title}</h6>
        <p className="hf-subtitle">{message}</p>
      </div>
      <div className="md-cell md-cell--6 md-cell--4-tablet md-cell--2-phone md-cell--middle">
        <Media aspectRatio="1-1">
          <img src={image} alt="" />
        </Media>
      </div>
    </div>
  )
}
