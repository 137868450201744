import React, { Component } from 'react'
import {
  Button,
  DataTable,
  FontIcon,
  Paper,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'

import { fetchExports, generateExports } from 'redux/modules/exports'

class SalesforceExports extends Component {
  constructor(props) {
    super(props)
    this.state = {
      exportData: [],
      exporting: false,
      exportNum: 25,
      exportCount: 0,
      disableMore: false
    }
  }

  componentDidMount() {
    this.getExportData()
  }

  /**
   * Make api call to get stat data and set to state
   */
  getExportData = () => {
    fetchExports(this.state.exportNum).then((data) => {
      const disableMore = this.state.exportCount === data.length
      this.setState({
        exportData: data,
        exportCount: data.length,
        disableMore: disableMore
      })
    })
  }

  showMore = () => {
    this.setState({ exportNum: this.state.exportNum + 25 }, (newState) => {
      this.getExportData()
    })
  }

  handleExportNow = (full) => {
    const truncateExports = full ? false : true
    this.setState({ exporting: true })
    generateExports(truncateExports).then((data) => {
      this.setState({ exporting: false })
      this.getExportData()
    })
  }

  /**
   * render the appropriate stats report
   */
  renderExports = () => {
    if (!this.state.exportData || this.state.exportData.length === 0) {
      return this.noResults()
    } else {
      const exportRows = this.state.exportData.map((item) => {
        const exportDate = new Date(item.inserted_at)
        const formattedDate = `${exportDate.toLocaleDateString()} ${exportDate.toLocaleTimeString()}`

        return (
          <TableRow key={'export-row-'.concat(item.id)}>
            <TableColumn>{item.type}</TableColumn>
            <TableColumn>
              {item.user_id === 0 ? 'sys' : item.user_id}
            </TableColumn>
            <TableColumn>{formattedDate}</TableColumn>
            <TableColumn>
              <a
                href={item.public_url}
                target="newExternaalTab"
                rel="noopener noreferrer"
                title={item.public_url}
              >
                <FontIcon forceSize={20} forceFontSize primary>
                  save_alt
                </FontIcon>
              </a>
            </TableColumn>
          </TableRow>
        )
      })

      return (
        <Paper style={{ margin: '16px' }}>
          <DataTable plain>
            <TableHeader>
              <TableRow>
                <TableColumn>type</TableColumn>
                <TableColumn>User id</TableColumn>
                <TableColumn>date exported</TableColumn>
                <TableColumn>link</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>{exportRows}</TableBody>
          </DataTable>
        </Paper>
      )
    }
  }

  /**
   * render no-data state
   */
  noResults = () => {
    return <div className="md-cell md-cell--12">No results found.</div>
  }

  render() {
    const buttonSpace = { marginRight: '16px' }
    const exportNow = [
      <Button
        raised
        secondary
        iconBefore={false}
        iconChildren="save_alt"
        onClick={() => this.handleExportNow(true)}
        disabled={this.state.exporting}
        style={buttonSpace}
      >
        Full Export
      </Button>,
      <Button
        raised
        secondary
        iconBefore={false}
        iconChildren="save_alt"
        onClick={() => this.handleExportNow()}
        disabled={this.state.exporting}
      >
        Quick Export
      </Button>
    ]

    return (
      <div className="hf-main-container">
        <Toolbar
          actions={exportNow}
          className={'hf-host-toolbar hf-reports'}
          colored
          title="Salesforce Exports"
        />
        {this.renderExports()}
        <div className="md-cell md-cell--10 md-cell--1-desktop-offset md-text-center">
          <Button
            raised
            primary
            onClick={this.showMore}
            disabled={this.state.disableMore}
          >
            Show More
          </Button>
        </div>
      </div>
    )
  }
}

export default connect()(SalesforceExports)
