function construct(google) {
  // TODO BA - check if we should only create this Class once
  SVGMarker.prototype = new google.maps.OverlayView()

  /** @constructor */
  function SVGMarker(options, google) {
    var default_ = {
      icon: {
        anchor: new google.maps.Point(0, 0),
        size: new google.maps.Size(50, 50),
        url: ''
      },
      map: '',
      opacity: 1,
      position: '',
      title: '',
      visible: true,
      zindex: ''
    }

    // Merge options with default
    for (var key in options) {
      if (options.hasOwnProperty(key)) {
        if (typeof default_[key] === 'object') {
          for (var key2 in options[key]) {
            if (options[key].hasOwnProperty(key2)) {
              if (options[key].hasOwnProperty(key2)) {
                default_[key][key2] = options[key][key2]
              }
            }
          }
        } else {
          if (options.hasOwnProperty(key)) {
            default_[key] = options[key]
          }
        }
      }
    }
    this.options_ = default_

    // Define div container
    this.div_ = null

    // Explicitly call setMap on this overlay
    if ('map' in options) {
      this.setMap(options.map)
    }
  }

  /**
   * onAdd is called when the map's panes are ready and the overlay has been
   * added to the map.
   */
  SVGMarker.prototype.onAdd = function () {
    var self = this

    // Create container with no visible styles
    this.div_ = document.createElement('div')
    this.div_.style.border = 'none'
    this.div_.style.borderWidth = '0px'
    this.div_.style.position = 'absolute'

    // Set if marker is visible when added
    if (this.options_.visible === false) {
      this.div_.style.visibility = 'hidden'
    }

    // Set marker opacity
    if (this.options_.opacity >= 0 && this.options_.opacity <= 1) {
      this.div_.style.opacity = this.options_.opacity
    }

    // Add drag start and end to container
    if (this.options_.draggable === true) {
      // TODO: Add draggable
    }

    // Attach image to div
    this.div_.innerHTML = this.options_.icon.svg

    // Add the element to the "overlayImage" pane.
    var panes = this.getPanes()
    panes.overlayImage.appendChild(this.div_)

    /* ############################ BA Add draggable ###################################*/
    self.div_.style.position = 'absolute'
    self.div_.draggable = this.options_.draggable
    if (this.options_.draggable) {
      google.maps.event.addDomListener(
        self.options_.map.getDiv(),
        'mouseleave',
        function () {
          if (self.options_.dragging) {
            google.maps.event.trigger(self.div_, 'mouseup')
          }
        }
      )

      google.maps.event.addDomListener(self.div_, 'mousedown', function (e) {
        self.options_.dragging = true
        self.div_.style.cursor = 'move'
        self.options_.map.set('draggable', false)
        self.options_.origin = e
        self.moveHandler = google.maps.event.addDomListener(
          self.options_.map.getDiv(),
          'mousemove',
          function (e) {
            if (self.options_.dragging) {
              const proj = self.getProjection()
              const origin = self.options_.origin
              const left = origin.clientX - e.clientX
              const top = origin.clientY - e.clientY
              const pos = proj.fromLatLngToDivPixel(self.options_.position)
              const pt = new google.maps.Point(pos.x - left, pos.y - top)
              const latLng = proj.fromDivPixelToLatLng(pt)
              self.options_.origin = e
              self.options_.position = latLng
              self.draw()
            }
          }
        )
      })

      google.maps.event.addDomListener(self.div_, 'mouseup', function () {
        self.options_.dragging = false
        self.options_.map.set('draggable', true)
        self.div_.style.cursor = 'default'
        if (self.options_.onMove) {
          self.options_.onMove(self.options_.position)
        }
        google.maps.event.removeListener(self.moveHandler)
      })
    } else {
      // this case is for draggable = false, to detect dragging and not fire the onClick event if they've dragged the map
      google.maps.event.addDomListener(self.div_, 'mouseleave', function () {
        self.options_.dragged = false
      })
      google.maps.event.addDomListener(self.div_, 'mousedown', function (e) {
        self.moveHandler = google.maps.event.addDomListener(
          self.options_.map.getDiv(),
          'mousemove',
          function (e) {
            self.options_.dragged = true
          }
        )
      })

      google.maps.event.addDomListener(self.div_, 'mouseup', function () {
        google.maps.event.removeListener(self.moveHandler)
      })
    }
    /* ############################ BA END draggable ###################################*/

    // Add click event to container
    if (this.options_.onClick) {
      google.maps.event.addDomListener(this.div_, 'click', function (e) {
        if (self.options_.draggable && self.options_.dragged === true) {
          self.options_.dragged = false
        } else {
          self.options_.onClick(e)
        }
      })
    }

    self.getPanes().floatPane.appendChild(this.div_)
  }

  SVGMarker.prototype.draw = function () {
    if (this.div_) {
      var overlayProjection = this.getProjection()
      var coords = overlayProjection.fromLatLngToDivPixel(
        this.options_.position
      )

      this.div_.style.left = coords.x - this.options_.icon.anchor.x + 'px'
      this.div_.style.top = coords.y - this.options_.icon.anchor.y + 'px'

      this.div_.style.cursor = 'pointer' // TODO: Only set this if clickable. Change if draggable.
    }
  }

  SVGMarker.prototype.onRemove = function () {
    if (this.div_) {
      this.div_.parentNode.removeChild(this.div_)
      this.div_ = null
    }
  }

  // Set the visibility to 'hidden' or 'visible'.
  SVGMarker.prototype.hide = function () {
    if (this.div_) {
      // The visibility property must be a string enclosed in quotes.
      this.div_.style.visibility = 'hidden'
    }
  }

  SVGMarker.prototype.show = function () {
    if (this.div_) {
      this.div_.style.visibility = 'visible'
    }
  }

  SVGMarker.prototype.toggle = function () {
    if (this.div_) {
      if (this.div_.style.visibility === 'hidden') {
        this.show()
      } else {
        this.hide()
      }
    }
  }

  // Detach from map
  SVGMarker.prototype.toggleDOM = function () {
    if (this.getMap()) {
      // Note: Calls onRemove()
      this.setMap(null)
    } else {
      // Note: Calls onAdd()
      this.setMap(this.options_.map)
    }
  }
  return SVGMarker
}
export default construct
