import React from 'react'

const Notifications = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`notifications`}</title>
    <path d="M18 15.984L20.016 18v.984H3.985V18l2.016-2.016v-4.969c0-3.094 1.641-5.625 4.5-6.328v-.703c0-.844.656-1.5 1.5-1.5s1.5.656 1.5 1.5v.703c2.859.703 4.5 3.281 4.5 6.328v4.969zm-6 6c-1.125 0-2.016-.891-2.016-1.969h4.031c0 1.078-.938 1.969-2.016 1.969z" />
  </svg>
)

export default Notifications
