import React from 'react'

const Needle = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`needle`}</title>
    <path d="M14 2c-1.102 0-2.094.602-2.594 1.5 0 .102-.117.207-.219.406-2.898-1.101-5.601-.293-8 2.406l1.5 1.282c2.899-3.399 5.52-2.282 7.72-.782L13.5 5.188c-.102-.199-.3-.273-.5-.375.102-.101.086-.21.188-.312.101-.3.414-.5.812-.5.2 0 .3-.008.5.094.5.3.605.906.406 1.406-.3.5-3.199 4.012-6.5 7.813 1-1.801 1.98-3.626 2.781-5.126-.601-.398-1.085-.699-1.687-1-2.7 4.899-7.012 12.907-7.313 13.407l1.626 1.093c2-2.398 12.18-14.187 12.78-15.187.802-1.398.305-3.293-1.093-4.094C15 2.106 14.5 2 14 2zm2.5 6.094l-1.313 1.594c1.399 1.199 2.32 2.324 2.22 3.124 0 .5-.302.883-1 1.282-2.302 1.398-2.02 3.113-1.72 3.812.7 1.899 3.399 3.188 6 3.188.5 0 .82.011 1.22-.188v-2c-2.4.399-5.009-.812-5.407-1.812-.2-.5.105-.907.906-1.407 1.2-.699 1.899-1.68 2-2.78.102-1.9-1.508-3.712-2.906-4.813z" />
  </svg>
)

export default Needle
