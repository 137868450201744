import React from 'react'

const Weekend = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`weekend`}</title>
    <path d="M18 5.016c1.078 0 2.016.891 2.016 1.969v2.156c-1.172.422-2.016 1.5-2.016 2.813v2.063H6v-2.063c0-1.313-.844-2.391-2.016-2.813V6.985c0-1.078.938-1.969 2.016-1.969h12zm3 4.968c1.078 0 2.016.938 2.016 2.016v5.016c0 1.078-.938 1.969-2.016 1.969H3c-1.078 0-2.016-.891-2.016-1.969V12c0-1.078.938-2.016 2.016-2.016s2.016.938 2.016 2.016v3h13.969v-3c0-1.078.938-2.016 2.016-2.016z" />
  </svg>
)

export default Weekend
