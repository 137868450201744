import 'whatwg-fetch'

/**
 * find domain object by domain root
 */
export function findDomain(domain) {
  const path = '/api/v1/domains/find/' + domain
  const method = 'GET'
  const url = process.env.REACT_APP_API_URL + path
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
}
