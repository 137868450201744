import { routerActions } from 'react-router-redux'
import { UserAuthWrapper } from 'redux-auth-wrapper'

import { addToast } from 'redux/modules/toast'

export default UserAuthWrapper({
  authSelector: function (state) {
    // TODO BA - while we still have wordpress this will be based on a cookie and possible API call to get current user.
    return state.auth.isAuthenticated ? { name: 'user' } : null
  }, // how to get the user state
  redirectAction2: routerActions.replace, // the redux action to dispatch for redirect
  redirectAction: (newLoc) => (dispatch) => {
    newLoc.search = 'redirect=' + window.location.pathname
    dispatch(routerActions.replace(newLoc))
    dispatch(addToast('You need to login'))
  },
  wrapperDisplayName: 'UserIsAuthenticated', // a nice name for this auth check
  allowRedirectBack: true
})
