import React from 'react'

const BeachChair = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`beach_chair`}</title>
    <path d="M11 2L7.625 9H10v4.656l.313.281L12 13V9h2.375zM9.406 3.063L2 7l4.438 2zm3.188 0L15.563 9 20 7zM17.5 10a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-2.094 2.188c-1.246.91-4.129 2.492-4.656 2.78A.884.884 0 0110 15c-.816-.352-1.363-1.176-1.844-1.531-.617-.457-1.3-.688-2.437.031-.778.492-3.063 2.031-3.063 2.031l1.156 1.531 3.157-2s1.383 1.41 2.25 1.782c.804.343 1.738.289 2.5-.125.816-.446 4.535-2.657 4.875-2.907zm5.094.937L11.75 18H2v2h2v2h2v-2h12v2h2v-2h2v-2h-1.063l-.687-2.438 1.25-.687zm-2.063 3.438L18.875 18h-3.031z" />
  </svg>
)

export default BeachChair
