import React from 'react'

const FishHook = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`fish_hook`}</title>
    <path d="M15.3 4c-1.1 0-2 .9-2 2 0 .9.6 1.6 1.3 1.9v7.5c0 1.8-1.5 3.3-3.3 3.3h-.1c-1.8 0-3.3-1.5-3.3-3.3 0-.9.3-1.7.9-2.3l1.1 1.1v-4l-2 2c-.9.9-1.3 2-1.3 3.2 0 2.5 2.1 4.6 4.6 4.6h.1c2.5 0 4.6-2.1 4.6-4.6V7.9c.8-.3 1.3-1 1.3-1.9.1-1.1-.8-2-1.9-2zm0 1.3c.4 0 .7.3.7.7s-.3.7-.7.7-.6-.3-.6-.7.3-.7.6-.7z" />
  </svg>
)

export default FishHook
