import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import UAParser from 'ua-parser-js'
import {
  Button,
  Card,
  CardText,
  CardTitle,
  DialogContainer,
  Toolbar
} from 'react-md'
// import { trackEvent } from 'utils/Segment';

const propTypes = {
  // this will also become the header of the modal
  linkText: PropTypes.string.isRequired,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.node.isRequired,

  // for tracking data
  trackLabel: PropTypes.string,

  // properties used for the modal dialog
  modalVisible: PropTypes.bool,
  focusOnMount: PropTypes.bool
}

const defaultProps = {
  modalTitle: null,
  modalVisible: false,
  focusOnMount: true,
  trackLabel: 'linkModal'
}

class LinkModal extends PureComponent {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      modalVisible: props.modalVisible
    }
  }

  openDialog = () => {
    // OLD SEGMENT CODE
    // let eventData = {
    //   category: 'Guidebook',
    //   label: (this.props.trackLabel) ? this.props.trackLabel : this.props.linkText
    // };
    // trackEvent('linkModalOpened', eventData);
    this.setState({ modalVisible: true }, () => {})
  }

  closeDialog = () => {
    // OLD SEGMENT CODE
    // let eventData = {
    //   category: 'Guidebook',
    //   label: (this.props.trackLabel) ? this.props.trackLabel : this.props.linkText
    // };
    // trackEvent('linkModalClosed', eventData);
    this.setState({ modalVisible: false }, () => {})
  }

  renderToolbar = (icon) => {
    var action = (
      <Button icon onClick={this.closeDialog}>
        back
      </Button>
    )
    return (
      <Toolbar
        colored
        nav={icon}
        actions={action}
        title={this.props.linkText.substring(0, 150)}
        fixed
      />
    )
  }

  // if we have children to render, use those
  // otherwise use secondary text
  renderContent = (icon) => {
    //Add title - displays on desktop dialogs
    var dialogTitle = this.props.modalTitle ? (
      <CardTitle
        title={this.props.modalTitle || this.props.linkText.substring(0, 150)}
      />
    ) : null
    return (
      <div className="list-item-modal-content">
        <Card className="list-item-modal-card" tabIndex="0">
          <Button
            icon
            className="md-cell--right list-item-modal-close"
            onClick={this.closeDialog}
          >
            keyboard_backspace
          </Button>
          {dialogTitle}
          <CardText>{this.props.modalContent}</CardText>
        </Card>
      </div>
    )
  }

  render() {
    // generate unique-ish id for items passed with no id
    const idForItems = this.props.itemId
      ? this.props.itemId
      : new Date().valueOf()
    const ua = new UAParser()
    let disableScrollLocking = ua.getDevice().type !== 'mobile'

    return (
      <div className="link-modal-outer">
        <div
          className="link-modal-inner"
          id={`li_${idForItems}`}
          onClick={this.openDialog}
        >
          {this.props.linkText}
        </div>
        <DialogContainer
          id={`dialog_${idForItems}`}
          visible={this.state.modalVisible}
          aria-label={this.props.linkText}
          onHide={this.closeDialog}
          focusOnMount={this.props.focusOnMount}
          fullPage
          portal={true}
          lastChild={true}
          disableScrollLocking={disableScrollLocking}
          renderNode={document.body}
          className="hf-guest-modal"
        >
          {this.renderContent()}
        </DialogContainer>
      </div>
    )
  }
}

LinkModal.propTypes = propTypes
LinkModal.defaultProps = defaultProps

export default LinkModal
