import React from 'react'

const Blinds = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`blinds`}</title>
    <path d="M2 2v20h20V2H2zm19.3 19.3H2.7V14h16v4.5c-.4.1-.7.5-.7.9 0 .6.4 1 1 1s1-.4 1-1c0-.4-.3-.8-.7-.9V14h2v7.3z" />
  </svg>
)

export default Blinds
