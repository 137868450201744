import 'whatwg-fetch'

export const SPONSORED_RECS_ARE_LOADING = 'SPONSORED_RECS_ARE_LOADING'
export const SPONSORED_RECS_FETCH_DATA_SUCCESS =
  'SPONSORED_RECS_FETCH_DATA_SUCCESS'
export const SPONSORED_RECS_HAS_ERRORED = 'SPONSORED_RECS_HAS_ERRORED'

const initialState = {
  isLoading: false,
  hasErrored: false,
  sponsoredRecs: []
}

/* Reducers */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SPONSORED_RECS_HAS_ERRORED:
      return action.hasErrored
    case SPONSORED_RECS_ARE_LOADING:
      return action.isLoading
    case SPONSORED_RECS_FETCH_DATA_SUCCESS:
      return action.sponsoredRecs
    default:
      return state
  }
}

/* Actions */
export function sponsoredRecsHasErrored(bool) {
  return {
    type: SPONSORED_RECS_HAS_ERRORED,
    hasErrored: bool
  }
}
export function sponsoredRecsIsLoading(bool) {
  return {
    type: SPONSORED_RECS_ARE_LOADING,
    isLoading: bool
  }
}

export function sponsoredRecsFetchDataSuccess(recommendations) {
  const recommendationsByCategory = recommendations.reduce(function (
    map,
    recommendation
  ) {
    const category = recommendation.category
    const existing = map[category.name]
    if (!existing) {
      const newTab = {
        category,
        recommendations: [recommendation]
      }
      map[category.name] = newTab
    } else {
      existing.recommendations.push(recommendation)
    }
    return map
  }, {})
  return {
    type: SPONSORED_RECS_FETCH_DATA_SUCCESS,
    sponsoredRecs: recommendationsByCategory
  }
}

export function fetchSponsoredRecsData(south, west, north, east) {
  const url =
    process.env.REACT_APP_API_URL +
    '/api/v0/recommendations/sponsored/' +
    south +
    '/' +
    west +
    '/' +
    north +
    '/' +
    east
  return (dispatch) => {
    dispatch(sponsoredRecsIsLoading(true))
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        dispatch(sponsoredRecsIsLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((guidebookResponse) =>
        dispatch(sponsoredRecsFetchDataSuccess(guidebookResponse.data))
      )
      .catch(() => dispatch(sponsoredRecsHasErrored(true)))
  }
}
