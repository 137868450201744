import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import { injectIntl } from 'react-intl'
import DocumentMeta from 'react-document-meta'
import SignupForm from './SignupForm'
import { push } from 'react-router-redux'
import UAParser from 'ua-parser-js'

class SignupContainer extends Component {
  componentWillMount() {
    if (this.props.auth && this.props.auth.isAuthenticated) {
      // console.log(this.props.auth);
      this.props.dispatch(push('/'))
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth && nextProps.auth.isAuthenticated) {
      this.props.dispatch(push('/'))
    }
  }

  componentDidMount() {
    // if there isn't already a hf-user-meta cookie, let's grab any utm params and stuff
    const { cookies } = this.props
    const has_utms = this.checkUtm()
    const affiliate_ref = this.checkRef()
    // console.log(affiliate_ref);
    if (affiliate_ref) {
      // we've got an affiliate reference, redirect to our special page for that.
      this.affiliateRedirect(affiliate_ref)
    }
    if (
      !cookies.cookies ||
      (cookies.cookies && !cookies.cookies.hasOwnProperty('hf-user-meta')) ||
      has_utms
    ) {
      // console.log('setting meta cookie');
      this.setUserMetaCookie()
    } else if (
      cookies.cookies &&
      cookies.cookies.hasOwnProperty('hf-user-meta') &&
      document.referrer
    ) {
      // if we have an existing cookie, but it's got an empty referrer, and we now have one, fill it
      var cookieData = JSON.parse(atob(cookies.cookies['hf-user-meta']))
      if (!cookieData['hf_mktng_referrer']) {
        cookieData['hf_mktng_referrer'] = document.referrer
        this.saveMetaCookie(cookieData)
      }
    }
  }

  checkUtm = () => {
    var query = window.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      if (pair[0].indexOf('utm_') !== -1) {
        return true
      } else if (pair[0].indexOf('fbclid') !== -1) {
        return true
      }
    }
    return false
  }

  checkRef = () => {
    var query = window.location.search.substring(1)
    // var query = window.location.search;
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
      // console.log(vars[i]);
      var pair = vars[i].split('=')
      if (pair[0].indexOf('noref') !== -1) {
        return false
      }
      if (pair[0].indexOf('ref') !== -1) {
        return pair[1]
      }
    }
    return false
  }

  affiliateRedirect = (affiliate_ref) => {
    if (affiliate_ref > 0) {
      const wp_base = process.env.REACT_APP_WORDPRESS_URL
      const react_base = process.env.REACT_APP_BASE_URL
      const return_url = `${react_base}/signup?noref`
      const redirect = `${wp_base}/affiliate-redirect?ref=${affiliate_ref}&redirect=${return_url}`
      // console.log(redirect);
      window.location.href = redirect
    }
  }

  setUserMetaCookie = () => {
    /* define the params we're looking to capture and what we want to store them as */
    var acceptedParams = {
      product_instance_id: 'booking_com_id',
      utm_source: 'hf_mktng_utm_source',
      utm_campaign: 'hf_mktng_utm_campaign',
      utm_medium: 'hf_mktng_utm_medium',
      utm_term: 'hf_mktng_utm_term',
      utm_content: 'hf_mktng_utm_content',
      utm_keyword: 'hf_mktng_utm_keyword',
      fbclid: 'hf_mktng_fbclid'
    }

    var storedData = {}
    var query = window.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      if (acceptedParams.hasOwnProperty(pair[0])) {
        storedData[acceptedParams[pair[0]]] = pair[1]
      }
    }
    // put together our calculated params here -
    storedData['hf_mktng_landing'] = window.location.pathname
    storedData['hf_mktng_referrer'] = document.referrer

    var ua = new UAParser()
    storedData['hf_mktng_platform'] = ua.getOS().name
    storedData['hf_mktng_platform_version'] = ua.getOS().version
    storedData['hf_mktng_browser'] = ua.getBrowser().name
    storedData['hf_mktng_browser_version'] = ua.getBrowser().version
    storedData['hf_mktng_device_type'] = ua.getDevice().type

    this.saveMetaCookie(storedData)
  }

  saveMetaCookie = (data) => {
    // save data to JSON string and save to cookie
    var jsonString = JSON.stringify(data)
    var base64string = btoa(jsonString)
    // don't escape characters since we're saving base64 data and that screws things up

    var timestamp = new Date().getTime()
    var expire = timestamp + 60 * 60 * 24 * 1000 * 90 // 90 days
    var expireDate = new Date(expire)
    var cookieOptions = {
      path: '/',
      expires: expireDate,
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      secure: true
    }
    this.props.cookies.set('hf-user-meta', base64string, cookieOptions)
  }

  render() {
    const title = 'Sign Up'
    const description = 'Get started with a free Guidebook Platform account'
    const twitter_site = '@hostfully_'
    const fb_app_id = process.env.REACT_APP_FB_APP_ID
    let urlBase = process.env.REACT_APP_BASE_URL
    const url = `${urlBase}/signup`
    const meta = {
      title: title,
      description: description,
      canonical: url,
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'guidebook,hostfully,vacation,rental,airbnb,signup',
          'twitter:card': 'summary_large_image',
          'twitter:site': twitter_site,
          'twitter:image:alt': 'Sign up form',
          'dc.title': title,
          'dc.description': description
        },
        property: {
          'fb:app_id': fb_app_id,
          'og:type': 'Website',
          'og:title': title,
          'og:description': description,
          'og:image':
            'https://storage.googleapis.com/hostfully-wp-1/hostfully-5-star-hospitality-sign-up.jpg',
          'og:image:alt': 'Sign up form',
          'og:url': url,
          'og:site_name': 'Hostfully'
        }
      }
    }

    return (
      <DocumentMeta {...meta}>
        <SignupForm {...this.props} />
      </DocumentMeta>
    )
  }
}

SignupContainer.propTypes = {
  dispatch: PropTypes.func
}

function mapStateToProps(state, props) {
  return {
    auth: state.auth
  }
}

export default withCookies(
  connect(mapStateToProps)(injectIntl(SignupContainer))
)
