import React from 'react'

const ShoppingBasket = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`shopping_basket`}</title>
    <path d="M12 17.016c1.078 0 2.016-.938 2.016-2.016s-.938-2.016-2.016-2.016-2.016.938-2.016 2.016.938 2.016 2.016 2.016zM9 9h6l-3-4.406zm8.203 0h4.781c.563 0 1.031.422 1.031.984-.812 3.235-1.727 6.366-2.578 9.563A1.968 1.968 0 0118.515 21H5.484a1.968 1.968 0 01-1.922-1.453l-2.531-9.281c-.047-.094-.047-.188-.047-.281 0-.563.469-.984 1.031-.984h4.781l4.359-6.563c.188-.281.516-.422.844-.422s.656.141.844.422z" />
  </svg>
)

export default ShoppingBasket
