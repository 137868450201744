import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import { replace } from 'react-router-redux'
import { Button, CardActions, Tabs, Tab, TabsContainer } from 'react-md'
import ManageSelectedCards from '../ManageSelectedCards'
import ManageInformationsSelectedTab from './ManageInformationsSelectedTab'

class ManageInformations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabIndex: 1,
      filter: '',
      tabContentChanged: new Date()
    }
    this.onTabChange = this.onTabChange.bind(this)
    this.tabContentChanged = this.tabContentChanged.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { selected_informations } = this.props
    //if selected items have loaded setState, otherwise this is the user changes to the model (ie activeTabIndex is 0)
    if (
      selected_informations &&
      nextProps.selected_informations &&
      selected_informations !== nextProps.selected_informations &&
      this.state.activeTabIndex === 1
    ) {
      const initialTabIndex = nextProps.selected_informations.length > 0 ? 1 : 0
      this.setState({
        activeTabIndex: initialTabIndex
      })
    }
  }

  createCard(type) {
    const { dispatch, item_id, singularName, pluralName } = this.props
    // if no id set, they must be creating a guidebook or template
    const id = item_id ? item_id : 'create'

    // BA. bit of a hack for when they edit a card and return with a hash in the URL, then refresh the whole browser
    dispatch(actions.change('edit_' + singularName + '.noReload', true))
    dispatch(replace(`/host/${pluralName}/${id}/${type}/create`))
  }

  onTabChange = (newTabIndex, event) => {
    this.setState({
      activeTabIndex: newTabIndex
    })
  }

  /*
    BA a bit of a hack to workaround an issue in react-md tabs. Let the tab notify the parent that it's content has changed.
    This function just sets a new state to force a recalc of heights etc
   */
  tabContentChanged = () => {
    this.setState({
      tabContentChanged: new Date()
    })
  }

  render() {
    const { pluralName, singularName } = this.props
    return (
      <div id="information" className="hf-editable-card-tabs">
        <TabsContainer
          onTabChange={this.onTabChange}
          activeTabIndex={this.state.activeTabIndex}
        >
          <Tabs tabId="tab">
            <Tab key="select" label="Select/Edit">
              <ManageSelectedCards
                ownerPluralName={pluralName}
                ownerSingularName={singularName}
                pluralName="informations"
                singularName="information"
                active={this.state.activeTabIndex === 0}
                onTabContentChanged={this.tabContentChanged}
                showHeading={false}
              />
            </Tab>
            <Tab key="order" label="Sort">
              <ManageInformationsSelectedTab
                ownerPluralName={pluralName}
                ownerSingularName={singularName}
                active={this.state.activeTabIndex === 1}
                {...this.props}
              />
            </Tab>
          </Tabs>
        </TabsContainer>
        <CardActions>
          <Button
            onClick={(e) => {
              this.createCard('informations')
            }}
            flat
            primary
          >
            Create House Manual Card
          </Button>
        </CardActions>
      </div>
    )
  }
}

ManageInformations.propTypes = {
  pluralName: PropTypes.string.isRequired,
  singularName: PropTypes.string.isRequired
}

function mapStateToProps(state, props) {
  const { singularName } = props
  const edit_item = state['edit_' + singularName]
  const selected_informations = edit_item.informations || []
  return {
    item_id: edit_item.id,
    selected_informations: selected_informations
  }
}

export default connect(mapStateToProps)(ManageInformations)
