import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const propTypes = {
  index: PropTypes.number,
  className: PropTypes.string,
  illustration: PropTypes.node,
  flexible: PropTypes.bool,
  copy: PropTypes.node.isRequired
}

const defaultProps = {
  flexible: false,
  className: ''
}

class OnboardingSlide extends Component {
  render() {
    const { index, className, illustration, flexible, copy, ...props } =
      this.props
    const classNames = cn('onboarding-slide', className)

    const illustrationClass = flexible
      ? 'illustration-container flexible'
      : 'illustration-container'
    const copyClass = flexible ? 'copy-container flexible' : 'copy-container'

    return (
      <div {...props} className={classNames} key={`slide_${index}`}>
        <div className={illustrationClass}>{illustration}</div>
        <div className={copyClass}>{copy}</div>
      </div>
    )
  }
}

OnboardingSlide.propTypes = propTypes
OnboardingSlide.defaultProps = defaultProps

export default OnboardingSlide
