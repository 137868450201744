import currentUser from 'utils/CurrentUser'

export const setupUserVoiceWidget = () => {
  const user = currentUser()
  const plan = user.isEnterprise
    ? 'Prime/Enterprise'
    : user.pro
      ? 'Power Host'
      : 'Free'
  const uv = document.createElement('script')
  const firstScript = document.getElementsByTagName('script')[0]

  uv.type = 'text/javascript'
  uv.async = true
  uv.src = `https://hostfully.uservoice.com/widget_environment/gyzodBYiFk7jcbHcPKLf7A.js`
  uv.onload = () => {
    // Set colors
    window.UserVoice.push([
      'set',
      {
        forum_id: '932419',
        accent_color: '#40caa1',
        trigger_color: '#8a5bda',
        trigger_background_color: '#fff'
      }
    ])

    // Identify the user and pass traits
    // To enable, replace sample data with actual user traits and uncomment the line
    window.UserVoice.push([
      'identify',
      {
        email: user.user.email,
        name: `${user.user.first_name} ${user.user.last_name}`,
        created_at: user.user.created_at,
        id: user.user.id,
        plan: plan
      }
    ])

    // Add default trigger to the bottom-right corner of the window:
    window.UserVoice.push([
      'addTrigger',
      { mode: 'feedback', trigger_position: 'bottom-left' }
    ])
  }

  if (firstScript.parentNode) {
    firstScript.parentNode.insertBefore(uv, firstScript)
  }
}

export const shutdownUserVoiceWidget = () => {
  window.UserVoice && window.UserVoice.removeTrigger()
}
