import React from 'react'

const Brightness1 = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`brightness_1`}</title>
    <path d="M2.016 12c0-5.531 4.453-9.984 9.984-9.984S21.984 6.469 21.984 12 17.531 21.984 12 21.984 2.016 17.531 2.016 12z" />
  </svg>
)

export default Brightness1
