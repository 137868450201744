import React from 'react'

const ThumbUp = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`thumb_up`}</title>
    <path d="M23.016 9.984l-.047.094h.047V12c0 .281-.047.516-.141.75l-3.047 7.031A1.96 1.96 0 0118 21H9c-1.078 0-2.016-.938-2.016-2.016V9c0-.563.234-1.031.609-1.406L14.156.985l1.078 1.078c.281.281.422.609.422 1.031v.328l-.984 4.594H21c1.078 0 2.016.891 2.016 1.969zM.984 21V9h4.031v12H.984z" />
  </svg>
)

export default ThumbUp
