import React from 'react'

const Rowing = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`rowing`}</title>
    <path d="M21 21l-3 3-3-3v-1.5l-7.078-7.078c-.328.047-.656.047-.938.047v-2.156c1.641.047 3.609-.844 4.688-2.016l1.406-1.547c.357-.357.945-.75 1.641-.75h.047c1.219 0 2.25 1.031 2.25 2.25v5.766c0 .844-.375 1.594-.938 2.156l-3.563-3.563v-2.297c-.609.516-1.453 1.031-2.297 1.406l6.281 6.281h1.5zM15 .984c1.078 0 2.016.938 2.016 2.016S16.078 5.016 15 5.016 12.984 4.078 12.984 3 13.922.984 15 .984zm-6.516 13.5l2.531 2.531H8.999l-3.516 3.469-1.5-1.5z" />
  </svg>
)

export default Rowing
