import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import { Button, FontIcon, Paper, Toolbar } from 'react-md'
import { connect } from 'react-redux'
import { actions, Errors, Form } from 'react-redux-form'
import { push, replace } from 'react-router-redux'

import InputTextField from 'components/InputTextField'
import TooltipButton from 'components/TooltipButton'
import { fetchItems } from 'redux/modules/crud'
import { saveItem } from 'redux/modules/crud'
import blankEditStates from 'redux/modules/crud/blankEditStates'
import currentUser from 'utils/CurrentUser'

class UserSettingForm extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.back = this.back.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
  }

  back = () => {
    const { dispatch } = this.props
    dispatch(push('/'))
  }

  handleEnter = (e) => {
    const { dispatch } = this.props
    e.preventDefault()
    dispatch(actions.submit('edit_user_setting'))
  }

  componentDidMount() {
    const { dispatch, itemId } = this.props
    if (itemId === 'create') {
      const newItem = blankEditStates.domain.data
      dispatch(fetchItems('user_settings'), () => {
        console.log('here')
      })
      dispatch(actions.load('edit_user_setting', newItem))
    }
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, edit_user_setting, user_settings } = this.props
    const nextSetting = nextProps.edit_user_setting
    // if we're on the create form, but this user already has a user_settings object, load that
    if (
      this.props.itemId === 'create' &&
      user_settings.data.length === 0 &&
      nextProps.user_settings.data.length > 0
    ) {
      const existingId = nextProps.user_settings.data[0].id
      dispatch(push(`/host/user_settings/${existingId}`))
    }
    // make sure this user can edit this item
    if (!edit_user_setting.id && nextSetting.id) {
      const user = currentUser()
      // admins can edit domains that are not theirs
      const canEdit = user.id === nextSetting.user_id || user.isAdmin
      if (!canEdit) {
        dispatch(replace('/403'))
      }
    }
  }

  handleSubmit(item) {
    const { itemId, dispatch } = this.props
    const addAnother = true
    // console.log('here');
    // const currentGuidebook = guidebookId ? this.props.owner_data : null;
    dispatch(
      saveItem('user_settings', 'user_setting', itemId, item, addAnother, null)
    )
  }

  partnerIdIsValid = (str) => {
    return !str || (str && str.slice(0, 1) === 'P')
  }

  render() {
    const { edit_user_setting } = this.props
    // special case we use all locales for domains
    const title = edit_user_setting.id
      ? 'Edit User Settings'
      : 'New User Settings'
    const nav = (
      <TooltipButton
        key="nav"
        onClick={this.back}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>keyboard_backspace</FontIcon>
      </TooltipButton>
    )
    const actions = []

    const validators = {
      viator_partner_id: {
        invalid: (val) => this.partnerIdIsValid(val)
      }
    }

    const formErrors = []
    const errorMessages = {
      viator_partner_id: {
        invalid: 'Invalid viator partner id'
      }
    }
    formErrors['viator_partner_id'] = (
      <Errors
        model=".viator_partner_id"
        messages={errorMessages.viator_partner_id}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )

    return (
      <DocumentTitle title="User Settings">
        <div>
          <Toolbar
            colored
            className="hf-host-toolbar"
            title={title}
            nav={nav}
            actions={actions}
          />
          <Paper key="settings" className="hf-form-wrapper">
            <Form
              model="edit_user_setting"
              onSubmit={(v) => this.handleSubmit(v)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  return this.handleEnter(e)
                }
              }}
              validators={validators}
            >
              <div className="md-grid">
                <div className="md-cell md-cell--12">
                  <h1 className="md-headline">Viator Partner Id</h1>
                  <p>
                    Enter your viator partner Id here to get commissions from
                    the sponsored recommendations on your guidebooks!
                  </p>
                  <p>
                    If you don't have a viator partner account yet, please go
                    here to sign up:{' '}
                    <a
                      href="https://partners.viator.com/signup?program=affiliate&mcid=66706"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      partners.viator.com/signup?program=affiliate&mcid=66706
                    </a>
                  </p>
                  <InputTextField
                    key="viator-id"
                    model=".viator_partner_id"
                    id="viator_partner_id"
                    label="Viator Partner Id"
                    required
                  />
                  {formErrors['viator_partner_id']}
                </div>
              </div>
              <div className="hf-sticky hf-form-errors">
                <Button
                  className="hf-button-margin"
                  type="submit"
                  primary
                  raised
                >
                  Save User Settings
                </Button>
              </div>
            </Form>
          </Paper>
        </div>
      </DocumentTitle>
    )
  }
}

UserSettingForm.propTypes = {}
function mapStateToProps(state, props) {
  const edit_user_setting = state.edit_user_setting
  const listData = state['list']
  const data = listData['user_settings']
  return {
    edit_user_setting,
    user_settings: data
  }
}

export default connect(mapStateToProps)(UserSettingForm)
