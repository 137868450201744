import React from 'react'

const Refresh = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`refresh`}</title>
    <path d="M17.672 6.328l2.344-2.344v7.031h-7.031l3.234-3.234C15.141 6.703 13.641 6 12 6c-3.328 0-6 2.672-6 6s2.672 6 6 6c2.625 0 4.875-1.641 5.672-3.984h2.063c-.891 3.469-3.984 6-7.734 6-4.406 0-7.969-3.609-7.969-8.016s3.563-8.016 7.969-8.016a8.01 8.01 0 015.672 2.344z" />
  </svg>
)

export default Refresh
