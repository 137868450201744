import React from 'react'

const Highlight = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`highlight`}</title>
    <path d="M16.969 6.563l2.109-2.109 1.406 1.406-2.109 2.156zM3.516 5.859l1.406-1.406 2.109 2.109-1.406 1.453zm7.5-3.843h1.969v3h-1.969v-3zM6 14.016V9h12v5.016l-3 3v4.969H9v-4.969z" />
  </svg>
)

export default Highlight
