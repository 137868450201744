import React from 'react'

const Treadmill = (props) => (
  <svg width={24} height={24} viewBox="0 0 31 32" {...props}>
    <title>{`treadmill`}</title>
    <path d="M13.913 5.565a2.783 2.783 0 000-5.566 2.783 2.783 0 000 5.566zM10.435 18.087l2.087 2.783v6.957h2.783v-9.739l-2.783-2.783v-3.478c1.636 2.082 4.134 3.423 6.947 3.478h.009v-2.783a6.254 6.254 0 01-5.551-2.76l-.014-.022-1.391-2.087h-.696L3.478 9.044v6.261h2.783v-4.87h2.087v9.739L0 18.087v3.478l10.435 2.087z" />
    <path d="M31.304 8.348l-11.13 11.13 2.783 2.783 4.87-4.87v9.739h-.696L2.088 29.913V32H30.61c.696-.696.696-2.087.696-2.783V8.347z" />
  </svg>
)

export default Treadmill
