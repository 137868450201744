import React from 'react'

const SpeakerNotes = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`speaker_notes`}</title>
    <path d="M18 8.016V6H9.984v2.016H18zm0 3V9H9.984v2.016H18zm-3 3V12H9.984v2.016H15zm-6.984-6V6H6v2.016h2.016zm0 3V9H6v2.016h2.016zm0 3V12H6v2.016h2.016zm12-12c1.078 0 1.969.891 1.969 1.969v12c0 1.078-.891 2.016-1.969 2.016H6l-3.984 3.984v-18c0-1.078.891-1.969 1.969-1.969h16.031z" />
  </svg>
)

export default SpeakerNotes
