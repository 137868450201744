import React from 'react'

const Kitchenwares = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`kitchenwares`}</title>
    <path d="M12 2c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 2c-3.969.023-6 1.344-6 3h12c0-1.656-2-2.977-6-3zM3 7c-.55 0-1 .45-1 1s.45 1 1 1h1l1.031 6.25C5.156 16.25 5.992 17 7 17h10c1.008 0 1.852-.754 1.969-1.719L20 9h1c.55 0 1-.45 1-1s-.45-1-1-1h-1c-1.008 0-1.852.758-1.969 1.719L17 15H7L5.969 8.75C5.844 7.75 5.008 7 4 7zm9 1c-.82 0-1.555.438-2.031 1.125A2.443 2.443 0 009.188 9C7.973 9 7 9.898 7 11h4c0-.57.531-1 1-1 .5 0 1 .43 1 1h4c0-1.102-.973-2-2.188-2-.269 0-.542.043-.78.125C13.554 8.437 12.82 8 12 8zM7 18c-.379 0-.734.223-.906.563l-1 2C4.762 21.227 5.258 22 6 22h12c.742 0 1.238-.773.906-1.438l-1-2A1.023 1.023 0 0017 18zm5 1c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z" />
  </svg>
)

export default Kitchenwares
