import React from 'react'

const DirectionsTransit = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`directions_transit`}</title>
    <path d="M18 11.016V6h-5.016v5.016H18zm-1.5 6c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm-5.484-6V6H6v5.016h5.016zm-3.516 6c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm4.5-15c4.406 0 8.016.469 8.016 3.984v9.516c0 1.922-1.594 3.469-3.516 3.469l1.5 1.5v.516H6v-.516l1.5-1.5c-1.922 0-3.516-1.547-3.516-3.469V6c0-3.516 3.609-3.984 8.016-3.984z" />
  </svg>
)

export default DirectionsTransit
