import { getToken } from 'utils/Auth'

import 'whatwg-fetch'

export function fetchProviders(successCallback, errorCallback) {
  const method = 'GET'
  const path = `/api/v1/orbirental/service_providers`
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => {
      return response.json()
    })
    .then((providerResponse) => {
      if (typeof successCallback === 'function') {
        successCallback(providerResponse.data)
      }
      return providerResponse.data
    })
    .catch(function (err) {
      if (typeof errorCallback === 'function') {
        errorCallback(err)
      }
      return { error: err }
    })
}

export function fetchItem(uid, pluralName, successCallback) {
  const method = 'GET'
  const path = `/api/v1/orbirental/${pluralName}/${uid}`
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => {
      return response.json()
    })
    .then((itemResponse) => {
      if (typeof successCallback === 'function') {
        successCallback(itemResponse.data)
      }
      return itemResponse.data
    })
    .catch(function (err) {
      return { error: err }
    })
}

export function createItem(pluralName, data, successCallback, failureCallback) {
  const method = 'POST'
  const path = `/api/v1/orbirental/${pluralName}`
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(data)
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => {
      return response.json()
    })
    .then((itemResponse) => {
      if (typeof successCallback === 'function') {
        successCallback(itemResponse.data)
      }
      return itemResponse.data
    })
    .catch(function (err) {
      if (typeof failureCallback === 'function') {
        failureCallback(err)
      }
      return { error: err }
    })
}

export function editItem(
  uid,
  pluralName,
  data,
  successCallback,
  failureCallback
) {
  const method = 'PUT'
  const path = `/api/v1/orbirental/${pluralName}/${uid}`
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(data)
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => {
      return response.json()
    })
    .then((itemResponse) => {
      if (typeof successCallback === 'function') {
        successCallback(itemResponse.data)
      }
      return itemResponse.data
    })
    .catch(function (err) {
      if (typeof failureCallback === 'function') {
        failureCallback(err)
      }
      return { error: err }
    })
}

export function deleteItem(uid, pluralName, successCallback, failureCallback) {
  const method = 'DELETE'
  const path = `/api/v1/orbirental/${pluralName}/${uid}`
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => {
      successCallback()
      return response.json()
    })
    .catch(function (err) {
      if (typeof failureCallback === 'function') {
        failureCallback(err)
      }
      return { error: err }
    })
}
