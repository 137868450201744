import React from 'react'

const Surfing = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`surfing`}</title>
    <path d="M9.25 2l-1.531.063.125 2.374c.02.399.156.747.437 1.032l1.844 1.937-1.906 3.657c-.184.527-.625 3.874-.625 3.874L4.625 16.25 7 16.656s1.156-.433 1.438-.625c.367-.246.664-.613.812-1.031.168-.477.5-1.938.5-1.938L12 14.97l.531 2.594 1.875.312-.75-3.469a2.099 2.099 0 00-.375-.781l-1.75-1.844L12.97 9l3.562 2.188.813-1.313-3.782-2.406-2.343-1.313-1.813-1.781zm4.188 1.031a1.5 1.5 0 100 3.001 1.5 1.5 0 000-3zM3.155 17l-.312 2 2.437.406L4 22c1.32 0 3.707-.848 4.625-2.031L20.844 22l.312-2z" />
  </svg>
)

export default Surfing
