import React from 'react'

const Target = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`target`}</title>
    <path d="M12 .5C5.6.5.5 5.6.5 12S5.6 23.5 12 23.5 23.5 18.4 23.5 12 18.4.5 12 .5zm0 19.7c-4.5 0-8.2-3.7-8.2-8.2S7.5 3.8 12 3.8s8.2 3.7 8.2 8.2c0 4.5-3.7 8.2-8.2 8.2z" />
    <path d="M12 6.8c-2.9 0-5.2 2.3-5.2 5.2s2.3 5.2 5.2 5.2 5.2-2.3 5.2-5.2c0-2.9-2.3-5.2-5.2-5.2zm0 7.8c-1.4 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6 2.6 1.2 2.6 2.6c0 1.4-1.2 2.6-2.6 2.6z" />
  </svg>
)

export default Target
