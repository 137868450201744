import React from 'react'
import { Button } from 'react-md'

import NavDrawer from 'components/NavDrawer'

import Bubbles from './assets/bg_bubbles.svg'
import Tablet from './assets/hostfully_tablet.png'

export default function GetStarted() {
  return (
    <NavDrawer showNavDrawer={false}>
      <div className="hf-get-started">
        <div className="hf-get-started-background" />
        <div className="hf-get-started-left">
          <p className="hf-get-started-left__subtitle-1">
            Welcome to Hostfully!
          </p>
          <h2 className="hf-get-started-left__title">
            Create your first <br /> Digital Guidebook
          </h2>
          <p className="hf-get-started-left__subtitle-2">
            Build it yourself in minutes. <br />
            Share it with guests whenever.
          </p>
          <Button
            className="hf-get-started-left__btn"
            href="/host/guidebooks/create"
            primary
            raised
          >
            Get Started
          </Button>
        </div>
        <div className="hf-get-started-right">
          <img src={Bubbles} alt="two background bubbles purple and yellow" />
          <img
            src={Tablet}
            className="hf-get-started-right__tablet"
            alt="a tablet with iphone on right top and some icons around"
          />
        </div>
      </div>
    </NavDrawer>
  )
}
