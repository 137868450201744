import formatAddress from 'components/Address/FormatAddress'
// import { trackEvent } from 'utils/Segment';
import UAParser from 'ua-parser-js'

// open map when clicking address list item
export const openMap = (event, address, track) => {
  event.stopPropagation()

  if (typeof address !== 'undefined' && Object.keys(address).length) {
    const { addressPrimary, addressSecondary } = formatAddress(address, true)
    const search_str = encodeURIComponent(
      addressPrimary.trim().concat(' ').concat(addressSecondary.trim())
    )

    let mapsUrl = null

    if (address.display_rule === 'latlng') {
      // if the host specified that we use latitude and longitude, do that
      mapsUrl = `https://www.google.com/maps/?q=${address.lat},${address.lng}`
    } else if (address.display_rule === 'address') {
      // if the host specified that we use the address, do that
      mapsUrl = `https://www.google.com/maps/search/${search_str}/@${address.lat},${address.lng},15z`
    } else {
      const ua = new UAParser()
      let device_type = ua.getDevice().type
      if (device_type !== 'mobile' && device_type !== 'tablet') {
        device_type = 'desktop'
      }
      if (address.google_place_url) {
        // if we have a stored url from our google places details request, use that
        mapsUrl = address.google_place_url
      } else if (address.google_place_id && device_type === 'desktop') {
        // otherwise if we're on desktop and have a google place ID, use that (this link doesn't work on mobile)
        mapsUrl = `https://www.google.com/maps/place/?q=place_id:${address.google_place_id}`
      } else {
        // otherwise construct the link using the address
        mapsUrl = `https://www.google.com/maps/search/${search_str}/@${address.lat},${address.lng},15z`
      }
    }

    // OLD SEGMENT CODE
    // const eventData = {
    //   category: 'Guidebook',
    //   label: mapsUrl
    // }
    var windowReference = window.open()
    // if (track) {
    //   trackEvent('maplinkOpened', eventData, {}, function() {
    //     windowReference.location = mapsUrl;
    //   });
    // } else {
    windowReference.location = mapsUrl
    // }
  }
}
