import React from 'react'

const ShoePolish = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`shoe_polish`}</title>
    <path d="M12 2C6.398 2 2 4.398 2 7.5v9C2 19.602 6.398 22 12 22s10-2.398 10-5.5v-9C22 4.398 17.602 2 12 2zm0 2c4.7 0 8 1.8 8 3.5S16.7 11 12 11 4 9.2 4 7.5 7.3 4 12 4zm0 1C8.687 5 6 6.121 6 7.5S8.688 10 12 10c3.313 0 6-1.121 6-2.5S15.312 5 12 5zm-8 5.813C5.8 12.113 8.7 13 12 13s6.2-.79 8-2.188V16.5c0 1.7-3.3 3.5-8 3.5s-8-1.8-8-3.5zM8 14v1H6v2h2v1h8v-1h2v-2h-2v-1zm2 1h4v2h-4z" />
  </svg>
)

export default ShoePolish
