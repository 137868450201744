import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class Translator extends Component {
  render() {
    let text
    if (
      this.props.intl.locale === 'en' ||
      !this.props.translations ||
      !this.props.translations[this.props.intl.locale]
    ) {
      text = this.props.text
    } else {
      text = this.props.translations[this.props.intl.locale]
    }
    return (
      <span style={this.props.styles ? this.props.styles.textColor : null}>
        {text}
      </span>
    )
  }
}

Translator.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  styles: PropTypes.object
}

function mapStateToProps(state, props) {
  return {
    intl: state.intl
  }
}

export default connect(mapStateToProps)(Translator)
