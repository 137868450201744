import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PrintHeader from 'components/Print/PrintHeader'
import { defineMessages, intlShape } from 'react-intl'
import { buildGuidebookUrl, removeProtocol } from 'utils/Urls'

class HostHeader extends Component {
  _formatMessage = this.props.intl.formatMessage

  _messages = defineMessages({
    host: {
      id: 'gb.host',
      defaultMessage: 'Host'
    }
  })

  render() {
    const { host_intro, theme, key, domain, is_published } =
      this.props.guidebook
    const hostName =
      host_intro && host_intro.host_name ? host_intro.host_name : <br />
    const hostEmail =
      host_intro && host_intro.host_email ? host_intro.host_email : ''
    const numberList =
      host_intro && host_intro.host_phone ? host_intro.host_phone : []
    let hostPhone = ''
    if (host_intro && host_intro.host_phone) {
      hostPhone = numberList.map(function (number, i) {
        return (
          <span key={i}>
            {number}
            {numberList.length > 1 && i + 1 < numberList.length ? <br /> : ''}
          </span>
        )
      })
    }
    const useDomain = domain && domain.domain ? domain.domain : null
    const gb = buildGuidebookUrl(
      key,
      null,
      false,
      false,
      useDomain,
      is_published
    )
    const cleanGb = removeProtocol(gb)
    return (
      <PrintHeader theme={theme} page="title" alignChildren="right">
        <div>
          <strong>{hostName}</strong>
        </div>
        <div>{hostPhone}</div>
        <div>{hostEmail}</div>
        <div className="header-url">{cleanGb}</div>
      </PrintHeader>
    )
  }
}

HostHeader.propTypes = {
  guidebook: PropTypes.object,
  printPreferences: PropTypes.object,
  intl: intlShape.isRequired
}

export default HostHeader
