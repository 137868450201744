import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Button,
  DialogContainer,
  FontIcon,
  LinearProgress,
  MenuButton,
  Paper,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { atMostPhablet } from 'components/ScreenSize'
import TooltipButton from 'components/TooltipButton'
import {
  copyItem,
  createTemplateFromGuidebook,
  deleteItem,
  fetchItems,
  showItems
} from 'redux/modules/crud'
import currentUser from 'utils/CurrentUser'
import { jsonEqual } from 'utils/Data'
import { buildGuidebookUrl } from 'utils/Urls'

import GuidebookForm from './GuidebookForm'

class GuidebookEditor extends Component {
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this)
    this.preview = this.preview.bind(this)
    this.copyDeck = this.copyDeck.bind(this)
    this.delete = this.delete.bind(this)
    this.createTemplate = this.createTemplate.bind(this)
    this.state = {
      showDeleteConfirm: false,
      confirmDeleteId: null
    }
  }

  componentDidMount() {
    const self = this
    const { dispatch, pluralName } = this.props

    dispatch(fetchItems(pluralName))
    // If they are returning from creating/editing a card, get the details so we can select it and scroll to it.
    window.location.hash = window.decodeURIComponent(window.location.hash)
    const hashParts = window.location.hash.split('#')
    if (hashParts.length >= 3) {
      // BA unfortunate that guidebook uses host_intro instead of hostintro
      const singularName =
        hashParts[2] === 'hostintro' ? 'host_intro' : hashParts[2]
      self.wait_loaded = hashParts[1]
      self.edit_card_type = singularName
      self.edit_card_id = hashParts[3]
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsEqual = jsonEqual(this.props, nextProps)
    const stateEqual = jsonEqual(this.state, nextState)
    // if all of these things are the same, don't re-render
    if (propsEqual && stateEqual) {
      return false
    }
    if (stateEqual) {
      if (
        this.props.itemId === nextProps.itemId &&
        this.props.items.loaded === nextProps.items.loaded &&
        this.props.items.data.length === nextProps.items.data.length
      ) {
        return false
      }
      // if (for some reason, the loaded items are getting unset)
      if (
        this.props.items.data.length > 0 &&
        nextProps.items.data.length === 0 &&
        this.props.itemId === nextProps.itemId
      ) {
        return false
      }
    }
    return true
  }

  goBack = () => {
    if (window.isIframe) {
      window.history.back()
    } else {
      const { dispatch, pluralName } = this.props
      dispatch(showItems(pluralName))
    }
  }

  preview = () => {
    const { edit_item } = this.props
    const domain =
      edit_item.domain && edit_item.domain.status === 'approved'
        ? edit_item.domain.domain
        : null
    const gbUrl = buildGuidebookUrl(
      edit_item.key,
      null,
      null,
      false,
      domain,
      edit_item.is_published
    )
    window.open(gbUrl, 'newExternalTab')
  }

  print = () => {
    this.props.dispatch(
      push('/host/guidebooks/' + this.props.edit_item.key + '/print')
    )
  }

  copyDeck = (id) => {
    const { dispatch, pluralName } = this.props
    dispatch(copyItem(pluralName, id))
  }

  createTemplate = (id) => {
    const { dispatch } = this.props
    dispatch(createTemplateFromGuidebook(id))
  }

  delete = (e) => {
    // prevent propagation so it doesn't also fire edit
    const { pluralName, singularName, dispatch } = this.props
    this.closeDialog()
    if (this.state.confirmDeleteId) {
      dispatch(deleteItem(pluralName, singularName, this.state.confirmDeleteId))
    }
  }

  confirmDelete = (itemId, e) => {
    e.stopPropagation()
    this.setState({ showDeleteConfirm: true, confirmDeleteId: itemId })
  }

  closeDialog = () => {
    this.setState({ showDeleteConfirm: false, confirmDeleteId: null })
  }

  showHelp = () => {
    window.open(
      'https://help.hostfully.com/en/collections/113571-guidebook-platform',
      '_blank',
      'noopener,noreferrer'
    )
  }

  renderDeleteConfirmation = () => {
    return (
      <DialogContainer
        id="simpleDialogExample"
        title="Confirm Delete"
        visible={this.state.showDeleteConfirm}
        onHide={this.closeDialog}
        actions={[
          {
            onClick: this.closeDialog,
            primary: true,
            children: 'Cancel'
          },
          {
            onClick: this.delete,
            primary: true,
            children: 'Delete'
          }
        ]}
      >
        <p id="deleteItemDescription" className="md-color--secondary-text">
          Are you sure you wish to delete this {this.props.singularName}? This
          cannot be undone.
        </p>
      </DialogContainer>
    )
  }

  isLoaded = () => {
    if (this.props.itemId && this.props.items.loaded) {
      return true
    }
    return false
  }

  render() {
    const { edit_item, items, itemId, copy, singularName, pluralName } =
      this.props
    const self = this

    let itemName = edit_item.name ? edit_item.name : singularName
    if (itemName === edit_item.name && itemName.length > 10) {
      itemName = itemName.substring(0, 10) + '...'
    }
    const title = copy
      ? 'Copy ' + itemName
      : itemId === 'create'
        ? 'New ' + singularName
        : 'Edit ' + itemName
    const nav = (
      <Button
        key="nav"
        onClick={self.goBack}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        icon
      >
        keyboard_backspace
      </Button>
    )

    const actions = []
    const menuItems = []
    const menuButtonStyle = {
      color: '#232323',
      padding: '2px 10px',
      margin: '2px',
      width: '100%'
    }
    const user = currentUser()
    if (
      edit_item.id &&
      user.canCopy &&
      items.loaded &&
      !items.isLoading &&
      items.data.length > 0 &&
      !copy
    ) {
      actions.push(
        <TooltipButton
          key="copy"
          onClick={() => {
            self.copyDeck(itemId)
          }}
          tooltipLabel="Copy"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>content_copy</FontIcon>
        </TooltipButton>
      )
      menuItems.push(
        <Button
          key="copy"
          flat
          onClick={() => {
            self.copyDeck(itemId)
          }}
          iconBefore={false}
          iconEl={<FontIcon>content_copy</FontIcon>}
          style={menuButtonStyle}
        >
          Copy
        </Button>
      )
    }
    if (user.isEnterprise && singularName === 'guidebook') {
      actions.push(
        <TooltipButton
          key="template"
          onClick={() => {
            self.createTemplate(itemId)
          }}
          tooltipLabel="Create Template"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>developer_board</FontIcon>
        </TooltipButton>
      )
      menuItems.push(
        <Button
          key="template"
          flat
          onClick={() => {
            self.createTemplate(itemId)
          }}
          iconBefore={false}
          iconEl={<FontIcon>developer_board</FontIcon>}
          style={menuButtonStyle}
        >
          Template
        </Button>
      )
    }
    actions.push(
      <TooltipButton
        key="preview"
        onClick={self.preview}
        tooltipLabel="Preview"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>pageview</FontIcon>
      </TooltipButton>
    )
    menuItems.push(
      <Button
        key="preview"
        onClick={self.preview}
        iconBefore={false}
        iconEl={<FontIcon>pageview</FontIcon>}
        style={menuButtonStyle}
        flat
      >
        Preview
      </Button>
    )
    if (edit_item.key) {
      actions.push(
        <TooltipButton
          key="print"
          onClick={self.print}
          tooltipLabel="Print"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>print</FontIcon>
        </TooltipButton>
      )
      menuItems.push(
        <Button
          key="mi-print"
          onClick={self.print}
          iconBefore={false}
          iconEl={<FontIcon>print</FontIcon>}
          style={menuButtonStyle}
          flat
        >
          Print
        </Button>
      )
    }
    actions.push(
      <TooltipButton
        key="help"
        onClick={() => {
          self.showHelp()
        }}
        tooltipLabel="Help"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>help</FontIcon>
      </TooltipButton>
    )
    menuItems.push(
      <Button
        key="help"
        onClick={() => {
          self.showHelp()
        }}
        iconBefore={false}
        iconEl={<FontIcon>help</FontIcon>}
        style={menuButtonStyle}
        flat
      >
        Help
      </Button>
    )
    actions.push(
      <TooltipButton
        key="delete"
        onClick={(e) => {
          self.confirmDelete(itemId, e)
        }}
        tooltipLabel="Delete"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>delete</FontIcon>
      </TooltipButton>
    )
    menuItems.push(
      <Button
        key="delete"
        onClick={(e) => {
          self.confirmDelete(itemId, e)
        }}
        iconBefore={false}
        iconEl={<FontIcon>delete</FontIcon>}
        style={menuButtonStyle}
        flat
      >
        Delete
      </Button>
    )

    const useResponsiveMenu = atMostPhablet
    const responsiveActions = useResponsiveMenu ? (
      <MenuButton id="menu-botton" icon menuItems={menuItems}>
        more_vert
      </MenuButton>
    ) : (
      actions
    )

    const loaderOrForm = this.isLoaded() ? (
      <Paper key="login" className="hf-form-wrapper">
        <GuidebookForm {...this.props} />
      </Paper>
    ) : (
      <LinearProgress id="status_check" />
    )

    return (
      <div className={`hf-${pluralName} hf-edit-container`}>
        <Toolbar
          colored
          className={'hf-edit-toolbar hf-' + pluralName + '-toolbar'}
          title={title}
          nav={nav}
          actions={itemId === 'create' || copy ? null : responsiveActions}
        />
        {loaderOrForm}
        {this.renderDeleteConfirmation()}
      </div>
    )
  }
}

GuidebookEditor.propTypes = {
  dispatch: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  singularName: PropTypes.string.isRequired,
  pluralName: PropTypes.string.isRequired,
  form: PropTypes.func.isRequired
}

function mapStateToProps(state, props) {
  const { pluralName, singularName } = props
  const edit_item = state['edit_' + singularName]
  return {
    edit_item,
    items: state.list[pluralName]
  }
}

export default connect(mapStateToProps)(GuidebookEditor)
