import React from 'react'

const SimCardAlert = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`sim_card_alert`}</title>
    <path d="M12.984 12.984V8.015h-1.969v4.969h1.969zm0 4.032V15h-1.969v2.016h1.969zm5.016-15c1.078 0 2.016.891 2.016 1.969v16.031c0 1.078-.938 1.969-2.016 1.969H6c-1.078 0-2.016-.891-2.016-1.969l.047-12 5.953-6H18z" />
  </svg>
)

export default SimCardAlert
