import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Tabs from 'react-md/lib/Tabs/Tabs'
import Tab from 'react-md/lib/Tabs/Tab'
import TabsContainer from 'react-md/lib/Tabs/TabsContainer'
import ManageSelectedCards from './ManageSelectedCards'
import currentUser from 'utils/CurrentUser'
import { Card } from 'react-md'

class ManageGuidebooksAndTemplates extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabIndex: props.activeTab === 'templates' ? 1 : 0,
      tabContentChanged: new Date()
    }
    this.onTabChange = this.onTabChange.bind(this)
    this.tabContentChanged = this.tabContentChanged.bind(this)
  }

  onTabChange = (newTabIndex, event) => {
    this.setState({
      activeTabIndex: newTabIndex
    })
  }

  /*
   BA a bit of a hack to workaround an issue in react-md tabs. Let the tab notify the parent that it's content has changed.
   This function just sets a new state to force a recalc of heights etc
   */
  tabContentChanged = () => {
    this.setState({
      tabContentChanged: new Date()
    })
  }

  render() {
    const { cardType, heading, replaceWarning } = this.props
    const user = currentUser()
    let headline = heading
    if (!heading) {
      if (user.isEnterprise) {
        headline = (
          <div>Which guidebooks or templates should this card appear on?</div>
        )
      } else {
        headline = <div>Which guidebooks should this card appear on?</div>
      }
    }

    return (
      <div id="information" className="hf-editable-card-tabs">
        {cardType ? <h1 className="md-headline">{headline}</h1> : null}
        {replaceWarning ? (
          <p>
            Note: Selecting a guidebook or template here will remove any other
            card of this type from that guidebook or template.
          </p>
        ) : null}
        <Card>
          <TabsContainer
            onTabChange={this.onTabChange}
            activeTabIndex={this.state.activeTabIndex}
          >
            <Tabs tabId="tab">
              <Tab key="order" label="Guidebooks">
                <ManageSelectedCards
                  pluralName="guidebooks"
                  singularName="guidebook"
                  active={this.state.activeTabIndex === 0}
                  onTabContentChanged={this.tabContentChanged}
                  {...this.props}
                />
              </Tab>
              {user.isEnterprise ? (
                <Tab key="select" label="Templates">
                  <ManageSelectedCards
                    pluralName="templates"
                    singularName="template"
                    active={this.state.activeTabIndex === 1}
                    onTabContentChanged={this.tabContentChanged}
                    {...this.props}
                  />
                </Tab>
              ) : null}
            </Tabs>
          </TabsContainer>
        </Card>
      </div>
    )
  }
}

ManageGuidebooksAndTemplates.propTypes = {
  ownerSingularName: PropTypes.string,
  ownerPluralName: PropTypes.string,
  activeTab: PropTypes.string,
  replaceWarning: PropTypes.bool
}

ManageGuidebooksAndTemplates.defaultProps = {
  replaceWarning: false
}

export default connect(null)(ManageGuidebooksAndTemplates)
