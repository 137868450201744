import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, injectIntl, intlShape } from 'react-intl'
import PrintContentBlock from 'components/Print/PrintContentBlock'
import Directions from '../../../pages/Guidebook/pages/MainView/components/Directions'
import Checkin from '../../../pages/Guidebook/pages/MainView/components/Checkin'
import WifiListItemModal from 'pages/Guidebook/pages/MainView/components/Checkin/WifiListItemModal'
import { buildGuidebookUrl } from 'utils/Urls'

const propTypes = {
  guidebook: PropTypes.object,
  styles: PropTypes.object,
  intl: intlShape.isRequired,
  showQR: PropTypes.bool
}

const defaultProps = {
  showQR: false
}

class Arrival extends Component {
  _formatMessage = this.props.intl.formatMessage

  _messages = defineMessages({
    gettingHere: {
      id: 'print.gettingHere',
      defaultMessage: 'Getting Here'
    },
    checkingIn: {
      id: 'print.checkingIn',
      defaultMessage: 'Checking In'
    },
    accessingWifi: {
      id: 'print.accessingWifi',
      defaultMessage: 'Accessing Wifi'
    }
  })

  render() {
    const gbShape = {
      key: this.props.guidebook.key || 'loading',
      is_secure: this.props.guidebook.is_secure,
      token: this.props.guidebook.token_data
    }
    const domain =
      this.props.guidebook.domain &&
      this.props.guidebook.domain.status === 'approved'
        ? this.props.guidebook.domain.domain
        : null

    return (
      <div>
        {this.props.guidebook.address ? (
          <PrintContentBlock
            title={this._formatMessage(this._messages.gettingHere)}
            styles={this.props.styles ? this.props.styles : null}
            avoidBreak={true}
            showQR={this.props.showQR}
            QRLink={`${buildGuidebookUrl(this.props.guidebook.key, null, null, false, domain, this.props.guidebook.is_published)}/arrival`}
          >
            <Directions
              address={this.props.guidebook.address}
              directions={this.props.guidebook.directions}
              parking={this.props.guidebook.parking}
              viewMode="print"
            />
          </PrintContentBlock>
        ) : null}

        {this.props.guidebook.checkin ? (
          <PrintContentBlock
            title={this._formatMessage(this._messages.checkingIn)}
            styles={this.props.styles}
            avoidBreak={true}
            showQR={this.props.showQR}
            QRLink={`${buildGuidebookUrl(this.props.guidebook.key, null, null, false, domain, this.props.guidebook.is_published)}/arrival/check-in`}
          >
            <Checkin
              guidebook={gbShape}
              checkin={this.props.guidebook.checkin}
              viewMode="print"
            />
          </PrintContentBlock>
        ) : null}

        {this.props.guidebook.wifi ? (
          <PrintContentBlock
            title={this._formatMessage(this._messages.accessingWifi)}
            leftIcon="wifi"
            styles={this.props.styles}
            avoidBreak={true}
            showQR={this.props.showQR}
            QRLink={`${buildGuidebookUrl(this.props.guidebook.key, null, null, false, domain, this.props.guidebook.is_published)}/arrival/wifi`}
          >
            <WifiListItemModal
              wifi={this.props.guidebook.wifi}
              host_intro={this.props.guidebook.host_intro}
              viewMode="print"
            />
          </PrintContentBlock>
        ) : null}
      </div>
    )
  }
}

Arrival.propTypes = propTypes
Arrival.defaultProps = defaultProps

export default injectIntl(Arrival)
