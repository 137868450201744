import React from 'react'

const DisabledAccess = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`disabled_access`}</title>
    <path d="M9.906 2A2.006 2.006 0 008 4.094 2.006 2.006 0 0010.094 6 2.006 2.006 0 0012 3.906 2.006 2.006 0 009.906 2zm.063 4.813a3.55 3.55 0 00-1.813.437C6.457 8.383 5.484 9.29 5.25 9.906c-.387 1.016-1.004 2.977-1.031 3.063l1.906.594c.008-.02.633-1.985.969-2.876.11-.167.55-.582 1.093-1V13c0 .688.356 1.32.938 1.688l3 3.187L13.656 22h2.125c-.394-.996-1.394-3.55-1.844-4.688l-2.624-3.843-.063-.313-.063-3.531 1.25 1.281c.622.774 2.485 1.801 3.282 2.219l.937-1.75c-1.199-.633-2.445-1.418-2.656-1.688l-1.438-1.53A3.343 3.343 0 009.97 6.811zm6.937 5.718l-.593 1.125L20.093 22l.907-.406zm-9 2.781L4.656 22H7l2.438-5.063z" />
  </svg>
)

export default DisabledAccess
