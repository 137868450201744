import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Avatar, FontIcon, ListItem, Media, TextField } from 'react-md'

import TooltipButton from 'components/TooltipButton'
import TooltipFontIcon from 'components/TooltipFontIcon'
import { editItem } from 'redux/modules/crud'
import currentUser from 'utils/CurrentUser'
import { filterData, sortData } from 'utils/Data'

class RentalList extends Component {
  constructor(props) {
    super(props)
    this.createGB = this.createGB.bind(this)
    this.updateGB = this.updateGB.bind(this)
    this.editGB = this.editGB.bind(this)
    this.filter = this.filter.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.state = {
      filter: '',
      showUpgradeAlert: false
    }
  }

  filter = (filter) => {
    this.setState({ filter })
  }

  clearFilter = () => {
    this.setState({ filter: '' })
  }

  createGB = (item, e) => {
    const { dispatch } = this.props
    dispatch(editItem('bookingsyncrentals', item.bookingsync_id, e))
  }

  updateGB = (item, e) => {
    const { dispatch } = this.props
    dispatch(editItem('bookingsyncrentals', item.bookingsync_id, e))
    e.preventDefault()
    e.stopPropagation()
  }

  editGB = (id, e) => {
    const { dispatch } = this.props
    dispatch(editItem('guidebooks', id, e))
  }

  renderBlankState = () => {
    const title = 'Fetching BookingSync rentals'
    const message = 'Please wait while we load your rentals from BookingSync'
    const image =
      'https://storage.googleapis.com/hostfully-test/blank-state-cards.png'
    return (
      <div className="md-grid">
        <div className="md-cell md-cell--1 md-cell--tablet-hidden"></div>
        <div className="md-cell md-cell--5 md-cell--4-tablet md-cell--4-phone md-cell--middle">
          <h6 className="md-headline">{title}</h6>
          <p>{message}</p>
        </div>
        <div className="md-cell md-cell--5 md-cell--4-tablet md-cell--4-phone">
          <Media aspectRatio="1-1">
            <img src={image} alt="" />
          </Media>
        </div>
      </div>
    )
  }

  upgrade = () => {
    window.location.href =
      process.env.REACT_APP_WORDPRESS_URL + '/pricing#prime'
  }

  render() {
    const { guidebooks, rentals } = this.props
    const { filter } = this.state
    const user = currentUser()
    //add upgrade prompts if needed
    const defaultSort = 'name'
    const filterFields = [
      'name',
      'key',
      'address.formatted_address',
      'address.street',
      'address.locality',
      'address.state',
      'address.post_code',
      'address.country_code'
    ]
    const self = this
    // sort the items
    const filteredItems = filterData(
      rentals.data,
      'bookingsyncrentals',
      filterFields,
      filter
    )
    const sorted = sortData(filteredItems, defaultSort, 'asc')
    let list

    const limitReached =
      guidebooks.loaded && guidebooks.data.length >= user.guidebookLimit

    if (rentals.loaded && !rentals.data.length) {
      //send blank state
      list = self.renderBlankState()
    } else {
      list = sorted.map(function (item) {
        // if id is set then this is linked to a guidebook
        const avatarLink = item.id ? (
          <Avatar
            src={item.image}
            alt=""
            onClick={(e) => {
              self.editGB(item.id, e)
            }}
          />
        ) : (
          <Avatar src={item.image} alt="" />
        )

        //const editLink = item.id ? (e) => {self.editGB(item.id, e)} : (e) => {self.createGB(item, e)};

        const actionButton = item.id ? (
          item.stale ? (
            <TooltipFontIcon
              tooltipLabel="Reimport from BookingSync"
              tooltipPosition="top"
              className="hf-clickable"
              onClick={(e) => {
                self.updateGB(item, e)
              }}
            >
              restore_page
            </TooltipFontIcon>
          ) : (
            <TooltipFontIcon
              tooltipLabel="View your Guidebook"
              tooltipPosition="top"
              className="hf-clickable"
              onClick={(e) => {
                self.editGB(item.id, e)
              }}
            >
              link
            </TooltipFontIcon>
          )
        ) : (
          <TooltipFontIcon
            tooltipLabel="Import from BookingSync"
            tooltipPosition="top"
            className="hf-clickable"
            onClick={(e) => {
              self.createGB(item, e)
            }}
            disabled={limitReached}
          >
            system_update_alt
          </TooltipFontIcon>
        )
        const helpText = item.id ? (
          item.stale ? (
            <span>
              Click the <FontIcon>restore_page</FontIcon> symbol to update from
              BookingSync
            </span>
          ) : (
            <span>
              Imported as <strong>{item.key}</strong>
            </span>
          )
        ) : (
          <span>Not imported yet</span>
        )

        return (
          <ListItem
            key={item.bookingsync_id}
            className={'hf-list-item hf-bookingsyncrental-list-item'}
            disabled={!item.id}
            onClick={(e) => {
              if (item.id) {
                self.editGB(item.id, e)
              }
            }}
            leftAvatar={avatarLink}
            primaryText={item.name}
            secondaryText={helpText}
            rightIcon={actionButton}
          />
        )
      })
    }

    return (
      <div>
        <div className="md-grid">
          <div className="md-cell md-cell--12 md-cell--bottom">
            <TextField
              id="filter"
              value={filter}
              label="Search / Filter"
              type="text"
              leftIcon={<FontIcon>search</FontIcon>}
              inlineIndicator={
                <TooltipButton
                  className="hf-mini-btn"
                  tooltipLabel="Clear Search / Filter"
                  tooltipPosition="left"
                  onClick={this.clearFilter}
                  icon
                >
                  <FontIcon>close</FontIcon>
                </TooltipButton>
              }
              onChange={this.filter}
            />
          </div>
        </div>
        {list}
      </div>
    )
  }
}

RentalList.propTypes = {
  guidebooks: PropTypes.object.isRequired,
  rentals: PropTypes.object.isRequired
}
export default RentalList
