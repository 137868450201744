import { getToken } from 'utils/Auth'

import 'whatwg-fetch'

export function fetchReservedWords() {
  let url = process.env.REACT_APP_API_URL + '/api/v1/reserved_words'

  const token = getToken()
  const config = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }

  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
}

export function addReservedWord(value, type) {
  let url = process.env.REACT_APP_API_URL + '/api/v1/reserved_words'

  const token = getToken()
  const body = {
    reserved_word: {
      word: value,
      type: type
    }
  }
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }

  return fetch(url, config)
    .then((response) => {
      if (!response.ok && response.status !== 422) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      if (response.errors) {
        return response
      } else {
        return response.data
      }
    })
}

export function deleteReservedWord(id) {
  let url = process.env.REACT_APP_API_URL + '/api/v1/reserved_words/' + id

  const token = getToken()
  const config = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }

  return fetch(url, config).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText)
    }
    return response
  })
}
