import React from 'react'

const Help = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`help`}</title>
    <path d="M15.047 11.25A3.186 3.186 0 0015.985 9c0-2.203-1.781-3.984-3.984-3.984S8.017 6.797 8.017 9h1.969c0-1.078.938-2.016 2.016-2.016s2.016.938 2.016 2.016c0 .563-.234 1.031-.609 1.406l-1.219 1.266c-.703.75-1.172 1.734-1.172 2.813v.516h1.969c0-1.5.469-2.063 1.172-2.813zm-2.063 7.734v-1.969h-1.969v1.969h1.969zM12 2.016c5.531 0 9.984 4.453 9.984 9.984S17.531 21.984 12 21.984 2.016 17.531 2.016 12 6.469 2.016 12 2.016z" />
  </svg>
)

export default Help
