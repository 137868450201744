import React from 'react'

const LocalDrink = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`local_drink`}</title>
    <path d="M18.328 8.016l.422-4.031H5.25l.422 4.031h12.656zM12 18.984c1.641 0 3-1.359 3-3 0-2.016-3-5.391-3-5.391s-3 3.375-3 5.391c0 1.641 1.359 3 3 3zM3 2.016h18L18.984 20.25c-.141.984-.938 1.734-1.969 1.734H6.984c-1.031 0-1.828-.75-1.969-1.734z" />
  </svg>
)

export default LocalDrink
