import React from 'react'

const Carport = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`carport`}</title>
    <path d="M12 2.7L22.7 6H1.3L12 2.7zM8 9.3l-1 3.2h10l-1-3.2H8zM9.2 15.2c0 .6-.5 1.2-1.2 1.2s-1.2-.5-1.2-1.2S7.4 14 8 14s1.2.5 1.2 1.2zM17.2 15.2c0 .6-.5 1.2-1.2 1.2s-1.2-.5-1.2-1.2c0-.6.5-1.2 1.2-1.2s1.2.5 1.2 1.2z" />
    <path d="M2.7 6v14.5h18.7V6H2.7zm15.8 13c0 .4-.3.7-.7.7h-.7c-.4 0-.7-.3-.7-.7v-.7H7.7v.7c0 .4-.3.7-.7.7h-.8c-.4 0-.7-.3-.7-.7v-5.7L7 9c.2-.4.6-.6 1-.7h8.1c.4 0 .8.3.9.7l1.5 4.3V19z" />
  </svg>
)

export default Carport
