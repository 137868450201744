import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  src: PropTypes.string,
  fallback: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]).isRequired,
  loadingImg: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  role: PropTypes.string,
  alt: PropTypes.string,
  onLoad: PropTypes.func,
  onError: PropTypes.func
}

const defaultProps = {
  loadingImg: null,
  role: null,
  alt: null
}

/**
 * image with a fallback in case there is an issue loading the image requested
 * (adapted from https://github.com/socialtables/react-image-fallback)
 */
class ImageFallback extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      imgSource: props.loadingImg
    }
    this._setImg = this._setImg.bind(this)
  }

  componentDidMount() {
    this.img = new window.Image()
    this._setImg({ image: this.props.src, fallbacks: this.props.fallback })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this._setImg({ image: nextProps.src, fallbacks: nextProps.fallback })
    }
  }

  _setImg({ image, fallbacks }) {
    // push all the images into an array
    const imagesArray = [image]
      .concat(fallbacks)
      .filter((fallback) => !!fallback)

    // on error try to use the fallback(s)
    this.img.onerror = () => {
      if (imagesArray.length > 2 && typeof imagesArray[1] === 'string') {
        // if there are multiple fallbacks, set the first as our image
        // and set whatever left as the new fallbacks
        const updatedFallbacks = imagesArray.slice(2)
        this._setImg({ image: imagesArray[1], fallbacks: updatedFallbacks })
        return
      }
      // set the image state
      this.setState(
        {
          imgSource: imagesArray[1] || null
        },
        () => {
          // call our callback, if any
          if (this.props.onError) {
            this.props.onError(this.props.src)
          }
        }
      )
    }

    // on load, rejoice (and call the callback if set)
    this.img.onload = () => {
      this.setState(
        {
          imgSource: imagesArray[0]
        },
        () => {
          if (this.props.onLoad) {
            this.props.onLoad(imagesArray[0])
          }
        }
      )
    }

    if (typeof imagesArray[0] === 'string') {
      this.img.src = imagesArray[0]
    } else {
      this.setState(
        {
          imgSource: imagesArray[0]
        },
        () => {
          if (this.props.onLoad) {
            this.props.onLoad(imagesArray[0])
          }
        }
      )
    }
  }

  render() {
    if (typeof this.state.imgSource === 'string') {
      return (
        <img
          src={this.state.imgSource}
          role={this.props.role}
          alt={this.props.alt}
        />
      )
    } else {
      return this.state.imgSource
    }
  }

  /**
   * cleanup on unmount
   */
  componentWillUnmount() {
    if (this.img) {
      this.img.onerror = null
      this.img.onload = null
      this.img = null
    }
  }
}

ImageFallback.propTypes = propTypes
ImageFallback.defaultProps = defaultProps

export default ImageFallback
