import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { wizardSteps } from 'constants/UIConstants'

const propTypes = {
  wizardStep: PropTypes.number
}

const defaultProps = {
  wizardStep: 0
}

class WizardProgress extends Component {
  render = () => {
    const totalSteps = wizardSteps.length - 2
    const percentComplete = (this.props.wizardStep / totalSteps) * 100
    const completeStyle = { width: `${percentComplete}%` }
    const title = `Step ${this.props.wizardStep}/${totalSteps}: ${wizardSteps[this.props.wizardStep].title}`

    return (
      <div id="wizard-progress">
        <div className="progress-title">
          <h2>{title}</h2>
        </div>
        <div className="progress-container">
          <div className="progress-complete" style={completeStyle}></div>
        </div>
        <div className="progress-label md-text-right">{`${Math.round(percentComplete)}% complete`}</div>
      </div>
    )
  }
}

WizardProgress.propTypes = propTypes
WizardProgress.defaultProps = defaultProps

export default WizardProgress
