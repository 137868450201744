import React from 'react'

const LocalHotel = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`local_hotel`}</title>
    <path d="M18.984 6.984c2.203 0 4.031 1.828 4.031 4.031v9h-2.016v-3h-18v3H.983v-15h2.016v9h8.016V6.984h7.969zm-12 6c-1.641 0-3-1.359-3-3s1.359-3 3-3 3 1.359 3 3-1.359 3-3 3z" />
  </svg>
)

export default LocalHotel
