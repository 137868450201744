import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { updateMapMarkers } from 'redux/modules/map'
import { defineMessages, injectIntl, intlShape } from 'react-intl'
import DocumentTitle from 'react-document-title'
import {
  Avatar,
  Button,
  Card,
  CardText,
  CardTitle,
  Divider,
  FontIcon,
  List,
  ListItem,
  Media,
  MediaOverlay,
  Toolbar
} from 'react-md'
import formatAddress from 'components/Address/FormatAddress'
import RecommendationsMarkers from 'components/MarkerHelpers/RecommendationsMarkers'
import GuidebookMarker from 'components/MarkerHelpers/GuidebookMarker'
import Translator from 'components/Translator'
import AsHtml from 'components/Translator/AsHtml'
import { fieldHasContent } from 'utils/ValidationHelpers'
import { resizeImage } from 'utils/Image'
import { translateString } from 'utils/I18nHelper'
import { openMap } from 'utils/Map'
import { renderHours } from 'utils/Dates'
import { customColor } from 'utils/Color'
// import { trackEvent, getHostIdAndKey } from 'utils/Segment';
import { updateViatorLink } from 'utils/Urls'
import { labelMessages } from 'constants/UIConstants'
import { guidebookShape, recommendationShape } from 'constants/SharedPropTypes'

class Recommendation extends Component {
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this)
  }

  formatMessage = this.props.intl.formatMessage

  messages = defineMessages({
    unknownCtaLabel: {
      id: 'rec.ctaLabel',
      defaultMessage: 'Book'
    },
    urlCopiedToClipboard: {
      id: 'rec.urlCopiedToClipboard',
      defaultMessage: 'The URL has been copied to the clipboard.'
    },
    visitWebsite: {
      id: 'rec.visitWebsite',
      defaultMessage: 'Visit Website'
    }
  })

  componentDidMount() {
    if (
      this.props.guidebook.key &&
      this.props.guidebook.key !== 'loading' &&
      this.props.guidebook.host_user_id
    ) {
      // const recommendation = this.findRecommendation();
      // OLD SEGMENT CODE
      // let eventData = {
      //   host_user_id: this.props.guidebook.host_user_id,
      //   key: recommendation.key,
      //   label: recommendation.key,
      //   category: 'Recommendation'
      // };
      // trackEvent('recommendationViewed', eventData);
    }
    // update which markers we want to show on the map
    this.updateMap()
  }

  componentDidUpdate() {
    // update which markers we want to show on the map
    this.updateMap()
  }

  updateMap() {
    const { guidebook } = this.props
    const recommendation = this.findRecommendation()
    if (guidebook && recommendation) {
      const markers = this.renderMarkers(guidebook, [recommendation])
      this.props.dispatch(updateMapMarkers(markers))
    }
  }

  findRecommendation() {
    const self = this
    const recommendation = this.props.guidebook.recommendations.find(
      function (rec) {
        return rec.key === self.context.router.route.match.params.recKey
      }
    )
    return recommendation
  }

  renderMarkers = (guidebook, recommendations) => {
    const rec = this.findRecommendation()
    if (!guidebook.id || rec.address === null) {
      return []
    }

    const markers = RecommendationsMarkers(
      recommendations,
      100,
      true,
      null,
      null,
      guidebook.theme
    )
    // place the marker for this Guidebook
    if (!this.props.recsOnly) {
      markers.push(
        GuidebookMarker(guidebook, 75, true, null, null, guidebook.theme)
      )
    }
    return markers
  }

  trackCallClick = () => {
    // const rec = this.findRecommendation();
    // OLD SEGMENT CODE
    // let eventData = getHostIdAndKey(this.props.guidebook);
    // eventData.label = rec.key;
    // eventData.category = 'Recommendation';
    // trackEvent('recPhoneNumberClicked', eventData);
  }

  trackWebsiteClick = (website) => {
    // const rec = this.findRecommendation();
    // OLD SEGMENT CODE
    // let eventData = getHostIdAndKey(this.props.guidebook);
    // eventData.label = rec.key;
    // eventData.category = 'Recommendation';
    var windowReference = window.open()
    // trackEvent('recWebsiteClicked', eventData, {}, function(){
    windowReference.location = website
    // });
  }

  goBack = () => {
    // work out where 'back' actually is, as they could have just landed on this page
    const { category, mapCategory, essentialsTab } =
      this.context.router.route.match.params
    const baseGuidebookUrl = this.buildBaseGuidebookUrl()
    if (mapCategory) {
      // they are viewing from the map
      this.props.dispatch(push(`${baseGuidebookUrl}/map/${mapCategory}`))
    } else if (category) {
      // the came from a rec category
      this.props.dispatch(
        push(`${baseGuidebookUrl}/recommendations/${category}`)
      )
    } else {
      if (essentialsTab) {
        this.props.dispatch(push(`${baseGuidebookUrl}/${essentialsTab}`))
      } else {
        // not sure, just go home
        this.props.dispatch(push(baseGuidebookUrl))
      }
    }
  }

  buildBaseGuidebookUrl = () => {
    const { recsOnly, guidebook } = this.props
    if (recsOnly && guidebook.recs_only_enabled && guidebook.recs_only_key) {
      return `/recs/${guidebook.recs_only_key}`
    } else {
      return `/${guidebook.key}`
    }
  }

  render() {
    const { guidebook } = this.props
    const rec = this.findRecommendation()
    if (!rec) {
      // if this rec is not found, just load the category page
      // work out where 'back' actually is, as they could have just landed on this page
      const { category } = this.context.router.route.match.params
      const baseGuidebookUrl = this.buildBaseGuidebookUrl()
      this.props.dispatch(
        push(`${baseGuidebookUrl}/recommendations/${category}`)
      )
      return null
    }
    const nav = (
      <Button key="nav" icon onClick={this.goBack}>
        keyboard_backspace
      </Button>
    )
    const quote = (
      <AsHtml
        text={rec.why_recommended}
        translations={rec.why_recommended_txn}
      />
    )
    const name = <Translator text={rec.name} translations={rec.name_txn} />
    const nameString = translateString(
      this.props.intl.locale,
      rec.name,
      rec.name_txn
    )
    const content = fieldHasContent(rec.content) ? (
      <CardText dir="auto">
        <AsHtml text={rec.content} translations={rec.content_txn} />
      </CardText>
    ) : null
    const image = resizeImage(rec.image, 748, 561, true)
    let addressListItem = null
    if (rec.address !== null) {
      //"Street Address Line 1 (including unit/house number"
      const { addressPrimary, addressSecondary } = formatAddress(rec.address)
      addressListItem = (
        <ListItem
          leftAvatar={<Avatar suffix="blue" icon={<FontIcon>map</FontIcon>} />}
          primaryText={addressPrimary}
          secondaryText={addressSecondary}
          onClick={(event) => openMap(event, rec.address)}
        />
      )
    }

    const actions = []
    var ctaButtonLabel = ''
    // console.log(rec);
    if (rec.phone_number) {
      actions.push(
        <Button
          target="new"
          href={`tel:${rec.phone_number}`}
          className="hf-rec-button"
          iconChildren="phone"
          onClick={this.trackCallClick}
          flat
          secondary
        >
          {rec.phone_number}
        </Button>
      )
    }
    if (rec.website) {
      const visitText = this.formatMessage(this.messages.visitWebsite)
      let website = rec.website
      if (rec.sponsored) {
        // console.log(this.props.guidebook);
        if (typeof rec.sponsor_cta !== 'undefined' && rec.sponsor_cta !== '') {
          ctaButtonLabel = (
            <AsHtml text={rec.sponsor_cta} translations={rec.sponsor_cta_txn} />
          )
        } else {
          ctaButtonLabel = this.formatMessage(this.messages.unknownCtaLabel)
        }
        if (rec.sponsor_source.toLowerCase() === 'viator') {
          website = updateViatorLink(
            rec.website,
            this.props.guidebook.viator_partner_id,
            guidebook.host_user_id,
            guidebook.id
          )
        }
        actions.push(
          <Button
            target="new"
            className="hf-rec-button"
            iconChildren="local_addractions"
            flat
            secondary
            onClick={() => this.trackWebsiteClick(website)}
          >
            {ctaButtonLabel}
          </Button>
        )
      } else {
        actions.push(
          <Button
            target="new"
            className="hf-rec-button"
            iconChildren="link"
            flat
            secondary
            onClick={() => this.trackWebsiteClick(website)}
          >
            {visitText}
          </Button>
        )
      }
    }

    let categoryColor = customColor(
      rec.category.name.toLowerCase(),
      this.props.guidebook.theme,
      rec.category.color
    )
    // only if we have an overridden color
    let toolBarStyle = {}
    if (categoryColor !== rec.category.color || !rec.category.is_default) {
      toolBarStyle = { background: categoryColor }
    }

    return (
      <DocumentTitle
        title={nameString + ' - ' + this.formatMessage(labelMessages.recTitle)}
      >
        <Card raise={true} className="recommendation-view">
          <Media aspectRatio="4-3">
            <img src={image} alt="" />
            <MediaOverlay>
              <CardTitle title={name} />
            </MediaOverlay>
          </Media>
          <Toolbar
            colored
            className="hf-orange hf-rec-buttons"
            nav={nav}
            actions={actions}
            style={toolBarStyle}
          />
          <CardText dir="auto">
            <p className="host-quote">{quote}</p>
          </CardText>
          <List>
            <Divider />
            {addressListItem}
          </List>
          {renderHours(rec)}
          {content}
        </Card>
      </DocumentTitle>
    )
  }
}

Recommendation.propTypes = {
  guidebook: guidebookShape,
  recommendation: recommendationShape,
  recsOnly: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired
}
Recommendation.defaultProps = {
  recsOnly: false
}
Recommendation.contextTypes = {
  router: PropTypes.object
}

function mapStateToProps(state, props) {
  return {
    guidebook: state.guidebook.data
  }
}

export default connect(mapStateToProps)(injectIntl(Recommendation))
