import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setPrintReadyState } from 'redux/modules/printReadyState'
const propTypes = {
  dispatch: PropTypes.func.isRequired,
  imageId: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  imageRole: PropTypes.string,
  imageAlt: PropTypes.string,
  imageStyle: PropTypes.object
}

const defaultProps = {
  imageUrl:
    'https://storage.googleapis.com/hostfully-wp-1/blank-state-images.png',
  className: ''
}

class PrintReadyImage extends PureComponent {
  componentWillMount() {
    this.props.dispatch(setPrintReadyState(this.props.imageId, false))
  }

  imageReady = () => {
    this.props.dispatch(setPrintReadyState(this.props.imageId, true))
    let ele = document.getElementById(this.props.imageId)
    if (ele) {
      ele.classList.remove('image-loading')
    }
  }

  render() {
    return (
      <div>
        <img
          id={this.props.imageId}
          src={this.props.imageUrl}
          alt={this.props.imageAlt ? this.props.imageAlt : 'What to expect'}
          onLoad={this.imageReady}
          className={'image-loading ' + this.props.className}
          role={this.props.imageRole ? this.props.imageRole : 'presentation'}
          style={this.props.imageStyle ? this.props.imageStyle : {}}
        />
      </div>
    )
  }
}

PrintReadyImage.propTypes = propTypes
PrintReadyImage.defaultProps = defaultProps

export default connect(null)(PrintReadyImage)
