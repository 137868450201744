import currentUser from 'utils/CurrentUser'
// import { sha256 } from 'js-sha256';

export const psSignup = () => {
  if (!window.growsumo) return

  const user = currentUser()
  // don't identify if we don't know the user's email
  const email = user && user.user && user.user.email ? user.user.email : ''
  // console.log(user);
  if (email) {
    window.growsumo.data.email = email
    // console.log(email);
    // const userId = user.id;
    window.growsumo.data.customer_key = email
    // console.log(userId);
    // const plan = (user && user.membership && user.membership.name) ? user.membership.name : '';
    // console.log(plan);
    const name = user && user.user.display_name ? user.user.display_name : ''
    window.growsumo.data.name = name
    // console.log(name);
    console.log(window.growsumo)
    window.growsumo.createSignup()
  }
}
