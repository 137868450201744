import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import { FontIcon, Paper, Toolbar } from 'react-md'
import { connect } from 'react-redux'
import { actions, Errors, Form } from 'react-redux-form'
import { push } from 'react-router-redux'

import CheckBoxForm from 'components/CheckBoxForm'
import InputTextField from 'components/InputTextField'
import LocaleForm from 'components/LocaleForm'
import MultilangWysiwyg from 'components/MultilangWysiwyg'
import TooltipButton from 'components/TooltipButton'
import VoiceForm from 'components/VoiceForm'
import { hostUIMessages } from 'constants/UIConstants'
import { copyItem, saveItem, showItems } from 'redux/modules/crud'
import blankEditStates from 'redux/modules/crud/blankEditStates'
import currentUser from 'utils/CurrentUser'

import FormCardActions from './FormCardActions'
import ManageSelectedGuidebooks from './ManageSelectedCards'
import ManageGuidebooksAndTemplates from './ManageSelectedCards/ManageGuidebooksAndTemplates'

class ParkingForm extends Component {
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this)
    this.copyCard = this.copyCard.bind(this)
    this.saveAndAddAnother = this.saveAndAddAnother.bind(this)
  }

  goBack = () => {
    const { dispatch, pluralName, guidebookId, ownerPluralName } = this.props
    if (guidebookId) {
      const path = '#parkings#parking'
      dispatch(push(`/host/${ownerPluralName}/${guidebookId}${path}`))
    } else if (window.isIframe) {
      window.history.back()
    } else {
      dispatch(showItems(pluralName))
    }
  }

  copyCard = (id) => {
    const { dispatch, pluralName } = this.props
    dispatch(copyItem(pluralName, id))
  }

  handleSubmit(item) {
    const { itemId, dispatch, guidebookId, copy } = this.props
    const id = copy ? null : itemId

    const addAnother = this.addAnother
    this.addAnother = false
    const currentGuidebook = guidebookId ? this.props.owner_data : null
    dispatch(
      saveItem('parkings', 'parking', id, item, addAnother, currentGuidebook)
    )
  }

  formHasErrors = (formErrors) => {
    const edit_form = this.props.edit_form
    for (var field in formErrors) {
      if (edit_form[field] && !edit_form[field].valid) {
        return false
      }
    }
    return true
  }

  saveAndAddAnother = (e) => {
    const { dispatch } = this.props
    this.addAnother = true
    dispatch(actions.submit('edit_parking'))
  }

  formatMessage = this.props.intl.formatMessage

  componentDidMount() {
    const { dispatch, itemId } = this.props
    if (itemId === 'create') {
      let newItem = blankEditStates.parking.data
      // also if we're making a new one and our "owner" has locales, default to those
      if (this.props.owner_data && this.props.owner_data.locales.length > 0) {
        newItem = Object.assign({}, newItem, {
          locales: this.props.owner_data.locales
        })
      }
      dispatch(actions.load('edit_parking', newItem))
    }
  }

  componentWillUnmount() {
    //clear redux state for next item
    this.props.dispatch(actions.reset('edit_parking'))
  }

  render() {
    const self = this
    const { edit_parking, itemId, copy, guidebookId, ownerPluralName } =
      this.props
    const locales = edit_parking.locales
    const title = copy
      ? 'Copy parking card'
      : itemId === 'create'
        ? 'New parking card'
        : 'Edit parking card'
    const nav = (
      <TooltipButton
        key="nav"
        onClick={self.goBack}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>keyboard_backspace</FontIcon>
      </TooltipButton>
    )
    const actions = []
    const formErrors = []
    const errorMessages = {
      formwide: {
        valid: 'Oops! Unable to save:'
      },
      label: {
        required: 'Please provide a name for this card'
      }
    }
    formErrors['formwide'] = (
      <Errors
        model="edit_parking"
        messages={errorMessages.formwide}
        wrapper="h2"
        className="md-text-field-message title hf-error"
        show={(form) => form.submitFailed}
      />
    )
    formErrors['label'] = (
      <Errors
        model=".label"
        messages={errorMessages.label}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )

    const user = currentUser()
    if (edit_parking.id && user.canCopy && !copy && !guidebookId) {
      actions.push(
        <TooltipButton
          key="copy"
          onClick={() => {
            self.copyCard(edit_parking.id)
          }}
          tooltipLabel="Copy"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>content_copy</FontIcon>
        </TooltipButton>
      )
    }

    const validators = {
      '': {
        // Form-level validator
        valid: () => this.formHasErrors(formErrors)
      },
      label: {
        required: (val) => val && val.length
      }
    }

    return (
      <DocumentTitle title="Parking">
        <div className="hf-parkings-paper">
          <Toolbar
            colored
            className="hf-edit-toolbar hf-parkings"
            title={title}
            nav={nav}
            actions={actions}
          />
          <Paper key="category" className="hf-form-wrapper">
            <Form
              model="edit_parking"
              onSubmit={(v) => this.handleSubmit(v)}
              validators={validators}
            >
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {this.formatMessage(hostUIMessages['nameHeading'])}
                  </h2>
                  <InputTextField
                    model=".label"
                    id="label"
                    label="Card name *"
                  />
                  {formErrors['label']}
                </div>

                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {this.formatMessage(hostUIMessages['languageHeading'])}
                  </h2>
                  <p className="md-body-1">
                    {this.formatMessage(hostUIMessages['languageSubHeading'])}
                  </p>
                  <LocaleForm editModel="edit_parking" />
                </div>

                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    Select the statements that best describe your advice to
                    guests about parking at the property
                  </h2>
                  <CheckBoxForm
                    model=".driveway_available"
                    label="Driveway parking available"
                    className="hf-taller-selection-control-container hf-wider-selection-control-container"
                  />
                  <CheckBoxForm
                    model=".on_street_available"
                    label="On-Street parking available"
                    className="hf-taller-selection-control-container hf-wider-selection-control-container"
                  />
                  <CheckBoxForm
                    model=".economical_available"
                    label="Economical parking available"
                    className="hf-taller-selection-control-container hf-wider-selection-control-container"
                  />
                  <CheckBoxForm
                    model=".expensive_nearby"
                    label="Parking is expensive nearby"
                    className="hf-taller-selection-control-container hf-wider-selection-control-container"
                  />
                </div>

                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    What other information does your guest need to know if they
                    are going to park a vehicle during their stay?
                  </h2>
                  <p className="md-body-1">
                    You can add images in this area. You can add other languages
                    at the top of this form
                  </p>
                  <MultilangWysiwyg
                    model="edit_parking"
                    field="parking_text"
                    txn_field="parking_text_txn"
                    locales={locales}
                    className="hf-taller-selection-control-container hf-wider-selection-control-container"
                  />
                </div>
              </div>
              <VoiceForm editModel="edit_parking" locales={locales} />
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  {user.isEnterprise ? (
                    <ManageGuidebooksAndTemplates
                      ownerPluralName="parkings"
                      ownerSingularName="parking"
                      guidebookId={guidebookId}
                      cardType="Parking"
                      activeTab={ownerPluralName}
                      replaceWarning={true}
                      active={true}
                    />
                  ) : (
                    <ManageSelectedGuidebooks
                      ownerPluralName="parkings"
                      ownerSingularName="parking"
                      guidebookId={guidebookId}
                      cardType="Parking"
                      replaceWarning={true}
                      active={true}
                    />
                  )}
                </div>
              </div>
              <div className="md-grid hf-headline-margin">
                <div className="md-cell md-cell--12">
                  <div>{formErrors['formwide']}</div>
                  <div>{formErrors['label']}</div>
                </div>
              </div>
              <FormCardActions
                guidebookId={guidebookId}
                saveAndAdd={this.saveAndAddAnother}
              />
            </Form>
          </Paper>
        </div>
      </DocumentTitle>
    )
  }
}

ParkingForm.propTypes = {
  ownerSingularName: PropTypes.string,
  ownerPluralName: PropTypes.string
}

function mapStateToProps(state, props) {
  const { ownerSingularName } = props
  const edit_parking = state.edit_parking
  const owner_data = state[`edit_${ownerSingularName}`]
  const edit_form = state.forms.edit_parking
  return {
    edit_parking,
    owner_data,
    edit_form
  }
}

export default connect(mapStateToProps)(ParkingForm)
