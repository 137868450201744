import React from 'react'

const LocalLaundryService = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`local_laundry_service`}</title>
    <path d="M12 20.016c3.328 0 6-2.672 6-6s-2.672-6-6-6-6 2.672-6 6 2.672 6 6 6zM6.984 3.984c-.563 0-.984.469-.984 1.031s.422.984.984.984 1.031-.422 1.031-.984-.469-1.031-1.031-1.031zm3 0c-.563 0-.984.469-.984 1.031s.422.984.984.984 1.031-.422 1.031-.984-.469-1.031-1.031-1.031zM18 2.016c1.125 0 2.016.844 2.016 1.969v16.031c0 1.125-.891 1.969-2.016 1.969H6c-1.125 0-2.016-.844-2.016-1.969V3.985c0-1.125.891-1.969 2.016-1.969h12zM9.188 16.828l5.625-5.672c1.547 1.547 1.547 4.125 0 5.672s-4.078 1.547-5.625 0z" />
  </svg>
)

export default LocalLaundryService
