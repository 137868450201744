import React from 'react'

const Mickey = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`mickey`}</title>
    <path d="M21.179 4.821c-1.997-1.997-4.815-2.416-6.294-.937-.614.614-.883 1.462-.866 2.383A8 8 0 0012 6a8 8 0 00-2.019.267c.017-.92-.252-1.769-.866-2.383-1.479-1.479-4.297-1.06-6.294.937s-2.416 4.815-.937 6.294c.614.614 1.462.883 2.383.866A8 8 0 004 14a8 8 0 0016 0 8 8 0 00-.267-2.019c.92.017 1.769-.252 2.383-.866 1.479-1.479 1.059-4.297-.937-6.294z" />
  </svg>
)

export default Mickey
