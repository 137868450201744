import React from 'react'

const Favorite = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`favorite`}</title>
    <path d="M12 21.328l-1.453-1.313c-5.156-4.688-8.531-7.734-8.531-11.531C2.016 5.39 4.407 3 7.5 3c1.734 0 3.422.844 4.5 2.109C13.078 3.843 14.766 3 16.5 3c3.094 0 5.484 2.391 5.484 5.484 0 3.797-3.375 6.891-8.531 11.578z" />
  </svg>
)

export default Favorite
