import { getToken } from 'utils/Auth'

import 'whatwg-fetch'

export function acceptTerms(item) {
  const path = '/api/v1/guest_terms'
  const method = 'POST'
  const url = process.env.REACT_APP_API_URL + path
  const body = {}
  body['guest_term'] = item
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
}
