import React from 'react'
import PropTypes from 'prop-types'
import ManageSelectedCards from './ManageSelectedCards'

class ManageSelectedGuidebooks extends ManageSelectedCards {
  render() {
    const { showHeading, heading, replaceWarning } = this.props
    if (showHeading) {
      return (
        <div id="information" className="hf-editable-card-tabs">
          <h1 className="md-headline">
            <div>{heading}</div>
          </h1>
          {replaceWarning ? (
            <p>
              Note: Selecting a guidebook here will remove any other card of
              this type from that guidebook.
            </p>
          ) : null}
          <ManageSelectedCards
            pluralName="guidebooks"
            singularName="guidebook"
            {...this.props}
          />
        </div>
      )
    } else {
      return (
        <ManageSelectedCards
          pluralName="guidebooks"
          singularName="guidebook"
          {...this.props}
        />
      )
    }
  }
}

ManageSelectedGuidebooks.propTypes = {
  showHeading: PropTypes.bool,
  heading: PropTypes.string,
  replaceWarning: PropTypes.bool
}

ManageSelectedGuidebooks.defaultProps = {
  showHeading: true,
  replaceWarning: false,
  heading: 'Which guidebooks should this card appear on?'
}

export default ManageSelectedGuidebooks
