import React from 'react'

const Library = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`library`}</title>
    <path d="M2 2v20.7h20V2H2zm18.1 1.2h.8v4.1h-.8V3.2zm0 5.5h.8v6.1h-.8V8.7zm-3-5h.8v3.7h-.8V3.7zm0 5.6h.8v5.4h-.8V9.3zm-5.2-4.5H13v2.6h-1.1V4.8zm0 6.2H13v3.7h-1.1V11zm-1.1-6.9h.5v3.2h-.5V4.1zm0 6.1h.5v4.6h-.5v-4.6zm-1.1-7h.8v4.1h-.8V3.2zm0 5.5h.8v6.1h-.8V8.7zM7.8 4.5h1.6v2.8H7.8V4.5zm0 6.1h1.6v4.1H7.8v-4.1zm.5 7.2v4.1H6.7v-4.1h1.6zM6.7 3.7h.8v3.7h-.8V3.7zm0 5.6h.8v5.4h-.8V9.3zm-3.6 8.9h1.1v3.9H3.1v-3.9zm1.1-3.3H3.1v-6h1.1v6zm0-7.3H3.1V3.2h1.1v4.4zm.3 7.1v-4.1h1.6v4.1H4.5zm.8 2.6v4.6h-.8v-4.6h.8zm1.1 4.6h-.8v-6.1h.8v6.1zM4.8 7.4V4.5h1.6v2.8H4.8zm4.9 14.5H8.6v-5.4h.8l.3 5.4zm2.2 0H9.7v-4.1h1.6l.6 4.1zM13 16h1.1l-.8 5.9h-1.4L13 16zm.8 2h1.1v3.9h-1.4l.3-3.9zm1.1-3.3h-1.4V8.9h1.1l.3 5.8zm0-7.3h-1.4V3.2h1.1l.3 4.2zm.2 9.9h.5v4.6h-.8l.3-4.6zm1.1 4.6v-6.1h.8v6.1h-.8zm.9-7.2h-2.2v-4.1h1.6l.6 4.1zm0-7.3h-2.2V4.5h1.6l.6 2.9zm.2 10.4H19v4.1h-1.9l.2-4.1zm2.2 4.1v-5.4h.8v5.4h-.8zm.8-7.2h-2.2v-4.1h1.6l.6 4.1zm0-7.3h-2.2V4.5h1.6l.6 2.9zm.3 14.5v-4.1" />
  </svg>
)

export default Library
