import React from 'react'

const Tv = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`tv`}</title>
    <path d="M21 17.016v-12H3v12h18zM21 3c1.078 0 2.016.938 2.016 2.016l-.047 12A1.981 1.981 0 0121 18.985h-5.016v2.016H8.015v-2.016H2.999c-1.078 0-2.016-.891-2.016-1.969v-12C.983 3.938 1.921 3 2.999 3h18z" />
  </svg>
)

export default Tv
