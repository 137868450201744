export const isoCurrencies = [
  {
    key: 'AED',
    name: 'AED - UAE Dirham'
  },
  {
    key: 'AFN',
    name: 'AFN - Afghani'
  },
  {
    key: 'ALL',
    name: 'ALL - Lek'
  },
  {
    key: 'AMD',
    name: 'AMD - Armenian Dram'
  },
  {
    key: 'ANG',
    name: 'ANG - Netherlands Antillean Guilder'
  },
  {
    key: 'AOA',
    name: 'AOA - Kwanza'
  },
  {
    key: 'ARS',
    name: 'ARS - Argentine Peso'
  },
  {
    key: 'AUD',
    name: 'AUD - Australian Dollar'
  },
  {
    key: 'AWG',
    name: 'AWG - Aruban Florin'
  },
  {
    key: 'AZN',
    name: 'AZN - Azerbaijan Manat'
  },
  {
    key: 'BAM',
    name: 'BAM - Convertible Mark'
  },
  {
    key: 'BBD',
    name: 'BBD - Barbados Dollar'
  },
  {
    key: 'BDT',
    name: 'BDT - Taka'
  },
  {
    key: 'BGN',
    name: 'BGN - Bulgarian Lev'
  },
  {
    key: 'BHD',
    name: 'BHD - Bahraini Dinar'
  },
  {
    key: 'BIF',
    name: 'BIF - Burundi Franc'
  },
  {
    key: 'BMD',
    name: 'BMD - Bermudian Dollar'
  },
  {
    key: 'BND',
    name: 'BND - Brunei Dollar'
  },
  {
    key: 'BOB',
    name: 'BOB - Boliviano'
  },
  {
    key: 'BOV',
    name: 'BOV - Mvdol'
  },
  {
    key: 'BRL',
    name: 'BRL - Brazilian Real'
  },
  {
    key: 'BSD',
    name: 'BSD - Bahamian Dollar'
  },
  {
    key: 'BTN',
    name: 'BTN - Ngultrum'
  },
  {
    key: 'BWP',
    name: 'BWP - Pula'
  },
  {
    key: 'BYN',
    name: 'BYN - Belarusian Ruble'
  },
  {
    key: 'BZD',
    name: 'BZD - Belize Dollar'
  },
  {
    key: 'CAD',
    name: 'CAD - Canadian Dollar'
  },
  {
    key: 'CDF',
    name: 'CDF - Congolese Franc'
  },
  {
    key: 'CHE',
    name: 'CHE - WIR Euro'
  },
  {
    key: 'CHF',
    name: 'CHF - Swiss Franc'
  },
  {
    key: 'CHW',
    name: 'CHW - WIR Franc'
  },
  {
    key: 'CLF',
    name: 'CLF - Unidad de Fomento'
  },
  {
    key: 'CLP',
    name: 'CLP - Chilean Peso'
  },
  {
    key: 'CNY',
    name: 'CNY - Yuan Renminbi'
  },
  {
    key: 'COP',
    name: 'COP - Colombian Peso'
  },
  {
    key: 'COU',
    name: 'COU - Unidad de Valor Real'
  },
  {
    key: 'CRC',
    name: 'CRC - Costa Rican Colon'
  },
  {
    key: 'CUC',
    name: 'CUC - Peso Convertible'
  },
  {
    key: 'CUP',
    name: 'CUP - Cuban Peso'
  },
  {
    key: 'CVE',
    name: 'CVE - Cabo Verde Escudo'
  },
  {
    key: 'CZK',
    name: 'CZK - Czech Koruna'
  },
  {
    key: 'DJF',
    name: 'DJF - Djibouti Franc'
  },
  {
    key: 'DKK',
    name: 'DKK - Danish Krone'
  },
  {
    key: 'DOP',
    name: 'DOP - Dominican Peso'
  },
  {
    key: 'DZD',
    name: 'DZD - Algerian Dinar'
  },
  {
    key: 'EGP',
    name: 'EGP - Egyptian Pound'
  },
  {
    key: 'ERN',
    name: 'ERN - Nakfa'
  },
  {
    key: 'ETB',
    name: 'ETB - Ethiopian Birr'
  },
  {
    key: 'EUR',
    name: 'EUR - Euro'
  },
  {
    key: 'FJD',
    name: 'FJD - Fiji Dollar'
  },
  {
    key: 'FKP',
    name: 'FKP - Falkland Islands Pound'
  },
  {
    key: 'GBP',
    name: 'GBP - Pound Sterling'
  },
  {
    key: 'GEL',
    name: 'GEL - Lari'
  },
  {
    key: 'GHS',
    name: 'GHS - Ghana Cedi'
  },
  {
    key: 'GIP',
    name: 'GIP - Gibraltar Pound'
  },
  {
    key: 'GMD',
    name: 'GMD - Dalasi'
  },
  {
    key: 'GNF',
    name: 'GNF - Guinean Franc'
  },
  {
    key: 'GTQ',
    name: 'GTQ - Quetzal'
  },
  {
    key: 'GYD',
    name: 'GYD - Guyana Dollar'
  },
  {
    key: 'HKD',
    name: 'HKD - Hong Kong Dollar'
  },
  {
    key: 'HNL',
    name: 'HNL - Lempira'
  },
  {
    key: 'HRK',
    name: 'HRK - Kuna'
  },
  {
    key: 'HTG',
    name: 'HTG - Gourde'
  },
  {
    key: 'HUF',
    name: 'HUF - Forint'
  },
  {
    key: 'IDR',
    name: 'IDR - Rupiah'
  },
  {
    key: 'ILS',
    name: 'ILS - New Israeli Sheqel'
  },
  {
    key: 'INR',
    name: 'INR - Indian Rupee'
  },
  {
    key: 'IQD',
    name: 'IQD - Iraqi Dinar'
  },
  {
    key: 'IRR',
    name: 'IRR - Iranian Rial'
  },
  {
    key: 'ISK',
    name: 'ISK - Iceland Krona'
  },
  {
    key: 'JMD',
    name: 'JMD - Jamaican Dollar'
  },
  {
    key: 'JOD',
    name: 'JOD - Jordanian Dinar'
  },
  {
    key: 'JPY',
    name: 'JPY - Yen'
  },
  {
    key: 'KES',
    name: 'KES - Kenyan Shilling'
  },
  {
    key: 'KGS',
    name: 'KGS - Som'
  },
  {
    key: 'KHR',
    name: 'KHR - Riel'
  },
  {
    key: 'KMF',
    name: 'KMF - Comorian Franc'
  },
  {
    key: 'KPW',
    name: 'KPW - North Korean Won'
  },
  {
    key: 'KRW',
    name: 'KRW - Won'
  },
  {
    key: 'KWD',
    name: 'KWD - Kuwaiti Dinar'
  },
  {
    key: 'KYD',
    name: 'KYD - Cayman Islands Dollar'
  },
  {
    key: 'KZT',
    name: 'KZT - Tenge'
  },
  {
    key: 'LAK',
    name: 'LAK - Lao Kip'
  },
  {
    key: 'LBP',
    name: 'LBP - Lebanese Pound'
  },
  {
    key: 'LKR',
    name: 'LKR - Sri Lanka Rupee'
  },
  {
    key: 'LRD',
    name: 'LRD - Liberian Dollar'
  },
  {
    key: 'LSL',
    name: 'LSL - Loti'
  },
  {
    key: 'LYD',
    name: 'LYD - Libyan Dinar'
  },
  {
    key: 'MAD',
    name: 'MAD - Moroccan Dirham'
  },
  {
    key: 'MDL',
    name: 'MDL - Moldovan Leu'
  },
  {
    key: 'MGA',
    name: 'MGA - Malagasy Ariary'
  },
  {
    key: 'MKD',
    name: 'MKD - Denar'
  },
  {
    key: 'MMK',
    name: 'MMK - Kyat'
  },
  {
    key: 'MNT',
    name: 'MNT - Tugrik'
  },
  {
    key: 'MOP',
    name: 'MOP - Pataca'
  },
  {
    key: 'MRU',
    name: 'MRU - Ouguiya'
  },
  {
    key: 'MUR',
    name: 'MUR - Mauritius Rupee'
  },
  {
    key: 'MVR',
    name: 'MVR - Rufiyaa'
  },
  {
    key: 'MWK',
    name: 'MWK - Malawi Kwacha'
  },
  {
    key: 'MXN',
    name: 'MXN - Mexican Peso'
  },
  {
    key: 'MXV',
    name: 'MXV - Mexican Unidad de Inversion (UDI)'
  },
  {
    key: 'MYR',
    name: 'MYR - Malaysian Ringgit'
  },
  {
    key: 'MZN',
    name: 'MZN - Mozambique Metical'
  },
  {
    key: 'NAD',
    name: 'NAD - Namibia Dollar'
  },
  {
    key: 'NGN',
    name: 'NGN - Naira'
  },
  {
    key: 'NIO',
    name: 'NIO - Cordoba Oro'
  },
  {
    key: 'NOK',
    name: 'NOK - Norwegian Krone'
  },
  {
    key: 'NPR',
    name: 'NPR - Nepalese Rupee'
  },
  {
    key: 'NZD',
    name: 'NZD - New Zealand Dollar'
  },
  {
    key: 'OMR',
    name: 'OMR - Rial Omani'
  },
  {
    key: 'PAB',
    name: 'PAB - Balboa'
  },
  {
    key: 'PEN',
    name: 'PEN - Sol'
  },
  {
    key: 'PGK',
    name: 'PGK - Kina'
  },
  {
    key: 'PHP',
    name: 'PHP - Philippine Peso'
  },
  {
    key: 'PKR',
    name: 'PKR - Pakistan Rupee'
  },
  {
    key: 'PLN',
    name: 'PLN - Zloty'
  },
  {
    key: 'PYG',
    name: 'PYG - Guarani'
  },
  {
    key: 'QAR',
    name: 'QAR - Qatari Rial'
  },
  {
    key: 'RON',
    name: 'RON - Romanian Leu'
  },
  {
    key: 'RSD',
    name: 'RSD - Serbian Dinar'
  },
  {
    key: 'RUB',
    name: 'RUB - Russian Ruble'
  },
  {
    key: 'RWF',
    name: 'RWF - Rwanda Franc'
  },
  {
    key: 'SAR',
    name: 'SAR - Saudi Riyal'
  },
  {
    key: 'SBD',
    name: 'SBD - Solomon Islands Dollar'
  },
  {
    key: 'SCR',
    name: 'SCR - Seychelles Rupee'
  },
  {
    key: 'SDG',
    name: 'SDG - Sudanese Pound'
  },
  {
    key: 'SEK',
    name: 'SEK - Swedish Krona'
  },
  {
    key: 'SGD',
    name: 'SGD - Singapore Dollar'
  },
  {
    key: 'SHP',
    name: 'SHP - Saint Helena Pound'
  },
  {
    key: 'SLL',
    name: 'SLL - Leone'
  },
  {
    key: 'SOS',
    name: 'SOS - Somali Shilling'
  },
  {
    key: 'SRD',
    name: 'SRD - Surinam Dollar'
  },
  {
    key: 'SSP',
    name: 'SSP - South Sudanese Pound'
  },
  {
    key: 'STN',
    name: 'STN - Dobra'
  },
  {
    key: 'SVC',
    name: 'SVC - El Salvador Colon'
  },
  {
    key: 'SYP',
    name: 'SYP - Syrian Pound'
  },
  {
    key: 'SZL',
    name: 'SZL - Lilangeni'
  },
  {
    key: 'THB',
    name: 'THB - Baht'
  },
  {
    key: 'TJS',
    name: 'TJS - Somoni'
  },
  {
    key: 'TMT',
    name: 'TMT - Turkmenistan New Manat'
  },
  {
    key: 'TND',
    name: 'TND - Tunisian Dinar'
  },
  {
    key: 'TOP',
    name: 'TOP - Pa’anga'
  },
  {
    key: 'TRY',
    name: 'TRY - Turkish Lira'
  },
  {
    key: 'TTD',
    name: 'TTD - Trinidad and Tobago Dollar'
  },
  {
    key: 'TWD',
    name: 'TWD - New Taiwan Dollar'
  },
  {
    key: 'TZS',
    name: 'TZS - Tanzanian Shilling'
  },
  {
    key: 'UAH',
    name: 'UAH - Hryvnia'
  },
  {
    key: 'UGX',
    name: 'UGX - Uganda Shilling'
  },
  {
    key: 'USD',
    name: 'USD - US Dollar'
  },
  {
    key: 'USN',
    name: 'USN - US Dollar (Next day)'
  },
  {
    key: 'UYI',
    name: 'UYI - Uruguay Peso en Unidades Indexadas (UI)'
  },
  {
    key: 'UYU',
    name: 'UYU - Peso Uruguayo'
  },
  {
    key: 'UYW',
    name: 'UYW - Unidad Previsional'
  },
  {
    key: 'UZS',
    name: 'UZS - Uzbekistan Sum'
  },
  {
    key: 'VES',
    name: 'VES - Bolívar Soberano'
  },
  {
    key: 'VND',
    name: 'VND - Dong'
  },
  {
    key: 'VUV',
    name: 'VUV - Vatu'
  },
  {
    key: 'WST',
    name: 'WST - Tala'
  },
  {
    key: 'XAF',
    name: 'XAF - CFA Franc BEAC'
  },
  {
    key: 'XAG',
    name: 'XAG - Silver'
  },
  {
    key: 'XAU',
    name: 'XAU - Gold'
  },
  {
    key: 'XBA',
    name: 'XBA - Bond Markets Unit European Composite Unit (EURCO)'
  },
  {
    key: 'XBB',
    name: 'XBB - Bond Markets Unit European Monetary Unit (E.M.U.-6)'
  },
  {
    key: 'XBC',
    name: 'XBC - Bond Markets Unit European Unit of Account 9 (E.U.A.-9)'
  },
  {
    key: 'XBD',
    name: 'XBD - Bond Markets Unit European Unit of Account 17 (E.U.A.-17)'
  },
  {
    key: 'XCD',
    name: 'XCD - East Caribbean Dollar'
  },
  {
    key: 'XDR',
    name: 'XDR - SDR (Special Drawing Right)'
  },
  {
    key: 'XOF',
    name: 'XOF - CFA Franc BCEAO'
  },
  {
    key: 'XPD',
    name: 'XPD - Palladium'
  },
  {
    key: 'XPF',
    name: 'XPF - CFP Franc'
  },
  {
    key: 'XPT',
    name: 'XPT - Platinum'
  },
  {
    key: 'XSU',
    name: 'XSU - Sucre'
  },
  {
    key: 'XTS',
    name: 'XTS - Codes specifically reserved for testing purposes'
  },
  {
    key: 'XUA',
    name: 'XUA - ADB Unit of Account'
  },
  {
    key: 'XXX',
    name: 'XXX - The codes assigned for transactions where no currency is involved'
  },
  {
    key: 'YER',
    name: 'YER - Yemeni Rial'
  },
  {
    key: 'ZAR',
    name: 'ZAR - Rand'
  },
  {
    key: 'ZMW',
    name: 'ZMW - Zambian Kwacha'
  },
  {
    key: 'ZWL',
    name: 'ZWL - Zimbabwe Dollar'
  }
]
