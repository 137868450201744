import React from 'react'

const Printer = (props) => (
  <svg width={24} height={24} viewBox="0 0 48 48" {...props}>
    <title>{`printer`}</title>
    <path d="M0 44c0 2.21 1.79 4 4 4h40c2.21 0 4-1.79 4-4V20c0-2.21-1.79-4-4-4h-3V4c0-2.21-1.79-4-4-4H11C8.79 0 7 1.79 7 4v12H4c-2.21 0-4 1.79-4 4v24zM36 7.2v11.6c0 1.22-.98 2.2-2.2 2.2H14.2c-1.22 0-2.2-.98-2.2-2.2V7.2c0-1.22.98-2.2 2.2-2.2h19.6c1.22 0 2.2.98 2.2 2.2zM39 43H9c-1.1 0-2-.9-2-2s.9-2 2-2h30c1.1 0 2 .9 2 2s-.9 2-2 2zM7 32.5a2.5 2.5 0 015 0 2.5 2.5 0 01-5 0z" />
  </svg>
)

export default Printer
