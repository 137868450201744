import React from 'react'
import { Button } from 'react-md'
import { withRouter } from 'react-router'

import ErrorScreen from 'components/ErrorScreen'

import Image403 from './sloth_pillow.svg'

const mapMessage = {
  403: {
    title: "We're sorry, you're not authorized to view that page.",
    description:
      'Please make sure you have entered the exact URL for the guidebook you are looking for!'
  },
  428: {
    title: "We're sorry, you're not authorized to view that guidebook.",
    description:
      'That guidebook requires a secure link to view. If you were not given one or yours is not working, please contact your host.'
  }
}

function NotAuthorized({ location }) {
  const code = location.pathname.replaceAll('/', '')

  const goBack = () => {
    window.history.back()
  }

  return (
    <ErrorScreen
      title={mapMessage[code].title}
      description={mapMessage[code].description}
      image={
        <img
          id="sloth-image"
          src={Image403}
          alt="sloth"
          height="256px"
          style={{ marginLeft: '48px' }}
        />
      }
      actions={
        <Button
          onClick={goBack}
          className="error-page__button"
          primary
          flat
          swapTheming
        >
          Go Back
        </Button>
      }
    />
  )
}

export default withRouter(NotAuthorized)
