import React from 'react'

const Recycle = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`recycle`}</title>
    <path d="M19.1 8.2c0 1-.1 1.8-.1 2.7 0 .5-.2.6-.6.3-1-.6-2.1-1.1-3.1-1.6-.4-.2-.5-.4 0-.7.1-.1.2-.2.4-.2.3-.1.4-.3.2-.6-.7-1.2-1.4-2.5-2.2-3.7-.9-1.3-2.6-1.3-3.5 0-.6.9-1.1 1.8-1.5 2.6-.3.4-.5.5-.9.3-1.6-.9-1.4-.6-.6-2 .3-.6.7-1.2 1-1.8 1.7-2.9 5.5-2.9 7.2 0 .7 1.1 1.4 2.3 2 3.5.2.4.4.5.8.2.7-.4.8-.4.8.4.1.2.1.5.1.6zM6.3 21.5c-.6 0-1.3.1-2.1-.1-2.7-.6-4.1-3.5-2.8-5.9.6-1.2 1.4-2.4 2.1-3.6.3-.4.2-.7-.2-.9-.2-.1-.5-.2-.5-.5 0-.2.3-.3.5-.4 1-.5 2-1 3-1.6.4-.2.6-.1.6.4 0 1.1.1 2.3.1 3.5 0 .6-.1.7-.6.4-.7-.4-.7-.4-1.1.3-.6 1-1.2 2.1-1.8 3.1-.9 1.6 0 3.2 1.9 3.2h2.7c.4 0 .6.2.6.6.1 1.5.1 1.5-1.4 1.5h-1zM16.8 21.6h-2.1c-.4 0-.6.1-.6.6 0 .9-.1.9-.8.4-.9-.6-1.9-1.2-2.8-1.7-.4-.2-.4-.4 0-.7 1-.6 2-1.3 3-1.9.5-.3.6-.2.6.3 0 .8 0 .8.9.8h3.6c1.7 0 2.7-1.6 1.8-3.1-.5-.8-.9-1.7-1.4-2.5-.2-.4-.2-.6.2-.8 1.6-.9 1.2-1 2.1.5.4.6.7 1.3 1.1 1.9 1.5 2.7-.4 6-3.5 6.1-.7.1-1.4 0-2.1.1 0-.1 0-.1 0 0z" />
  </svg>
)

export default Recycle
