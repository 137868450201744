import { getToken } from 'utils/Auth'

import 'whatwg-fetch'

export const STATUS_CHECK_LOADING = 'STATUS_CHECK_LOADING'
export const STATUS_CHECK_FETCH_DATA_SUCCESS = 'STATUS_CHECK_FETCH_DATA_SUCCESS'
export const STATUS_CHECK_HAS_ERRORED = 'STATUS_CHECK_HAS_ERRORED'

const initialState = {
  isLoading: false,
  hasErrored: false,
  data: {}
}

/* Reducers */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case STATUS_CHECK_HAS_ERRORED:
      return Object.assign({}, state, { hasErrored: action.hasErrored })
    case STATUS_CHECK_LOADING:
      return Object.assign({}, state, { isLoading: action.isLoading })
    case STATUS_CHECK_FETCH_DATA_SUCCESS:
      return Object.assign({}, state, {
        data: action.data,
        isLoading: action.isLoading
      })
    default:
      return state
  }
}

/* Actions */
export function statusCheckHasErrored(bool) {
  return {
    type: STATUS_CHECK_HAS_ERRORED,
    hasErrored: bool
  }
}
export function statusCheckIsLoading(bool) {
  return {
    type: STATUS_CHECK_LOADING,
    isLoading: bool
  }
}

export function statusCheckFetchDataSuccess(data) {
  return {
    type: STATUS_CHECK_FETCH_DATA_SUCCESS,
    isLoading: false,
    data: data
  }
}

export function checkStatus(cb) {
  const url =
    process.env.REACT_APP_API_URL + '/api/v1/orbirental/agencies/status'
  const method = 'GET'
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }
  return (dispatch) => {
    dispatch(statusCheckIsLoading(true))
    fetch(url, config)
      .then((response) => {
        if (!response.ok) {
          dispatch(statusCheckIsLoading(false))
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.json())
      .then((statusResponse) => {
        dispatch(statusCheckFetchDataSuccess(statusResponse.data))
        if (typeof cb === 'function') {
          cb(statusResponse.data)
        }
      })
      .catch(() => dispatch(statusCheckHasErrored(true)))
  }
}
