import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Avatar, FontIcon, NavigationDrawer } from 'react-md'

import AccountDrawer from 'components/AccountDrawer'
import logo from 'components/Header/logo_white.svg'
import { atLeastDesktop } from 'components/ScreenSize'
import Toast from 'components/Toast'
import currentUser from 'utils/CurrentUser'
import {
  bootIntercom,
  changeIntercomUser,
  shutdownIntercom,
  updateIntercom
} from 'utils/Intercom'
import { setupUserVoiceWidget, shutdownUserVoiceWidget } from 'utils/UserVoice'

import AdminNavItem from './AdminNavItem'
import HostNavItem from './HostNavItem'
import ReportNavItem from './ReportNavItem'
import SwitchNavItem from './SwitchNavItem'

class HostNavDrawer extends PureComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {
      drawerVisible: atLeastDesktop,
      accountDrawerVisible: false
    }
    this.intercomBooted = false
    this.intercomUser = null
    this.toggleAccountDrawer = this.toggleAccountDrawer.bind(this)
    this.openNavDrawer = this.openNavDrawer.bind(this)
    this.onVisibilityChange = this.onVisibilityChange.bind(this)
  }

  componentDidMount() {
    if (!window.isIframe) {
      bootIntercom('hostNav')
      setupUserVoiceWidget()
      this.intercomBooted = true
    }
  }

  componentWillUnmount() {
    if (!window.isIframe) {
      shutdownIntercom()
      shutdownUserVoiceWidget()
    }
  }

  componentWillUpdate(nextProps, _nextState, nextContext) {
    const currentUserId =
      this.props.auth && this.props.auth.user && this.props.auth.user.id
        ? this.props.auth.user.id
        : null
    const nextUserId =
      nextProps.auth && nextProps.auth.user && nextProps.auth.user.id
        ? nextProps.auth.user.id
        : null
    const currentPathName = this.context.router.route.location.pathname
    const nextPathName = nextContext.router.route.location.pathname
    // if the user changed, update intercom
    if (currentUserId !== nextUserId && this.intercomBooted) {
      changeIntercomUser()
    }
    // when the host changes paths, let's update intercom
    if (currentPathName !== nextPathName && this.intercomBooted) {
      updateIntercom()
    }
  }

  toggleAccountDrawer(state) {
    this.setState({
      accountDrawerVisible: state
    })
  }

  openNavDrawer() {
    this.setState({
      drawerVisible: true
    })
  }

  onVisibilityChange(visState) {
    this.setState({ drawerVisible: visState })
  }

  render() {
    const { drawerVisible, accountDrawerVisible } = this.state
    const user = currentUser()
    const logoComponent = (
      <div key="logo" className="hf-logo-container hf-host">
        <a href="/host">
          <img src={logo} alt="Hostfully logo" className="hf-logo" />
        </a>
      </div>
    )
    const drawerHeaderChildren = [logoComponent]
    const toolbarActions = []
    const avatarStyle = { cursor: 'pointer' }

    toolbarActions.push(
      <Avatar
        key="avatar"
        id="menu"
        src={user.user?.avatar}
        icon={<FontIcon>account_circle</FontIcon>}
        onClick={() => this.toggleAccountDrawer(!accountDrawerVisible)}
        style={avatarStyle}
      />
    )

    const navItems = [
      <HostNavItem key="hostnav" showTooltips={!drawerVisible} />
    ]

    if (user.canTheme || user.isAdmin) {
      navItems.push(
        <ReportNavItem
          key="reportNavItem"
          showTooltips={!drawerVisible}
          openNavDrawer={this.openNavDrawer}
        />
      )
    }

    if (user.isAdmin) {
      navItems.push(
        <AdminNavItem
          key="adminNavItem"
          showTooltips={!drawerVisible}
          openNavDrawer={this.openNavDrawer}
          user={user}
        />
      )
    }

    if (user.switchedTo && drawerVisible) {
      navItems.push(
        <SwitchNavItem
          key="switchNavItem"
          switchedFrom={user.switchedFrom}
          userEmail={user.user.email}
        />
      )
    }

    return (
      <NavigationDrawer
        drawerHeaderChildren={drawerHeaderChildren}
        navItems={navItems}
        drawerTransitionDuration={0}
        transitionDuration={0}
        mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY}
        tabletDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
        desktopDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
        drawerClassName="hf-drawer"
        toolbarActions={toolbarActions}
        contentId="main-desktop"
        onVisibilityChange={this.onVisibilityChange}
        visible={drawerVisible}
        toolbarThemeType="default"
        toolbarClassName="hf-toolbar"
        toolbarTitle={logoComponent}
      >
        <AccountDrawer
          visible={accountDrawerVisible}
          onVisibilityChange={this.toggleAccountDrawer}
          onClose={this.toggleAccountDrawer}
          useLocaleSwitcher={false}
        />
        {this.props.children}
        <Toast></Toast>
      </NavigationDrawer>
    )
  }
}

HostNavDrawer.propTypes = {
  dispatch: PropTypes.func,
  showNavDrawer: PropTypes.bool,
  onClose: PropTypes.func
}

HostNavDrawer.contextTypes = {
  router: PropTypes.object
}

export default HostNavDrawer
