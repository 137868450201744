import React from 'react'

const Dishwasher = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`dishwasher`}</title>
    <path d="M15.8 19c.3-.5.5-1 .5-1.7v-4.2c0-1.2-.5-1.8-.5-1.8V19zM16.8 19c.3-.5.5-1 .5-1.7v-4.2c0-1.2-.5-1.8-.5-1.8V19zM18.6 19c.3-.5.5-1 .5-1.7v-4.2c0-1.2-.5-1.8-.5-1.8V19zM17.4 9.7h1c.2 0 .3-.1.3-.3L18.3 6h-.8c-.2 0-.3 3.3-.3 3.3-.1.2 0 .4.2.4zM14.8 19c.3-.5.5-1 .5-1.7v-4.2c0-1.2-.5-1.8-.5-1.8V19zM12.9 15.2c0 2.1-1.7 3.8-3.8 3.8s-3.8-1.7-3.8-3.8 1.7-3.8 3.8-3.8 3.8 1.7 3.8 3.8zM3.9 21.8c0 .5.4.8.8.8h14.5c.5 0 .8-.4.8-.8v-.3H3.9v.3zM13.8 19c.3-.5.5-1 .5-1.7v-4.2c0-1.2-.5-1.8-.5-1.8V19zM17.8 19c.3-.5.5-1 .5-1.7v-4.2c0-1.2-.5-1.8-.5-1.8V19zM5.4 9.7h1c.2 0 .3-.1.3-.3L6.4 6h-1c-.1 0-.3 3.3-.3 3.3 0 .2.1.4.3.4zM15.3 9.7h1c.2 0 .3-.1.3-.3L16.1 6h-.8c-.2 0-.3 3.3-.3 3.3-.1.2.1.4.3.4zM19.1 1.3H4.8c-.5 0-.8.4-.8.8V4h16V2.2c-.1-.5-.5-.9-.9-.9zM7.3 2.7h-1c-.2 0-.3-.1-.3-.3s.1-.4.3-.4h1c.2 0 .3.1.3.3s-.2.4-.3.4zm6.6 0h-4V2h4v.7zm3.7 0h-1c-.2 0-.3-.1-.3-.3s.1-.4.3-.4h1c.2 0 .3.1.3.3s-.1.4-.3.4z" />
    <path d="M3.9 21.2h16V4.3h-16v16.9zm.4-14.7c0-.8.6-1.5 1.5-1.5H18c.8 0 1.5.7 1.5 1.5v11.7c0 .8-.7 1.5-1.5 1.5H6c-.8 0-1.5-.7-1.5-1.5L4.3 6.5zm0 13.8c0-.3.2-.5.5-.5h14.3c.3 0 .5.2.5.5s-.2.5-.5.5H4.8c-.3 0-.5-.2-.5-.5z" />
    <path d="M14.6 7.7c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM7.8 9.7h1c.2 0 .3-.1.3-.3L8.8 6h-1c-.2 0-.4 3.3-.4 3.3 0 .2.2.4.4.4zM4.8 19.3h14.3c.1 0 .2.1.2.2v.1c0 .1-.1.2-.2.2H4.8c-.1 0-.2-.1-.2-.2v-.1c0-.1.1-.2.2-.2z" />
    <path d="M19.1 8h-.3v2.2H4.9v-2h-.5v2.3h14.8V8.2l-.1-.2z" />
  </svg>
)

export default Dishwasher
