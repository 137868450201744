import React, { Component } from 'react'

class Edit extends Component {
  shouldComponentUpdate(nextProps) {
    const { itemId, isLoading, pluralName, guidebookId, copy } = this.props
    return (
      nextProps.itemId !== itemId ||
      nextProps.isLoading !== isLoading ||
      nextProps.pluralName !== pluralName ||
      nextProps.guidebookId !== guidebookId ||
      nextProps.copy !== copy
    )
  }
  render() {
    const EditForm = this.props.form
    return <EditForm itemId={this.props.itemId} {...this.props} />
  }
}

Edit.propTypes = {}
export default Edit
