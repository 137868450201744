import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import Logo from 'components/Logo'

const propTypes = {
  coverPageClass: PropTypes.string,
  alignChildren: PropTypes.string,
  theme: PropTypes.object,
  page: PropTypes.string
}
const defaultProps = {
  coverPageClass: 'print-header-coverpage',
  alignChildren: 'center',
  theme: {},
  page: ''
}

class PrintHeader extends PureComponent {
  render() {
    const { theme, coverPageClass, alignChildren, page } = this.props
    const headerClass =
      page === 'title'
        ? 'hf-title-page-header md-grid md-grid--no-spacing'
        : 'md-grid md-grid--no-spacing'
    const coverClass = coverPageClass + ' md-cell md-cell--12'
    const childAlignClass = 'md-text-' + alignChildren

    return (
      <div className={headerClass}>
        <div className={coverClass}>
          <Logo theme={theme} className="hf-print-logo" />
          <span className={childAlignClass}>{this.props.children}</span>
        </div>
      </div>
    )
  }
}

PrintHeader.propTypes = propTypes
PrintHeader.defaultProps = defaultProps

export default PrintHeader
