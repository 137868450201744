import React from 'react'

const GolfCourse = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`golf_course`}</title>
    <path d="M17.016 5.906l-6 3.094v9.047c2.859.141 4.969.984 4.969 1.969 0 1.078-2.672 1.969-6 1.969s-6-.891-6-1.969c0-.75 1.219-1.406 3-1.734v1.734h2.016v-18zM18 19.5c0-.844.656-1.5 1.5-1.5s1.5.656 1.5 1.5-.656 1.5-1.5 1.5-1.5-.656-1.5-1.5z" />
  </svg>
)

export default GolfCourse
