import React from 'react'

const Pool = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`pool`}</title>
    <path d="M14.016 5.484C14.016 4.125 15.141 3 16.5 3s2.484 1.125 2.484 2.484-1.125 2.531-2.484 2.531-2.484-1.172-2.484-2.531zM8.672 12c-.563 0-.797-.141-1.172-.375-.188-.141-.422-.234-.75-.375l3.234-3.234L9 6.985c-1.125-1.125-2.156-1.5-3.984-1.5V3.001c2.484 0 3.938.469 5.484 2.016l6.422 6.375c-.141.094-.281.188-.422.234-.375.234-.609.375-1.172.375s-.75-.141-1.125-.375c-.469-.281-1.078-.609-2.203-.609s-1.734.328-2.203.609c-.375.234-.563.375-1.125.375zm13.312 4.5c-1.125 0-1.688-.375-2.156-.656-.375-.234-.609-.328-1.172-.328s-.75.094-1.125.328c-.469.281-1.078.656-2.203.656s-1.688-.375-2.156-.656c-.375-.234-.609-.328-1.172-.328s-.75.094-1.125.328c-.469.281-1.078.656-2.203.656s-1.688-.375-2.156-.656c-.375-.234-.609-.328-1.172-.328s-.75.094-1.125.328c-.469.281-1.078.656-2.203.656v-2.016c.563 0 .75-.094 1.125-.328.469-.281 1.078-.656 2.203-.656s1.688.375 2.156.656c.375.234.609.328 1.172.328s.75-.094 1.125-.328c.469-.281 1.078-.656 2.203-.656s1.688.375 2.156.656c.375.234.609.328 1.172.328s.75-.094 1.125-.328c.469-.281 1.078-.656 2.203-.656s1.688.375 2.156.656c.375.234.609.328 1.172.328V16.5zm0 4.5c-1.125 0-1.688-.375-2.156-.656-.375-.234-.609-.328-1.172-.328s-.75.094-1.125.328c-.469.281-1.078.656-2.203.656s-1.688-.375-2.156-.656c-.375-.234-.609-.328-1.172-.328s-.75.094-1.125.328c-.469.281-1.078.656-2.203.656s-1.734-.375-2.203-.656c-.375-.234-.563-.328-1.125-.328s-.797.094-1.172.328c-.469.281-1.031.656-2.156.656v-2.016c.563 0 .75-.094 1.125-.328C3.61 18.375 4.219 18 5.344 18s1.688.375 2.156.656c.375.234.609.328 1.172.328s.75-.094 1.125-.328C10.266 18.375 10.875 18 12 18s1.734.375 2.203.656c.375.234.563.328 1.125.328s.797-.094 1.172-.328c.469-.281 1.031-.656 2.156-.656s1.734.375 2.203.656c.375.234.563.328 1.125.328V21z" />
  </svg>
)

export default Pool
