import React from 'react'

const Gondola = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`gondola`}</title>
    <path d="M22 18c0 .472-.748 1-1.75 1-.271 0-.521-.041-.746-.107 1.56-1.196 2.499-3.16 2.496-5.52V8h-2v3.486C17.96 12.942 16.016 13 16 13V9h1a.998.998 0 00.97-1.243l-.811-3.243A1.996 1.996 0 0015.219 3H8.781c-.92 0-1.718.623-1.94 1.515L6.03 7.757A1.003 1.003 0 007 9h1v4H4.004c0-.95-.004-3.002-.004-3.002l-2 .004s.005 2.81.004 3.372c-.004 2.359.936 4.322 2.494 5.518A2.617 2.617 0 013.75 19C2.748 19 2 18.472 2 18H0c0 1.682 1.647 3 3.75 3 1.101 0 2.07-.367 2.75-.953.68.586 1.649.953 2.75.953s2.07-.367 2.75-.953c.68.586 1.649.953 2.75.953s2.07-.367 2.75-.953c.68.586 1.649.953 2.75.953 2.103 0 3.75-1.318 3.75-3h-2zM8.781 5h6.438l.5 2H8.281l.5-2zM14 9v6h-4V9h4z" />
  </svg>
)

export default Gondola
