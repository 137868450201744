import React from 'react'

const Whatshot = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`whatshot`}</title>
    <path d="M11.719 18.984a4.741 4.741 0 004.781-4.781c0-1.406-.188-2.719-.563-4.031-1.031 1.359-2.859 2.203-4.641 2.578s-2.813 1.453-2.813 3.094c0 1.734 1.453 3.141 3.234 3.141zM13.5.656c3.891 3.141 6.516 7.969 6.516 13.359 0 4.406-3.609 7.969-8.016 7.969s-8.016-3.563-8.016-7.969c0-3.375 1.219-6.516 3.234-8.906v.375c0 2.063 1.547 3.703 3.609 3.703s3.422-1.641 3.422-3.703c0-2.156-.75-4.828-.75-4.828z" />
  </svg>
)

export default Whatshot
