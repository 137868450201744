import React from 'react'

const Sailing = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`sailing`}</title>
    <path d="M9.4.8v18.5l10.2-.2C19.6 19.2 9.4.8 9.4.8zM8.8 5.3v14.1l-5.7-.2L8.8 5.3zM3.3 19.8h5.5V21h11.6L19 22.4l-.9.5H5h-.1c-.4 0-.7-.3-.9-.6l-.7-2.5z" />
  </svg>
)

export default Sailing
