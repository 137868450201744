import React from 'react'

const CleaningUtensils = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`cleaning_utensils`}</title>
    <path d="M4.7 18.3V3.8H4v14.5H2v.7h4.7v-.7h-2zM1.8 19.2l-.5 1.5h6l-.7-1.5H1.8zM13.8 18.5V18h-1.5v-1.8h-.7V18h-1.5v.5l-.5 2h4.7l-.5-2zM13.2 15.5l.5-1.2.3-.3-3.3-3.3-.5.5 1.2 1.2-.9.6 2.7 2.5zM19.3 16L22 3.3l.7.2L20 16h2.2l-.7 4.7h-4l-.7-4.7h2.5zM12 6.7V7l4 2.2-.3.7-4-2.3-.2.3L7.7 7l1.7-3L12 6.7z" />
  </svg>
)

export default CleaningUtensils
