import React from 'react'

const Fridge = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`fridge`}</title>
    <path d="M17.4 8.5V1.8c0-.5-.5-.9-1.2-.9H7.7c-.6 0-1.2.4-1.2.9v6.7h10.9zM7.4 5h.8v2.9h-.8V5zM6.6 8.6V22c0 .5.5.9 1.2.9h8.7c.6 0 1.2-.4 1.2-.9V8.6H6.6zm1.5 3.7h-.7V9.4h.8v2.9z" />
  </svg>
)

export default Fridge
