import React from 'react'

const SmartHome = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`smart_home`}</title>
    <path d="M12 2L3 7.963V19a2 2 0 002 2h14a2 2 0 002-2V7.963L12 2zm-.936 6h1.872c.129 0 .238.096.252.225l.117 1.011c.484.168.921.424 1.3.752l.936-.404a.253.253 0 01.32.105l.936 1.622a.253.253 0 01-.068.33l-.805.6c.048.246.076.499.076.759s-.028.513-.076.76l.806.6a.254.254 0 01.069.33l-.938 1.62a.252.252 0 01-.318.108l-.938-.406c-.38.329-.816.584-1.3.752l-.117 1.011a.254.254 0 01-.252.225h-1.872a.252.252 0 01-.252-.225l-.117-1.011a3.974 3.974 0 01-1.3-.752l-.936.404a.253.253 0 01-.32-.105l-.936-1.622a.253.253 0 01.068-.33l.805-.6A3.97 3.97 0 018 13c0-.26.03-.514.076-.762l-.806-.597a.254.254 0 01-.069-.33l.938-1.622a.251.251 0 01.318-.107l.938.406c.38-.329.816-.584 1.3-.752l.117-1.011A.254.254 0 0111.065 8zM12 11a2 2 0 00-2 2 2 2 0 002 2 2 2 0 002-2 2 2 0 00-2-2z" />
  </svg>
)

export default SmartHome
