import React, { Component } from 'react'
import {
  Avatar,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'

import { allIcons } from 'constants/UIIcons'

class IconViewer extends Component {
  renderIcons = () => {
    return allIcons.map((icon) => {
      const IconComponent = icon.icon
      return (
        <div className="md-cell md-cell--12" key={icon.field}>
          <Avatar icon={<IconComponent fill="#ffffff" />} />
          {icon.tags}
        </div>
      )
    })
  }

  render() {
    return (
      <div className="hf-main-container">
        <Toolbar
          className={'hf-host-toolbar hf-reports'}
          colored
          title="Icon Viewer"
        />
        <div className="md-grid">
          <div className="md-cell md-cell--10 md-cell--1-desktop-offset">
            <div className="md-grid md-grid--no-spacing">
              {this.renderIcons()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(IconViewer)
