import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { fetchItem } from 'redux/modules/crud'
import { attachPropertyToGuidebook } from 'redux/modules/orbirental/connect'
import { Media, Toolbar } from 'react-md'
import { push } from 'react-router-redux'

class OrbirentalProperty extends Component {
  componentDidMount() {
    const { dispatch, itemId, gbId } = this.props
    if (typeof gbId !== 'undefined') {
      attachPropertyToGuidebook(itemId, gbId).then((data) => {
        // now fetch the item normally
        dispatch(push('/host/guidebooks/' + gbId))
      })
    } else {
      dispatch(fetchItem('orbirentalproperties', 'orbirentalproperty', itemId))
    }
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, property_guidebook, itemId } = nextProps
    if (this.guidebookMatchesProperty(property_guidebook, itemId)) {
      dispatch(push('/host/guidebooks/' + property_guidebook.id))
    }
  }

  guidebookMatchesProperty = (guidebook, property_uid) => {
    if (guidebook.properties.length === 0) return false
    for (var i = 0, len = guidebook.properties.length; i < len; i++) {
      if (guidebook.properties[i].uid === property_uid) return true
    }
  }

  render() {
    return (
      <div className="hf-main-container">
        <Toolbar
          colored
          prominent
          className={'hf-host-toolbar hf-bookingsyncrentals'}
          title={'Property Management Platform Properties'}
          actions={[]}
        />
        <div className={'md-grid hf-host-paper hf-bookingsyncrentals-paper'}>
          <div className="md-cell md-cell--2 md-cell--tablet-hidden md-cell--phone-hidden"></div>
          <div className="md-cell md-cell--8 hf-paper">
            <div className="md-grid">
              <div className="md-cell md-cell--1 md-cell--tablet-hidden"></div>
              <div className="md-cell md-cell--5 md-cell--4-tablet md-cell--4-phone md-cell--middle">
                <h6 className="md-headline">Fetching Property Details</h6>
                <p>
                  Please wait while we take information from the Property
                  Management Property Platform to set up your guidebook
                </p>
              </div>
              <div className="md-cell md-cell--5 md-cell--4-tablet md-cell--4-phone">
                <Media aspectRatio="1-1">
                  <img
                    src="https://storage.googleapis.com/hostfully-test/blank-state-cards.png"
                    alt=""
                  />
                </Media>
              </div>
            </div>
          </div>
          <div className="md-cell md-cell--2 md-cell--tablet-hidden md-cell--phone-hidden"></div>
        </div>
      </div>
    )
  }
}

OrbirentalProperty.propTypes = {
  itemId: PropTypes.string.isRequired,
  gbId: PropTypes.string,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state, props) {
  const property_guidebook = state.edit_orbirentalproperty
  return {
    property_guidebook
  }
}

export default connect(mapStateToProps)(injectIntl(OrbirentalProperty))
