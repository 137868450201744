import React from 'react'

const BrightnessHigh = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`brightness_high`}</title>
    <path d="M12 8.016c2.203 0 3.984 1.781 3.984 3.984S14.203 15.984 12 15.984 8.016 14.203 8.016 12 9.797 8.016 12 8.016zM12 18c3.328 0 6-2.672 6-6s-2.672-6-6-6-6 2.672-6 6 2.672 6 6 6zm8.016-9.328L23.297 12l-3.281 3.328v4.688h-4.688L12 23.297l-3.328-3.281H3.984v-4.688L.703 12l3.281-3.328V3.984h4.688L12 .703l3.328 3.281h4.688v4.688z" />
  </svg>
)

export default BrightnessHigh
