import React from 'react'

const EventSeat = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`event_seat`}</title>
    <path d="M17.016 12.984H6.985V5.015c0-1.078.938-2.016 2.016-2.016h6c1.078 0 2.016.938 2.016 2.016v7.969zm-15-3h3v3h-3v-3zm16.968 0h3v3h-3v-3zM3.984 21v-6h16.031v6h-3v-3H6.984v3h-3z" />
  </svg>
)

export default EventSeat
