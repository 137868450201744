import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Avatar, FontIcon, ListItem } from 'react-md'
import { stripTags } from 'utils/Strings'
import { Link } from 'react-router-dom'

class HostIntroListItem extends Component {
  render() {
    const { item, editItem, deleteItem, pluralName } = this.props
    const link = (
      <Link
        to={'/host/' + pluralName + '/' + item.id}
        onClick={(e) => {
          e.stopPropagation()
        }}
        className="md-tile-text--primary md-text hf-fake-link"
      >
        {item.label}
      </Link>
    )
    const secondary = item.host_intro ? stripTags(item.host_intro) : ' '

    return (
      <ListItem
        onClick={editItem}
        className={'hf-list-item hf-' + pluralName + 'list-item'}
        leftAvatar={
          <Link
            to={'/host/' + pluralName + '/' + item.id}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <Avatar src={item.image} role="presentation" />
          </Link>
        }
        primaryText={link}
        secondaryText={secondary}
        rightIcon={<FontIcon onClick={deleteItem}>delete</FontIcon>}
      />
    )
  }
}

HostIntroListItem.propTypes = {
  item: PropTypes.object.isRequired
}
export default HostIntroListItem
