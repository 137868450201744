import React from 'react'

const SurroundSound = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`surround_sound`}</title>
    <path d="M12 9.984c1.078 0 2.016.938 2.016 2.016s-.938 2.016-2.016 2.016S9.984 13.078 9.984 12 10.922 9.984 12 9.984zm5.672 7.688c1.547-1.547 2.344-3.609 2.344-5.672s-.797-4.125-2.344-5.672l-1.453 1.453C17.391 8.953 18 10.453 18 12s-.563 3.094-1.734 4.266zM12 15.984c2.203 0 3.984-1.781 3.984-3.984S14.203 8.016 12 8.016 8.016 9.797 8.016 12 9.797 15.984 12 15.984zm-4.219.235C6.609 15.047 6 13.547 6 12s.563-3.094 1.734-4.266L6.328 6.328C4.781 7.875 3.984 9.937 3.984 12s.797 4.125 2.344 5.672zM20.016 3.984c1.078 0 1.969.938 1.969 2.016v12c0 1.078-.891 2.016-1.969 2.016H3.985c-1.078 0-1.969-.938-1.969-2.016V6c0-1.078.891-2.016 1.969-2.016h16.031z" />
  </svg>
)

export default SurroundSound
