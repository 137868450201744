import React from 'react'

const Cot = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`cot`}</title>
    <path d="M21.3 4.8H2v15.5h1v-4.8h18v4.8h1V4.8h-.7zm-14.6.9h1V13h-1V5.7zm-1 7.3h-.9V5.7h1L5.7 13zm3-7.3h1V13h-1V5.7zm1.8 0h1V13h-1V5.7zm1.8 0h1V13h-1V5.7zm1.9 0h1V13h-1V5.7zm1.8 0h1V13h-1V5.7zm1.8 0h1V13h-1V5.7zM3 5.7h1V13H3V5.7zM20 13V5.7h1V13h-1z" />
  </svg>
)

export default Cot
