import React from 'react'

const Ambulance = (props) => (
  <svg width={24} height={24} viewBox="0 0 31 28" {...props}>
    <title>{`ambulance`}</title>
    <path d="M10 22c0-1.109-.891-2-2-2s-2 .891-2 2 .891 2 2 2 2-.891 2-2zm-6-8h6v-4H7.531a.939.939 0 00-.344.141L4.14 13.188c-.047.078-.125.25-.141.344v.469zm20 8c0-1.109-.891-2-2-2s-2 .891-2 2 .891 2 2 2 2-.891 2-2zm2-10.5v-3c0-.281-.219-.5-.5-.5H22V4.5c0-.281-.219-.5-.5-.5h-3c-.281 0-.5.219-.5.5V8h-3.5c-.281 0-.5.219-.5.5v3c0 .281.219.5.5.5H18v3.5c0 .281.219.5.5.5h3c.281 0 .5-.219.5-.5V12h3.5c.281 0 .5-.219.5-.5zM30 3v18c0 .547-.453 1-1 1h-3c0 2.203-1.781 4-4 4-2.203 0-4-1.797-4-4h-6c0 2.203-1.781 4-4 4s-4-1.797-4-4H2c-.547 0-1-.453-1-1s.453-1 1-1v-6.5c0-.547.313-1.313.703-1.703l3.094-3.094C6.188 8.312 6.953 8 7.5 8H10V3c0-.547.453-1 1-1h18c.547 0 1 .453 1 1z" />
  </svg>
)

export default Ambulance
