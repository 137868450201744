import React from 'react'

const Hiking = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`hiking`}</title>
    <path d="M14 3.3c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM10 14.3l2 2v5.8h2v-7.3L12 13l.7-3c1.3 1.5 3.2 2.5 5.3 2.5v-2c-1.7 0-3.3-.9-4.1-2.3l-1-1.5c-.3-.5-1-1-1.7-1s-1.2.8-1.5 1.1S8 12.7 8 12.7l-2 9.5h2l2-7.9z" />
    <path d="M19.2 3.9l.7.1-4.3 18.8-.6-.1c-.1 0 4.2-18.8 4.2-18.8z" />
  </svg>
)

export default Hiking
