import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  DialogContainer,
  FontIcon,
  Media,
  SelectField,
  TextField
} from 'react-md'

import TooltipButton from 'components/TooltipButton'
import { editItem, editItemAndAttach } from 'redux/modules/crud'
import currentUser from 'utils/CurrentUser'
import { filterData, sortData } from 'utils/Data'

import PropertyListItem from './PropertyListItem'

class PropertyList extends Component {
  constructor(props) {
    super(props)
    this.filter = this.filter.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.state = {
      filter: '',
      showUpgradeAlert: false,
      showGuidebookChooser: false,
      chosenGuidebook: 0,
      importProperty: null
    }
  }

  filter = (filter) => {
    this.setState({ filter })
  }

  clearFilter = () => {
    this.setState({ filter: '' })
  }

  importGuidebook = () => {
    const { dispatch } = this.props
    const { importProperty, chosenGuidebook } = this.state
    // if no chosen guidebook, create new
    if (chosenGuidebook === 0) {
      dispatch(editItem('orbirentalproperties', importProperty.property_uid))
    } else {
      dispatch(
        editItemAndAttach(
          'orbirentalproperties',
          importProperty.property_uid,
          chosenGuidebook
        )
      )
    }
  }

  handleGbSelection = (value) => {
    this.setState({ chosenGuidebook: value })
  }

  showChooser = (item, e) => {
    this.setState({
      showGuidebookChooser: true,
      chosenGuidebook: 0,
      importProperty: item
    })
  }

  closeChooser = () => {
    this.setState({
      showGuidebookChooser: false,
      chosenGuidebook: 0,
      importProperty: null
    })
  }

  renderBlankState = () => {
    const title = 'Fetching PMP properties'
    const message = 'Please wait while we load your rentals from the PMP'
    const image =
      'https://storage.googleapis.com/hostfully-test/blank-state-cards.png'
    return (
      <div className="md-grid">
        <div className="md-cell md-cell--1 md-cell--tablet-hidden"></div>
        <div className="md-cell md-cell--5 md-cell--4-tablet md-cell--4-phone md-cell--middle">
          <h6 className="md-headline">{title}</h6>
          <p>{message}</p>
        </div>
        <div className="md-cell md-cell--5 md-cell--4-tablet md-cell--4-phone">
          <Media aspectRatio="1-1">
            <img src={image} alt="" />
          </Media>
        </div>
      </div>
    )
  }

  upgrade = () => {
    window.location.href =
      process.env.REACT_APP_WORDPRESS_URL + '/pricing#prime'
  }

  render() {
    const { guidebooks, properties } = this.props
    const { filter } = this.state
    const user = currentUser()
    //add upgrade prompts if needed
    const defaultSort = 'name'
    const filterFields = [
      'name',
      'key',
      'address.formatted_address',
      'address.street',
      'address.locality',
      'address.state',
      'address.post_code',
      'address.country_code'
    ]
    const self = this
    // sort the items
    const filteredItems = filterData(
      properties.data,
      'orbirentalproperties',
      filterFields,
      filter
    )
    const sorted = sortData(filteredItems, defaultSort, 'asc')
    let list

    const limitReached =
      guidebooks.loaded && guidebooks.data.length >= user.guidebookLimit

    if (properties.loaded && !properties.data.length) {
      //send blank state
      list = self.renderBlankState()
    } else {
      list = sorted.map(function (property) {
        // console.log(property);
        return (
          <PropertyListItem
            key={property.property_uid}
            item={property}
            guidebooks={guidebooks}
            limitReached={limitReached}
            // updateGB={self.updateGB}
            showChooser={self.showChooser}
          />
        )
      })
    }

    const gbSelections = [{ label: 'Create New Guidebook', value: 0 }]
    // console.log(this.props.guidebooks);
    if (this.props.guidebooks.data && this.props.guidebooks.data.length) {
      const gbData = sortData(this.props.guidebooks.data, 'name', 'asc')
      for (var i = 0, len = gbData.length; i < len; i++) {
        const gb = gbData[i]
        // only add guidebooks that are not already mapped to the list
        gbSelections.push({ label: `${gb.name}`, value: gb.id })
      }
    }
    const gbSelectField = (
      <SelectField
        key="gbMenu"
        id="gbSelection"
        fullWidth
        listClassName={'not-nested'}
        menuItems={gbSelections}
        defaultValue={0}
        onChange={this.handleGbSelection}
      />
    )
    const dialogContentStyle = { overflow: 'visible' }

    return (
      <div>
        <div className="md-grid">
          <div className="md-cell md-cell--12 md-cell--bottom">
            <TextField
              id="filter"
              value={filter}
              label="Search / Filter"
              type="text"
              leftIcon={<FontIcon>search</FontIcon>}
              inlineIndicator={
                <TooltipButton
                  className="hf-mini-btn"
                  tooltipLabel="Clear Search / Filter"
                  tooltipPosition="left"
                  onClick={this.clearFilter}
                  icon
                >
                  <FontIcon>close</FontIcon>
                </TooltipButton>
              }
              onChange={this.filter}
            />
          </div>
          <DialogContainer
            id="guidebookChooser"
            title={`Import PMP Property: ${this.state.importProperty ? this.state.importProperty.name : '' || ''}`}
            visible={this.state.showGuidebookChooser}
            onHide={this.closeChooser}
            portal={true}
            contentStyle={dialogContentStyle}
            actions={[
              {
                onClick: this.closeChooser,
                primary: true,
                children: 'Cancel'
              },
              {
                onClick: this.importGuidebook,
                primary: true,
                children: 'Import'
              }
            ]}
          >
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--12">
                <p>
                  Select which guidebook you would like to link this property to
                  or create a new guidebook.
                </p>
                <p>
                  Linking to an existing guidebook will not immediately
                  overwrite any existing information
                </p>
                {gbSelectField}
              </div>
            </div>
          </DialogContainer>
        </div>
        <div className="md-grid">{list}</div>
      </div>
    )
  }
}

PropertyList.propTypes = {
  guidebooks: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired
}
export default PropertyList
