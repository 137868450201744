import React from 'react'

const Paddleboard = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`paddleboard`}</title>
    <path d="M12.1 5.5c.2.1.5.2.8.2.8 0 1.4-.6 1.4-1.4 0-.7-.5-1.3-1.2-1.4h-.6c-.6 0-1.1.4-1.3.9v.3c0 .7.3 1.2.9 1.4zM22.4 18.7h-6.3l1.7-14h-.5l-6.6 1.9c-.2.1-.4.3-.5.5-.4.6-.8 2.3-1 3.5l-1.9 8.1H1.4c1.8 1.2 6.2 1 8.4 1h4.8c2.2 0 5 0 8.9-1h-1.1zm-5.5-9.9c-.1 0-2.7-.8-3.9-1.5l4.2-.9-.3 2.4zm-8 9.9l1.4-5.8 1.3 1.4v4.4H8.9zm4.4 0c0-1-.1-3.6 0-4.2s0-.6-.1-1.2l-1.4-1.9.8-2.6 4.2 1.5-1 8.4h-2.5z" />
  </svg>
)

export default Paddleboard
