import React from 'react'

const BeachAccess = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`beach_access`}</title>
    <path d="M17.438 8.813C14.304 5.679 9.957 4.646 6.027 5.945c2.99-.341 6.843 1.206 9.958 4.321l-5.719 5.719C7.151 12.87 5.604 9.017 5.945 6.027c-1.299 3.931-.265 8.278 2.868 11.411l-2.859 2.859c-3.935-3.935-3.938-10.352-.006-14.29.006-.052.008-.054.06-.06 3.938-3.931 10.355-3.929 14.29.006zm-4.313 5.765l1.453-1.453L21 19.594 19.547 21z" />
  </svg>
)

export default BeachAccess
