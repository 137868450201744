import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, Paper, TextField } from 'react-md'

import { createAgency } from 'redux/modules/orbirental/connect'
import currentUser from 'utils/CurrentUser'
import { debounce } from 'utils/Debounce'

const propTypes = {
  onConnect: PropTypes.func.isRequired
}
const defaultProps = {}

/**
 * Form to allow connection to orbirental
 * accept a company name and first/last name
 * pass them along to api to save to wordpress as well as create agency in orbirental
 */
class MarketplaceConnectForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showErrors: {
        company: false
      },
      connectEnabled: true
    }
    this.companyRef = null
    this.handleInputChange = debounce(this.handleInputChange.bind(this), 250)
  }

  handleConnectClick = () => {
    const companyName = this.companyRef.value
    // errors is the inverse of the return from validation
    const errors = {
      company: !this.validateInput('company', companyName)
    }
    if (errors['company']) {
      // errors - update error state
      this.setState({ showErrors: errors })
    } else {
      // no errors - disable button and update error state, then call function to create agency
      this.setState({ connectEnabled: false, showErrors: errors }, () => {
        const user = currentUser()
        createAgency(companyName, user.user.email).then((data) => {
          // call onConnect callback
          this.props.onConnect(data)
        })
      })
    }
  }

  handleInputChange = (inputName, value) => {
    const valid = this.validateInput(inputName, value)
    const errors = Object.assign({}, this.state.showErrors)
    errors[inputName] = !valid
    this.setState({ showErrors: errors })
  }

  validateInput = (inputName, value) => {
    return value.length > 0
  }

  render() {
    const user = currentUser()

    return (
      <Paper className="hf-card hf-marketplace-dashboard-card">
        <h3 className="hf-secondary-title">Setup Marketplace Company</h3>
        <div className="md-body-1">
          Please enter the name of the company or individual that will be
          receiving payments for any others or services.
        </div>
        <div className="hf-input-container">
          <TextField
            id="organizationName"
            ref={(input) => {
              this.companyRef = input
            }}
            placeholder="Company name"
            defaultValue={
              user.user.organisation_name ? user.user.organisation_name : ''
            }
            error={this.state.showErrors.company}
            errorText={
              <span className="hf-input-error">
                Please enter a Company name
              </span>
            }
            className="hf-input"
            onChange={(value) => {
              this.handleInputChange('company', value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                return this.handleConnectClick()
              }
            }}
          />
        </div>
        <Button
          disabled={!this.state.connectEnabled}
          onClick={this.handleConnectClick}
          raised
          primary
        >
          Save
        </Button>
      </Paper>
    )
  }
}

MarketplaceConnectForm.propTypes = propTypes
MarketplaceConnectForm.defaultProps = defaultProps

export default MarketplaceConnectForm
