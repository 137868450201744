import React from 'react'

const FrontDesk = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`front_desk`}</title>
    <path d="M9.5 2A2.502 2.502 0 007 4.5C7 5.879 8.121 7 9.5 7S12 5.879 12 4.5 10.879 2 9.5 2zm0 6C6.082 8 5 10 5 10v1h9v-1s-1.082-2-4.5-2zm8 0c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5zm0 1.5A1.5 1.5 0 0016 11h3a1.5 1.5 0 00-1.5-1.5zM2 12v2h20v-2zm1 3v7h18v-7z" />
  </svg>
)

export default FrontDesk
