const translateString = function (locale, text, translations) {
  if (
    typeof locale === 'undefined' ||
    !locale ||
    typeof translations === 'undefined' ||
    !translations ||
    locale === 'en_US' ||
    locale === 'en' ||
    !translations[locale]
  ) {
    return text || ''
  }
  return translations[locale]
}

exports.translateString = translateString
