import React from 'react'

const Delete = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`delete`}</title>
    <path d="M18.984 3.984V6H5.015V3.984h3.469L9.515 3h4.969l1.031.984h3.469zM6 18.984v-12h12v12C18 20.062 17.062 21 15.984 21H8.015c-1.078 0-2.016-.938-2.016-2.016z" />
  </svg>
)

export default Delete
