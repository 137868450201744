import React from 'react'

const Deck = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`deck`}</title>
    <path d="M10.9 12.4h2.3c.2 0 .4.2.4.4v1.3c0 .2-.2.4-.4.4h-2.3c-.2 0-.4-.2-.4-.4v-1.3c0-.2.2-.4.4-.4z" />
    <path d="M16.9 13.7v1.9h-.1v-.8H7.2v.7h-.1v-1.7h-.7v3h11.1v-3l-.6-.1z" />
    <path d="M7.8 12.4H10c.2 0 .4.2.4.4v1.3c0 .2-.2.4-.4.4H7.8c-.2 0-.4-.2-.4-.4v-1.3c0-.2.1-.4.4-.4zM14.1 12.4h2.3c.2 0 .4.2.4.4v1.3c0 .2-.2.4-.4.4h-2.3c-.2 0-.4-.2-.4-.4v-1.3c0-.2.2-.4.4-.4zM20.3 15.7v-.8c.1-.2.3-.4.5-.4.2.1.4.3.4.5v.7h.1v3h-3.2v-3h2.2zM3.5 15.7v-.8c-.1-.2-.3-.4-.5-.4-.2.1-.4.3-.4.5v.7h-.3v3h3.3v-3H3.5zM22.6 11V8.1h.3v-.3H1v.3h.5V11H1v.3h22V11h-.4zm-.3-2.9V11H22V8.1h.3zm-.5 0V11h-.3V8.1h.3zm-.5 0V11H21V8.1h.3zm-.6 0V11h-.3V8.1h.3zm-.5 0V11H20V8.1h.2zm-.5 0V11h-.3V8.1h.3zm-.5 0V11h-.3V8.1h.3zm-.6 0V11h-.3V8.1h.3zm-.5 0V11h-.3V8.1h.3zm-.5 0V11h-.3V8.1h.3zm-.6 0V11h-.3V8.1h.3zm-.5 0V11h-.3V8.1h.3zm-.5 0V11h-.3V8.1h.3zm-.6 0V11h-.3V8.1h.3zm-.5 0V11h-.3V8.1h.3zm-.5 0V11h-.3V8.1h.3zm-.5 0V11h-.3V8.1h.3zm-.6 0V11H13V8.1h.3zm-.5 0V11h-.3V8.1h.3zm-.5 0V11H12V8.1h.3zm-.6 0V11h.4V8.1h-.4zm-.5 0V11h.5V8.1h-.5zm-.5 0V11h.5V8.1h-.5zm-.6 0V11h.5V8.1h-.5zm-.5 0V11h.5V8.1h-.5zm-.5 0V11h.5V8.1h-.5zm-.5 0V11h.7V8.1h-.7zm-.6 0V11h.7V8.1H8zm-.5 0V11h.7V8.1h-.7zm-.5 0V11h.7V8.1H7zm-.6 0V11h.8V8.1h-.8zm-.5 0V11h.8V8.1h-.8zm-.5 0V11h.8V8.1h-.8zm-.6 0V11h.8V8.1h-.8zm-.5 0V11h.9V8.1h-.9zm-.5 0V11h.9V8.1h-.9zm-.5 0V11h.9V8.1h-.9zm-.6 0V11h.9V8.1h-.9zm-.5 0V11h.9V8.1h-.9zm-.5 0V11h1.1V8.1H1.7zm-.6 0V11h1.1V8.1H1.1zm.6 2.9M11.1 3.9l-.3-.3-.3.3.3.3.3-.3zm-.4.9h-.5v.3h.5v-.3zM12 3.2h-.3v.5h.3v-.5zm1.2.7l-.3-.3-.3.3.3.3.3-.3zm-.5 2.3l.3.3.3-.3-.3-.3-.3.3zm.4-1.2v.3h.5v-.5l-.5.2zm-1.2-1c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9c0-.4-.4-.9-.9-.9zm-.2 2.8h.3v-.5h-.3v.5zm-1.2-.6l.3.3.3-.3-.3-.3-.3.3z" />
  </svg>
)

export default Deck
