import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { defineMessages, injectIntl, intlShape } from 'react-intl'
import { Card, CardText, CardTitle, Media, MediaOverlay } from 'react-md'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import ImageFallback from 'components/ImageFallback'
import {
  atLeastDesktop,
  atLeastTablet,
  atMostPhablet
} from 'components/ScreenSize'
import Translator from 'components/Translator'
import AsHtml from 'components/Translator/AsHtml'
import { recommendationShape } from 'constants/SharedPropTypes'
import { customColor, shadeBlend } from 'utils/Color'
import { trackCardOpened } from 'utils/GTM';
import { resizeImage } from 'utils/Image'

const propTypes = {
  recommendation: recommendationShape,
  recsOnly: PropTypes.bool,
  // helper for internationalization
  intl: intlShape.isRequired
}

const defaultProps = {
  recsOnly: false
}

class RecommendationCard extends Component {
  formatMessage = this.props.intl.formatMessage

  messages = defineMessages({
    subTitle: {
      id: 'rec.subTitle',
      defaultMessage: 'Sponsored'
    }
  })

  restoreColor = null
  restoreSizeAndPos = {
    height: null,
    width: null,
    left: null,
    top: null,
    zIndex: null
  }

  viewRec = (rec) => {
    
    const guidebook_id = this.props.guidebook.id || 0
    const host_user_id = this.props.guidebook.host_user_id || 0
    const tab_name = this.props.category.name.toLowerCase().replace(/ /g, '_') || 'unknown'
    const card_key = this.props.recommendation.key || null
    const card_name = this.props.recommendation.name || null
    // send card_opened event to GTM/GA4
    trackCardOpened(guidebook_id, host_user_id, tab_name, card_name, card_key);
    const recUrl = this.buildRecURL(rec)
    // trackEvent(rec.sponsored ? 'sponsoredRecClicked' : 'recommendationClicked', eventData, {}, function(){
    this.props.dispatch(push(recUrl))
    // });
  }

  buildRecURL = (rec) => {
    const { guidebook, category, recsOnly } = this.props
    let category_key = category.name.toLowerCase().replace(/ /g, '_')
    if (recsOnly && guidebook.recs_only_enabled && guidebook.recs_only_key) {
      return `/recs/${guidebook.recs_only_key}/recommendations/${category_key}/recommendation/${rec.key}`
    } else {
      return `/${guidebook.key}/recommendations/${category_key}/recommendation/${rec.key}`
    }
  }

  resizeRecImage = (image) => {
    const fallbackImage = image || '/add-place-img.svg'
    let srcImage = image || '/add-place-img.svg'

    if (image) {
      // for desktop and up, grab images 350 wide
      if (atLeastDesktop) {
        srcImage = resizeImage(image, 348, 261, true)
        // for tablet and up, grab images 480 wide
      } else if (atLeastTablet) {
        srcImage = resizeImage(image, 480, 360, true)
      } else if (atMostPhablet) {
        srcImage = resizeImage(image, 748, 561, true)
      }
    }
    // otherwise
    return { srcImage: srcImage, fallbackImage: fallbackImage }
  }

  hoverRec = (recId, isHovering) => {
    const pathEle = document.getElementById(`path_rec_${recId}`)
    const svgEle = document.getElementById(`svg_rec_${recId}`)
    if (svgEle) {
      const containerDiv = svgEle.parentNode.parentNode.parentNode
      // console.log(containerDiv);
      if (isHovering) {
        const highlightHeight = 99
        const highlightWidth = 66
        // save original color and styles so we can restore them later
        this.restoreColor = pathEle.style.fill
        this.restoreSizeAndPos.height = svgEle.height.baseVal.value
        this.restoreSizeAndPos.width = svgEle.width.baseVal.value
        this.restoreSizeAndPos.top = parseFloat(containerDiv.style.top)
        this.restoreSizeAndPos.left = parseFloat(containerDiv.style.left)
        this.restoreSizeAndPos.zIndex = containerDiv.parentNode.style.zIndex
        // record change in height and width so we can adjust position
        const dTop = highlightHeight - this.restoreSizeAndPos.height
        const dLeft = (highlightWidth - this.restoreSizeAndPos.width) / 2
        // now actually change the height and width
        svgEle.setAttribute('height', `${highlightHeight}`)
        svgEle.setAttribute('width', `${highlightWidth}`)
        // change the position to reflect the size change and keep the point pointed properly
        containerDiv.style.top = `${this.restoreSizeAndPos.top - dTop}px`
        containerDiv.style.left = `${this.restoreSizeAndPos.left - dLeft}px`
        containerDiv.style.zIndex = `${this.restoreSizeAndPos.zIndex + 1}`
        // change the color
        pathEle.style.fill = shadeBlend(0.33, this.restoreColor)
      } else if (this.restoreColor) {
        // restore original height
        svgEle.setAttribute('height', `${this.restoreSizeAndPos.height}`)
        svgEle.setAttribute('width', `${this.restoreSizeAndPos.width}`)
        // restore original pos
        containerDiv.style.top = `${this.restoreSizeAndPos.top}px`
        containerDiv.style.left = `${this.restoreSizeAndPos.left}px`
        containerDiv.style.zIndex = `${this.restoreSizeAndPos.zIndex}`
        // restore original color
        pathEle.style.fill = this.restoreColor
        // clear stored variables
        this.restoreColor = null
        this.restoreSizeAndPos = {
          height: null,
          width: null,
          left: null,
          top: null,
          zIndex: null
        }
      }
    }
  }

  render() {
    const { recommendation, guidebook } = this.props
    const { srcImage, fallbackImage } = this.resizeRecImage(
      recommendation.image
    )
    const aspectRatio = '4-3'
    let markerColor = recommendation.category.color || '#2D2AA5'
    markerColor = customColor(
      recommendation.category.name,
      guidebook.theme,
      markerColor
    )
    const sponsorBgStyle = { background: markerColor }
    const avatar = recommendation.sponsored ? (
      <img
        style={sponsorBgStyle}
        className="sponsored-avatar"
        src="https://storage.googleapis.com/v2-static/hostfully-white-avatar.png"
        alt=""
      />
    ) : (
      ''
    )
    const recNameTextString = (
      <Translator
        text={recommendation.name}
        translations={recommendation.name_txn}
      />
    )
    const subTitle = recommendation.sponsored
      ? this.formatMessage(this.messages.subTitle)
      : ''
    const recQuoteTextString = (
      <AsHtml
        text={recommendation.why_recommended}
        translations={recommendation.why_recommended_txn}
      />
    )

    return (
      <Card
        key={recommendation.id}
        raise={true}
        className="hf-clickable"
        onMouseEnter={() => this.hoverRec(recommendation.id, true)}
        onMouseLeave={() => this.hoverRec(recommendation.id, false)}
        onClick={() => {
          this.viewRec(recommendation)
        }}
      >
        <Media aspectRatio={aspectRatio}>
          <ImageFallback
            src={srcImage}
            fallback={fallbackImage}
            role="presentation"
          />
          {avatar}
          <MediaOverlay>
            <CardTitle
              title={recNameTextString}
              subtitle={subTitle}
              className="hf-rec-overlay-title"
            ></CardTitle>
          </MediaOverlay>
        </Media>
        <CardText dir="auto">
          <p className="host-quote">{recQuoteTextString}</p>
        </CardText>
      </Card>
    )
  }
}

RecommendationCard.propTypes = propTypes
RecommendationCard.defaultProps = defaultProps

export default connect(null)(injectIntl(RecommendationCard))
