import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import {
  Button,
  DialogContainer,
  FontIcon,
  LinearProgress,
  Paper,
  Tab,
  Tabs,
  TabsContainer,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { filterPageAccess } from 'constants/UIConstants'
import NotInPlan from 'pages/ErrorPages/NotInPlan'
import { me } from 'redux/modules/auth'
import { saveStripeCode } from 'redux/modules/orbirental/connect'
import { fetchProviders } from 'redux/modules/orbirental/crud'
import { deleteItem } from 'redux/modules/orbirental/crud'
import { checkStatus } from 'redux/modules/orbirental/status'
import currentUser from 'utils/CurrentUser'
import { parseQueryString } from 'utils/Urls'

import DefaultProviderForm from './DefaultProviderForm'
import MarketplaceConnectForm from './MarketplaceConnectForm'
import StripeSetupForm from './StripeSetupForm'

import { MarketplaceListItem } from '../../components/ModelItemRenderers'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  initialTab: PropTypes.string
}
const contextTypes = {
  router: PropTypes.object
}

class MarketplaceContainer extends Component {
  constructor(props, context) {
    super(props)
    this.state = {
      helpVisible: false,
      showDeleteConfirm: false,
      confirmDeleteId: null,
      confirmDeleteType: null,
      activeTabIndex: props.initialTab === 'providers' ? 1 : 0,
      connectError: null,
      hasLoadedProviders: false,
      loadingProviders: false,
      providers: [],
      services: []
    }
    this.addNew = this.addNew.bind(this)
    this.listRefs = {}
    // see if we're in the callback from stripe
    this.handleConnectCallback(context)
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(checkStatus())
  }

  componentWillUpdate(nextProps, nextState) {
    // if status checks out, fetch provider data
    // not loading status, is connected, has default_provider, has payment_processor
    // only do this automatically if we haven't already loaded data
    // provider data can be reloaded by manually calling this.loadProviderData
    const nextStatus = nextProps.status
    if (
      !nextStatus.isLoading &&
      !this.state.hasLoadedProviders &&
      !nextState.hasLoadedProviders &&
      nextStatus.data &&
      nextStatus.data.connected &&
      nextStatus.data.default_provider &&
      nextStatus.data.payment_processor
    ) {
      this.loadProviderData()
    }
  }

  loadProviderData = () => {
    let services = []
    let providers = []
    this.setState({ fetchingProviders: true, hasLoadedProviders: true })
    fetchProviders((data) => {
      if (data.length) {
        // take the returned data and split into list of providers and list of services.
        for (var i = 0, len = data.length; i < len; i++) {
          const item = Object.assign({}, data[i])
          if (item.services.length) {
            for (var j = 0, lenn = item.services.length; j < lenn; j++) {
              const service = Object.assign({}, item.services[j], {
                providerName: item.companyName
              })
              services.push(service)
            }
          }
          providers.push(item)
        }
      }
      this.setState({
        fetchingProviders: false,
        providers: providers,
        services: services
      })
    })
  }

  handleTabChange = (activeTabIndex) => {
    this.setState({ activeTabIndex: activeTabIndex }, () => {
      const tabPath = activeTabIndex === 0 ? '' : '/providers'
      this.context.router.history.push(`/host/marketplace${tabPath}`)
    })
  }

  addNew = (pluralName) => {
    const { dispatch } = this.props

    if (pluralName === 'services') {
      dispatch(push('/host/marketplaceItems/create'))
    } else {
      dispatch(push('/host/marketplaceProviders/create'))
    }
  }

  confirmDelete = (uid, pluralName, e) => {
    e.stopPropagation()
    this.setState({
      showDeleteConfirm: true,
      confirmDeleteId: uid,
      confirmDeleteType: pluralName
    })
  }

  deleteItem = (e) => {
    // prevent propagation so it doesn't also fire edit
    const { confirmDeleteId, confirmDeleteType } = this.state
    if (confirmDeleteId && confirmDeleteType) {
      deleteItem(confirmDeleteId, confirmDeleteType, () => {
        this.setState({ showDeleteConfirm: false }, () => {
          this.loadProviderData()
        })
      })
    }
  }

  editItem = (uid, pluralName, e) => {
    const path = `/host/${pluralName}/${uid}`
    const url = process.env.REACT_APP_BASE_URL + path
    window.location.href = url
  }

  closeDialog = () => {
    this.setState({
      showDeleteConfirm: false,
      confirmDeleteId: null,
      confirmDeleteType: null
    })
  }

  renderDescription = () => {
    return (
      <p className="md-body-1" style={{ padding: '16px 24px' }}>
        The Marketplace is an optional tab of your guidebook where you can sell
        additional services or items to your guests to earn additional revenue.
        Common offers include mid-stay cleaning, or late checkout. You can also
        sell additional amenities and local rentals. The Company is the entity
        that will receive payments for any services or items. The Provider is
        the specific person or group that provides the offer or service.
      </p>
    )
  }

  renderLoading = () => {
    return (
      <div className="md-text-center md-full-width">
        <LinearProgress id="status_check" />
        <div>Checking connection status...</div>
      </div>
    )
  }

  renderConnectionError = () => {
    return (
      <div className="hf-marketplace-dashboard">
        <Paper className="hf-card hf-marketplace-dashboard-card">
          <h3 className="hf-secondary-title">Connection Error</h3>
          <p className="md-body-1">
            There was an error establishing a connection to the Property
            Management Platform.
          </p>
          <p className="md-body-1">
            Please try again in a few minutes. <br />
            If the problem persists, please contact{' '}
            <a href="mailto:support@hostfully.com" className="hf-link">
              support@hostfully.com
            </a>
          </p>
        </Paper>
      </div>
    )
  }

  handleConnect = (data) => {
    const { dispatch } = this.props
    // reset user item with redirect
    dispatch(me('/host/marketplace'))
  }

  handleReload = (data) => {
    window.location.reload()
  }

  handleConnectCallback = (context) => {
    if (context.router?.route?.location) {
      const { pathname, search } = context.router.route.location

      if (pathname === '/host/marketplace/stripeConnect') {
        const urlParams = parseQueryString(search)
        const { scope, state, code, error_description } = urlParams

        if (code && state === this.getStripeState() && scope === 'read_write') {
          this.saveStripeCodeToAgency(code)
          return
        }

        const message =
          decodeURIComponent(error_description) ||
          'There was an error connecting to stripe'

        this.setState({ connectError: message })
      }
    }
  }

  /* state is used in the stripe connect process as a CSRF protection */
  getStripeState = () => {
    return localStorage.getItem('hf_stripe_state')
  }

  saveStripeCodeToAgency = async (code) => {
    await saveStripeCode(code)
    const path = `/host/marketplace`
    const url = process.env.REACT_APP_BASE_URL + path

    window.location.href = url
  }

  renderSetupSteps = () => {
    const { status } = this.props
    const mapStep = {
      0: () => null,
      1: () => <MarketplaceConnectForm onConnect={this.handleConnect} />,
      2: () => <DefaultProviderForm onConnect={this.handleReload} />,
      3: () => <StripeSetupForm />
    }
    let step = 0

    if (!status.data.connected) {
      // set up basic connection to orbirental
      step = 1
    } else if (!status.data.default_provider) {
      // set up default provider
      step = 2
    } else if (!status.data.payment_processor) {
      // set up stripe connect
      step = 3
    }

    return (
      <div className="hf-marketplace-dashboard">
        <div className="hf-main-title">Marketplace Setup</div>
        <div className="md-body-1">
          The Marketplace is an optional tab of your guidebook where you can
          tell additional services or items to your guests to earn additional
          revenue. Common offers include must-stay cleaning, or late checkout.
          You can also sell additional amenities and local rentals.
        </div>
        <div className="md-body-1 md-font-bold">
          To setup your marketplace, you will need to:
        </div>
        <ol>
          <li
            className={`hf-marketplace-dashboard-step ${status.data.connected && 'completed'} ${step === 1 && 'current'}`}
          >
            1. Setup your Marketplace Company
            {status.data.connected && <FontIcon>task_alt</FontIcon>}
          </li>
          <li
            className={`hf-marketplace-dashboard-step ${status.data.default_provider && 'completed'} ${step === 2 && 'current'}`}
          >
            2. Setup your default Marketplace Provider
            {status.data.default_provider && <FontIcon>task_alt</FontIcon>}
          </li>
          <li
            className={`hf-marketplace-dashboard-step ${status.data.payment_processor && 'completed'} ${step === 3 && 'current'}`}
          >
            3. Activate your Stripe account
            {status.data.payment_processor && <FontIcon>task_alt</FontIcon>}
          </li>
        </ol>
        <div className="md-body-1">
          The Marketplace Company is the entity that will receive payments for
          any services or items. The service Provider is the specific person or
          group that provides the offer or service.
        </div>
        {mapStep[step]()}
      </div>
    )
  }

  renderDeleteConfirmation = () => {
    const isProvider = this.state.activeTabIndex === 1
    const itemType = isProvider ? 'provider' : 'item'
    const mapText = {
      provider: (
        <span>
          <p className="md-headline-2">
            Are you sure you wish to delete this provider?
          </p>
          <p className="md-headline-1">
            All Marketplace items linked to it will also be removed. This cannot
            be undone.
          </p>
        </span>
      ),
      item: (
        <p className="md-headline-1">
          Are you sure you wish to delete this item? This cannot be undone.
        </p>
      )
    }

    return (
      <DialogContainer
        id="simpleDialogExample"
        aria-label="Confirm delete dialog"
        visible={this.state.showDeleteConfirm}
        onHide={this.closeDialog}
        dialogClassName="hf-marketplace-delete-dialog"
        footerClassName="hf-marketplace-delete-dialog-actions"
        actions={[
          {
            onClick: this.closeDialog,
            label: 'Cancel'
          },
          {
            onClick: this.deleteItem,
            primary: true,
            label: 'Delete'
          }
        ]}
      >
        <h3 id="deleteItemDescription" className="md-title">
          Remove {itemType}
        </h3>
        {mapText[itemType]}
      </DialogContainer>
    )
  }

  noData = (pluralName) => {
    const primaryText =
      pluralName === 'services'
        ? 'Click here to create a new Marketplace item'
        : 'Click here to create a new Provider'

    return (
      <Paper
        onClick={() => this.addNew(pluralName)}
        className="hf-card hf-marketplace-dashboard-add-card"
        zDepth={0}
      >
        <FontIcon>add_circle_outline</FontIcon>
        <p className="md-title">{primaryText}</p>
      </Paper>
    )
  }

  renderItemRows = () => {
    const items = this.state.services

    return items.map((item) => (
      <MarketplaceListItem
        key={item.uid}
        editItem={(e) => {
          this.editItem(item.uid, 'marketplaceItems', e)
        }}
        deleteItem={(e) => {
          this.confirmDelete(item.uid, 'services', e)
        }}
        canDelete={true}
        title={item.title}
        subtitle={item.description}
        description={item.longDescription}
        imageSrc={item.defaultPicture}
        type="service"
      />
    ))
  }

  renderProviderRows = () => {
    const providers = this.state.providers
    const user = currentUser()
    const canDelete =
      user.user.provider === 'orbirental'
        ? false
        : this.state.providers.length > 1

    return providers.map((provider) => (
      <MarketplaceListItem
        key={provider.uid}
        editItem={(e) => {
          this.editItem(provider.uid, 'marketplaceProviders', e)
        }}
        deleteItem={(e) => {
          this.confirmDelete(provider.uid, 'service_providers', e)
        }}
        canDelete={canDelete}
        title={provider.companyName}
        subtitle={provider.type}
        description={`${provider.firstName} ${provider.lastName}`}
        imageSrc={provider.imageUrl}
        type="provider"
      />
    ))
  }

  renderMain = () => {
    const { status } = this.props
    const serviceCount = this.state.services.length
    const providerCount = this.state.providers.length
    const pluralName =
      this.state.activeTabIndex === 0 ? 'services' : 'service_providers'

    if (status.isLoading || this.state.fetchingProviders) {
      return this.renderLoading()
    } else if (status.hasErrored || this.state.connectError) {
      return this.renderConnectionError()
    } else if (this.state.providers.length === 0) {
      return this.renderSetupSteps()
    } else {
      return (
        <div style={{ backgroundColor: '#fff' }}>
          {this.renderDescription()}
          <Button
            className={window.isIframe ? '' : 'hf-new-item'}
            onClick={() => {
              this.addNew(pluralName)
            }}
            floating
            secondary
            fixed
            mini
          >
            add
          </Button>
          <TabsContainer
            className="hf-list-tabs"
            onTabChange={this.handleTabChange}
            activeTabIndex={this.state.activeTabIndex}
            themed
          >
            <Tabs tabId="tab" className="hf-scrolling-tabs" mobile={true}>
              <Tab
                label={`Marketplace Items (${serviceCount})`}
                key="mktplc_items"
              >
                {this.noData('services')}
                <div className="hf-marketplace-list">
                  {this.renderItemRows()}
                </div>
              </Tab>
              <Tab label={`Providers (${providerCount})`} key="published">
                {this.noData('providers')}
                <div className="hf-marketplace-list">
                  {this.renderProviderRows()}
                </div>
              </Tab>
            </Tabs>
          </TabsContainer>
        </div>
      )
    }
  }

  render() {
    const user = currentUser()
    const showList = filterPageAccess('marketplace', user)

    return (
      <div className="hf-main-container">
        <Toolbar
          colored
          className="hf-host-toolbar hf-marketplace"
          title="Marketplace"
        />
        {!showList ? <NotInPlan /> : this.renderMain()}

        {this.renderDeleteConfirmation()}
      </div>
    )
  }
}

MarketplaceContainer.propTypes = propTypes
MarketplaceContainer.contextTypes = contextTypes

function mapStateToProps(state, props) {
  const orbiStatus = state['orbiStatusState']

  return {
    status: orbiStatus
  }
}

export default connect(mapStateToProps)(injectIntl(MarketplaceContainer))
