import React from 'react'

const DirectionsCar = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`directions_car`}</title>
    <path d="M5.016 11.016h13.969l-1.5-4.5H6.516zm12.468 4.968c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm-10.968 0c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zM18.938 6l2.063 6v8.016a.96.96 0 01-.984.984h-1.031a.96.96 0 01-.984-.984v-1.031h-12v1.031a.96.96 0 01-.984.984H3.987a.96.96 0 01-.984-.984V12l2.063-6c.188-.609.797-.984 1.453-.984h10.969c.656 0 1.266.375 1.453.984z" />
  </svg>
)

export default DirectionsCar
