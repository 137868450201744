import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'

import HostNavDrawer from 'components/HostNavDrawer'

import HostRoutes from './HostRoutes'

class Host extends Component {
  render() {
    return (
      <HostNavDrawer>
        <DocumentTitle title="Hostfully Host">
          <HostRoutes />
        </DocumentTitle>
      </HostNavDrawer>
    )
  }
}

Host.propTypes = {}

export default Host
