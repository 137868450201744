import React from 'react'

const VirtualAssistant = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`virtual_assistant`}</title>
    <path d="M12.33 2.006a8.2 8.2 0 00-.863.012c-4.015.26-7.102 3.592-7.434 7.55l-.013-.017L3 22h12v-2h-3a6 6 0 01-6-6v-1a2 2 0 012-2h8a2 2 0 012 2v3h-4.088a1.5 1.5 0 00-1.412-1 1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 001.393 1.494A1 1 0 0012.5 18H20c1.1 0 2-.9 2-2v-2c0-1.1-.9-2-2-2v-2a8.002 8.002 0 00-7.67-7.994z" />
  </svg>
)

export default VirtualAssistant
