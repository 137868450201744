import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Map from 'components/Map'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  onMapBoundsChange: PropTypes.func,
  onVisibleMarkersChange: PropTypes.func,
  fitType: PropTypes.string,
  initialZoom: PropTypes.number,
  fixToPosition: PropTypes.bool,
  outerContainer: PropTypes.string
}

const defaultProps = {
  fitType: 'smart',
  initialZoom: 13,
  fixToPosition: false,
  outerContainer: 'map-outer-container'
}

class GuidebookMap extends Component {
  componentDidUpdate() {
    this.fixMapPosition()
  }

  fixMapPosition = () => {
    if (this.props.fixToPosition) {
      // get width of the md-cell--6
      const container = document.getElementById(this.props.outerContainer)
      if (container) {
        const containerWidth = container.offsetWidth
        //const containerTop = container.offsetTop;
        const mapElement = document.getElementsByClassName(
          'map-container-fixed'
        )[0]
        mapElement.style.position = 'fixed'
        mapElement.style.width = containerWidth + 'px'
        // set width to the calculated width
      }
    }
  }

  render() {
    const markers = this.props.mapMarkers
    const options = this.props.mapOptions
    const mapStyle = { position: 'fixed', width: '100%' }
    // allow setting initial
    return (
      <div className="guidebook-map" style={mapStyle}>
        <Map
          className={this.props.className}
          lat={this.props.lat || this.props.guidebook.address.lat}
          lng={this.props.lng || this.props.guidebook.address.lng}
          zoom={options.zoom || this.props.initialZoom}
          onClick={this.onMapClick}
          fitType={this.props.fitType}
          onBoundsChanged={this.props.onBoundsChanged}
          onVisibleMarkersChange={this.props.onVisibleMarkersChange}
          onWindowResized={this.fixMapPosition}
          mapId={this.props.guidebook.id + '-map'}
          useLocation
        >
          {markers}
        </Map>
      </div>
    )
  }
}

GuidebookMap.propTypes = propTypes
GuidebookMap.defaultProps = defaultProps

function mapStateToProps(state, props) {
  return {
    mapMarkers: state.map.markers,
    mapOptions: state.map.options
  }
}

export default connect(mapStateToProps)(GuidebookMap)
