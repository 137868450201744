import React from 'react'

const Assignment = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`assignment`}</title>
    <path d="M17.016 9V6.984H6.985V9h10.031zm0 3.984v-1.969H6.985v1.969h10.031zm-3 4.032V15H6.985v2.016h7.031zM12 3c-.563 0-.984.422-.984.984s.422 1.031.984 1.031.984-.469.984-1.031S12.562 3 12 3zm6.984 0C20.062 3 21 3.938 21 5.016v13.969c0 1.078-.938 2.016-2.016 2.016H5.015c-1.078 0-2.016-.938-2.016-2.016V5.016C2.999 3.938 3.937 3 5.015 3h4.172C9.609 1.828 10.687.984 12 .984s2.391.844 2.813 2.016h4.172z" />
  </svg>
)

export default Assignment
