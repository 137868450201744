import React from 'react'

const LaptopMac = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`laptop_mac`}</title>
    <path d="M12 18.984c.563 0 .984-.422.984-.984s-.422-.984-.984-.984-.984.422-.984.984.422.984.984.984zM3.984 5.016v10.969h16.031V5.016H3.984zM20.016 18H24c0 1.078-.938 2.016-2.016 2.016H2.015c-1.078 0-2.016-.938-2.016-2.016h3.984c-1.078 0-1.969-.938-1.969-2.016V5.015c0-1.078.891-2.016 1.969-2.016h16.031c1.078 0 1.969.938 1.969 2.016v10.969c0 1.078-.891 2.016-1.969 2.016z" />
  </svg>
)

export default LaptopMac
