import React from 'react'

const EvStation = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`ev_station`}</title>
    <path d="M8.016 18L12 11.016H9.984V6L6 13.5h2.016V18zM18 9.984c.563 0 .984-.422.984-.984s-.422-.984-.984-.984-.984.422-.984.984.422.984.984.984zm1.781-2.765c.469.469.703 1.078.703 1.781v9.516C20.484 19.875 19.359 21 18 21s-2.484-1.125-2.484-2.484V13.5h-1.5V21H3.985V5.016C3.985 3.938 4.923 3 6.001 3h6c1.078 0 2.016.938 2.016 2.016V12h.984c1.078 0 2.016.938 2.016 2.016v4.5c0 .563.422.984.984.984s.984-.422.984-.984v-7.219a2.298 2.298 0 01-.984.188 2.5 2.5 0 01-2.484-2.484c0-1.078.656-1.969 1.594-2.344l-2.109-2.109 1.078-1.031z" />
  </svg>
)

export default EvStation
