import React from 'react'

const Toilet = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`toilet`}</title>
    <path d="M5 2c-.523 0-1.059.184-1.438.563C3.184 2.94 3 3.477 3 4v9c0 2.79 1.652 5.117 4 6.594V22h10v-2.406c2.348-1.477 4-3.805 4-6.594v-1H11V4c0-.523-.184-1.059-.563-1.438C10.06 2.184 9.524 2 9 2zm0 2h4v8H5zm1 1v3h2V5zm-.688 9h13.375c-.355 1.727-1.367 3.25-3.156 4.219L15 18.5V20H9v-1.5l-.531-.281C6.679 17.25 5.668 15.727 5.313 14z" />
  </svg>
)

export default Toilet
