import React from 'react'

const MovieCreation = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`movie_creation`}</title>
    <path d="M18 3.984h3.984V18c0 1.078-.891 2.016-1.969 2.016H3.984c-1.078 0-1.969-.938-1.969-2.016V6c0-1.078.891-2.016 1.969-2.016h1.031l1.969 4.031h3L8.015 3.984h1.969L12 8.015h3l-2.016-4.031H15l2.016 4.031h3z" />
  </svg>
)

export default MovieCreation
