export const addressDefaults = {
  street_number: '',
  street: '',
  state: '',
  prefix: '',
  post_code: '',
  locality: '',
  lat: 37.774929,
  lng: -122.419416,
  country_code: ''
}
export const directionsDefaults = {
  label: '',
  show_airport: false,
  airport_text: '',
  airport_text_txn: {},
  show_taxi: false,
  taxi_text: '',
  taxi_text_txn: {},
  show_uber: false,
  uber_text: '',
  uber_text_txn: {},
  uberpool_voucher: '',
  show_lyft: false,
  lyft_text: '',
  lyft_text_txn: {},
  lyft_voucher: '',
  show_bus: false,
  bus_text: '',
  bus_text_txn: {},
  show_train: false,
  train_text: '',
  train_text_txn: {},
  show_pickup: false,
  pickup_text: '',
  pickup_text_txn: {},
  show_driving: false,
  driving_text: '',
  driving_text_txn: {},
  additional_directions: '',
  additional_directions_txn: {}
}
export const parkingDefaults = {
  on_street_available: false,
  expensive_nearby: false,
  economical_available: false,
  driveway_available: false,
  parking_text: '',
  parking_text_txn: {}
}
export const checkoutDefaults = {
  checkout_time: '11:00am',
  checkout_text: '',
  checkout_text_txn: {},
  show_late_checkout: true,
  late_checkout_sometimes: false,
  late_checkout_no: false,
  late_checkout_contact: false,
  store_bags_after_checkout: false,
  sign_guestbook: false,
  towels_on_floor: false,
  towels_in_tub: false,
  take_out_trash: false,
  rearrange_furniture: false,
  lock_doors: false,
  key_lockbox: false,
  key_inside: false,
  electrical_off: false,
  door_unlocked: false,
  dishes_in_washer: false,
  clean_dishes: false,
  beds_unmade: false
}
export const hostInfoDefaults = {
  host_email: '',
  host_name: '',
  label: '',
  image: '',
  host_phone: [],
  host_intro: '',
  host_intro_txn: {}
}

export const wifiDefaults = {
  wifi_provided: false,
  network_name: '',
  wifi_password: '',
  reset_text: '',
  reset_text_txn: {},
  wifi_rules_text: '',
  wifi_rules_text_txn: {},
  cafe_address: '',
  use_external_cafe: false,
  external_network_name: '',
  use_external_network: false,
  if_issues_reset: false,
  if_issues_cafe: false,
  if_issues_alt: false,
  login_provided_offline: false,
  login_offline_text: '',
  login_offline_text_txn: {}
}
export const checkinDefaults = {
  show_early: false,
  self_access_meet: false,
  self_access_lockbox: false,
  self_access_keyless: false,
  self_access_email: false,
  self_access_codes: false,
  id: 0,
  early_checkin_text: '',
  early_checkin_text_txn: {},
  early_checkin_sometimes: false,
  early_checkin_never: false,
  early_checkin_alternative: false,
  checkin_time: '',
  use_checkin_end_time: false,
  checkin_end_time: '',
  checkin_text: '',
  checkin_text_txn: {},
  bag_storage_allowed: false,
  access_image: ''
}

export const houseRulesInformationDefaults = {
  name: '',
  title: '',
  title_txn: {},
  content: '',
  content_txn: {},
  icon: ''
}
export const houseRulesDefaults = {
  informations: []
}
