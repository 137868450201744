import React from 'react'

const PhoneIphone = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`phone_iphone`}</title>
    <path d="M15.984 18V3.984h-9V18h9zm-4.5 3.984c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm4.032-21C16.875.984 18 2.156 18 3.515v16.969c0 1.359-1.125 2.531-2.484 2.531H7.5c-1.359 0-2.484-1.172-2.484-2.531V3.515C5.016 2.156 6.141.984 7.5.984h8.016z" />
  </svg>
)

export default PhoneIphone
