import React from 'react'

const LiveTv = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`live_tv`}</title>
    <path d="M9 9.984l6.984 4.031L9 17.999V9.983zm12 10.032v-12H3v12h18zM21 6c1.078 0 2.016.891 2.016 2.016v12c0 1.078-.938 1.969-2.016 1.969H3c-1.078 0-2.016-.891-2.016-1.969v-12C.984 6.891 1.922 6 3 6h7.594L7.313 2.719l.703-.703L12 6l3.984-3.984.703.703L13.406 6H21z" />
  </svg>
)

export default LiveTv
