import React from 'react'

const Dining = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`dining`}</title>
    <path d="M3.9 11.9H1v4.3h.6v-3h2.3v3h.6v-4.3h-.6zM1.6 10.8h.6V7.6H1v4.3h.6v-1.1zM20.1 11.9h-.6v4.3h.6v-3h2.3v3h.6v-4.3h-2.9z" />
    <path d="M22.4 7.6h-.6v3.2h.6V12h.6V7.6h-.6zM19.5 11.6v-1.3h-2.8V7.6H13v2.7h-2V7.6H7.2v2.7H4.5v1.3h.6v4.7h1.2v-4.7h1.6v.5h-.7v4.3h.6v-3.2h2.6v3h.4v-4.3h-.7v-.3h3.5v.5H13v4.3h.6v-3.2h2.6v3h.4v-4.3h-.7v-.3h1.9v4.7H19v-4.7h.5zm-10 .3H8.4v-.3h1.2v.3zm5.8 0h-1.2v-.3h1.2v.3z" />
  </svg>
)

export default Dining
