import 'whatwg-fetch'

export function searchByGb(key, query, locale) {
  let url =
    process.env.REACT_APP_API_URL +
    '/api/v1/search/gb/' +
    key +
    '/' +
    locale +
    '/' +
    query

  const config = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }

  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
}
