import { getToken } from 'utils/Auth'

import 'whatwg-fetch'

export function createAgency(company_name, email) {
  const method = 'POST'
  const path = `/api/v1/orbirental/agencies`
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const body = {
    name: company_name,
    email: email
  }
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
    .catch(function (err) {
      return { error: err }
    })
}

export function verifyWebhooks() {
  const method = 'POST'
  const path = `/api/v1/orbirental/agencies/verify_webhooks`
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
    .catch(function (err) {
      return { error: err }
    })
}

export function createDefaultProvider(first_name, last_name, email) {
  const method = 'POST'
  const path = `/api/v1/orbirental/agencies/default_serviceprovider`
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const body = {
    first_name: first_name,
    last_name: last_name,
    email: email
  }
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
    .catch(function (err) {
      return { error: err }
    })
}

export function saveStripeCode(code) {
  const method = 'POST'
  const path = `/api/v1/orbirental/agencies/connect_stripe`
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const body = {
    code: code
  }
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
    .catch(function (err) {
      return { error: err }
    })
}

export function attachPropertyToGuidebook(property_uid, guidebook_id) {
  const method = 'POST'
  const path = `/api/v1/orbirental/properties/attach`
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const body = {
    property_uid: property_uid,
    guidebook_id: guidebook_id
  }
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
    .catch(function (err) {
      return { error: err }
    })
}

export function synchTokens(property_uid) {
  const method = 'POST'
  const path = `/api/v1/orbirental/synch_tokens`
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const body = {
    property_uid: property_uid
  }
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
    .catch(function (err) {
      return { error: err }
    })
}

export function autoCreateTokens(guidebook_id, create_tokens) {
  const method = 'POST'
  const path = `/api/v1/orbirental/agencies/create_tokens`
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const body = {
    guidebook_id: guidebook_id,
    create_tokens: create_tokens
  }
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
    .catch(function (err) {
      return { error: err }
    })
}

export function detachPropertyFromGuidebook(property_uid, guidebook_id) {
  const method = 'POST'
  const path = `/api/v1/orbirental/properties/detach`
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const body = {
    property_uid: property_uid,
    guidebook_id: guidebook_id
  }
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
    .catch(function (err) {
      return { error: err }
    })
}
