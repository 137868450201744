import React from 'react'

const AirportShuttle = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`airport_shuttle`}</title>
    <path d="M15 11.016h5.016l-4.031-4.031h-.984v4.031zm2.484 6.468c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm-4.5-6.468V6.985H9v4.031h3.984zM6 17.484c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm-3-6.468h3.984V6.985H3v4.031zm14.016-6l6 6v4.969h-2.531c0 1.641-1.359 3-3 3s-3-1.359-3-3H9.001c0 1.641-1.359 3-3 3s-3-1.359-3-3H.985v-9c0-1.125.938-1.969 2.016-1.969h14.016z" />
  </svg>
)

export default AirportShuttle
