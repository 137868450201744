import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import {
  Avatar,
  Button,
  Card,
  CardText,
  CardTitle,
  DialogContainer,
  ListItem,
  Toolbar
} from 'react-md'
import UAParser from 'ua-parser-js'

import { trackCardOpened } from 'utils/GTM';

const propTypes = {
  // (future) use id to tie animations together
  itemId: PropTypes.string,

  // properties to use in both listItem and Dialog
  leftIcon: PropTypes.node,
  iconColor: PropTypes.string,
  // this will also become the header of the modal
  primaryText: PropTypes.string.isRequired,
  // this will also become the main body of the modal if no children
  secondaryText: PropTypes.node,
  threeLines: PropTypes.bool,

  // for tracking data
  trackLabel: PropTypes.string,
  guidebook: PropTypes.shape({
    id: PropTypes.number,
    host_user_id: PropTypes.number
  }),
  tabName: PropTypes.string,
  // properties used for the modal dialog
  modalVisible: PropTypes.bool,
  focusOnMount: PropTypes.bool,
  containerRef: PropTypes.object,

  // properties used for setting the url when opening/closing the dialog
  baseLocation: PropTypes.string,
  cardTitle: PropTypes.string,

  // custom style for Avatar when opening
  avatarStyle: PropTypes.object,

  // whether to show the "more" indicator
  showMore: PropTypes.bool
}

const defaultProps = {
  modalVisible: false,
  focusOnMount: true,
  leftIcon: '',
  iconColor: 'hfprimaryavatar',
  avatarStyle: {},
  showMore: true
}

class ListItemModal extends PureComponent {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      modalVisible: props.modalVisible
    }
  }

  openDialog = () => {
    const guidebook_id = this.props.guidebook.id || 0
    const host_user_id = this.props.guidebook.host_user_id || 0
    const tab_name = this.props.tabName || 'unknown'
    const card_key = this.props.cardTitle || null
    const card_name = this.props.trackLabel || this.props.primaryText || null
    // send card_opened event to GTM/GA4
    trackCardOpened(guidebook_id, host_user_id, tab_name, card_name, card_key);
    this.setState({ modalVisible: true }, () => {
      if (this.props.baseLocation && this.props.cardTitle) {
        this.context.router.history.push(
          `${this.props.baseLocation}/${this.props.cardTitle}`
        )
      }
    })
  }

  closeDialog = () => {
    // OLD SEGMENT CODE
    // let eventData = {
    //   category: 'Guidebook',
    //   label: (this.props.trackLabel) ? this.props.trackLabel : this.props.primaryText
    // };
    // trackEvent('listitemClosed', eventData);
    this.setState({ modalVisible: false }, () => {
      if (this.props.baseLocation) {
        this.context.router.history.push(this.props.baseLocation)
      }
    })
  }
  renderToolbar = (icon) => {
    var action = (
      <Button icon onClick={this.closeDialog}>
        back
      </Button>
    )
    return (
      <Toolbar
        colored
        nav={icon}
        actions={action}
        title={this.props.primaryText.substring(0, 150)}
        fixed
      />
    )
  }

  // if we have children to render, use those
  // otherwise use secondary text
  renderContent = (icon) => {
    //Add title - displays on desktop dialogs
    var dialogTitle = this.props.title
      ? this.props.title
      : this.props.primaryText.substring(0, 150)
    var dialogContent = ''
    if (typeof this.props.children === 'undefined') {
      if (this.props.secondaryText) {
        dialogContent = this.props.secondaryText
      } else {
        dialogContent = this.props.primaryText
      }
    } else {
      if (this.props.children && this.props.children.length) {
        dialogContent = this.props.children
      }
    }
    return (
      <div className="list-item-modal-content">
        <Card className="list-item-modal-card" tabIndex="0">
          <Button
            icon
            className="md-cell--right list-item-modal-close"
            onClick={this.closeDialog}
          >
            keyboard_backspace
          </Button>
          <CardTitle avatar={icon} title={dialogTitle}></CardTitle>
          <CardText dir="auto">{dialogContent}</CardText>
        </Card>
      </div>
    )
  }

  render() {
    // generate unique-ish id for items passed with no id
    const idForItems = this.props.itemId
      ? this.props.itemId
      : new Date().valueOf()
    const ua = new UAParser()
    let disableScrollLocking = ua.getDevice().type !== 'mobile'

    var icon = null
    if (this.props.leftIcon) {
      icon = (
        <Avatar
          icon={this.props.leftIcon}
          suffix={this.props.iconColor}
          style={this.props.avatarStyle}
          role="presentation"
        />
      )
    }
    const showMore = this.props.showMore ? (
      <div className="hf-list-item-more" onClick={this.openDialog}>
        <span className="hf-more-span">click for more</span> »
      </div>
    ) : null

    // const primaryText = (
    //   <div dir="auto" className="hf-fullwidth">{this.props.primaryText}</div>
    // );

    const secondaryText = (
      <div dir="auto" className="hf-fullwidth">
        {this.props.secondaryText}
      </div>
    )

    return (
      <div className="hf-list-item-modal">
        <ListItem
          id={`li_${idForItems}`}
          leftIcon={this.props.leftIcon}
          primaryText={this.props.primaryText}
          primaryTextClassName="hf-autoalign"
          secondaryText={secondaryText}
          secondaryTextClassName="hf-autoalign"
          onClick={this.openDialog}
          threeLines={this.props.threeLines}
        />
        <DialogContainer
          id={`dialog_${idForItems}`}
          visible={this.state.modalVisible}
          aria-label={this.props.primaryText}
          onHide={this.closeDialog}
          focusOnMount={this.props.focusOnMount}
          fullPage
          portal={true}
          lastChild={true}
          disableScrollLocking={disableScrollLocking}
          renderNode={document.body}
          className="hf-guest-modal"
        >
          {this.renderContent(icon)}
        </DialogContainer>
        {showMore}
      </div>
    )
  }
}

ListItemModal.propTypes = propTypes
ListItemModal.defaultProps = defaultProps

export default ListItemModal
