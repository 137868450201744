import React, { Component } from 'react'
import {
  Button,
  DataTable,
  DialogContainer,
  SelectField,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow,
  TextField,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'

import {
  addReservedWord,
  deleteReservedWord,
  fetchReservedWords
} from 'redux/modules/reservedWords'
import { addToast } from 'redux/modules/toast'
import { sortData } from 'utils/Data'

class SlugBlacklist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reservedWords: [],
      addWord: '',
      wordType: 'literal',
      sortField: {},
      showDeleteConfirm: false,
      confirmDelete: null
    }
  }

  componentDidMount() {
    this.loadReservedWords()
  }

  loadReservedWords = () => {
    fetchReservedWords().then((data) => {
      this.setState({ reservedWords: data })
    })
  }

  handleChangeWord = (value) => {
    this.setState({ addWord: value })
  }

  handleChangeType = (value) => {
    this.setState({ wordType: value })
  }

  submitReservedWord = () => {
    const word = this.state.addWord
    const type = this.state.wordType
    if (word) {
      addReservedWord(word, type).then((data) => {
        if (data.errors) {
          this.setState({ addWord: '' }, () => {
            this.props.dispatch(addToast('This word has already been used'))
          })
        } else {
          this.setState({ addWord: '' }, () => {
            this.loadReservedWords()
          })
        }
      })
    }
  }

  confirmDeleteWord = (word, e) => {
    e.stopPropagation()
    this.setState({ showDeleteConfirm: true, confirmDelete: word })
  }

  closeDialog = () => {
    this.setState({ showDeleteConfirm: false, confirmDelete: null })
  }

  renderDeleteConfirmation = () => {
    const wordDisplay = this.state.confirmDelete
      ? this.state.confirmDelete.word
      : ''
    return (
      <DialogContainer
        id="simpleDialogExample"
        aria-label="Confirm delete dialog"
        visible={this.state.showDeleteConfirm}
        onHide={this.closeDialog}
        actions={[
          {
            onClick: this.closeDialog,
            primary: true,
            label: 'Cancel'
          },
          {
            onClick: this.deleteReservedWord,
            primary: true,
            label: 'Delete'
          }
        ]}
      >
        <p id="deleteItemDescription" className="md-color--secondary-text">
          Confirm that you would like to delete the following word:
          <br />
          <strong>{wordDisplay}</strong>
        </p>
      </DialogContainer>
    )
  }

  deleteReservedWord = () => {
    if (this.state.confirmDelete && this.state.confirmDelete.id) {
      this.closeDialog()
      deleteReservedWord(this.state.confirmDelete.id).then((data) => {
        this.loadReservedWords()
      })
    }
  }

  sortData = (col) => {
    const { sortField } = this.state
    const newSortField = {}
    let sortedWords = []
    if (sortField[col]) {
      const direction = sortField[col].ascending ? 'desc' : 'asc'
      sortedWords = sortData(this.state.reservedWords, col, direction)
      newSortField[col] = { ascending: !sortField[col].ascending }
    } else {
      sortedWords = sortData(this.state.reservedWords, col, 'asc')
      newSortField[col] = { ascending: true }
    }
    this.setState({ reservedWords: sortedWords, sortField: newSortField })
  }

  renderReservedWords = () => {
    let rows = (
      <TableRow>
        <TableColumn>No reserved words defined.</TableColumn>
      </TableRow>
    )

    if (this.state.reservedWords.length) {
      rows = this.state.reservedWords.map((word, idx) => {
        return (
          <TableRow key={`word-${word.id}`}>
            <TableColumn>{word.word}</TableColumn>
            <TableColumn>{word.type}</TableColumn>
            <TableColumn>{word.user_id}</TableColumn>
            <TableColumn>
              <Button
                icon
                primary
                onClick={(e) => {
                  this.confirmDeleteWord(word, e)
                }}
              >
                delete
              </Button>
            </TableColumn>
          </TableRow>
        )
      })
    }

    const { sortField } = this.state
    const wordList = (
      <DataTable baseId="movies" selectableRows={false}>
        <TableHeader>
          <TableRow>
            <TableColumn
              grow
              role="button"
              sorted={sortField.word && sortField.word.ascending}
              sortIconBefore={false}
              onClick={() => this.sortData('word')}
            >
              Word
            </TableColumn>
            <TableColumn
              role="button"
              sorted={sortField.type && sortField.type.ascending}
              sortIconBefore={false}
              onClick={() => this.sortData('type')}
            >
              Type
            </TableColumn>
            <TableColumn
              numeric
              role="button"
              sorted={sortField.user_id && sortField.user_id.ascending}
              sortIconBefore={false}
              onClick={() => this.sortData('user_id')}
            >
              user Id
            </TableColumn>
            <TableColumn>&nbsp;</TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>{rows}</TableBody>
      </DataTable>
    )

    return (
      <div className="md-cell md-cell--12 reserved-words-list">
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--12">
            <h2>Currently Blacklisted Words</h2>
          </div>
          {wordList}
        </div>
      </div>
    )
  }

  renderInput = () => {
    const self = this
    const word_types = ['literal', 'wildcard']
    return (
      <div className="md-cell md-cell--12 reserved-words-input">
        <div className="md-grid">
          <TextField
            id="word"
            className="md-cell md-cell--4"
            label="Add a word"
            value={self.state.addWord}
            onChange={self.handleChangeWord}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                return self.submitReservedWord()
              }
            }}
          />
          <SelectField
            id="word_type"
            label="word type"
            menuItems={word_types}
            value={self.state.wordType}
            onChange={self.handleChangeType}
            simplifiedMenu={false}
            className="md-cell md-cell--2 md-text-left"
          />
          <Button
            onClick={self.submitReservedWord}
            className="md-cell md-cell--2 md-cell--middle"
            flat
            primary
          >
            Submit
          </Button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="hf-main-container">
        <Toolbar
          className={'hf-host-toolbar hf-reports'}
          colored
          title="Custom URL Slug Blacklist"
        />
        <div className="md-grid">
          <div className="md-cell md-cell--10 md-cell--1-desktop-offset">
            <div className="md-grid md-grid--no-spacing">
              {this.renderReservedWords()}
              {this.renderInput()}
              {this.renderDeleteConfirmation()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(SlugBlacklist)
