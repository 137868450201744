const formatAddress = function (address, excludePrefix) {
  // concatenate each item into street address

  // street_number street, prefix
  var streetAddress = ''
  if (address.street_number) {
    streetAddress += address.street_number
  }
  // add spaces and commas where applicable
  if (address.street) {
    if (streetAddress.length) streetAddress += ' '
    streetAddress += address.street
  }
  if (!excludePrefix && address.prefix) {
    if (streetAddress.length) streetAddress += ', '
    streetAddress += address.prefix
  }

  // greater location
  // locality, state, post_code, country_code
  var greaterLoc = ''
  if (address.locality) {
    greaterLoc = address.locality
  }
  if (address.state) {
    if (greaterLoc.length) greaterLoc += ', '
    greaterLoc += address.state
  }
  if (address.post_code) {
    if (greaterLoc.length) greaterLoc += ' '
    greaterLoc += address.post_code
  }
  if (address.country_code) {
    if (greaterLoc.length) greaterLoc += ', '
    greaterLoc += address.country_code
  }

  return { addressPrimary: streetAddress, addressSecondary: greaterLoc }
}

export default formatAddress
