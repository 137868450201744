export const getAvailableTemplates = () => {
  return [
    {
      name: 'Photo',
      help: 'Capture guests attention with a meaningful image',
      tabClass: 'photo-template-tab'
    },
    {
      name: 'Video',
      help: 'Host your videos on Vimeo or Youtube before using this template',
      tabClass: 'video-template-tab'
    },
    {
      name: 'Text',
      help: 'This is good for important things which just have to be said. You can go into more detail later',
      tabClass: 'text-template-tab'
    },
    {
      name: 'Icon',
      help: 'For those times when all you need is the title and icon to engage guests to open the card',
      tabClass: 'icon-template-tab'
    }
  ]
}
