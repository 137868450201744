import React from 'react'

const DirectionsRailway = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`directions_railway`}</title>
    <path d="M18 9.984V5.015H6v4.969h12zm-6 7.032c1.078 0 2.016-.938 2.016-2.016s-.938-2.016-2.016-2.016-2.016.938-2.016 2.016.938 2.016 2.016 2.016zm-8.016-1.5v-10.5C3.984 1.5 7.593.985 12 .985s8.016.516 8.016 4.031v10.5c0 1.922-1.594 3.469-3.516 3.469l1.5 1.5v.516H6v-.516l1.5-1.5c-1.922 0-3.516-1.547-3.516-3.469z" />
  </svg>
)

export default DirectionsRailway
