import React from 'react'

const Mosquito = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`mosquito`}</title>
    <path d="M2 2v2h3.625L5 6c0 .688 1.477 2.395 3.313 3.625C5.335 10.27 2 10.223 2 11c0 .508.922 1.54 2 2.25V15l-1.969 4.094L4 20l2-5v-1c1.113 0 2.91-.805 4.25-2.125.05-.02.105-.012.156-.031.035.07.086.125.125.187C9.051 13.402 7 15 7 15v3c-.328 1.367-.86 2.703-1 3l2 1c.29-.582.773-3.047 1-4v-2c.29-.285.84-.738 1.375-1.188-.207.805-.344 1.594-.344 2.032 0 1.187.89 5.156 2 5.156C13.141 22 14 18.031 14 16.844c0-.434-.14-1.207-.344-2 .516.437 1.063.879 1.344 1.156v2s.71 3.418 1 4l2-1c-.14-.297-.672-1.633-1-3v-3s-2.05-1.598-3.531-2.969c.039-.062.09-.117.125-.187.058.023.129.008.187.031C15.121 13.184 16.891 14 18 14v1l2 5 2-1-2-4v-1.75c1.078-.71 2-1.742 2-2.25 0-.742-3.367-.656-6.375-1.344C17.488 8.426 19 6.696 19 6l-.625-2H22V2h-6l1 4c-.676.75-1.918 1.8-3.156 2.438a1.161 1.161 0 00-.625-.72c.465-.304.781-.753.781-1.218 0-.672-.195-1.531-1.25-1.5L12 2l-.781 3.063C10.19 5.117 10 5.96 10 6.625c0 .453.3.852.75 1.125-.305.16-.488.379-.594.688C8.918 7.8 7.676 6.75 7 6l1-4z" />
  </svg>
)

export default Mosquito
