import React from 'react'
import Marker from 'components/Map/Marker'
import { resizeImage } from 'utils/Image'
import { customColor } from 'utils/Color'

export default function RecommendationMarkers(
  recommendations,
  size,
  showPopup,
  fillColor,
  clickHandler,
  theme
) {
  return (
    // filter the ones that have no address
    recommendations
      .filter((rec) => {
        return rec.address !== null
      })
      .map((rec) => {
        const rpos = {
          lat: rec.address.lat,
          lng: rec.address.lng
        }

        const clickHandlerFn = clickHandler
          ? (e) => {
              clickHandler(rec, e)
            }
          : null

        let markerColor = fillColor || '#F44336'
        if (rec && rec.category && rec.category.color) {
          markerColor = customColor(
            rec.category.name.toLowerCase(),
            theme,
            rec.category.color
          )
        }
        const imgSize = parseInt(size, 10) * 2
        const image = rec.image
          ? resizeImage(rec.image, imgSize, imgSize, true)
          : '/add-place-img.svg'

        const marker = (
          <Marker
            key={'recmarker-' + rec.id}
            id={'rec_' + rec.id}
            type="recommendation"
            name={rec.name}
            imageUrl={image}
            clickable={true}
            draggable={false}
            fillColor={markerColor}
            onClick={clickHandlerFn}
            height={size}
            width={size}
            size={size}
            position={rpos}
            popup={null}
          />
        )
        return marker
      })
  )
}
