import React from 'react'

const AccessAlarms = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`access_alarms`}</title>
    <path d="M12 20.016c3.891 0 6.984-3.141 6.984-7.031S15.89 6.001 12 6.001s-6.984 3.094-6.984 6.984S8.11 20.016 12 20.016zm0-16.032c5.016 0 9 3.984 9 9s-3.984 9-9 9-9-3.984-9-9 3.984-9 9-9zm.516 4.032v5.297l3.984 2.391-.797 1.219-4.688-2.906v-6h1.5zM7.922 3.422L3.281 7.219l-1.266-1.5 4.594-3.797zm14.062 2.297l-1.266 1.5-4.641-3.938 1.313-1.5z" />
  </svg>
)

export default AccessAlarms
