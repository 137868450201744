import React from 'react'

const Ants = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`ants`}</title>
    <path d="M20.2 10.8v-.7l-4.5-1.5-2.4 1.4-.3-.5c.8-.5 1.3-1.4 1.3-2.4V7c0-.7-.3-1.3-.7-1.8l1.8-2-.4-.5-1.5 2.2c-.4-.3-.9-.5-1.5-.5s-1.1.2-1.5.5l-1.8-2-.5.4 1.8 2c-.4.5-.7 1.2-.7 1.9v.1c0 1 .5 1.9 1.3 2.4l-.3.8L8 8.7l-4.3 1.8v.7l4-1.5 2.5 1.5V12.1H4.7v.7h5.7l.7 1c-.4.3-.7.6-1 1H7.3l-2.3 4 .5.3 2.2-3.7h2c-.2.5-.3 1.2-.3 1.8 0 2.2 1.2 3.8 2.7 3.8s2.7-1.7 2.7-3.8c0-.7-.1-1.3-.3-1.9h2l2.2 3.7.5-.3-2.3-4H14c-.3-.4-.6-.7-1-1l.7-1h5.8V12h-5.8c0-.2.1-.4.1-.7s0-.5-.1-.7L16 9.3l4.2 1.5z" />
  </svg>
)

export default Ants
