import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Avatar, FontIcon, ListItem } from 'react-md'
import { resizeImage } from 'utils/Image'
import { ucFirst } from 'utils/Strings'

class RecommendationListItem extends Component {
  formatSecondary = (rec) => {
    if (
      rec.address &&
      rec.address.locality &&
      rec.address.street &&
      rec.address.post_code
    ) {
      return `${rec.address.street_number ? rec.address.street_number + ' ' : ''}${rec.address.street}, ${rec.address.locality} (${rec.address.post_code})`
    } else if (rec.address && rec.address.locality && rec.address.street) {
      return `${rec.address.street}, ${rec.address.locality}`
    } else if (rec.address && rec.address.locality) {
      return rec.address.locality
    } else {
      return 'Locationless Recommenation'
    }
  }

  render() {
    const { item, editItem, deleteItem, pluralName } = this.props
    const link = (
      <Link
        to={'/host/' + pluralName + '/' + item.id}
        onClick={(e) => {
          e.stopPropagation()
        }}
        className="md-tile-text--primary md-text hf-fake-link"
      >
        {item.name}
      </Link>
    )
    const image = resizeImage(item.image, 50, 50, true)
    const catName =
      item.category.name.charAt(0).toUpperCase() + item.category.name.slice(1)
    const catColor = item.category.color
      ? {
          backgroundColor: item.category.color,
          color: '#ffffff',
          padding: '2px 6px',
          borderRadius: '5px'
        }
      : {}
    const wizColor = {
      backgroundColor: '#cccccc',
      color: '#ffffff',
      padding: '2px 6px',
      borderRadius: '5px'
    }
    const wizardSpan =
      item.batch_ref && item.batch_ref.startsWith('wizard') ? (
        <span>
          &nbsp;&nbsp;&nbsp;
          <span className="rec-list-category" style={wizColor}>
            wizard
          </span>
        </span>
      ) : null
    const sponsoredLabel = item.sponsor_source
      ? ucFirst(item.sponsor_source)
      : 'Sponsored'
    const sponsoredRef = item.sponsor_ref ? ` ${item.sponsor_ref}` : ''
    const sponsoredSpan = item.sponsored ? (
      <span className="item-list-sponsored">
        <FontIcon>loyalty</FontIcon> {sponsoredLabel}
        {sponsoredRef}
      </span>
    ) : null
    const fullSecondary = (
      <div>
        <span>{this.formatSecondary(item)}</span>
        <br />
        <span className={`rec-list-category ${catName}`} style={catColor}>
          {catName}
        </span>
        {wizardSpan} {sponsoredSpan}
      </div>
    )
    const avatarStyle = item.category.color
      ? { borderColor: item.category.color, cursor: 'pointer' }
      : {}
    return (
      <ListItem
        onClick={editItem}
        className={'hf-list-item hf-' + pluralName + 'list-item'}
        leftAvatar={
          <Link
            to={'/host/' + pluralName + '/' + item.id}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <Avatar src={image} role="presentation" style={avatarStyle} />
          </Link>
        }
        primaryText={link}
        secondaryText={fullSecondary}
        rightIcon={<FontIcon onClick={deleteItem}>delete</FontIcon>}
        threeLines
      />
    )
  }
}

RecommendationListItem.propTypes = {
  item: PropTypes.object.isRequired
}
export default RecommendationListItem
