import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { showItems } from 'redux/modules/crud'
import { CircularProgress } from 'react-md'

class Loading extends Component {
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this)
  }

  goBack = () => {
    const { dispatch, pluralName } = this.props
    dispatch(showItems(pluralName))
  }

  render() {
    const { pluralName } = this.props
    const className = 'hf-' + pluralName
    return (
      <div className={className + ' md-grid md-grid--no-spacing'}>
        <div className="md-cell md-cell--10-desktop md-cell--1-desktop-offset md-cell--8-tablet md-cell--4-phone">
          <div className="hf-full-height hf-center">
            <CircularProgress
              key="progress"
              id={pluralName}
              scale={4}
              centered
            />
          </div>
        </div>
      </div>
    )
  }
}

Loading.propTypes = {
  dispatch: PropTypes.func.isRequired,
  pluralName: PropTypes.string.isRequired
}

export default connect()(Loading)
