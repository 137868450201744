import PropTypes from 'prop-types'
import React, { Component } from 'react'

import PrintHeader from 'components/Print/PrintHeader'
import HostIntro from 'components/Print/SubSections/HostIntro'
import ListingName from 'components/Print/SubSections/ListingName'
import SvgIcon from 'components/SvgIcon'
import { categoryIconSvg } from 'constants/UIConstants'
import { resizeImage } from 'utils/Image'
import { buildGuidebookUrl } from 'utils/Urls'

class CoverPage4 extends Component {
  render() {
    const color = this.props.printPreferences.color

    const icons = []
    for (var icon in categoryIconSvg) {
      if ({}.hasOwnProperty.call(categoryIconSvg, icon)) {
        icons.push(
          <td key={'icon_' + icon} className="hf-col-sm">
            <SvgIcon fillColor={color} size="50px" viewBox="0 0 200 200">
              {categoryIconSvg[icon]}
            </SvgIcon>
          </td>
        )
      }
    }

    //remove unwanted icons
    icons.splice(9, 1)

    const iconTable = (
      <table key="cp2-table" className="hf-icon-table">
        <tbody width="100%">
          <tr width="100%">{icons}</tr>
        </tbody>
      </table>
    )

    const listingImage = resizeImage(this.props.guidebook.image, 314, 215, true)

    return (
      <div id="coverpage4" className="page-break-after">
        <PrintHeader
          theme={this.props.theme}
          coverPageClass="print-header-cp2"
          alignChildren="center"
        >
          <ListingName
            guidebook={this.props.guidebook}
            styles={this.props.styles ? this.props.styles : null}
          />
        </PrintHeader>
        <svg id="cp4-svg" height="370" width="794">
          <polygon
            points="0 0 173 101 0 221 0 0"
            fill={this.props.printPreferences.color}
            fillOpacity="0.8"
          />
          <polygon
            points="231 366 231 61 558 117 746 214 558 310 231 366"
            fill={this.props.printPreferences.color}
            fillOpacity="0.9"
          />
          <polygon
            points="231 61 231 366 19 207 231 61"
            fill={this.props.printPreferences.color}
            fillOpacity="0.6"
          />
          <rect
            x="434"
            y="96"
            width="360"
            height="235"
            fill={this.props.printPreferences.color}
          />
          <image
            width="314"
            height="215"
            x="457"
            y="106"
            xlinkHref={listingImage}
          />
          Sorry, your browser does not support inline SVG.
        </svg>
        {iconTable}

        <div className="hf-gb-url">
          {buildGuidebookUrl(
            this.props.guidebook.key,
            null,
            null,
            false,
            this.props.guidebook.domain?.status === 'approved'
              ? this.props.guidebook.domain.domain
              : null,
            this.props.guidebook.is_published
          )}
        </div>

        <HostIntro
          guidebook={this.props.guidebook}
          styles={this.props.styles ? this.props.styles : null}
        />
      </div>
    )
  }
}

CoverPage4.propTypes = {
  guidebook: PropTypes.object,
  printPreferences: PropTypes.object,
  onReady: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  styles: PropTypes.object,
  theme: PropTypes.object,
  color: PropTypes.string.isRequired
}

export default CoverPage4
