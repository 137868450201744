import moment from 'moment'

const TOKEN_KEY = process.env.REACT_APP_AUTH_TOKEN_NAME
const USER_KEY = process.env.REACT_APP_USER_KEY

export function saveAuth(authData) {
  const authToken = {
    jwt: authData.jwt,
    expirationDate: moment().add(3, 'M').toJSON()
  }

  localStorage.setItem(USER_KEY, JSON.stringify(authData.user))
  localStorage.setItem(TOKEN_KEY, JSON.stringify(authToken))
}

export function getToken() {
  const authStorage = localStorage.getItem(TOKEN_KEY)

  if (authStorage) {
    const authData = JSON.parse(authStorage)
    return authData.jwt
  }

  return null
}

export function getUser() {
  const userData = localStorage.getItem(USER_KEY)

  if (userData) {
    return JSON.parse(userData)
  }

  return null
}

export function isTokenExpired() {
  const authStorage = localStorage.getItem(TOKEN_KEY)

  if (authStorage) {
    const authData = JSON.parse(authStorage)

    return moment(authData.expirationDate).isBefore(moment())
  }

  return true
}

export function clearAuth() {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(USER_KEY)
}
