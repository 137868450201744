import React from 'react'

const BeachTree = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`beach_tree`}</title>
    <path d="M16 3.006C13.656 3 12.007 4.157 10.906 4.938c1.347-.333 5.395-1.56 8.867 1.126l.286.252c.024.024.093.098.181.194.127-.09.254-.176.381-.276l1.022-.81-1.047-.778C19.01 3.47 17.434 3.01 16 3.006zm-5.094 1.932l-.023.033c-1.066-.14-2.59.006-4.069.826a7.119 7.119 0 00-.592.371c-.037.026-.076.051-.113.078-.158.115-.312.24-.466.371-.05.043-.1.085-.149.13a7.808 7.808 0 00-.408.405c-.057.061-.116.12-.172.184a8.278 8.278 0 00-.361.447c-.06.078-.122.153-.18.235-.112.159-.217.331-.324.505-.056.093-.114.18-.168.278-.108.19-.208.396-.307.605-.045.094-.093.182-.136.28a11.54 11.54 0 00-.385.998L2.61 12H4c.769 0 1.464-.085 2.107-.223 0-3.362 2.382-5.325 3.844-6.187C9.248 6.262 8 7.765 8 10c0 1.355.139 2.842 1.143 4.516l.658 1.097.906-.906c1.66-1.659 2.266-3.737 2.29-5.053C14.334 10.414 16.248 11 19 11h1.563l-.655-1.42c-1.867-4.289-8.006-4.891-9.002-4.643zm3.64 5.875s-.463 2.479-1.108 3.662C13.774 16.283 14 18.443 14 21h2c0-3.923-.502-6.978-1.158-9.324a6.177 6.177 0 00-.295-.864zM3 19a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm3 0a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm3 0a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm3 0a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm6 0a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm3 0a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1z" />
  </svg>
)

export default BeachTree
