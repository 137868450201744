import PickerOverlay from 'filestack-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import {
  Autocomplete,
  Avatar,
  Card,
  CardActions,
  CardText,
  CardTitle,
  Checkbox,
  CircularProgress,
  FontIcon,
  Media,
  Paper,
  SelectField,
  Switch,
  TextField,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'
import { actions, Errors, Form } from 'react-redux-form'
import { push } from 'react-router-redux'

import AddressForm from 'components/AddressForm'
import InputTextField from 'components/InputTextField'
import LocaleForm from 'components/LocaleForm'
import MultilangWysiwyg from 'components/MultilangWysiwyg'
import TooltipButton from 'components/TooltipButton'
import VoiceForm from 'components/VoiceForm'
import { hostUIMessages } from 'constants/UIConstants'
import { copyItem, fetchItems, saveItem, showItems } from 'redux/modules/crud'
import blankEditStates from 'redux/modules/crud/blankEditStates'
import { convertAndStoreImage, fixRotation } from 'redux/modules/filestack'
import currentUser from 'utils/CurrentUser'
import { sortData } from 'utils/Data'
import { preloadImages } from 'utils/Image'
import { requiredIf } from 'utils/ValidationHelpers'

import FormCardActions from './FormCardActions'
import ManageSelectedGuidebooks from './ManageSelectedCards'
import ManageGuidebooksAndTemplates from './ManageSelectedCards/ManageGuidebooksAndTemplates'

import EmptyState from '../EmptyState'

class RecommendationForm extends Component {
  constructor(props) {
    super(props)
    this.imageUploaded = this.imageUploaded.bind(this)
    this.sponsoredChange = this.sponsoredChange.bind(this)
    this.ignoreHoursChange = this.ignoreHoursChange.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    this.handleDestinationChange = this.handleDestinationChange.bind(this)
    this.goBack = this.goBack.bind(this)
    this.copyCard = this.copyCard.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
    this.saveAndAddAnother = this.saveAndAddAnother.bind(this)
    this.state = {
      photos: [],
      photoIndexSelected: null,
      photoLoading: false,
      showAddressForm: true,
      addressValue: {}
    }
  }

  formatMessage = this.props.intl.formatMessage
  // images sizes for preloading
  imageSizes = [
    [748, 561],
    [480, 360],
    [348, 261],
    [320, 240],
    [132, 99],
    [100, 100],
    [60, 60],
    [50, 50],
    [30, 30]
  ]

  async componentDidMount() {
    this.mounted = true
    const { dispatch, itemId, guidebookId } = this.props
    if (itemId === 'create') {
      let newItem = blankEditStates.recommendation.data
      if (guidebookId && guidebookId !== 'create') {
        // if creating from a guidebook, select the guidebook by default
        newItem = Object.assign({}, newItem, {
          guidebooks: [{ id: guidebookId }]
        })
      }
      // also if we're making a new one and our "owner" has locales, default to those
      if (this.props.owner_data && this.props.owner_data.locales.length > 0) {
        newItem = Object.assign({}, newItem, {
          locales: this.props.owner_data.locales
        })
      }
      dispatch(actions.load('edit_recommendation', newItem))
    }
    dispatch(fetchItems('destinations'))
    dispatch(
      fetchItems('categories', (response) => {
        // find id of 'other category'
        // only set this if we're making a new rec
        if (itemId === 'create') {
          if (response && response.length) {
            for (var item in response) {
              if (response[item].name === 'other') {
                const otherCat = { id: response[item].id }
                dispatch(
                  actions.load('edit_recommendation.category.id', otherCat.id)
                )
              }
            }
          }
        }
      })
    )
  }

  componentWillReceiveProps(nextProps) {
    const rec = this.props.edit_recommendation
    const nextRec = nextProps.edit_recommendation
    // if the rec has loaded, and the address is null
    if (typeof rec.id === 'undefined' && nextRec.id) {
      if (nextRec.address === null) {
        this.setState({ showAddressForm: false })
      }
    }
  }

  goBack = () => {
    const { dispatch, pluralName, guidebookId, ownerPluralName } = this.props
    if (guidebookId) {
      const path = '#recommendations#recommendation'
      dispatch(push(`/host/${ownerPluralName}/${guidebookId}${path}`))
    } else if (window.isIframe) {
      window.history.back()
    } else {
      dispatch(showItems(pluralName))
    }
  }

  copyCard = (id) => {
    const { dispatch, pluralName } = this.props
    dispatch(copyItem(pluralName, id))
  }

  handleEnter = (e) => {
    const { dispatch } = this.props
    e.preventDefault()
    dispatch(actions.submit('edit_recommendation'))
  }

  imageUploaded(uploadResult) {
    const { dispatch } = this.props
    if (uploadResult.filesUploaded && uploadResult.filesUploaded.length > 0) {
      const file = uploadResult.filesUploaded[0]
      const filestackUrl = file.url
      fixRotation(filestackUrl).then((data) => {
        if (data.url && data.url.length > 0) {
          preloadImages(data.url, this.imageSizes)
          dispatch(actions.change('edit_recommendation.image', data.url))
          this.setState({ photoIndexSelected: null })
        }
      })
    }
    //"{"filesUploaded":[{"filename":"badge-enterprise.gif","mimetype":"image/gif","size":13684,"source":"local_file_system","url":"https://cdn.filestackcontent.com/yzpngoNWTiaTOzGyeGVa","handle":"yzpngoNWTiaTOzGyeGVa","status":"Stored"}],"filesFailed":[]}
  }

  sponsoredChange(checked, e) {
    const { dispatch } = this.props
    dispatch(actions.change('edit_recommendation.sponsored', checked))
  }

  ignoreHoursChange(checked, e) {
    const { dispatch } = this.props
    dispatch(actions.change('edit_recommendation.ignore_hours', checked))
  }

  handleCategoryChange(value) {
    const { dispatch } = this.props
    dispatch(actions.change('edit_recommendation.category.id', value))
  }

  handleDestinationChange(value) {
    const { dispatch } = this.props
    dispatch(actions.change('edit_recommendation.destination.id', value))
  }

  handleChangeHasAddress = (value) => {
    const { dispatch, edit_recommendation } = this.props
    // if turning the form off, remove address from the recommendation object
    // but save it, in case they turn it right back on
    var stateAddress, editAddress
    if (value === false) {
      editAddress = null
      stateAddress = edit_recommendation.address
    } else {
      editAddress = this.state.addressValue
      stateAddress = this.state.addressValue
    }
    this.setState(
      { showAddressForm: value, addressValue: stateAddress },
      () => {
        dispatch(actions.change('edit_recommendation.address', editAddress))
      }
    )
  }

  handleSubmit(item) {
    const { itemId, dispatch, guidebookId, copy } = this.props
    const id = copy ? null : itemId
    const addAnother = this.addAnother
    this.addAnother = false
    const currentGuidebook = guidebookId ? this.props.owner_data : null
    dispatch(
      saveItem(
        'recommendations',
        'recommendation',
        id,
        item,
        addAnother,
        currentGuidebook
      )
    )
  }

  formHasErrors = (formErrors) => {
    const edit_form = this.props.edit_form
    for (var field in formErrors) {
      if (field.substring(0, 7) === 'address') {
        var subField = field.split('.').pop()
        if (edit_form.address[subField] && !edit_form.address[subField].valid) {
          return false
        }
      } else {
        if (edit_form[field] && !edit_form[field].valid) {
          return false
        }
      }
    }
    return true
  }

  saveAndAddAnother = (e) => {
    const { dispatch } = this.props
    this.addAnother = true
    dispatch(actions.submit('edit_recommendation'))
  }

  onPlaceImagesUpdated = (photos) => {
    const placeImages = photos || []
    // if we don't have an image at all yet, use the first image right away
    if (!this.props.image) {
      this.updateSelectedImage(
        photos[0].getUrl({ maxWidth: 720, maxHeight: 540 })
      )
    }
    this.setState({ photos: placeImages })
  }

  onPlaceTypeUpdated = (types) => {
    const { dispatch } = this.props
    if (types && types.length && types[0].trim() !== '') {
      const catId = this.mapTypeToCategory(types[0])
      if (catId) {
        dispatch(actions.change('edit_recommendation.category.id', catId))
      }
    }
  }

  mapTypeToCategory = (type) => {
    const catIds = this.getCategoryIdsByName()
    const mapping = {
      beauty_salon: 'activities',
      bowling_alley: 'activities',
      campground: 'activities',
      casino: 'activities',
      gym: 'activities',
      movie_rental: 'activities',
      movie_theater: 'activities',
      night_club: 'bars',
      spa: 'activities',
      amusement_park: 'attractions',
      aquarium: 'attractions',
      art_gallery: 'attractions',
      cemetery: 'attractions',
      church: 'attractions',
      city_hall: 'attractions',
      courthouse: 'attractions',
      embassy: 'attractions',
      hindu_temple: 'attractions',
      library: 'attractions',
      mosque: 'attractions',
      museum: 'attractions',
      park: 'attractions',
      stadium: 'attractions',
      synagogue: 'attractions',
      university: 'attractions',
      zoo: 'attractions',
      point_of_interest: 'attractions',
      bakery: 'places to eat',
      bar: 'bars',
      cafe: 'places to eat',
      convenience_store: 'places to eat',
      grocery_or_supermarket: 'places to eat',
      liquor_store: 'places to eat',
      meal_delivery: 'places to eat',
      meal_takeaway: 'places to eat',
      restaurant: 'places to eat',
      food: 'places to eat',
      bicycle_store: 'shopping',
      book_store: 'shopping',
      car_dealer: 'shopping',
      clothing_store: 'shopping',
      department_store: 'shopping',
      electronics_store: 'shopping',
      florist: 'shopping',
      furniture_store: 'shopping',
      hair_care: 'shopping',
      hardware_store: 'shopping',
      home_goods_store: 'shopping',
      jewelry_store: 'shopping',
      pet_store: 'shopping',
      pharmacy: 'shopping',
      post_office: 'shopping',
      shoe_store: 'shopping',
      shopping_mall: 'shopping',
      store: 'shopping',
      accounting: 'other',
      airport: 'other',
      atm: 'other',
      bank: 'other',
      bus_station: 'other',
      car_rental: 'other',
      car_repair: 'other',
      car_wash: 'other',
      dentist: 'other',
      doctor: 'other',
      electrician: 'other',
      fire_station: 'other',
      funeral_home: 'other',
      gas_station: 'other',
      hospital: 'other',
      insurance_agency: 'other',
      laundry: 'other',
      lawyer: 'other',
      local_government_office: 'other',
      locksmith: 'other',
      lodging: 'other',
      moving_company: 'other',
      painter: 'other',
      parking: 'other',
      physiotherapist: 'other',
      plumber: 'other',
      police: 'other',
      real_estate_agency: 'other',
      roofing_contractor: 'other',
      rv_park: 'other',
      school: 'other',
      storage: 'other',
      subway_station: 'other',
      taxi_stand: 'other',
      train_station: 'other',
      transit_station: 'other',
      travel_agency: 'other',
      veterinary_care: 'other',
      route: 'other',
      establishment: 'other',
      administrative_area_level_3: 'other',
      street_address: 'other',
      political: 'other'
    }
    const catFound = mapping.hasOwnProperty(type) ? mapping[type] : 'other'
    return catIds[catFound]
  }

  getCategoryIdsByName = () => {
    let data = {}

    for (var idx in this.props.categories.data) {
      if ({}.hasOwnProperty.call(this.props.categories.data, idx)) {
        data[this.props.categories.data[idx].name.toLowerCase()] =
          this.props.categories.data[idx].id
      }
    }
    return data
  }

  updateSelectedImage = (photoUrl, index) => {
    const { dispatch } = this.props
    this.setState({ photoLoading: true })
    convertAndStoreImage(photoUrl).then((response) => {
      const new_rec_image = response.image_url ? response.image_url : photoUrl

      dispatch(actions.change('edit_recommendation.image', new_rec_image))
      preloadImages(new_rec_image, this.imageSizes)
      if (typeof index !== 'undefined') {
        this.setState({ photoIndexSelected: index })
      } else {
        this.setState({ photoIndexSelected: null })
      }
      this.setState({ photoLoading: false })
    })
  }

  renderGoogleImageSelector = (photo, index, preview) => {
    const { image } = this.props
    let key = preview ? 'uploaded-image' + index : 'google-photo-' + index
    let photoUrl = !preview
      ? photo.getUrl({ maxWidth: 720, maxHeight: 540 })
      : image
    let imgClass = 'md-cell md-cell--3 md-cell--2-tablet hf-images-grid'
    imgClass +=
      image === photoUrl || this.state.photoIndexSelected === index
        ? ' selected'
        : ''
    const pointerStyle = { cursor: 'pointer' }
    return (
      <div
        key={key}
        className={imgClass}
        onClick={() => this.updateSelectedImage(photoUrl, index)}
        style={pointerStyle}
      >
        <Media aspectRatio="4-3">
          <img src={photoUrl} alt="" />
        </Media>
      </div>
    )
  }

  renderGoogleImages = () => {
    const { image } = this.props
    let photoSelectors = []
    if (this.state.photos && this.state.photos.length) {
      photoSelectors = this.state.photos.map((photo, index) => {
        return this.renderGoogleImageSelector(photo, index)
      })
    }
    if (image && this.state.photoIndexSelected === null) {
      const selectedImage =
        this.state.photos && !this.containsImage(this.state.photos, image)
          ? this.renderGoogleImageSelector(image, null, 'preview')
          : null
      if (selectedImage) {
        photoSelectors.push(selectedImage)
      }
    }
    if (photoSelectors && photoSelectors.length) {
      const showLoader = this.state.photoLoading
        ? { display: 'block' }
        : { display: 'none' }
      return (
        <div className="md-grid md-grid--no-spacing hf-place-photos">
          {photoSelectors}
          <div className="hf-loading-overlay" style={showLoader}>
            <CircularProgress id="loader" />
          </div>
        </div>
      )
    }

    return <EmptyState type="images" />
  }

  containsImage = (a, obj) => {
    for (var i = 0; i < a.length; i++) {
      let googlePhotoUrl = a[i].getUrl({ maxWidth: 720, maxHeight: 540 })
      if (googlePhotoUrl === obj) {
        return true
      }
    }
    return false
  }

  componentWillUnmount() {
    this.mounted = false
    //clear redux state for next item
    this.props.dispatch(actions.reset('edit_recommendation'))
  }

  render() {
    const self = this
    const {
      address,
      categories,
      copy,
      destinations,
      edit_recommendation,
      guidebookId,
      ignore_hours,
      image,
      itemId,
      ownerPluralName,
      sponsored
    } = this.props
    const locales = edit_recommendation.locales
    const user = currentUser()

    let recName = this.props.name
    if (address && address.street && address.locality) {
      recName = `${this.props.name} - ${address.street}, ${address.locality}`
    } else if (address && address.locality) {
      recName = `${this.props.name} - ${address.locality}`
    }
    const cardName = this.props.name ? (
      <TextField id="disabled-name-field" placeholder={recName} disabled />
    ) : (
      <label>Once you enter a title below, your card will be named</label>
    )
    const title = copy
      ? 'Copy recommendation card'
      : itemId === 'create'
        ? 'New recommendation card'
        : 'Edit recommendation card'
    const nav = (
      <TooltipButton
        key="nav"
        onClick={self.goBack}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>keyboard_backspace</FontIcon>
      </TooltipButton>
    )

    const actions = []
    const formErrors = []
    const errorMessages = {
      formwide: {
        valid: 'Oops! Unable to save:'
      },
      name: {
        required: 'Please provide a title'
      },
      why_recommended: {
        required: 'Please provide some information on why you recommend this'
      },
      website: {
        valid:
          'Please enter a valid website address. It must begin with http or https'
      },
      content: {
        required:
          'Please provide some more information about this recommendation'
      },
      street: {
        required: 'Please provide a street address'
      },
      locality: {
        required: 'Please provide a city / suburb'
      },
      country_code: {
        required: 'Please provide a country'
      },
      lat: {
        required: 'Please provide a map location'
      },
      category_id: {
        required: 'Please select a Category'
      }
    }
    formErrors['formwide'] = (
      <Errors
        model="edit_recommendation"
        messages={errorMessages.formwide}
        wrapper="h2"
        className="md-text-field-message title hf-error"
        show={(form) => form.submitFailed}
      />
    )
    formErrors['name'] = (
      <Errors
        model=".name"
        messages={errorMessages.name}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['website'] = (
      <Errors
        model=".website"
        messages={errorMessages.website}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['why_recommended'] = (
      <Errors
        model=".why_recommended"
        messages={errorMessages.why_recommended}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['content'] = (
      <Errors
        model=".content"
        messages={errorMessages.content}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['address.street'] = (
      <Errors
        model=".address.street"
        messages={errorMessages.street}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['address.locality'] = (
      <Errors
        model=".address.locality"
        messages={errorMessages.locality}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['address.country_code'] = (
      <Errors
        model=".address.country_code"
        messages={errorMessages.country_code}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['address.lat'] = (
      <Errors
        model=".address.lat"
        messages={errorMessages.lat}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['category.id'] = (
      <Errors
        model=".category.id"
        messages={errorMessages.category_id}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )

    if (edit_recommendation.id && user.canCopy && !copy && !guidebookId) {
      actions.push(
        <TooltipButton
          key="copy"
          onClick={() => {
            self.copyCard(edit_recommendation.id)
          }}
          tooltipLabel="Copy"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>content_copy</FontIcon>
        </TooltipButton>
      )
    }
    const filestackKey = process.env.REACT_APP_FILESTACK_KEY
    const filestackOptions = {
      accept: 'image/*',
      maxFiles: 1,
      fromSources: ['local_file_system', 'url', 'imagesearch'],
      storeTo: {
        location: 'gcs'
      },
      imageMax: [1600, 1200],
      transformations: {
        crop: {
          aspectRatio: 4 / 3,
          force: true
        },
        rotate: true
      }
    }

    const rec_address = address || {}
    const validators = {
      '': {
        // Form-level validator
        valid: () => this.formHasErrors(formErrors)
      },
      name: {
        required: (val) => val && val.length
      },
      why_recommended: {
        required: (val) => val && val.length
      },
      website: {
        valid: (val) => {
          if (val) {
            if (
              val.substring(0, 7) !== 'http://' &&
              val.substring(0, 8) !== 'https://'
            ) {
              return false
            }
          }
          return true
        }
      },
      'address.locality': {
        required: (val) =>
          requiredIf(this.state.showAddressForm, val && val.length)
      },
      'address.country_code': {
        required: (val) =>
          requiredIf(this.state.showAddressForm, val && val.length)
      },
      'address.lat': {
        required: (val) =>
          requiredIf(this.state.showAddressForm, val && val !== '')
      }, // BA only need to validate one of these, validiting 2 will show the same error twice
      'category.id': {
        required: (val) => !!val
      }
    }

    const sortedCategories = sortData(categories.data, 'order')
      .filter((cat) => {
        return cat.type === 'recommendation'
      })
      .map((cat) => {
        const catName =
          cat.name.length > 0
            ? cat.name.charAt(0).toUpperCase() + cat.name.slice(1)
            : cat.name
        return { id: cat.id, name: catName }
      })
    const selectedCategory = edit_recommendation.category
      ? edit_recommendation.category.id
      : ''

    let categoryUpgrade = null
    const accountPage = process.env.REACT_APP_WORDPRESS_URL + '/account'
    if (!user.canCreateCategory) {
      categoryUpgrade = (
        <p>
          Paid subscribers can create custom categories. Click the round icon in
          the upper right corner and select{' '}
          <a href={accountPage}>Manage Account</a> to see upgrade options.
        </p>
      )
    }
    const sortedDestinations = sortData(destinations.data, 'name').map(
      (dest) => {
        return { id: dest.id, name: dest.name }
      }
    )
    const selectedDestination = edit_recommendation.destination
      ? edit_recommendation.destination.name
      : ''

    const titlePrompt = this.state.showAddressForm
      ? 'Review the suggested title, do you want to change it?'
      : 'Recommendation Title'
    const imagePrompt = this.state.showAddressForm
      ? 'Review the images, select or upload an alternative'
      : 'Recommendation Image'
    const urlPrompt = this.state.showAddressForm
      ? 'Review the url, do you want to change it?'
      : 'Website'
    const phonePrompt = this.state.showAddressForm
      ? 'Review the phone number, do you want to change it?'
      : 'Phone Number'

    const sponsoredCheckbox = user.isAdmin ? (
      <div className="md-cell md-cell--12">
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
            <FontIcon className="md-inline-block md-btn md-btn--icon hf-float-right">
              loyalty
            </FontIcon>
          </div>
          <Checkbox
            name="sponsored"
            id="sponsored_checkbox"
            label="Sponsored"
            labelBefore
            onChange={self.sponsoredChange}
            checked={sponsored}
            className="md-cell md-cell--9 md-cell--5-tablet"
          />
          <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--phone-hidden" />
        </div>
      </div>
    ) : null

    const sponsoredStyle = { marginBottom: '24px' }
    const sponsoredData =
      user.isAdmin && edit_recommendation.sponsored ? (
        <div className="md-cell md-cell--12" style={sponsoredStyle}>
          <div className="md-grid md-grid--no-spacing">
            <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet" />
            <div className="md-cell md-cell--11 md-cell--7-tablet">
              <p className="md-title">Sponsor Call To Action</p>
              <p>This is what the button will say on the recommendation</p>
              <MultilangWysiwyg
                simpleEditor
                model="edit_recommendation"
                field="sponsor_cta"
                txn_field="sponsor_cta_txn"
                locales={locales}
              />
            </div>
            <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet" />
            <div className="md-cell md-cell--5 md-cell--3-tablet md-cell--4-phone">
              <InputTextField
                model=".sponsor_source"
                id="sponsor_source"
                label="Sponsor Source"
              />
            </div>

            <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet" />
            <div className="md-cell md-cell--5 md-cell--3-tablet md-cell--4-phone">
              <InputTextField
                model=".sponsor_ref"
                id="sponsor_ref"
                label="Sponsor Reference"
              />
            </div>

            <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
              <FontIcon className="md-inline-block md-btn md-btn--icon hf-float-right">
                room
              </FontIcon>
            </div>
            <Autocomplete
              id="selectDestination"
              className="md-cell md-cell--9 md-cell--5-tablet"
              label="Destination"
              data={sortedDestinations}
              dataLabel="name"
              dataValue="id"
              onAutocomplete={this.handleDestinationChange}
              defaultValue={selectedDestination}
            />
            <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--phone-hidden" />
          </div>
        </div>
      ) : null

    return (
      <DocumentTitle title="Host Recommendation">
        <div className="hf-recommendations-paper">
          <Toolbar
            colored
            className="hf-edit-toolbar hf-recommendations"
            title={title}
            nav={nav}
            actions={actions}
          />

          <Paper key="category" className="hf-form-wrapper">
            <Form
              model="edit_recommendation"
              onSubmit={(v) => this.handleSubmit(v)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  return this.handleEnter(e)
                }
              }}
              validators={validators}
            >
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">Card name</h2>
                  {cardName}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {this.formatMessage(hostUIMessages['languageHeading'])}
                  </h2>
                  <p className="md-body-1">
                    {this.formatMessage(hostUIMessages['languageSubHeading'])}
                  </p>
                  <LocaleForm editModel="edit_recommendation" />
                </div>
                <div className="md-cell md-cell--12">
                  <h2>Does this recommendation have a specific address?</h2>
                  <div className="md-grid md-grid--no-spacing">
                    <div className="md-cell md-cell--1 md-cell--1-phone">
                      <p className="md-text md-text-right hf-switch-left">No</p>
                    </div>
                    <div className="md-cell md-cell--11 md-cell--7-tablet md-cell--3-phone">
                      <Switch
                        id="rec_has_address"
                        name="rec_has_address"
                        checked={this.state.showAddressForm}
                        onChange={this.handleChangeHasAddress}
                        label="Yes"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {this.state.showAddressForm ? (
                <AddressForm
                  editModel="edit_recommendation"
                  address={rec_address}
                  image={image}
                  onPlaceImagesUpdated={this.onPlaceImagesUpdated}
                  onPlaceTypeUpdated={this.onPlaceTypeUpdated}
                />
              ) : null}
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">{titlePrompt}</h2>
                  <p className="md-body-1">
                    Guests will see this heading at the top of the card *
                  </p>
                  <MultilangWysiwyg
                    simpleEditor
                    model="edit_recommendation"
                    field="name"
                    txn_field="name_txn"
                    locales={locales}
                    label="Card title *"
                  />
                  {formErrors['name']}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">{imagePrompt}</h2>
                  <Card className="hf-wider-selection-control-container">
                    <div className=" hf-editable-grid-lists">
                      {this.renderGoogleImages()}
                    </div>
                    <CardActions>
                      <PickerOverlay
                        apikey={filestackKey}
                        componentDisplayMode={{
                          type: 'button',
                          customText: 'Select Image',
                          customClass:
                            'md-inline-block md-btn md-btn--raised md-background--primary md-background--primary-hover md-pointer--hover md-btn--text md-btn--raised-pressed'
                        }}
                        actionOptions={filestackOptions}
                        onSuccess={this.imageUploaded}
                      />
                    </CardActions>
                  </Card>
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    Tell guests why you recommend it
                  </h2>
                  <p className="md-body-1">
                    Guests will see this quoted on the card *
                  </p>
                  <p className="hf-notice">
                    Note: If you are picky about the cards on the page lining up
                    exactly, you will want to normalize the amount of text you
                    use in this field
                  </p>
                  <MultilangWysiwyg
                    simpleEditor
                    id="why_recommended"
                    model="edit_recommendation"
                    field="why_recommended"
                    txn_field="why_recommended_txn"
                    locales={locales}
                  />
                  {formErrors['why_recommended']}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">{urlPrompt}</h2>
                  <p className="md-body-1">
                    Guests will see this when they open the card
                  </p>
                  <InputTextField
                    model=".website"
                    id="website"
                    label="website"
                  />
                  {formErrors['website']}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">{phonePrompt}</h2>
                  <p className="md-body-1">
                    Guests will see this when they open the card
                  </p>
                  <InputTextField
                    model=".phone_number"
                    id="phone_number"
                    label="phone number"
                  />
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    What else would you like to tell your guests?
                  </h2>
                  <p className="md-body-1">
                    Guests will see this when they open the card
                  </p>
                  <MultilangWysiwyg
                    model="edit_recommendation"
                    field="content"
                    txn_field="content_txn"
                    locales={locales}
                  />
                  {formErrors['content']}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    Are there any settings you need to change?
                  </h2>
                  <Card className="hf-wider-selection-control-container">
                    <CardTitle
                      avatar={
                        <Avatar
                          icon={<FontIcon>settings</FontIcon>}
                          alt=""
                          suffix="hfpurple"
                        />
                      }
                      title="Apply settings"
                    />
                    <CardText>
                      <div
                        className="md-grid md-grid--no-spacing"
                        id="sponsored"
                      >
                        {sponsoredCheckbox}
                        {sponsoredData}
                      </div>

                      <div
                        className="md-grid md-grid--no-spacing"
                        id="category"
                      >
                        <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle" />
                        <div className="md-cell md-cell--9 md-cell--5-tablet">
                          <p className="md-font-bold">
                            Selecting a category will determine which
                            recommendation tab this card will appear on
                          </p>
                          {categoryUpgrade}
                        </div>
                        <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--phone-hidden" />

                        <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
                          <FontIcon className="md-inline-block md-btn md-btn--icon hf-float-right">
                            tab
                          </FontIcon>
                        </div>
                        <SelectField
                          id="selectDefaultPath"
                          className="md-cell md-cell--9 md-cell--5-tablet"
                          label="Category *"
                          menuItems={sortedCategories}
                          itemLabel="name"
                          itemValue="id"
                          onChange={this.handleCategoryChange}
                          value={selectedCategory}
                        />
                        {formErrors['category.id']}
                        <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--phone-hidden" />
                      </div>
                      <div
                        className="md-grid md-grid--no-spacing"
                        id="ignore_hours_op"
                      >
                        <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
                          <FontIcon className="md-inline-block md-btn md-btn--icon hf-float-right">
                            access_time
                          </FontIcon>
                        </div>
                        <Checkbox
                          name="ignore_hours"
                          id="ignore_hours"
                          label="Ignore Hours of Operation from Google"
                          labelBefore
                          onChange={self.ignoreHoursChange}
                          checked={ignore_hours}
                          className="md-cell md-cell--9 md-cell--5-tablet"
                        />
                      </div>
                    </CardText>
                  </Card>
                </div>
              </div>
              <VoiceForm editModel="edit_recommendation" locales={locales} />
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  {user.isEnterprise ? (
                    <ManageGuidebooksAndTemplates
                      ownerPluralName="recommendations"
                      ownerSingularName="recommendation"
                      guidebookId={guidebookId}
                      cardType="Recommendation"
                      activeTab={ownerPluralName}
                      active={true}
                    />
                  ) : (
                    <ManageSelectedGuidebooks
                      ownerPluralName="recommendations"
                      ownerSingularName="recommendation"
                      guidebookId={guidebookId}
                      cardType="Recommendation"
                      active={true}
                    />
                  )}
                </div>
              </div>
              <div className="md-grid hf-headline-margin">
                <div className="md-cell md-cell--12">
                  <div>{formErrors['formwide']}</div>
                  <div>{formErrors['name']}</div>
                  <div>{formErrors['why_recommended']}</div>
                  <div>{formErrors['content']}</div>
                  <div>{formErrors['address.lat']}</div>
                  <div>{formErrors['address.street']}</div>
                  <div>{formErrors['address.locality']}</div>
                  <div>{formErrors['address.country_code']}</div>
                  <div>{formErrors['category']}</div>
                </div>
              </div>
              <FormCardActions saveAndAdd={this.saveAndAddAnother} />
            </Form>
          </Paper>
        </div>
      </DocumentTitle>
    )
  }
}

RecommendationForm.propTypes = {
  ownerSingularName: PropTypes.string,
  ownerPluralName: PropTypes.string
}

function mapStateToProps(state, props) {
  const { ownerSingularName } = props
  const edit_recommendation = state.edit_recommendation
  const owner_data = state[`edit_${ownerSingularName}`]
  const locales = edit_recommendation.locales
  const address = edit_recommendation.address
  const image = edit_recommendation.image
  const name = edit_recommendation.name
  const edit_form = state.forms.edit_recommendation

  const listData = state.list
  return {
    edit_recommendation,
    owner_data,
    locales,
    address,
    image,
    name,
    categories: listData.categories,
    destinations: listData.destinations,
    sponsored: edit_recommendation.sponsored,
    ignore_hours: edit_recommendation.ignore_hours,
    edit_form
  }
}

export default connect(mapStateToProps)(RecommendationForm)
