import React from 'react'
import PropTypes from 'prop-types'
import GoogleApiWrapper from '../Map/GoogleApiWrapper'
import Autocomplete from 'react-md/lib/Autocompletes'
import { debounce } from 'utils/Debounce'
import { consolidatePlaceResults } from 'constants/GeoConstants'

class GooglePlaces extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      locations: [],
      location: ''
    }
    this._getSuggestions = this._getSuggestions.bind(this)
    this._selectItem = this._selectItem.bind(this)
    this._selectedItem = null
    this._throttledSearch = debounce(this._getSuggestions, 250)
  }

  componentWillReceiveProps(nextProps) {
    //console.log('places props')
    if (nextProps.google && nextProps.google !== this.props.google) {
      const { google } = nextProps
      this.service = new google.maps.places.AutocompleteService()
      this.detailService = new google.maps.places.PlacesService(
        document.createElement('div')
      )
      this.geocoder = new google.maps.Geocoder()
    }
  }

  _fetchPlaceDetails(place_id, result) {
    if (typeof place_id !== 'undefined' && place_id.length > 0) {
      const place_request = { placeId: place_id }
      const google = this.props.google
      this.detailService.getDetails(place_request, (detailsResult, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          // fix postal code - some results returning suffix instead
          // this may have been fixed by adding $ to the regex above, but leaving it in anyway
          if (
            typeof detailsResult.address_components !== 'undefined' &&
            detailsResult.address_components.length > 0
          ) {
            for (var i = 0; i < detailsResult.address_components.length; i++) {
              if (
                detailsResult.address_components[i].types[0] === 'postal_code'
              ) {
                result.postal_code =
                  detailsResult.address_components[i].long_name
              }
            }
          }
          if (
            typeof detailsResult.name !== 'undefined' &&
            detailsResult.name.length > 0
          ) {
            result.name = detailsResult.name
          }
          if (
            typeof detailsResult.photos !== 'undefined' &&
            detailsResult.photos.length > 0
          ) {
            result.photos = detailsResult.photos
          }
          if (
            typeof detailsResult.types !== 'undefined' &&
            detailsResult.types.length > 0
          ) {
            result.types = detailsResult.types
          }
          if (
            typeof detailsResult.website !== 'undefined' &&
            detailsResult.website.length > 0
          ) {
            result.website = detailsResult.website
          }
          if (
            typeof detailsResult.international_phone_number !== 'undefined' &&
            detailsResult.international_phone_number.length > 0
          ) {
            result.phone_number = detailsResult.international_phone_number
          } else if (
            typeof detailsResult.formatted_phone_number !== 'undefined' &&
            detailsResult.formatted_phone_number.length > 0
          ) {
            result.phone_number = detailsResult.formatted_phone_number
          }
          if (
            typeof detailsResult.opening_hours !== 'undefined' &&
            detailsResult.opening_hours.weekday_text !== 'undefined'
          ) {
            result.hours = detailsResult.opening_hours.weekday_text
          }
          if (
            typeof detailsResult.url !== 'undefined' &&
            detailsResult.url.length > 0
          ) {
            result.google_place_url = detailsResult.url
          }
          // console.log(detailsResult);
        }
        this.setState({ locations: [] })
        this.props.onSuggestSelect(result)
      })
    }
  }

  _selectItem(suggest, suggestionIndex, matches) {
    const { google } = this.props
    const match = matches[suggestionIndex]
    this.geocoder.geocode(
      match.id ? { placeId: match.id } : { address: suggest },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const result = consolidatePlaceResults(results)
          this._fetchPlaceDetails(result.place_id, result)
        }
      }
    )
  }

  _getSuggestions(value) {
    const options = {
      input: value
    }
    if (value) {
      this.service.getPlacePredictions(options, (locations = []) => {
        this.setState({ locations })
      })
    }
  }

  _updateValue(value) {
    this.setState({
      value: value
    })
  }

  render() {
    const { locations } = this.state
    const options = locations
      ? locations.map(function (loc) {
          return {
            id: loc.place_id,
            name: loc.description
          }
        })
      : []

    const inputStyle = { fontSize: '20px' }
    return (
      <Autocomplete
        id="place-lookup"
        type="search"
        placeholder={this.props.prompt}
        data={options}
        dataLabel="name"
        dataValue="id"
        filter={null}
        onChange={this._throttledSearch}
        onAutocomplete={this._selectItem}
        focusInputOnAutocomplete
        inputStyle={inputStyle}
      />
    )
  }
}

GooglePlaces.propTypes = {
  prompt: PropTypes.string
}
GooglePlaces.defaultProps = {
  prompt: 'Search for a location'
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY_HOST
})(GooglePlaces)
