import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { fetchItems, fetchItemsNoCache } from 'redux/modules/crud'
import { verifyWebhooks } from 'redux/modules/orbirental/connect'
import PropertyList from './PropertyList'
import {
  Button,
  Card,
  CardTitle,
  CardText,
  DialogContainer,
  FontIcon,
  LinearProgress,
  Toolbar
} from 'react-md'
import TooltipButton from 'components/TooltipButton'
import currentUser from 'utils/CurrentUser'

class OrbirentalContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      helpVisible: false
    }
    this.showHelp = this.showHelp.bind(this)
    this.closeHelp = this.closeHelp.bind(this)
    this.upgrade = this.upgrade.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchItems('guidebooks'))
    dispatch(fetchItems('orbirentalproperties'))
  }

  upgrade = () => {
    const user = currentUser()
    let slug = '/upgrade-enterprise'
    if (user.guidebookLimit < 5) {
      slug = '/upgrade-prime-annual'
    } else if (user.guidebookLimit < 10) {
      slug = '/upgrade-prime-plus-annual'
    }
    window.location.href = process.env.REACT_APP_WORDPRESS_URL + slug
  }

  showHelp = () => {
    this.setState({ helpVisible: true })
  }

  closeHelp = () => {
    this.setState({ helpVisible: false })
  }

  verifyWebhooks = () => {
    verifyWebhooks()
  }

  reloadList = () => {
    const { dispatch } = this.props
    dispatch(fetchItemsNoCache('orbirentalproperties'))
  }

  getNumPublishedGuidebooks = () => {
    let publishedGuidebooks = 0
    const { guidebooks } = this.props
    if (guidebooks.loaded) {
      for (var i = 0, len = guidebooks.data.length; i < len; i++) {
        if (guidebooks.data[i].is_published) {
          publishedGuidebooks += 1
        }
      }
    }
    return publishedGuidebooks
  }

  render() {
    const { guidebooks, properties } = this.props

    const user = currentUser()

    const actions = []
    // add upgrade button
    actions.push(
      <TooltipButton
        key="reload"
        onClick={this.reloadList}
        tooltipLabel="Reload List"
        tooltipPosition="bottom"
        flat
      >
        <FontIcon>refresh</FontIcon>
      </TooltipButton>
    )
    if (user.switchedTo) {
      actions.push(
        <TooltipButton
          key="webhooks"
          onClick={this.verifyWebhooks}
          tooltipLabel="Verify Webhooks"
          tooltipPosition="bottom"
          flat
        >
          <FontIcon>sync_alt</FontIcon>
        </TooltipButton>
      )
    }
    actions.push(
      <TooltipButton
        key="help"
        onClick={this.showHelp}
        tooltipLabel="Help"
        tooltipPosition="bottom"
        flat
      >
        <FontIcon>help</FontIcon>
      </TooltipButton>
    )
    const currentLimit = user.guidebookLimit
    const publishedGuidebooks = this.getNumPublishedGuidebooks()
    const remainingLimit = currentLimit - publishedGuidebooks
    if (remainingLimit <= 0) {
      actions.push(
        <TooltipButton
          key="upgrade"
          onClick={this.upgrade}
          tooltipLabel="Upgrade"
          tooltipPosition="bottom"
          className="hf-bs-upgrade"
          flat
        >
          <p>Upgrade</p>
        </TooltipButton>
      )
    }

    const loaderOrList = !properties.loaded ? (
      <div className={'md-grid md-grid--no-spacing hf-host-paper'}>
        <div className="md-cell md-cell--12 md-text-center">
          <LinearProgress id="status_check" />
          <div>Loading Property Data...</div>
        </div>
      </div>
    ) : (
      <div
        className={
          'md-grid md-grid--no-spacing hf-host-paper hf-bookingsyncrentals-paper'
        }
      >
        <div className="md-cell md-cell--12 hf-paper">
          <PropertyList
            guidebooks={guidebooks}
            properties={properties}
            {...this.props}
          />
        </div>
      </div>
    )

    return (
      <div className="hf-main-container">
        <Toolbar
          colored
          className={'hf-host-toolbar hf-bookingsyncrentals'}
          title={'Property Management Platform Imports'}
          actions={actions}
        />
        {loaderOrList}
        <DialogContainer
          id="bs_help"
          fullPage
          portal={true}
          lastChild={true}
          renderNode={document.body}
          disableScrollLocking={true}
          visible={this.state.helpVisible}
          aria-label="PMP Imports Help"
          onHide={this.closeHelp}
          className="hf-guest-modal"
        >
          <Toolbar
            fixed
            actions={[
              <Button icon onClick={this.closeHelp}>
                close
              </Button>
            ]}
          />
          <div className="md-toolbar-relative list-item-modal-content">
            <Card>
              <CardTitle title="PMP Imports Help">
                <Button
                  className="md-cell--right"
                  icon
                  onClick={this.closeHelp}
                >
                  close
                </Button>
              </CardTitle>
              <CardText>
                <h2>Property Management Platform (PMP) Integration</h2>
                <p>
                  This list of your properties has been imported from your PMP
                  account.
                </p>
                <p>
                  Each property can be assigned to a guidebook, and multiple
                  properties can be assigned to the same guidebook.
                </p>
                <p>&nbsp;</p>
                <h2>Getting Started with Hostfully</h2>
                <p>
                  The Guidebook Platform offers all users one free guidebook, so
                  as a PMP user you can get started by selecting one of your
                  properties to import.
                </p>
                <p>
                  Once imported you can see your guidebook for this property and
                  add content to it and share it with guests if you like. The
                  guidebook URL will become availabel as a dynamic varable
                  within your custom templates and triggers within the PMP.
                </p>
                <p>
                  You need to upgrade to a paid plan (Prime or higher) to create
                  more than one guidebook.
                </p>
                <p>
                  We offer a variety of plans to suit individuals and businesses
                  and you can compare them before selecting the one that suits
                  you when you upgrade.
                </p>
              </CardText>
            </Card>
          </div>
        </DialogContainer>
      </div>
    )
  }
}

OrbirentalContainer.propTypes = {
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state, props) {
  const listData = state['list']
  const properties = listData['orbirentalproperties']

  return {
    properties: properties,
    guidebooks: listData['guidebooks']
  }
}

export default connect(mapStateToProps)(injectIntl(OrbirentalContainer))
