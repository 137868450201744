import React from 'react'

const CloudQueue = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`cloud_queue`}</title>
    <path d="M18.984 18c1.641 0 3-1.359 3-3s-1.359-3-3-3h-1.5v-.516A5.461 5.461 0 0012 6a5.499 5.499 0 00-5.297 3.984H6c-2.203 0-3.984 1.828-3.984 4.031S3.797 17.999 6 17.999h12.984zm.375-7.969C21.937 10.219 24 12.375 24 15a5.021 5.021 0 01-5.016 5.016H6c-3.328 0-6-2.672-6-6 0-3.094 2.344-5.625 5.344-5.953C6.61 5.672 9.094 3.985 12 3.985c3.656 0 6.656 2.578 7.359 6.047z" />
  </svg>
)

export default CloudQueue
