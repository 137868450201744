import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import {
  Button,
  Card,
  CardText,
  CardTitle,
  DialogContainer,
  LinearProgress,
  Media,
  MediaOverlay,
  TextField
} from 'react-md'
import { connect } from 'react-redux'
import Markdown from 'react-remarkable'

import ImageFallback from 'components/ImageFallback'
import { buildItinerary, fetchAiLog } from 'redux/modules/aiTools'
import { trackCardOpened } from 'utils/GTM';
import { nl2br, randomString } from 'utils/Strings'

const propTypes = {
  initialVisible: PropTypes.bool,
  intl: intlShape.isRequired
}

class AiItineraryCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: props.initialVisible,
      request: this.getDefaultRequest(),
      requestStatus: 'awaiting input',
      response: this.checkForExistingResponse(),
      errors: [],
      pageX: null,
      pageY: null
    }
    this.interval = null
    this.openDialog = this.openDialog.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
  }

  openDialog(e) {
    let { pageX, pageY } = e
    if (e.changedTouches) {
      const [touch] = e.changedTouches
      pageX = touch.pageX
      pageY = touch.pageY
    }
    const guidebook_id = this.props.guidebook.id || 0
    const host_user_id = this.props.guidebook.host_user_id || 0
    const tab_name = "ai"
    const card_key = "itinerary"
    const card_name = "itinerary"
    // send card_opened event to GTM/GA4
    trackCardOpened(guidebook_id, host_user_id, tab_name, card_name, card_key);
    this.setState({ visible: true, pageX, pageY })
    const cardUrl = `/${this.props.guidebook.key}/ai/itinerary`
    this.context.router.history.push(cardUrl)
  }

  closeDialog() {
    this.setState({ visible: false })
    const removeCardUrl = `/${this.props.guidebook.key}/ai/`
    this.context.router.history.push(removeCardUrl)
  }

  getDefaultRequest = () => {
    return {
      guidebook: this.props.guidebook.key,
      purpose: 'vacation',
      duration: '2 days',
      dates: 'August',
      num_adults: '2',
      num_kids: '2',
      kids_ages: '2 to 8',
      budget: '$200 per day',
      details: 'show us the hidden gems'
    }
  }

  checkForExistingResponse = () => {
    const self = this
    // check for key of most recently logged response from ChatGPT in localstorage
    console.log(this.props);
    const { key } = this.props.guidebook
    const log_id = localStorage.getItem(`hf_itinerary_${key}`)
    if (log_id) {
      self.loadExistingResponse(log_id)
    }
    return null
  }

  loadExistingResponse = (log_id) => {
    fetchAiLog(log_id).then((response) => {
      if (
        response.data &&
        response.data.response &&
        response.data.response.length > 0
      ) {
        const content = response.data.response
        // if it's not complete, then it's pending
        const status = response.data.status
        // if status is complete, then we want to make sure to turn off our polling timer
        if (status === 'complete') {
          this.disablePolling()
        }
        this.setState({
          requestStatus: response.data.status,
          response: content
        })
        return content
      } else {
        return null
      }
    })
  }

  disablePolling = () => {
    clearInterval(this.interval)
    this.interval = null
  }

  clearItineraryCookie = () => {
    const { key } = this.props.guidebook
    localStorage.removeItem(`hf_itinerary_${key}`)
  }

  setItineraryCookie = (ailog_id) => {
    const { key } = this.props.guidebook
    localStorage.setItem(`hf_itinerary_${key}`, ailog_id)
  }

  handleInputChange = (field, val) => {
    const { request, errors } = this.state
    const newDelta = Object.assign({}, request)
    const newErrors = Object.assign({}, errors)
    // if the value is different from the original data
    // AND is different from what's already in the delta
    // then we add to the delta
    if (!request[field] || request[field] !== val) {
      newDelta[field] = val
    }
    // validate the input as well
    const fieldHasError = this.fieldHasError(field, val)
    // if field has an error, update errors object
    if (fieldHasError) {
      newErrors[field] = fieldHasError
    }
    // if the field is valid but we already had errors for the field
    // remove errors
    if (!fieldHasError && errors[field]) {
      delete newErrors[field]
    }
    this.setState({ request: newDelta, errors: newErrors })
  }

  fieldHasError = (field, val) => {
    const requiredFields = [
      'purpose',
      'duration',
      'dates',
      'num_adults',
      'num_kids'
    ]
    const maxLengths = {
      purpose: 120,
      duration: 6,
      dates: 40,
      num_adults: 6,
      num_kids: 6,
      details: 240
    }
    const numericFields = ['num_adults', 'num_kids']
    if (requiredFields.indexOf(field) !== -1 && !val) {
      return 'This field is required'
    }
    if (maxLengths[field] && maxLengths[field] < val.length) {
      return `This field is limited to ${maxLengths[field]} characters`
    }
    if (numericFields.indexOf(field) !== -1 && val && isNaN(val)) {
      return 'Please enter a numeric value for this field'
    }
    return false
  }

  resetForm = () => {
    const self = this
    this.clearItineraryCookie()
    this.setState({
      requestStatus: 'awaiting input',
      request: self.getDefaultRequest(),
      response: null
    })
  }

  submitForm = () => {
    // check our errors state
    if (Object.keys(this.state.errors).length > 0) return false

    this.setState({ requestStatus: 'requested' }, () => {
      const key = randomString(10)
      const request = this.state.request

      this.setItineraryCookie(key)
      request.key = key
      buildItinerary(request)
      this.interval = setInterval(() => {
        this.loadExistingResponse(key)
      }, 750)
    })
  }

  render() {
    const aspectRatio = '4-3'
    const { intl } = this.props
    const guidebook = this.props.guidebook

    let title = 'Itinerary Generator'
    let description = 'Let AI plan your trip.'
    if (guidebook.theme && guidebook.theme.itinerary_title) {
      title =
        guidebook.theme.itinerary_title_txn[intl.locale] ||
        guidebook.theme.itinerary_title
    }
    if (guidebook.theme && guidebook.theme.itinerary_desc) {
      description =
        guidebook.theme.itinerary_desc_txn[intl.locale] ||
        guidebook.theme.itinerary_desc
    }

    // if no image, just use a title
    let imageUrl = 'https://cdn.filestackcontent.com/m2gfGcCtS2mkoXBFD49v'
    if (guidebook.theme && guidebook.theme.itinerary_image) {
      imageUrl = guidebook.theme.itinerary_image
    }
    const mediaOrTitle = (
      <Media aspectRatio={aspectRatio}>
        <ImageFallback src={imageUrl} fallback={imageUrl} role="presentation" />
        <MediaOverlay>
          <CardTitle
            title={title}
            subtitle={''}
            className="hf-rec-overlay-title"
          ></CardTitle>
        </MediaOverlay>
      </Media>
    )

    // see if we should enable the submit button
    // (disabled if there are errors, or if the request is still processing)
    const buttonDisabled =
      this.state.requestStatus === 'requested' ||
      Object.keys(this.state.errors).length > 0

    let itineraryBuilderContent = null
    if (this.state.requestStatus === 'requested' || (this.state.requestStatus === 'pending' && this.state.response === null)) {
      const bgStyle = { background: '#cccccc' }
      // const progressStyle = {background: this.props.tabColor};
      itineraryBuilderContent = (
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--12 md-text-center">
            <LinearProgress id="status_check" style={bgStyle} />
            <div>
              Communicating with ChatGPT...
              <br />
              This may take a minute or two!
            </div>
          </div>
        </div>
      )
    } else if (this.state.response === null) {
      const { errors } = this.state
      itineraryBuilderContent = (
        <div className="md-grid">
          <div className="md-cell md-cell--12">
            <p>
              Fill out the form below to generate an itinerary for your trip!
              <br />
              Note: The resulting trip plan is generated by ChatGPT and is only
              a creative suggestion of ideas to help you plan your trip. Please
              double check actual availability of suggested activities.
              <br />
              You can enter any text you want in the prompts below, don't be
              afraid to change units or currencies. We've suggested some ideas
              below but feel free to share whatever information works for you.
            </p>
          </div>
          <div className="md-cell md-cell--12">
            <TextField
              id="purpose"
              label="Purpose of Trip (eg. business, vacation, etc.) *"
              defaultValue="vacation"
              required
              onChange={(val) => {
                this.handleInputChange('purpose', val)
              }}
              maxLength={120}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              error={typeof errors.purpose !== 'undefined'}
              errorText={errors.purpose || ''}
            />
          </div>
          <div className="md-cell md-cell--6 md-cell--8-tablet md-cell--4-phone">
            <TextField
              id="budget"
              label="Estimated Budget (eg. $1000 or $100 per day)"
              defaultValue="$200 per day"
              onChange={(val) => {
                this.handleInputChange('budget', val)
              }}
              maxLength={120}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              error={typeof errors.budget !== 'undefined'}
              errorText={errors.budget || ''}
            />
          </div>
          <div className="md-cell md-cell--6 md-cell--8-tablet md-cell--4-phone">
            <TextField
              id="duration"
              label="Duration of Stay (in days) *"
              defaultValue={'1 day'}
              required
              onChange={(val) => {
                this.handleInputChange('duration', val)
              }}
              maxLength={20}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              error={typeof errors.duration !== 'undefined'}
              errorText={errors.duration || ''}
            />
          </div>
          <div className="md-cell md-cell--6 md-cell--8-tablet md-cell--4-phone">
            <TextField
              id="dates"
              label="Time of Trip (eg. Summer, August, March 2 - 8) *"
              defaultValue={'August'}
              required
              onChange={(val) => {
                this.handleInputChange('dates', val)
              }}
              maxLength={40}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              error={typeof errors.dates !== 'undefined'}
              errorText={errors.dates || ''}
            />
          </div>
          <div className="md-cell md-cell--6 md-cell--8-tablet md-cell--4-phone">
            <TextField
              id="num_adults"
              label="Number of Adults *"
              defaultValue={'2'}
              required
              onChange={(val) => {
                this.handleInputChange('num_adults', val)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              error={typeof errors.num_adults !== 'undefined'}
              errorText={errors.num_adults || ''}
            />
          </div>
          <div className="md-cell md-cell--6 md-cell--8-tablet md-cell--4-phone">
            <TextField
              id="num_kids"
              label="Number of Kids *"
              defaultValue={'2'}
              required
              onChange={(val) => {
                this.handleInputChange('num_kids', val)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              error={typeof errors.num_kids !== 'undefined'}
              errorText={errors.num_kids || ''}
            />
          </div>
          <div className="md-cell md-cell--6 md-cell--8-tabllet md-cell--4-phone">
            <TextField
              id="kids_ages"
              label="Kids Ages"
              defaultValue={'2 to 10'}
              onChange={(val) => {
                this.handleInputChange('kids_ages', val)
              }}
              maxLength={20}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              error={typeof errors.kids_ages !== 'undefined'}
              errorText={errors.kids_ages || ''}
            />
          </div>
          <div className="md-cell md-cell--12">
            <TextField
              id="details"
              label='Details (eg. "celebrating an anniversary" or "we love BBQ")'
              defaultValue={'show us the hidden gems'}
              onChange={(val) => {
                this.handleInputChange('details', val)
              }}
              maxLength={240}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              error={typeof errors.details !== 'undefined'}
              errorText={errors.details || ''}
            />
          </div>
          <div className="md-cell md-cell--12">
            <Button
              raised
              secondary
              className="hf-button-margin"
              type="submit"
              onClick={this.submitForm}
              disabled={buttonDisabled}
            >
              Plan my Trip!
            </Button>
          </div>
        </div>
      )
    } else {
      const content = nl2br(this.state.response)
      itineraryBuilderContent = (
        <div className="md-grid">
          <div className="md-cell md-cell--12">
            <Markdown>{content}</Markdown>
          </div>
          <div className="md-cell md-cell--12">
            <Button
              raised
              secondary
              className="hf-button-margin"
              type="submit"
              onClick={this.resetForm}
            >
              Reset Form
            </Button>
          </div>
        </div>
      )
    }

    return (
      <div>
        <Card
          key={'ai-itinerary'}
          raise={true}
          className="hf-clickable"
          onClick={(e) => this.openDialog(e)}
        >
          {mediaOrTitle}
          <CardText>
            <p className="host-quote">{description}</p>
          </CardText>
        </Card>
        <DialogContainer
          id="dialog-itinerary"
          visible={this.state.visible}
          pageX={this.state.pageX}
          pageY={this.state.pageY}
          onHide={this.closeDialog}
          fullPage
          portal={true}
          lastChild={true}
          disableScrollLocking={true}
          renderNode={document.body}
          aria-label={'Open the AI Trip Planner'}
          className="hf-guest-modal"
        >
          <div className="list-item-modal-content">
            <Card className="list-item-modal-card" tabIndex="0">
              <Button
                icon
                className="md-cell--right list-item-modal-close"
                onClick={this.closeDialog}
              >
                keyboard_backspace
              </Button>
              <CardTitle title={title}></CardTitle>
              <CardText>{itineraryBuilderContent}</CardText>
            </Card>
          </div>
        </DialogContainer>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    guidebook: state.guidebook.data
  }
}

AiItineraryCard.propTypes = propTypes
AiItineraryCard.contextTypes = {
  router: PropTypes.object
}

export default connect(mapStateToProps)(injectIntl(AiItineraryCard))
