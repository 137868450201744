import React from 'react'

const Canoe = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`canoe`}</title>
    <path d="M13.4 1l-2.1.9c-.2.1-.3.2-.3.3 0 .1 0 .1.1.2.4 1.4.7 2.6 1.1 3.7l-.1-.2c.3.6.5 1.4.9 2.1.5.8 1.1-.3 1.7.9l.3.9-2.7.6c-1.9.3-3.4 1.7-3.9 3.5l-1.7 3.6c-.1.1-.1.2-.1.3 0 .3.3.6.6.6H7.5c1.4 0 4.4.9 5.6 1.4h.3c.4.2 1 .3 1.5.3.7 0 1.3-.2 1.9-.4l1.7-.8c.3-.2.3-.2.5.2l.3.8v.2c0 .3-.2.5-.5.6-1.1.3-2.3.6-3.2.6-.9 0-2.3-.5-3.5-.9-1.2-.5-2.6-1.1-4.2-1.1s-3.6.6-5 1.2c-.3.1-.5.3-.5.6v.9c0 .3.3.6.6.6.1 0 .2 0 .3-.1 1.2-.5 3-1.2 4.4-1.2s2.4.5 3.5.8h.2c1.2.6 2.6 1.1 4.1 1.1s4.2-.8 5.9-1.4c.3-.1.5-.3.5-.6v-.6-.1c0-.3-.3-.6-.6-.6-.1 0-.2 0-.3.1h-.2c-.3 0-.5-.2-.6-.5l-4.1-10V8.4c-.3-1.2.5-.9.5-1.5-.2-.9-.4-1.6-.8-2.3v.1L14 1.2l-.6-.2zm2.4 10.6l1.8 5.3c.2.3.2.2-.2.3l-1.8.8c-.6.3-1.5.3-2.1-.2l-2.4-1.4c-.5-.4-.8-.9-.8-1.6v-.1c.2-1.2 1.2-2.2 2.4-2.4l2.6-.6c.5-.2.3-.4.5-.1zm-2.1 1.1c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2c-.1-1.1-.9-2-2-2z" />
  </svg>
)

export default Canoe
