import React from 'react'

const FestivalTent = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`festival_tent`}</title>
    <path d="M12 1v2h4l-1-1 1-1zm0 3l7 7v2s.242 1 1.5 1 1.5-1 1.5-1v-2zm0 0l1 7v2s.496 1 2 1 2-1 2-1v-2zm0 0l-5 7v2s.496 1 2 1 2-1 2-1v-2zm0 0L2 11v2s.242 1 1.5 1S5 13 5 13v-2zM6 14s-.992 1-2.5 1c-.215 0-.41-.027-.594-.063L2 22h7l1-4h4l1 4h7l-.906-7.063A3.111 3.111 0 0120.5 15c-1.738 0-2.5-1-2.5-1s-1.148 1-3 1c-1.598 0-3-1-3-1s-1.41 1-3 1c-1.824 0-3-1-3-1z" />
  </svg>
)

export default FestivalTent
