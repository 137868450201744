import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import { defineMessages, intlShape } from 'react-intl'
import { airportSearch } from 'redux/modules/mozio'
import { timezoneFromLatLng } from 'redux/modules/timezone'
import CSSTransitionGroup from 'react-addons-css-transition-group'
import DatePicker from 'react-datepicker'
import TimePicker from 'rc-time-picker'
import querystring from 'querystring'
import {
  Autocomplete,
  Avatar,
  Button,
  Card,
  CardTitle,
  CircularProgress,
  Divider,
  FontIcon,
  Radio,
  SelectField
} from 'react-md'
import formatAddress from 'components/Address/FormatAddress'
import injectTranslator from 'components/Translator/injectTranslator'
import { debounce } from 'utils/Debounce'
// import {trackEvent} from 'utils/Segment';
import { addressShape } from 'constants/SharedPropTypes'

const arrive = <FontIcon>flight_land</FontIcon>
const depart = <FontIcon>flight_takeoff</FontIcon>
const propTypes = {
  intl: intlShape.isRequired,
  address: addressShape.isRequired,
  direction: PropTypes.string,
  custom_color: PropTypes.string
}
const defaultProps = {
  direction: 'to_airport'
}
class GroundTransport extends PureComponent {
  constructor(props) {
    super(props)
    const startDate = this.props.date
      ? Moment(this.props.date)
      : Moment().add(30, 'minutes')
    const mode = this.props.mode
    const direction = this.props.direction
    const airport = this.props.airport
    this.state = {
      mode: mode,
      direction: direction,
      airport: airport,
      flight_type: 'domestic',
      num_passengers: '1',
      date: startDate,
      validationErrors: {},
      fetching: false,
      airports: []
    }
    this.airportSearch = debounce(this.airportSearch.bind(this), 250)
  }

  componentDidMount() {
    if (
      this.props.address &&
      this.props.address.lat &&
      this.props.address.lng
    ) {
      let newStartDate = Moment().add(30, 'minutes')
      if (this.props.address.timezone) {
        // set startdate using timezone from location if we have one
        // also add 30 minutes.
        newStartDate = Moment()
          .tz(this.props.address.timezone)
          .add(30, 'minutes')
        this.setState({ date: newStartDate })
      } else {
        // otherwise look it up using lat/lng
        timezoneFromLatLng(this.props.address.lat, this.props.address.lng).then(
          (data) => {
            if (data.status === 'OK' && data.zoneName) {
              // update startdate using this timezone
              newStartDate = Moment().tz(data.zoneName).add(30, 'minutes')
              this.setState({ date: newStartDate })
            }
          }
        )
      }
    }
  }

  formatMessage = this.props.intl.formatMessage

  messages = defineMessages({
    airportIsRequired: {
      id: 'gb.airportIsRequired',
      defaultMessage: 'An airport is required!'
    },
    dateIsRequired: {
      id: 'gb.dateIsRequired',
      defaultMessage: 'A date is required!'
    },
    futureDateIsRequired: {
      id: 'gb.futureDateIsRequired',
      defaultMessage: 'Date/time must be in the future!'
    },
    youAreTravelingFrom: {
      id: 'gb.youAreTravelingFrom',
      defaultMessage: "You are travelling from '{airport}'"
    },
    findGroundTransportation: {
      id: 'gb.findGroundTransportation',
      defaultMessage: 'Find ground transportation'
    },
    groundTransportationDeparture: {
      id: 'gb.groundTransportationDeparture',
      defaultMessage: 'Departure'
    },
    groundTransportationArrival: {
      id: 'gb.groundTransportationArrival',
      defaultMessage: 'Arrival'
    },
    oneWay: {
      id: 'gb.oneWay',
      defaultMessage: 'One way'
    },
    return: {
      id: 'gb.return',
      defaultMessage: 'Return'
    },
    direction: {
      id: 'gb.direction',
      defaultMessage: 'Direction'
    },
    toAirport: {
      id: 'gb.toAirport',
      defaultMessage: 'To airport'
    },
    fromAirport: {
      id: 'gb.fromAirport',
      defaultMessage: 'From airport'
    },
    arrivalTime: {
      id: 'gb.arrivalTime',
      defaultMessage: 'Flight arrival time'
    },
    departureTime: {
      id: 'gb.departureTime',
      defaultMessage: 'Flight departure time'
    },
    arrivalDate: {
      id: 'gb.arrivalDate',
      defaultMessage: 'Flight arrival date'
    },
    departureDate: {
      id: 'gb.departureDate',
      defaultMessage: 'Flight departure date'
    },
    pickupFromWhichAirport: {
      id: 'gb.pickupFromWhichAirport',
      defaultMessage: 'Pick up from which airport?'
    },
    dropoffAtWhichAirport: {
      id: 'gb.dropoffAtWhichAirport',
      defaultMessage: 'Drop off at which airport?'
    },
    domestic: {
      id: 'gb.domestic',
      defaultMessage: 'Domestic'
    },
    international: {
      id: 'gb.international',
      defaultMessage: 'International'
    },
    domesticOrInternational: {
      id: 'gb.domesticOrInternational',
      defaultMessage: 'Will you be at the domestic or international terminal?'
    },
    searchMozio: {
      id: 'gb.searchMozio',
      defaultMessage: 'Search Mozio'
    },
    passengers: {
      id: 'gb.passengers',
      defaultMessage: 'Passengers'
    },
    terminal: {
      id: 'gb.terminal',
      defaultMessage: 'Terminal'
    }
  })

  setAirport = (airport) => {
    this.trackMozioEvent('AirportChanged', airport)
    this.setState({ airport })
  }

  airportSearch = (value) => {
    if (!value) {
      this.setState({ airports: [], fetching: false })
      return
    }

    this.setState({ airport: value })
    if (value.length > 2) {
      airportSearch(value).then((data) => {
        const { results } = data
        console.log(data)

        const airports = results.map((airport) => {
          return {
            id: airport.value,
            key: airport.value,
            name: airport.display
          }
        })

        this.setState({ airports })
      })
    }
  }

  validate = () => {
    // clear validation errors
    this.setState({ validationErrors: {} })
    const validationErrors = {}
    const { airport } = this.state

    if (!airport) {
      validationErrors.airport = this.formatMessage(
        this.messages.airportIsRequired
      )
    }

    const now = Moment()
    const dateTime = this.state.date ? Moment(this.state.date) : now
    if (dateTime.isBefore(now)) {
      validationErrors.date = this.formatMessage(
        this.messages.futureDateIsRequired
      )
    }

    const valid = Object.keys(validationErrors).length === 0
    if (!valid) {
      this.setState({ validationErrors })
    }
    return valid
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { airport, mode, direction, num_passengers, flight_type } = this.state
    const dateTime = this.state.date ? Moment(this.state.date) : Moment()

    if (this.validate()) {
      const address = this.props.address

      const { addressPrimary, addressSecondary } = formatAddress(address, true)
      const mozioAddress = addressPrimary + ' ' + addressSecondary
      const startAddress = direction === 'to_airport' ? mozioAddress : airport
      const endAddress = direction === 'to_airport' ? airport : mozioAddress

      //02/13/2015 3:00 pm
      const params = {
        ref: process.env.REACT_APP_MOZIO_REF || 'hostfully',
        utm_campaign: process.env.REACT_APP_MOZIO_CAMPAIGN || 'hostfully',
        mode: mode,
        dir: direction,
        num_passengers: num_passengers,
        flight_type: flight_type,
        flight_datetime: dateTime.format('MM/DD/YYYY h:mm a'),
        start_address: startAddress,
        end_address: endAddress
      }
      const baseUrl =
        process.env.REACT_APP_MOZIO_URL || 'https://www.mozio.com/search'
      /*
       <div class="mozio-widget"
       style="background-color:#ffffff;border:1px solid #ffffff;padding:5%"
       data-destination-name="<?php echo $data_destination_name ?>"
       data-destination="<?php echo $data_destination ?>"
       data-default-dir="from_airport"
       data-default-mode="round_trip"
       data-ref="hostfully"><?php _e("Loading airport ground transportation search powered by", "hostfully");?> <a href="https://www.mozio.com/">Mozio</a>...
       </div>
       */
      const url = baseUrl + '?' + querystring.stringify(params)
      // console.log(url);
      this.trackMozioEvent(
        'SearchClicked',
        querystring.stringify(params),
        function () {
          window.open(url, '_transport')
        }
      )
    }
  }

  _handleDirectionChange = (direction) => {
    this.setState({ direction })
  }

  _handleModeChange = (mode) => {
    this.setState({ mode })
  }

  _handleTimeChange = (time) => {
    const date = Moment(this.state.date)
    date.hours(time.hours())
    date.minutes(time.minutes())
    this.validate()
    this.trackMozioEvent('TimeChanged', date.toString())
    this.setState({ date: date })
  }

  _handleTerminalChange = (flight_type) => {
    this.trackMozioEvent('TerminalChanged', flight_type)
    this.setState({ flight_type })
  }

  _handlePassengersChange = (num_passengers) => {
    this.trackMozioEvent('PassengersChanged', num_passengers)
    this.setState({ num_passengers })
  }

  _handleDateChange = (newDateStr) => {
    const date = Moment(this.state.date)
    const newDate = Moment(newDateStr)
    date.year(newDate.year())
    date.month(newDate.month())
    date.dayOfYear(newDate.dayOfYear())
    this.validate()
    this.trackMozioEvent('DateChanged', date.toString())
    this.setState({ date: date })
  }

  trackMozioEvent = (type, value, cb) => {
    if (typeof cb !== 'function') cb = function () {}
    let eventData = {
      category: 'Mozio'
    }
    if (value) {
      eventData.label = value
    }
    // OLD SEGMENT CODE
    // trackEvent('mozio' + type, eventData, {}, cb);
    cb()
  }

  render() {
    const {
      mode,
      direction,
      date,
      flight_type,
      num_passengers,
      fetching,
      airports
    } = this.state
    const modeSelection = this.props.mode ? null : (
      <div className="md-cell md-cell--6">
        <fieldset>
          <legend className="md-subheading-1">Trip</legend>
          <Radio
            id="oneway"
            inline
            name="trip"
            value="one_way"
            checked={mode === 'one_way'}
            label={this.formatMessage(this.messages.oneWay)}
            onChange={this._handleModeChange}
          />
          <Radio
            id="return"
            inline
            name="trip"
            value="return"
            checked={mode === 'return'}
            label={this.formatMessage(this.messages.return)}
            onChange={this._handleModeChange}
          />
        </fieldset>
      </div>
    )

    const displayNone = { display: 'none' }
    const directionSelection = this.props.direction ? null : (
      <div className="md-cell md-cell--6" style={displayNone}>
        <fieldset>
          <legend className="md-subheading-1" />
          <Radio
            id="to_airport"
            inline
            name="direction"
            value="to_airport"
            checked={direction === 'to_airport'}
            label={this.formatMessage(this.messages.toAirport)}
            onChange={this._handleDirectionChange}
          />
          <Radio
            id="from_airport"
            inline
            name="direction"
            value="from_airport"
            checked={direction === 'from_airport'}
            label={this.formatMessage(this.messages.fromAirport)}
            onChange={this._handleDirectionChange}
          />
        </fieldset>
      </div>
    )

    /*todo: bob confirm if this is needed?
    const timeLabel = this.props.direction === 'to_airport' ? this.formatMessage(this.messages.departureTime) : this.formatMessage(this.messages.arrivalTime);
    const dateLabel = this.props.direction === 'to_airport' ? this.formatMessage(this.messages.departureDate) : this.formatMessage(this.messages.arrivalDate);*/
    const airportMessage =
      this.props.direction === 'to_airport'
        ? this.formatMessage(this.messages.dropoffAtWhichAirport)
        : this.formatMessage(this.messages.pickupFromWhichAirport)
    const subtitle =
      this.props.direction === 'to_airport'
        ? this.formatMessage(this.messages.groundTransportationDeparture)
        : this.formatMessage(this.messages.groundTransportationArrival)
    const dateErrors = this.state.validationErrors.date ? (
      <span className="error">{this.state.validationErrors.date}</span>
    ) : null
    const airportErrors = this.state.validationErrors.airport ? (
      <span className="error">{this.state.validationErrors.airport}</span>
    ) : null
    const avatarIcon = this.props.direction === 'to_airport' ? depart : arrive
    const avatarStyle = { background: this.props.custom_color || '#03a9f4' }
    const progress = (
      <div className="md-cell md-cell--12" key="progress">
        <CircularProgress id="fetching-artist" />
      </div>
    )
    return (
      <Card className="md-cell md-cell--12 hf-ground-transport" raise={true}>
        <CardTitle
          avatar={
            <Avatar icon={avatarIcon} style={avatarStyle} role="presentation" />
          }
          title={this.formatMessage(this.messages.findGroundTransportation)}
          subtitle={subtitle}
        ></CardTitle>
        <form className="md-grid">
          {modeSelection}
          {directionSelection}
          <div className="md-picker-container md-cell md-cell--6">
            <div className="md-text-field-container md-full-width md-text-field-container--input md-pointer--hover">
              <div className="md-text-field-icon-container">
                <i className="md-icon material-icons md-text-field-icon md-text-field-icon--positioned">
                  date_range
                </i>
                <div className="md-text-field-divider-container md-text-field-divider-container--grow">
                  <DatePicker
                    selected={date}
                    onChange={this._handleDateChange}
                    className="md-text-field md-text md-full-width md-text-field--floating-margin md-pointer--hover"
                  />
                  <hr className="md-divider md-divider--text-field md-divider--expand-from-left" />
                </div>
              </div>
            </div>
            {dateErrors}
          </div>
          <div className="md-picker-container md-cell md-cell--6">
            <div className="md-text-field-container md-full-width md-text-field-container--input md-pointer--hover">
              <div className="md-text-field-icon-container">
                <i className="md-icon material-icons md-text-field-icon md-text-field-icon--positioned">
                  access_time
                </i>
                <div className="md-text-field-divider-container md-text-field-divider-container--grow">
                  <TimePicker
                    showSecond={false}
                    value={date}
                    className="md-text-field md-text md-full-width md-text-field--floating-margin md-pointer--hover"
                    onChange={this._handleTimeChange}
                    format="h:mm a"
                    use12Hours
                  />
                  <hr className="md-divider md-divider--text-field md-divider--expand-from-left" />
                </div>
              </div>
            </div>
          </div>
          <div className="md-cell md-cell--6">
            <CSSTransitionGroup
              component="div"
              className="md-grid"
              transitionName="md-cross-fade"
              transitionEnterTimeout={300}
              transitionLeave={false}
            >
              <Autocomplete
                id="airport"
                label={airportMessage}
                data={airports}
                dataLabel="name"
                dataValue="id"
                required
                errorText={this.state.errorText}
                onChange={this.airportSearch}
                onAutocomplete={this.setAirport}
              />
              {airportErrors}
              {fetching ? progress : null}
            </CSSTransitionGroup>
          </div>

          <SelectField
            id="terminal"
            label={this.formatMessage(this.messages.terminal)}
            menuItems={[
              {
                label: this.formatMessage(this.messages.domestic),
                value: 'domestic'
              },
              {
                label: this.formatMessage(this.messages.international),
                value: 'international'
              }
            ]}
            itemLabel="label"
            itemValue="value"
            className="md-cell md-cell--6"
            value={flight_type}
            helpOnFocus
            helpText={this.formatMessage(this.messages.domesticOrInternational)}
            onChange={this._handleTerminalChange}
          />
          <Divider />
          <SelectField
            id="passengers"
            label={this.formatMessage(this.messages.passengers)}
            placeholder="1"
            defaultValue={1}
            menuItems={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            className="md-cell md-cell--6"
            value={num_passengers}
            onChange={this._handlePassengersChange}
          />
          <div className="md-cell md-cell--12">
            <Button
              className="card-button-padding"
              onClick={this.handleSubmit}
              raised
              primary
            >
              {this.formatMessage(this.messages.searchMozio)}
            </Button>
          </div>
        </form>
      </Card>
    )
  }
}
GroundTransport.propTypes = propTypes
GroundTransport.defaultProps = defaultProps

export default injectTranslator(GroundTransport)
