import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Chip } from 'react-md'

class TagChip extends Component {
  constructor(props) {
    super(props)
    this.handleRemove = this.handleRemove.bind(this)
  }

  handleRemove = () => {
    this.props.onClick(this.props.tagName)
  }

  render() {
    const { tagName, ...props } = this.props
    const chipStyle = { marginRight: '4px', cursor: 'pointer' }
    return (
      <Chip
        {...props}
        onClick={this.handleRemove}
        removable
        label={tagName}
        style={chipStyle}
      />
    )
  }
}

TagChip.propTypes = {
  tagName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default TagChip
