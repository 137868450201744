import PropTypes from 'prop-types'
import React from 'react'
import { Avatar, Button, FontIcon, Paper } from 'react-md'
import { connect } from 'react-redux'

import oneLineAddress from 'components/Address/OneLineAddress'

import { resizeImage } from 'utils/Image'
import { buildGuidebookUrl } from 'utils/Urls'

import SharePanel from '../SharePanel'

function GuidebookListItem({ item, editItem, pluralName }) {
  const name = `${item.is_published === false ? 'DRAFT - ' : ''}${item.name}`
  const image = resizeImage(item.image, 50, 50, true)

  const previewItem = (e) => {
    const domain =
      item.domain && item.domain.status === 'approved'
        ? item.domain.domain
        : null
    const gbUrl = buildGuidebookUrl(
      item.key,
      null,
      null,
      false,
      domain,
      item.is_published
    )

    e.stopPropagation()
    window.open(gbUrl, '_blank')
  }

  const formattedAddress = oneLineAddress(item.address)

  return (
    <Paper
      raiseOnHover
      zDepth={0}
      className="hf-list-item--guidebook"
      onClick={editItem}
    >
      <Avatar src={image} role="presentation" />
      <div className="hf-list-item--guidebook-title">
        <p className="md-subheading-1">{name}</p>
        <span className="md-caption">{formattedAddress}</span>
      </div>
      {pluralName === 'guidebooks' ? (
        <SharePanel
          domain={item.domain}
          isPublished={item.is_published}
          gbKey={item.key}
          locales={item.locales}
        />
      ) : null}
      <Button
        iconEl={<FontIcon>visibility</FontIcon>}
        onClick={previewItem}
        secondary
        flat
      >
        VIEW
      </Button>
    </Paper>
  )
}

GuidebookListItem.propTypes = {
  item: PropTypes.object.isRequired,
  editItem: PropTypes.func,
  deleteItem: PropTypes.func,
  pluralName: PropTypes.string.isRequired,
  key: PropTypes.string,
  id: PropTypes.number.isRequired
}

function mapStateToProps(state, props) {
  return {
    item: props.item
  }
}

export default connect(mapStateToProps)(GuidebookListItem)
