import React, { Component } from 'react'
import { Button, Paper } from 'react-md'

import { randomString } from 'utils/Strings'

const propTypes = {}
const defaultProps = {}

/**
 * Form to initiate Oauth Stripe Connect
 */
class StripeSetupForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stripeState: null,
      redirectUri: null
    }
  }

  componentDidMount() {
    // do this here so we only do it once
    this.setState({
      stripeState: this.generateState(),
      redirectUri: this.generateRedirectUri()
    })
  }

  buildStripeUrl = () => {
    const baseUrl = 'https://connect.stripe.com/oauth/authorize'
    const responseType = 'code'
    const clientId = process.env.REACT_APP_ORBI_STRIPE_ID
    const scope = 'read_write'
    const stripeState = this.state.stripeState
    const redirectUri = this.state.redirectUri

    if (stripeState && redirectUri) {
      return `${baseUrl}?response_type=${responseType}&client_id=${clientId}&scope=${scope}&state=${stripeState}&redirect_uri=${redirectUri}`
    }

    return '#'
  }

  generateRedirectUri = () => {
    const urlBase = process.env.REACT_APP_BASE_URL

    return `${urlBase}/host/marketplace/stripeConnect`
  }

  /* state is used in the stripe connect process as a CSRF protection */
  generateState = () => {
    const stripeState =
      localStorage.getItem('hf_stripe_state') || randomString(10)

    localStorage.setItem('hf_stripe_state', stripeState)

    return stripeState
  }

  render() {
    const fee = '1%'
    const stripeUrl = this.buildStripeUrl()

    return (
      <Paper className="hf-card hf-marketplace-dashboard-card">
        <h3 className="hf-secondary-title">Setup Stripe account</h3>
        <div className="md-body-1">
          <p>
            You need to connect with Stripe so that you can accept payment from
            guests when they purchase Marketplace items. Once setup, payments
            will go directly to your Stripe account.
          </p>
          <p>
            Please click{' '}
            <span className="md-font-bold">“Connect with Stripe”</span> below to
            connect your Hostfully Guidebooks with your Stripe account.
          </p>
          <p>
            Please note: If you do not have an existing Stripe account, you can
            create a new one. Hostfully charges a {fee} commission fee for all
            services. This is in addition to any fees that Stripe may charge on
            payments.
          </p>
        </div>
        <Button href={stripeUrl} secondary raised>
          Connect with Stripe
        </Button>
      </Paper>
    )
  }
}

StripeSetupForm.propTypes = propTypes
StripeSetupForm.defaultProps = defaultProps

export default StripeSetupForm
