import React from 'react'

const Microwave = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`microwave`}</title>
    <path d="M5 4C3.355 4 2 5.355 2 7v9c0 1.645 1.355 3 3 3v1h3v-1h8v1h3v-1c1.645 0 3-1.355 3-3V7c0-1.645-1.355-3-3-3zm0 2h14c.555 0 1 .445 1 1v9c0 .555-.445 1-1 1H5c-.555 0-1-.445-1-1V7c0-.555.445-1 1-1zm1 2v7h9V8zm11.5 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM16 12v2h3v-2z" />
  </svg>
)

export default Microwave
