import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FontIcon, ListItem } from 'react-md'
import { Link } from 'react-router-dom'

class ThemeListItem extends Component {
  render() {
    const { item, editItem, deleteItem, pluralName } = this.props
    const imgStyle = { height: '40px', width: 'auto' }

    return (
      <ListItem
        onClick={editItem}
        className={'hf-list-item hf-' + pluralName + 'list-item'}
        leftAvatar={
          <Link
            to={'/host/' + pluralName + '/' + item.id}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <img src={item.logo} alt="" style={imgStyle} />
          </Link>
        }
        primaryText={
          <Link
            to={'/host/' + pluralName + '/' + item.id}
            onClick={(e) => {
              e.stopPropagation()
            }}
            className="md-tile-text--primary md-text hf-fake-link"
          >
            {item.name}
          </Link>
        }
        secondaryText={item.website}
        rightIcon={<FontIcon onClick={deleteItem}>delete</FontIcon>}
      />
    )
  }
}

ThemeListItem.propTypes = {
  item: PropTypes.object.isRequired
}

export default ThemeListItem
