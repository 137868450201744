import { getToken } from 'utils/Auth'

import 'whatwg-fetch'

export function fetchTerms(userId) {
  let url = process.env.REACT_APP_API_URL + '/api/v1/guest_terms/'

  const token = getToken()
  const config = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }

  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
}
