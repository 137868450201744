import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
  autoSaveItem,
  fetchItem,
  newItem,
  createTemplateFromGuidebook,
  newGuidebookWithTemplate
} from 'redux/modules/crud'
import Edit from './Edit'
import Loading from './Loading'

class ItemEditorContainer extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    const {
      itemId,
      isLoading,
      pluralName,
      ownerPluralName,
      guidebookId,
      copy
    } = this.props
    return (
      nextProps.itemId !== itemId ||
      nextProps.isLoading !== isLoading ||
      nextProps.pluralName !== pluralName ||
      nextProps.ownerPluralName !== ownerPluralName ||
      nextProps.guidebookId !== guidebookId ||
      nextProps.copy !== copy
    )
  }

  componentWillMount() {
    this.load()
  }

  load() {
    const {
      itemId,
      pluralName,
      singularName,
      ownerPluralName,
      ownerSingularName,
      guidebookId,
      edit_owner,
      edit_gb_template_form,
      copy,
      createTemplate,
      createWithTemplate,
      dispatch
    } = this.props
    const hash = window.decodeURIComponent(window.location.hash)
    // make sure the guidebook/template is up to date if specified (eg if browser refreshed)
    if (
      guidebookId &&
      '' + edit_owner.id !== guidebookId &&
      guidebookId !== 'create'
    ) {
      dispatch(fetchItem(ownerPluralName, ownerSingularName, guidebookId))
    }
    if (
      !hash ||
      ((pluralName === 'guidebooks' || pluralName === 'templates') &&
        !edit_gb_template_form.noReload)
    ) {
      // the hash indicates they've returned from adding/editing a card from within a guidebook, so we don't want to reload anything.yo
      if (itemId === 'create') {
        // if createWithTemplate is flagged
        if (createWithTemplate) {
          dispatch(newGuidebookWithTemplate(itemId))
          // , or empty data template id, use load with template
        } else if (
          edit_gb_template_form.template &&
          edit_gb_template_form.template.id &&
          this.isNumeric(edit_gb_template_form.template.id.value)
        ) {
          dispatch(
            newGuidebookWithTemplate(
              parseInt(edit_gb_template_form.template.id.value, 10)
            )
          )
        } else {
          dispatch(newItem(singularName))
        }
      } else if (createTemplate) {
        dispatch(createTemplateFromGuidebook(itemId))
      } else {
        if (copy && singularName === 'guidebook') {
          // save item right off the bat and redirect
          dispatch(
            fetchItem(pluralName, singularName, itemId, copy, (gbData) => {
              dispatch(
                autoSaveItem(pluralName, singularName, undefined, gbData)
              )
            })
          )
        } else {
          dispatch(fetchItem(pluralName, singularName, itemId, copy))
        }
      }
    }
  }

  isNumeric(n) {
    return !isNaN(parseInt(n, 10))
  }

  componentDidUpdate(prevProps) {
    const { itemId, singularName, copy } = this.props
    if (
      prevProps.itemId !== itemId ||
      prevProps.singularName !== singularName ||
      !!prevProps.copy !== !!copy
    ) {
      this.load()
    }
  }

  render() {
    const { itemId, EditForm, isLoading, singularName, pluralName, copy } =
      this.props
    const id = itemId === 'create' ? null : itemId
    return isLoading || (copy && singularName === 'guidebook') ? (
      <Loading pluralName={pluralName} />
    ) : (
      <Edit form={EditForm} itemId={id} {...this.props} />
    )
  }
}

ItemEditorContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  singularName: PropTypes.string.isRequired,
  pluralName: PropTypes.string.isRequired,
  ownerSingularName: PropTypes.string,
  ownerPluralName: PropTypes.string,
  createTemplate: PropTypes.bool,
  createWithTemplate: PropTypes.bool,
  form: PropTypes.func.isRequired
}

function mapStateToProps(state, props) {
  const { ownerSingularName, singularName } = props
  const data = state.edit_item[singularName]
  const edit_owner = state['edit_' + ownerSingularName]
  const edit_gb_template_form = state.forms['edit_' + singularName]
  return {
    isLoading: data.isLoading,
    edit_owner,
    edit_gb_template_form
  }
}

export default connect(mapStateToProps)(injectIntl(ItemEditorContainer))
