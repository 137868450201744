import React from 'react'

const Dog = (props) => (
  <svg width={24} height={24} viewBox="0 0 192 192" {...props}>
    <title>{`dog`}</title>
    <path d="M172 34c0-1-20 20-22 31s-3 9-7 12H70L53 59 35 40s-3 11-3 15-4 11-6 15-6 13-6 13 8 8 10 8 14-4 14-4l6 5v58a6 6 0 006 6 6 6 0 006-6v-34l72-2v38a6 6 0 1012 0V82c4-3 5-10 9-16 10-17 18-20 17-32z" />
  </svg>
)

export default Dog
