/*eslint no-unused-vars: ["off", { "ignoreRestSiblings": true }]*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import { Control } from 'react-redux-form'
import { Switch } from 'react-md'

const propTypes = {
  checked: PropTypes.bool
}
const defaultProps = {
  checked: false
}

class HfSwitchForm extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    let { editModel, model, dispatch } = this.props
    dispatch(actions.change(editModel + '.' + model, e))
  }

  render() {
    const hfSwitch = (props) => {
      const { model, dispatch, ...rest } = props
      return (
        <Switch
          id={props.name}
          name={props.name}
          checked={this.props.checked}
          onChange={this.handleChange}
          {...rest}
        />
      )
    }

    const mapProps = { value: (props) => props.viewValue }
    return (
      <Control.custom
        label={this.props.label}
        mapProps={mapProps}
        model={this.props.model}
        name={this.props.name}
        className={this.props.className}
        component={hfSwitch}
      />
    )
  }
}

HfSwitchForm.propTypes = propTypes
HfSwitchForm.defaultProps = defaultProps

export default connect()(HfSwitchForm)
