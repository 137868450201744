import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import {
  Avatar,
  Button,
  Card,
  CardText,
  CardTitle,
  DialogContainer,
  FontIcon,
  List,
  ListItem,
  TextField
} from 'react-md'
import { searchByGb } from 'redux/modules/search'
import { ucFirst } from 'utils/Strings'

const propTypes = {
  onOpenClose: PropTypes.func
}
const defaultProps = {
  onOpenClose: () => {}
}

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchOpen: false,
      showResults: false,
      results: [],
      filter: ''
    }
  }

  openSearch = () => {
    this.setState({ searchOpen: true }, () => {
      this.props.onOpenClose(true)
      const input = document.getElementById('searchEntry')
      input.setSelectionRange(input.value.length, input.value.length)
      input.focus()
    })
  }

  closeSearch = () => {
    this.setState({ searchOpen: false }, () => {
      this.props.onOpenClose(false)
    })
  }

  closeResults = () => {
    this.setState({ showResults: false })
  }

  runSearch = () => {
    // get locale so we can make searching system text easier
    const { cookies, guidebook } = this.props
    const cookieLocale = cookies.get(`hf_lang_${guidebook.key}`) || 'en-US'

    searchByGb(guidebook.key, this.state.filter, cookieLocale).then((data) => {
      this.setState({ results: data, showResults: true })
      // console.log(data);
    })
  }

  filterChanged = (term) => {
    this.setState({ filter: term })
  }

  handleKeyPressed = (event) => {
    if (event.key === 'Enter') {
      this.runSearch()
    }
  }

  loadItem = (link) => {
    window.open(link, 'newExternalTab')
  }

  renderSearchResults = () => {
    const self = this

    if (this.state.results.length) {
      return (
        <List>
          {this.state.results.map((result) => {
            let icon = 'assignment_turned_in'
            let primaryText = ucFirst(result.type)
            switch (result.type) {
              case 'directions':
                icon = (
                  <FontIcon style={{ color: '#ffc107' }}>directions</FontIcon>
                )
                break
              case 'wifi':
                icon = <FontIcon style={{ color: '#03a9f4' }}>wifi</FontIcon>
                break
              case 'checkin':
                primaryText = 'Check-in'
                icon = <FontIcon style={{ color: '#4caf50' }}>vpn_key</FontIcon>
                break
              case 'gainingaccess':
                primaryText = 'Gaining Access'
                icon = <FontIcon style={{ color: '#4caf50' }}>vpn_key</FontIcon>
                break
              case 'checkout':
                primaryText = 'Checkout'
                icon = <FontIcon style={{ color: '#2196f3' }}>list</FontIcon>
                break
              case 'parking':
                icon = (
                  <FontIcon style={{ color: '#8bc34a' }}>
                    local_parking
                  </FontIcon>
                )
                break
              case 'hostintro':
                primaryText = 'Host Introduction'
                icon = <FontIcon style={{ color: '#ff9800' }}>face</FontIcon>
                break
              case 'recommendation':
                icon = (
                  <FontIcon style={{ color: '#673ab7' }}>favorite</FontIcon>
                )
                break
              case 'information':
                primaryText = 'House Manual'
                icon = <FontIcon style={{ color: '#9c27b0' }}>home</FontIcon>
                break
              default:
                icon = <FontIcon>search</FontIcon>
                break
            }
            return (
              <ListItem
                key={`${result.type}${result.id}`}
                onClick={() => self.loadItem(result.link)}
                leftIcon={icon}
                primaryText={primaryText}
                secondaryText={result.label}
              />
            )
          })}
        </List>
      )
    }

    // if no results...
    return <div className="search-results-inner">No results found</div>
  }

  renderResultsContainer = () => {
    const icon = (
      <Avatar
        icon={<FontIcon>search</FontIcon>}
        suffix={'hfprimaryavatar'}
        role="presentation"
      />
    )

    return (
      <div className="search-results">
        <Card tabIndex="0">
          <CardTitle title={''} avatar={icon}>
            <TextField
              key="gbSearch"
              id="searchEntry"
              value={this.state.filter || ''}
              placeholder="Search"
              type="text"
              helpOnFocus={true}
              className="searchEntry"
              onChange={this.filterChanged}
              onKeyDown={(e) => this.handleKeyPressed(e)}
              style={{ paddingLeft: '20px' }}
            />
            <Button flat primary onClick={this.runSearch}>
              Go
            </Button>
          </CardTitle>
          <CardText dir="auto">{this.renderSearchResults()}</CardText>
        </Card>
      </div>
    )
  }

  render() {
    // const self = this;

    return (
      <div id="hf-search" className="md-inline-block ">
        {this.state.searchOpen ? (
          [
            <FontIcon key="searchIcon" onClick={this.runSearch}>
              search
            </FontIcon>,
            <TextField
              key="gbSearch"
              id="searchEntry"
              value={this.state.filter || ''}
              placeholder="Search"
              type="text"
              helpOnFocus={true}
              className="searchEntry"
              onChange={this.filterChanged}
              onKeyDown={(e) => this.handleKeyPressed(e)}
            />,
            <FontIcon key="searchClose" onClick={this.closeSearch}>
              close
            </FontIcon>
          ]
        ) : (
          <FontIcon key="searchOpen" onClick={this.openSearch}>
            search
          </FontIcon>
        )}
        <DialogContainer
          id={'search-results'}
          visible={this.state.showResults}
          aria-label={'Search Results'}
          onHide={this.closeResults}
          focusOnMount={true}
          portal={true}
          renderNode={document.body}
          className="search-dialog"
        >
          {this.renderResultsContainer()}
        </DialogContainer>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    guidebook: state.guidebook.data
  }
}

Search.propTypes = propTypes
Search.defaultProps = defaultProps

export default withCookies(connect(mapStateToProps)(Search))
