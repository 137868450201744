import React from 'react'

const Bathroom = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`bathroom`}</title>
    <path d="M9 6.8h1v2.3H9V6.8zM11.3 10.9h-.8v2.3h.8v-2.3zM14.2 11.9h1v2.3h-1v-2.3zM12.7 8.1h1v2.3h-1V8.1z" />
    <path d="M18.8 1.1h-6.2c-1 0-1.8.5-2.3 1.3L9 4.9h5.2l-1.5-2.3h6c1 0 1.8.8 1.8 1.8v10.7H2v6c0 1 .8 1.8 1.8 1.8h16.8c.7 0 1.3-.6 1.3-1.3V3.9c.1-1.8-.7-2.8-3.1-2.8z" />
  </svg>
)

export default Bathroom
