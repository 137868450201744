import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types';

// const propTypes = {};
// const defaultProps = {};

class GuidebookClosedStep extends PureComponent {
  render() {
    return <div className="guidebook-step-closed"></div>
  }
}

// GuidebookClosedStep.propTypes = propTypes;
// GuidebookClosedStep.defaultProps = defaultProps;

export default GuidebookClosedStep
