import React from 'react'

const BabyNeeds = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`baby_needs`}</title>
    <path d="M21.4 11.3l1.1 5.5h.5l-1.2-5.5V9.6h-.9c0-1.2-.6-2.3-1.6-2.3s-1.6 1.1-1.6 2.3h-.9v1.6l-1.2 5.6h.4l1.1-5.5h.3v.3l-1 4.4h.5l.8-4.1h2.8l.8 4.1h.5l-.9-4.4h.5v-.3zm-2.3 0c0 .3-.2.5-.5.5h-.2c-.3 0-.5-.2-.5-.5v-.8h1.2v.8zm1.6 0c0 .3-.2.5-.5.5H20c-.3 0-.5-.2-.5-.5v-.8h1.2v.8zM12.5 7.6H1v9.2h.6v-3h10.6v3h.6V7.6h-.3zM3.8 8h.6v4.4h-.6V8zm-.6 4.4h-.5V8h.6l-.1 4.4zM4.9 8h.6v4.4h-.6V8zM6 8h.6v4.4h-.5L6 8zm1.1 0h.6v4.4h-.5L7.1 8zm1.1 0h.6v4.4h-.5L8.2 8zm1.1 0h.6v4.4h-.5L9.3 8zm1.1 0h.6v4.4h-.5L10.4 8zM1.6 8h.6v4.4h-.6V8zm10 4.4V8h.6v4.4h-.6z" />
  </svg>
)

export default BabyNeeds
