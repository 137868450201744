import React from 'react'

const FishFood = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`fish_food`}</title>
    <path d="M22.946 11.676c-.06-.173-1.441-4.063-6.039-5.301a3.575 3.575 0 00-2.904-2.722l-3.839-.64c-.338-.055-.68.065-.909.32s-.31.609-.217.938l.755 2.677a12.303 12.303 0 00-2.787 1.695C6.232 7.93 5.017 7 3 7a1 1 0 00-1 1v8a1 1 0 001 1c1.873 0 3.145-.966 3.955-1.657A10.953 10.953 0 0011 17.553V19a1.002 1.002 0 001.161.987c.328-.053 3.036-.542 4.252-2.267 4.752-1.104 6.443-5.168 6.514-5.346a.999.999 0 00.019-.698zM16.5 10c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5z" />
  </svg>
)

export default FishFood
