import React from 'react'

const FreeBreakfast = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`free_breakfast`}</title>
    <path d="M3.984 18.984h16.031V21H3.984v-2.016zM20.016 8.016v-3H18v3h2.016zm0-5.016c1.125 0 1.969.891 1.969 2.016v3a1.951 1.951 0 01-1.969 1.969H18v3c0 2.203-1.781 4.031-3.984 4.031h-6c-2.203 0-4.031-1.828-4.031-4.031V3.001h16.031z" />
  </svg>
)

export default FreeBreakfast
