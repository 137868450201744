import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  header: PropTypes.node.isRequired
}

class PrintSubsection extends PureComponent {
  render() {
    return (
      <div className="print-subsection">
        <div className="print-subheader">{this.props.header}</div>
        <div>{this.props.children}</div>
      </div>
    )
  }
}

PrintSubsection.propTypes = propTypes

export default PrintSubsection
