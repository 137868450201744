import { getToken } from 'utils/Auth'

import 'whatwg-fetch'

/**
 * fetch voice tags from Virtual Concierge
 */
export function fetchTags() {
  const method = 'GET'
  const path = '/api/v1/voicetags'
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }
  return fetch(url, config)
    .then((response) => {
      // console.log(response);
      if (!response.ok) {
        // throw Error(response.statusText);
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
}

export function reImportGuidebook(id, key) {
  const method = 'GET'
  const path = `/api/v1/vcs_import/${id}/${key}`
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
    .catch(function (err) {
      return { error: err }
    })
}

export function checkVCSStatus() {
  const method = 'GET'
  const path = '/api/v1/vcs_check'
  const url = process.env.REACT_APP_API_URL + path
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
    .catch(function (err) {
      return { error: err }
    })
}
