import React from 'react'

const People = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`people`}</title>
    <path d="M15.984 12.984c2.344 0 7.031 1.172 7.031 3.516v2.484h-6V16.5c0-1.5-.797-2.625-1.969-3.469.328-.047.656-.047.938-.047zm-7.968 0C10.36 12.984 15 14.156 15 16.5v2.484H.984V16.5c0-2.344 4.688-3.516 7.031-3.516zm0-1.968c-1.641 0-3-1.359-3-3s1.359-3 3-3 2.953 1.359 2.953 3-1.313 3-2.953 3zm7.968 0c-1.641 0-3-1.359-3-3s1.359-3 3-3 3 1.359 3 3-1.359 3-3 3z" />
  </svg>
)

export default People
