import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Snackbar } from 'react-md'
import { clearToast } from 'redux/modules/toast'

class Toast extends PureComponent {
  constructor(props) {
    super(props)

    this._removeToast = this._removeToast.bind(this)
    this._addToast = this._addToast.bind(this)
    this.state = {
      toasts: [],
      autohide: true
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.toast) {
      this._addToast(
        nextProps.toast.text,
        nextProps.toast.action,
        nextProps.toast.autohide
      )
      // The state of the toasts is actually managed by this component, not Redux.
      // So after we add a toast to our initial state, we just clear the state in the redux store.
      this.props.dispatch(clearToast())
    }
  }

  _addToast(text, action, autohide = true) {
    this.setState((state) => {
      const toasts = state.toasts.slice()
      toasts.push({ text, action })
      return { toasts, autohide }
    })
  }

  _removeToast() {
    const [, ...toasts] = this.state.toasts
    this.setState({ toasts })
  }

  render() {
    // update zIndex so toast appears in front of dialogs
    const snackbarStyle = { zIndex: 120 }
    return (
      <div className="btn-group toast-item">
        <Snackbar
          className="toast-item"
          toasts={this.state.toasts}
          onDismiss={this._removeToast}
          autohide={this.state.autohide}
          autohideTimeout={5000}
          style={snackbarStyle}
          portal
        />
      </div>
    )
  }
}

Toast.propTypes = {
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state, props) {
  return {
    toast: state.toast
  }
}

export default connect(mapStateToProps)(Toast)
