import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Map from 'components/Map'
import { setPrintReadyState } from 'redux/modules/printReadyState'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  onMapBoundsChange: PropTypes.func,
  fitType: PropTypes.string,
  initialZoom: PropTypes.number,
  fixToPosition: PropTypes.bool,
  outerContainer: PropTypes.string
}

const defaultProps = {
  fitType: 'smart',
  initialZoom: 13,
  fixToPosition: false,
  outerContainer: 'map-outer-container'
}

class PrintMap extends Component {
  componentWillMount() {
    this.props.dispatch(setPrintReadyState(this.props.mapId, false))
  }

  componentDidUpdate() {
    this.fixMapPosition()
    //this.props.dispatch(setPrintReadyState(this.props.mapId, false));
  }

  mapReady = () => {
    this.props.dispatch(setPrintReadyState(this.props.mapId, true))
  }

  fixMapPosition = () => {
    if (this.props.fixToPosition) {
      // get width of the md-cell--6
      const container = document.getElementById(this.props.outerContainer)
      const containerWidth = container.offsetWidth
      //const containerTop = container.offsetTop;
      const mapElement = document.getElementsByClassName(
        'map-container-fixed'
      )[0]
      mapElement.style.position = 'fixed'
      mapElement.style.width = containerWidth + 'px'
      // set width to the calculated width
    }
  }

  render() {
    const markers = this.props.mapMarkers
    const options = this.props.mapOptions

    return (
      <div className="guidebook-map">
        <Map
          className={this.props.className}
          lat={this.props.guidebook.address.lat}
          lng={this.props.guidebook.address.lng}
          zoom={options.zoom || this.props.initialZoom}
          onClick={this.onMapClick}
          fitType={this.props.fitType}
          onBoundsChanged={this.props.onBoundsChanged}
          onWindowResized={this.fixMapPosition}
          mapId={this.props.mapId}
          onTilesLoaded={this.mapReady}
        >
          {markers}
        </Map>
      </div>
    )
  }
}

PrintMap.propTypes = propTypes
PrintMap.defaultProps = defaultProps

function mapStateToProps(state, props) {
  return {
    mapOptions: state.map.options
  }
}

export default connect(mapStateToProps)(PrintMap)
