import arMessages from 'translations/locales/ar-AE.json'
import daMessages from 'translations/locales/da-DK.json'
import deMessages from 'translations/locales/de-DE.json'
import elMessages from 'translations/locales/el-GR.json'
import enMessages from 'translations/locales/en-US.json'
import esMessages from 'translations/locales/es-ES.json'
import frMessages from 'translations/locales/fr-FR.json'
import heMessages from 'translations/locales/he-IL.json'
import itMessages from 'translations/locales/it-IT.json'
import jaMessages from 'translations/locales/ja-JP.json'
import nlMessages from 'translations/locales/nl-NL.json'
import ptBRMessages from 'translations/locales/pt-BR.json'
import ptPTMessages from 'translations/locales/pt-PT.json'
import plMessages from 'translations/locales/pl-PL.json'
import ruMessages from 'translations/locales/ru-RU.json'
import vnMessages from 'translations/locales/vi-VN.json'
import zhMessages from 'translations/locales/zh-TW.json'

const messages = {
  'ar-AE': arMessages,
  'da-DK': daMessages,
  'de-DE': deMessages,
  'el-GR': elMessages,
  'es-ES': esMessages,
  'en-US': enMessages,
  'fr-FR': frMessages,
  'he-IL': heMessages,
  'it-IT': itMessages,
  'ja-JP': jaMessages,
  'nl-NL': nlMessages,
  'pl-PL': plMessages,
  'pt-BR': ptBRMessages,
  'pt-PT': ptPTMessages,
  'ru-RU': ruMessages,
  'vi-VN': vnMessages,
  'zh-TW': zhMessages
}

export const messagesForLocale = function (locale) {
  return messages[locale]
}
