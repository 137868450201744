import React from 'react'

const Usb = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`usb`}</title>
    <path d="M15 6.984h3.984v4.031H18v1.969A2.001 2.001 0 0115.984 15h-3v3.047c.703.375 1.219 1.125 1.219 1.969 0 1.219-.984 2.203-2.203 2.203s-2.203-.984-2.203-2.203c0-.844.516-1.594 1.219-1.969V15h-3A2.001 2.001 0 016 12.984v-2.063c-.703-.375-1.219-1.078-1.219-1.922 0-1.219.984-2.203 2.203-2.203s2.203.984 2.203 2.203c0 .844-.469 1.547-1.172 1.922v2.063h3V5.015H8.999l3-4.031 3 4.031h-2.016v7.969h3v-1.969h-.984V6.984z" />
  </svg>
)

export default Usb
