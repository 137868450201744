import React from 'react'
import { Paper } from 'react-md'

export default function Alert({ type, message, description }) {
  return (
    <Paper className={`hf-alert hf-alert-${type}`}>
      <div className="hf-alert-message">{message}</div>
      <div className="hf-alert-description">{description}</div>
    </Paper>
  )
}
