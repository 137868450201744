import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Button, FontIcon } from 'react-md'

class GuidebookStatusBar extends Component {
  publishGB = () => {
    const { dispatch, guidebook } = this.props
    // just redirect to the edit page so we don't have to reproduce the whole
    // process of checking # of guidebooks and offering upgrades and everything.
    const gb_id = guidebook.id
    dispatch(push(`/host/guidebooks/${gb_id}#publish`))
  }

  render() {
    const self = this
    const { guidebook } = this.props
    let iconStyle = {}
    if (guidebook.is_template) {
      iconStyle = { verticalAlign: 'bottom' }
      return (
        <div className="guidebook-status-outer">
          <div className="guidebook-status-inner">
            <div className="md-text-center">
              <FontIcon primary style={iconStyle}>
                developer_board
              </FontIcon>
              <span className="md-text-uppercase md-font-light">
                {' '}
                Template{' '}
              </span>
              <span>
                {' '}
                This is a template and is not visible to guests. Create a
                guidebook from the template and publish it to make it visible.
              </span>
            </div>
          </div>
        </div>
      )
    } else if (!guidebook.is_published) {
      iconStyle = { verticalAlign: 'middle', marginRight: '8px' }
      const buttonStyle = { marginLeft: '8px' }
      return (
        <div className="guidebook-status-outer">
          <div className="guidebook-status-inner">
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--12 md-text-center">
                <FontIcon primary style={iconStyle}>
                  visibility_off
                </FontIcon>
                <span className="md-text-uppercase md-font-light">
                  {' '}
                  Unpublished{' '}
                </span>
                <span> This guidebook is not visible to guests.</span>
                <Button
                  raised
                  primary
                  className="preview-button"
                  onClick={self.publishGB}
                  style={buttonStyle}
                >
                  <span>Publish Guidebook</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

function mapStateToProps(state, props) {
  return {
    guidebook: state.guidebook.data
  }
}

export default connect(mapStateToProps)(GuidebookStatusBar)
