import React from 'react'
import { Button } from 'react-md'

import ErrorScreen from 'components/ErrorScreen'

import MissingInPlan from './sloth_sleep.svg'

export default function NotInPlan({
  description = 'You can click the round icon in the upper right corner and select Manage Account (or click button below), to see upgrade options.'
}) {
  const goToAccount = () => {
    window.location.href = process.env.REACT_APP_WORDPRESS_URL + '/account'
  }

  return (
    <ErrorScreen
      title="We’re sorry, you don’t have access to this feature under your current plan."
      description={description}
      image={
        <img
          id="sloth-image"
          src={MissingInPlan}
          alt="sloth"
          height="256px"
          style={{ marginLeft: '-64px' }}
        />
      }
      actions={
        <Button
          onClick={goToAccount}
          className="error-page__button"
          primary
          flat
          swapTheming
        >
          Manage Account
        </Button>
      }
      nested
    />
  )
}
