import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PrintHtml from 'components/Print/PrintHtml'
import PrintContentBlock from 'components/Print/PrintContentBlock'
import { stringToId } from 'utils/Strings'
import { buildGuidebookUrl } from 'utils/Urls'

const propTypes = {
  sectionTitle: PropTypes.string,
  informations: PropTypes.array,
  guidebook: PropTypes.object,
  styles: PropTypes.object,
  token: PropTypes.object,
  showQR: PropTypes.bool
}

const defaultProps = {
  showQR: false
}

class HouseManual extends Component {
  render() {
    const { informations, sectionTitle, styles } = this.props
    const variableData = { token: this.props.token }
    const domain =
      this.props.guidebook.domain &&
      this.props.guidebook.domain.status === 'approved'
        ? this.props.guidebook.domain.domain
        : null

    let children = []
    // filter out meta cards
    
    children = informations
    .filter(function(information) {
      return !information.meta_card
    })
    .map(function (information) {
      let printContent = information.use_external_link
        ? information.external_link
        : information.content
      // for photo-type house manual cards, inject the photo!
      if (information.template === 0) {
        printContent =
          `<p><img src="${information.image}" /></p>` + printContent
      }
      const printTxn = information.use_external_link
        ? {}
        : information.content_txn
      return (
        <div
          key={information.id}
          className="content-block content-block-avoid md-cell md-cell--12 print-house-rules"
        >
          <PrintHtml
            title={information.title}
            title_txn={information.title_txn}
            titleClass="content-subtitle"
            styles={styles ? styles : null}
            text={printContent}
            translations={printTxn}
            variableData={variableData}
          />
        </div>
      )
    })

    const section_id = stringToId(sectionTitle)
    let linkSlug = 'house_manual'
    // get the house manual category
    if (informations.length && informations[0].category) {
      linkSlug = informations[0].category.name.toLowerCase().replace(/ /g, '_')
    }
    return (
      <PrintContentBlock
        key={`container-${section_id}`}
        title={sectionTitle}
        styles={styles ? styles : null}
        avoidBreak={true}
        showQR={this.props.showQR}
        QRLink={`${buildGuidebookUrl(this.props.guidebook.key, null, null, false, domain, this.props.guidebook.is_published)}/${linkSlug}`}
      >
        {children}
      </PrintContentBlock>
    )
  }
}

HouseManual.propTypes = propTypes
HouseManual.defaultProps = defaultProps

export default HouseManual
