import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Media } from 'react-md'

import PrintHeader from 'components/Print/PrintHeader'
import HostIntro from 'components/Print/SubSections/HostIntro'
import ListingName from 'components/Print/SubSections/ListingName'
import SvgIcon from 'components/SvgIcon'
import { categoryIconSvg, markerColors } from 'constants/UIConstants'
import { resizeImage } from 'utils/Image'
import { buildGuidebookUrl } from 'utils/Urls'

class CoverPage3 extends Component {
  render() {
    const icons = []
    for (var icon in categoryIconSvg) {
      if ({}.hasOwnProperty.call(categoryIconSvg, icon)) {
        icons.push(
          <td key={'icon_' + icon} className="hf-col">
            <SvgIcon
              fillColor={markerColors[icon]}
              size="208px"
              viewBox="0 0 200 200"
            >
              {categoryIconSvg[icon]}
            </SvgIcon>
          </td>
        )
      }
    }

    const listingImage = resizeImage(this.props.guidebook.image, 260, 260, true)
    const propertyImage = (
      <td key={'icon_property'} className="hf-col">
        <Media aspectRatio="1-1">
          <img
            src={listingImage}
            alt="category icon"
            className="hf-cp3-property"
          />
        </Media>
      </td>
    )

    //remove unwanted icons
    icons.splice(2, 1)
    icons.splice(8, 1)

    //add property to middle
    icons.splice(4, 0, propertyImage)

    //break into row arrays
    const row1 = icons.slice(0, 3)
    const row2 = icons.slice(3, 6)
    const row3 = icons.slice(6)

    const tableWrapperStyle = { width: '80%', margin: '0 auto' }
    return (
      <div id="coverpage3" className="page-break-after">
        <PrintHeader
          theme={this.props.theme}
          coverPageClass="print-header-cp2"
          alignChildren="center"
        >
          <ListingName
            guidebook={this.props.guidebook}
            styles={this.props.styles ? this.props.styles : null}
          />
        </PrintHeader>
        <div style={tableWrapperStyle}>
          <table key="cp3-table" className="hf-icon-table">
            <tbody width="100%">
              <tr width="100%">{row1}</tr>
              <tr width="100%">{row2}</tr>
              <tr width="100%">{row3}</tr>
            </tbody>
          </table>
        </div>
        <div className="hf-gb-url">
          {buildGuidebookUrl(
            this.props.guidebook.key,
            null,
            null,
            false,
            this.props.guidebook.domain?.status === 'approved'
              ? this.props.guidebook.domain.domain
              : null,
            this.props.guidebook.is_published
          )}
        </div>

        <HostIntro
          guidebook={this.props.guidebook}
          styles={this.props.styles ? this.props.styles : null}
        />
      </div>
    )
  }
}

CoverPage3.propTypes = {
  guidebook: PropTypes.object,
  printPreferences: PropTypes.object,
  onReady: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  styles: PropTypes.object,
  theme: PropTypes.object
}

export default CoverPage3
