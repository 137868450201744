import React from 'react'

const DirectionsBus = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`directions_bus`}</title>
    <path d="M18 11.016V6H6v5.016h12zm-1.5 6c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm-9 0c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm-3.516-1.032V6c0-3.516 3.609-3.984 8.016-3.984s8.016.469 8.016 3.984v9.984c0 .891-.422 1.688-1.031 2.25v1.781a.96.96 0 01-.984.984h-.984c-.563 0-1.031-.422-1.031-.984v-1.031H8.017v1.031c0 .563-.469.984-1.031.984h-.984a.96.96 0 01-.984-.984v-1.781c-.609-.563-1.031-1.359-1.031-2.25z" />
  </svg>
)

export default DirectionsBus
