import React from 'react'

const FerrisWheel = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`ferris_wheel`}</title>
    <path d="M12 2c-.813 0-1.5.492-1.813 1.188-.003.003-.027-.004-.03 0-1.11.23-2.145.68-3.063 1.28a1.992 1.992 0 00-.782-.155c-1.105 0-2 .894-2 2 0 .277.055.542.157.78a8.878 8.878 0 00-1.282 3.095A1.99 1.99 0 002 12c0 .82.484 1.535 1.188 1.844.23 1.113.68 2.14 1.28 3.062a1.992 1.992 0 00-.155.782c0 1.105.894 2 2 2 .277 0 .542-.055.78-.157.587.383 1.208.7 1.876.938L8.688 22h2l.187-1.063c.371.047.742.063 1.125.063.379 0 .758-.016 1.125-.063L13.313 22h2l-.282-1.5c.672-.242 1.285-.582 1.875-.969.239.102.504.157.782.157 1.105 0 2-.895 2-2 0-.278-.055-.543-.157-.782a8.904 8.904 0 001.282-3.062c.003-.012-.004-.02 0-.031A1.99 1.99 0 0022 12c0-.813-.492-1.5-1.188-1.813-.003-.011.004-.019 0-.03a8.904 8.904 0 00-1.28-3.063c.1-.239.155-.504.155-.782 0-1.105-.894-2-2-2-.277 0-.542.055-.78.157a8.904 8.904 0 00-3.063-1.282c-.004-.003-.028.004-.031 0A1.99 1.99 0 0012 2zm-1.594 3.188C10.77 5.68 11.344 6 12 6c.656 0 1.23-.32 1.594-.813.77.18 1.476.497 2.125.907-.008.074-.031.144-.031.218 0 1.106.894 2 2 2 .074 0 .144-.023.218-.03.41.648.727 1.355.907 2.124A1.973 1.973 0 0018 12c0 .656.32 1.23.813 1.594a6.975 6.975 0 01-.875 2.125c-.083-.012-.165-.031-.25-.031-1.106 0-2 .894-2 2 0 .074.023.144.03.218-.34.215-.687.41-1.062.563l-.969-5.407c.192-.308.313-.671.313-1.062a1.999 1.999 0 10-4 0c0 .39.121.754.313 1.063l-.97 5.406a6.917 6.917 0 01-1.062-.532c.012-.082.031-.164.031-.25 0-1.105-.894-2-2-2-.085 0-.167.02-.25.032a6.84 6.84 0 01-.875-2.125C5.68 13.23 6 12.656 6 12a2 2 0 00-.813-1.625 6.92 6.92 0 01.907-2.094c.074.008.144.031.218.031 1.106 0 2-.894 2-2 0-.085-.019-.167-.03-.25a6.975 6.975 0 012.124-.875zM12 14.813l.75 4.156A6.84 6.84 0 0112 19c-.254 0-.504-.004-.75-.031z" />
  </svg>
)

export default FerrisWheel
