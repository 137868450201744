import Moment from 'moment'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import {
  Button,
  Card,
  CardText,
  CardTitle,
  DataTable,
  FontIcon,
  Paper,
  SelectField,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow,
  TextField,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'

import CsvCreator from 'components/react-csv-creator'
import { fetchStats } from 'redux/modules/stats'
import { buildGuidebookUrl } from 'utils/Urls'

class HostStats extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stats: {
        name: 'basic',
        startDate: null,
        userId: null,
        data: {}
      },
      lookup: {
        user: {
          gb_key: null,
          data: {}
        }
      }
    }
    this.timer = null
  }

  componentDidMount() {
    this._getStatsData()
    this.timer = setInterval(() => this._getStatsData(), 10000)
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.stats.name !== this.state.stats.name) {
      if (nextState.stats.name === 'basic') {
        this.timer = setInterval(() => this._getStatsData(), 10000)
      } else {
        clearInterval(this.timer)
        this.timer = null
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
    this.timer = null
  }

  /**
   * Make api call to get stat data and set to state
   */
  _getStatsData = (name, startDate) => {
    const n = name || this.state.stats.name || 'basic'
    const sd = startDate || null
    const ed = null
    const u = this.state.stats.userId || null

    fetchStats(n, sd, ed, u).then((data) => {
      this.setState({
        stats: {
          name: n,
          startDate: sd,
          userId: u,
          data: data
        }
      })
    })
  }

  /**
   * respond to change in user id on form
   */
  _handleUserIdValueChange = (newValue) => {
    const n = this.state.stats.name
    const sd = this.state.stats.startDate
    const u = newValue
    const data = this.state.stats.data

    this.setState({
      stats: {
        name: n,
        startDate: sd,
        userId: u,
        data: data
      }
    })
  }

  _handleUserChange = () => {
    this._getStatsData()
  }

  /**
   * respond to changes in dropdown for stats types
   */
  _handleTypeChange = (newValue, newActiveIndex, event) => {
    let statType = 'basic'
    let startDate = null
    // let endDate = null;
    if (newValue === 'Daily Guidebooks') {
      statType = 'guidebooks'
      // get selected date, default to today
      startDate = this.state.stats.startDate || this._formatDate()
    } else if (newValue === 'Customer Completion') {
      statType = 'customer_completion'
    }
    this._getStatsData(statType, startDate)
  }

  /**
   * respond to changes in datepicker for startDate
   */
  _handleStartDateChange = (newDateStr) => {
    const startDate = this._formatDate(newDateStr)
    const statType = this.state.stats.name
    this._getStatsData(statType, startDate)
  }

  handleLookupUserGbKeyChange = (newKey) => {
    const lookup = this.state.lookup
    lookup.user.gb_key = newKey
    this.setState({ lookup: lookup })
  }

  handleLookupUser = () => {
    const lookup = this.state.lookup

    // allow copied/pasted URL or key.
    // if url format detected, strip down to just the key
    let k = this.state.lookup.user.gb_key
    if (k.substr(0, 4) === 'http' || k.indexOf('/') !== -1) {
      k = k.split('/').slice(-1)[0]
      lookup.user.gb_key = k
    }
    const n = 'user_by_guidebook',
      sd = null,
      ed = null,
      u = null

    fetchStats(n, sd, ed, u, k).then((data) => {
      if (data) {
        lookup.user.data = data
        this.setState({ lookup: lookup })
      }
    })
  }

  /**
   * format the inputDate as YYYY-MM-DD
   */
  _formatDate = (inputDate) => {
    const date = inputDate || new Date()
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  /**
   * render the appropriate stats report
   */
  _renderStats = () => {
    if (this.state.stats.name === 'basic') {
      return this._renderQuickStats()
    } else if (this.state.stats.name === 'guidebooks') {
      return this._renderGuidebookReport()
    } else if (this.state.stats.name === 'customer_completion') {
      return this._renderCustomerCompletion()
    }
  }

  _startTimer = () => {}

  /**
   * render Daily Guidebook Report
   */
  _renderGuidebookReport = () => {
    const numGuidebooks = this.state.stats.data.length
    const date = Moment(this.state.stats.startDate).format('L')

    return (
      <Card style={{ margin: '16px' }}>
        <CardTitle title={numGuidebooks + ' guidebooks created on ' + date} />
        <CardText className="md-grid">
          {this._renderGuidebookRows()}
          {this._renderDatePicker()}
        </CardText>
      </Card>
    )
  }

  /**
   * render rows for Daily Guidebook report
   */
  _renderGuidebookRows = () => {
    const { data } = this.state.stats
    if (!data || data.length === 0) {
      return this._noResults()
    } else {
      const gbRows = data.map((gb) => {
        const gbUrl = buildGuidebookUrl(gb.key)
        const length = 20 // set to the number of characters you want to keep
        const trimmedName = gb.name.trim().substr(0, length)
        return (
          <TableRow key={'gb-row-'.concat(gb.id)}>
            <TableColumn>{gb.id}</TableColumn>
            <TableColumn>
              <a
                href={gbUrl}
                target="newExternaalTab"
                rel="noopener noreferrer"
                title={gbUrl}
              >
                {trimmedName}
              </a>
            </TableColumn>
            <TableColumn>{gb.user_id}</TableColumn>
            <TableColumn>{gb.user_email}</TableColumn>
          </TableRow>
        )
      })
      return (
        <div className="md-cell md-cell--12">
          <DataTable plain>
            <TableHeader>
              <TableRow>
                <TableColumn>id</TableColumn>
                <TableColumn>name</TableColumn>
                <TableColumn>user_id</TableColumn>
                <TableColumn>email</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>{gbRows}</TableBody>
          </DataTable>
        </div>
      )
    }
  }

  /**
   * render date picker row
   */
  _renderDatePicker = () => {
    const startDate = Moment(this.state.stats.startDate)
    return (
      <div className="md-picker-container md-cell md-cell--4">
        <div className="md-text-field-container md-full-width md-text-field-container--input md-pointer--hover">
          <div className="md-text-field-icon-container">
            <i className="md-icon material-icons md-text-field-icon md-text-field-icon--positioned">
              date_range
            </i>
            <div className="md-text-field-divider-container md-text-field-divider-container--grow">
              <DatePicker
                selected={startDate}
                onChange={this._handleStartDateChange}
                className="md-text-field md-text md-full-width md-text-field--floating-margin md-pointer--hover"
              />
              <hr className="md-divider md-divider--text-field md-divider--expand-from-left" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  /**
   * render no-data state
   */
  _noResults = () => {
    return <div className="md-cell md-cell--12">No results found.</div>
  }

  /**
   * render simple data error
   */
  _dataError = (message) => {
    return <div className="md-cell md-cell--12">{message}</div>
  }

  _arrayToObject = (arr) => {
    var rv = {}
    for (var i = 0; i < arr.length; ++i) rv[i] = arr[i]
    return rv
  }

  _toObjectArray = (data) => {
    var returnData = []
    for (var i = 0; i < data.length; ++i)
      returnData.push(this._arrayToObject(data[i]))
    return returnData
  }

  /**
   * render individual quick stats card
   */
  _renderQuickStatCard = (title, icon, data) => {
    return (
      <Card className="md-cell md-cell--4 md-cell--2-phone">
        <CardTitle avatar={<FontIcon>{icon}</FontIcon>} title={title} />
        <CardText className="md-text-center">
          <h2>{data}</h2>
        </CardText>
      </Card>
    )
  }

  _renderRecsStatCard = (title, icon, recs, sponsored_recs) => {
    return (
      <Card className="md-cell md-cell--4 md-cell--2-phone">
        <CardTitle avatar={<FontIcon>{icon}</FontIcon>} title={title} />
        <CardText className="md-text-left">
          <div className=" md-grid md-grid--no-spacing">
            <div className="md-cell md-cell--7">Recommendations:</div>
            <div className="md-cell md-cell--5">{recs}</div>
            <div className="md-cell md-cell--7">Sponsored:</div>
            <div className="md-cell md-cell--5">{sponsored_recs}</div>
          </div>
        </CardText>
      </Card>
    )
  }

  /**
   * render quick stats report
   */
  _renderQuickStats = () => {
    const { data } = this.state.stats
    return (
      <div className="md-grid">
        {this._renderQuickStatCard(
          'Total Guidebooks',
          'import_contacts',
          data.guidebook_count
        )}
        {this._renderRecsStatCard(
          'Total Recs',
          'favorite',
          data.recommendation_count,
          data.sponsored_rec_count
        )}
        {this._renderQuickStatCard(
          'Total Infos',
          'home',
          data.information_count
        )}
        <Card className="md-cell md-cell--4 md-cell--2-phone">
          <CardTitle
            avatar={<FontIcon>people</FontIcon>}
            title="Users with most Guidebooks"
          />
          <CardText className="md-text-center">
            {data.users_with_most_gb
              ? data.users_with_most_gb.map((user) => (
                  <div key={'user_' + user.user_id}>
                    {user.user_id + ' (' + user.gb_count + ')'}
                  </div>
                ))
              : null}
          </CardText>
        </Card>
        <Card className="md-cell md-cell--4 md-cell--2-phone">
          <CardTitle
            avatar={<FontIcon>favorite</FontIcon>}
            title="GB with most Recs"
          />
          <CardText className="md-text-center">
            {data.gbs_with_most_recs
              ? data.gbs_with_most_recs.map((gb) => (
                  <div key={'gb' + gb.key}>
                    <a
                      href={'/' + gb.key}
                      target="newExternaalTab"
                      rel="noopener noreferrer"
                    >
                      {gb.key + ' (' + gb.rec_count + ')'}
                    </a>
                  </div>
                ))
              : null}
          </CardText>
        </Card>
        <Card className="md-cell md-cell--4 md-cell--2-phone">
          <CardTitle
            avatar={<FontIcon>home</FontIcon>}
            title="GB with most Infos"
          />
          <CardText className="md-text-center">
            {data.gbs_with_most_infos
              ? data.gbs_with_most_infos.map((gb) => (
                  <div key={'gb' + gb.key}>
                    <a
                      href={'/' + gb.key}
                      target="newExternaalTab"
                      rel="noopener noreferrer"
                    >
                      {gb.key + ' (' + gb.info_count + ')'}
                    </a>
                  </div>
                ))
              : null}
          </CardText>
        </Card>
        <Card className="md-cell md-cell--6">
          <CardTitle
            avatar={<FontIcon>search</FontIcon>}
            title="Lookup user by guidebook key"
          />
          {this.renderUserLookup()}
        </Card>
        <Card className="md-cell md-cell--6">
          <CardTitle
            avatar={<FontIcon>insert_link</FontIcon>}
            title="Quick Links"
          />
          <CardText className="md-text-left">
            <div key="icon-viewer">
              <a href="/admin/icons" target="_blank">
                Icon Viewer
              </a>
              <br />
              <a href="/admin/slugblacklist" target="_blank">
                Custom Slug Blacklist
              </a>
            </div>
          </CardText>
        </Card>
      </div>
    )
  }

  renderUserLookup = () => {
    const gbKey = this.state.lookup.user.gb_key || ''
    const { data } = this.state.lookup.user
    let results = null
    if (data && data.user_id && data.email) {
      results = (
        <div>
          <div className="md-cell md-cell--2">
            <strong>id</strong>
          </div>
          <div className="md-cell md-cell--10">{data.user_id}</div>
          <div className="md-cell md-cell--2">
            <strong>email</strong>
          </div>
          <div className="md-cell md-cell--10">{data.email}</div>
        </div>
      )
    }
    if (data && data.error) {
      results = <div className="md-cell md-cell--12">{data.error}</div>
    }

    return (
      <div className="md-grid">
        <TextField
          id="guidebook_key"
          label="Guidebook Key"
          lineDirection="center"
          size={10}
          value={gbKey}
          onChange={this.handleLookupUserGbKeyChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              return this.handleLookupUser()
            }
          }}
          className="md-cell md-cell--8"
        />
        <div className="md-cell md-cell--4">
          <Button
            onClick={this.handleLookupUser}
            raised
            primary
            className="hf-button-margin"
          >
            Go
          </Button>
        </div>
        {results}
      </div>
    )
  }

  _renderCustomerCompletion = () => {
    const userId = this.state.stats.userId || ''
    return (
      <Paper style={{ margin: '16px', padding: '12px 16px' }}>
        <TextField
          id="customer_id"
          label="User Id"
          lineDirection="center"
          maxLength={10}
          value={userId}
          onChange={this._handleUserIdValueChange}
        />
        <Button onClick={this._handleUserChange} raised primary>
          Go
        </Button>
        {this._renderCustomerCompletionData()}
      </Paper>
    )
  }

  _renderCustomerCompletionData = () => {
    const { data } = this.state.stats
    if (!this.state.stats.userId) {
      return (
        <div className="md-grid">
          {this._dataError('Please enter a User Id first.')}
        </div>
      )
    } else if (!data || data.length === 0 || !data.generalInfo) {
      return <div className="md-grid">{this._noResults()}</div>
    } else {
      return (
        <div className="md-grid">
          <div className="md-cell md-cell--3">General Guidebook Info:</div>
          <div className="md-cell md-cell--9">
            {this._renderGeneralInfo(data.generalInfo)}
          </div>
          <div className="md-cell md-cell--3">House Manual Info:</div>
          <div className="md-cell md-cell--9">
            {this._renderHouseManualInfo(data.houseInfo)}
          </div>
          <div className="md-cell md-cell--3">Recommendation Info:</div>
          <div className="md-cell md-cell--9">
            {this._renderRecommendationInfo(data.recInfo)}
          </div>
        </div>
      )
    }
  }

  _renderGeneralInfo = (data) => {
    if (!data || data.length === 0) return 'insufficient data - no guidebooks'

    const general_filename = 'user_' + this.state.stats.userId + '_general'
    const general_headers = [
      { id: 'id', display: 'id' },
      { id: 'guidebook_name', display: 'Guidebook Name' },
      { id: 'street_no', display: 'street_no' },
      { id: 'street_name', display: 'street_name' },
      { id: 'city', display: 'city' },
      { id: 'country', display: 'country' },
      { id: 'postal_code', display: 'postal_code' },
      { id: 'checkin_card', display: 'checkin_card' },
      { id: 'directions_card', display: 'directions_card' },
      { id: 'parking_card', display: 'parking_card' },
      { id: 'wifi_card', display: 'wifi_card' },
      { id: 'checkout_card', display: 'checkout_card' },
      { id: 'host_card', display: 'host_card' },
      { id: 'url', display: 'url' },
      { id: 'is_published', display: 'is_published' }
    ]

    return (
      <CsvCreator
        filename={general_filename}
        headers={general_headers}
        rows={data}
      >
        <span className="csv-link">{general_filename + '.csv'}</span>
      </CsvCreator>
    )
  }

  /**
   * data returned in three parts:
   * array of guidebooks with name and id
   * array of informations with name and id
   * array of associations between these
   *
   * need to combine so that it becomes a map of associations btw infos and gbs
   *
   */
  _renderHouseManualInfo = (data) => {
    if (!data.informations || data.informations.length === 0)
      return 'insufficient data - no house manuals'
    if (!data.guidebooks || data.informations.length === 0)
      return 'insufficient data - no guidebooks'

    const houseData = []
    const house_headers = ['Guidebook Name']
    const house_id_idx = [0]
    const gb_id_idx = [0]
    // go through the info rows to create the headers
    for (var info of data.informations) {
      house_headers.push(info.name)
      // stash the ids in the house_id_idx array
      house_id_idx.push(info.id)
    }
    // push this as first array
    houseData.push(house_headers)

    // go through the guidebooks and set up rows for each
    for (var gb of data.guidebooks) {
      const gbRow = []
      gbRow.push(gb.name)
      // prefill everything with "No"
      for (var i = 1; i < house_id_idx.length; i++) {
        gbRow[i] = 'No'
      }
      houseData.push(gbRow)
      // stash the ids in the house_id_idx array
      gb_id_idx.push(gb.id)
    }

    if (data.associations && data.associations.length > 0) {
      // now go through the association array and fill in yes where applicable
      for (var assoc of data.associations) {
        // use our index references to look up the row and column
        let rowIdx = gb_id_idx.indexOf(assoc.guidebook_id)
        let colIdx = house_id_idx.indexOf(assoc.information_id)
        // fill in the Yes where applicable
        houseData[rowIdx][colIdx] = 'Yes'
      }
    }
    const dataObject = this._toObjectArray(houseData)
    const house_filename = 'user_' + this.state.stats.userId + '_house_manuals'
    return (
      <CsvCreator filename={house_filename} rows={dataObject}>
        <span className="csv-link">{house_filename + '.csv'}</span>
      </CsvCreator>
    )
  }

  /**
   * data returned in three parts:
   * array of guidebooks with name and id
   * array of recommendations with name and id
   * array of associations between these
   *
   * need to combine so that it becomes a map of associations between recs and gbs
   *
   */
  _renderRecommendationInfo = (data) => {
    if (!data.recommendations || data.recommendations.length === 0)
      return 'insufficient data - no recommendations'
    if (!data.guidebooks || data.guidebooks.length === 0)
      return 'insufficient data - no guidebooks'

    const recData = []
    const rec_headers = ['Guidebook Name']
    const rec_id_idx = [0]
    const gb_id_idx = [0]
    // go through the info rows to create the headers
    for (var rec of data.recommendations) {
      rec_headers.push(rec.name)
      // stash the ids in the rec_id_idx array
      rec_id_idx.push(rec.id)
    }
    // push this as first array
    recData.push(rec_headers)

    // go through the guidebooks and set up rows for each
    for (var gb of data.guidebooks) {
      const gbRow = []
      gbRow.push(gb.name)
      // prefill everything with "No"
      for (var i = 1; i < rec_id_idx.length; i++) {
        gbRow[i] = 'No'
      }
      recData.push(gbRow)
      // stash the ids in the rec_id_idx array
      gb_id_idx.push(gb.id)
    }

    if (data.associations && data.associations.length > 0) {
      // now go through the association array and fill in yes where applicable
      for (var assoc of data.associations) {
        // use our index references to look up the row and column
        let rowIdx = gb_id_idx.indexOf(assoc.guidebook_id)
        let colIdx = rec_id_idx.indexOf(assoc.recommendation_id)
        // fill in the Yes where applicable
        recData[rowIdx][colIdx] = 'Yes'
      }
    }
    const dataObject = this._toObjectArray(recData)
    const rec_filename = 'user_' + this.state.stats.userId + '_recommendations'
    return (
      <CsvCreator filename={rec_filename} rows={dataObject}>
        <span className="csv-link">{rec_filename + '.csv'}</span>
      </CsvCreator>
    )
  }

  render() {
    const statTypes = ['Quick Stats', 'Daily Guidebooks', 'Customer Completion']
    const titleMenu = (
      <SelectField
        key="statMenu"
        id="statTypes"
        menuItems={statTypes}
        defaultValue={statTypes[0]}
        onChange={this._handleTypeChange}
      />
    )

    return (
      <div className="hf-main-container">
        <Toolbar
          className={'hf-host-toolbar hf-reports'}
          colored
          titleMenu={titleMenu}
        />
        {this._renderStats()}
      </div>
    )
  }
}

export default connect()(HostStats)
