import React from 'react'
import { Button } from 'react-md'
import Checkbox from 'react-md/lib/SelectionControls/Checkbox'
import { withRouter } from 'react-router'

import { getUser } from '../../utils/Auth'
import { getFeatureFlags } from '../../utils/featureFlag'

class OpenBetaBanner extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      doNotShow: false,
      open: false
    }
  }

  componentWillMount() {
    this.unListen = this.props.history.listen((location) => {
      if (['/login', '/signup'].includes(location.pathname)) {
        this.setState({ open: false })
        document.getElementById('mainContainer').classList.remove('hf-banner')
      }
    })
  }

  componentWillUnmount() {
    this.unListen()
  }

  async componentDidMount() {
    const user = getUser()
    const flag = localStorage.getItem('hf_beta_invite')

    if (
      window.isIframe ||
      !user ||
      flag === 'false' ||
      (!window.location.pathname.includes('/host') &&
        window.location.pathname !== '/')
    )
      return

    const { gbHostOpenBeta } = await getFeatureFlags(['gbHostOpenBeta'])

    if (flag === 'true' && gbHostOpenBeta.includes(user.email)) {
      window.location.href =
        process.env.REACT_APP_V3_BASE_URL + window.location.pathname
      return
    }

    if (!flag && gbHostOpenBeta.includes(user.email)) {
      this.setState({ open: true })
      document.getElementById('mainContainer').classList.add('hf-banner')
    }
  }

  saveFlag = () => {
    localStorage.setItem('hf_beta_invite', true)
    this.setState({ open: false })
    window.location.href = process.env.REACT_APP_V3_BASE_URL
  }

  handleDismiss = () => {
    this.setState({ open: false })
    if (this.state.doNotShow) {
      localStorage.setItem('hf_beta_invite', false)
    }
    document.getElementById('mainContainer').classList.remove('hf-banner')
  }

  toggleShow = () => {
    this.setState({ doNotShow: !this.state.doNotShow })
  }

  render() {
    return (
      <div
        className={`gbHostOpenBetaBanner ${this.state.open ? 'open' : 'close'}`}
      >
        <div className="gbHost-title">
          <span role="img" aria-label="party popper emoji">
            🎉
          </span>{' '}
          Exciting News!
        </div>
        <div className="gbHost-content">
          We’re thrilled to announce the redesigned Guidebooks Host app is ready
          for testing, and <strong>YOU</strong> have been invited to try it out.
        </div>

        <Checkbox
          id="doNotShowAgain"
          name="doNotShowAgain"
          label="Do not show again"
          checked={this.state.doNotShow}
          onChange={this.toggleShow}
        />
        <Button className="outlined" onClick={this.handleDismiss} flat>
          Dismiss
        </Button>
        <Button onClick={this.saveFlag} raised>
          Try new version
        </Button>
      </div>
    )
  }
}

export default withRouter(OpenBetaBanner)
