import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import { Button, DialogContainer, FontIcon, Paper, Toolbar } from 'react-md'
import { connect } from 'react-redux'
import { actions, Errors, Form } from 'react-redux-form'
import { replace } from 'react-router-redux'

import InputTextField from 'components/InputTextField'
import SelectForm from 'components/SelectForm'
import TooltipButton from 'components/TooltipButton'
import { fetchItems, saveItem, showItems } from 'redux/modules/crud'
import blankEditStates from 'redux/modules/crud/blankEditStates'
import currentUser from 'utils/CurrentUser'
import { fieldHasContent, requiredIf } from 'utils/ValidationHelpers'

import ManageSelectedGuidebooks from './ManageSelectedCards'

class DomainForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmStep: false,
      confirmActions: [],
      confirmTitle: 'Are you sure?',
      confirmMessage: null
    }
    this.back = this.back.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
  }

  back = () => {
    if (window.isIframe) {
      window.history.back()
    } else {
      const { dispatch, pluralName } = this.props
      dispatch(showItems(pluralName))
    }
  }

  handleEnter = (e) => {
    const { dispatch } = this.props
    e.preventDefault()
    dispatch(actions.submit('edit_domain'))
  }

  showHelpPage = (helpUrl) => {
    window.open(helpUrl, 'newExternalTab')
  }

  componentDidMount() {
    const { dispatch, itemId } = this.props
    dispatch(fetchItems('guidebooks'))
    if (itemId === 'create') {
      const newItem = blankEditStates.domain.data
      dispatch(actions.load('edit_domain', newItem))
    }
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, edit_domain } = this.props
    const nextDomain = nextProps.edit_domain
    // make sure this user can edit this item
    if (!edit_domain.id && nextDomain.id) {
      const user = currentUser()
      // admins can edit domains that are not theirs
      const canEdit = user.id === nextDomain.user_id || user.isAdmin
      if (!canEdit) {
        dispatch(replace('/403'))
      }
    }
  }

  handleSubmit(item) {
    const { itemId, guidebookId, dispatch } = this.props
    const addAnother = false

    const currentGuidebook = guidebookId ? this.props.owner_data : null
    dispatch(
      saveItem('domains', 'domain', itemId, item, addAnother, currentGuidebook)
    )
  }

  showConfirmation = (step) => {
    const self = this
    if (step === 'verify') {
      const confirmActions = []
      confirmActions.push(
        <Button flat primary onClick={self.hideConfirmation}>
          Cancel
        </Button>
      )
      confirmActions.push(
        <Button
          flat
          primary
          onClick={() => {
            self.submitConfirmation(step)
          }}
        >
          Continue
        </Button>
      )
      this.setState({
        confirmStep: true,
        confirmActions: confirmActions,
        confirmTitle: 'Confirmation',
        confirmMessage: 'Did you add this TXT record to your DNS?'
      })
    } else if (step === 'redirection') {
      const confirmActions = []
      confirmActions.push(
        <Button flat primary onClick={self.hideConfirmation}>
          Cancel
        </Button>
      )
      confirmActions.push(
        <Button
          flat
          primary
          onClick={() => {
            self.submitConfirmation(step)
          }}
        >
          Continue
        </Button>
      )
      this.setState({
        confirmStep: true,
        confirmActions: confirmActions,
        confirmTitle: 'Confirmation',
        confirmMessage: 'Did you add these A and AAAA records to your DNS?'
      })
    }
  }

  hideConfirmation = () => {
    this.setState({ confirmStep: false })
  }

  submitConfirmation = (step) => {
    const { edit_domain, itemId, dispatch } = this.props
    if (step === 'verify') {
      const item = Object.assign({}, edit_domain, { status: 'verifying' })
      dispatch(saveItem('domains', 'domain', itemId, item, false))
    } else if (step === 'redirection') {
      const item = Object.assign({}, edit_domain, { status: 'approved' })
      dispatch(
        saveItem('domains', 'domain', itemId, item, null, null, null, true)
      )
    }
  }

  domainIsValid = (str) => {
    const regex = /^([a-zA-Z0-9-]+\.){2,}[a-zA-Z]{2,}$/

    return !str || regex.test(str)
  }

  render() {
    const { edit_domain, guidebookId } = this.props
    // special case we use all locales for domains
    const self = this
    const title = edit_domain.id ? 'Edit domain' : 'New domain'
    const nav = (
      <TooltipButton
        key="nav"
        onClick={this.back}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>keyboard_backspace</FontIcon>
      </TooltipButton>
    )
    const domainHelpUrl =
      'http://help.hostfully.com/en/articles/4998353-using-your-own-custom-domain-for-guidebooks'
    const actions = [
      <TooltipButton
        key="help"
        onClick={() => {
          self.showHelpPage(domainHelpUrl)
        }}
        tooltipLabel="Help"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>help</FontIcon>
      </TooltipButton>
    ]

    const user = currentUser()
    const validators = {
      domain: {
        required: (val) => val && val.length,
        invalid: (val) => this.domainIsValid(val)
      },
      txt_record: {
        required: (val) =>
          requiredIf(
            edit_domain.status === 'verification requested',
            val && fieldHasContent(val)
          )
      }
    }

    const formErrors = []
    const errorMessages = {
      domain: {
        required: 'Please provide a domain name',
        invalid:
          'Invalid characters - please enter a valid domain and subdomain'
      },
      txt_record: {
        required: 'Please enter the TXT string for DNS'
      }
    }
    formErrors['domain'] = (
      <Errors
        model=".domain"
        messages={errorMessages.domain}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['txt_record'] = (
      <Errors
        model=".txt_record"
        messages={errorMessages.txt_record}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )

    const status_data = [
      { name: 'pending', id: 'pending' },
      { name: 'verification requested', id: 'verification requested' },
      { name: 'verifying', id: 'verifying' },
      { name: 'verified', id: 'verified' },
      { name: 'approved', id: 'approved' }
    ]
    const adminView = user.isAdmin && edit_domain.user_id !== user.id
    const status_disabled = !user.isAdmin
    const name_disabled = !adminView && !(edit_domain.status === 'pending')
    const marginStyle = { marginBottom: '20px' }
    const thStyle = {
      padding: '4px 8px',
      textAlign: 'left',
      borderBottom: '1px solid black'
    }
    const cellStyle = { padding: '4px 8px', textAlign: 'left' }
    const altCellStyle = {
      padding: '4px 8px',
      textAlign: 'left',
      backgroundColor: '#F3F3F3'
    }

    let nextSteps = null
    let cta = (
      <div className="hf-sticky">
        <Button type="submit" primary raised>
          Save domain
        </Button>
      </div>
    )

    if (edit_domain.status === 'pending' && !adminView) {
      nextSteps = (
        <div className="md-cell md-cell--12">
          <h1 className="md-headline">Next Steps</h1>
          <h4>
            An administrator will be reviewing your request. We will be in
            contact soon.
            <br />
            For the next step you'll need access to your domain's DNS settings
            to add a TXT record for verification.
          </h4>
        </div>
      )
    }
    if (edit_domain.status === 'verification requested' && adminView) {
      nextSteps = (
        <div className="md-cell md-cell--12">
          <h1 className="md-headline">TXT record</h1>
          <p>Enter verification string for DNS TXT record.</p>
          <InputTextField
            model=".txt_record"
            id="txt_record"
            label="TXT record string"
          />
          {formErrors['txt_record']}
        </div>
      )
    }
    if (edit_domain.status === 'verification requested' && !adminView) {
      const domain_parts = edit_domain.domain.split('.')
      const host = domain_parts.length > 2 ? domain_parts[0] : '@'
      const value = edit_domain.txt_record
      nextSteps = (
        <div className="md-cell md-cell--12">
          <h1 className="md-headline">Next Steps</h1>
          <p>
            To proceed, you'll need access to your domain's DNS settings (or the
            help of someone who has access).
          </p>
          <p>The first required step is domain validation.</p>
          <p>
            To validate your domain, please add the following information as a
            TXT record for the requested subdomain:
          </p>
          <div style={marginStyle}>
            <table cellSpacing="0">
              <thead>
                <tr>
                  <th style={thStyle}>record type</th>
                  <th style={thStyle}>Value/Content</th>
                  <th style={thStyle}>Name/Host/Alias</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={cellStyle}>TXT</td>
                  <td style={cellStyle}>{value}</td>
                  <td style={cellStyle}>{host}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            If you need some assistance, please contact{' '}
            <a href="mailto:support@hostfully.com">support@hostfully.com</a>
          </p>
        </div>
      )
      cta = (
        <div className="hf-sticky hf-form-errors">
          <Button
            raised
            primary
            onClick={() => {
              this.showConfirmation('verify')
            }}
          >
            I have done this
          </Button>
        </div>
      )
    }
    if (edit_domain.status === 'verifying' && adminView) {
      nextSteps = (
        <div className="md-cell md-cell--12">
          <h1 className="md-headline">Check Verification</h1>
          <p>
            Check verification in Cloud console for {edit_domain.domain} and
            then change status to verified.
          </p>
        </div>
      )
    }
    if (
      ['verifying', 'verified', 'approved'].indexOf(edit_domain.status) !==
        -1 &&
      !adminView
    ) {
      const domain_parts = edit_domain.domain.split('.')
      const host = domain_parts.length > 2 ? domain_parts[0] : '@'

      let statusText = null
      if (edit_domain.status === 'verifying') {
        statusText =
          'An administrator will be reviewing your request. We will be in contact soon.'
      } else if (edit_domain.status === 'verified') {
        statusText = 'Your domain has been verified. Only one step left!'
      } else {
        statusText =
          'All set, now we wait! DNS settings can take up to 24 hours to propagate, but generally happen much faster.'
      }
      let nextText = null
      if (edit_domain.status === 'verifying') {
        nextText =
          "For the next step you'll need access to your domain's DNS settings again, and you can do it ahead of time, if you wish."
      } else if (edit_domain.status === 'verified') {
        nextText =
          "For the next step you'll need access to your domain's DNS settings again."
      } else {
        nextText =
          'You should now be able to assign this domain to your guidebook(s).'
      }

      let stepText = null
      if (edit_domain.status === 'verifying') {
        stepText =
          "Once your domain's validation is confirmed, all you'll need to do is point it to our servers."
      } else if (edit_domain.status === 'verified') {
        stepText = 'All you need to do now is point your domain to our servers.'
      } else {
        stepText =
          "We'll leave these DNS settings here so you can verify them if needed in the future"
      }
      nextSteps = (
        <div className="md-cell md-cell--12">
          <h1 className="md-headline">Next Steps</h1>
          <p>{statusText}</p>
          <p>{nextText}</p>
          <p>
            {stepText}
            <br />
            This requires <strong>four</strong> A records and{' '}
            <strong>four</strong> AAAA records as shown below.
            <br />
          </p>
          <div style={marginStyle}>
            <table cellSpacing="0">
              <thead>
                <tr>
                  <th style={thStyle}>record type</th>
                  <th style={thStyle}>Name/Host/Alias</th>
                  <th style={thStyle}>Value/Content</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={cellStyle}>A</td>
                  <td style={cellStyle}>{host}</td>
                  <td style={cellStyle}>216.239.32.21</td>
                </tr>
                <tr>
                  <td style={altCellStyle}>A</td>
                  <td style={altCellStyle}>{host}</td>
                  <td style={altCellStyle}>216.239.34.21</td>
                </tr>
                <tr>
                  <td style={cellStyle}>A</td>
                  <td style={cellStyle}>{host}</td>
                  <td style={cellStyle}>216.239.36.21</td>
                </tr>
                <tr>
                  <td style={altCellStyle}>A</td>
                  <td style={altCellStyle}>{host}</td>
                  <td style={altCellStyle}>216.239.38.21</td>
                </tr>
                <tr>
                  <td style={cellStyle}>AAAA</td>
                  <td style={cellStyle}>{host}</td>
                  <td style={cellStyle}>2001:4860:4802:32::15</td>
                </tr>
                <tr>
                  <td style={altCellStyle}>AAAA</td>
                  <td style={altCellStyle}>{host}</td>
                  <td style={altCellStyle}>2001:4860:4802:34::15</td>
                </tr>
                <tr>
                  <td style={cellStyle}>AAAA</td>
                  <td style={cellStyle}>{host}</td>
                  <td style={cellStyle}>2001:4860:4802:36::15</td>
                </tr>
                <tr>
                  <td style={altCellStyle}>AAAA</td>
                  <td style={altCellStyle}>{host}</td>
                  <td style={altCellStyle}>2001:4860:4802:38::15</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            If you need some assistance, please contact{' '}
            <a href="mailto:support@hostfully.com">support@hostfully.com</a>
          </p>
        </div>
      )
      if (edit_domain.status === 'verifying') {
        cta = null
      } else if (edit_domain.status === 'verified') {
        cta = (
          <div className="hf-sticky hf-form-errors">
            <Button
              onClick={() => {
                this.showConfirmation('redirection')
              }}
              primary
              raised
            >
              I have done this
            </Button>
          </div>
        )
      }
    }

    let name_field = null
    if (name_disabled) {
      name_field = <h3 className="md-color--primary">{edit_domain.domain}</h3>
    } else if (user.id === edit_domain.user_id) {
      name_field = [
        <p key="domain-prompt">
          Enter the full domain name (including subdomain) you wish to use. This
          must be a domain you already own.
          <br />
          Unless you are <strong>only</strong> using this domain for your
          guidebooks, you'll want to use a subdomain that you aren't using for
          anything else.
          <br />
          <br />
          For example, if your domain is <strong>sweetlodgings.com</strong>{' '}
          you'll want to use something like{' '}
          <strong>welcome.sweetlodgings.com</strong> or{' '}
          <strong>guidebook.sweetlodgings.com</strong>
          <br />
          <br />
          <a href="http://help.hostfully.com/en/articles/4998353-using-your-own-custom-domain-for-guidebooks">
            Further details and instructions can be found here
          </a>
        </p>,
        <InputTextField
          key="domain-name"
          model=".domain"
          id="domain"
          label="Domain name (with subdomain) *"
        />
      ]
    } else {
      name_field = (
        <InputTextField
          key="domain-name"
          model=".domain"
          id="domain"
          label="Domain name (with subdomain) *"
        />
      )
    }

    let guidebookConnections = null
    let defaultPath = null
    let defaultValue = null
    // only show guidebook connection form if the domain is this user's (not an admin viewing it)
    // and only if it's approved
    if (edit_domain.status === 'approved' && user.id === edit_domain.user_id) {
      guidebookConnections = (
        <div className="md-cell md-cell--12 hf-headline-margin">
          <ManageSelectedGuidebooks
            ownerPluralName="domains"
            ownerSingularName="domain"
            guidebookId={guidebookId}
            cardType="Domain"
            replaceWarning={true}
            active={true}
          />
        </div>
      )
      if (edit_domain.default_type === 0) {
        // if it's a "default guidebook" type show a list of guidebooks that are using this domain
        let gb_data = []
        if (this.props.guidebooks && this.props.guidebooks.data.length > 0) {
          gb_data = this.props.guidebooks.data.map(({ key, name }) => ({
            key,
            name: `${name} (${key})`
          }))
        }
        defaultValue = [
          <SelectForm
            key="defaultGuidebookKey"
            className="md-cell md-cell--6 md-cell--5-tablet"
            label="Choose which guidebook to load by default:"
            model={'edit_domain.default_path'}
            includeNone={false}
            itemLabel="name"
            itemValue="key"
          >
            {gb_data}
          </SelectForm>,
          <div
            key="defaultGuidebookSpacer"
            className="md-cell md-cell--6 md-cell--3-tablet"
          ></div>
        ]
      } else if (edit_domain.default_type === 1) {
        defaultValue = [
          <InputTextField
            key="defaultExternal"
            model=".default_path"
            id="defaultExternal"
            label="External URL"
            className="md-cell md-cell--6 md-cell--5-tablet"
          />,
          <div
            key="defaultURLSpacer"
            className="md-cell md-cell--6 md-cell--3-tablet"
          ></div>
        ]
      }

      defaultPath = (
        <div className="md-cell md-cell--12">
          <div className="md-grid md-grid--no-spacing">
            <div className="md-cell md-cell--12">
              <h1 className="md-headline">Domain Defaults</h1>
              <p>
                Where should this domain point if no guidebook key is provided?
                You can choose a guidebook as a default, or an external url
              </p>
            </div>
            <SelectForm
              className="md-cell md-cell--6 md-cell--5-tablet"
              label="Default for this domain"
              model={'edit_domain.default_type'}
              includeNone={false}
              itemLabel="name"
              itemValue="id"
            >
              {[
                { id: 0, name: 'Default Guidebook' },
                { id: 1, name: 'External URL' }
              ]}
            </SelectForm>
            <div className="md-cell md-cell--6 md-cell--3-tablet"></div>
            {defaultValue}
          </div>
        </div>
      )
    }

    return (
      <DocumentTitle title="Domain">
        <div className="hf-domains-paper">
          <Toolbar
            colored
            className="hf-edit-toolbar hf-domains"
            title={title}
            nav={nav}
            actions={actions}
          />
          <Paper key="domain" className="hf-form-wrapper">
            <Form
              model="edit_domain"
              onSubmit={(v) => this.handleSubmit(v)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  return this.handleEnter(e)
                }
              }}
              validators={validators}
            >
              <div className="md-grid">
                <div className="md-cell md-cell--12">
                  <h1 className="md-headline">Domain Name</h1>
                  {name_field}
                  {formErrors['domain']}
                </div>
                <div className="md-cell md-cell--12">
                  <h1 className="md-headline">Status</h1>
                  <SelectForm
                    className="md-cell md-cell--9 md-cell--5-tablet"
                    label="Status"
                    model=".status"
                    includeNone={false}
                    itemLabel="name"
                    itemValue="id"
                    disabled={status_disabled}
                  >
                    {status_data}
                  </SelectForm>
                </div>
                {nextSteps}
              </div>
              {guidebookConnections}
              {defaultPath}
              {cta}
            </Form>
          </Paper>
          <DialogContainer
            id="confirmNextStep"
            visible={this.state.confirmStep}
            onHide={() => {
              this.hideConfirmation()
            }}
            actions={this.state.confirmActions}
            portal={true}
            title={this.state.confirmTitle}
          >
            {this.state.confirmMessage}
          </DialogContainer>
        </div>
      </DocumentTitle>
    )
  }
}

DomainForm.propTypes = {}
function mapStateToProps(state, props) {
  const edit_domain = state.edit_domain
  const listData = state.list
  return {
    edit_domain,
    guidebooks: listData.guidebooks
  }
}

export default connect(mapStateToProps)(DomainForm)
