import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Card, CardText } from 'react-md'
import { ExpansionList, ExpansionPanel } from 'react-md'
import Media from 'react-md/lib/Media'

import ImageFallback from 'components/ImageFallback'
import HfGuidebookIcon from 'components/InlineSvg/HfGuidebookIcon'
import HfTemplateIcon from 'components/InlineSvg/HfTemplateIcon'

class TemplatePanel extends Component {
  constructor(props) {
    super(props)
    this.handleNew = this.handleNew.bind(this)
    this.handleNewFromTemplate = this.handleNewFromTemplate.bind(this)
    this.renderTemplateItems = this.renderTemplateItems.bind(this)
  }

  handleNew = (itemType) => {
    this.props.newCallBack(itemType)
  }

  handleNewFromTemplate = (templateId) => {
    this.props.newFromTemplateCallBack(templateId)
  }

  renderDescription = () => {
    const descriptionItems = []
    const { templates } = this.props
    const pStyle = { marginTop: '12px' }
    descriptionItems.push(
      <p style={pStyle} key="blankGB">
        To create a new guidebook from scratch, click on "Blank Guidebook"
        below.
      </p>
    )
    if (templates.length <= 0) {
      descriptionItems.push(
        <p key="newTemplate">
          You can also create a template, from which you can then make multiple
          guidebooks that share all of the information from the template. To do
          so, click the "Create Template" button below.
        </p>
      )
    } else {
      descriptionItems.push(
        <p key="gbFromTemplate">
          You can also make a guidebook using one of the templates you've
          created. To do so, click the desired template below.
        </p>
      )
    }

    return <div className="md-cell md-cell--12">{descriptionItems}</div>
  }

  renderTemplateItems = () => {
    const { templates } = this.props
    const listItems = []
    if (templates.length <= 0) {
      listItems.push(
        <Card key="tmp_blank" onClick={() => this.handleNew('templates')} raise>
          <Media aspectRatio="16-9">
            <img
              src="https://storage.googleapis.com/hostfully-wp-1/blank.gif"
              alt=""
            />
          </Media>
          <span className="hf-template-icon">
            <HfTemplateIcon width={64} height={64} fill="magenta" />
          </span>
          <CardText>Create Template</CardText>
        </Card>
      )
    } else {
      for (var i = 0; i < templates.length; i++) {
        const templateId = templates[i].id
        const fallbackImage = (
          <HfTemplateIcon width={64} height={64} fill="magenta" />
        )
        listItems.push(
          <Card
            key={'tmp_' + i}
            onClick={() => this.handleNewFromTemplate({ templateId })}
            raise
          >
            <Media aspectRatio="16-9">
              <ImageFallback
                src={templates[i].image}
                fallback={fallbackImage}
                alt=""
              />
            </Media>
            <CardText>{templates[i].name}</CardText>
          </Card>
        )
      }
    }
    return (
      <div className="hf-template-action-cards">
        <Card
          key="gb_blank"
          onClick={() => this.handleNewFromTemplate(null)}
          raise
        >
          <Media aspectRatio="16-9">
            <img
              src="https://storage.googleapis.com/hostfully-wp-1/blank.gif"
              alt=""
            />
          </Media>
          <span className="hf-template-icon">
            <HfGuidebookIcon width={64} height={64} fill="red" />
          </span>
          <CardText>Blank Guidebook</CardText>
        </Card>
        {listItems}
      </div>
    )
  }

  render() {
    return (
      <ExpansionList>
        <ExpansionPanel
          label="Create a new guidebook"
          className="hf-paper-templates"
          contentClassName="hf-template-wrapper"
          defaultExpanded
          footer={null}
        >
          {this.renderDescription()}
          {this.renderTemplateItems()}
        </ExpansionPanel>
      </ExpansionList>
    )
  }
}

TemplatePanel.propTypes = {
  templates: PropTypes.array,
  expandTemplatePanel: PropTypes.bool
}

export default TemplatePanel
