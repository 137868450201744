import React from 'react'

const ContentPaste = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`content_paste`}</title>
    <path d="M18.984 20.016V3.985h-1.969v3H6.984v-3H5.015v16.031h13.969zM12 2.016c-.563 0-.984.422-.984.984s.422.984.984.984.984-.422.984-.984-.422-.984-.984-.984zm6.984 0c1.078 0 2.016.891 2.016 1.969v16.031c0 1.078-.938 1.969-2.016 1.969H5.015c-1.078 0-2.016-.891-2.016-1.969V3.985c0-1.078.938-1.969 2.016-1.969h4.172C9.609.844 10.687 0 12 0s2.391.844 2.813 2.016h4.172z" />
  </svg>
)

export default ContentPaste
