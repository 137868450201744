import React, { Component } from 'react'
import { TextField } from 'react-md'
import { randomString } from 'utils/Strings'

class TextFieldForm extends Component {
  render() {
    const { value, max_length, ...rest } = this.props
    rest.value = value
    if (!value) {
      rest.value = ''
    }
    // hack to ditch a prop that doesn't play well when passed on
    delete rest.componentModel
    const maxLength = !max_length ? 240 : max_length
    return (
      <TextField
        block
        paddedBlock
        id={rest.id || randomString(8)}
        placeholder=""
        maxLength={maxLength === -1 ? null : maxLength}
        {...rest}
      />
    )
  }
}
export default TextFieldForm
