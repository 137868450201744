import React from 'react'

const Garage = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`garage`}</title>
    <path d="M12 2.7L1.3 6v16h4V9.3h13.3V22h4V6L12 2.7z" />
    <path d="M6 10h12v.7H6V10zM6 11.3h12v.7H6v-.7zM6 12.7h12v.7H6v-.7zM6 14h12v.7H6V14zM6 15.3h12v.7H6v-.7zM6 16.7h12v.7H6v-.7zM6 18h12v.7H6V18zM6 19.3h12v.7H6v-.7zM6 20.7h12v.7H6v-.7z" />
  </svg>
)

export default Garage
