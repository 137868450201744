import React from 'react'

const Skiing = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`skiing`}</title>
    <path d="M19 3a1.999 1.999 0 100 4 1.999 1.999 0 100-4zm-13.5.156l-.813 1.281 8.907 5.594V10L15 9.156c.02 1.16.55 2.278 1.563 2.875l.03.031 2.97 1.876 1.062-1.688-3-1.906h-.031c-.344-.196-.594-.645-.594-1.25V7.188v-.094C17 5.894 16.21 5 14.812 5c-1.644 0-2.976.93-3.75 1.656zM9.188 8.5l-.376.406c-1 1-.699 2.586.5 3.188l3.563 1.75-3.625 2.062-6.156-3.5-1 1.688 12.5 7.094c.8.5 1.82.718 2.719.718.898 0 1.699-.195 2.5-.593l1.593-.907-1-.406c-1.398.7-3.414.3-4.812-.5l-4.313-2.438 2.625-1.5h.031a2.377 2.377 0 001.126-1.843c.042-.703-.325-1.461-.97-1.906v-.032c-.023-.02-.038-.011-.062-.031a75.994 75.994 0 00-3.156-2.094c-.016-.011-.156-.094-.156-.094z" />
  </svg>
)

export default Skiing
