import { getToken } from 'utils/Auth'

import 'whatwg-fetch'

export function fetchExports(num) {
  const exportLimit = num || 25

  const url =
    process.env.REACT_APP_API_URL + '/api/v1/exports/recent/' + exportLimit

  const token = getToken()
  const config = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }

  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
}

export function generateExports(truncate) {
  const url = process.env.REACT_APP_API_URL + '/api/v1/admin/salesforce_exports'

  const token = getToken()
  const body = {
    truncate: truncate
  }
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }

  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
    .then((response) => {
      return response.data
    })
}
