import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import { replace } from 'react-router-redux'
import { Avatar, FontIcon, ListItem } from 'react-md'
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc'

import EmptyState from '../../EmptyState'

import { resizeImage } from 'utils/Image'

class ManageRecommendationsSelectedTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabIndex: 1,
      filter: ''
    }
    this.onSortEnd = this.onSortEnd.bind(this)
  }

  editItemFromGuidebook(id) {
    const { dispatch, ownerId, ownerSingularName, ownerPluralName } = this.props
    // BA. make sure that we don't reload/refresh the guidebook/template when going off to edit a card
    dispatch(actions.change(`edit_${ownerSingularName}.noReload`, true))
    let url = `/host/${ownerPluralName}/${ownerId}/recommendations/${id}`
    dispatch(replace(url))
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { selected_recommendations, singularName, dispatch } = this.props
    const ordered_recs = arrayMove(selected_recommendations, oldIndex, newIndex)
    dispatch(
      actions.change('edit_' + singularName + '.recommendations', ordered_recs)
    )
  }

  formatSecondary = (rec) => {
    let secondLine = null
    if (
      rec.address &&
      rec.address.locality &&
      rec.address.street &&
      rec.address.post_code
    ) {
      secondLine = `${rec.address.street_number ? rec.address.street_number + ' ' : ''}${rec.address.street}, ${rec.address.locality} (${rec.address.post_code})`
    } else if (rec.address && rec.address.locality && rec.address.street) {
      secondLine = `${rec.address.street}, ${rec.address.locality}`
    } else if (rec.address && rec.address.locality) {
      secondLine = rec.address.locality
    } else {
      secondLine = 'Locationless Recommendation'
    }
    if (rec.category && rec.category.name) {
      const catName =
        rec.category.name.charAt(0).toUpperCase() + rec.category.name.slice(1)
      const catColor = rec.category.color
        ? {
            backgroundColor: rec.category.color,
            color: '#ffffff',
            padding: '2px 6px',
            borderRadius: '5px'
          }
        : {}
      const wizColor = {
        backgroundColor: '#cccccc',
        color: '#ffffff',
        padding: '2px 6px',
        borderRadius: '5px'
      }
      const wizardSpan =
        rec.batch_ref && rec.batch_ref.startsWith('wizard') ? (
          <span>
            &nbsp;&nbsp;&nbsp;
            <span className="rec-list-category" style={wizColor}>
              wizard
            </span>
          </span>
        ) : null
      return (
        <div>
          <span>{secondLine}</span>
          <br />
          <span className={`rec-list-category ${catName}`} style={catColor}>
            {catName}
          </span>
          {wizardSpan}
        </div>
      )
    }
    return secondLine
  }

  render() {
    const self = this
    const { active, selected_recommendations } = this.props
    const SortableItem = SortableElement(({ rec }) => {
      const avatarColor = rec.category.color
        ? { borderColor: rec.category.color }
        : {}
      const resizedImage = resizeImage(rec.image, 50, 50, true)
      return (
        <ListItem
          key={rec.id}
          className="hf-recommendationslist-item"
          leftAvatar={
            <Avatar
              src={resizedImage}
              className="hf-recommendationslist-item hf-hideOnLessThanPhablet"
              onClick={(e) => {
                self.editItemFromGuidebook(rec.id)
              }}
              style={avatarColor}
              alt=""
            >
              {rec.name.charAt(0).toUpperCase()}
            </Avatar>
          }
          primaryText={rec.name}
          secondaryText={self.formatSecondary(rec)}
          rightIcon={<FontIcon>reorder</FontIcon>}
          threeLines
        />
      )
    })

    const SortableList = SortableContainer(({ recs }) => {
      return (
        <div>
          {recs.map((rec, index) => (
            <SortableItem key={`item-${index}`} index={index} rec={rec} />
          ))}
        </div>
      )
    })

    return (
      <div className="md-grid">
        {active &&
          (selected_recommendations.length > 0 ? (
            <div className="md-cell md-cell--12">
              <SortableList
                recs={selected_recommendations}
                onSortEnd={this.onSortEnd}
                distance={1}
              />
            </div>
          ) : (
            <EmptyState type="cards" />
          ))}
      </div>
    )
  }
}

ManageRecommendationsSelectedTab.propTypes = {
  active: PropTypes.bool.isRequired,
  ownerSingularName: PropTypes.string.isRequired,
  ownerPluralName: PropTypes.string.isRequired
}

function mapStateToProps(state, props) {
  const { ownerSingularName } = props
  const edit_item = state['edit_' + ownerSingularName]
  const selected_recommendations = edit_item.recommendations || []
  return {
    ownerId: edit_item.id,
    selected_recommendations: selected_recommendations
  }
}

export default connect(mapStateToProps)(ManageRecommendationsSelectedTab)
