import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Avatar, FontIcon, ListItem } from 'react-md'
import { matchData } from 'utils/Data'
import { allIcons } from 'constants/UIIcons'
import currentUser from 'utils/CurrentUser'

class CategoryListItem extends Component {
  render() {
    const { item, editItem, deleteItem, pluralName } = this.props
    // use first letter of name if no icon
    let avatarIcon = null
    if (item.name) {
      avatarIcon = item.name.charAt(0).toUpperCase()
    }
    if (item.icon) {
      let iconMatch = matchData(
        allIcons,
        'Icons',
        ['field', 'v1_name'],
        item.icon
      )
      if (iconMatch) {
        const IconComponent = iconMatch.icon
        avatarIcon = <IconComponent fill="#ffffff" />
      }
    }
    const avatarStyle = { backgroundColor: item.color || '#cccccc' }
    const user = currentUser()
    const typeSpan =
      item.type === 'recommendation' ? (
        <span className="cat-type-rec">recommendation</span>
      ) : (
        <span className="cat-type-hm">house manual</span>
      )
    const fullSecondary = (
      <div>
        <span>{item.description || ' '}</span>
        <br />
        {typeSpan}
      </div>
    )

    const categoryItem =
      (user.isAdmin && user.id === 1055) || !item.is_default ? (
        <ListItem
          onClick={editItem}
          className={'hf-list-item hf-' + pluralName + 'list-item'}
          leftAvatar={
            <Link
              to={'/host/' + pluralName + '/' + item.id}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <Avatar
                className={'hf-' + pluralName + 'list-item'}
                style={avatarStyle}
                alt=""
              >
                {avatarIcon}
              </Avatar>
            </Link>
          }
          primaryText={
            <Link
              to={'/host/' + pluralName + '/' + item.id}
              onClick={(e) => {
                e.stopPropagation()
              }}
              className="md-tile-text--primary md-text hf-fake-link"
            >
              {item.name}
            </Link>
          }
          secondaryText={fullSecondary}
          rightIcon={<FontIcon onClick={deleteItem}>delete</FontIcon>}
        />
      ) : (
        <ListItem
          className={'hf-list-item hf-' + pluralName + 'list-item'}
          leftAvatar={
            <Avatar
              className={'hf-' + pluralName + 'list-item'}
              style={avatarStyle}
              alt=""
            >
              {avatarIcon}
            </Avatar>
          }
          primaryText={item.name}
          secondaryText={fullSecondary}
          disabled={true}
          inkDisabled={true}
        />
      )

    return categoryItem
  }
}

CategoryListItem.propTypes = {
  item: PropTypes.object.isRequired
}
export default CategoryListItem
