import React from 'react'

const Restaurant = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`restaurant`}</title>
    <path d="M15.984 6c0-1.781 2.25-3.984 5.016-3.984v19.969h-2.484v-7.969h-2.531V6zm-4.968 3V2.016h1.969V9a4 4 0 01-3.75 3.984v9H6.751v-9c-2.109-.141-3.75-1.875-3.75-3.984V2.016h2.016V9h1.969V2.016h2.016V9h2.016z" />
  </svg>
)

export default Restaurant
