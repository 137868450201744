import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { OnAtLeastTablet, OnAtMostPhablet } from 'components/ScreenSize'
import { houseRulesShape } from 'constants/SharedPropTypes'
import { obfuscateID } from 'utils/Strings'

import HouseRulesItem from './HouseRulesItem'

const propTypes = {
  guidebook: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    host_user_id: PropTypes.number
  }),
  informations: houseRulesShape.isRequired,
  custom_color: PropTypes.string,
  tabKey: PropTypes.string,
  token: PropTypes.object
}

const defaultProps = {
  custom_color: 'rgb(255, 87, 34)',
  tabKey: 'house_manual',
  token: {}
}

class HouseRules extends Component {
  /* for phone, just render them all in order. */
  /* for tablet and up, split into two colums and fill columnwise */

  buildCards = (informations) => {
    return informations
      .filter(function(information) {
        return !information.meta_card
      })
      .map((information) => {
        let initVisible = false

        const matchParams = this.context.router.route.match.params
        if (obfuscateID(information.id) === matchParams.card_title) {
          initVisible = true
        }
        return (
          <HouseRulesItem
            key={information.id}
            tabKey={this.props.tabKey}
            guidebook={this.props.guidebook}
            initialVisible={initVisible}
            information={information}
            custom_color={this.props.custom_color}
            token={this.props.token}
          />
        )
      })
  }

  columniseCards = (allCards) => {
    const grid = {}
    allCards.forEach(function (card, idx) {
      const pos = idx % 2
      let gridArr = grid[pos]
      if (!gridArr) {
        gridArr = []
        grid[pos] = gridArr
      }
      gridArr.push(card)
    })
    return grid
  }

  render() {
    const { informations } = this.props
    const allCards = this.buildCards(informations)
    const columns = this.columniseCards(allCards)
    return (
      <div>
        <OnAtMostPhablet>
          <div className="md-grid">
            <div className="md-cell md-cell--12">
              <div className="md-grid md-grid--no-spacing">
                <div className="md-cell md-cell--4 md-cell--4-tablet md-cell--4-phone">
                  {allCards}
                </div>
              </div>
            </div>
          </div>
        </OnAtMostPhablet>
        <OnAtLeastTablet>
          <div className="md-grid">
            <div className="md-cell md-cell--6">{columns[0]}</div>
            <div className="md-cell md-cell--6">{columns[1]}</div>
          </div>
        </OnAtLeastTablet>
      </div>
    )
  }
}

HouseRules.propTypes = propTypes
HouseRules.defaultProps = defaultProps
HouseRules.contextTypes = {
  router: PropTypes.object
}

export default connect()(HouseRules)
