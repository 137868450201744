import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl, intlShape } from 'react-intl'
import { setLocale } from 'redux/modules/auth'
import cn from 'classnames'
import { withCookies } from 'react-cookie'
import { Button } from 'react-md'
// import { trackEvent, getHostIdAndKey } from 'utils/Segment';
import { nativeLanguageNames } from 'constants/UIConstants'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  // don't drop a cookie if user closes or chooses a language
  persistant: PropTypes.bool,
  // force the locale bar to display regardless of cookie settings
  forceShown: PropTypes.bool,
  // callback when the language interface is shown
  onShown: PropTypes.func,
  // callback when the language interface is hidden
  onHidden: PropTypes.func
}

const defaultProps = {
  persistant: false,
  forceShown: false,
  onShown: () => {},
  onHidden: () => {}
}

class LocaleSwitcher extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSwitcher: false,
      showAutoSwitchToast: false
    }
    this.setLanguage = this.setLanguage.bind(this)
    this.hideLocales = this.hideLocales.bind(this)
    this.showLocales = this.showLocales.bind(this)
  }

  componentDidMount() {
    // detect user language
    this.checkUserLocale()
    // on mounting, check cookie and decide whether to show the switcher
    const { cookies, guidebook, forceShown, persistant } = this.props
    const cookieLocale = cookies.get(`hf_lang_${guidebook.key}`)
    const gbHasMultiLang =
      this.props.guidebook.locales && this.props.guidebook.locales.length > 1
    if ((gbHasMultiLang && (!cookieLocale || persistant)) || forceShown) {
      this.showLocales()
    }
  }

  componentWillUpdate(nextProps, nextState) {
    // parent component can force the language bar back open with forceShown prop
    if (
      nextProps.forceShown !== this.props.forceShown &&
      nextProps.forceShown === true
    ) {
      this.clearLanguageCookie()
      this.showLocales()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // check if cookie changed
    if (prevProps.guidebook && prevProps.guidebook.key) {
      const cookieName = `hf_lang_${prevProps.guidebook.key}`
      const prevCookies = prevProps.allCookies
      const nowCookies = this.props.allCookies
      if (
        (typeof prevCookies[cookieName] === 'undefined' &&
          nowCookies[cookieName]) ||
        prevCookies[cookieName] !== nowCookies[cookieName]
      ) {
        // we've discovered an exiting language cookie.   set the locale
        const cookieLocale = nowCookies[cookieName]
        this.loadSavedLanguage(cookieLocale)
      }
    }
  }

  checkUserLocale() {
    const { cookies, guidebook, intl } = this.props
    const cookieLocale = cookies.get(`hf_lang_${guidebook.key}`)
    const userLocale = this.getUserLocale()
    // don't bother with auto setting if user has already manually chosen
    if (cookieLocale) {
      this.loadSavedLanguage(cookieLocale)
    } else {
      // if the user locale is supported by the guidebook, switch to it,
      // add a toast saying that we did so, but leave the locale switcher up.
      if (
        guidebook.locales.indexOf(userLocale) !== -1 &&
        userLocale !== intl.locale
      ) {
        this.autoSetLanguage(userLocale)
      } else {
        // just match on the prefix, default to 'en'
        let userPrefix = userLocale.split('-')[0] || 'en'
        for (let langIdx = 0; langIdx < guidebook.locales.length; langIdx++) {
          if (guidebook.locales[langIdx].split('-')[0] === userPrefix) {
            this.autoSetLanguage(guidebook.locales[langIdx])
            break
          }
        }
      }
    }
  }

  getUserLocale() {
    // look through these in the order listed
    // https://stackoverflow.com/questions/673905/best-way-to-determine-users-locale-within-browser
    const {
      userLanguage,
      languages,
      language,
      browserLanguage,
      systemLanguage
    } = navigator
    return (
      userLanguage ||
      (languages && languages.length ? languages[0] : language) ||
      browserLanguage ||
      systemLanguage
    )
  }

  hideLocales() {
    // hide the component itself
    this.setState({ showSwitcher: false, forceShown: false }, function () {
      // call the onHidden callback, if applicable
      if (typeof this.props.onHidden === 'function') {
        this.props.onHidden()
      }
    })
  }

  showLocales() {
    // show the component itself
    this.setState({ showSwitcher: true }, function () {
      // call the onShown callback, if applicable
      if (typeof this.props.onShown === 'function') {
        this.props.onShown()
      }
    })
  }

  autoSetLanguage(locale) {
    const self = this
    // OLD SEGMENT CODE
    // track this event in segment
    // let eventData = getHostIdAndKey(this.props.guidebook);
    // eventData.label = locale;
    // eventData.category = 'Guidebook';
    // trackEvent('languageAutoChanged', eventData, {}, function(){
    self.props.dispatch(setLocale(locale))
    // });
  }

  loadSavedLanguage(locale) {
    if (locale) {
      this.props.dispatch(setLocale(locale))
    }
  }

  setLanguage(locale) {
    const self = this
    const { guidebook, cookies } = this.props
    // OLD SEGMENT CODE
    // track this event in segment
    // let eventData = getHostIdAndKey(this.props.guidebook);
    // eventData.label = locale;
    // eventData.category = 'Guidebook';
    // trackEvent('languageChanged', eventData, {}, function(){
    // cookie lasts 6 months
    const expiresDate = new Date()
    expiresDate.setMonth(expiresDate.getMonth() + 6)
    const cookieOptions = {
      path: '/',
      expires: expiresDate,
      sameSite: 'strict'
    }
    cookies.set(`hf_lang_${guidebook.key}`, locale, cookieOptions)
    self.props.dispatch(setLocale(locale))
    // });
  }

  clearLanguageCookie() {
    const { cookies, guidebook } = this.props
    const cookieOptions = {
      path: '/',
      expires: -1,
      sameSite: 'strict'
    }
    cookies.remove(`hf_lang_${guidebook.key}`, cookieOptions)
  }

  renderLanguageButtons() {
    const self = this
    const { guidebook, intl } = this.props
    const locales = guidebook.locales ? guidebook.locales : []
    if (locales.indexOf('en-US') === -1) {
      locales.unshift('en-US')
    }
    const links = locales.map(function (locale) {
      let btnClass = cn(
        'language-button',
        intl.locale === locale ? 'active' : ''
      )

      return (
        <Button
          key={'loc-' + locale}
          className={btnClass}
          onClick={(e) => {
            self.setLanguage(locale)
            self.hideLocales()
          }}
          flat
          primary
        >
          {nativeLanguageNames[locale]}
        </Button>
      )
    })

    return links
  }

  renderCloseButton() {
    const self = this
    const { intl } = this.props
    return (
      <Button
        icon
        primary
        className="close-locales"
        onClick={(e) => {
          self.setLanguage(intl.locale)
          self.hideLocales()
        }}
      >
        close
      </Button>
    )
  }

  render() {
    const languageButtons = this.renderLanguageButtons()
    const closeButton = this.renderCloseButton()

    const showSwitcher = this.state.showSwitcher ? (
      <div className="locale-switcher-outer">
        <div className="locale-switcher-inner">
          <div className="locale-buttons">{languageButtons}</div>
          {closeButton}
        </div>
      </div>
    ) : null

    return showSwitcher
  }
}

LocaleSwitcher.propTypes = propTypes
LocaleSwitcher.defaultProps = defaultProps

function mapStateToProps(state, props) {
  return {
    intl: state.intl,
    guidebook: state.guidebook.data
  }
}

export default withCookies(connect(mapStateToProps)(injectIntl(LocaleSwitcher)))
