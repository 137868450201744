import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { defineMessages, injectIntl, intlShape } from 'react-intl'
import {
  Avatar,
  Divider,
  Drawer,
  FontIcon,
  ListItem,
  ListItemControl,
  Radio
} from 'react-md'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { nativeLanguageNames } from 'constants/UIConstants'
import { logoutUser, setLocale } from 'redux/modules/auth'
import currentUser from 'utils/CurrentUser'

class AccountDrawer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      visible: props.visible
    }

    this.formatMessage = props.intl.formatMessage
    this.messages = defineMessages({
      login: {
        id: 'ad.Login',
        defaultMessage: 'Login'
      },
      signup: {
        id: 'ad.Signup',
        defaultMessage: 'Sign up'
      },
      logout: {
        id: 'ad.Logout',
        defaultMessage: 'Logout'
      },
      home: {
        id: 'ad.Home',
        defaultMessage: 'Home'
      },
      account: {
        id: 'ad.account',
        defaultMessage: 'Manage account'
      },
      help: {
        id: 'ad.Help',
        defaultMessage: 'Help'
      },
      language: {
        id: 'ad.Language',
        defaultMessage: 'Language'
      },
      guest: {
        id: 'ad.Guest',
        defaultMessage: 'Guest'
      }
    })
  }

  handleDrawerVisibility = (visible) => {
    if (this.props.onVisibilityChange) {
      this.props.onVisibilityChange(visible)
    }
  }

  closeDrawer = () => {
    if (this.props.onClose) {
      this.props.onClose(false)
    }
  }

  logout = () => {
    this.props.dispatch(logoutUser())
  }

  login = () => {
    this.props.dispatch(push('/login'))
  }

  signup = () => {
    this.props.dispatch(push('/signup'))
  }

  goHome = () => {
    this.props.dispatch(push('/'))
  }

  hostAccount = () => {
    this.props.dispatch(push('/host/account'))
  }

  showSettings = () => {
    this.props.dispatch(push('/host/settings'))
  }

  showReferrals = () => {
    this.props.dispatch(push('/host/referrals'))
  }

  showWpAdmin = () => {
    window.open(process.env.REACT_APP_WORDPRESS_URL + '/wp-admin', '_blank')
  }

  showAccount = () => {
    //BA, for now just doing window.open as we want them to refresh this page when they get back
    window.open(process.env.REACT_APP_WORDPRESS_URL + '/account', '_self')
  }

  setLanguage = (locale) => {
    this.props.dispatch(setLocale(locale))
  }

  handleLanguageClick = () => {
    if (this.props.useLocaleSwitcher) {
      if (typeof this.props.onLanguageClick === 'function') {
        this.props.onLanguageClick()
      }
      this.closeDrawer()
    }
  }

  showUVWidget = () => {
    window.UserVoice.show()
  }

  goToForum = () => {
    window.open(
      'https://hostfully.uservoice.com/forums/932419-guidebook',
      '_blank'
    )
  }

  render() {
    const { auth, guidebook } = this.props
    const isHost = auth.isAuthenticated
    const locales = guidebook.data?.locales || ['en-US']
    const user = currentUser()

    const nestedNavItems = !this.props.useLocaleSwitcher
      ? locales.map((locale) => (
          <ListItemControl
            key={'loc-' + locale}
            secondaryAction={
              <Radio
                id={'loc-' + locale}
                name="languageRadio"
                value={locale}
                label={nativeLanguageNames[locale]}
                className="hf-account-drawer-radio"
                checked={this.props.intl.locale === locale}
                onChange={this.setLanguage}
                labelBefore
              />
            }
          />
        ))
      : null

    const languageLabel =
      this.props.intl.locale !== 'en-US'
        ? `${this.formatMessage(this.messages.language)} (Language)`
        : this.formatMessage(this.messages.language)

    const navListItems = [
      <ListItem
        key="li-avatar"
        className="hf-account-list-item"
        primaryText={user.user.email || 'Guest'}
        leftAvatar={<Avatar src={user.user.avatar} role="presentation" />}
        rightIcon={<FontIcon>arrow_forward</FontIcon>}
        onClick={this.closeDrawer}
      />,
      <Divider key="div1" />,
      user.isAdmin ? (
        <span key="li-admin">
          <ListItem primaryText={'WP Admin'} onClick={this.showWpAdmin} />
          <Divider key="div2" />
        </span>
      ) : null,
      user.canAccessApi ? (
        <span key="li-hostaccount">
          <ListItem primaryText={'API Access'} onClick={this.hostAccount} />
          <Divider key="div3" />
        </span>
      ) : null,
      <ListItem
        key="li-home"
        primaryText={this.formatMessage(this.messages.home)}
        onClick={this.goHome}
      />,
      <ListItem
        key="li-account"
        primaryText={this.formatMessage(this.messages.account)}
        onClick={this.showAccount}
      />,
      <ListItem
        key="li-settings"
        primaryText={'User Settings'}
        onClick={this.showSettings}
      />,
      <ListItem
        key="li-referrals"
        primaryText={'Refer a Friend'}
        onClick={this.showReferrals}
      />,
      <ListItem
        key="li-feedback"
        primaryText={'Feedback'}
        nestedItems={[
          <ListItem
            key="feedback-trigger"
            primaryText={'Give feedback'}
            onClick={this.showUVWidget}
          />,
          <ListItem
            key="feedback-link"
            primaryText={'Go to forum'}
            onClick={this.goToForum}
          />
        ]}
      />,
      <ListItem
        key="li-language"
        primaryText={languageLabel}
        nestedItems={nestedNavItems}
        onClick={this.handleLanguageClick}
      />,
      <Divider key="div4" />,
      <ListItem
        key="li-logout"
        leftIcon={
          <FontIcon key="authenticated">remove_circle_outline</FontIcon>
        }
        primaryText={this.formatMessage(this.messages.logout)}
        onClick={this.logout}
      />
    ].filter((item) => !!item)

    if (isHost) {
      return (
        <Drawer
          position="right"
          type={Drawer.DrawerTypes.TEMPORARY}
          onVisibilityChange={this.handleDrawerVisibility}
          visible={this.props.visible}
          className="md-toolbar-relative"
          navItems={navListItems}
        />
      )
    } else {
      return null
    }
  }
}

AccountDrawer.propTypes = {
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
  onVisibilityChange: PropTypes.func,
  onLanguageClick: PropTypes.func,
  useLocaleSwitcher: PropTypes.bool,
  intl: intlShape.isRequired
}

AccountDrawer.defaultProps = {
  useLocaleSwitcher: true
}

function mapStateToProps(state, props) {
  return {
    intl: state.intl,
    auth: state.auth,
    guidebook: state.guidebook
  }
}

export default connect(mapStateToProps)(injectIntl(AccountDrawer))
