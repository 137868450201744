import React from 'react'

const ChildFriendly = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`child_friendly`}</title>
    <path d="M17.016 20.016c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm-9 0c.844 0 1.5-.656 1.5-1.5s-.656-1.5-1.5-1.5-1.5.656-1.5 1.5.656 1.5 1.5 1.5zm11.297-4.125c.703.656 1.172 1.594 1.172 2.625a3.461 3.461 0 01-3.469 3.469 3.494 3.494 0 01-3.469-3h-2.109c-.234 1.688-1.641 3-3.422 3-1.922 0-3.516-1.547-3.516-3.469 0-1.313.75-2.484 1.828-3.094-.234-.328-2.109-4.406-2.109-4.406H2.016V9h3.469l.938 2.016h14.578c0 1.828-.656 3.516-1.688 4.875zM12.984 2.016C17.39 2.016 21 5.579 21 9.985h-8.016V2.016z" />
  </svg>
)

export default ChildFriendly
