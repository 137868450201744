import React from 'react'

const Note = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`note`}</title>
    <path d="M15 5.484v5.531h5.484zm6.984 4.5V18a1.981 1.981 0 01-1.969 1.969l-16.031.047c-1.078 0-1.969-.938-1.969-2.016V6c0-1.078.891-2.016 1.969-2.016h12z" />
  </svg>
)

export default Note
