import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { BottomNavigation, FontIcon } from 'react-md'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { labelMessages } from 'constants/UIConstants'

class BottomNav extends Component {
  constructor(props, context) {
    super(props)
    this.state = {
      activeIndex: props.activeIndex || this.getDefaultIndex(context)
    }
    this._navigateTo = this._navigateTo.bind(this)
  }

  getDefaultIndex(context) {
    if (context.router.route.match.url.indexOf('/map') !== -1) {
      return 1
    }
    return 0
  }

  _navigateTo(index) {
    if (index === 2) {
      // show the Settings drawer by calling the callback function provided
      this.props.showSettings()
    } else {
      const path = index === 0 ? '' : 'map'
      const baseUrl = this.buildBaseGuidebookUrl()
      this.props.dispatch(push(`${baseUrl}/${path}`))
      this.setState({
        activeIndex: index
      })
    }
  }

  buildBaseGuidebookUrl = () => {
    const { recsOnly, guidebook } = this.props
    if (recsOnly && guidebook.recs_only_enabled && guidebook.recs_only_key) {
      return `/recs/${guidebook.recs_only_key}`
    } else {
      return `/${guidebook.key}`
    }
  }

  render() {
    const {
      intl: { formatMessage },
      hideSettings
    } = this.props
    const bottomNavLinks = [
      {
        label: formatMessage(labelMessages.nav_list_view),
        icon: <FontIcon>view_list</FontIcon>
      },
      {
        label: formatMessage(labelMessages.nav_map_view),
        icon: <FontIcon>map</FontIcon>
      },
      {
        label: formatMessage(labelMessages.nav_settings),
        icon: <FontIcon>settings</FontIcon>
      }
    ]

    if (hideSettings) {
      bottomNavLinks.pop()
    }

    return (
      <BottomNavigation
        key="mobile-drawer"
        links={bottomNavLinks}
        activeIndex={this.state.activeIndex}
        onNavChange={this._navigateTo}
        dynamic={false}
      />
    )
  }
}

BottomNav.propTypes = {
  activeIndex: PropTypes.number,
  recsOnly: PropTypes.bool
}
BottomNav.defaultProps = {
  count: 3,
  recsOnly: false
}
BottomNav.contextTypes = {
  router: PropTypes.object
}

export default connect(null)(injectIntl(BottomNav))
