import PropTypes from 'prop-types'
import React from 'react'
import { Button, CircularProgress, Paper, TextField } from 'react-md'
import { connect } from 'react-redux'
import { actions, Control, Errors, Form } from 'react-redux-form'
import { isEmail } from 'validator'

import Alert from 'components/Alert'
import { PublicView } from 'components/PublicView'
import { loginUser } from 'redux/modules/auth'

const validators = {
  email: {
    required: (val) => val && val.length,
    isEmail: (val) => {
      if (val.length > 0) {
        return isEmail(val)
      }
      return true
    },
    isValid: (val) => {
      return true
    }
  },
  password: {
    required: (val) => val && val.length
  }
}
const errorMessages = {
  email: {
    required: 'Please provide an email address.',
    isEmail: (val) => `${val} is not a valid email.`
  },
  password: {
    required: 'Please enter your password.'
  },
  emailCombo: {
    isValid: () =>
      `Did you use a different address or password for your Hostfully account?`
  }
}

const EmailComponent = (props) => (
  <TextField
    {...props}
    id="em"
    type="email"
    placeholder="name@mail.com"
    className="hf-public-view-input"
  />
)

const PasswordComponent = (props) => (
  <TextField
    {...props}
    id="pwd"
    type="password"
    placeholder="Password"
    className="hf-public-view-input"
  />
)

const ErrorAlert = (props) => {
  return (
    <Alert
      {...props}
      type="error"
      message="Wrong email/password combination"
      description={props.children}
    />
  )
}

function Login({ dispatch, auth }) {
  const handleSubmit = (login) => {
    dispatch(actions.setPending('login.email', true))
    dispatch(loginUser(login))
  }

  const forgotPasswordUrl = `${process.env.REACT_APP_WORDPRESS_URL}/login?action=forgot_password`

  return (
    <PublicView showNavDrawer={false}>
      <div className="hf-public-view-welcome">
        <p className="hf-public-view-title">Welcome back!</p>
        <p className="hf-public-view-subtitle--large">
          As a Hostfully host, you're an important member of the Hostfully team.
        </p>
      </div>
      <Paper className="hf-public-view-card">
        <div>
          <h4 className="hf-public-view-title">Login</h4>
          <p className="hf-public-view-subtitle">Need a Hostfully account?</p>
          <a href="/signup" className="hf-public-view-link">
            Sign up here
          </a>
        </div>
        <Form
          model="login"
          onSubmit={handleSubmit}
          validators={validators}
          className="hf-public-view-form"
        >
          <div className="hf-public-view-form-container">
            <div className="hf-public-view-input-container">
              <label className="md-text">Email*</label>
              <Control.text model=".email" component={EmailComponent} />
              <Errors
                model=".email"
                messages={errorMessages.email}
                className="hf-public-view-input-error"
                show={(field) => field.touched && !field.focus}
              />
            </div>
            <div className="hf-public-view-input-container">
              <label className="md-text">Password*</label>
              <Control.text model=".password" component={PasswordComponent} />
              <Errors
                model=".password"
                messages={errorMessages.password}
                className="hf-public-view-input-error"
                show={(field) => field.touched && !field.focus}
              />
            </div>
          </div>
          <p className="hf-public-view-sup-link">
            Forgot your password?{' '}
            <a
              target="blank"
              href={forgotPasswordUrl}
              className="hf-public-view-link hf-public-view-link--small"
            >
              Reset it here
            </a>
            .
          </p>
          <Errors
            model=".email"
            messages={errorMessages.emailCombo}
            component={ErrorAlert}
          />
          <div className="hf-public-view-actions">
            <Button
              type="submit"
              className="hf-public-view-button"
              swapTheming
              primary
              flat
            >
              Login
            </Button>
            {auth.isFetching && (
              <CircularProgress key="progress" id="loader" scale={1.25} />
            )}
          </div>
        </Form>
      </Paper>
    </PublicView>
  )
}

Login.propTypes = {
  dispatch: PropTypes.func
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(Login)
