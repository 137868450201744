import React from 'react'

const Wine = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`wine`}</title>
    <path d="M4.688 3C4.188 4.102 4 5.387 4 6.688c0 4.703 3.07 8.636 7 9.218V20H7v2h10v-2h-4v-4.094c3.93-.582 7-4.515 7-9.219 0-1.3-.188-2.585-.688-3.687zm1.5 2h11.625c.101.602.187 1.086.187 1.688 0 .105.004.21 0 .312H8c0 3.316 1.137 5.547 2.531 6.75C7.934 12.945 6 10.074 6 6.687c0-.601.086-1.085.188-1.687z" />
  </svg>
)

export default Wine
