import { defineMessages } from 'react-intl'
const names = defineMessages({
  all: {
    id: 'gb.categoryAll',
    defaultMessage: 'All'
  },
  food: {
    id: 'gb.categoryFood',
    defaultMessage: 'Places to eat'
  },
  'non-food': {
    id: 'gb.categoryOther',
    defaultMessage: 'Other'
  },
  bars: {
    id: 'gb.categoryBars',
    defaultMessage: 'Bars'
  },
  activities: {
    id: 'gb.categoryActivities',
    defaultMessage: 'Activities'
  },
  attractions: {
    id: 'gb.categoryAttractions',
    defaultMessage: 'Attractions'
  },
  shopping: {
    id: 'gb.categoryShopping',
    defaultMessage: 'Shopping'
  },
  other: {
    id: 'gb.categoryOther',
    defaultMessage: 'Other'
  }
})

export default names
