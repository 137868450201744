import { push } from 'react-router-redux'

import { getToken } from 'utils/Auth'

export const LOADING = 'LOADING'
export const FETCH_SUCCESS = 'FETCH_SUCCESS'
export const API_ERROR = 'API_ERROR'

/* Reducers */
export default function buildReducer(initialState) {
  return function reducer(state = initialState, action = {}) {
    const { type, name } = action
    const data = {}

    switch (type) {
      case API_ERROR:
        data[name] = Object.assign({}, state[name], {
          hasErrored: action.hasErrored
        })
        return Object.assign({}, state, data)

      case LOADING:
        data[name] = Object.assign({}, state[name], {
          data: action.data || [],
          isLoading: action.isLoading
        })
        return Object.assign({}, state, data)

      case FETCH_SUCCESS:
        data[name] = Object.assign({}, state[name], {
          data: action.items,
          loaded: true
        })
        return Object.assign({}, state, data)

      default:
        return state
    }
  }
}

/* Actions */
export function showItems(pluralName) {
  return (dispatch) => {
    dispatch(push('/host/' + pluralName))
  }
}

export function errored(name, bool) {
  return {
    type: API_ERROR,
    name: name,
    hasErrored: bool
  }
}
export function loading(name, bool, data) {
  return {
    type: LOADING,
    name: name,
    isLoading: bool,
    data
  }
}
export function fetchSuccess(name, items) {
  return {
    type: FETCH_SUCCESS,
    name: name,
    items: items
  }
}

export function fetchCategoriesForUser(userId, successCallback) {
  const url =
    process.env.REACT_APP_API_URL + '/api/v1/categories_for_user/' + userId
  const name = 'categories'
  const token = getToken()
  const config = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }

  return (dispatch) => {
    dispatch(loading(name, true))
    fetch(url, config)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        dispatch(loading(name, false))
        return response
      })
      .then((response) => response.json())
      .then((listResponse) => {
        dispatch(fetchSuccess(name, listResponse.data))
        if (typeof successCallback === 'function') {
          successCallback(listResponse.data)
        }
      })
      .catch(() => dispatch(errored(name, true)))
  }
}

export function fetchItemsNoCache(name, successCallback) {
  let base = name
  if (name === 'bookingsyncrentals') {
    base = 'bookingsync/rentals'
  }
  if (name === 'orbirentalproperties') {
    base = 'orbirental/properties'
  }
  const url =
    process.env.REACT_APP_API_URL + '/api/v1/' + base + '?nocache=true'

  const token = getToken()
  const config = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }

  return (dispatch) => {
    dispatch(loading(name, true))
    fetch(url, config)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        dispatch(loading(name, false))
        return response.json()
      })
      .then((listResponse) => {
        dispatch(fetchSuccess(name, listResponse.data))
        if (typeof successCallback === 'function') {
          successCallback(listResponse.data)
        }
      })
      .catch(() => dispatch(errored(name, true)))
  }
}

export function fetchItems(name, successCallback) {
  let base = name
  if (name === 'bookingsyncrentals') {
    base = 'bookingsync/rentals'
  }
  if (name === 'orbirentalproperties') {
    base = 'orbirental/properties'
  }
  if (name === 'approveddomains') {
    name = 'domains'
    base = 'domains/approved'
  }

  const url = process.env.REACT_APP_API_URL + '/api/v1/' + base
  const token = getToken()
  const config = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }

  return (dispatch) => {
    dispatch(loading(name, true))

    fetch(url, config)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }

        dispatch(loading(name, false))
        return response.json()
      })
      .then((listResponse) => {
        dispatch(fetchSuccess(name, listResponse.data))

        if (typeof successCallback === 'function') {
          successCallback(listResponse.data)
        }
      })
      .catch(() => {
        dispatch(errored(name, true))
      })
  }
}

export function bulkFetch(names, successCallback) {
  const url = process.env.REACT_APP_API_URL + '/api/v1/'
  const token = getToken()
  const config = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }

  return async (dispatch) => {
    names.forEach((name) => dispatch(loading(name, true)))

    try {
      const response = await Promise.all(
        names.map((name) => fetch(url + name, config))
      )
      const data = await Promise.all(response.map((item) => item.json()))

      for (let i = 0; i < response.length; i++) {
        if (!response[i].ok) {
          throw Error(response[i].statusText)
        }
      }

      for (let n = 0; n < data.length; n++) {
        dispatch(loading(names[n], false, data[n].data))
      }

      successCallback()
    } catch (e) {
      dispatch(errored(names.join(' | '), true))
    }
  }
}
