import React from 'react'

const Keyboard = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`keyboard`}</title>
    <path d="M18.984 9.984V8.015h-1.969v1.969h1.969zm0 3v-1.969h-1.969v1.969h1.969zm-3-3V8.015h-1.969v1.969h1.969zm0 3v-1.969h-1.969v1.969h1.969zm0 4.032V15H8.015v2.016h7.969zm-9-7.032V8.015H5.015v1.969h1.969zm0 3v-1.969H5.015v1.969h1.969zm1.032-1.968v1.969h1.969v-1.969H8.016zm0-3v1.969h1.969V8.016H8.016zm3 3v1.969h1.969v-1.969h-1.969zm0-3v1.969h1.969V8.016h-1.969zm9-3c1.078 0 1.969.891 1.969 1.969v10.031a1.981 1.981 0 01-1.969 1.969H3.985a1.981 1.981 0 01-1.969-1.969V6.985c0-1.078.891-1.969 1.969-1.969h16.031z" />
  </svg>
)

export default Keyboard
