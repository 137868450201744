import Intercom, { shutdown, update } from '@intercom/messenger-js-sdk'

import currentUser from 'utils/CurrentUser'

const getBaseUser = (user) => {
  const crypto = require('crypto')
  const hash = crypto
    .createHmac('sha256', process.env.REACT_APP_INTERCOM_SECRET_KEY)
    .update(user.email)
    .digest('hex')

  return {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    company: user.company,
    created_at: user.created_at,
    user_hash: hash
  }
}

export const bootIntercom = (options) => {
  const overwriteOptions = options || {}
  const user = currentUser()

  // make sure we're not a "switch to" user
  if (user.id && !user.switchedTo) {
    let plan = 'Free'
    if (user.isEnterprise) {
      plan = 'Prime/Enterprise'
    } else if (user.isPro) {
      plan = 'Power Host'
    }

    const baseUser = {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      Product: 'Hostfully',
      plan: plan,
      ...getBaseUser(user.user)
    }

    const intercomUser = Object.assign(baseUser, overwriteOptions)

    Intercom(intercomUser)
  }
}

export const shutdownIntercom = () => {
  shutdown()
}

export const changeIntercomUser = (options) => {
  const overwriteOptions = options || {}
  const user = currentUser()

  let plan = 'Free'
  if (user.isEnterprise) {
    plan = 'Prime/Enterprise'
  } else if (user.isPro) {
    plan = 'Power Host'
  }

  const baseUser = {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    Product: 'Hostfully',
    plan: plan,
    ...getBaseUser(user.user)
  }

  const intercomUser = Object.assign(baseUser, overwriteOptions)

  shutdown()
  Intercom(intercomUser)
}

export const updateIntercom = (options) => {
  const overwriteOptions = options || {}
  const user = currentUser()
  const baseUser = getBaseUser(user.user)
  const intercomUser = Object.assign(baseUser, overwriteOptions)

  update(intercomUser)
}
