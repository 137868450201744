import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-md'

class Activate extends Component {
  render() {
    const { featureEnabled, buttonOnClick, cellCount } = this.props

    return featureEnabled ? (
      <div className={'md-cell md-cell--' + cellCount} key="activate-feature">
        <div className="md-grid md-grid--no-spacing">{this.props.children}</div>
      </div>
    ) : (
      <div
        className={'hf-print-inactive md-cell md-cell--' + cellCount}
        key="activate-feature"
      >
        <div className="md-grid md-grid--no-spacing">{this.props.children}</div>
        <Button
          onClick={buttonOnClick}
          iconChildren="account_circle"
          className="hf-print-activate"
          flat
          primary
        >
          Activate
        </Button>
      </div>
    )
  }
}

Activate.propTypes = {
  featureEnabled: PropTypes.bool,
  buttonOnClick: PropTypes.func.isRequired,
  cellCount: PropTypes.string
}

Activate.defaultProps = {
  featureEnabled: false,
  cellCount: '12'
}

export default Activate
