import React from 'react'

const CloudSunLightning = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`cloud-sun-lightning`}</title>
    <path d="M12.4 15.9l1.1-2.8h-2.8l-1.6 4.3h1.8l-1 2.9 4.3-4.3c0-.1-1.8-.1-1.8-.1zM9.9 18H6c-2 0-3.5-1.6-3.5-3.5 0-1.5.9-2.7 2.1-3.3v-.3c0-2.7 2.2-5 5-5 .4 0 .7 0 1.1.1.6-1.3 2-2.2 3.5-2.2 2.2 0 3.9 1.7 3.9 3.9v.5c.9.7 1.4 1.8 1.5 3 1.3.5 2.1 1.8 2.1 3.3 0 2-1.6 3.5-3.5 3.5h-5l-5 5 1.7-5zm7.4-10.2c0-1.8-1.4-3.3-3.2-3.3-1.2 0-2.3.7-2.8 1.7.9.3 1.7.9 2.2 1.7.6-.4 1.3-.6 2-.6.7.1 1.3.2 1.8.5zM14.1 1c-.2 0-.4.2-.4.4v1.4c0 .2.2.4.4.4s.4-.2.4-.4V1.4c0-.2-.2-.4-.4-.4zm4.8 2c-.1-.1-.4-.1-.5 0l-1 1c-.1.1-.1.4 0 .5.1.1.4.1.5 0l1-1c.1-.2.1-.4 0-.5zm2 4.8c0-.2-.2-.4-.4-.4h-1.4c-.2 0-.4.2-.4.4s.2.4.4.4h1.4c.2-.1.4-.3.4-.4zM9.4 3c-.1.1-.1.4 0 .5l1 1c.1.1.4.1.5 0 .1-.2.1-.4 0-.5l-1-1c-.2-.2-.4-.2-.5 0z" />
  </svg>
)

export default CloudSunLightning
