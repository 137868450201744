import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import { replace } from 'react-router-redux'
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc'
import { Avatar, FontIcon, ListItem } from 'react-md'

import EmptyState from '../../EmptyState'

import { resizeImage } from 'utils/Image'

class ManageInformationsSelectedTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabIndex: 1,
      filter: ''
    }
    this.onSortEnd = this.onSortEnd.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { selectedItems } = this.props
    //if selected items have loaded setState, otherwise this is the user changes to the model (ie activeTabIndex is 0)
    if (
      selectedItems &&
      nextProps.selectedItems &&
      selectedItems !== nextProps.selectedItems &&
      this.state.activeTabIndex === 1
    ) {
      const initialTabIndex = nextProps.selectedItems.length > 0 ? 1 : 0
      this.setState({
        activeTabIndex: initialTabIndex
      })
    }
  }

  editItemFromGuidebook(uid) {
    const { dispatch, ownerId, ownerSingularName, ownerPluralName } = this.props
    // BA. make sure that we don't reload/refresh the guidebook/template when going off to edit a card
    dispatch(actions.change(`edit_${ownerSingularName}.noReload`, true))
    let url = `/host/${ownerPluralName}/${ownerId}/marketplaceItems/${uid}`
    dispatch(replace(url))
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { selectedItems, onSort } = this.props
    const ordered_recs = arrayMove(selectedItems, oldIndex, newIndex)
    onSort(ordered_recs)
  }

  render() {
    const self = this
    const { active, selectedItems } = this.props
    const SortableItem = SortableElement(({ item }) => {
      const resizedImage = resizeImage(item.defaultPicture, 50, 50, true)
      return (
        <ListItem
          key={item.uid}
          className="hf-marketplacelist-item"
          leftAvatar={
            <Avatar
              src={resizedImage}
              className="hf-marketplacelist-item hf-hideOnLessThanPhablet"
              onClick={(e) => {
                self.editItemFromGuidebook(item.uid)
              }}
              title="Click to edit"
              alt=""
            >
              {item.title.charAt(0).toUpperCase()}
            </Avatar>
          }
          primaryText={item.title}
          secondaryText={item.description}
          rightIcon={<FontIcon>reorder</FontIcon>}
          threeLines
        />
      )
    })

    const SortableList = SortableContainer(({ items }) => {
      return (
        <div>
          {items.map((item, index) => (
            <SortableItem key={`item-${index}`} index={index} item={item} />
          ))}
        </div>
      )
    })

    return (
      <div className="md-grid">
        {active &&
          (selectedItems.length > 0 ? (
            <div className="md-cell md-cell--12">
              <SortableList
                items={selectedItems}
                onSortEnd={this.onSortEnd}
                distance={1}
              />
            </div>
          ) : (
            <EmptyState type="cards" />
          ))}
      </div>
    )
  }
}

ManageInformationsSelectedTab.propTypes = {
  active: PropTypes.bool.isRequired,
  ownerSingularName: PropTypes.string.isRequired,
  ownerPluralName: PropTypes.string.isRequired,
  selectedItems: PropTypes.array,
  onSort: PropTypes.func
}

function mapStateToProps(state, props) {
  const { ownerSingularName } = props
  const edit_item = state['edit_' + ownerSingularName]
  return {
    ownerId: edit_item.id
  }
}

export default connect(mapStateToProps)(ManageInformationsSelectedTab)
