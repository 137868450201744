import { Component } from 'react'
import { connect } from 'react-redux'

import { me } from 'redux/modules/auth'
import { isTokenExpired } from 'utils/Auth'

class InitUser extends Component {
  componentDidMount() {
    if (isTokenExpired() || window.location.search.includes('refreshuser')) {
      this.props.dispatch(me('/host/guidebooks'))
    }
  }

  render() {
    return this.props.children
  }
}

export default connect(null)(InitUser)
