import React from 'react'

const VolumeUp = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`volume_up`}</title>
    <path d="M14.016 3.234C18.047 4.125 21 7.734 21 12s-2.953 7.875-6.984 8.766v-2.063c2.906-.844 4.969-3.516 4.969-6.703s-2.063-5.859-4.969-6.703V3.234zM16.5 12a4.451 4.451 0 01-2.484 4.031V7.968c1.5.75 2.484 2.25 2.484 4.031zM3 9h3.984L12 3.984v16.031l-5.016-5.016H3v-6z" />
  </svg>
)

export default VolumeUp
