import React, { Component } from 'react'
import { connect } from 'react-redux'
import CopyToClipboard from 'react-copy-to-clipboard'
import {
  Button,
  CircularProgress,
  FontIcon,
  TextField,
  Toolbar
} from 'react-md'
import { addToast } from 'redux/modules/toast'
import { fetchToken } from 'redux/modules/token'
import currentUser from 'utils/CurrentUser'

//todo meli - only show paid content ot paid hosts and hook up function to create cards
class HostAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: null
    }
  }

  componentDidMount() {
    const self = this
    const user = currentUser()
    if (user.canAccessApi) {
      fetchToken().then((token) => {
        self.setState({ token: token })
      })
    }
  }

  tokenCopied = () => {
    this.props.dispatch(addToast('The API token has been clipboard.'))
  }

  renderApiAccess = () => {
    const user = currentUser()
    let apiInfo = null
    if (user.canAccessApi) {
      if (this.state.token === null) {
        // still loading
        apiInfo = <CircularProgress id="loading-tags" />
      } else {
        apiInfo = (
          <div>
            <strong>You have access to our API</strong>
            <p>
              In order to use our API you will require a Bearer Token in all of
              your requests
            </p>
            <p>
              Further instructions can be found here:{' '}
              <a href="https://gb-dev.hostfully.com/docs">
                https://gb-dev.hostfully.com
              </a>
            </p>
            <br />
            <label>
              <strong>Here is your Api Token (JWT)</strong>
            </label>
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--10 md-cell--6-tablet">
                <TextField
                  id="token"
                  onChange={() => {
                    /* do nothing */
                  }}
                  value={this.state.token}
                  rows={2}
                  helpText="Click the icon to the right to copy this token"
                  rightIcon={
                    <CopyToClipboard
                      className="show-pointer"
                      text={this.state.token}
                      onCopy={() => {
                        this.tokenCopied()
                      }}
                    >
                      <FontIcon>content_copy</FontIcon>
                    </CopyToClipboard>
                  }
                />
              </div>
            </div>
          </div>
        )
      }
    } else {
      apiInfo = (
        <div>
          <p>
            Only users with a Prime Subscription or higher are allowed access to
            our API
          </p>
          <Button onClick={this.upgrade} raised primary>
            Click to Upgrade
          </Button>
        </div>
      )
    }

    return (
      <div className="md-cell md-cell--12">
        <h3 className="md-title">API access</h3>
        {apiInfo}
      </div>
    )
  }

  upgrade = () => {
    const user = currentUser()
    if (user.guidebookLimit < 5) {
      window.location.href =
        process.env.REACT_APP_WORDPRESS_URL + '/upgrade-prime'
    } else {
      window.location.href =
        process.env.REACT_APP_WORDPRESS_URL + '/upgrade-enterprise'
    }
  }

  render() {
    return (
      <div className="hf-main-container hf-welcome">
        <Toolbar colored title="Account Information" />
        <div className="md-cell md-cell--10 md-cell--1-desktop-offset hf-md-padding-48">
          <div className="md-grid">{this.renderApiAccess()}</div>
        </div>
      </div>
    )
  }
}

HostAccount.propTypes = {}

export default connect()(HostAccount)
