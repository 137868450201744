import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Avatar, Button } from 'react-md'

import GuidebookClosedStep from './GuidebookClosedStep'

const propTypes = {
  step: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  enabled: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}
const defaultProps = {
  subtitle: ''
}

class GuidebookStepper extends PureComponent {
  renderExpander = () => {
    const expanderIcon = this.props.enabled
      ? this.props.open
        ? 'expand_less'
        : 'expand_more'
      : ''
    const expanderTooltip = this.props.open ? 'Hide Section' : 'Show Section'
    return (
      <div className="md-cell--1 md-text-right">
        <Button
          icon
          primary
          disabled={!this.props.enabled}
          tooltipLabel={expanderTooltip}
          onClick={() => {
            this.props.onClick(this.props.step)
          }}
        >
          {expanderIcon}
        </Button>
      </div>
    )
  }

  renderContent = () => {
    if (this.props.open) {
      return this.props.children
    } else {
      return <GuidebookClosedStep />
    }
  }

  render() {
    const { step, subtitle, title } = this.props
    const suffix = this.props.enabled ? 'secondary' : 'tertiary'
    const onClick = this.props.enabled
      ? () => {
          this.props.onClick(this.props.step)
        }
      : () => {}
    const cursorStyle = this.props.enabled ? { cursor: 'pointer' } : {}
    return (
      <div id={`guidebook_step_${step}`} className="step">
        <div>
          <div className="circle1">
            <Avatar suffix={suffix} onClick={onClick} style={cursorStyle}>
              {step}
            </Avatar>
          </div>
          <div className="line"></div>
        </div>
        <div>
          <div className="md-grid md-grid--no-spacing">
            <div
              onClick={onClick}
              style={cursorStyle}
              className="md-cell--11 md-cell--7-tablet md-cell--3-phone"
            >
              <div className="md-grid md-grid--no-spacing">
                <div className="md-cell md-cell--12 stepper-title">{title}</div>
                <div className="md-cell md-cell--12 stepper-subtitle">
                  {subtitle}
                </div>
              </div>
            </div>
            {this.renderExpander()}
          </div>
          {this.renderContent()}
        </div>
      </div>
    )
  }
}

GuidebookStepper.propTypes = propTypes
GuidebookStepper.defaultProps = defaultProps

export default GuidebookStepper
