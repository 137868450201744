import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl, intlShape } from 'react-intl'
import Print from './Print.js'
import { CircularProgress } from 'react-md'
import {
  resetPrintPreferencesState,
  initPrintPreferences
} from 'redux/modules/printPreferences'
import { fetchCategoriesForUser } from 'redux/modules/crud'
import { sortData } from 'utils/Data'

class PrintContainer extends Component {
  componentWillReceiveProps(nextProps) {
    const { guidebook, dispatch } = this.props
    if (guidebook.isLoading && !nextProps.guidebook.isLoading) {
      dispatch(
        fetchCategoriesForUser(nextProps.guidebook.host_user_id, (response) => {
          dispatch(initPrintPreferences())
        })
      )
    }
  }

  componentDidMount() {
    const { dispatch, guidebook } = this.props
    dispatch(
      fetchCategoriesForUser(guidebook.host_user_id, (response) => {
        dispatch(initPrintPreferences())
      })
    )
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch(resetPrintPreferencesState())
  }

  render() {
    const { dispatch, intl, guidebook, printPreferences, printReadyState } =
      this.props
    if (guidebook && guidebook.id) {
      return (
        <Print
          guidebook={guidebook}
          printPreferences={printPreferences}
          printReadyState={printReadyState}
          intl={intl}
          dispatch={dispatch}
        />
      )
    } else {
      return <CircularProgress id="loader" />
    }
  }
}

PrintContainer.propTypes = {
  guidebook: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string
  }),
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired
}

function mapStateToProps(state, props) {
  const printPreferences = state.printPreferences
  const printReadyState = state.printReadyState
  const listData = state.list
  return {
    guidebook: state.guidebook.data,
    categories: listData.categories.data
      ? sortData(listData.categories.data, 'order')
      : [],
    printPreferences,
    printReadyState
  }
}

export default connect(mapStateToProps)(injectIntl(PrintContainer))
