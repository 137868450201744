/*eslint no-unused-vars: ["off", { "ignoreRestSiblings": true }]*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions, Control } from 'react-redux-form'
import { SelectField } from 'react-md'

const propTypes = {
  includeNone: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}
const defaultProps = {
  includeNone: true,
  disabled: false,
  onChange: () => {}
}

class SelectForm extends Component {
  render() {
    const self = this
    const {
      itemLabel,
      itemValue,
      className,
      defaultValue,
      children,
      onChange,
      disabled
    } = this.props
    const selectNone = {}
    selectNone[itemLabel] = '-- NONE --'
    // using 0 here sets to 'none'. It seems react-md doesn't like null or undefined here but 0 works ok.
    selectNone[itemValue] = 0
    let items = []
    if (children && children.length) {
      items = children.map((item) => {
        let newItem = {}
        newItem[itemLabel] = item[itemLabel]
        newItem[itemValue] = item[itemValue]
        return newItem
      })
    }
    if (this.props.includeNone) {
      items.unshift(selectNone)
    }
    const select = (props) => {
      const { children, ...rest } = props
      return (
        <SelectField
          id={props.name}
          className={className}
          menuItems={items}
          defaultValue={defaultValue}
          itemLabel={itemLabel}
          itemValue={itemValue}
          {...rest}
        >
          {items}
        </SelectField>
      )
    }

    return (
      <Control.select
        label={this.props.label}
        model={this.props.model}
        component={select}
        onChange={(value) => {
          onChange(value)
        }}
        disabled={disabled}
      ></Control.select>
    )
  }
}

SelectForm.propTypes = propTypes
SelectForm.defaultProps = defaultProps

export default connect()(SelectForm)
