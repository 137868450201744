import { updateIntl } from 'react-intl-redux'
import { actions } from 'react-redux-form'
import { push } from 'react-router-redux'

import { messagesForLocale } from 'components/Translator/localeMessages'
import {
  clearAuth,
  getToken,
  getUser,
  isTokenExpired,
  saveAuth
} from 'utils/Auth'
import { trackFreeSignup } from 'utils/GTM'
import { psSignup } from 'utils/PartnerStack'

import { guidebookReset } from './guidebook.js'
import { resetPrintPreferencesState } from './printPreferences'

// import {identify, trackEvent} from 'utils/Segment';

// There are three possible states for our signup
// process and we need actions for each of them
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'

// There are three possible states for our signup
// process and we need actions for each of them
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const EXTERNAL_AUTH_FAILURE = 'EXTERNAL_AUTH_FAILURE'

export const ME_REQUEST = 'ME_REQUEST'
export const SWITCH_FAILURE = 'SWITCH_FAILURE'

// Three possible states for our logout process as well.
// Since we are using JWTs, we just need to remove the token
// from localStorage. These actions are more useful if we
// were calling the API to log the user out
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

const initialState = {
  isFetching: false,
  checkingToken: false,
  isAuthenticated: !isTokenExpired(),
  user: getUser()
}

/* Reducers */
// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        user: action.creds
      })
    case SIGNUP_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        errorMessage: '',
        jwt: action.jwt,
        user: action.user
      })
    case SIGNUP_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message
      })
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        user: action.creds
      })
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        checkingToken: false,
        errorMessage: '',
        jwt: action.jwt,
        user: action.user
      })
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        checkingToken: false,
        isAuthenticated: false,
        errorMessage: action.message
      })
    case EXTERNAL_AUTH_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        checkingToken: false,
        isAuthenticated: false,
        externalAuthMessage: action.message
      })
    case ME_REQUEST:
      return Object.assign({}, state, {
        checkingToken: true,
        isAuthenticated: false
      })
    case SWITCH_FAILURE:
      // leave the current user as-is
      return Object.assign({}, state, {
        isFetching: false,
        checkingToken: false,
        isAuthenticated: true,
        jwt: state.jwt,
        user: state.user,
        errorMessage: 'Unable to switch'
      })
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false
      })
    default:
      return state
  }
}

/* Actions */
function requestSwitch() {
  // we can use the same as ME_REQUEST as it behaves siimilarly
  return {
    type: ME_REQUEST,
    checkingToken: true,
    isAuthenticated: false
  }
}
function switchError(message) {
  return {
    type: SWITCH_FAILURE,
    checkingToken: false,
    message
  }
}
function requestMe() {
  return {
    type: ME_REQUEST,
    checkingToken: true,
    isAuthenticated: false
  }
}
function receiveMe(authData) {
  return {
    type: LOGIN_SUCCESS,
    checkingToken: false,
    isAuthenticated: true,
    user: authData.user,
    jwt: authData.jwt
  }
}

function meError(message) {
  return {
    type: LOGIN_FAILURE,
    checkingToken: false,
    isAuthenticated: false,
    message
  }
}

function requestSignup(email, password) {
  return {
    type: SIGNUP_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    email,
    password
  }
}

function requestLogin(email, password) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    email,
    password
  }
}

function receiveLogin(authData) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    user: authData.user,
    jwt: authData.jwt
  }
}

function receiveSignup(authData) {
  return {
    type: SIGNUP_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    user: authData.user,
    jwt: authData.jwt
  }
}

function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  }
}

function externalAuthError(message) {
  return {
    type: EXTERNAL_AUTH_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  }
}

function signupError(message) {
  return {
    type: SIGNUP_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  }
}

export function switchTo(id) {
  const token = getToken()
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    credentials: 'include'
  }

  return (dispatch) => {
    // We dispatch requestSwitch to kickoff the call to the API
    dispatch(requestSwitch())
    const failMessage = 'Unable to switch.'
    return handleSwitch(
      dispatch,
      config,
      process.env.REACT_APP_API_URL + '/api/v1/admin/switch_user/' + id,
      failMessage
    )
  }
}

export function switchBack(email) {
  const token = getToken()
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    credentials: 'include'
  }

  return (dispatch) => {
    // We dispatch requestSwitch to kickoff the call to the API
    dispatch(requestSwitch())
    const failMessage = 'Unable to switch.'
    return handleSwitch(
      dispatch,
      config,
      process.env.REACT_APP_API_URL + '/api/v1/admin/switch_back/' + email,
      failMessage
    )
  }
}

export function me(redirect) {
  const config = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  }

  return (dispatch) => {
    // We dispatch requestMe to kickoff the call to the API
    dispatch(requestMe())
    const failMessage = 'You are not logged in.'
    return handleMe(
      dispatch,
      config,
      process.env.REACT_APP_API_URL + '/api/v1/session',
      failMessage,
      redirect
    )
  }
}

// Calls the API to get a token and
// dispatches actions along the way
export function loginUser(login) {
  const email = login.email
  const password = login.password

  const config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      session: {
        email: email,
        password: password
      }
    }),
    credentials: 'include'
  }

  return (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(email, password))
    const failMessage =
      'Unable to login with this email address and password, sorry!'
    return handleAuth(
      dispatch,
      config,
      process.env.REACT_APP_API_URL + '/api/v1/session',
      failMessage
    )
  }
}

/**
 * Used to login via external provider (eg Orbirental)
 * @param provider - name of the provider, eg 'orbirental'
 * @param token - JWT including external provider UID
 * @returns {Function}
 */
export function externalLoginUser(provider, token) {
  const config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      session: {
        provider: provider,
        token: token
      }
    }),
    credentials: 'include'
  }

  return (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(null, null))
    const failMessage = 'Unable to complete authentication'
    return handleExternalAuth(
      dispatch,
      config,
      process.env.REACT_APP_API_URL + '/api/v1/session/external',
      failMessage
    )
  }
}

function handleMe(dispatch, config, url, failMessage, redirect) {
  return fetch(url, config)
    .then((response) =>
      response.json().then((data) => ({
        data,
        response
      }))
    )
    .then(({ data, response }) => {
      if (!response.ok) {
        // If there was a problem, we want to
        // dispatch the error condition
        dispatch(meError(failMessage))
      } else {
        const authData = data.data
        // If login was successful, set the token in local storage
        saveAuth(authData)
        // Dispatch the success action
        dispatch(receiveMe(authData))
        if (redirect) {
          dispatch(push(redirect))
        }
      }
    })
    .catch(function (err) {
      dispatch(meError(err))
    })
}

function handleSwitch(dispatch, config, url, failMessage) {
  return fetch(url, config)
    .then((response) =>
      response.json().then((data) => ({
        data,
        response
      }))
    )
    .then(({ data, response }) => {
      if (!response.ok) {
        // If there was a problem, we want to
        // dispatch the error condition
        dispatch(switchError(failMessage))
      } else {
        const authData = data.data
        // If login was successful, set the token in local storage
        saveAuth(authData)
        // Dispatch the success action
        dispatch(receiveMe(authData))
        dispatch(push('/host'))
      }
    })
    .catch(function (err) {
      dispatch(switchError(err))
    })
}

function handleAuth(dispatch, config, url, failMessage) {
  return fetch(url, config)
    .then((response) =>
      response.json().then((data) => ({
        data,
        response
      }))
    )
    .then(({ data, response }) => {
      if (!response.ok) {
        // If there was a problem, we want to
        // dispatch the error condition
        //todo make API actually return message as currently undefined is returned for user.message
        dispatch(loginError(failMessage))
        dispatch(
          actions.setValidity('login.email', {
            isValid: false
          })
        )
      } else {
        const authData = data.data
        // If login was successful, set the token in local storage
        saveAuth(authData)
        resetStateForNewUser(dispatch)
        // Dispatch the success action
        dispatch(actions.reset('signup'))
        dispatch(actions.reset('login'))
        dispatch(receiveLogin(authData))
        // OLD SEGMENT CODE identify();
      }
      dispatch(actions.setPending('login.email', false))
    })
    .catch((err) => console.log('Error: ', err))
}
function handleExternalAuth(dispatch, config, url, failMessage) {
  return fetch(url, config)
    .then((response) =>
      response.json().then((data) => ({
        data,
        response
      }))
    )
    .then(({ data, response }) => {
      if (!response.ok) {
        // If there was a problem, we want to
        // dispatch the error condition
        dispatch(externalAuthError(failMessage))
      } else {
        const authData = data.data
        // If login was successful, set the token in local storage
        saveAuth(authData)
        resetStateForNewUser(dispatch)
        // Dispatch the success action
        dispatch(receiveLogin(authData))
        // OLD SEGMENT CODE identify();
      }
    })
    .catch((err) => console.log('Error: ', err))
}

// Calls the API to create a new user and then logs them in
export function signupUser(signup) {
  const { firstName, lastName, company, email, password } = signup

  const config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      user: {
        first_name: firstName,
        last_name: lastName,
        company,
        email,
        password
      }
    }),
    credentials: 'include'
  }

  return (dispatch) => {
    // We dispatch requestSignup to kickoff the call to the API
    dispatch(requestSignup(email, password))

    return fetch(process.env.REACT_APP_API_URL + '/api/v1/users', config)
      .then((response) =>
        response.json().then((data) => ({
          data,
          response
        }))
      )
      .then(({ data, response }) => {
        if (!response.ok) {
          // const body = response.body;
          dispatch(signupError(signup, 'Unable to create an account, sorry!'))
          if (data.errors && data.errors.email) {
            dispatch(
              actions.setValidity('signup.email', {
                isAvailable: false
              })
            )
          } else {
            // just dispatch a generic error. This shouldn't happen
            dispatch(
              actions.setValidity('signup', {
                success: false
              })
            )
          }
        } else {
          // If signup was successful, dispatch the success action
          const authData = data.data
          // If login was successful, set the token in local storage
          saveAuth(authData)
          resetStateForNewUser(dispatch)
          // track signup in segment
          // trackConversionEvent(authData.user);
          // Dispatch the success action
          dispatch(receiveSignup(authData))
          trackFreeSignup()
          psSignup()
          dispatch(push('/getstarted'))
        }
      })
      .catch((err) => console.log('Error: ', err))
  }
}

function resetStateForNewUser(dispatch) {
  //and reset the state to remove any previous user's items
  dispatch(guidebookReset())
  dispatch(resetPrintPreferencesState())
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true
  }
}

function logoutError(message) {
  return {
    type: LOGOUT_FAILURE,
    isFetching: false,
    isAuthenticated: true,
    message
  }
}

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  }
}

export function logoutUser() {
  let config = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  }

  return (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogout())

    return fetch(process.env.REACT_APP_API_URL + '/api/v1/session', config)
      .then((response) =>
        response.json().then((logout) => ({
          logout,
          response
        }))
      )
      .then(({ logout, response }) => {
        if (!response.ok) {
          // If there was a problem, we want to
          // dispatch the error condition
          dispatch(logoutError(logout.message))
          return Promise.reject(logout)
        } else {
          // If logout was successful, reset token and user on localStorage
          clearAuth()
          resetStateForNewUser(dispatch)
          // Dispatch the success action
          dispatch(receiveLogout())
          dispatch(push('/login'))
        }
      })
      .catch((err) => console.log('Error: ', err))
  }
}

export function setLocale(locale) {
  const localeMessages = messagesForLocale(locale)
  // keep in local storage so we remember users preferences.
  localStorage.setItem('hf_locale', locale)
  return (dispatch) => {
    dispatch(
      updateIntl({
        locale: locale,
        messages: localeMessages
      })
    )
  }
}
