import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { Avatar, Card, CardTitle, List, ListItem, Subheader } from 'react-md'
import { connect } from 'react-redux'

import formatAddress from 'components/Address/FormatAddress'
import ListItemModal from 'components/ListItemModal'
import AsHtml from 'components/Translator/AsHtml'
import {
  addressDefaults,
  directionsDefaults,
  parkingDefaults
} from 'constants/SharedPropDefaults'
import {
  addressShape,
  directionsShape,
  parkingShape
} from 'constants/SharedPropTypes'
import { directionsIcons } from 'constants/UIConstants'
import { directionsMessages, hostNavMessages } from 'constants/UIConstants'
import { translateString } from 'utils/I18nHelper'
import { openMap } from 'utils/Map'
import { cleanUpTextOnly } from 'utils/Strings'

import PrintSubsection from '../../../PrintView/PrintSubsection'

const propTypes = {
  // address element defining listing address
  address: addressShape,
  custom_color: PropTypes.string,
  directions: directionsShape,
  guidebook: PropTypes.shape({
    host_user_id: PropTypes.number,
    id: PropTypes.number,
    key: PropTypes.string
  }),
  // helper for internationalization
  intl: intlShape.isRequired,
  parking: parkingShape,
  viewMode: PropTypes.string
}

const defaultProps = {
  viewMode: 'web',
  address: addressDefaults,
  directions: directionsDefaults,
  parking: parkingDefaults
}

class Directions extends PureComponent {
  _formatMessage = this.props.intl.formatMessage

  renderAddress = () => {
    const address = this.props.address
    // make sure we even have an address object to render...
    if (typeof address !== 'undefined' && Object.keys(address).length) {
      const { addressPrimary, addressSecondary } = formatAddress(address)
      if (this.props.viewMode === 'print') {
        return (
          <PrintSubsection
            header={this._formatMessage(directionsMessages.address)}
          >
            {addressPrimary}
            <br />
            {addressSecondary}
          </PrintSubsection>
        )
      } else {
        return (
          <ListItem
            leftIcon={directionsIcons.map}
            primaryText={addressPrimary}
            secondaryText={addressSecondary}
            onClick={(event) => openMap(event, address, true)}
            threeLines
          />
        )
      }
    }
  }

  renderParking = () => {
    // dont' show this section if null
    if (!this.props.parking) return null

    const matchParams = this.context.router.route.match.params
    const deepLinkedCard = matchParams.card_title
      ? matchParams.card_title
      : null
    const parking = this.props.parking
    // props.parking.show_parking_details isn't reliable
    if (typeof parking !== 'undefined') {
      // parkingHtml will hold an array of elements to output
      var parkingHtml = []
      var parkingString = ''

      // get values of the four static parking items
      var parkingItems = [
        'on_street_available',
        'driveway_available',
        'economical_available',
        'expensive_nearby'
      ]
      // thorugh these keys and ad to parkingHtml if set
      for (var i = 0, len = parkingItems.length; i < len; i++) {
        if (parking[parkingItems[i]]) {
          var itemText = this._formatMessage(
            directionsMessages[parkingItems[i]]
          )
          parkingHtml.push(
            <p
              className="md-text--secondary"
              key={`parking-${parkingItems[i]}`}
            >
              {itemText}
              <br />
            </p>
          )
          // also append text only version to parkingSTring
          parkingString += ` ${itemText}`
        }
      }

      // get any additional parking text
      if (parking.parking_text) {
        // add an extra break if not empty
        if (parkingHtml.length) {
          parkingHtml.push(<br key="parking-brake" />) // lol
        }

        parkingHtml.push(
          <p key="parking-text-custom" className="md-text--secondary">
            <AsHtml
              text={parking.parking_text}
              translations={parking.parking_text_txn}
            />
          </p>
        )
        var parkingTextString = translateString(
          this.props.intl.locale,
          parking.parking_text,
          parking.parking_text_txn
        )
        if (parkingTextString) {
          parkingString += ` ${parkingTextString}`
        }
      }
      const modalAvatarStyle = this.props.custom_color
        ? { background: this.props.custom_color }
        : {}
      if (parkingHtml.length) {
        if (this.props.viewMode === 'print') {
          return (
            <PrintSubsection
              header={this._formatMessage(directionsMessages.parking)}
            >
              {parkingHtml}
            </PrintSubsection>
          )
        } else {
          parkingString = cleanUpTextOnly(parkingString)
          return (
            <ListItemModal
              avatarStyle={modalAvatarStyle}
              baseLocation={`/${this.props.guidebook.key}/arrival`}
              cardTitle="parking"
              guidebook={this.props.guidebook}
              key="li_parking"
              leftIcon={directionsIcons.parking}
              modalVisible={deepLinkedCard === 'parking'}
              primaryText={this._formatMessage(directionsMessages.parking)}
              secondaryText={parkingString}
              tabName="arrival"
              threeLines
              trackLabel="parking"
            >
              {parkingHtml}
            </ListItemModal>
          )
        }
      }
    }
  }

  renderDirectionsItems = (directionKeys) => {
    // dont' show this section if null
    if (!this.props.directions) return null
    var itemsToRender = []
    for (var i = 0, len = directionKeys.length; i < len; i++) {
      var directionKey = directionKeys[i]
      if (
        this.props.directions[`show_${directionKey}`] !== 'undefined' &&
        this.props.directions[`show_${directionKey}`]
      ) {
        itemsToRender.push(this.renderDirectionsItem(directionKey))
      }
    }
    // if we have any items at all, add the divider first
    if (itemsToRender.length && this.props.viewMode !== 'print') {
      itemsToRender.unshift(
        <Subheader
          key="directions-subheader"
          primaryText={this._formatMessage(directionsMessages.recApproaches)}
        />
      )
    }
    return itemsToRender
  }

  renderDirectionsItem = (directionKey) => {
    const directions = this.props.directions
    const matchParams = this.context.router.route.match.params
    const deepLinkedCard = matchParams.card_title
      ? matchParams.card_title
      : null
    var itemContent = []
    var itemString = ''
    itemContent.push(
      <AsHtml
        key={`${directionKey}_text`}
        className="md-text--secondary"
        text={directions[`${directionKey}_text`]}
        translations={directions[`${directionKey}_text_txn`]}
      />
    )
    var itemText = translateString(
      this.props.intl.locale,
      directions[`${directionKey}_text`],
      directions[`${directionKey}_text_txn`]
    )
    itemString += itemText

    // add Uber/Lyft referral links if applicable
    if (directionKey === 'uber' && directions.uberpool_voucher !== '') {
      var uberBaseUrl = 'https://get.uber.com/invite/'
      var addBreaksUber =
        itemString !== ''
          ? [<br key={directionKey + '1'} />, <br key={directionKey + '2'} />]
          : null
      itemContent.push(
        <span key="uber-referral" className="md-text--secondary">
          {addBreaksUber}
          {itemString !== ''
            ? this._formatMessage(directionsMessages.uberReferralAlso) + ' '
            : this._formatMessage(directionsMessages.uberReferral)}
          <a href={uberBaseUrl + directions.uberpool_voucher}>Uber</a>
        </span>
      )
      if (itemString !== '') {
        itemString +=
          ' ' + this._formatMessage(directionsMessages.uberReferralAlso)
      } else {
        itemString += this._formatMessage(directionsMessages.uberReferral)
      }
      itemString += ' ' + uberBaseUrl + directions.uberpool_voucher
    } else if (directionKey === 'lyft' && directions.lyft_voucher !== '') {
      var lyftBaseUrl = 'https://www.lyft.com/invite/'
      var addBreaksLyft =
        itemString !== ''
          ? [<br key={directionKey + '1'} />, <br key={directionKey + '2'} />]
          : null
      itemContent.push(
        <span key="lyft-referral" className="md-text--secondary">
          {addBreaksLyft}
          {itemString !== ''
            ? this._formatMessage(directionsMessages.lyftReferralAlso) + ' '
            : this._formatMessage(directionsMessages.lyftReferral)}
          <a href={lyftBaseUrl + directions.lyft_voucher}>Lyft</a>
        </span>
      )
      if (itemString !== '') {
        itemString +=
          ' ' + this._formatMessage(directionsMessages.lyftReferralAlso)
      } else {
        itemString += this._formatMessage(directionsMessages.lyftReferral)
      }
      itemString += ' ' + lyftBaseUrl + directions.lyft_voucher
    }
    if (this.props.viewMode === 'print') {
      return (
        <PrintSubsection
          key={`li_${directionKey}`}
          header={this._formatMessage(directionsMessages[directionKey])}
        >
          {itemContent}
        </PrintSubsection>
      )
    } else {
      // strip tags from item string
      itemString = cleanUpTextOnly(itemString)
      const modalAvatarStyle = this.props.custom_color
        ? { background: this.props.custom_color }
        : {}
      return (
        <ListItemModal
          avatarStyle={modalAvatarStyle}
          baseLocation={`/${this.props.guidebook.key}/arrival`}
          cardTitle={directionKey}
          leftIcon={directionsIcons[directionKey]}
          guidebook={this.props.guidebook}
          key={`li_${directionKey}`}
          modalVisible={deepLinkedCard === directionKey}
          primaryText={this._formatMessage(directionsMessages[directionKey])}
          secondaryText={itemString}
          tabName="arrival"
          threeLines
          trackLabel={directionKey}
        >
          {itemContent}
        </ListItemModal>
      )
    }
  }

  renderOverviewDirections = () => {
    // dont' show this section if null
    if (!this.props.directions) return null
    if (this.props.directions.additional_directions) {
      const matchParams = this.context.router.route.match.params
      const deepLinkedCard = matchParams.card_title
        ? matchParams.card_title
        : null
      const itemHTML = []
      itemHTML.push(
        <AsHtml
          key="overview_text"
          className="md-text--secondary"
          text={this.props.directions.additional_directions}
          translations={this.props.directions.additional_directions_txn}
        />
      )
      if (this.props.viewMode === 'print') {
        return (
          <PrintSubsection
            header={this._formatMessage(
              directionsMessages.additional_directions
            )}
          >
            <AsHtml
              text={this.props.directions.additional_directions}
              translations={this.props.directions.additional_directions_txn}
            />
          </PrintSubsection>
        )
      } else {
        const itemString = cleanUpTextOnly(
          translateString(
            this.props.intl.locale,
            this.props.directions.additional_directions,
            this.props.directions.additional_directions_txn
          )
        )
        const modalAvatarStyle = this.props.custom_color
          ? { background: this.props.custom_color }
          : {}
        return [
          <ListItemModal
            avatarStyle={modalAvatarStyle}
            baseLocation={`/${this.props.guidebook.key}/arrival`}
            cardTitle="directions"
            guidebook={this.props.guidebook}
            key="li_overview"
            leftIcon={directionsIcons['overview']}
            modalVisible={deepLinkedCard === 'directions'}
            primaryText={this._formatMessage(hostNavMessages.directions)}
            secondaryText={itemString}
            tabName="arrival"
            trackLabel="directions overview"
            threeLines
          >
            {itemHTML}
          </ListItemModal>
        ]
      }
    }
  }

  render() {
    const directionKeys = [
      'airport',
      'taxi',
      'uber',
      'lyft',
      'train',
      'bus',
      'ferry',
      'pickup',
      'driving'
    ]
    if (this.props.viewMode === 'print') {
      // much simplified version for print
      return (
        <div>
          {this.renderAddress()}
          {this.renderOverviewDirections()}
          {this.renderParking()}
          {this.renderDirectionsItems(directionKeys)}
        </div>
      )
    } else {
      const avatarStyle = { background: this.props.custom_color || '#f44336' }
      return (
        <Card className="md-cell md-cell--12 arrival arrival-card" raise={true}>
          <CardTitle
            avatar={
              <Avatar
                icon={directionsIcons.place}
                style={avatarStyle}
                role="presentation"
              />
            }
            title={this._formatMessage(directionsMessages.gettingHere)}
            subtitle={this._formatMessage(directionsMessages.arrival)}
          />
          <List>
            {this.renderAddress()}
            {this.renderOverviewDirections()}
            {this.renderParking()}
            {this.renderDirectionsItems(directionKeys)}
          </List>
        </Card>
      )
    }
  }
}

Directions.propTypes = propTypes
Directions.defaultProps = defaultProps
Directions.contextTypes = {
  router: PropTypes.object
}

export default connect()(injectIntl(Directions))
