import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, injectIntl, intlShape } from 'react-intl'
import AsHtml from 'components/Translator/AsHtml'
import PrintContentBlock from 'components/Print/PrintContentBlock'
import { Media } from 'react-md'
import PrintReadyImage from 'components/Print/PrintReadyImage'

const propTypes = {
  host_intro: PropTypes.object,
  styles: PropTypes.object,
  token: PropTypes.object,
  intl: intlShape.isRequired
}
const defaultProps = {
  token: {}
}

class HostIntro extends Component {
  _formatMessage = this.props.intl.formatMessage

  _messages = defineMessages({
    welcome: {
      id: 'print.welcome',
      defaultMessage: 'Welcome'
    }
  })

  render() {
    const { host_intro, token } = this.props
    const variableData = { token: token }
    if (host_intro && host_intro.host_intro) {
      const imageUrl = host_intro.image
        ? host_intro.image
        : 'https://storage.googleapis.com/v2-static/user_profile.jpg'
      return (
        <PrintContentBlock
          title={this._formatMessage(this._messages.welcome)}
          avoidBreak={false}
          breakBefore={true}
          styles={this.props.styles ? this.props.styles : null}
        >
          <div className="print-house-rules">
            <AsHtml
              text={host_intro.host_intro}
              translations={host_intro.host_intro_txn || {}}
              className="print-house-rules"
              variableData={variableData}
            />
          </div>
          <div className="md-grid">
            <div className={'md-cell--2 md-cell--2-tablet md-cell--1-phone'}>
              <Media aspectRatio="1-1">
                <PrintReadyImage
                  imageId="host-image"
                  className="hf-host-img"
                  imageUrl={imageUrl}
                  imageRole="presentation"
                />
              </Media>
            </div>
            <div className="md-cell--10 md-cell--6-tablet md-cell--3-phone rec-content-cell">
              <div className="hf-print-host-name">
                {host_intro.host_name || 'Your Host'}
              </div>
              <div>{host_intro.host_phone}</div>
              <div>{host_intro.host_email}</div>
            </div>
          </div>
        </PrintContentBlock>
      )
    } else {
      return null
    }
  }
}

HostIntro.propTypes = propTypes
HostIntro.defaultProps = defaultProps

export default injectIntl(HostIntro)
