import React from 'react'

const Camping = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`camping`}</title>
    <path d="M12 3C6.906 15.406 5.719 17.836 4 19l-2 1v1l2-1v1h5s2.598-2.836 3-7c.438 4.18 3 7 3 7h5v-1l2 1v-1l-2-1c-1.719-1.164-3.125-3.656-8-16z" />
  </svg>
)

export default Camping
