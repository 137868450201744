import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

/*
HOC to wrap other components and inject a translator
 */
export default function TranslatorWrapper(WrappedComponent) {
  class TranslatorWrapped extends React.Component {
    render() {
      const { intl } = this.props
      const translator = (defaultText, translations) => {
        // first try and return text for the given locale, otherwise return the defaultText
        return translations[intl.locale] || defaultText || ''
      }
      return <WrappedComponent translator={translator} {...this.props} />
    }
  }

  /*
   Map our react-intl state from redux into this HOC
   */
  function mapStateToProps(state, props) {
    return {
      intl: state.intl
    }
  }
  return connect(mapStateToProps)(injectIntl(TranslatorWrapped))
}
