export const trackGuidebookView = (guidebook_id, host_user_id) => {
  let eventObject = {
    "event": "guidebook_viewed",
    "guidebook_id": guidebook_id || 0,
    "host_user_id": host_user_id || 0
  }
  trackEvent(eventObject)
}

export const trackTabOpened = (guidebook_id, host_user_id, tab_name) => {
  let eventObject = {
    "event": "tab_opened",
    "guidebook_id": guidebook_id || 0,
    "host_user_id": host_user_id || 0,
    "tab_name": tab_name || "arrival"
  }
  trackEvent(eventObject)
}

export const trackCardOpened = (guidebook_id, host_user_id, tab_name, card_name, card_key) => {
  let eventObject = {
    "event": "card_opened",
    "guidebook_id": guidebook_id || 0,
    "host_user_id": host_user_id || 0,
    "tab_name": tab_name || "arrival"
  }
  if (card_name) {
    eventObject["card_name"] = card_name
  }
  if (card_key) {
    eventObject["card_key"] = card_key
  }
  trackEvent(eventObject)
}

export const trackMarketplaceePurchaseClick = (guidebook_id, host_user_id, tab_name, card_name, card_key) => {
  let eventObject = {
    "event": "marketplace_purchase_click",
    "guidebook_id": guidebook_id || 0,
    "host_user_id": host_user_id || 0,
    "tab_name": tab_name || "arrival"
  }
  if (card_name) {
    eventObject["card_name"] = card_name
  }
  if (card_key) {
    eventObject["card_key"] = card_key
  }
  trackEvent(eventObject)
}

export const trackFreeSignup = () => {
  let eventObject = {
    "event": "guidebook_free_signup"
  }
  trackEvent(eventObject)
}

const trackEvent = (eventObject) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventObject);
}
