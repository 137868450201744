import React from 'react'

const Phone = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`phone`}</title>
    <path d="M6.609 10.781a15.506 15.506 0 006.609 6.609l2.203-2.203c.281-.281.703-.375 1.031-.234 1.125.375 2.344.563 3.563.563a.96.96 0 01.984.984v3.516a.96.96 0 01-.984.984C10.64 21 2.999 13.359 2.999 3.984A.96.96 0 013.983 3h3.516a.96.96 0 01.984.984c0 1.266.188 2.438.563 3.563.094.328.047.75-.234 1.031z" />
  </svg>
)

export default Phone
