// import React from 'react';

const componentTypes = [
  'street_number',
  'street_address',
  'route',
  'country',
  'administrative_area_level_1',
  'administrative_area_level_2',
  'neighborhood',
  'postal_town',
  'locality',
  'postal_code$',
  'post_box'
]
const matches = componentTypes.join('|')
const addressRegex = new RegExp(matches, 'i')

export const consolidatePlaceResults = function (results) {
  const place = results[0]
  const address = {
    place_id: place.place_id,
    formatted_address: place.formatted_address,
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng()
  }
  let result = place.address_components.reduce(function (acc, item) {
    const types = item.types.join(',')
    const matches = types.match(addressRegex)
    if (matches) {
      // put into our acc object
      acc[matches[0]] = item.short_name
    }
    return acc
  }, address)

  return result
}
