import React from 'react'

const Security = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`security`}</title>
    <path d="M12 .984l9 4.031v6c0 5.531-3.844 10.734-9 12-5.156-1.266-9-6.469-9-12v-6zM12 12v8.953c3.703-1.172 6.469-4.828 6.984-8.953H12zm0 0V3.187L5.016 6.281V12H12z" />
  </svg>
)

export default Security
