import React from 'react'

const SmokingRooms = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`smoking_rooms`}</title>
    <path d="M16.031 10.219c1.875 0 3.469 1.313 3.469 3.141v1.641H18v-1.313c0-1.313-.938-2.016-1.969-2.016h-1.547c-1.828 0-3.328-1.547-3.328-3.375s1.5-3.328 3.328-3.328v1.5c-1.031 0-1.828.703-1.828 1.734s.797 2.016 1.828 2.016h1.547zm2.813-2.485a5.536 5.536 0 013.141 5.016V15h-1.5v-2.25c0-2.25-1.734-4.031-3.984-4.031v-1.5c1.031 0 1.828-.844 1.828-1.875s-.797-1.828-1.828-1.828v-1.5a3.34 3.34 0 013.328 3.328c0 .938-.375 1.781-.984 2.391zM18 15.984h1.5v3H18v-3zm2.484 0h1.5v3h-1.5v-3zm-18.468 0h15v3h-15v-3z" />
  </svg>
)

export default SmokingRooms
