import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HostHeader from 'components/Print/SubSections/HostHeader'
import GuidebookMapWOverlay from 'components/Print/SubSections/GuidebookMapWOverlay'
import ListingName from 'components/Print/SubSections/ListingName'
import HostIntro from 'components/Print/SubSections/HostIntro'

class CoverPage extends Component {
  render() {
    return (
      <div id="coverpage" className="page-break-after">
        <HostHeader guidebook={this.props.guidebook} intl={this.props.intl} />

        <GuidebookMapWOverlay
          guidebook={this.props.guidebook}
          intl={this.props.intl}
          onReady={this.props.onReady}
          printPreferences={this.props.printPreferences}
        />

        <ListingName
          guidebook={this.props.guidebook}
          styles={this.props.styles ? this.props.styles : null}
        />

        <HostIntro
          guidebook={this.props.guidebook}
          styles={this.props.styles ? this.props.styles : null}
        />
      </div>
    )
  }
}

CoverPage.propTypes = {
  guidebook: PropTypes.object,
  printPreferences: PropTypes.object,
  onReady: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  styles: PropTypes.object
}

export default CoverPage
