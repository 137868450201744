import React from 'react'

const RestaurantMenu = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`restaurant_menu`}</title>
    <path d="M14.859 11.531l-1.453 1.453 6.891 6.891-1.406 1.406L12 14.39l-6.891 6.891-1.406-1.406 9.75-9.75c-.703-1.547-.188-3.703 1.406-5.297 1.922-1.922 4.641-2.25 6.094-.797s1.125 4.219-.797 6.141c-1.594 1.594-3.75 2.063-5.297 1.359zm-6.75 1.828L3.89 9.14a3.989 3.989 0 010-5.625l7.031 6.984z" />
  </svg>
)

export default RestaurantMenu
