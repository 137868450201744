import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import UAParser from 'ua-parser-js'
import cn from 'classnames'
import { Button, Media } from 'react-md'
import LocaleSwitcher from 'components/LocaleSwitcher'
import { safelyMessages } from 'constants/UIConstants'
// import { trackEvent, getHostIdAndKey } from 'utils/Segment';
import { checkDomain } from 'utils/Urls'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  guidebook: PropTypes.object,
  // helper for internationalization
  intl: intlShape.isRequired
}

const defaultProps = {}

class SafelySplash extends Component {
  constructor(props) {
    super(props)
    this.state = {
      localesShown: false
    }
  }

  componentDidMount() {
    // track this event in segment
    if (
      this.props.guidebook.key &&
      this.props.guidebook.key !== 'loading' &&
      this.props.guidebook.host_user_id
    ) {
      // OLD SEGMENT CODE
      // console.log(this.props.guidebook.host_user_id);
      // let eventData = getHostIdAndKey(this.props.guidebook);
      // if (eventData.key) eventData.label = eventData.key;
      // eventData.category = 'Guidebook';
      // trackEvent('safelySplashViewed', eventData);
    }
  }

  setSplashCookie = () => {
    const { cookies } = this.props
    const cookieName = `hf_safely_splash_${this.props.guidebook.key}`
    const cookieValue = this.props.guidebook.key
    // remember preference with cookie
    const expiresDate = new Date()
    expiresDate.setMonth(expiresDate.getMonth() + 1)
    const cookieOptions = {
      path: '/',
      expires: expiresDate,
      secure: true
    }
    cookies.set(cookieName, cookieValue, cookieOptions)
  }

  openSafely = () => {
    const self = this
    const safelyUrl = `https://travel.safely.com/quote?u=${this.props.guidebook.host_user_id}&g=${this.props.guidebook.id}`
    // OLD SEGMENT CODE
    // let eventData = getHostIdAndKey(this.props.guidebook);
    // if (eventData.key) eventData.label = eventData.key;
    // eventData.category = 'Guidebook';
    // trackEvent('safelyClickthrough', eventData, {}, () => {
    window.open(safelyUrl, '_blank')
    self.setSplashCookie()
    // });
  }

  closeSplash = () => {
    const self = this
    // OLD SEGMENT CODE
    // let eventData = getHostIdAndKey(this.props.guidebook);
    // if (eventData.key) eventData.label = eventData.key;
    // eventData.category = 'Guidebook';
    // trackEvent('safelyDismissed', eventData, {}, () => {
    self.setSplashCookie()
    // });
  }

  localesShown = (value) => {
    this.setState({ localesShown: value })
  }

  render() {
    checkDomain(this.props.guidebook)
    const self = this
    const { formatMessage } = this.props.intl
    const ua = new UAParser()
    let device_type = ua.getDevice().type
    if (device_type !== 'mobile' && device_type !== 'tablet') {
      device_type = 'desktop'
    }

    const locale_padding = this.state.localesShown ? 'locale-margin' : ''
    const classNames = cn('safely-splash', device_type)
    const innerClass = cn('safely-content', locale_padding)
    const imageUrl = '/images/Safely-Logo.png'
    return (
      <div className={classNames}>
        <LocaleSwitcher
          persistant
          onShown={(e) => {
            self.localesShown(true)
          }}
          onHidden={(e) => {
            self.localesShown(false)
          }}
        />
        <div className={innerClass}>
          <div className="md-grid">
            <div className="md-cell md-cell--12">
              <h1>{formatMessage(safelyMessages.header)}</h1>
            </div>
            <div className="md-cell md-cell--8">
              <div className="md-grid">
                <div className="md-cell--12">
                  <p>{formatMessage(safelyMessages.subheader)}</p>

                  <p>{formatMessage(safelyMessages.details)}</p>
                </div>
                <div className="md-cell md-cell--12 md-cell--tablet-hidden md-cell--phone-hidden">
                  <Button
                    raised
                    onClick={this.openSafely}
                    className="safely-cta"
                  >
                    {formatMessage(safelyMessages.tellMeMore)}
                  </Button>
                </div>
                <div className="md-cell md-cell--12 md-cell--tablet-hidden md-cell--phone-hidden">
                  <Button
                    raised
                    onClick={this.closeSplash}
                    className="safely-cta"
                  >
                    {formatMessage(safelyMessages.viewGuidebook)}
                  </Button>
                </div>
              </div>
            </div>
            <div className="md-cell md-cell--4 md-cell--2-tablet-offset">
              <div className="md-grid md-text-center provided-by-wrapper">
                <div className="md-cell md-cell--11 md-cell--1-desktop-offset provided-by">
                  {formatMessage(safelyMessages.providedBy)}
                </div>
                <div className="md-cell md-cell--11 md-cell--1-desktop-offset">
                  <Media aspectRatio="7-4">
                    <img src={imageUrl} alt="" />
                  </Media>
                </div>
              </div>
            </div>
            <div className="md-cell md-cell--12 md-cell--desktop-hidden md-text-center">
              <Button raised onClick={this.openSafely} className="safely-cta">
                {formatMessage(safelyMessages.tellMeMore)}
              </Button>
            </div>
            <div className="md-cell md-cell--12 md-cell--desktop-hidden md-text-center">
              <Button raised onClick={this.closeSplash} className="safely-cta">
                {formatMessage(safelyMessages.viewGuidebook)}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SafelySplash.propTypes = propTypes
SafelySplash.defaultProps = defaultProps

export default withCookies(connect()(injectIntl(SafelySplash)))
