import React from 'react'
import ReactDOM from 'react-dom'

import { getUser, saveAuth } from 'utils/Auth'

import { initializeFirebase } from './firebaseConfig'
import Main from './Main'

if (window.location !== window.parent.location) {
  window.isIframe = true
  document.getElementById('root').classList.add('hf-external-access')
}

if (window.location.search.includes('resetBeta')) {
  localStorage.removeItem('hf_beta_invite')
}

window.addEventListener('message', (event) => {
  const user = getUser()

  // Ensure the message is coming from a trusted source
  if (event.origin === process.env.REACT_APP_V3_BASE_URL) {
    switch (event.data.type) {
      case 'userAuth':
        if (event.data.user.email !== user?.email) {
          saveAuth({ ...event.data.session, user: event.data.user })
          window.location.reload()
        }

        break
      default:
        return
    }
  }
})

initializeFirebase()

ReactDOM.render(<Main />, document.getElementById('root'))
