import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { FontIcon } from 'react-md'
import { connect } from 'react-redux'

import ListItemModal from 'components/ListItemModal'
import AsHtml from 'components/Translator/AsHtml'
import { hostInfoDefaults, wifiDefaults } from 'constants/SharedPropDefaults'
import { hostInfoShape, wifiShape } from 'constants/SharedPropTypes'
import { securityMessages, wifiMessages } from 'constants/UIConstants'
import { translateString } from 'utils/I18nHelper'
import { br2nl, stripTags } from 'utils/Strings'

import NetworkDetails from './NetworkDetails'

import PrintSubsection from '../../../PrintView/PrintSubsection'

const propTypes = {
  viewMode: PropTypes.string,
  wifi: wifiShape,
  isSecure: PropTypes.bool,
  host_intro: hostInfoShape,
  guidebook: PropTypes.shape({
    id: PropTypes.number,
    host_user_id: PropTypes.number,
    key: PropTypes.string
  }),
  avatarStyle: PropTypes.object,
  intl: intlShape.isRequired
}

const defaultProps = {
  viewMode: 'web',
  wifi: wifiDefaults,
  isSecure: false,
  host_intro: hostInfoDefaults,
  avatarStyle: {}
}

class WifiListItemModal extends PureComponent {
  shouldComponentUpdate(nextProps, nextState) {
    const propsEqual = JSON.stringify(this.props) === JSON.stringify(nextProps)
    const stateEqual = JSON.stringify(this.state) === JSON.stringify(nextState)
    // if all of these things are the same, don't re-render
    if (propsEqual && stateEqual) {
      return false
    }
    return true
  }

  formatMessage = this.props.intl.formatMessage

  buildWifiContent = () => {
    const { wifi } = this.props

    const dontProvideBut = this.formatMessage(wifiMessages.dontProvideBut)

    const wifiInfo = []
    if (wifi.wifi_provided) {
      // if we have wifi, return the network details as well as the Expander
      wifiInfo.push(
        <NetworkDetails key="network-details" nested={false} {...this.props} />
      )
    } else if (wifi.use_external_network) {
      const toGainAccess = (
        <span>
          {this.formatMessage(wifiMessages.gainAccess)}{' '}
          <b>{wifi.external_network_name}</b>
        </span>
      )

      const cantHelp = this.formatMessage(wifiMessages.cantHelp)

      let alsoUseExternalCafe = null
      if (wifi.use_external_cafe) {
        alsoUseExternalCafe = this.formatMessage(wifiMessages.alsoCafe, {
          externalCafeName: wifi.cafe_address
        })
      }

      const messageList = (
        <div>
          <p>{dontProvideBut}</p>
          <p>{toGainAccess}</p>
          <p>{cantHelp}</p>
          <p>{alsoUseExternalCafe}</p>
        </div>
      )

      wifiInfo.push(<div key="external-wifi">{messageList}</div>)
    } else if (wifi.use_external_cafe) {
      const useExternalCafe = this.formatMessage(wifiMessages.canCafe, {
        externalCafeName: wifi.cafe_address
      })

      wifiInfo.push(
        <div key="external-cafe">
          <p>{dontProvideBut}</p>
          <p>{useExternalCafe}</p>
        </div>
      )
    } else {
      const dontProvide = this.formatMessage(wifiMessages.weDontProvide)
      wifiInfo.push(
        <div key="no-wifi">
          <p>{dontProvide}</p>
        </div>
      )
    }

    const otherInfo = this.buildOtherInfo()
    if (otherInfo) {
      const otherInfoStyle = { marginTop: '32px' }
      wifiInfo.push(
        <div key="other-wifi-info" style={otherInfoStyle}>
          {otherInfo}
        </div>
      )
    }

    return wifiInfo
  }

  buildOtherInfo = () => {
    const { wifi, host_intro } = this.props

    let host_phone = null
    if (
      host_intro &&
      host_intro.host_phone &&
      host_intro.host_phone.length &&
      host_intro.host_phone[0].length
    ) {
      host_phone = host_intro.host_phone[0]
    }

    let wifiIssuesCall = null
    if (wifi.wifi_provided && wifi.if_issues_call) {
      if (host_phone) {
        wifiIssuesCall = (
          <p>
            {this.formatMessage(wifiMessages.ifIssuesCallOn, {
              hostPhone: host_intro.host_phone
            })}
          </p>
        )
      } else {
        wifiIssuesCall = (
          <p>{this.formatMessage(wifiMessages.ifIssuesContact)}</p>
        )
      }
    }

    let wifiIssuesReset = null
    if (wifi.wifi_provided && wifi.if_issues_reset) {
      wifiIssuesReset = (
        <p>
          <AsHtml text={wifi.reset_text} translations={wifi.reset_text_txn} />
        </p>
      )
    }

    let wifiCafe = null
    if (wifi.wifi_provided && wifi.if_issues_cafe) {
      wifiCafe = (
        <p>
          {this.formatMessage(wifiMessages.youCanAccessWifi, {
            cafe_address: wifi.cafe_address
          })}
        </p>
      )
    }

    let wifiRules = null
    if (wifi.wifi_rules_text) {
      wifiRules = (
        <p>
          <AsHtml
            text={wifi.wifi_rules_text}
            translations={wifi.wifi_rules_text_txn}
          />
        </p>
      )
    }

    let resolveHeader = null
    if (!wifi.wifi_provided || wifi.use_external_network) {
      resolveHeader = this.formatMessage(wifiMessages.otherWifiInfo)
    } else if (
      wifi.if_issues_reset ||
      wifi.if_issues_cafe ||
      wifi.if_issues_call
    ) {
      resolveHeader = this.formatMessage(wifiMessages.howToResolveWifi)
    }

    let otherInfo = null
    if (this.props.viewMode === 'print' && resolveHeader) {
      otherInfo = (
        <PrintSubsection header={resolveHeader}>
          {wifiIssuesReset}
          {wifiIssuesCall}
          {wifiCafe}
          {wifiRules}
        </PrintSubsection>
      )
    } else {
      otherInfo = (
        <div>
          <p>
            <strong>{resolveHeader}</strong>
          </p>
          <div>
            {wifiIssuesReset}
            {wifiIssuesCall}
            {wifiCafe}
            {wifiRules}
          </div>
        </div>
      )
    }

    return wifiIssuesReset || wifiIssuesCall || wifiCafe || wifiRules
      ? otherInfo
      : null
  }

  /**
   * build a text-only version of the 'gist' of the wifi situation
   */
  buildSecondaryText = () => {
    const { wifi, intl, isSecure } = this.props
    const canAccessWifi = (wifi.wifi_secure && isSecure) || !wifi.wifi_secure
    const network_name = canAccessWifi
      ? wifi.network_name
      : this.formatMessage(securityMessages.noAccess)
    const wifi_password = canAccessWifi
      ? wifi.wifi_password
      : this.formatMessage(securityMessages.noAccess)
    if (wifi.wifi_provided) {
      if (wifi.login_provided_online) {
        const networkLabel = this.formatMessage(wifiMessages.networkDetails)
        const network = `${networkLabel}: ${network_name}`
        const passwordLabel = this.formatMessage(wifiMessages.passwordText)
        const password = `${passwordLabel}: ${wifi_password}`
        return `${network}\n${password}`
      } else if (wifi.login_provided_offline) {
        const wifiOffline = stripTags(
          br2nl(
            translateString(
              intl.locale,
              wifi.login_offline_text,
              wifi.login_offline_text_txn
            )
          )
        )
        return `${wifiOffline}`
      }
    } else if (wifi.use_external_network || wifi.use_external_cafe) {
      return this.formatMessage(wifiMessages.dontProvideBut)
    } else {
      return this.formatMessage(wifiMessages.weDontProvide)
    }
  }

  render() {
    if (this.props.viewMode === 'print') {
      return (
        <div>
          <br />
          {this.buildWifiContent()}
        </div>
      )
    } else {
      const matchParams = this.context.router.route.match.params
      const deepLinkedCard = matchParams.card_title
        ? matchParams.card_title
        : null
      return (
        <ListItemModal
          avatarStyle={this.props.avatarStyle}
          baseLocation={`/${this.props.guidebook.key}/arrival`}
          cardTitle="wifi"
          guidebook={this.props.guidebook}
          leftIcon={<FontIcon>wifi</FontIcon>}
          modalVisible={deepLinkedCard === 'wifi'}
          primaryText={this.formatMessage(wifiMessages.WiFi)}
          secondaryText={this.buildSecondaryText()}
          tabName="Arrival"
          title={this.formatMessage(wifiMessages.WiFi)}
          threeLines
          trackLabel="wifi info"
        >
          {this.buildWifiContent()}
        </ListItemModal>
      )
    }
  }
}

WifiListItemModal.propTypes = propTypes
WifiListItemModal.defaultProps = defaultProps
WifiListItemModal.contextTypes = {
  router: PropTypes.object
}

export default connect()(injectIntl(WifiListItemModal))
