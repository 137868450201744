import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Avatar, FontIcon, ListItem } from 'react-md'
import styles from './HostInfo.css'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import AsHtml from 'components/Translator/AsHtml'
import { atMostPhablet } from 'components/ScreenSize'
import { hostInfoShape } from 'constants/SharedPropTypes'
import { hostInfoDefaults } from 'constants/SharedPropDefaults'
import { fieldHasContent } from 'utils/ValidationHelpers'
// import {trackEvent} from 'utils/Segment';

const propTypes = {
  host_intro: hostInfoShape,
  is_secure: PropTypes.bool,
  token: PropTypes.object
}

const defaultProps = {
  host_intro: hostInfoDefaults,
  is_secure: false,
  token: {}
}

class HostInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      infoVisible: true
    }
  }

  // add some functionality to provide a default icon if none is provided
  getHostAvatar = () => {
    if (
      !this.props.host_intro ||
      !this.props.host_intro.image ||
      this.props.host_intro.image.trim() === ''
    ) {
      // we can change the icon later can also user our svg icons
      // return <Avatar><Icon glyph="hf-host" /></Avatar>;
      return (
        <Avatar>
          <FontIcon>face</FontIcon>
        </Avatar>
      )
    } else {
      return <Avatar src={this.props.host_intro.image} role="presentation" />
    }
  }

  // default to email if we don't have the host name
  getHostName = () => {
    if (
      this.props.host_intro &&
      this.props.host_intro.host_name &&
      this.props.host_intro.host_name.trim() !== ''
    ) {
      return this.props.host_intro.host_name
    } else if (
      this.props.host_intro &&
      this.props.host_intro.host_email &&
      this.props.host_intro.host_email.trim() !== ''
    ) {
      return this.props.host_intro.host_email
    } else {
      return "Host's Name" //shouldn't happen
    }
  }

  // create list of phone numbers if we have any
  getPhoneNumbers = () => {
    let phoneNumbers = null
    if (
      this.props.host_intro &&
      this.props.host_intro.host_phone &&
      this.props.host_intro.host_phone.length > 0
    ) {
      //filter out any empty elements in the array
      const filteredPhoneNumbers = this.props.host_intro.host_phone.filter(
        function (n) {
          return n !== ''
        }
      )
      if (filteredPhoneNumbers.length > 0) {
        phoneNumbers = filteredPhoneNumbers.map(function (number, i) {
          const formattedNumber = atMostPhablet ? (
            <a href={'tel:' + number}>{number}</a>
          ) : (
            number
          )
          return (
            <ListItem
              className="no-click"
              key={i}
              primaryText={formattedNumber}
            />
          )
        })
      }
    }
    return phoneNumbers
  }

  getEmail = () => {
    let hostEmail = null
    if (
      this.props.host_intro &&
      this.props.host_intro.host_email &&
      this.props.host_intro.host_email.trim() !== ''
    ) {
      hostEmail = (
        <ListItem
          key="host-email"
          primaryText={this.props.host_intro.host_email || ''}
          onClick={this.handleEmailClick}
        />
      )
    }
    return hostEmail
  }

  handleEmailClick = () => {
    if (this.props.host_intro && this.props.host_intro.host_email) {
      window.location = 'mailto:' + this.props.host_intro.host_email
    }
  }

  handleClick = (e, f) => {
    const showInfo = !this.state.infoVisible
    // OLD SEGMENT CODE
    // let eventData = {
    //   category: 'Guidebook'
    // };
    // trackEvent(showInfo ? 'hostintroShown' : 'hostintroHidden', eventData);
    this.setState({ infoVisible: showInfo })
  }

  buildNestedItems = () => {
    let nestedItems = []
    //phone number is optional
    const phoneNumbers = this.getPhoneNumbers()
    if (phoneNumbers) nestedItems.push(phoneNumbers)
    //email address is required
    nestedItems.push(this.getEmail())
    // host intro could be blank
    const hostIntro = this.renderHostIntro()
    if (hostIntro) nestedItems.push(hostIntro)
    return nestedItems
  }

  renderHostIntro = () => {
    const { host_intro, token } = this.props

    let hostIntroContent = null

    if (host_intro && host_intro.host_intro) {
      const variableData = { token: token }
      if (this.props.is_secure && host_intro.host_intro_has_secure_info) {
        if (fieldHasContent(host_intro.host_intro_secure)) {
          hostIntroContent = (
            <div key="host_welcome" className="hf-host-welcome">
              <AsHtml
                text={host_intro.host_intro_secure}
                translations={host_intro.host_intro_secure_txn || {}}
                variableData={variableData}
              />
            </div>
          )
        }
      } else {
        //check to see if host intro just contains whitespace

        if (fieldHasContent(host_intro.host_intro)) {
          hostIntroContent = (
            <div key="host_welcome" className="hf-host-welcome">
              <AsHtml
                text={host_intro.host_intro}
                translations={host_intro.host_intro_txn || {}}
                variableData={variableData}
              />
            </div>
          )
        }
      }
    }
    return hostIntroContent
  }

  render() {
    // pack up component classes and add the md classes necessary
    const classes = classnames(styles.HostInfo)
    const yourHost = (
      <FormattedMessage id="gb.yourHost" defaultMessage="Contact your host" />
    )
    return (
      <div className={classes}>
        <ListItem
          key="host_intro"
          leftAvatar={this.getHostAvatar()}
          primaryText={this.getHostName() || "Host's Name"}
          secondaryText={yourHost}
          nestedItems={this.buildNestedItems()}
          visible={this.state.infoVisible}
          onClick={this.handleClick}
        />
      </div>
    )
  }
}

HostInfo.propTypes = propTypes
HostInfo.defaultProps = defaultProps

export default HostInfo
