import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { updateMapMarkers, setMapBounds } from 'redux/modules/map'
import DocumentTitle from 'react-document-title'
import { injectIntl } from 'react-intl'
import { SelectField, Toolbar } from 'react-md'
import GuidebookMap from 'components/GuidebookMap'
import GuidebookMarker from 'components/MarkerHelpers/GuidebookMarker'
import MapOverlay from 'components/MapOverlay'
import RecommendationsMarkers from 'components/MarkerHelpers/RecommendationsMarkers'
// import {trackEvent, getHostIdAndKey} from 'utils/Segment';
import { essentialsLabel, labelMessages } from 'constants/UIConstants'
import { sortData } from 'utils/Data'

class GuidebookMapView extends Component {
  constructor(props) {
    super(props)
    this.recClick = this.recClick.bind(this)
    this.handleCloseRec = this.handleCloseRec.bind(this)
    this.gbClick = this.gbClick.bind(this)
    this.onBoundsChanged = this.onBoundsChanged.bind(this)
    this.state = {
      recDisplayed: null
    }
  }

  componentDidMount() {
    if (
      this.props.guidebook.key &&
      this.props.guidebook.key !== 'loading' &&
      this.props.guidebook.host_user_id
    ) {
      // OLD SEGMENT CODE
      // let eventData = getHostIdAndKey(this.props.guidebook);
      // if (eventData.key) eventData.label = eventData.key;
      // eventData.category = 'Guidebook';
      // eventData.view_type = 'map';
      // trackEvent('guidebookViewed', eventData);
    }
    // update which markers we want to show on the map
    this.updateMap()
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { mapCategory } = this.props
    const nextCategory = nextProps.mapCategory
    const { recDisplayed } = this.state
    const nextRecDisplayed = nextState.recDisplayed
    return mapCategory !== nextCategory || recDisplayed !== nextRecDisplayed
  }

  componentDidUpdate() {
    // update which markers we want to show on the map
    this.updateMap()
  }

  onBoundsChanged(mapProps, map) {
    const { dispatch } = this.props
    const category = this.props.mapCategory || 'all'
    const center = map.getCenter()
    const zoom = map.getZoom()
    dispatch(setMapBounds(category, center.lat(), center.lng(), zoom))
  }

  handleCloseRec = (e) => {
    this.setState({ recDisplayed: null })
  }

  recClick = (rec, e) => {
    if (!this.state.recDisplayed) {
      this.setState({ recDisplayed: rec })
    }
  }

  gbClick() {
    this.props.dispatch(push(`${this.buildBaseGuidebookUrl()}`))
  }

  renderMapOverlay = () => {
    if (this.state.recDisplayed) {
      const gbSlim = {
        key: this.props.guidebook.key,
        host_user_id: this.props.guidebook.host_user_id
      }
      return (
        <MapOverlay
          className="hf-mapview-map-overlay"
          guidebook={gbSlim}
          rec={this.state.recDisplayed}
          onClose={this.handleCloseRec}
        />
      )
    }
    return null
  }

  updateMap() {
    const { guidebook } = this.props
    const tabs = guidebook.recommendationsByCategory
    const category = this.props.mapCategory || 'all'
    let categoryKey = category.replace(/ /g, '_')
    const selectedCategory = categoryKey ? tabs[categoryKey] : null
    var cat = selectedCategory ? selectedCategory.recommendations : null
    const markers = this.renderMarkers(guidebook, cat)
    this.props.dispatch(updateMapMarkers(markers))
  }

  handleFilterChange = (category) => {
    const baseGuidebookUrl = this.buildBaseGuidebookUrl()
    if (category === 'all') {
      this.props.dispatch(push(`${baseGuidebookUrl}/map`))
    } else {
      this.props.dispatch(push(`${baseGuidebookUrl}/map/${category}`))
    }
  }

  buildBaseGuidebookUrl = () => {
    const { recsOnly, guidebook } = this.props
    if (recsOnly && guidebook.recs_only_enabled && guidebook.recs_only_key) {
      return `/recs/${guidebook.recs_only_key}`
    } else {
      return `/${guidebook.key}`
    }
  }

  renderMarkers = (newGb, recommendations) => {
    // console.log(recommendations);
    var self = this
    if (!newGb.id) {
      return []
    }
    const recColor = null
    let markers = []
    if (recommendations) {
      markers = RecommendationsMarkers(
        recommendations,
        30,
        true,
        recColor,
        self.recClick,
        newGb.theme
      )
    }
    // place the marker for this Guidebook
    const gbColor = null
    if (!this.props.recsOnly) {
      markers.push(
        GuidebookMarker(newGb, 30, true, gbColor, self.gbClick, newGb.theme)
      )
    }
    return markers
  }

  render() {
    const { guidebook, intl, map } = this.props
    const category = this.props.mapCategory || 'all'
    const bounds = map.bounds[category]
    const { formatMessage } = intl
    const recCategories = []
    Object.keys(guidebook.recommendationsByCategory).forEach(function (key) {
      recCategories.push(guidebook.recommendationsByCategory[key].category)
    })

    const sortedCategories = sortData(recCategories, 'order')

    const categories = sortedCategories.map(function (cat) {
      // mn hack take care of the stray &amp;  in the description
      const catName = cat.name.charAt(0).toUpperCase() + cat.name.slice(1)
      return {
        id: cat.name.toLowerCase(),
        label: catName.replace(/&amp;/g, '&')
      }
    })
    if (!this.props.recsOnly) {
      var home_label = '0'
      let home_tab_label = formatMessage(
        labelMessages[essentialsLabel[home_label]]
      )
      if (guidebook.theme && guidebook.theme.home_label) {
        home_tab_label =
          guidebook.theme.home_label_txn[intl.locale] ||
          guidebook.theme.home_label
      }
      categories.unshift({ id: 'home', label: home_tab_label })
    }

    const toolbar_title = (
      <SelectField
        id="selectMapFilter"
        placeholder="Filter By"
        menuItems={categories}
        itemLabel="label"
        itemValue="id"
        onChange={this.handleFilterChange}
        position={SelectField.Positions.BELOW}
        className="md-cell"
        value={category}
      />
    )

    const pageTitle = formatMessage(labelMessages.map_view)

    return (
      <DocumentTitle title={this.props.guidebook.name + ' - ' + pageTitle}>
        <div>
          <Toolbar className="map-toolbar" themed titleMenu={toolbar_title} />
          <GuidebookMap
            className="map-container-full"
            lat={bounds ? bounds.lat : undefined}
            lng={bounds ? bounds.lng : undefined}
            initialZoom={bounds ? bounds.zoom : undefined}
            fitType={bounds ? 'bounds' : 'smart'}
            guidebook={this.props.guidebook}
            onBoundsChanged={this.onBoundsChanged}
          />
          {this.renderMapOverlay()}
        </div>
      </DocumentTitle>
    )
  }
}

GuidebookMapView.propTypes = {
  guidebook: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string
  }),
  recsOnly: PropTypes.bool,
  dispatch: PropTypes.func.isRequired
}

GuidebookMapView.defaultProps = {
  recsOnly: false
}

function mapStateToProps(state, props) {
  return {
    guidebook: state.guidebook.data,
    map: state.map
  }
}

export default connect(mapStateToProps)(injectIntl(GuidebookMapView))
