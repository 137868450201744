const camelizeFrom = (str, delim) => {
  return str
    .split(delim)
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

export const camelize = function (str) {
  str = camelizeFrom(str, ' ')
  str = camelizeFrom(str, '_')
  return str
}

export const stripTags = (html) => {
  var tmp = document.createElement('div')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText
}

export const nl2br = (str) => {
  // this should replace any newline chareacters that do not come directly after a closing tag
  // (or a closing tag with whitespace afterwards)
  let breakChar = '<br />'
  return (str + '').replace(
    /([^>[\s]?\r\n]?)(\r\n|\n\r|\r|\n)/g,
    '$1' + breakChar
  )
}

export const br2nl = (str) => {
  return (str + '').replace(/(<br>|<br\/>|<br \/>)/g, '\r')
}

// convert a string to a useable id (strip out any non-id characters and spaces)
export const stringToId = (string, separator) => {
  if (!string) return ''
  const useSeparator = separator || '_'
  return string.trim().replace(/[^0-9a-z]/gi, useSeparator)
}

export const idToTitle = (id) => {
  return id.trim().replace(/_/g, ' ')
}

export const obfuscateID = (string) => {
  let result = ''
  // convert to string, split into array, then reverse
  const string_array = String(string).split('').reverse()
  // convert each number into it's character representation, shifted to start at g
  // console.log(string_array);
  for (var i = 0; i < string_array.length; i++) {
    let char = String.fromCharCode(103 + parseInt(string_array[i], 10))
    result += char
  }
  // add hm
  result = 'hm' + result
  return result
}

export const isNumericKey = (val) => {
  var code, i, len
  if (!val) return false
  for (i = 0, len = val.length; i < len; i++) {
    code = val.charCodeAt(i)
    if (!(code > 47 && code < 58)) {
      return false
    }
  }
  return true
}

export const isValidUrlString = (str) => {
  var code, i, len
  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i)
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123) && // lower alpha (a-z)
      !(code === 45 || code === 95) && //hyphen and underscore
      !(code === 44 || code === 58)
    ) {
      // comma and colon
      return false
    }
  }
  return true
}

export const randomString = (length, chars) => {
  const charSet =
    chars || '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

  let result = ''
  for (let i = length; i > 0; --i) {
    result += charSet[Math.floor(Math.random() * charSet.length)]
  }
  return result
}

export const ucFirst = (string) => {
  if (string.length === 0) return string
  if (string.length === 1) return string.toUpperCase()

  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const deObfuscateID = (string) => {
  let result = ''
  // remove hm
  // split into char array and reverse
  const string_array = string.substring(2).split('').reverse()
  for (var i = 0; i < string_array.length; i++) {
    let num = string_array[i].charCodeAt()
    result += String(num - 103)
  }
  result = parseInt(result, 10)
  return result
}

export const transformCustomVariables = (text, data) => {
  if (!text) return text

  let resultHtml = text
  let replaceText = ''
  const variableReg =
    /(?:)\[hf-custom-variable([^[]+)\](.*?)\[\/hf-custom-variable\]/g
  const matchlinks = text.match(variableReg)
  if (matchlinks && matchlinks.length > 0) {
    for (var i = 0; i < matchlinks.length; i++) {
      // trim off what we need
      let scratch = matchlinks[i].substring(21)
      let variable = scratch.split('"', 1)[0]
      let fallback = scratch.split(']', 2)[1].split('[', 1)[0]
      replaceText = fetchVariableOrFallback(variable, fallback, data)
      // console.log(matchlinks[i]);
      resultHtml = resultHtml.replace(matchlinks[i], replaceText)
    }
  }
  return resultHtml
}

const fetchVariableOrFallback = (variable, fallback, data) => {
  const variableData = data
  // if the variable has a . it, we have nested objects
  if (variable.indexOf('.') !== -1) {
    const splitVar = variable.split('.')
    let baseObject = splitVar[0]
    let nestedObject = splitVar[1]
    if (
      variableData &&
      variableData[baseObject] &&
      variableData[baseObject][nestedObject] &&
      variableData[baseObject][nestedObject].length
    ) {
      return variableData[baseObject][nestedObject]
    }
  } else if (variable.length) {
    if (
      variableData &&
      variableData[variable] &&
      variableData[variable].length
    ) {
      return variableData[variable]
    }
  }
  return fallback
}

export const cleanUpTextOnly = (string) => {
  string = string.replace(/<(?:.|\n)*?>/gm, ' ')
  string = string.replace(/&amp;/g, '&')
  string = string.replace(/&quot;/g, '"')
  string = string.replace(/&#39;/g, "'")
  string = string.replace(/&gt;/g, '>')
  string = string.replace(/&lt;/g, '<')
  // now also strip out any shortcode stuff
  return stripShortCodes(string)
}

export const stripShortCodes = (text) => {
  return stripPDFLinks(stripCardToCardLinks(text))
}

export const stripPDFLinks = (text) => {
  if (!text) return text

  let resultHtml = text
  const pdfReg = /(?:)\[hf-pdf([^[]+)\]\[\/hf-pdf\]/g
  const matches = text.match(pdfReg)
  if (matches && matches.length > 0) {
    for (var i = 0; i < matches.length; i++) {
      // trim off what we need
      resultHtml = resultHtml.replace(matches[i], `[Embedded PDF]`)
    }
  }
  return resultHtml
}

export const stripCardToCardLinks = (text) => {
  if (!text) return text

  let resultHtml = text
  let replaceLink = ''
  const cardLinkReg = /(?:)\[hf-card-link([^[]+)\](.+?)\[\/hf-card-link\]/g
  const matchlinks = text.match(cardLinkReg)
  if (matchlinks && matchlinks.length > 0) {
    for (var i = 0; i < matchlinks.length; i++) {
      // trim off what we need
      let scratch = matchlinks[i].substring(15)
      scratch = scratch.substring(0, scratch.length - 15)
      let text = scratch.substring(scratch.indexOf(']') + 1)
      // if we have a recs-only link make sure not to build links to non-rec links
      replaceLink = `${text}`
      resultHtml = resultHtml.replace(matchlinks[i], replaceLink)
    }
  }
  return resultHtml
}
