import React from 'react'

const Headset = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`headset`}</title>
    <path d="M12 .984c4.969 0 9 4.031 9 9v7.031c0 1.641-1.359 3-3 3h-3v-8.016h3.984V9.983c0-3.891-3.094-6.984-6.984-6.984S5.016 6.093 5.016 9.983v2.016H9v8.016H6a3.02 3.02 0 01-3-3V9.984c0-4.969 4.031-9 9-9z" />
  </svg>
)

export default Headset
