import React from 'react'

const LocalParking = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`local_parking`}</title>
    <path d="M13.219 11.016c1.078 0 1.969-.938 1.969-2.016s-.891-2.016-1.969-2.016H9.985v4.031h3.234zM12.984 3c3.328 0 6 2.672 6 6s-2.672 6-6 6h-3v6H6V3h6.984z" />
  </svg>
)

export default LocalParking
