import PickerOverlay from 'filestack-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import {
  Button,
  FontIcon,
  LinearProgress,
  Paper,
  SelectField,
  TextField
} from 'react-md'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { isInt } from 'validator'

import MultilangWysiwyg from 'components/MultilangWysiwyg'
import TooltipButton from 'components/TooltipButton'
import { fixRotation } from 'redux/modules/filestack'
import {
  createItem,
  editItem,
  fetchItem,
  fetchProviders
} from 'redux/modules/orbirental/crud'
import { addToast } from 'redux/modules/toast'
import currentUser from 'utils/CurrentUser'
import { debounce } from 'utils/Debounce'
import { focusErrors } from 'utils/ValidationHelpers'

import ManageGuidebooksAndTemplates from '../../components/ModelForms/ManageSelectedCards/ManageGuidebooksAndTemplates'
import ManageSelectedGuidebooks from '../../components/ModelForms/ManageSelectedCards/ManageSelectedGuidebooks'

const propTypes = {
  uid: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  ownerSingularName: PropTypes.string,
  ownerPluralName: PropTypes.string
}
const defaultErrorText = 'This field is required'
const filestackKey = process.env.REACT_APP_FILESTACK_KEY

/**
 * This is the Marketplace item create/edit form
 * we don't use the react-redux-form stuff here because we're not using one of our
 * own models.
 *
 * TODO: probably would be good to encapsulate this create/edit behavior somehow to reuse with other integrations
 * or at least with other orbirental objects.
 *
 *
 */
class MarketplaceItem extends Component {
  constructor(props, context) {
    super(props)
    this.state = {
      // service holds the pristine data from the api
      service: {},
      // servicedelta holds any changes we're going to make
      serviceDelta: {},
      // errors hold any errors to show on the page
      providers: [],
      selectedGuidebooks: [],
      errors: {},
      isLoading: true,
      serviceSaving: false
    }
    this.goBack = this.goBack.bind(this)
    this.handleInputChange = debounce(this.handleInputChange.bind(this), 250)
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this)
    this.imageUploaded = this.imageUploaded.bind(this)
  }

  componentDidMount() {
    this.load()
  }
  componentDidUpdate(prevProps) {
    const { uid } = this.props
    if (prevProps.uid !== uid) {
      this.load()
    }
  }

  goBack = (uid) => {
    const { dispatch, guidebookId, ownerPluralName } = this.props
    if (guidebookId) {
      let path = '#marketplaceItems#marketplaceItem'
      if (uid) {
        path = `${path}#${uid}`
      }
      dispatch(push(`/host/${ownerPluralName}/${guidebookId}${path}`))
    } else {
      dispatch(push(`/host/marketplace`))
    }
  }

  load = () => {
    const { uid, guidebookId } = this.props
    const providers = []

    fetchProviders((data) => {
      if (data.length) {
        // take the returned data and split into list of providers and list of services.
        for (var i = 0, len = data.length; i < len; i++) {
          const label = `${data[i].companyName} (${data[i].type.toUpperCase()})`
          const item = { label: label, value: data[i].uid }
          providers.push(item)
        }
      }
      this.setState({ providers: providers })
    })

    // get guidebooks for listdata
    if (uid !== 'create') {
      fetchItem(uid, 'services', (item) => {
        this.setState({
          isLoading: false,
          service: item,
          selectedGuidebooks: item.guidebooks
        })
      })
    } else {
      // anything else we need for a fresh thingy
      const blankService = {
        serviceProviderUid: '',
        title: '',
        type: '',
        cateredToType: 'GUEST',
        description: '',
        longDescription: '',
        quantityDescription: '',
        price: '',
        isActive: true
      }
      const selectedGuidebooks =
        guidebookId !== 'create' ? [{ id: guidebookId }] : []
      const newDelta = selectedGuidebooks.length
        ? { ...blankService, guidebooks: selectedGuidebooks }
        : { ...blankService }

      this.setState({
        isLoading: false,
        service: blankService,
        serviceDelta: newDelta,
        selectedGuidebooks: selectedGuidebooks
      })
    }
  }

  renderLoading = (message) => {
    return (
      <div>
        <LinearProgress id="status_check" />
        <div className="md-subheading-1 md-text-center">{message}</div>
      </div>
    )
  }

  handleInputChange = (field, val) => {
    const { service, serviceDelta, errors } = this.state
    const newDelta = Object.assign({}, serviceDelta)
    const newErrors = Object.assign({}, errors)
    // if the value is different from the original data
    // AND is different from what's already in the delta
    // then we add to the delta
    if (
      (!service[field] || service[field] !== val) &&
      (!serviceDelta[field] || serviceDelta[field] !== val)
    ) {
      newDelta[field] = val
    }
    // if the value is the same as the original data
    // AND is different from what's in the delta
    // then we remove from the delta
    if (
      service[field] === val &&
      serviceDelta[field] &&
      serviceDelta[field] !== val
    ) {
      delete newDelta[field]
    }
    // validate the input as well
    const fieldHasError = this.fieldHasError(field, val)
    // if field has an error, update errors object
    if (fieldHasError) {
      newErrors[field] = fieldHasError
    }
    // if the field is valid but we already had errors for the field
    // remove errors
    if (!fieldHasError && errors[field]) {
      delete newErrors[field]
    }
    this.setState({ serviceDelta: newDelta, errors: newErrors })
  }

  handleSelectionChanged = (selection) => {
    const { serviceDelta } = this.state
    const newDelta = Object.assign({}, serviceDelta)
    let newSelection = []
    // strip everything but the id out of each item
    if (selection.length) {
      newSelection = selection.map((item) => {
        return { id: item.id }
      })
    }
    newDelta['guidebooks'] = newSelection
    this.setState({ selectedGuidebooks: newSelection, serviceDelta: newDelta })
  }

  imageUploaded(uploadResult) {
    if (uploadResult.filesUploaded && uploadResult.filesUploaded.length > 0) {
      const file = uploadResult.filesUploaded[0]
      const filestackUrl = file.url

      fixRotation(filestackUrl).then((data) => {
        if (data.url && data.url.length > 0) {
          this.handleInputChange('defaultPicture', data.url)
        }
      })
    }
  }

  validateAllData = () => {
    const { serviceDelta, errors } = this.state
    const newErrors = Object.assign({}, errors)

    let errorsFound = false
    Reflect.ownKeys(serviceDelta).forEach((field) => {
      const fieldHasError = this.fieldHasError(field, serviceDelta[field])
      if (fieldHasError) {
        errorsFound = true
        newErrors[field] = fieldHasError
      }
    })
    if (errorsFound) {
      focusErrors()
      this.setState({ errors: newErrors })
      return false
    }
    return true
  }

  /**
   * for now just validate required fields and basic length
   * TODO: if necessary add some better validation
   */
  fieldHasError = (field, val) => {
    const requiredFields = [
      'serviceProviderUid',
      'title',
      'type',
      'description',
      'price'
    ]
    const maxLengths = {
      title: 120,
      description: 255,
      quantityDescription: 120,
      cancellationPolicy: 255
    }
    const numericFields = ['price', 'partyMaximumSize', 'duration']
    const intFields = ['partyMaximumSize', 'duration']

    if (requiredFields.includes(field) && !val) {
      return 'This field is required'
    }
    if (maxLengths[field] && maxLengths[field] < val.length) {
      return `This field is limited to ${maxLengths[field]} characters`
    }
    if (intFields.includes(field) && val && !isInt(val)) {
      return 'Please enter an integer value for this field'
    } else if (numericFields.includes(field) && val && isNaN(val)) {
      return 'Please enter a numeric value for this field'
    }
    if (field === 'price' && val < 0.5) {
      return 'Please enter a price higher than 0.5'
    }
    return false
  }

  /**
   * if we have any changes at all, and everything's valid go ahead and create/edit
   */
  submitService = () => {
    const { service, serviceDelta } = this.state
    const { dispatch } = this.props

    this.setState({ serviceSaving: true }, () => {
      // first, see if we have any changes at all
      if (Reflect.ownKeys(serviceDelta).length) {
        // first validate all fields, then call the create/edit
        if (this.validateAllData()) {
          if (service.uid) {
            const editData = { service: serviceDelta }
            // TODO: add an errorCAllback here
            editItem(service.uid, 'services', editData, this.goBack)
          } else {
            const createData = { service: serviceDelta }
            // TODO: add an errorCAllback here
            createItem('services', createData, (item) => {
              // if we're creating a services WHILE creating a guidebook
              this.goBack(item.uid)
            })
          }
        } else {
          this.setState({ serviceSaving: false })
        }
      } else {
        dispatch(addToast("You haven't made any changes"))
        this.setState({ serviceSaving: false })
      }
    })
  }

  renderEditForm = () => {
    const { service, serviceDelta, errors } = this.state
    const imageSrc = service.defaultPicture || serviceDelta.defaultPicture
    const user = currentUser()
    const typeItems = [
      { label: 'Personal Service', value: 'PERSONAL_SERVICE' },
      { label: 'Transportation', value: 'TRANSPORTATION_SERVICE' },
      { label: 'Cleaning', value: 'CLEANING_SERVICE' },
      { label: 'Greeting Service', value: 'GREETING_SERVICE' },
      { label: 'Cooking', value: 'COOKING_SERVICE' },
      { label: 'Tours and Activities', value: 'TOUR' },
      { label: 'Other', value: 'OTHER' }
    ]

    const filestackOptions = {
      accept: 'image/*',
      maxFiles: 1,
      fromSources: ['local_file_system', 'url', 'imagesearch'],
      storeTo: {
        location: 'gcs'
      },
      imageMax: [1400, 800],
      transformations: {
        crop: {
          aspectRatio: 4 / 3,
          force: true
        },
        rotate: true
      }
    }

    const imageSelection = (
      <Paper key="card-image" className="hf-marketplace-form-paper">
        {imageSrc ? (
          <img
            src={imageSrc}
            alt="marketplace item"
            className="hf-marketplace-form-image"
          />
        ) : (
          <h4 className="md-subheading-1">
            Do you have an image to represent this service?
          </h4>
        )}
        <PickerOverlay
          apikey={filestackKey}
          customRender={({ onPick }) => (
            <Button onClick={onPick} secondary raised>
              {imageSrc ? 'Update Image' : 'Select Image'}
            </Button>
          )}
          actionOptions={filestackOptions}
          onSuccess={this.imageUploaded}
        />
      </Paper>
    )

    return (
      <div>
        <div className="hf-form-container">
          <div style={{ flex: 2 }}>
            <h4 className="md-subheading-1">What is the name of this offer?</h4>
            <TextField
              id="title"
              placeholder="Title"
              defaultValue={service.title}
              onChange={(val) => {
                this.handleInputChange('title', val)
              }}
              maxLength={120}
              error={typeof errors.title !== 'undefined'}
              errorText={errors.title || defaultErrorText}
              className="hf-input"
              required
            />
          </div>
          <div style={{ flex: 1 }}>
            <h4 className="md-subheading-1">What type of service is this?</h4>
            <SelectField
              id="type"
              placeholder="Item type"
              defaultValue={service.type}
              menuItems={typeItems}
              className="hf-input"
              onChange={(val) => {
                this.handleInputChange('type', val)
              }}
              error={typeof errors.type !== 'undefined'}
              errorText={errors.type || defaultErrorText}
              fullWidth
              required
            />
          </div>
          <div style={{ flex: 1 }}>
            <h4 className="md-subheading-1">Which provider offers this?</h4>
            <SelectField
              id="serviceProviderUid"
              placeholder="Provider"
              defaultValue={service.serviceProviderUid}
              menuItems={this.state.providers}
              className="hf-input"
              onChange={(val) => {
                this.handleInputChange('serviceProviderUid', val)
              }}
              error={typeof errors.type !== 'undefined'}
              errorText={errors.type || defaultErrorText}
              disabled={this.props.uid !== 'create'}
              fullWidth
              required
            />
          </div>
          <div className="md-full-width">
            <h4 className="md-subheading-1">Describe the service</h4>
            <p className="md-caption">Provide a brief summary of the offer.</p>
            <TextField
              id="description"
              placeholder="Description"
              defaultValue={service.description}
              onChange={(val) => {
                this.handleInputChange('description', val)
              }}
              maxLength={255}
              rows={2}
              error={typeof errors.description !== 'undefined'}
              errorText={errors.description || defaultErrorText}
              className="hf-input hf-text-area"
              required
            />
          </div>

          <div className="md-full-width">
            <h4 className="md-subheading-1">Detailed Description</h4>
            <p className="md-caption">Describe this service in more detail.</p>
            <MultilangWysiwyg
              id="longDescription"
              label="Long Description"
              defaultValue={service.longDescription}
              ignoreModel={true}
              field="longDescription"
              txn_field="longDescription_txn"
              locales={[]}
              onChange={(val) => {
                this.handleInputChange('longDescription', val)
              }}
              className="hf-test"
              suppressEnter
            />
          </div>
          <div style={{ flex: 1 }}>
            <br />
            <h4 className="md-subheading-1">Price</h4>
            <p className="md-caption">What is the price of this service?</p>
            <TextField
              id="price"
              placeholder="Price"
              defaultValue={service.price}
              onChange={(val) => {
                this.handleInputChange('price', val)
              }}
              error={typeof errors.price !== 'undefined'}
              errorText={errors.price || defaultErrorText}
              className="hf-input"
              required
            />
          </div>

          <div style={{ flex: 1 }}>
            <h4 className="md-subheading-1">Quantity Description</h4>
            <p className="md-caption">
              If multiple units of this service can be purchased, this is the
              unit to display. Please use the plural form here. Examples include
              "Hours", "People", "Units", "Days", etc.
            </p>
            <TextField
              id="quantityDescription"
              placeholder="Quantity Description"
              defaultValue={service.quantityDescription}
              onChange={(val) => {
                this.handleInputChange('quantityDescription', val)
              }}
              error={typeof errors.quantityDescription !== 'undefined'}
              errorText={errors.quantityDescription || defaultErrorText}
              className="hf-input"
            />
          </div>
          <div className="md-full-width">
            <h4 className="md-subheading-1">Cancellation Policy</h4>
            <p className="md-caption">
              Briefly describe any rules regarding cancellation of this service
            </p>
            <TextField
              id="cancellationPolicy"
              placeholder="Cancellation Policy"
              defaultValue={service.cancellationPolicy}
              onChange={(val) => {
                this.handleInputChange('cancellationPolicy', val)
              }}
              maxLength={255}
              rows={2}
              error={typeof errors.cancellationPolicy !== 'undefined'}
              errorText={errors.cancellationPolicy || defaultErrorText}
              className="hf-input hf-text-area"
            />
          </div>
          {imageSelection}
          {user.isEnterprise ? (
            <ManageGuidebooksAndTemplates
              ownerPluralName="marketplaceItems"
              ownerSingularName="marketplaceItem"
              cardType="marketplaceItem"
              activeTab="guidebooks"
              heading="Which guidebooks or templates should this service appear on?"
              manuallyManageSelection={true}
              initialSelection={this.state.selectedGuidebooks}
              onSelectionChanged={(selection) =>
                this.handleSelectionChanged(selection)
              }
              active={true}
            />
          ) : (
            <ManageSelectedGuidebooks
              ownerPluralName="marketplaceItems"
              ownerSingularName="marketplaceItem"
              cardType="marketplaceItem"
              heading="Which guidebooks should this service appear on?"
              manuallyManageSelection={true}
              initialSelection={this.state.selectedGuidebooks}
              onSelectionChanged={(selection) =>
                this.handleSelectionChanged(selection)
              }
              active={true}
            />
          )}
        </div>

        <div className="hf-marketplace-form-actions">
          <Button
            onClick={this.submitService}
            disabled={this.state.serviceSaving}
            primary
            raised
          >
            Save Service
          </Button>
        </div>
      </div>
    )
  }

  renderMain = () => {
    if (this.state.isLoading) {
      return this.renderLoading('Fetching marketplace data...')
    } else {
      return this.renderEditForm()
    }
  }

  render() {
    const { uid } = this.props

    return (
      <div className="hf-marketplace-form">
        <div className="hf-marketplace-form-header">
          <TooltipButton
            key="nav"
            onClick={this.goBack}
            tooltipLabel="Back"
            tooltipPosition="bottom"
            icon
          >
            <FontIcon>keyboard_backspace</FontIcon>
          </TooltipButton>
          <h2 className="hf-main-title">
            {uid === 'create' ? 'Create' : 'Edit'} Marketplace Item
          </h2>
        </div>
        {this.renderMain()}
      </div>
    )
  }
}

MarketplaceItem.propTypes = propTypes

function mapStateToProps(state, props) {
  const { ownerSingularName } = props
  const owner_data = state[`edit_${ownerSingularName}`]
  return {
    owner_data
  }
}

export default connect(mapStateToProps)(injectIntl(MarketplaceItem))
