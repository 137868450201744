import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { fetchItem } from 'redux/modules/crud'
import { Media, Toolbar } from 'react-md'
import { push } from 'react-router-redux'

class BookingSyncRental extends Component {
  componentDidMount() {
    const { dispatch, itemId } = this.props
    dispatch(fetchItem('bookingsyncrentals', 'bookingsyncrental', itemId))
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, rental_guidebook, itemId } = nextProps
    if ('' + rental_guidebook.bookingsync_id === itemId) {
      dispatch(push('/host/guidebooks/' + rental_guidebook.id))
    }
  }

  render() {
    return (
      <div className="hf-main-container">
        <Toolbar
          colored
          prominent
          className={'hf-host-toolbar hf-bookingsyncrentals'}
          title={'BookingSync Rentals'}
          actions={[]}
        />
        <div className={'md-grid hf-host-paper hf-bookingsyncrentals-paper'}>
          <div className="md-cell md-cell--2 md-cell--tablet-hidden md-cell--phone-hidden"></div>
          <div className="md-cell md-cell--8 hf-paper">
            <div className="md-grid">
              <div className="md-cell md-cell--1 md-cell--tablet-hidden"></div>
              <div className="md-cell md-cell--5 md-cell--4-tablet md-cell--4-phone md-cell--middle">
                <h6 className="md-headline">Fetching Rental Details</h6>
                <p>
                  Please wait while we take information from BookingSync to set
                  up your guidebook
                </p>
              </div>
              <div className="md-cell md-cell--5 md-cell--4-tablet md-cell--4-phone">
                <Media aspectRatio="1-1">
                  <img
                    src="https://storage.googleapis.com/hostfully-test/blank-state-cards.png"
                    alt=""
                  />
                </Media>
              </div>
            </div>
          </div>
          <div className="md-cell md-cell--2 md-cell--tablet-hidden md-cell--phone-hidden"></div>
        </div>
      </div>
    )
  }
}

BookingSyncRental.propTypes = {
  itemId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state, props) {
  const rental_guidebook = state.edit_bookingsyncrental
  return {
    rental_guidebook
  }
}

export default connect(mapStateToProps)(injectIntl(BookingSyncRental))
