import PickerOverlay from 'filestack-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import {
  Autocomplete,
  Button,
  FontIcon,
  LinearProgress,
  Paper,
  SelectField,
  TextField
} from 'react-md'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { isEmail, isISO31661Alpha2 } from 'validator'

import TooltipButton from 'components/TooltipButton'
import { isoCurrencies } from 'constants/Currencies'
import { fixRotation } from 'redux/modules/filestack'
import { createItem, editItem, fetchItem } from 'redux/modules/orbirental/crud'
import { addToast } from 'redux/modules/toast'
import { debounce } from 'utils/Debounce'
import { focusErrors } from 'utils/ValidationHelpers'

const propTypes = {
  uid: PropTypes.string,
  dispatch: PropTypes.func.isRequired
}
const defaultErrorText = 'This field is required'
const filestackKey = process.env.REACT_APP_FILESTACK_KEY

/**
 * This is the Marketplace provider create/edit form
 * we don't use the react-redux-form stuff here because we're not using one of our
 * own models.
 *
 * TODO: probably would be good to encapsulate this create/edit behavior somehow to reuse with other integrations
 * or at least with other orbirental objects.
 *
 *
 */
class MarketplaceProvider extends Component {
  constructor(props, context) {
    super(props)
    this.state = {
      // provider holds the pristine data from the api
      provider: {},
      // provider delta holds any changes we're going to make
      providerDelta: {},
      // errors hold any errors to show on the page
      errors: {},
      isLoading: true,
      providerSaving: false
    }
    this.goBack = this.goBack.bind(this)
    this.requiredFieldRefs = {}
    this.handleInputChange = debounce(this.handleInputChange.bind(this), 250)
    this.imageUploaded = this.imageUploaded.bind(this)
  }

  componentDidMount() {
    this.load()
  }
  componentDidUpdate(prevProps) {
    const { uid } = this.props
    if (prevProps.uid !== uid) {
      this.load()
    }
  }

  goBack = () => {
    const { dispatch } = this.props
    dispatch(push(`/host/marketplace/providers`))
  }

  load = () => {
    const { uid } = this.props
    if (uid !== 'create') {
      fetchItem(uid, 'service_providers', (item) => {
        this.setState({ isLoading: false, provider: item })
      })
    } else {
      // anything else we need for a fresh thingy
      const blankProvider = {
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        type: 'HOST',
        currency: 'USD'
      }
      this.setState({
        isLoading: false,
        provider: blankProvider,
        providerDelta: blankProvider
      })
    }
  }

  renderLoading = (message) => {
    return (
      <div className="md-cell md-cell--12 md-text-center">
        <LinearProgress id="status_check" />
        <div>{message}</div>
      </div>
    )
  }

  handleInputChange = (field, val) => {
    const { provider, providerDelta, errors } = this.state
    const newDelta = Object.assign({}, providerDelta)
    const newErrors = Object.assign({}, errors)
    // if the value is different from the original data
    // AND is different from what's already in the delta
    // then we add to the delta
    if (
      (!provider[field] || provider[field] !== val) &&
      (!providerDelta[field] || providerDelta[field] !== val)
    ) {
      newDelta[field] = val
    }
    // if the value is the same as the original data
    // AND is different from what's in the delta
    // then we remove from the delta
    if (
      provider[field] === val &&
      providerDelta[field] &&
      providerDelta[field] !== val
    ) {
      delete newDelta[field]
    }
    // validate the input as well
    const fieldHasError = this.fieldHasError(field, val)
    // if field has an error, update errors object
    if (fieldHasError) {
      newErrors[field] = fieldHasError
    }
    // if the field is valid but we already had errors for the field
    // remove errors
    if (!fieldHasError && errors[field]) {
      delete newErrors[field]
    }
    this.setState({ providerDelta: newDelta, errors: newErrors })
  }

  imageUploaded(uploadResult) {
    if (uploadResult.filesUploaded && uploadResult.filesUploaded.length > 0) {
      const file = uploadResult.filesUploaded[0]

      fixRotation(file.url).then((data) => {
        if (data.url && data.url.length > 0) {
          this.handleInputChange('imageUrl', data.url)
        }
      })
    }
  }

  validateAllData = () => {
    const { providerDelta, errors } = this.state
    const newErrors = Object.assign({}, errors)
    let errorsFound = false

    Reflect.ownKeys(providerDelta).forEach((field) => {
      const fieldHasError = this.fieldHasError(field, providerDelta[field])
      if (fieldHasError) {
        errorsFound = true
        newErrors[field] = fieldHasError
      }
    })
    if (errorsFound) {
      focusErrors()
      this.setState({ errors: newErrors })
      return false
    }
    return true
  }

  /**
   * for now just validate required fields and basic length
   * TODO: if necessary add some better validation
   */
  fieldHasError = (field, val) => {
    const requiredFields = ['companyName', 'firstName', 'lastName', 'email']
    const maxLengths = {
      companyName: 120,
      firstName: 40,
      lastName: 40,
      currency: 3,
      email: 120,
      phoneNumber: 40
    }
    if (requiredFields.indexOf(field) !== -1 && !val) {
      this.requiredFieldRefs[field].focus()
      return 'This field is required'
    }
    if (maxLengths[field] && maxLengths[field] < val.length) {
      return `Please enter no more than ${maxLengths[field]} characters`
    }
    if (field === 'email' && val.length && !isEmail(val)) {
      return `Please enter a valid email address.`
    }
    if (field === 'countryCode' && val.length && !isISO31661Alpha2(val)) {
      return (
        <span>
          Please enter a valid 2-letter&nbsp;
          <a
            className="hf-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2"
          >
            ISO_3166-1_alpha-2
          </a>
          &nbsp; country code
        </span>
      )
    }
    return false
  }

  /**
   * if we have any changes at all, and everything's valid go ahead and create/edit
   */
  submitProvider = () => {
    const { provider, providerDelta } = this.state
    const { dispatch } = this.props

    // first, see if we have any changes at all
    this.setState({ propertySaving: true }, () => {
      if (Reflect.ownKeys(providerDelta).length) {
        // first validate all fields, then call the create/edit
        if (this.validateAllData()) {
          if (provider.uid) {
            const editData = { service_provider: providerDelta }
            // TODO: add an errorCAllback here
            editItem(provider.uid, 'service_providers', editData, this.goBack)
          } else {
            const createData = { service_provider: providerDelta }
            // TODO: add an errorCAllback here
            createItem('service_providers', createData, this.goBack)
          }
        } else {
          this.setState({ propertySaving: false })
        }
      } else {
        dispatch(addToast("You haven't made any changes"))
        this.setState({ propertySaving: false })
      }
    })
  }

  renderEditForm = () => {
    const { provider, providerDelta, errors } = this.state
    const typeItems = [
      'HOST',
      'CLEANER',
      'GREETER',
      'HANDYMAN',
      'LANDSCAPE',
      'CONTRACTOR',
      'OTHER'
    ]
    const filestackOptions = {
      accept: 'image/*',
      maxFiles: 1,
      fromSources: ['local_file_system', 'url', 'imagesearch'],
      storeTo: {
        location: 'gcs'
      },
      imageMax: [1400, 800],
      transformations: {
        crop: {
          aspectRatio: 4 / 3,
          force: true
        },
        rotate: true
      }
    }
    const imageSrc = provider.imageUrl || providerDelta.imageUrl

    const imageSelection = (
      <Paper key="card-image" className="hf-marketplace-form-paper">
        {imageSrc ? (
          <img
            src={imageSrc}
            alt="marketplace item"
            className="hf-marketplace-form-image"
          />
        ) : (
          <h4 className="md-subheading-1">
            Do you have an image to represent this service?
          </h4>
        )}
        <PickerOverlay
          apikey={filestackKey}
          customRender={({ onPick }) => (
            <Button onClick={onPick} secondary raised>
              {imageSrc ? 'Update Image' : 'Select Image'}
            </Button>
          )}
          actionOptions={filestackOptions}
          onSuccess={this.imageUploaded}
        />
      </Paper>
    )

    return (
      <div>
        <div className="hf-form-container">
          <div style={{ flex: 2 }}>
            <h4 className="md-subheading-1">Provider Name</h4>
            <div className="hf-form-group">
              <TextField
                id="companyName"
                placeholder="Provider name"
                ref={(input) => {
                  this.requiredFieldRefs['companyName'] = input
                }}
                defaultValue={provider.companyName}
                onChange={(val) => {
                  this.handleInputChange('companyName', val)
                }}
                maxLength={120}
                error={typeof errors.companyName !== 'undefined'}
                errorText={errors.companyName || defaultErrorText}
                className="hf-input"
                required
              />
            </div>
          </div>

          <div style={{ flex: 1 }}>
            <h4 className="md-subheading-1">Provider Type</h4>
            <div className="hf-form-group">
              <SelectField
                id="type"
                placeholder="Provider type"
                defaultValue={provider.type}
                menuItems={typeItems}
                sameWidth
                onChange={(val) => {
                  this.handleInputChange('type', val)
                }}
                error={typeof errors.type !== 'undefined'}
                errorText={errors.type || defaultErrorText}
                className="hf-input"
                fullWidth
                required
              />
            </div>
          </div>

          <div style={{ flex: 1 }}>
            <h4 className="md-subheading-1">Currency</h4>
            <div className="hf-form-group">
              <Autocomplete
                id="currency"
                placeholder="Provider currency"
                data={isoCurrencies}
                filter={Autocomplete.fuzzyFilter}
                dataLabel="name"
                dataValue="key"
                defaultValue={provider.currency || 'USD - US Dollar'}
                onAutocomplete={(val) => {
                  this.handleInputChange('currency', val)
                }}
                error={typeof errors.currency !== 'undefined'}
                errorText={errors.currency || defaultErrorText}
                className="hf-input"
              />
            </div>
          </div>

          <div className="md-full-width">
            <h4 className="md-subheading-1">Provider Contact Information</h4>
            <div className="hf-form-group">
              <TextField
                id="firstName"
                placeholder="First name"
                ref={(input) => {
                  this.requiredFieldRefs['firstName'] = input
                }}
                defaultValue={provider.firstName}
                onChange={(val) => {
                  this.handleInputChange('firstName', val)
                }}
                maxLength={40}
                error={typeof errors.firstName !== 'undefined'}
                errorText={errors.firstName || defaultErrorText}
                className="hf-input"
                required
              />
              <TextField
                id="lastName"
                placeholder="Last name"
                ref={(input) => {
                  this.requiredFieldRefs['lastName'] = input
                }}
                defaultValue={provider.lastName}
                onChange={(val) => {
                  this.handleInputChange('lastName', val)
                }}
                maxLength={40}
                error={typeof errors.lastName !== 'undefined'}
                errorText={errors.lastName || defaultErrorText}
                className="hf-input"
                required
              />
              <TextField
                id="email"
                placeholder="Contact email"
                required
                ref={(input) => {
                  this.requiredFieldRefs['email'] = input
                }}
                defaultValue={provider.email}
                onChange={(val) => {
                  this.handleInputChange('email', val)
                }}
                maxLength={120}
                error={typeof errors.email !== 'undefined'}
                errorText={errors.email || defaultErrorText}
                className="hf-input"
              />
              <TextField
                id="phoneNumber"
                placeholder="Contact phone"
                defaultValue={provider.phoneNumber}
                onChange={(val) => {
                  this.handleInputChange('phoneNumber', val)
                }}
                maxLength={40}
                error={typeof errors.phoneNumber !== 'undefined'}
                errorText={errors.phoneNumber || defaultErrorText}
                className="hf-input"
              />
            </div>
          </div>

          <div className="md-full-width">
            <h4 className="md-subheading-1">Provider Address</h4>
            <div className="hf-form-group">
              <TextField
                id="address"
                placeholder="Street Address"
                defaultValue={provider.address}
                onChange={(val) => {
                  this.handleInputChange('address', val)
                }}
                maxLength={255}
                error={typeof errors.address !== 'undefined'}
                errorText={errors.address || defaultErrorText}
                className="hf-input"
                style={{ flex: '50%' }}
              />
              <TextField
                id="city"
                placeholder="City"
                defaultValue={provider.city}
                onChange={(val) => {
                  this.handleInputChange('city', val)
                }}
                maxLength={120}
                error={typeof errors.city !== 'undefined'}
                errorText={errors.city || defaultErrorText}
                className="hf-input"
                style={{ flex: '40%' }}
              />
              <TextField
                id="state"
                placeholder="State"
                defaultValue={provider.state}
                onChange={(val) => {
                  this.handleInputChange('state', val)
                }}
                maxLength={120}
                error={typeof errors.state !== 'undefined'}
                errorText={errors.state || defaultErrorText}
                className="hf-input"
                style={{ flex: '36%' }}
              />
              <TextField
                id="zipCode"
                placeholder="Postal Code"
                defaultValue={provider.zipCode}
                onChange={(val) => {
                  this.handleInputChange('zipCode', val)
                }}
                maxLength={40}
                error={typeof errors.zipCode !== 'undefined'}
                errorText={errors.zipCode || defaultErrorText}
                className="hf-input"
                style={{ flex: '30%' }}
              />
              <TextField
                id="countryCode"
                placeholder="Country Code"
                defaultValue={provider.countryCode}
                onChange={(val) => {
                  this.handleInputChange('countryCode', val)
                }}
                maxLength={2}
                error={typeof errors.countryCode !== 'undefined'}
                errorText={errors.countryCode || defaultErrorText}
                className="hf-input"
                style={{ flex: '20%' }}
              />
            </div>
          </div>

          {imageSelection}
        </div>
        <div className="hf-marketplace-form-actions">
          <Button
            onClick={this.submitProvider}
            disabled={this.state.propertySaving}
            primary
            raised
          >
            Save Service
          </Button>
        </div>
      </div>
    )
  }

  renderMain = () => {
    if (this.state.isLoading) {
      return this.renderLoading('Fetching provider data...')
    } else {
      return this.renderEditForm()
    }
  }

  render() {
    const { uid } = this.props

    return (
      <div className="hf-marketplace-form">
        <div className="hf-marketplace-form-header">
          <TooltipButton
            key="nav"
            onClick={this.goBack}
            tooltipLabel="Back"
            tooltipPosition="bottom"
            icon
          >
            <FontIcon>keyboard_backspace</FontIcon>
          </TooltipButton>
          <h2 className="hf-main-title">
            {uid === 'create' ? 'Create' : 'Edit'} Marketplace Provider
          </h2>
        </div>
        {this.renderMain()}
      </div>
    )
  }
}

MarketplaceProvider.propTypes = propTypes

export default connect()(injectIntl(MarketplaceProvider))
