import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { withCookies } from 'react-cookie'
import { acceptTerms } from 'redux/modules/guestTerms'
import UAParser from 'ua-parser-js'
import Slider from 'react-slick'
import cn from 'classnames'
import { Button, Media, MediaOverlay, TextField } from 'react-md'
import OnboardingSlide from 'components/Onboarding/OnboardingSlide'
import LocaleSwitcher from 'components/LocaleSwitcher'
import Translator from 'components/Translator'
import AsHtml from 'components/Translator/AsHtml'
import { splashMessages } from 'constants/UIConstants'
import { translateString } from 'utils/I18nHelper'
// import { trackEvent, getHostIdAndKey } from 'utils/Segment';
import { stringToId, ucFirst } from 'utils/Strings'
import { resizeImage } from 'utils/Image'
import { checkDomain } from 'utils/Urls'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  // helper for internationalization
  intl: intlShape.isRequired
}

const defaultProps = {}

class SplashScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      customFields: this.prepareCustomFields(),
      emailValid: !props.guidebook.theme.splash_require_email,
      emailTouched: false,
      isValid: false,
      localesShown: false
    }
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this)
    this.handleLastNameChange = this.handleLastNameChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleKeyPressed = this.handleKeyPressed.bind(this)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    // track this event in segment
    if (
      this.props.guidebook.key &&
      this.props.guidebook.key !== 'loading' &&
      this.props.guidebook.host_user_id
    ) {
      // OLD SEGMENT CODE
      // let eventData = getHostIdAndKey(this.props.guidebook);
      // if (eventData.key) eventData.label = eventData.key;
      // eventData.category = 'Guidebook';
      // trackEvent('splashScreenViewed', eventData);
    }
  }

  setSplashCookie = () => {
    const { cookies } = this.props
    const cookieName = `hf_splash_${this.props.guidebook.key}`
    const cookieValue = this.props.guidebook.key
    // remember preference with cookie
    const expiresDate = new Date()
    expiresDate.setMonth(expiresDate.getMonth() + 6)
    const cookieOptions = {
      path: '/',
      expires: expiresDate,
      secure: true
    }
    cookies.set(cookieName, cookieValue, cookieOptions)
  }

  handleFirstNameChange = (value) => {
    this.setState({ first_name: value }, () => {
      this.checkFormValidity()
    })
  }

  handleLastNameChange = (value) => {
    this.setState({ last_name: value }, () => {
      this.checkFormValidity()
    })
  }

  handleEmailChange = (value) => {
    this.setState({ email: value, emailTouched: true }, () => {
      this.validateEmail()
    })
  }

  handleCustomFieldChange = (field, value) => {
    // console.log({field: field, value: value});
    const customFields = Object.assign({}, this.state.customFields)
    customFields[field] = value
    this.setState({ customFields: customFields }, () => {
      this.checkFormValidity()
    })
  }

  validateEmail = () => {
    if (
      (!this.state.email && !this.props.guidebook.theme.splash_require_email) ||
      /^.+@.+\..+$/.test(this.state.email)
    ) {
      this.setState({ emailValid: true }, () => {
        this.checkFormValidity()
      })
    } else {
      this.setState({ emailValid: false }, () => {
        this.checkFormValidity()
      })
    }
  }

  prepareCustomFields = () => {
    const { splash_custom_fields } = this.props.guidebook.theme
    const customFields = {}
    if (splash_custom_fields.data && splash_custom_fields.data.length) {
      for (var i = 0; i < splash_custom_fields.data.length; i++) {
        customFields[stringToId(splash_custom_fields.data[i].name)] = ''
      }
    }
    return customFields
  }

  handleKeyPressed = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmit(event)
    }
  }

  checkFormValidity = () => {
    if (
      this.state.first_name &&
      this.state.last_name &&
      this.state.emailValid &&
      this.customFieldsValid()
    ) {
      this.setState({ isValid: true })
    } else {
      this.setState({ isValid: false })
    }
  }

  customFieldsValid = () => {
    // if there are no custom fields defined, then it's valid
    const { splash_custom_fields } = this.props.guidebook.theme
    const { customFields } = this.state
    if (!splash_custom_fields.data || splash_custom_fields.data.length === 0) {
      return true
    } else {
      const { data } = splash_custom_fields
      for (var i = 0, len = data.length; i < len; i++) {
        if (data[i].required === false) {
          continue
        } else if (
          data[i].required &&
          !customFields[stringToId(data[i].name)]
        ) {
          return false
        }
      }
      return true
    }
  }

  next = () => {
    this.slider.slickNext()
  }

  previous = () => {
    this.slider.slickPrev()
  }

  handleSlideChanged = (index) => {
    // track this event in segment
    // OLD SEGMENT CODE
    // let eventData = getHostIdAndKey(this.props.guidebook);
    // if (eventData.key) eventData.label = eventData.key;
    // eventData.category = 'Guidebook';
    // eventData.current_slide = index;
    // trackEvent('splashScreenSlideChanged', eventData);
  }

  handleSubmit = (value) => {
    if (this.state.isValid) {
      this.setState({ isValid: false })
      // we should have first_name and last_name here now, and guidebook id from props
      const data = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        guidebook_id: this.props.guidebook.id,
        custom_data: this.state.customFields
      }
      // see if we got an email address
      if (this.state.email) data.email = this.state.email
      // add in parsed data from user agent string
      var ua = new UAParser()
      data.platform = ua.getOS().name // . ' ' . ua.getOS().version;
      data.browser = ua.getBrowser().name // . ' ' . ua.getBrowser().version;
      data.device_type = ua.getDevice().type || 'desktop'

      // OLD SEGMENT CODE
      // track this event in segment
      // let eventData = getHostIdAndKey(this.props.guidebook);
      // if (eventData.key) eventData.label = eventData.key;
      // eventData.category = 'Guidebook';
      // eventData.email_submitted = (data.email && data.email.length > 0);
      // trackEvent('splashScreenTermsSubmitted', eventData);

      // call the redux function
      acceptTerms(data).then((response) => {
        this.setState({ isValid: true })
        if (response && response.data && response.data.id) {
          // success!  now drop that cookie
          this.setSplashCookie()
          setTimeout(() => {
            const { dispatch } = this.props
            dispatch(push('/' + this.props.guidebook.key))
          }, 250)
        }
      })
    }
  }

  localesShown = (value) => {
    this.setState({ localesShown: value })
  }

  renderLogo = (extra_classes) => {
    const logoSrc = resizeImage(
      this.props.guidebook.theme.logo,
      320,
      null,
      true
    )
    const logoStyle = {
      backgroundImage: `url(${logoSrc})`
    }

    const classNames = cn('splash-logo', extra_classes || '')
    return <div className={classNames} style={logoStyle} />
  }

  renderPageOne = () => {
    const { intl, guidebook } = this.props
    const { formatMessage } = intl
    const { theme } = guidebook
    const buttonStyle = theme.primary_color
      ? { background: theme.primary_color }
      : {}

    let illustration = null
    if (guidebook && guidebook.image) {
      const image = resizeImage(guidebook.image, 400, 400, true)
      illustration = (
        <Media aspectRatio="1-1">
          <img
            src={image}
            alt={formatMessage(splashMessages.listingAlt)}
            role="presentation"
          />
          <MediaOverlay>{this.renderLogo('cover')}</MediaOverlay>
        </Media>
      )
    }

    const copy = (
      <div>
        <h3>{formatMessage(splashMessages.needToKnow)}</h3>
        <p className="read-terms">
          {formatMessage(splashMessages.readAcceptTerms)}
        </p>
        <Button
          raised
          primary
          className="splash-button"
          style={buttonStyle}
          onClick={this.next}
        >
          {formatMessage(splashMessages.reviewTerms)}
        </Button>
      </div>
    )

    return (
      <OnboardingSlide
        className="page-one"
        illustration={illustration}
        copy={copy}
      />
    )
  }

  renderPageTwo = () => {
    const { theme } = this.props.guidebook
    const buttonStyle = theme.primary_color
      ? { background: theme.primary_color }
      : {}
    const termsLabel = (
      <Translator
        text={theme.splash_button_text}
        translations={theme.splash_button_text_txn}
      />
    )
    const termsContent = (
      <AsHtml
        text={theme.splash_content_text}
        translations={theme.splash_content_text_txn}
      />
    )
    const contentStyle = theme.primary_color
      ? { borderColor: theme.primary_color }
      : {}

    const illustration = (
      <div>
        {this.renderLogo()}
        <div className="term-content" style={contentStyle}>
          <div className="term-content-inner" dir="auto">
            {termsContent}
          </div>
        </div>
      </div>
    )

    const copy = (
      <Button
        raised
        primary
        className="splash-button"
        style={buttonStyle}
        onClick={this.next}
      >
        {termsLabel}
      </Button>
    )

    return (
      <OnboardingSlide
        className="page-two"
        illustration={illustration}
        copy={copy}
        flexible
      />
    )
  }

  renderCustomFields = () => {
    const { formatMessage } = this.props.intl
    // const {customFields} = this.state;
    const customFieldDefinitions =
      this.props.guidebook.theme.splash_custom_fields
    if (customFieldDefinitions.data && customFieldDefinitions.data.length) {
      return customFieldDefinitions.data.map((item) => {
        const translations = item.name_txn || {}
        let translatedName = ucFirst(
          translateString(this.props.intl.locale, item.name, translations)
        )

        return (
          <TextField
            id={stringToId(item.name)}
            key={stringToId(item.name)}
            label={translatedName}
            lineDirection="center"
            className="md-cell md-cell--12 md-cell--bottom no-zoom"
            value={this.state.customFields[stringToId(item.name)]}
            onChange={(value) => {
              this.handleCustomFieldChange(stringToId(item.name), value)
            }}
            required={item.required}
            errorText={formatMessage(splashMessages.fieldRequired)}
          />
        )
      })
    }
    return null
  }

  renderPageThree = () => {
    const { intl, guidebook } = this.props
    const { formatMessage } = intl
    const { theme } = guidebook
    const buttonStyle = theme.primary_color
      ? { background: theme.primary_color }
      : {}

    const emailField = theme.splash_collect_email ? (
      <TextField
        id="email"
        label={formatMessage(splashMessages.email)}
        lineDirection="center"
        className="md-cell md-cell--12 md-cell--bottom no-zoom"
        value={this.state.email}
        error={this.state.emailTouched && !this.state.emailValid}
        required={theme.splash_require_email}
        errorText={formatMessage(splashMessages.emailError)}
        onChange={this.handleEmailChange}
      />
    ) : null

    const illustration = (
      <div className="page-three-header">
        {this.renderLogo()}
        <div className="input-description">
          {formatMessage(splashMessages.supplyDetails)}
        </div>
      </div>
    )

    const copy = (
      <div className="input-form" onKeyDown={(e) => this.handleKeyPressed(e)}>
        <div className="md-grid md-grid--no-spacing input-form-inner">
          <TextField
            id="first_name"
            label={formatMessage(splashMessages.firstName)}
            lineDirection="center"
            className="md-cell md-cell--12 md-cell--bottom no-zoom"
            value={this.state.first_name}
            onChange={this.handleFirstNameChange}
            required
            errorText={formatMessage(splashMessages.fieldRequired)}
          />
          <TextField
            id="last_name"
            label={formatMessage(splashMessages.lastName)}
            lineDirection="center"
            className="md-cell md-cell--12 md-cell--bottom no-zoom"
            value={this.state.last_name}
            onChange={this.handleLastNameChange}
            required
            errorText={formatMessage(splashMessages.fieldRequired)}
          />
          {emailField}
          {this.renderCustomFields()}
          <div className="md-cell md-cell--12">
            <Button
              raised
              primary
              className="splash-button"
              style={buttonStyle}
              onClick={this.handleSubmit}
              disabled={!this.state.isValid}
            >
              {formatMessage(splashMessages.getAccess)}
            </Button>
          </div>
        </div>
      </div>
    )

    return (
      <OnboardingSlide
        className="page-three"
        illustration={illustration}
        copy={copy}
        flexible
      />
    )
  }

  render() {
    checkDomain(this.props.guidebook)
    const self = this
    const ua = new UAParser()
    let device_type = ua.getDevice().type
    if (device_type !== 'mobile' && device_type !== 'tablet') {
      device_type = 'desktop'
    }

    const sliderSettings = {
      dots: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      lazyLoad: false,
      draggable: false,
      swipe: false
    }

    const classNames = cn('splash-screen', device_type)
    const sliderClass = this.state.localesShown ? 'slider-locale-offset' : ''

    return (
      <div className={classNames}>
        <LocaleSwitcher
          persistant
          onShown={(e) => {
            self.localesShown(true)
          }}
          onHidden={(e) => {
            self.localesShown(false)
          }}
        />
        <Slider
          className={sliderClass}
          ref={(c) => (this.slider = c)}
          {...sliderSettings}
          afterChange={(index) => {
            this.handleSlideChanged(index)
          }}
        >
          {this.renderPageOne()}
          {this.renderPageTwo()}
          {this.renderPageThree()}
        </Slider>
      </div>
    )
  }
}

SplashScreen.propTypes = propTypes
SplashScreen.defaultProps = defaultProps

export default withCookies(connect()(injectIntl(SplashScreen)))
