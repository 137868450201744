import React from 'react'

const DirectionsRun = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`directions_run`}</title>
    <path d="M9.891 19.359L2.907 18l.375-2.016 4.922.984 1.594-8.109-1.828.703v3.422H6.001V8.296l5.203-2.203c.281 0 .516-.094.797-.094.703 0 1.266.375 1.688.984l.984 1.594c.797 1.406 2.391 2.391 4.313 2.391v2.016a7.289 7.289 0 01-5.484-2.484l-.609 3 2.109 1.969v7.5h-2.016v-6L10.877 15zM13.5 5.484c-1.078 0-2.016-.938-2.016-2.016s.938-1.969 2.016-1.969 1.969.891 1.969 1.969-.891 2.016-1.969 2.016z" />
  </svg>
)

export default DirectionsRun
