import React from 'react'

const LibraryBooks = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`library_books`}</title>
    <path d="M18.984 6.984V5.015H9v1.969h9.984zM15 15v-2.016H9V15h6zm3.984-3.984V9H9v2.016h9.984zm1.032-9c1.078 0 1.969.891 1.969 1.969v12c0 1.078-.891 2.016-1.969 2.016h-12C6.938 18.001 6 17.063 6 15.985v-12c0-1.078.938-1.969 2.016-1.969h12zM3.984 6v14.016H18v1.969H3.984a1.981 1.981 0 01-1.969-1.969V6h1.969z" />
  </svg>
)

export default LibraryBooks
