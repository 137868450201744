import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Avatar, FontIcon, ListItem } from 'react-md'
import currentUser from 'utils/CurrentUser'

class UserSettingListItem extends Component {
  render() {
    const { item, editItem, deleteItem, pluralName } = this.props
    // use first letter of name if no icon
    let avatarIcon = 'V'
    const avatarStyle = { backgroundColor: '#3f51b5' }

    const user = currentUser()
    // console.log(user);
    const threeLines = false
    console.log(item)
    let secondaryText = (
      <span className="domain-status">
        Viator Partner Id: {item.viator_partner_id}
      </span>
    )
    const userSetting = (
      <ListItem
        onClick={editItem}
        className={'hf-list-item hf-' + pluralName + 'list-item'}
        leftAvatar={
          <Avatar
            className={'hf-' + pluralName + 'list-item'}
            style={avatarStyle}
            alt=""
          >
            {avatarIcon}
          </Avatar>
        }
        primaryText={`User: ${user.id} (${user.user.email})`}
        secondaryText={secondaryText}
        rightIcon={<FontIcon onClick={deleteItem}>delete</FontIcon>}
        threeLines={threeLines}
      />
    )

    return userSetting
  }
}

UserSettingListItem.propTypes = {
  item: PropTypes.object.isRequired
}
export default UserSettingListItem
