import React from 'react'

const LocalBar = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`local_bar`}</title>
    <path d="M7.453 6.984h9.094l1.781-1.969H5.672zM21 5.016l-8.016 9v4.969H18v2.016H6v-2.016h5.016v-4.969L3 5.016V3h18v2.016z" />
  </svg>
)

export default LocalBar
