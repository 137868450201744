import React from 'react'

const AcUnit = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`ac_unit`}</title>
    <path d="M21.984 11.016v1.969h-4.172l3.234 3.234-1.406 1.453-4.641-4.688h-2.016V15l4.688 4.641-1.453 1.406-3.234-3.234v4.172h-1.969v-4.172l-3.234 3.234-1.453-1.406L11.016 15v-2.016H9l-4.641 4.688-1.406-1.453 3.234-3.234H2.015v-1.969h4.172L2.953 7.782l1.406-1.453L9 11.017h2.016V9.001L6.328 4.36l1.453-1.406 3.234 3.234V2.016h1.969v4.172l3.234-3.234 1.453 1.406-4.688 4.641v2.016h2.016l4.641-4.688 1.406 1.453-3.234 3.234h4.172z" />
  </svg>
)

export default AcUnit
