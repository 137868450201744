import Breakjs from 'breakjs'
import { layoutGenerator } from 'react-break'

const breakpoints = {
  mobile: 0,
  phablet: 550,
  tablet: 768,
  desktop: 1024,
  wide: 1368,
  superwide: 1500
}

const layout = layoutGenerator(breakpoints)
export const OnAtMostPhablet = layout.isAtMost('phablet')
export const OnAtLeastTablet = layout.isAtLeast('tablet')
export const OnAtMostTablet = layout.isAtMost('tablet')
export const OnAtLeastDesktop = layout.isAtLeast('desktop')

const breakLayout = Breakjs(breakpoints)
export const atMostPhablet = breakLayout.atMost('phablet')
export const atLeastPhablet = breakLayout.atLeast('phablet')
export const atMostTablet = breakLayout.atMost('tablet')
export const atLeastTablet = breakLayout.atLeast('tablet')
export const atLeastDesktop = breakLayout.atLeast('desktop')
