import React from 'react'

const Firepit = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`firepit`}</title>
    <path d="M11.8 14.8h.2c1.9 0 3.5-1.6 3.5-3.5v-.2c0-1.1-.2-2.1-.5-3.1v.1c-.8 1-2.2 1.7-3.5 2s-2.2 1.2-2.2 2.3c0 1.3 1 2.3 2.3 2.3.1.1.2.1.2.1zm1.4-13.6c3 2.3 4.8 6 4.8 10 0 3.3-2.7 6-6 6s-6-2.7-6-6c0-2.6 1-4.9 2.5-6.7v.3c0 1.5 1.2 2.8 2.7 2.8s2.5-1.2 2.5-2.8-.5-3.6-.5-3.6zM1.3 18.2h21.3v4.7H1.3v-4.7z" />
  </svg>
)

export default Firepit
