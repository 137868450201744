import Breakjs from 'breakjs'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withCookies } from 'react-cookie'
import DocumentMeta from 'react-document-meta'
import DocumentTitle from 'react-document-title'
import { CircularProgress } from 'react-md'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'

import BottomNav from 'components/BottomNav'
import LandingPage from 'components/LandingPage'
import NavDrawer from 'components/NavDrawer'
import SafelySplash from 'components/SafelySplash'
import { OnAtMostTablet } from 'components/ScreenSize'
import SplashScreen from 'components/SplashScreen'
import { nativeLanguageNames } from 'constants/UIConstants'
import { setLocale } from 'redux/modules/auth'
import { fetchGuidebookData, fetchRecsOnlyData } from 'redux/modules/guidebook'
import currentUser from 'utils/CurrentUser'
import { bootIntercom, updateIntercom } from 'utils/Intercom'
import { updateManifest } from 'utils/Manifest'
import { buildGuidebookUrl, parseBool, parseQueryString } from 'utils/Urls'

import Guidebook from './Guidebook'

const breakLayout = Breakjs({
  mobile: 0,
  phablet: 550,
  desktop: 1024,
  superwide: 1500
})

class GuidebookContainer extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      showNavDrawer: false,
      forceSplash: null,
      forceLanding: null,
      forceSafely: null,
      layout: breakLayout.current()
    }
    this.showNavDrawer = this.showNavDrawer.bind(this)
    this.onNavDrawerClose = this.onNavDrawerClose.bind(this)
    this.onLayoutChange = this.onLayoutChange.bind(this)
  }

  componentWillMount() {
    const qs = parseQueryString(this.context.router.route.location.search)
    // inspect the querystring for important stuff
    if (qs.hasOwnProperty('nosplash')) {
      this.setTempSplashCookie()
      this.setState({ forceSplash: false })
    } else if (qs.hasOwnProperty('showsplash')) {
      this.removeSplashCookie()
      this.setState({ forceSplash: true })
    }
    if (qs.hasOwnProperty('nolanding')) {
      this.setTempLandingCookie()
      this.setState({ forceLanding: false })
    } else if (qs.hasOwnProperty('showlanding')) {
      this.removeLandingCookie()
      this.setState({ forceLanding: true })
    }
    if (qs.hasOwnProperty('nosafely')) {
      this.setTempSafelyCookie()
      this.setState({ forceSafely: false })
    } else if (qs.hasOwnProperty('showsafely')) {
      this.removeSafelyCookie()
      this.setState({ forceSafely: true })
    }
  }

  componentDidMount() {
    this.fetchGuidebookData(this.props)
    const icOptions = {
      alignment: 'right',
      vertical_padding:
        this.state.layout === 'mobile' || this.state.layout === 'phablet'
          ? 60
          : 20,
      horizontal_padding:
        this.state.layout === 'mobile' || this.state.layout === 'phablet'
          ? 20
          : 60
    }
    bootIntercom(icOptions)
    breakLayout.addChangeListener(this.onLayoutChange)
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match.params.guidebookKey !==
      this.props.match.params.guidebookKey
    ) {
      this.fetchGuidebookData(nextProps)
    } else if (
      nextProps.match.params.recsOnlyKey !== this.props.match.params.recsOnlyKey
    ) {
      this.fetchGuidebookData(nextProps)
    }
    if (
      (!this.props.guidebook || !this.props.guidebook.id) &&
      nextProps.guidebook &&
      nextProps.guidebook.id
    ) {
      const { recsOnly, guidebook } = nextProps
      const title = recsOnly
        ? guidebook.recs_only_name
          ? guidebook.recs_only_name
          : 'Recommendations'
        : guidebook.name
      const data = { short_name: `Hostfully - ${title}` }
      updateManifest(data)
    }
  }

  componentWillUpdate(_nextProps, nextState) {
    if (this.state.layout !== nextState.layout) {
      const vertical_padding =
        nextState.layout === 'mobile' || nextState.layout === 'phablet'
          ? 60
          : 20
      const horizontal_padding =
        nextState.layout === 'mobile' || nextState.layout === 'phablet'
          ? 20
          : 60
      const icOptions = {
        horizontal_padding: horizontal_padding,
        vertical_padding: vertical_padding
      }
      updateIntercom(icOptions)
    }
  }

  componentWillUnmount() {
    breakLayout.removeChangeListener(this.onLayoutChange)
  }

  onLayoutChange = (layout) => {
    this.setState({ layout: layout })
  }

  fetchGuidebookData(props) {
    const { match, recsOnly } = props
    const { guidebookKey, recsOnlyKey } = match.params
    let showAllRecs = false
    let token = undefined
    const qs = parseQueryString(this.context.router.route.location.search)
    // do we have a secure token?
    const existingCookie = this.checkTokenCookie(guidebookKey)
    if (existingCookie) token = existingCookie
    const sponsoredCookie = this.checkSponsoredCookie(guidebookKey)
    if (sponsoredCookie) showAllRecs = true
    if (qs.hasOwnProperty('st')) token = qs.st
    if (qs.hasOwnProperty('ss')) {
      showAllRecs = parseBool(qs.ss, false)
      this.setSponsoredCookie(showAllRecs, guidebookKey)
    }
    if (token) {
      // we have a token - let's re-fetch the guidebook using it
      this.setTokenCookie(token, guidebookKey)
    }
    const path = this.context.router.route.location.pathname
    const insertShortLinks =
      guidebookKey && path && path === `/${guidebookKey}/print`
    if (recsOnly) {
      this.props.dispatch(fetchRecsOnlyData(recsOnlyKey, showAllRecs))
    } else {
      this.props.dispatch(
        fetchGuidebookData(guidebookKey, token, showAllRecs, insertShortLinks)
      )
    }
  }

  showNavDrawer() {
    this.setState({
      showNavDrawer: true
    })
  }

  onNavDrawerClose() {
    this.setState({
      showNavDrawer: false
    })
  }

  detectSecureOnly = () => {
    const user = currentUser()
    const { guidebook } = this.props
    if (
      guidebook &&
      guidebook.secure_access_only &&
      !guidebook.is_secure &&
      guidebook.host_user_id !== user.id
    ) {
      return true
    }
    return false
  }

  detectShowLandingPage = () => {
    if (this.state.forceLanding === true) {
      return true
    } else if (this.state.forceLanding === false) {
      return false
    }

    if (
      this.props.guidebook.host_intro &&
      this.props.guidebook.host_intro.show_landing &&
      !this.checkLandingCookie()
    ) {
      return true
    }
    return false
  }

  detectShowSplashScreen = () => {
    if (this.state.forceSplash === true) {
      return true
    } else if (this.state.forceSplash === false) {
      return false
    }

    if (
      this.props.guidebook.theme &&
      this.props.guidebook.theme.show_splash_screen &&
      !this.checkSplashCookie()
    ) {
      return true
    }
    return false
  }

  detectShowSafelySplash = () => {
    if (this.state.forceSafely === true) {
      return true
    } else if (this.state.forceSafely === false) {
      return false
    }
    if (this.props.guidebook.is_safely && !this.checkSafelyCookie()) {
      return true
    }
    return false
  }

  removeSplashCookie = () => {
    const { cookies } = this.props
    const cookieName = `hf_splash_${this.props.guidebook.key}`
    cookies.remove(cookieName)
  }

  checkSplashCookie = () => {
    const { cookies } = this.props
    const cookieName = `hf_splash_${this.props.guidebook.key}`
    const cookieVal = cookies.get(cookieName)
    if (cookieVal) return true
    return false
  }

  removeLandingCookie = () => {
    const { cookies } = this.props
    const cookieName = `hf_landing_${this.props.guidebook.key}`
    cookies.remove(cookieName)
  }

  checkLandingCookie = () => {
    const { cookies } = this.props
    const cookieName = `hf_landing_${this.props.guidebook.key}`
    const cookieVal = cookies.get(cookieName)
    if (cookieVal) return true
    return false
  }

  removeSafelyCookie = () => {
    const { cookies } = this.props
    const cookieName = `hf_safely_splash_${this.props.guidebook.key}`
    cookies.remove(cookieName)
  }

  checkSafelyCookie = () => {
    const { cookies } = this.props
    const cookieName = `hf_safely_splash_${this.props.guidebook.key}`
    const cookieVal = cookies.get(cookieName)
    if (cookieVal) return true
    return false
  }

  setLanguage(locale) {
    const self = this
    const { guidebook, cookies } = this.props
    // OLD SEGMENT CODE
    // track this event in segment
    // let eventData = getHostIdAndKey(this.props.guidebook);
    // eventData.label = locale;
    // eventData.category = 'Guidebook';
    // trackEvent('languageChanged', eventData, {}, function(){
    // cookie lasts 6 months
    const expiresDate = new Date()
    expiresDate.setMonth(expiresDate.getMonth() + 6)
    const cookieOptions = {
      path: '/',
      expires: expiresDate,
      sameSite: 'strict'
    }
    cookies.set(`hf_lang_${guidebook.key}`, locale, cookieOptions)
    self.props.dispatch(setLocale(locale))
    // });
  }

  setTempSafelyCookie = () => {
    const { cookies, guidebook } = this.props
    const cookieName = `hf_safely_splash_${guidebook.key}`
    const cookieValue = guidebook.key
    // remember preference with cookie
    const expiresDate = new Date()
    expiresDate.setHours(expiresDate.getHours() + 1)
    const cookieOptions = {
      path: '/',
      expires: expiresDate,
      sameSite: 'strict'
    }
    cookies.set(cookieName, cookieValue, cookieOptions)
  }

  setTempLandingCookie = () => {
    const { cookies, guidebook } = this.props
    const cookieName = `hf_landing_${guidebook.key}`
    const cookieValue = guidebook.key
    // remember preference with cookie
    const expiresDate = new Date()
    expiresDate.setHours(expiresDate.getHours() + 1)
    const cookieOptions = {
      path: '/',
      expires: expiresDate,
      sameSite: 'strict'
    }
    cookies.set(cookieName, cookieValue, cookieOptions)
  }

  setTempSplashCookie = () => {
    const { cookies, guidebook } = this.props
    const cookieName = `hf_splash_${guidebook.key}`
    const cookieValue = guidebook.key
    // remember preference with cookie
    const expiresDate = new Date()
    expiresDate.setHours(expiresDate.getHours() + 1)
    const cookieOptions = {
      path: '/',
      expires: expiresDate,
      sameSite: 'strict'
    }
    cookies.set(cookieName, cookieValue, cookieOptions)
  }

  checkTokenCookie = (key) => {
    const { cookies } = this.props
    const cookieName = `hf_st_${key}`
    const cookieVal = cookies.get(cookieName)
    return cookieVal
  }

  setTokenCookie = (token, key) => {
    const { cookies } = this.props
    const cookieName = `hf_st_${key}`
    const cookieValue = token
    // remember preference with cookie
    const expiresDate = new Date()
    // cookie lasts 2 weeks
    expiresDate.setDate(expiresDate.getDate() + 14)
    const cookieOptions = {
      path: '/',
      expires: expiresDate,
      sameSite: 'strict'
    }
    cookies.set(cookieName, cookieValue, cookieOptions)
  }

  checkSponsoredCookie = (key) => {
    const { cookies } = this.props
    const cookieName = `hf_ss_${key}`
    const cookieVal = cookies.get(cookieName)
    return cookieVal
  }

  setSponsoredCookie = (value, key) => {
    const { cookies } = this.props
    const cookieName = `hf_ss_${key}`
    // remember preference with cookie
    const expiresDate = new Date()
    // cookie lasts 2 weeks
    expiresDate.setDate(expiresDate.getDate() + 14)
    const cookieOptions = {
      path: '/',
      expires: expiresDate,
      sameSite: 'strict'
    }
    cookies.set(cookieName, value, cookieOptions)
  }

  render() {
    const { recsOnly, guidebook } = this.props
    const user = currentUser()

    const ShortLocaleRedirect = ({ match }) => {
      // get short locale string
      // path parts will be like ["", "de", ":guidebookKey"]
      const short_locale = match.path.split('/')[1]
      // now go back through the nativeLanguageNames and find a match
      const langPrefixes = Object.keys(nativeLanguageNames)
      for (var i = 0; i < langPrefixes.length; i++) {
        if (langPrefixes[i].split('-')[0] === short_locale) {
          const url_locale = langPrefixes[i]
          // see if this guidebook even supports this language
          if (guidebook.locales.indexOf(url_locale) !== -1) {
            this.setLanguage(url_locale)
          } else if (url_locale === 'en-US') {
            this.setLanguage(url_locale)
          }
        }
      }

      if (match && match.params && match.params.guidebookKey) {
        return <Redirect to={'/' + match.params.guidebookKey} />
      }
      return <Redirect to="/" />
    }

    const splashLocaleRoutes = () => {
      // return nativeLanguageNames.map()
      return Object.keys(nativeLanguageNames).map((item) => {
        const short_locale = item.substring(0, 2)
        return (
          <Route
            path={`/${short_locale}/:guidebookKey`}
            component={ShortLocaleRedirect}
            key={`${short_locale}-route`}
          />
        )
      })
    }

    if (guidebook && guidebook.id) {
      const title = recsOnly
        ? guidebook.recs_only_name
          ? guidebook.recs_only_name
          : 'Recommendations'
        : guidebook.name
      const description = `A guidebook for your stay in ${guidebook.address.locality}`
      const twitter_site = '@hostfully_'
      const fb_app_id = process.env.REACT_APP_FB_APP_ID
      // do we want to use custom domain as canonical if they're using one?
      const domain =
        guidebook.domain && guidebook.domain.status === 'approved'
          ? guidebook.domain.domain
          : null
      const gb_url = buildGuidebookUrl(
        guidebook.key,
        null,
        recsOnly,
        true,
        domain,
        guidebook.is_published
      )
      const image =
        recsOnly && guidebook.recs_only_image
          ? guidebook.recs_only_image
          : guidebook.image
      const meta = {
        title: title,
        description: description,
        canonical: gb_url,
        meta: {
          charset: 'utf-8',
          name: {
            robots: 'noindex',
            keywords: 'guidebook,hostfully,vacation,rental,airbnb',
            'twitter:card': 'summary_large_image',
            'twitter:site': twitter_site,
            'twitter:image:alt': `${title} image`,
            'dc.title': title,
            'dc.description': description
          },
          property: {
            'fb:app_id': fb_app_id,
            'og:type': 'Website',
            'og:title': title,
            'og:description': description,
            'og:image': image,
            'og:image:alt': `${title} image`,
            'og:url': gb_url,
            'og:site_name': 'Hostfully'
          }
        }
      }
      if (!recsOnly && this.detectSecureOnly()) {
        return <Redirect to="/428" />
      } else if (!recsOnly && this.detectShowSafelySplash()) {
        return (
          <DocumentMeta {...meta}>
            <DocumentTitle title={title}>
              <Switch>
                {splashLocaleRoutes()}
                <Route
                  path="/*"
                  render={() => <SafelySplash guidebook={guidebook} />}
                />
              </Switch>
            </DocumentTitle>
          </DocumentMeta>
        )
      } else if (!recsOnly && this.detectShowLandingPage()) {
        return (
          <DocumentMeta {...meta}>
            <DocumentTitle title={title}>
              <Switch>
                {splashLocaleRoutes()}
                <Route
                  path="/*"
                  render={() => <LandingPage guidebook={guidebook} />}
                />
              </Switch>
            </DocumentTitle>
          </DocumentMeta>
        )
      } else if (!recsOnly && this.detectShowSplashScreen()) {
        return (
          <DocumentMeta {...meta}>
            <DocumentTitle title={title}>
              <Switch>
                {splashLocaleRoutes()}
                <Route
                  path="/*"
                  render={() => <SplashScreen guidebook={guidebook} />}
                />
              </Switch>
            </DocumentTitle>
          </DocumentMeta>
        )
      } else {
        return (
          <NavDrawer
            showNavDrawer={this.state.showNavDrawer}
            onClose={this.onNavDrawerClose}
            recsOnly={recsOnly}
          >
            <DocumentMeta {...meta}>
              <DocumentTitle title={title}>
                <Guidebook {...this.props}>{this.props.children}</Guidebook>
              </DocumentTitle>
            </DocumentMeta>
            <OnAtMostTablet>
              <BottomNav
                guidebook={this.props.guidebook}
                showSettings={this.showNavDrawer}
                hideSettings={!user.id}
                recsOnly={recsOnly}
              />
            </OnAtMostTablet>
          </NavDrawer>
        )
      }
    } else {
      return (
        <div className="hf-center-loading">
          <CircularProgress id="loader" scale={3} centered />
        </div>
      )
    }
  }
}

GuidebookContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  recsOnly: PropTypes.bool,
  recsOnlyKey: PropTypes.string
}

GuidebookContainer.defaultProps = {
  recsOnly: false,
  recsOnlyKey: null
}

function mapStateToProps(state, props) {
  return {
    guidebook: state.guidebook.data
  }
}

export default withCookies(connect(mapStateToProps)(GuidebookContainer))
