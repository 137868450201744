import React from 'react'

import './ErrorScreen.scss'

import BlueBubble from './background/bubble_blue.svg'
import LightBlueBubble from './background/bubble_light_blue.svg'
import PurpleBubble from './background/bubble_purple.svg'
import RedBubble from './background/bubble_red.svg'
import YellowBubble from './background/bubble_yellow.svg'

export default function ErrorScreen({
  image,
  title,
  description,
  actions,
  footer,
  nested
}) {
  return (
    <div className={`error-page ${nested ? 'nested' : ''}`}>
      <div className="error-page__left">
        <div className="error-page__background">
          <img id="yellow-bubble" src={YellowBubble} alt="yellow bubble" />
          <img
            id="light-blue-bubble"
            src={LightBlueBubble}
            alt="light blue bubble"
          />
          <img id="purple-bubble" src={PurpleBubble} alt="purple bubble" />
          {image}
        </div>
      </div>
      <div className={`error-page__container ${nested ? 'nested' : ''}`}>
        <div>
          <h4 className="hf-title">{title}</h4>
          <p className="hf-subtitle">{description}</p>
          {actions}
        </div>
      </div>
      <div className="error-page__right">
        <div className="error-page__background">
          <img id="blue-bubble" src={BlueBubble} alt="blue bubble" />
          <img id="red-bubble" src={RedBubble} alt="red bubble" />
        </div>
      </div>
      {footer}
    </div>
  )
}
