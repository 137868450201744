import React, { Component } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { Button, FontIcon, ListItem } from 'react-md'
import { connect } from 'react-redux'
import { Errors, LocalForm } from 'react-redux-form'
import { withRouter } from 'react-router-dom'
import { push } from 'react-router-redux'

import InputTextField from 'components/InputTextField'
import TooltipFontIcon from 'components/TooltipFontIcon'
import {
  adminNavIcons,
  adminNavIndexes,
  adminNavMessages
} from 'constants/UIConstants'
import { switchBack, switchTo } from 'redux/modules/auth'
import { randomString } from 'utils/Strings'

class AdminNavItem extends Component {
  constructor(props) {
    super(props)
    this.activeRoute = this.props.location.pathname.split('/')[2]
  }

  showAdminItem = (tab) => {
    this.props.dispatch(push('/admin/' + tab))
  }

  switchUser = (item) => {
    this.props.dispatch(switchTo(item.id))
  }

  switchBack = (switchedFromEmail) => {
    this.props.dispatch(switchBack(switchedFromEmail))
  }

  componentDidMount() {
    const handler = this.handleRouteChange.bind(this)

    this.props.history.listen(handler)
  }

  handleRouteChange(location) {
    this.activeRoute = location.pathname.split('/')[2]
    this.forceUpdate()
  }

  buildItems = () => {
    const self = this
    const { formatMessage } = this.props.intl
    const icon = 'settings_applications'
    const adminIcon = this.props.showTooltips ? (
      <TooltipFontIcon tooltipLabel="Admin" tooltipPosition="right">
        {icon}
      </TooltipFontIcon>
    ) : (
      <FontIcon className="hf-icon-color">{icon}</FontIcon>
    )
    const nestedItems = adminNavIndexes.map((tab) => {
      const msg = formatMessage(adminNavMessages[tab])
      const icon = adminNavIcons[tab]

      return (
        <ListItem
          key={tab}
          onClick={() => self.showAdminItem(tab)}
          leftIcon={icon}
          active={this.activeRoute === tab}
          primaryText={msg}
        />
      )
    })

    return (
      <ListItem
        key="Admin"
        tileClassName="main-navigation-list"
        leftIcon={adminIcon}
        primaryText="Admin"
        onClick={this.props.openNavDrawer}
        active={['stats', 'exports'].includes(this.activeRoute)}
        nestedItems={this.props.showTooltips ? null : nestedItems}
        defaultVisible={!this.props.showTooltips}
      />
    )
  }

  buildSwitchTo = () => {
    const validators = {
      id: {
        required: (val) => val && val.length
      }
    }
    const messages = {
      id: {
        required: 'Please provide an id or email address'
      }
    }

    return (
      <LocalForm
        onSubmit={(v) => this.switchUser(v)}
        validators={validators}
        style={{
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '16px'
        }}
      >
        <div>
          <InputTextField
            model=".id"
            id={`switch_${randomString(4)}`}
            label="ID/Email to switch to"
            required
          />
          <Errors
            model=".id"
            messages={messages.id}
            className="md-text-field-message-container md-text--error"
            show={(field) => field.touched && !field.focus}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button type="submit" primary raised>
            Switch
          </Button>
        </div>
      </LocalForm>
    )
  }

  render() {
    return (
      <div>
        {this.buildItems()}
        {!this.props.showTooltips && this.buildSwitchTo()}
      </div>
    )
  }
}

AdminNavItem.propTypes = {
  intl: intlShape.isRequired
}

export default connect(null)(injectIntl(withRouter(AdminNavItem)))
