import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'react-md'

const TooltipButton = ({ children, className, ...props }) => (
  <Button {...props} className={classnames(className, 'hf-tooltip-button')}>
    {children}
  </Button>
)

TooltipButton.propTypes = {
  tooltip: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node
}

export default TooltipButton
