import React from 'react'

const Kayak = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`kayak`}</title>
    <path d="M7.9 19.4l2 1.2-1.1-2.1-2-1.1 1.1 2z" />
    <path d="M13.1 9.5L7 15.3c-1.5 1.5-3.5 5.5-2.3 6.8 1.5 1.5 5.8 0 7.6-1.8l5.8-5.5c1.5-1.5 5.9-7.8 4.7-9.1-1.2-1.4-8.1 2.1-9.7 3.8zm-1.7 10.2l-2.3-1.2 1.1 2.3-2.3-1.2.9 2-2.1-.9.6 1.3-.6-1.5-1.5-.8 1.4.6-.9-2.1 1.8 1.1L6.3 17l2.3 1.2-1.1-2.3h.2l1.2 2.6 2.5 1.2zm.7-2.2c.1 0 .2.1.2.2s-.1.2-.2.2l-.6-.3-1.7-1.8c0-.2-.3-.6-.2-.6h.4l2.1 2.3zm2.3-3.6c.8.8.2 2.4.2 2.4s-2.9-.2-3.3-.5c-.5-.3 0-3 0-3 .6-.6 1.7-.5 2.4.3l.7.8zm5-3.7c-.2.2-.6.4-.9.4-.8 0-1.4-.6-1.4-1.4s.6-1.4 1.4-1.4c.4 0 .7.1.9.4.3.3.5.6.5 1.1 0 .4-.1.7-.4.9h-.1z" />
    <path d="M6.8 20.4l1.8.9-.9-1.8-1.7-.9.8 1.8zM17.3 1.3l-2.6 2.4c-.2.3-.3.6-.2.9l-10 9.6c-.3-.2-.6 0-.9 0L1 16.6v.4l.8.8h.3l2.6-2.4c.1-.4.3-.9.1-1l10-9.6c.3.2.6 0 .9 0l2.6-2.4c.1 0 .2-.1.2-.2s-.1-.2-.2-.2l-.8-.8-.2.1z" />
  </svg>
)

export default Kayak
