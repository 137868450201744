import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedNumber, injectIntl, intlShape } from 'react-intl'
import {
  Button,
  Card,
  CardText,
  CardTitle,
  DialogContainer,
  Media,
  MediaOverlay,
  TextField
} from 'react-md'
import { connect } from 'react-redux'

import ImageFallback from 'components/ImageFallback'
import {
  atLeastDesktop,
  atLeastTablet,
  atMostPhablet
} from 'components/ScreenSize'
import AsHtml from 'components/Translator/AsHtml'
import { marketplaceItemShape } from 'constants/SharedPropTypes'
import { trackCardOpened, trackMarketplaceePurchaseClick } from 'utils/GTM';
import { resizeImage } from 'utils/Image'
// import { trackEvent } from 'utils/Segment';
import { stringToId } from 'utils/Strings'
import { fieldHasContent } from 'utils/ValidationHelpers'

const propTypes = {
  marketplace_item: marketplaceItemShape,
  guidebookKey: PropTypes.string,
  initialVisible: PropTypes.bool,
  // helper for internationalization
  intl: intlShape.isRequired
}

class MarketplaceCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: props.initialVisible,
      quantity: 1,
      quantityError: null,
      pageX: null,
      pageY: null
    }
    this.openDialog = this.openDialog.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
    this.purchaseClick = this.purchaseClick.bind(this)
  }

  openDialog(e) {
    let { pageX, pageY } = e
    if (e.changedTouches) {
      const [touch] = e.changedTouches
      pageX = touch.pageX
      pageY = touch.pageY
    }
    const cardIdString = stringToId(
      `mktpl-${this.props.marketplace_item.title}`,
      '-'
    )
    const guidebook_id = this.props.guidebook.data.id || 0
    const host_user_id = this.props.guidebook.data.host_user_id || 0
    const tab_name = "marketplace"
    const card_name = this.props.marketplace_item.title || null
    const card_key = cardIdString
    // send card_opened event to GTM/GA4
    trackCardOpened(guidebook_id, host_user_id, tab_name, card_name, card_key);
    this.setState({ visible: true, pageX, pageY })
    // push route to history
    const cardUrl = `/${this.props.guidebookKey}/marketplace/${cardIdString}`
    this.context.router.history.push(cardUrl)
  }

  closeDialog() {
    this.setState({ visible: false })
    const removeCardUrl = `/${this.props.guidebookKey}/marketplace/`
    this.context.router.history.push(removeCardUrl)
  }

  updateQuantity = (value) => {
    const useValue = parseInt(value, 10)
    let quantityError = null
    if (!useValue || useValue <= 0) {
      quantityError = 'Please enter a positive quantity'
    }
    this.setState({ quantity: value, quantityError: quantityError })
  }

  purchaseClick = (item_uid) => {
    if (this.state.quantityError) return false
    const pmpBaseUrl = process.env.REACT_APP_PMP_URL
    let purchaseUrl = `${pmpBaseUrl}/book.jsp?securekey=RKWhnTySPWpzkxGt8WZyJE&type=service_gb&sUID=${item_uid}`
    if (this.state.quantity) {
      purchaseUrl += `&quantity=${this.state.quantity}`
    }
    purchaseUrl += `&gb_key=${this.props.guidebookKey}`
    if (
      this.props.guidebook.data &&
      this.props.guidebook.data.token_data &&
      this.props.guidebook.data.token_data.lead_uid
    ) {
      purchaseUrl += `&lUID=${this.props.guidebook.data.token_data.lead_uid}`
    }
    if (this.props.guidebook.data && this.props.guidebook.data.property_uid) {
      purchaseUrl += `&pUID=${this.props.guidebook.data.property_uid}`
    }
    const guidebook_id = this.props.guidebook.data.id || 0
    const host_user_id = this.props.guidebook.data.host_user_id || 0
    const tab_name = "marketplace"
    const card_name = this.props.marketplace_item.title || null
    const card_key = stringToId(
      `mktpl-${this.props.marketplace_item.title}`,
      '-'
    )
    // send card_opened event to GTM/GA4
    trackMarketplaceePurchaseClick(guidebook_id, host_user_id, tab_name, card_name, card_key);
    var windowReference = window.open()
    windowReference.location = purchaseUrl
  }

  resizeMarketplaceItemImage = (image) => {
    const fallbackImage = image || '/add-place-img.svg'
    let srcImage = image || '/add-place-img.svg'

    if (image) {
      // for desktop and up, grab images 350 wide
      if (atLeastDesktop) {
        srcImage = resizeImage(image, 348, 261, true)
        // for tablet and up, grab images 480 wide
      } else if (atLeastTablet) {
        srcImage = resizeImage(image, 480, 360, true)
      } else if (atMostPhablet) {
        srcImage = resizeImage(image, 748, 561, true)
      }
    }
    // otherwise
    return { srcImage: srcImage, fallbackImage: fallbackImage }
  }

  render() {
    const { marketplace_item } = this.props
    const { srcImage, fallbackImage } = this.resizeMarketplaceItemImage(
      marketplace_item.defaultPicture
    )
    const aspectRatio = '4-3'

    const description = fieldHasContent(marketplace_item.description)
      ? marketplace_item.description
      : marketplace_item.type.toLowerCase()

    const longDescription_txn = {}
    const longDescription = fieldHasContent(
      marketplace_item.longDescription
    ) ? (
      <div className="md-cell md-cell--12">
        <h3>Description</h3>
        <AsHtml
          text={marketplace_item.longDescription}
          translations={longDescription_txn}
        />
      </div>
    ) : null
    // if no image, just use a title
    let mediaOrTitle = null
    if (marketplace_item.defaultPicture) {
      mediaOrTitle = (
        <Media aspectRatio={aspectRatio}>
          <ImageFallback
            src={srcImage}
            fallback={fallbackImage}
            role="presentation"
          />
          <MediaOverlay>
            <CardTitle
              title={marketplace_item.title}
              subtitle={''}
              className="hf-rec-overlay-title"
            ></CardTitle>
          </MediaOverlay>
        </Media>
      )
    } else {
      mediaOrTitle = (
        <CardTitle
          title={marketplace_item.title}
          subtitle={''}
          className="hf-rec-overlay-title"
        ></CardTitle>
      )
    }

    const dialogId = stringToId(`dailog-${marketplace_item.title}`)
    const titleString = marketplace_item.title

    const dialogMedia = marketplace_item.defaultPicture ? (
      <Media aspectRatio="4-3">
        <ImageFallback
          src={srcImage}
          fallback={fallbackImage}
          role="presentation"
        />
      </Media>
    ) : null

    const cancellationPolicy = marketplace_item.cancellationPolicy ? (
      <div>
        <br />
        <h3>Cancellation Policy</h3>
        <p>{marketplace_item.cancellationPolicy}</p>
      </div>
    ) : null
    const buttonStyle = { marginTop: '20px' }
    const quantityDescription = marketplace_item.quantityDescription ? (
      <p>(Sold by number of {marketplace_item.quantityDescription})</p>
    ) : null
    const quantityLabel = marketplace_item.quantityDescription ? (
      <p>Number of {marketplace_item.quantityDescription}</p>
    ) : (
      'Quantity'
    )
    const purchaseCTA = (
      <div className="md-grid md-grid--no-spacing">
        <div className="md-cell md-cell--6">
          <TextField
            id={'text-quantity'}
            value={this.state.quantity}
            label={quantityLabel}
            onChange={this.updateQuantity}
            error={this.state.quantityError ? true : false}
            errorText={this.state.quantityError}
          />
        </div>
        <div className="md-cell md-cell--6">
          <Button
            className="hf-orange-button"
            raised
            secondary
            style={buttonStyle}
            onClick={() => {
              this.purchaseClick(marketplace_item.uid)
            }}
          >
            Buy Now
          </Button>
        </div>
      </div>
    )
    const price = parseFloat(
      Math.round(marketplace_item.price * 100) / 100
    ).toFixed(2)
    const currency = marketplace_item.serviceProvider.currency

    const fullMarketplaceItem = (
      <div className="md-grid">
        <div className="md-cell md-cell--6 md-cell--8-tablet">
          <h3>Summary</h3>
          <p>{marketplace_item.description || marketplace_item.type}</p>
          <br />
          <h3>Price</h3>
          <p>
            <FormattedNumber
              value={price}
              style={`currency`}
              currency={currency || 'USD'}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          </p>
          {quantityDescription}
          {cancellationPolicy}
        </div>
        <div className="md-cell md-cell--5 md-cell--1-desktop-offset md-cell--tablet-hidden md-cell--phone-hidden md-text-center">
          {dialogMedia}
          {purchaseCTA}
        </div>
        {longDescription}
        <div className="md-cell md-cell--desktop-hidden md-cell--8-tablet md-cell--4-phone md-text-center">
          {purchaseCTA}
        </div>
      </div>
    )

    return (
      <div>
        <Card
          key={marketplace_item.uid}
          raise={true}
          className="hf-clickable"
          onClick={(e) => this.openDialog(e)}
        >
          {mediaOrTitle}
          <CardText>
            <p className="host-quote">{description}</p>
          </CardText>
        </Card>
        <DialogContainer
          id={dialogId}
          visible={this.state.visible}
          pageX={this.state.pageX}
          pageY={this.state.pageY}
          onHide={this.closeDialog}
          fullPage
          portal={true}
          lastChild={true}
          disableScrollLocking={true}
          renderNode={document.body}
          aria-label={'Detailed information about ' + marketplace_item.title}
          className="hf-guest-modal"
        >
          <div className="list-item-modal-content">
            <Card className="list-item-modal-card" tabIndex="0">
              <Button
                icon
                className="md-cell--right list-item-modal-close"
                onClick={this.closeDialog}
              >
                keyboard_backspace
              </Button>
              <CardTitle title={titleString}></CardTitle>
              <CardText>{fullMarketplaceItem}</CardText>
            </Card>
          </div>
        </DialogContainer>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    guidebook: state.guidebook
  }
}

MarketplaceCard.propTypes = propTypes
MarketplaceCard.contextTypes = {
  router: PropTypes.object
}

export default connect(mapStateToProps)(injectIntl(MarketplaceCard))
