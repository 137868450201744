import React from 'react'

const TouchApp = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`touch_app`}</title>
    <path d="M18.844 15.891a1.5 1.5 0 01.891 1.359v.188l-.75 5.297c-.094.75-.703 1.266-1.453 1.266h-6.797c-.422 0-.75-.141-1.031-.422L4.735 18.61l.797-.797a1.13 1.13 0 01.797-.328c.094 0 .141.047.234.047l3.422.703V7.501c0-.844.656-1.5 1.5-1.5s1.5.656 1.5 1.5v6h.797c.188 0 .328.047.516.094zM9 11.25A4.496 4.496 0 016.984 7.5C6.984 5.016 9 3 11.484 3s4.5 2.016 4.5 4.5c0 1.547-.75 2.953-1.969 3.75V7.5c0-1.359-1.172-2.484-2.531-2.484S9 6.141 9 7.5v3.75z" />
  </svg>
)

export default TouchApp
