import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router'
import { push, replace } from 'react-router-redux'
import { withCookies } from 'react-cookie'
import { injectIntl } from 'react-intl'
import GuidebookMap from 'components/GuidebookMap'
import {
  OnAtMostPhablet,
  OnAtLeastTablet,
  atMostTablet
} from 'components/ScreenSize'
import MainView from './pages/MainView'
import PrintContainer from './pages/Print/index.js'
import MapView from './pages/MapView'
import Recommendation from './pages/Recommendation'
import { setLocale } from 'redux/modules/auth'
import { nativeLanguageNames } from 'constants/UIConstants'
import currentUser from 'utils/CurrentUser'
// import { trackEvent, getHostIdAndKey } from 'utils/Segment';
import { checkDomain } from 'utils/Urls'

class Guidebook extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      showNavDrawer: false
    }
  }

  componentDidMount() {
    // if this guidebook has a "default path" and we're at the base url
    // redirect to the appropriate tab
    let { hash, search, pathname } = this.context.router.route.location
    let path = pathname
    if (pathname[pathname.length - 1] === '/') {
      path = pathname.substring(0, pathname.length - 1)
    }
    const key = this.props.guidebook.key
    const default_path = this.props.guidebook.default_path
    if (path === `/${key}` && default_path) {
      const { dispatch } = this.props
      const newpath = `${path}${default_path}${search}${hash}`
      dispatch(push(newpath))
    }
  }

  setLanguage(locale) {
    const self = this
    const { guidebook, cookies } = this.props
    // OLD SEGMENT CODE
    // // track this event in segment
    // let eventData = getHostIdAndKey(this.props.guidebook);
    // eventData.label = locale;
    // eventData.category = 'Guidebook';
    // trackEvent('languageChanged', eventData, {}, function(){
    // cookie lasts 6 months
    const expiresDate = new Date()
    expiresDate.setMonth(expiresDate.getMonth() + 6)
    const cookieOptions = {
      path: '/',
      expires: expiresDate,
      sameSite: 'strict'
    }
    cookies.set(`hf_lang_${guidebook.key}`, locale, cookieOptions)
    self.props.dispatch(setLocale(locale))
    // });
  }

  render() {
    const { dispatch, guidebook } = this.props

    const user = currentUser().user
    // if this is either a draft or a template and the user is not the host
    // throw a 404
    if (guidebook.id && (guidebook.is_template || !guidebook.is_published)) {
      if (user.id !== guidebook.host_user_id) {
        dispatch(replace('/403'))
      }
    }
    // if this is being accessed by a custom domain check the guidebook for a connection
    checkDomain(guidebook)
    const bottomOffset = atMostTablet ? 'md-bottom-navigation-offset' : ''
    const GbMapView = ({ match, recsOnly }) => {
      return (
        <MapView
          mapCategory={match.params.mapCategory}
          recsOnly={typeof recsOnly !== 'undefined' && recsOnly}
        />
      )
    }

    const SingleRecView = ({ match, recsOnly }) => {
      return (
        <div key={'house'} className="main-desktop hf-relatively">
          <GuidebookMap
            className="map-container-full recommendation-map hf-hideOnLessThanPhablet"
            fitType={this.props.fitType}
            onBoundsChanged={this.props.onBoundsChanged}
            guidebook={guidebook}
          />
          <div className="md-grid">
            <div className="md-cell md-cell--4 md-cell--4-tablet md-cell--4-phone hf-absolutely">
              <Recommendation
                recsOnly={typeof recsOnly !== 'undefined' && recsOnly}
              />
            </div>
          </div>
        </div>
      )
    }

    const ShortLocaleRedirect = ({ match }) => {
      // get short locale string
      // path parts will be like ["", "de", ":guidebookKey"]
      const short_locale = match.path.split('/')[1]
      // now go back through the nativeLanguageNames and find a match
      const langPrefixes = Object.keys(nativeLanguageNames)
      for (var i = 0; i < langPrefixes.length; i++) {
        if (langPrefixes[i].split('-')[0] === short_locale) {
          const url_locale = langPrefixes[i]
          // see if this guidebook even supports this language
          if (guidebook.locales.indexOf(url_locale) !== -1) {
            this.setLanguage(url_locale)
          }
        }
      }

      if (match && match.params && match.params.guidebookKey) {
        return <Redirect to={'/' + match.params.guidebookKey} />
      }
      return <Redirect to="/" />
    }

    const localeRoutes = () => {
      // return nativeLanguageNames.map()
      return Object.keys(nativeLanguageNames).map((item) => {
        const short_locale = item.substring(0, 2)
        return (
          <Route
            path={`/${short_locale}/:guidebookKey`}
            component={ShortLocaleRedirect}
            key={`${short_locale}-route`}
          />
        )
      })
    }

    return (
      <div className={bottomOffset}>
        <OnAtLeastTablet>
          <Switch>
            <Route
              exact
              path="/(r|recs)/:recsOnlyKey/map/:mapCategory/recommendation/:recKey"
              render={({ match }) => {
                return <SingleRecView match={match} recsOnly />
              }}
            />
            <Route
              exact
              path="/(r|recs)/:recsOnlyKey/recommendations/:category/recommendation/:recKey"
              render={({ match }) => {
                return <SingleRecView match={match} recsOnly />
              }}
            />
            <Route
              exact
              path="/(r|recs)/:recsOnlyKey/:essentialsTab/recommendation/:recKey"
              render={({ match }) => {
                return <SingleRecView match={match} recsOnly />
              }}
            />
            <Route
              exact
              path="/(r|recs)/:recsOnlyKey/recommendation/:recKey"
              render={({ match }) => {
                return <SingleRecView match={match} recsOnly />
              }}
            />
            <Route exact path="/(r|recs)/:recsOnlyKey/recommendations" />
            <Route
              exact
              path="/(r|recs)/:recsOnlyKey/recommendations/:category"
            />
            <Route exact path="/(r|recs)/:recsOnlyKey" />
            <Route exact path="/(r|recs)/:recsOnlyKey/:activeTab" />
            <Route
              exact
              path="/:guidebookKey/map/:mapCategory/recommendation/:recKey"
              component={SingleRecView}
            />
            <Route
              exact
              path="/:guidebookKey/recommendations/:category/recommendation/:recKey"
              component={SingleRecView}
            />
            <Route
              exact
              path="/:guidebookKey/:essentialsTab/recommendation/:recKey"
              component={SingleRecView}
            />
            <Route
              exact
              path="/:guidebookKey/recommendation/:recKey"
              component={SingleRecView}
            />
            <Route exact path="/:guidebookKey/recommendations" />
            <Route exact path="/:guidebookKey/recommendations/:category" />
            <Route exact path="/:guidebookKey" />
            <Route exact path="/:guidebookKey/:activeTab" />
          </Switch>
        </OnAtLeastTablet>
        <Switch>
          <Route
            exact
            path="/(r|recs)/:recsOnlyKey/map/:mapCategory/recommendation/:recKey"
          >
            <OnAtMostPhablet>
              <Recommendation recsOnly />
            </OnAtMostPhablet>
          </Route>
          <Route
            exact
            path="/(r|recs)/:recsOnlyKey/recommendations/:category/recommendation/:recKey"
          >
            <OnAtMostPhablet>
              <Recommendation recsOnly />
            </OnAtMostPhablet>
          </Route>
          <Route
            exact
            path="/(r|recs)/:recsOnlyKey/:essentialsTab/recommendation/:recKey"
          >
            <OnAtMostPhablet>
              <Recommendation recsOnly />
            </OnAtMostPhablet>
          </Route>
          <Route exact path="/(r|recs)/:recsOnlyKey/recommendation/:recKey">
            <OnAtMostPhablet>
              <Recommendation recsOnly />
            </OnAtMostPhablet>
          </Route>
          <Route
            exact
            path="/(r|recs)/:recsOnlyKey/map"
            render={({ match }) => {
              return <GbMapView match={match} recsOnly />
            }}
          />
          <Route
            exact
            path="/(r|recs)/:recsOnlyKey/map/:mapCategory"
            render={({ match }) => {
              return <GbMapView match={match} recsOnly />
            }}
          />
          <Route
            exact
            path="/(r|recs)/:recsOnlyKey/recommendations"
            render={({ match }) => {
              return <MainView match={match} recsOnly />
            }}
          />
          <Route
            exact
            path="/(r|recs)/:recsOnlyKey/recommendations/:category"
            render={({ match }) => {
              return <MainView match={match} recsOnly />
            }}
          />
          <Route
            path="/(r|recs)/:recsOnlyKey/:activeTab/:card_title"
            render={({ match }) => {
              return <MainView match={match} recsOnly />
            }}
          />
          <Route
            path="/(r|recs)/:recsOnlyKey/:activeTab"
            render={({ match }) => {
              return <MainView match={match} recsOnly />
            }}
          />
          <Route
            exact
            path="/(r|recs)/:recsOnlyKey"
            render={({ match }) => {
              return <MainView match={match} recsOnly />
            }}
          />

          {localeRoutes()}
          <Route
            exact
            path="/:guidebookKey/recommendations/:category/recommendation/:recKey"
          >
            <OnAtMostPhablet>
              <Recommendation />
            </OnAtMostPhablet>
          </Route>
          <Route
            exact
            path="/:guidebookKey/map/:mapCategory/recommendation/:recKey"
          >
            <OnAtMostPhablet>
              <Recommendation />
            </OnAtMostPhablet>
          </Route>
          <Route
            exact
            path="/:guidebookKey/:essentialsTab/recommendation/:recKey"
          >
            <OnAtMostPhablet>
              <Recommendation />
            </OnAtMostPhablet>
          </Route>
          <Route exact path="/:guidebookKey/recommendation/:recKey">
            <OnAtMostPhablet>
              <Recommendation />
            </OnAtMostPhablet>
          </Route>
          <Route exact path="/:guidebookKey/print" component={PrintContainer} />
          <Route exact path="/:guidebookKey/map" component={GbMapView} />
          <Route
            exact
            path="/:guidebookKey/map/:mapCategory"
            component={GbMapView}
          />
          <Route
            exact
            path="/:guidebookKey/recommendations"
            component={MainView}
          />
          <Route
            exact
            path="/:guidebookKey/recommendations/:category"
            component={MainView}
          />
          <Route
            path="/:guidebookKey/:activeTab/:card_title"
            component={MainView}
          />
          <Route path="/:guidebookKey/:activeTab" component={MainView} />
          <Route exact path="/:guidebookKey" component={MainView} />
        </Switch>
      </div>
    )
  }
}

Guidebook.propTypes = {
  guidebook: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string
  })
}
export default withCookies(injectIntl(Guidebook))
