import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { Button } from 'react-md'
import { connect } from 'react-redux'

import { switchBack } from 'redux/modules/auth'

class SwitchNavItem extends Component {
  switchBack = (switchedFromEmail) => {
    this.props.dispatch(switchBack(switchedFromEmail))
  }

  render() {
    return (
      <div
        style={{
          padding: '24px',
          textAlign: 'center'
        }}
      >
        <p className="md-tile-text--secondary md-text--secondary">
          Logged as: {this.props.userEmail}
        </p>
        <Button
          onClick={() => this.switchBack(this.props.switchedFrom)}
          secondary
          raised
        >
          Switch back
        </Button>
      </div>
    )
  }
}

SwitchNavItem.propTypes = {
  intl: intlShape.isRequired,
  userEmail: PropTypes.string,
  switchedFrom: PropTypes.string
}

export default connect(null)(injectIntl(SwitchNavItem))
