import React from 'react'

const Videocam = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`videocam`}</title>
    <path d="M17.016 10.5L21 6.516v10.969l-3.984-3.984v3.516c0 .563-.469.984-1.031.984h-12a.96.96 0 01-.984-.984V6.986a.96.96 0 01.984-.984h12c.563 0 1.031.422 1.031.984v3.516z" />
  </svg>
)

export default Videocam
