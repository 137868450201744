import React, { Component } from 'react'
import { ChromePicker } from 'react-color'
import DocumentTitle from 'react-document-title'
import { Button, FontIcon, LinearProgress, Paper, Toolbar } from 'react-md'
import { connect } from 'react-redux'
import { actions, Errors, Form } from 'react-redux-form'
import { replace } from 'react-router-redux'

import IconChooser from 'components/IconChooser'
import MultilangWysiwyg from 'components/MultilangWysiwyg'
import SelectForm from 'components/SelectForm'
import TooltipButton from 'components/TooltipButton'
import { localeMessages } from 'constants/UIConstants'
import { saveItem, showItems } from 'redux/modules/crud'
import blankEditStates from 'redux/modules/crud/blankEditStates'
import currentUser from 'utils/CurrentUser'

class CategoryForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true
    }
    this.back = this.back.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
    this.saveAndAddAnother = this.saveAndAddAnother.bind(this)
    this.handlePrimaryColorChange = this.handlePrimaryColorChange.bind(this)
  }

  back = () => {
    if (window.isIframe) {
      window.history.back()
    } else {
      const { dispatch, pluralName } = this.props
      dispatch(showItems(pluralName))
    }
  }

  handleEnter = (e) => {
    const { dispatch } = this.props
    e.preventDefault()
    dispatch(actions.submit('edit_category'))
  }

  componentDidMount() {
    const { dispatch, itemId } = this.props
    if (itemId === 'create') {
      const newItem = blankEditStates.category.data
      dispatch(actions.load('edit_category', newItem))
      this.setState({ isLoading: false })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, edit_category } = this.props
    const nextCategory = nextProps.edit_category
    // make sure this user can edit this item
    if (edit_category.id !== nextCategory.id && nextCategory.id) {
      const user = currentUser()
      // admins can edit categories that are not theirs if they are default
      const canEdit =
        user.id === nextCategory.user_id ||
        (user.isAdmin && nextCategory.is_default)
      if (!canEdit) {
        dispatch(replace('/403'))
      } else {
        this.setState({ isLoading: false })
      }
    } else if (this.props.itemId === 'create') {
      this.setState({ isLoading: false })
    }
  }

  handleSubmit(item) {
    const { itemId, dispatch } = this.props
    const addAnother = this.addAnother
    this.addAnother = false
    dispatch(saveItem('categories', 'category', itemId, item, addAnother))
  }

  handlePrimaryColorChange = (color) => {
    const { dispatch } = this.props
    dispatch(actions.change('edit_category.color', color.hex))
  }

  saveAndAddAnother = (e) => {
    const { dispatch } = this.props
    this.addAnother = true
    dispatch(actions.submit('edit_category'))
  }

  getLocalesNoEn = () => {
    const localeKeys = Object.keys(localeMessages)
    let enIdx = localeKeys.indexOf('en-US')
    if (enIdx !== -1) {
      localeKeys.splice(enIdx, 1)
    }
    return localeKeys
  }

  categoryIsValid = (str) => {
    var code, i, len
    if (str) {
      for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i)
        if (
          !(code > 47 && code < 58) && // numeric (0-9)
          !(code > 64 && code < 91) && // upper alpha (A-Z)
          !(code > 96 && code < 123) && // lower alpha (a-z)
          !(code === 142) && // Ž
          !(code === 158) && // ž
          !(code > 191 && code < 256) && // À-ÿ
          !(code === 45) && //hyphen and underscore
          !(code === 38 || code === 32)
        ) {
          // ampersand and space
          return false
        }
      }
    }
    return true
  }

  renderLoading = (message) => {
    return (
      <div className="md-cell md-cell--12 md-text-center">
        <LinearProgress id="status_check" />
        <div>{message}</div>
      </div>
    )
  }

  renderMain = () => {
    if (this.state.isLoading) {
      return this.renderLoading('Fetching category data...')
    } else {
      return this.renderEditForm()
    }
  }

  renderEditForm = () => {
    const { edit_category } = this.props
    const locales = this.getLocalesNoEn()
    const validators = {
      name: {
        required: (val) => val && val.length,
        length: (val) => val && val.length < 25,
        invalid: (val) => this.categoryIsValid(val)
      },
      icon: {
        required: (val) => val && val.length
      }
    }

    const formErrors = []
    const errorMessages = {
      name: {
        required: 'Please provide a name',
        length: 'Please use a maximum of 25 characters in your category name',
        invalid:
          'Invalid characters - please only use letters, numbers or hyphens (-)'
      },
      icon: {
        required: 'Please choose an icon'
      }
    }
    formErrors['name'] = (
      <Errors
        model=".name"
        messages={errorMessages.name}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['icon'] = (
      <Errors
        model=".icon"
        messages={errorMessages.icon}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )

    const color = edit_category.color || '#ff5722'

    return (
      <Paper key="category" className="hf-form-wrapper">
        <Form
          model="edit_category"
          onSubmit={(v) => this.handleSubmit(v)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              return this.handleEnter(e)
            }
          }}
          validators={validators}
        >
          <div className="md-grid">
            <div className="md-cell md-cell--12">
              <h1 className="md-headline">Category Name</h1>
              <p>
                This is used in the main navigation tabs. Don't worry about
                filling out translations you're not using.
              </p>
              <MultilangWysiwyg
                model="edit_category"
                field="name"
                txn_field="name_txn"
                locales={locales}
                max_length={25}
                simpleEditor
              />
              {formErrors['name']}
            </div>
            <div className="md-cell md-cell--12">
              <h1 className="md-headline">Category Type</h1>
              <p>
                This will determine what kind of cards can be assigned to this
                category.
              </p>
            </div>
            <SelectForm
              className="md-cell md-cell--12"
              label="Category Type"
              model={'edit_category.type'}
              defaultValue={'recommendation'}
              includeNone={false}
              itemLabel="name"
              itemValue="id"
            >
              {[
                { id: 'recommendation', name: 'Recommendation Cards' },
                { id: 'house manual', name: 'House Manual Cards' }
              ]}
            </SelectForm>
            <div className="md-cell md-cell--12 hf-headline-margin">
              <h1 className="md-headline">Choose an icon for the category</h1>
              <div className="hf-editable-grid-lists hf-wider-selection-control-container">
                <IconChooser
                  editModel="edit_category"
                  editField="icon"
                  color={color}
                />
              </div>
              {formErrors['icon']}
            </div>
            <div className="md-cell md-cell--12 hf-headline-margin">
              <h1 className="md-headline">
                Choose a color for the category icon
              </h1>
              <div className="md-grid md-grid--no-spacing">
                <div className="md-cell md-cell--8">
                  <ChromePicker
                    color={color}
                    onChangeComplete={this.handlePrimaryColorChange}
                    disableAlpha
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="md-grid hf-form-errors">
            <div className="md-cell md-cell--12">
              <div>{formErrors['name']}</div>
              <div>{formErrors['icon']}</div>
            </div>
          </div>
          <p className="md-caption" style={{ textAlign: 'right' }}>
            After you save this card you'll be able to assign it to any
            guidebooks you like
          </p>
          <div className="hf-sticky">
            <Button type="submit" primary raised>
              Save category
            </Button>
          </div>
        </Form>
      </Paper>
    )
  }

  render() {
    const { edit_category, copy } = this.props
    // special case we use all locales for categories
    const self = this
    const title = copy
      ? 'Copy category'
      : edit_category.id
        ? 'Edit category'
        : 'New category'
    const nav = (
      <TooltipButton
        key="nav"
        onClick={self.back}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>keyboard_backspace</FontIcon>
      </TooltipButton>
    )
    const actions = []

    return (
      <DocumentTitle title="Category">
        <div className="hf-categories-paper">
          <Toolbar
            colored
            className="hf-edit-toolbar hf-categories"
            title={title}
            nav={nav}
            actions={actions}
          />
          {this.renderMain()}
        </div>
      </DocumentTitle>
    )
  }
}

CategoryForm.propTypes = {}
function mapStateToProps(state, props) {
  const edit_category = state.edit_category
  return {
    edit_category
  }
}

export default connect(mapStateToProps)(CategoryForm)
