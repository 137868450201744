import React from 'react'

const ShoppingCart = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`shopping_cart`}</title>
    <path d="M17.016 18c1.078 0 1.969.938 1.969 2.016s-.891 1.969-1.969 1.969S15 21.094 15 20.016 15.938 18 17.016 18zM.984 2.016h3.281l.938 1.969h14.813c.563 0 .984.469.984 1.031a.79.79 0 01-.141.469l-3.563 6.469a1.973 1.973 0 01-1.734 1.031H8.109l-.891 1.641-.047.141c0 .141.094.234.234.234h11.578v2.016h-12c-1.078 0-1.969-.938-1.969-2.016 0-.328.094-.656.234-.938l1.359-2.484-3.609-7.594H.982V2.016zm6 15.984C8.062 18 9 18.938 9 20.016s-.938 1.969-2.016 1.969-1.969-.891-1.969-1.969S5.906 18 6.984 18z" />
  </svg>
)

export default ShoppingCart
