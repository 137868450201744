import React from 'react'

const FilterVintage = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`filter_vintage`}</title>
    <path d="M12 15.984c2.203 0 3.984-1.781 3.984-3.984S14.203 8.016 12 8.016 8.016 9.797 8.016 12 9.797 15.984 12 15.984zm-9.703 1.594c0-2.307 1.47-4.787 3.844-5.578-.281-.094-.563-.281-.844-.422-1.922-1.125-3-3.094-3-5.156 1.781-1.031 4.078-1.125 6 0 .281.141.563.328.797.516C9.047 6.61 9 6.329 9 6a6.047 6.047 0 013-5.203c1.781 1.031 3 3 3 5.203 0 .328-.047.609-.094.938.234-.188.516-.375.797-.516 1.922-1.125 4.219-1.031 6 0 0 2.063-1.078 4.031-3 5.156-.281.141-.563.328-.844.422.281.094.563.281.844.422 1.922 1.125 3 3.094 3 5.156-1.781 1.031-4.078 1.125-6 0a4.635 4.635 0 01-.797-.516c.047.328.094.609.094.938a6.047 6.047 0 01-3 5.203c-1.781-1.031-3-3-3-5.203 0-.328.047-.609.094-.938a4.635 4.635 0 01-.797.516c-1.922 1.125-4.219 1.031-6 0z" />
  </svg>
)

export default FilterVintage
