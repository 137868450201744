import { replace } from 'react-router-redux'
import { loadPrintPreferences } from './printPreferences'
import currentUser from 'utils/CurrentUser'
export const GB_IS_LOADING = 'GB_IS_LOADING'
export const GB_FETCH_DATA_SUCCESS = 'GB_FETCH_DATA_SUCCESS'
export const GB_HAS_ERRORED = 'GB_HAS_ERRORED'
export const GB_RESET_STATE = 'GB_RESET_STATE'

const initialState = {
  isLoading: false,
  hasErrored: false,
  data: {
    key: 'loading',
    default_path: '',
    name: 'Hostfully',
    address: {},
    directions: {},
    domain: {},
    checkin: {},
    checkout: {},
    host_intro: {},
    wifi: {
      wifi_provided: false
    },
    informations: [],
    informationsByCategory: {},
    recommendations: [],
    recommendationsByCategory: {},
    userSettings: {}
  }
}

/* Reducers */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GB_HAS_ERRORED:
      return Object.assign({}, state, { hasErrored: action.hasErrored })
    case GB_IS_LOADING:
      return Object.assign({}, state, { isLoading: action.isLoading })
    case GB_FETCH_DATA_SUCCESS:
      return Object.assign({}, state, { data: action.guidebook })
    case GB_RESET_STATE:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

/* Actions */
export function guidebookHasErrored(bool) {
  return {
    type: GB_HAS_ERRORED,
    hasErrored: bool
  }
}
export function notFound() {
  return (dispatch) => {
    dispatch(replace('/404'))
  }
}
export function guidebookIsLoading(bool) {
  return {
    type: GB_IS_LOADING,
    isLoading: bool
  }
}
export function guidebookFetchDataSuccess(guidebook) {
  const sponsored = guidebook.sponsoredRecommendations || []
  const gbRecs = guidebook.recommendations.reduce(function (ids, rec) {
    ids[rec.id] = rec
    return ids
  }, {})
  // just to be safe, remove any sponsored recs that happen to be already included in the guidebook recommendations
  const uniqueSponsored = sponsored.filter((rec) => {
    return !gbRecs[rec.id]
  })
  const recommendations = guidebook.recommendations.concat(uniqueSponsored)
  const informations = guidebook.informations
  // BA for now we need them all under recommendations.
  guidebook.recommendations = recommendations
  // TODO BA - probably a better place to do this but it's needed by multiple components, so doing it here for now.
  const recommendationsByCategory = recommendations.reduce(function (
    map,
    recommendation
  ) {
    const category = recommendation.category
    let cat_key = category.name.toLowerCase().replace(/ /g, '_')
    const existing = map[cat_key]
    if (!existing) {
      const newTab = {
        category,
        recommendations: [recommendation]
      }
      map[cat_key] = newTab
    } else {
      existing.recommendations.push(recommendation)
    }
    return map
  }, {})

  const informationsByCategory = informations.reduce(function (
    map,
    information
  ) {
    let category = information.category
    if (!category) {
      category = {
        id: 0,
        name: 'House Manual',
        icon: 'Book',
        color: '#ff5722',
        type: 'house manual',
        is_default: true,
        user_id: 0
      }
    }
    const cat_key = category.name.toLowerCase().replace(/ /g, '_')
    const existing = map[cat_key]
    if (!existing) {
      const newTab = {
        category,
        informations: [information]
      }
      map[cat_key] = newTab
    } else {
      existing.informations.push(information)
    }
    return map
  }, {})
  recommendationsByCategory['all'] = {
    category: {
      id: 0,
      name: 'all',
      description: 'All',
      icon: 'Apps',
      color: '#e91e63',
      order: 9999
    },
    recommendations
  }
  guidebook.recommendationsByCategory = recommendationsByCategory
  guidebook.informationsByCategory = informationsByCategory
  return {
    type: GB_FETCH_DATA_SUCCESS,
    guidebook: guidebook
  }
}

export function fetchGuidebookData(
  key,
  token,
  show_sponsored,
  insert_short_links
) {
  let url = process.env.REACT_APP_API_URL + '/api/v1/guidebooks/key/' + key
  if (typeof token !== 'undefined' && token) {
    url = url + '/' + token
  } else {
    const user = currentUser()
    if (user.id) {
      url = url + '/' + btoa(`user-${user.id}`)
    }
  }
  if (typeof show_sponsored !== 'undefined' && show_sponsored) {
    url = url + '?show_sponsored=true'
  }
  if (typeof insert_short_links !== 'undefined' && insert_short_links) {
    if (url.indexOf('?') !== -1) {
      url = url + '&insert_short_links=true'
    } else {
      url = url + '?insert_short_links=true'
    }
  }
  return (dispatch) => {
    dispatch(guidebookIsLoading(true))
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            dispatch(notFound())
          }
          throw Error(response.statusText)
        }
        dispatch(guidebookIsLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((guidebookResponse) => {
        const guidebook = guidebookResponse.data
        // load the print preferences from this guidebooks theme if applicable
        const printPreferences =
          guidebook && guidebook.theme
            ? guidebook.theme.print_preferences
            : null
        if (printPreferences) {
          dispatch(loadPrintPreferences(printPreferences))
        }
        dispatch(guidebookFetchDataSuccess(guidebook))
      })
      .catch(() => dispatch(guidebookHasErrored(true)))
  }
}

export function fetchRecsOnlyData(recs_only_key, show_sponsored) {
  let url =
    process.env.REACT_APP_API_URL +
    '/api/v1/guidebooks/recs_only_key/' +
    recs_only_key
  if (typeof show_sponsored !== 'undefined' && show_sponsored) {
    url = url + '?show_sponsored=true'
  }
  return (dispatch) => {
    dispatch(guidebookIsLoading(true))
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            dispatch(notFound())
          }
          throw Error(response.statusText)
        }
        dispatch(guidebookIsLoading(false))
        return response
      })
      .then((response) => response.json())
      .then((guidebookResponse) => {
        const guidebook = guidebookResponse.data
        // load the print preferences from this guidebooks theme if applicable
        const printPreferences =
          guidebook && guidebook.theme
            ? guidebook.theme.print_preferences
            : null
        if (printPreferences) {
          dispatch(loadPrintPreferences(printPreferences))
        }
        dispatch(guidebookFetchDataSuccess(guidebook))
      })
      .catch(() => dispatch(guidebookHasErrored(true)))
  }
}

export function fetchGuidebookDataForProvider(provider, ref) {
  const url =
    process.env.REACT_APP_API_URL +
    '/api/v1/guidebooks/provider/' +
    provider +
    '/ref/' +
    ref
  return fetch(url, {})
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
}

export function guidebookReset() {
  return {
    type: GB_RESET_STATE
  }
}
