import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withCookies } from 'react-cookie'
import DocumentTitle from 'react-document-title'
import { injectIntl, intlShape } from 'react-intl'
import { Avatar, Tab, Tabs, TabsContainer } from 'react-md'
import { push } from 'react-router-redux'
import UAParser from 'ua-parser-js'

import categoryNames from 'components/Categories/CategoryNames'
import GuidebookMarker from 'components/MarkerHelpers/GuidebookMarker'
import RecommendationsMarkers from 'components/MarkerHelpers/RecommendationsMarkers'
import {
  getAvailableTabs,
  isEssentialsTab,
  updatePotentialTabs
} from 'components/NavDrawer/PotentialTabs'
import { labelMessages } from 'constants/UIConstants'
import { allIcons } from 'constants/UIIcons'
import { updateMapMarkers } from 'redux/modules/map'
import { customColor } from 'utils/Color'
import { findKeyByValue, matchData } from 'utils/Data'
import { debounce } from 'utils/Debounce'
import { trackTabOpened } from 'utils/GTM'
import { translateString } from 'utils/I18nHelper'
import { stringToId } from 'utils/Strings'

import AiTab from './AiTab'
import MarketplaceTab from './MarketplaceTab'
import RecommendationsTab from './RecommendationsTab'

import Checkin from '../Checkin'
import Checkout from '../Checkout'
import Directions from '../Directions'
import GroundTransport from '../GroundTransport'
import HouseRules from '../HouseRules'
import BabyQuip from '../Partners/BabyQuip'
import Weather from '../Partners/Weather'

class MainTabs extends Component {
  constructor(props) {
    super(props)
    const ua = new UAParser()
    let deviceType = ua.getDevice().type
    if (deviceType !== 'mobile' && deviceType !== 'tablet') {
      deviceType = 'desktop'
    }
    this.state = {
      renderCount: 0,
      deviceType: deviceType
    }
    this.selectedCategoryName = null
    this.handleTabChange = this.handleTabChange.bind(this)
    this.recClick = this.recClick.bind(this)
    this.defaultTab = 'arrival'
    this.deviceType = null
    this.harveyOverrides = false
    this.activeTabIndex = 0
    this.handleWindowResize = debounce(this.handleWindowResize.bind(this), 250)
    this.potentialTabs = updatePotentialTabs(props.guidebook)
    this.bounceTabs = this.bounceTabs.bind(this)
    this.scrollTo = this.scrollTo.bind(this)
    this.easeInOutQuad = this.easeInOutQuad.bind(this)
    // this._debounce = debounce.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize)
    this.sendTabOpenedEvent(this.props.match.params.activeTab)
    // update which markers we want to show on the map
    this.updateMap()
    // get hfTabs div and animate it
    this.bounceTabs()
  }
  componentDidUpdate() {
    // update which markers we want to show on the map
    this.updateMap()
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }
  componentWillReceiveProps(nextProps) {
    const currentTab = this.props.category
    const nextTab = nextProps.category
    if (currentTab !== nextTab) {
      this.sendTabOpenedEvent(nextProps.category)
    }

    if (
      this.props.guidebook &&
      nextProps.guidebook &&
      this.props.guidebook.key !== nextProps.guidebook.key
    ) {
      this.potentialTabs = updatePotentialTabs(nextProps.guidebook)
    }
  }

  sendTabOpenedEvent = (activeTab) => {
    const { id, host_user_id } = this.props.guidebook
    trackTabOpened(id, host_user_id, activeTab)
  }

  // indication that there are more tabs
  bounceTabs() {
    const self = this
    const { cookies } = this.props
    // don't bounce on mobile.
    if (this.state.deviceType !== 'mobile') {
      const hasBounced = cookies.get('hf_bouncetabs')
      if (self.activeTabIndex === 0 && (!hasBounced || hasBounced < 2)) {
        // bounce the tabs
        const tabsDiv = document.getElementById('main-tabs-ul')
        self.scrollTo(tabsDiv, 60, 10, () => {
          self.scrollTo(tabsDiv, 0, 10)
        })
        // set a cookie with number of times we've bounced
        const cookieOptions = {
          path: '/',
          expires: 0,
          sameSite: 'strict'
        }
        const numBounces = parseInt(hasBounced, 10) + 1 || 1
        cookies.set('hf_bouncetabs', numBounces, cookieOptions)
      }
    }
  }

  scrollTo(element, to, duration, cb) {
    var self = this
    var start = element.scrollLeft,
      change = to - start,
      currentTime = 0,
      increment = 0.125

    var animateScroll = function () {
      currentTime += increment
      var val = self.easeInOutQuad(currentTime, start, change, duration)
      element.scrollLeft = val
      if (currentTime < duration) {
        setTimeout(animateScroll, increment)
      }
      if (currentTime >= duration && typeof cb === 'function') {
        cb()
      }
    }
    animateScroll()
  }

  //t = current time
  //b = start value
  //c = change in value
  //d = duration
  easeInOutQuad(t, b, c, d) {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
  }

  // easeOutBounce(t, b, c, d) {
  //   if ((t/=d) < (1/2.75)) {
  //     return c*(7.5625*t*t) + b;
  //   } else if (t < (2/2.75)) {
  //     return c*(7.5625*(t-=(1.5/2.75))*t + .75) + b;
  //   } else if (t < (2.5/2.75)) {
  //     return c*(7.5625*(t-=(2.25/2.75))*t + .9375) + b;
  //   } else {
  //     return c*(7.5625*(t-=(2.625/2.75))*t + .984375) + b;
  //   }
  // }

  updateMap() {
    const { guidebook } = this.props
    const tabs = guidebook.recommendationsByCategory
    const { path } = this.props.match
    const { category } = this.props.match.params
    const selectedCategory = category ? tabs[category] : tabs['all']
    // if this category is already selected, don't dispatch anything
    if (
      selectedCategory &&
      selectedCategory.category.name === this.selectedCategoryName
    ) {
      return true
    }

    if (path.indexOf('recommendations') !== -1 && selectedCategory) {
      const markers = RecommendationsMarkers(
        selectedCategory.recommendations,
        50,
        true,
        null,
        this.recClick,
        guidebook.theme
      )
      if (!this.harveyOverrides && !this.props.recsOnly) {
        markers.push(GuidebookMarker(guidebook, 30, true))
      }
      this.props.dispatch(updateMapMarkers(markers))
      //this.props.dispatch(updateMapOptions({zoom:selectedCategory.recommendations.length}));
    } else if (typeof guidebook.address.lat === 'undefined') {
      return true
    } else {
      // if an essentials tab has been chosen
      const markers = RecommendationsMarkers(
        guidebook.recommendations,
        30,
        true,
        null,
        this.recClick,
        guidebook.theme
      )
      // place the marker for this Guidebook
      if (!this.harveyOverrides && !this.props.recsOnly) {
        markers.push(
          GuidebookMarker(guidebook, 50, true, null, null, guidebook.theme)
        )
      }
      this.props.dispatch(updateMapMarkers(markers))
    }
    this.selectedCategoryName = selectedCategory.category.name
  }

  recClick(rec, e) {
    if (typeof this.props.onRecClick === 'function') {
      this.props.onRecClick(rec, e)
    }
  }

  handleWindowResize = () => {
    // something funny happens when resizing.  maybe forcing a render of the tabs will help. Only needed when the map is visible
    const ua = new UAParser()
    let deviceType = ua.getDevice().type
    if (deviceType !== 'mobile' && deviceType !== 'tablet') {
      deviceType = 'desktop'
      this.setState({
        deviceType: deviceType,
        renderCount: this.state.renderCount ? this.state.renderCount + 1 : 1
      })
    } else {
      this.setState({ deviceType: deviceType })
    }
  }

  handleTabChange = (tabIndex, _tabId) => {
    const availableTabs = getAvailableTabs(
      this.potentialTabs,
      this.props.recsOnly
    )
    let tabKey = availableTabs[tabIndex]
      ? availableTabs[tabIndex]
      : availableTabs[0]
    const baseGuidebookUrl = this.buildBaseGuidebookUrl()
    // if we're on an essentials tab, set the path to the tabIndex
    if (isEssentialsTab(availableTabs[tabIndex], this.props.guidebook)) {
      this.props.dispatch(push(`${baseGuidebookUrl}/${tabKey}`))
      // otherwise set the path to recommendations category
    } else {
      this.props.dispatch(
        push(`${baseGuidebookUrl}/${this.props.pathBase}/${tabKey}`)
      )
    }
  }

  buildBaseGuidebookUrl = () => {
    const { recsOnly, guidebook } = this.props
    if (recsOnly && guidebook.recs_only_enabled && guidebook.recs_only_key) {
      return `/recs/${guidebook.recs_only_key}`
    } else {
      return `/${guidebook.key}`
    }
  }

  buildAvatar = (item, message) => {
    const name = message || item.name
    const letterStyle = {
      width: '24px',
      textAlign: 'center',
      fontSize: '20px',
      lineHeight: '24px'
    }
    let avatarIcon = null
    if (message) {
      avatarIcon = <div style={letterStyle}>{name.charAt(0).toUpperCase()}</div>
    }
    if (item.icon) {
      let iconMatch = matchData(
        allIcons,
        'Icons',
        ['field', 'v1_name'],
        item.icon
      )
      if (iconMatch) {
        const IconComponent = iconMatch.icon
        avatarIcon = <IconComponent fill="#ffffff" />
      }
    }
    return avatarIcon
  }

  render() {
    const { guidebook, intl, dispatch } = this.props
    const { formatMessage } = intl
    if (guidebook.key === 'loading') {
      return <div />
    }
    // hardcode default tab to 'house_manual' for HarveyHelp
    // we're now using the theme to set the acutal label for this tab
    const guidebooksToInclude = ['glltkdz', 'ggwrcmq', 'HarveyHelp', 'IrmaHelp']
    let houseManualLabel = formatMessage(labelMessages.house_manual)
    if (guidebooksToInclude.indexOf(guidebook.key) !== -1) {
      this.defaultTab = 'house_manual'
      this.harveyOverrides = true
    }
    if (guidebook.theme && guidebook.theme.home_label) {
      houseManualLabel =
        guidebook.theme.home_label_txn[intl.locale] ||
        guidebook.theme.home_label
    }
    // find what tabs are available
    const availableTabs = getAvailableTabs(
      this.potentialTabs,
      this.props.recsOnly
    )
    const { activeTab, category } = this.context.router.route.match.params
    if (
      guidebook.theme &&
      guidebook.theme.tab_orders &&
      Object.keys(guidebook.theme.tab_orders).length &&
      guidebook.default_path === ''
    ) {
      const orders = guidebook.theme.tab_orders
      // find the first tab in the theme's order that is actually available on this guidebook
      for (
        var key_idx = 0, key_len = Object.keys(orders).length;
        key_idx < key_len;
        key_idx++
      ) {
        const this_key = findKeyByValue(orders, key_idx)
        if (availableTabs.indexOf(this_key) !== -1) {
          this.defaultTab = this_key
          break
        }
      }
    }

    let selectedTab = null
    if (activeTab) {
      selectedTab = activeTab
    } else if (category) {
      selectedTab = category
    } else if (this.props.recsOnly) {
      selectedTab = availableTabs[0]
    } else {
      if (
        (this.defaultTab === 'arrival' &&
          guidebook.theme &&
          guidebook.theme.hide_arrival) ||
        (this.defaultTab === 'departure' &&
          guidebook.theme &&
          guidebook.theme.hide_departure) ||
        (this.defaultTab === 'all' &&
          guidebook.theme &&
          guidebook.theme.hide_all)
      ) {
        selectedTab = availableTabs[0]
      } else {
        selectedTab = this.defaultTab
      }
    }

    this.activeTabIndex = availableTabs.indexOf(selectedTab)

    // see if we have an essentials tab or recommendations tab
    const tabType = isEssentialsTab(selectedTab, guidebook)
      ? 'essentials'
      : 'recommendations'

    // if we're pointed to a tab that doesn't exist figure out what to do
    if (this.activeTabIndex === -1) {
      const baseGuidebookUrl = this.buildBaseGuidebookUrl()
      // for backward compatibilty, see if we've got an old category with spaces
      if (tabType === 'recommendations' && selectedTab.indexOf(' ') !== -1) {
        let findString = encodeURI(selectedTab)
        let replaceString = selectedTab.replace(/ /g, '_')
        let newpath = window.location.href.replace(findString, replaceString)
        window.location.href = newpath
      } else if (
        tabType === 'recommendations' &&
        guidebook.recommendations.length > 0
      ) {
        // category was not found but we do have recommendations - redirect to 'all'
        dispatch(push(`${baseGuidebookUrl}/recommendations/all`))
      } else if (availableTabs.length > 0) {
        // some other nonexistant tab was referred to - redirect to base guidebook
        dispatch(push(`${baseGuidebookUrl}`))
      }
      return <div />
    }
    const gbShape = {
      host_user_id: this.props.guidebook.host_user_id,
      id: this.props.guidebook.id,
      is_secure: this.props.guidebook.is_secure,
      key: this.props.guidebook.key || 'loading',
      token: this.props.guidebook.token_data
    }
    // get the contents of the various tabs
    let directions = null
    if (guidebook.directions || guidebook.address) {
      directions = (
        <Directions
          address={guidebook.address}
          directions={guidebook.directions}
          guidebook={gbShape}
          parking={guidebook.parking}
          custom_color={customColor('arrival', guidebook.theme, '#f44336')}
        />
      )
    }
    let checkin = null
    if (guidebook.checkin || guidebook.wifi) {
      checkin = (
        <Checkin
          guidebook={gbShape}
          checkin={guidebook.checkin}
          wifi={guidebook.wifi ? guidebook.wifi : null}
          host_intro={guidebook.host_intro ? guidebook.host_intro : null}
          custom_color={customColor('arrival', guidebook.theme, '#f44336')}
        />
      )
    }

    let checkout = null
    if (guidebook.checkout) {
      checkout = (
        <Checkout
          guidebook={gbShape}
          checkout={guidebook.checkout}
          custom_color={customColor('departure', guidebook.theme, '#ff9800')}
        />
      )
    }
    const hideGroundTransport =
      guidebook.theme && !guidebook.theme.show_ground_transport

    let groundTransportArrive = null
    let groundTransportDepart = null
    if (!hideGroundTransport) {
      groundTransportArrive = (
        <GroundTransport
          address={guidebook.address}
          mode="one_way"
          direction="from_airport"
          custom_color={customColor('arrival', guidebook.theme, '#f44336')}
        />
      )
      groundTransportDepart = (
        <GroundTransport
          address={guidebook.address}
          mode="one_way"
          direction="to_airport"
          custom_color={customColor('departure', guidebook.theme, '#ff9800')}
        />
      )
    }

    let babyQuipSection = null
    const babyQuipCountryCodes = [
      'us',
      'unitedstates',
      'united states',
      'untied states',
      'united states of america',
      'usa',
      'ca',
      'canada'
    ]
    if (guidebook.address && guidebook.address.country_code) {
      const showBabyQuip = guidebook.theme && guidebook.theme.show_babyquip
      const gbCC = guidebook.address.country_code.toLowerCase().trim()
      if (babyQuipCountryCodes.indexOf(gbCC) !== -1 && showBabyQuip) {
        babyQuipSection = (
          <BabyQuip
            guidebook={guidebook}
            custom_color={customColor('arrival', guidebook.theme, '#f44336')}
          />
        )
      }
    }

    let weatherSection = null

    if (guidebook.address && guidebook.address.lat && guidebook.address.lng) {
      const hideWeather = true
      // todo - replace weather widget and restore this
      // const hideWeather = guidebook.theme && !guidebook.theme.show_weather;
      if (!hideWeather) {
        weatherSection = (
          <Weather
            guidebook={guidebook}
            custom_color={customColor('arrival', guidebook.theme, '#f44336')}
          />
        )
      }
    }

    const tabsOutput = []
    const minHeight = { minHeight: '500px' }
    // console.log(guidebook.recommendationsByCategory);
    const token_data =
      guidebook.token_data && guidebook.token_data.data
        ? guidebook.token_data.data
        : {}
    for (var i = 0; i < availableTabs.length; i++) {
      // add tabs and cards based on key
      if (availableTabs[i] === 'arrival') {
        const tabOptions = this.potentialTabs['arrival']
        const tabColor = customColor('arrival', guidebook.theme, '#f44336')
        const tabStyle =
          selectedTab === 'arrival' ? { backgroundColor: tabColor } : {}
        const avatarStyle = { backgroundColor: tabColor }
        const avatarIcon = this.buildAvatar(tabOptions)
        tabsOutput.push(
          <Tab
            key="arrival"
            id="essentials-arrival"
            icon={<Avatar icon={avatarIcon} style={avatarStyle} />}
            className="md-text-capitalize hf-maintab"
            label={formatMessage(labelMessages.arrival)}
            style={tabStyle}
          >
            {selectedTab === 'arrival' ? (
              <div className="md-cell md-cell--12">
                <div className="md-grid">
                  {checkin}
                  {directions}
                  {weatherSection}
                  {babyQuipSection}
                  {groundTransportArrive}
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </Tab>
        )
      } else if (availableTabs[i] === 'departure') {
        const tabOptions = this.potentialTabs['departure']
        const tabColor = customColor('departure', guidebook.theme, '#ff9800')
        const tabStyle =
          selectedTab === 'departure' ? { backgroundColor: tabColor } : {}
        const avatarStyle = { backgroundColor: tabColor }
        const avatarIcon = this.buildAvatar(tabOptions)
        tabsOutput.push(
          <Tab
            key="departure"
            id="essentials-departure"
            icon={<Avatar icon={avatarIcon} style={avatarStyle} />}
            className="md-text-capitalize hf-maintab"
            label={formatMessage(labelMessages.departure)}
            style={tabStyle}
          >
            {selectedTab === 'departure' ? (
              <div className="md-cell md-cell--12">
                <div className="md-grid">
                  {checkout}
                  {groundTransportDepart}
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </Tab>
        )
      } else if (availableTabs[i] === 'ai') {
        const tabOptions = this.potentialTabs['ai']
        const tabColor = customColor('ai', guidebook.theme, '#74aa9c')
        const tabStyle =
          selectedTab === 'ai' ? { backgroundColor: tabColor } : {}
        const avatarStyle = { backgroundColor: tabColor }
        const avatarIcon = this.buildAvatar(tabOptions)
        let aiLabel = formatMessage(labelMessages.ai)
        if (guidebook.theme && guidebook.theme.ai_label) {
          aiLabel =
            guidebook.theme.ai_label_txn[intl.locale] ||
            guidebook.theme.ai_label
        }
        const aiTab =
          selectedTab === 'ai' ? <AiTab tabColor={tabColor} /> : null
        tabsOutput.push(
          <Tab
            key="ai"
            id="essentials-ai"
            icon={<Avatar icon={avatarIcon} style={avatarStyle} />}
            className="md-text-capitalize hf-maintab"
            label={aiLabel}
            style={tabStyle}
          >
            {aiTab}
          </Tab>
        )
      } else if (availableTabs[i] === 'marketplace') {
        const tabOptions = this.potentialTabs['marketplace']
        const tabColor = customColor('marketplace', guidebook.theme, '#ffc107')
        const tabStyle =
          selectedTab === 'marketplace' ? { backgroundColor: tabColor } : {}
        const avatarStyle = { backgroundColor: tabColor }
        const avatarIcon = this.buildAvatar(tabOptions)
        let marketplaceLabel = formatMessage(labelMessages.marketplace)
        if (guidebook.theme && guidebook.theme.mktpl_label) {
          marketplaceLabel =
            guidebook.theme.mktpl_label_txn[intl.locale] ||
            guidebook.theme.mktpl_label
        }
        const marketplaceTab =
          selectedTab === 'marketplace' ? (
            <MarketplaceTab
              guidebookKey={guidebook.key}
              tabColor={tabColor}
              marketplace_items={guidebook.marketplace_items}
              recsOnly={this.props.recsOnly}
            />
          ) : null
        tabsOutput.push(
          <Tab
            key="marketplace"
            id="essentials-marketplace"
            icon={<Avatar icon={avatarIcon} style={avatarStyle} />}
            className="md-text-capitalize hf-maintab"
            label={marketplaceLabel}
            style={tabStyle}
          >
            {marketplaceTab}
          </Tab>
        )
      } else if (availableTabs[i] === 'house_manual') {
        const tabOptions = this.potentialTabs['house_manual']
        const tabColor = customColor('house_manual', guidebook.theme, '#ff5722')
        const tabStyle =
          selectedTab === 'house_manual' ? { backgroundColor: tabColor } : {}
        const avatarStyle = { backgroundColor: tabColor }
        const avatarIcon = this.buildAvatar(tabOptions)
        tabsOutput.push(
          <Tab
            key="house_manual"
            id="essentials-house_manual"
            icon={<Avatar icon={avatarIcon} style={avatarStyle} />}
            className="md-text-capitalize InformationsTab hf-maintab"
            label={houseManualLabel}
            style={tabStyle}
          >
            {selectedTab === 'house_manual' ? (
              <div style={minHeight} className="md-cell md-cell--12">
                <HouseRules
                  tabKey={'house_manual'}
                  guidebook={gbShape}
                  informations={
                    guidebook.informationsByCategory[availableTabs[i]]
                      .informations
                  }
                  custom_color={tabColor}
                  token={token_data}
                />
              </div>
            ) : (
              <div></div>
            )}
          </Tab>
        )
      } else if (
        guidebook.informationsByCategory.hasOwnProperty(availableTabs[i])
      ) {
        const category_name = availableTabs[i]
        const category =
          guidebook.informationsByCategory[category_name.toLowerCase()].category
        const category_id = category.id
        const category_cards =
          guidebook.informationsByCategory[category_name.toLowerCase()]
            .informations
        let tabColor = category.color || '#CCCCCC'
        const category_key = category.name.toLowerCase().replace(/ /g, '_')
        const tabStyle =
          selectedTab.toLowerCase() === category_key
            ? { backgroundColor: tabColor }
            : {}
        const avatarStyle = { backgroundColor: tabColor }
        const message = translateString(
          intl.locale,
          category.name,
          category.name_txn
        )
        const avatarIcon = this.buildAvatar(category, message)
        tabsOutput.push(
          <Tab
            key={category_id}
            id={`essentials-${stringToId(category_name)}`}
            icon={<Avatar icon={avatarIcon} style={avatarStyle} />}
            className="md-text-capitalize InformationsTab hf-maintab"
            label={message}
            style={tabStyle}
          >
            {selectedTab === category_key ? (
              <div style={minHeight} className="md-cell md-cell--12">
                <HouseRules
                  tabKey={category_key}
                  guidebook={gbShape}
                  guidebookKey={guidebook.key}
                  informations={category_cards}
                  custom_color={tabColor}
                  token={token_data}
                />
              </div>
            ) : (
              <div></div>
            )}
          </Tab>
        )
      } else if (
        guidebook.recommendationsByCategory.hasOwnProperty(availableTabs[i])
      ) {
        // it's a recommendation category.
        const { visibleMarkerIds } = this.props
        const category_name = availableTabs[i]
        const category =
          guidebook.recommendationsByCategory[category_name.toLowerCase()]
            .category
        const category_id = category.id
        const category_recs =
          guidebook.recommendationsByCategory[category_name.toLowerCase()]
            .recommendations
        const visibleRecs = category_recs.filter((rec) => {
          // recommendations with no address count as visible
          const isLocationless = rec.address === null
          return (
            !visibleMarkerIds ||
            isLocationless ||
            visibleMarkerIds.indexOf('rec_' + rec.id) > -1
          )
        })
        const hiddenRecs = category_recs.filter((rec) => {
          const isLocationless = rec.address === null
          return (
            visibleMarkerIds &&
            !isLocationless &&
            visibleMarkerIds.indexOf('rec_' + rec.id) === -1
          )
        })
        let tabColor = category.color || '#CCCCCC'
        if (category.is_default || category.name === 'all') {
          tabColor = customColor(category_name, guidebook.theme, tabColor)
        }
        const category_key = category.name.toLowerCase().replace(/ /g, '_')
        const tabStyle =
          selectedTab.toLowerCase() === category_key
            ? { backgroundColor: tabColor }
            : {}
        const avatarStyle = { backgroundColor: tabColor }
        // see if we have a legacy translation based on the category name
        let message = null
        if (category_name === 'all') {
          const messageId = categoryNames[category_name]
          message = formatMessage(messageId)
        } else {
          message = translateString(
            intl.locale,
            category.name,
            category.name_txn
          )
        }
        const avatarIcon = this.buildAvatar(category, message)
        // console.log(category);
        const recTab = (
          <RecommendationsTab
            guidebook={guidebook}
            key={category_id}
            category={category}
            isActive={selectedTab === category_name}
            recommendations={visibleRecs}
            hiddenRecommendations={hiddenRecs}
            recsOnly={this.props.recsOnly}
          />
        )
        tabsOutput.push(
          <Tab
            id={'recommendation-' + stringToId(category_name)}
            key={category_name}
            icon={<Avatar style={avatarStyle}>{avatarIcon}</Avatar>}
            className="md-text-capitalize RecommendationsTabs hf-maintab"
            label={message}
            style={tabStyle}
          >
            {recTab}
          </Tab>
        )
      }
    }

    let titleMessage = ' - '
    // console.log(tabType);
    if (tabType === 'recommendations' || this.props.recsOnly) {
      const category = guidebook.recommendationsByCategory[selectedTab].category
      const catName = translateString(
        intl.locale,
        category.name,
        category.name_txn
      )
      titleMessage += catName
    } else if (
      tabType === 'essentials' &&
      Object.keys(guidebook.informationsByCategory).indexOf(selectedTab) !== -1
    ) {
      const category = guidebook.informationsByCategory[selectedTab].category
      const catName = translateString(
        intl.locale,
        category.name,
        category.name_txn
      )
      titleMessage += catName
    } else if (tabType === 'essentials' && selectedTab === 'house_manual') {
      titleMessage += houseManualLabel
    } else {
      titleMessage += formatMessage(labelMessages[selectedTab])
    }

    // add slideStyle={height: 'auto'} for windows
    const tcSlideStyle =
      navigator.platform.indexOf('Win') > -1
        ? { height: 'auto', minHeight: '500px' }
        : { minHeight: '500px' }

    const titleBase = this.props.recsOnly
      ? this.props.guidebook.recs_only_name
        ? this.props.guidebook.recs_only_name
        : 'Recommendations'
      : this.props.guidebook.name
    return (
      <DocumentTitle title={titleBase + titleMessage}>
        <div id="main-tabs">
          <TabsContainer
            className="md-grid md-grid--no-spacing"
            panelClassName="md-grid md-grid--no-spacing"
            onTabChange={this.handleTabChange}
            activeTabIndex={this.activeTabIndex}
            slideStyle={tcSlideStyle}
            key={'tabs-' + this.state.renderCount}
          >
            <Tabs
              id="main-tabs-ul"
              tabId="tabs"
              className="hfTabs hf-scrolling-tabs md-cell md-cell--12"
              mobile={true}
            >
              {tabsOutput}
            </Tabs>
          </TabsContainer>
        </div>
      </DocumentTitle>
    )
  }
}

// ask for `router` from context
MainTabs.contextTypes = {
  router: PropTypes.object,
  history: PropTypes.object
}
MainTabs.propTypes = {
  intl: intlShape.isRequired,
  dispatch: PropTypes.func.isRequired,
  guidebook: PropTypes.object,
  visibleMarkerIds: PropTypes.array,
  recsOnly: PropTypes.bool
}
MainTabs.defaultProps = {
  tabsClass: 'recommendations',
  pathBase: 'recommendations',
  recsOnly: false
}

export default withCookies(injectIntl(MainTabs))
