import React from 'react'

const RoomService = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`room_service`}</title>
    <path d="M13.828 7.781c3.891.797 6.891 4.125 7.172 8.203H3c.281-4.078 3.281-7.406 7.172-8.203-.094-.234-.188-.516-.188-.797 0-1.078.938-1.969 2.016-1.969s2.016.891 2.016 1.969c0 .281-.094.563-.188.797zM2.016 17.016h19.969v1.969H2.016v-1.969z" />
  </svg>
)

export default RoomService
