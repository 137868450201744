import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  DialogContainer,
  FontIcon,
  Tab,
  Tabs,
  TabsContainer,
  TextField
} from 'react-md'

import TooltipButton from 'components/TooltipButton'
import { deleteItem, editItem, newItem } from 'redux/modules/crud'
import currentUser from 'utils/CurrentUser'
import { filterData, fuzzyFilter, sortData } from 'utils/Data'

import EmptyState from '../EmptyState'

const propTypes = {
  collection: PropTypes.object.isRequired,
  pluralName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  defaultSort: PropTypes.string.isRequired,
  defaultSortDirection: PropTypes.string,
  filterFields: PropTypes.array.isRequired,
  rowRenderer: PropTypes.func.isRequired
}

const defaultProps = {
  defaultSortDirection: 'asc'
}

class List extends Component {
  constructor(props) {
    super(props)
    this.newItem = this.newItem.bind(this)
    this.editItem = this.editItem.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.filter = this.filter.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.state = {
      showDeleteConfirm: false,
      confirmDeleteId: null,
      filter: '',
      activeTabIndex: 0
    }
  }

  filter = (filter) => {
    this.setState({ filter })
  }

  clearFilter = () => {
    this.setState({ filter: '' })
  }

  newItem = () => {
    const { pluralName, dispatch } = this.props
    dispatch(newItem(pluralName))
  }

  editItem = (id, e) => {
    const { pluralName, dispatch } = this.props
    dispatch(editItem(pluralName, id, e))
  }

  deleteItem = (e) => {
    // prevent propagation so it doesn't also fire edit
    const { pluralName, singularName, dispatch } = this.props
    this.closeDialog()
    if (this.state.confirmDeleteId) {
      dispatch(deleteItem(pluralName, singularName, this.state.confirmDeleteId))
    }
  }

  handleTabChange = (activeTabIndex) => {
    this.setState({ activeTabIndex })
  }

  confirmDelete = (item, e) => {
    e.stopPropagation()
    const itemName = item.name || item.label || item.title
    this.setState({
      showDeleteConfirm: true,
      confirmDeleteId: item.id,
      confirmDeleteName: itemName
    })
  }

  closeDialog = () => {
    this.setState({ showDeleteConfirm: false, confirmDeleteId: null })
  }

  renderDeleteConfirmation = () => {
    return (
      <DialogContainer
        id="simpleDialogExample"
        aria-label="Confirm delete dialog"
        visible={this.state.showDeleteConfirm}
        onHide={this.closeDialog}
        actions={[
          {
            onClick: this.closeDialog,
            primary: true,
            label: 'Cancel'
          },
          {
            onClick: this.deleteItem,
            primary: true,
            label: 'Delete'
          }
        ]}
      >
        <p id="deleteItemDescription" className="md-color--secondary-text">
          Delete {this.props.singularName} "{this.state.confirmDeleteName}"?
        </p>
      </DialogContainer>
    )
  }

  buildRows = (items, pluralName, rowRenderer) => {
    const self = this
    const Row = rowRenderer

    return items.map(function (item) {
      return (
        <Row
          editItem={(e) => {
            self.editItem(item.id, e)
          }}
          deleteItem={(e) => {
            self.confirmDelete(item, e)
          }}
          item={item}
          pluralName={pluralName}
          key={item.id}
          id={item.id}
        />
      )
    })
  }

  buildList = (items, pluralName, rowRenderer) => {
    const user = currentUser()
    if (pluralName === 'guidebooks') {
      const allCount = items.length
      const publishedCount = items.filter(
        (item) => item['is_published'] === true
      ).length
      const draftCount = items.filter(
        (item) => item['is_published'] === false
      ).length

      const allRows =
        this.state.activeTabIndex === 0 ? (
          this.buildRows(items, pluralName, rowRenderer)
        ) : (
          <div />
        )
      const publishedRows =
        this.state.activeTabIndex === 1 ? (
          this.buildRows(
            filterData(items, pluralName, ['is_published'], true),
            pluralName,
            rowRenderer
          )
        ) : (
          <div />
        )
      const draftRows =
        this.state.activeTabIndex === 2 ? (
          this.buildRows(
            filterData(items, pluralName, ['is_published'], false),
            pluralName,
            rowRenderer
          )
        ) : (
          <div />
        )

      return (
        <TabsContainer
          className="hf-list-tabs"
          onTabChange={this.handleTabChange}
          activeTabIndex={this.state.activeTabIndex}
          themed
        >
          <Tabs tabId="tab" className="hf-scrolling-tabs" mobile={true}>
            <Tab label={`All (${allCount})`} key="all">
              {allRows}
            </Tab>
            <Tab
              label={`Published (${publishedCount}/${user.guidebookLimit})`}
              key="published"
              title={`${publishedCount} guidebooks published out of ${user.guidebookLimit} available`}
            >
              {publishedRows}
            </Tab>
            <Tab label={`Drafts (${draftCount})`} key="drafts">
              {draftRows}
            </Tab>
          </Tabs>
        </TabsContainer>
      )
    } else if (pluralName === 'tokens') {
      const allCount = items.length
      const activeCount = items.filter((item) =>
        this.tokenIsActive(item)
      ).length
      const expiredCount = items.filter(
        (item) => !this.tokenIsActive(item)
      ).length

      const activeRows =
        this.state.activeTabIndex === 0 ? (
          this.buildRows(
            items.filter((item) => this.tokenIsActive(item)),
            pluralName,
            rowRenderer
          )
        ) : (
          <div />
        )
      const expiredRows =
        this.state.activeTabIndex === 1 ? (
          this.buildRows(
            items.filter((item) => !this.tokenIsActive(item)),
            pluralName,
            rowRenderer
          )
        ) : (
          <div />
        )
      const allRows =
        this.state.activeTabIndex === 2 ? (
          this.buildRows(items, pluralName, rowRenderer)
        ) : (
          <div />
        )

      return (
        <TabsContainer
          className="hf-list-tabs"
          onTabChange={this.handleTabChange}
          activeTabIndex={this.state.activeTabIndex}
          themed
        >
          <Tabs tabId="tab" className="hf-scrolling-tabs" mobile={true}>
            <Tab label={`Active / Future (${activeCount})`} key="published">
              {activeRows}
            </Tab>
            <Tab label={`Disabled / Expired (${expiredCount})`} key="drafts">
              {expiredRows}
            </Tab>
            <Tab label={`All (${allCount})`} key="all">
              {allRows}
            </Tab>
          </Tabs>
        </TabsContainer>
      )
    } else if (pluralName === 'domains' && user.isAdmin) {
      const allCount = items.length
      const approvedCount = items.filter((item) =>
        this.domainIsApproved(item)
      ).length
      const pendingCount = items.filter(
        (item) => !this.domainIsApproved(item)
      ).length

      const pendingRows =
        this.state.activeTabIndex === 0 ? (
          this.buildRows(
            items.filter((item) => !this.domainIsApproved(item)),
            pluralName,
            rowRenderer
          )
        ) : (
          <div />
        )
      const approvedRows =
        this.state.activeTabIndex === 1 ? (
          this.buildRows(
            items.filter((item) => this.domainIsApproved(item)),
            pluralName,
            rowRenderer
          )
        ) : (
          <div />
        )
      const allRows =
        this.state.activeTabIndex === 2 ? (
          this.buildRows(items, pluralName, rowRenderer)
        ) : (
          <div />
        )

      return (
        <TabsContainer
          className="hf-list-tabs"
          onTabChange={this.handleTabChange}
          activeTabIndex={this.state.activeTabIndex}
          themed
        >
          <Tabs tabId="tab" className="hf-scrolling-tabs" mobile={true}>
            <Tab label={`In Progress (${pendingCount})`} key="drafts">
              {pendingRows}
            </Tab>
            <Tab label={`Accepted (${approvedCount})`} key="published">
              {approvedRows}
            </Tab>
            <Tab label={`All (${allCount})`} key="all">
              {allRows}
            </Tab>
          </Tabs>
        </TabsContainer>
      )
    } else {
      return (
        <div className={pluralName === 'templates' ? 'hf-list-content' : ''}>
          {this.buildRows(items, pluralName, rowRenderer)}
        </div>
      )
    }
  }

  tokenIsActive = (token) => {
    const initEndDate = new Date(token.end_date)
    const endDate = new Date(
      initEndDate.getFullYear(),
      initEndDate.getMonth(),
      initEndDate.getDate()
    )
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    return token.is_enabled && today <= endDate
  }

  domainIsApproved = (domain) => {
    return domain.status === 'approved'
  }

  render() {
    const {
      collection,
      pluralName,
      defaultSort,
      defaultSortDirection,
      filterFields,
      rowRenderer
    } = this.props
    const { filter } = this.state
    // sort the items
    const filteredItems = fuzzyFilter(
      collection.data,
      pluralName,
      filterFields,
      filter
    )
    const sorted = sortData(filteredItems, defaultSort, defaultSortDirection)
    const list =
      !collection.isLoading && !collection.data.length ? (
        <EmptyState type={pluralName} />
      ) : (
        this.buildList(sorted, pluralName, rowRenderer)
      )

    return (
      <div>
        <TextField
          id="filter"
          value={filter}
          label="Search / Filter"
          type="text"
          leftIcon={<FontIcon>search</FontIcon>}
          inlineIndicator={
            <TooltipButton
              className="hf-mini-btn"
              tooltipLabel="Clear Search / Filter"
              tooltipPosition="left"
              onClick={this.clearFilter}
              icon
            >
              <FontIcon>close</FontIcon>
            </TooltipButton>
          }
          onChange={this.filter}
        />
        {list}
        {this.renderDeleteConfirmation()}
      </div>
    )
  }
}

List.propTypes = propTypes
List.defaultProps = defaultProps

export default List
