import React from 'react'

const Clubhouse = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`clubhouse`}</title>
    <path d="M7.3 17.3h9.3l4.7 4.7H2.7l4.6-4.7zM1.3 2.5v14.2h21.3V2.5H1.3zm14 .5h1.8v2.5h-1.8V3zm-2.1 0H15v2.5h-1.8V3zm-2 .2H13v2.3h-1.8V3.2zM9 3.2h1.8v2.3H9V3.2zm-2.2 0h1.8v2.3H6.8V3.2zM4.5 5.5H2.7V3.2h1.8v2.3zM4.8 3h1.7v2.5H4.8V3zm6.9 13.5H7.3V10h4.3v6.5zm5 0h-4.3V10h4.3v6.5zm-11.4-7L12 8.3l6.7 1.2H5.3zm13.9-4h-1.7V3.2h1.8l-.1 2.3zm2.1 0h-1.8V3.2h1.8v2.3z" />
  </svg>
)

export default Clubhouse
