import React from 'react'

const Toaster = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`toaster`}</title>
    <path d="M12 2c-2.32 0-3.852.07-4.969.344-.558.136-1.043.304-1.437.687C5.199 3.414 5 4.004 5 4.5c0 .879.43 1.566.844 1.938.074.066.082.042.156.093V8c-2.2 0-4 1.8-4 4v8h20v-5h2v-1c0-.602-.398-1-1-1h-1v-1c0-2.2-1.8-4-4-4V6.469c.473-.367 1-.926 1-1.969 0-.488-.203-1.07-.594-1.438-.39-.367-.883-.542-1.437-.687C15.855 2.09 14.329 2 12 2zm0 2c2.27 0 3.73.121 4.469.313.343.09.496.16.531.187 0 .492-.375.563-.375.563l-.625.25V8H8V5.25l-.719-.219s-.039.016-.125-.062S7 4.82 7 4.5c0-.105-.012-.023 0-.031s.133-.098.5-.188C8.234 4.101 9.723 4 12 4zm-1 1c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm2.5 1c-.277 0-.5.223-.5.5s.223.5.5.5.5-.223.5-.5-.223-.5-.5-.5zM19 16c.602 0 1 .398 1 1s-.398 1-1 1-1-.398-1-1 .398-1 1-1z" />
  </svg>
)

export default Toaster
