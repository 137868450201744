import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import {
  Button,
  Card,
  CardText,
  CardTitle,
  DialogContainer,
  Media
} from 'react-md'

import IconCard from 'components/Cards/IconCard'
import PhotoCard from 'components/Cards/PhotoCard'
import TextCard from 'components/Cards/TextCard'
import VideoCard from 'components/Cards/VideoCard'
import AsHtml from 'components/Translator/AsHtml'
import injectTranslator from 'components/Translator/injectTranslator'
import { houseRulesInformationDefaults } from 'constants/SharedPropDefaults'
import { houseRulesInformationShape } from 'constants/SharedPropTypes'
import { allIcons } from 'constants/UIIcons'
import { filterData, matchData } from 'utils/Data'
import { trackCardOpened } from 'utils/GTM';
import { obfuscateID, stringToId } from 'utils/Strings'
// import { trackEvent } from 'utils/Segment';
import { updateVideoUrl } from 'utils/Urls'

const propTypes = {
  guidebook: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    host_user_id: PropTypes.number
  }),
  information: houseRulesInformationShape.isRequired,
  initialVisible: PropTypes.bool,
  custom_color: PropTypes.string,
  tabKey: PropTypes.string,
  token: PropTypes.object
}

const defaultProps = {
  information: houseRulesInformationDefaults,
  custom_color: 'rgb(255, 87, 34)',
  initialVisible: false,
  focusOnMount: true,
  tabKey: 'house_manual',
  token: {}
}

class HouseRulesItem extends PureComponent {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      visible: props.initialVisible,
      pageX: null,
      pageY: null
    }
    this.renderCardBasedOnTemplate = this.renderCardBasedOnTemplate.bind(this)
    this.openDialog = this.openDialog.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
    if (props.initialVisible) {
      this.trackOpen()
    }
  }

  openDialog(e) {
    let { pageX, pageY } = e
    if (e.changedTouches) {
      const [touch] = e.changedTouches
      pageX = touch.pageX
      pageY = touch.pageY
    }
    this.trackOpen()
    this.setState({ visible: true, pageX, pageY })
    // push route to history
    const cardIdString = obfuscateID(this.props.information.id)
    const cardUrl = `/${this.props.guidebook.key}/${this.props.tabKey}/${cardIdString}`
    this.context.router.history.push(cardUrl)
  }

  closeDialog() {
    this.setState({ visible: false })
    const removeCardUrl = `/${this.props.guidebook.key}/${this.props.tabKey}/`
    this.context.router.history.push(removeCardUrl)
  }

  trackOpen = () => {
    const guidebook_id = this.props.guidebook.id || 0
    const host_user_id = this.props.guidebook.host_user_id || 0
    const tab_name = this.props.tabKey || 'unknown'
    const card_key = obfuscateID(this.props.information.id) || null
    const card_name = this.props.information.title || null
    // send card_opened event to GTM/GA4
    trackCardOpened(guidebook_id, host_user_id, tab_name, card_name, card_key);
  }

  openLink = () => {
    window.open(this.props.information.external_link, 'newExternalTab')
  }

  renderCardBasedOnTemplate = (titleString) => {
    const { information } = this.props
    let showIcon = null
    if (information.icon) {
      let iconMatch = matchData(
        allIcons,
        'Icons',
        ['field', 'v1_name'],
        information.icon
      )
      if (iconMatch) showIcon = iconMatch
    }

    const callback =
      information.use_external_link && information.external_link
        ? this.openLink
        : this.openDialog

    switch (information.template) {
      case 0:
        return (
          <PhotoCard
            iconSuffix="hfminimalgrey"
            titleString={titleString}
            imageUrl={information.image}
            icon={showIcon}
            clickCallBack={callback}
            custom_color={this.props.custom_color}
          />
        )
      case 1:
        return (
          <VideoCard
            iconSuffix="hfminimalgrey"
            titleString={titleString}
            videoUrl={information.video}
            icon={showIcon}
            clickCallBack={callback}
            custom_color={this.props.custom_color}
          />
        )
      case 2:
        return (
          <TextCard
            iconSuffix="hforange"
            titleString={titleString}
            leadPara={information.lead_para}
            leadParaTxn={information.lead_para_txn}
            icon={showIcon}
            clickCallBack={callback}
            custom_color={this.props.custom_color}
          />
        )
      default:
        //V1 cards revert to icon template...
        return (
          <IconCard
            iconSuffix="hforange"
            titleString={titleString}
            icon={showIcon}
            clickCallBack={callback}
            custom_color={this.props.custom_color}
          />
        )
    }
  }

  renderVideoIfApplicable = () => {
    const { information, translator } = this.props
    if (information.template === 1) {
      const titleString = translator(information.title, information.title_txn)
      const videoCode = (
        <iframe
          title={'Video - ' + titleString}
          src={updateVideoUrl(information.video)}
          frameBorder="0"
          seamless="seamless"
        />
      )
      const mediaStyle = { marginBottom: '20px' }
      return (
        <Media aspectRatio="7-4" style={mediaStyle}>
          {videoCode}
        </Media>
      )
    }
    return null
  }

  render() {
    const { information, translator } = this.props
    const dialogId = stringToId(`dailog-${information.title}`)
    const titleString = translator(information.title, information.title_txn)
    const selectedIcon = filterData(
      allIcons,
      'Icons',
      ['field', 'v1_name'],
      information.icon
    )
    let showIcon = null
    if (selectedIcon.length > 0) {
      const Icon = selectedIcon[0].icon
      showIcon = <Icon fill="#545454" />
    }
    const variableData = { token: this.props.token }

    return (
      <div>
        {this.renderCardBasedOnTemplate(titleString)}
        <DialogContainer
          id={dialogId}
          {...this.state}
          onHide={this.closeDialog}
          fullPage
          portal={true}
          lastChild={true}
          disableScrollLocking={true}
          renderNode={document.body}
          aria-label={'Detailed information about ' + information.title}
          className="hf-guest-modal"
          focusOnMount={this.props.focusOnMount}
        >
          <div className="list-item-modal-content">
            <Card className="list-item-modal-card" tabIndex="0">
              <Button
                icon
                className="md-cell--right list-item-modal-close"
                onClick={this.closeDialog}
              >
                keyboard_backspace
              </Button>
              <CardTitle avatar={showIcon} title={titleString}></CardTitle>
              <CardText dir="auto">
                {this.renderVideoIfApplicable()}
                <AsHtml
                  text={information.content}
                  translations={information.content_txn}
                  variableData={variableData}
                />
              </CardText>
            </Card>
          </div>
        </DialogContainer>
      </div>
    )
  }
}

HouseRulesItem.propTypes = propTypes
HouseRulesItem.defaultProps = defaultProps

export default injectTranslator(HouseRulesItem)
