import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, injectIntl, intlShape } from 'react-intl'
import { Avatar, Button, Card, CardText, CardTitle, FontIcon } from 'react-md'
import formatAddress from 'components/Address/FormatAddress'
// import { trackEvent, getHostIdAndKey } from 'utils/Segment';
import { guidebookShape } from 'constants/SharedPropTypes'

const propTypes = {
  guidebook: guidebookShape.isRequired,
  custom_color: PropTypes.string,
  intl: intlShape.isRequired
}
const defaultProps = {}

class BabyQuip extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  formatMessage = this.props.intl.formatMessage

  messages = defineMessages({
    rentBabyGear: {
      id: 'partners.babyquip.rentBabyGear',
      defaultMessage: 'Rent Baby Gear'
    },
    babyQuipHeadline: {
      id: 'partners.babyquip.headline',
      defaultMessage: 'Pack light. Travel happy.'
    },
    babyQuipSubHeadline: {
      id: 'partners.babyquip.subheadline',
      defaultMessage: 'Safe rental essentials for babies and toddlers.'
    },
    babyQuipCopyLine1: {
      id: 'partners.babyquip.copyLine1',
      defaultMessage:
        'BabyQuip will deliver, set-up, and pick-up all the baby gear your little one needs, delivered at a time convenient for you. '
    },
    babyQuipCopyLine2: {
      id: 'partners.babyquip.copyLine2',
      defaultMessage:
        'Clean, quality gear, meets safety standards, and fully insured. Cribs, strollers, car seats, high chairs, toys, bath, bouncy seats and more!'
    },
    babyQuipLearnMore: {
      id: 'partners.babyquip.learnMore',
      defaultMessage: 'Learn More'
    }
  })

  trackBabyQuipEvent = (type, cb) => {
    if (typeof cb !== 'function') cb = function () {}
    // OLD SEGMENT CODE
    // let eventData = getHostIdAndKey(this.props.guidebook);
    // eventData.category = 'BabyQuip';
    // if (eventData.key) {
    //   eventData.label = eventData.key;
    // }
    // trackEvent('babyquip' + type, eventData, {}, cb);
  }

  handleSubmit = () => {
    const address = this.props.guidebook.address
    const baseUrl = 'https://www.babyquip.com'
    let searchUrl = baseUrl
    if (address) {
      const { addressPrimary, addressSecondary } = formatAddress(address)
      const encodedAddress = encodeURIComponent(
        addressPrimary + ' ' + addressSecondary
      )
      searchUrl += `/partners/location-search?searchAddress=${encodedAddress}`
      searchUrl += '&affiliate=hostfully'
    } else {
      searchUrl += '?affiliate=hostfully'
    }
    // OLD SEGMENT CODE
    // this.trackBabyQuipEvent('Clicked', () => {
    window.open(searchUrl)
    // });
  }

  render() {
    const avatarIcon = <FontIcon>child_friendly</FontIcon>
    const avatarStyle = { background: this.props.custom_color || '#f44336' }
    const imageWrapperStyle = { marginBottom: '20px' }
    const imageStyle = { maxHeight: '60px' }
    const buttonStyle = { marginTop: '20px' }
    return (
      <Card className="md-cell md-cell--12 hf-babyquip" raise={true}>
        <CardTitle
          avatar={
            <Avatar icon={avatarIcon} role="presentation" style={avatarStyle} />
          }
          title={this.formatMessage(this.messages.rentBabyGear)}
        ></CardTitle>
        <CardText>
          <div>
            <div style={imageWrapperStyle}>
              <img src="/babyquip.png" alt="BabyQuip Logo" style={imageStyle} />
            </div>
            <strong>
              {this.formatMessage(this.messages.babyQuipHeadline)}
            </strong>{' '}
            {this.formatMessage(this.messages.babyQuipSubHeadline)}
            <br />
            <br />
            {this.formatMessage(this.messages.babyQuipCopyLine1)}
            <br />
            <br />
            {this.formatMessage(this.messages.babyQuipCopyLine2)}
            <br />
            <br />
          </div>
          <Button
            onClick={this.handleSubmit}
            style={buttonStyle}
            raised
            primary
          >
            {this.formatMessage(this.messages.babyQuipLearnMore)}
          </Button>
        </CardText>
      </Card>
    )
  }
}
BabyQuip.propTypes = propTypes
BabyQuip.defaultProps = defaultProps

export default injectIntl(BabyQuip)
