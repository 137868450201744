/*eslint no-unused-vars: ["off", { "ignoreRestSiblings": true }]*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions, Control } from 'react-redux-form'
import { TextField } from 'react-md'

class InputTextField extends Component {
  render() {
    const { id, model, label } = this.props
    const { validators, preventWhitespace, ...childProps } = this.props

    const trimValue = (value) => {
      return value && this.props.preventWhitespace ? value.trim() : value
    }

    const input = (props) => {
      const { value } = props
      return (
        <TextField
          {...props}
          id={id}
          ref={this.setInputTextRef}
          label={label}
          type={this.props.type || 'text'}
        />
      )
    }
    return (
      <Control.text
        model={model}
        validators={validators}
        component={input}
        parser={trimValue}
        {...childProps}
      />
    )
  }
}
InputTextField.propTypes = {
  preventWhitespace: PropTypes.bool
}
InputTextField.defaultProps = {
  preventWhitespace: false
}

export default connect()(InputTextField)
