export const downloadQRCode = (svg, fileName) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const svgData = new XMLSerializer().serializeToString(svg)
  const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' })
  const url = URL.createObjectURL(svgBlob)
  const img = new Image()
  const scale = 8
  const width = svg.clientWidth * scale
  const height = svg.clientHeight * scale

  canvas.width = width
  canvas.height = height

  img.onload = () => {
    ctx.scale(scale, scale)
    ctx.drawImage(img, 0, 0)
    URL.revokeObjectURL(url)

    const pngUrl = canvas.toDataURL('image/png')
    const a = document.createElement('a')

    a.href = pngUrl
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  img.src = url
}
