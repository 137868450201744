import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'
import Paper from 'react-md/lib/Papers'
import CoverPage from './Sections/CoverPage.js'
import CoverPage2 from './Sections/CoverPage2.js'
import CoverPage3 from './Sections/CoverPage3.js'
import CoverPage4 from './Sections/CoverPage4.js'
import HostIntro from './SubSections/HostIntro'
import Arrival from './Sections/Arrival.js'
import Departure from './Sections/Departure.js'
import HouseManual from './Sections/HouseManual.js'
import RecommendationSection from './Sections/Recommendation.js'
import {
  updatePotentialTabs,
  getAvailableTabs
} from 'components/NavDrawer/PotentialTabs'
import { defineMessages, intlShape } from 'react-intl'
import { translateString } from 'utils/I18nHelper'
import { buildGuidebookUrl } from 'utils/Urls'
import { stringToId } from 'utils/Strings'
import { labelMessages } from 'constants/UIConstants'
import reactCSS from 'reactcss'

const propTypes = {
  className: PropTypes.string,
  guidebook: PropTypes.shape({
    key: PropTypes.string.isRequired,
    token: PropTypes.object
  }).isRequired,
  printPreferences: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  dispatch: PropTypes.func.isRequired,
  guest: PropTypes.bool
}

const defaultProp = {
  guest: false
}

class PrintHTMLContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
    this.potentialTabs = updatePotentialTabs(props.guidebook)
  }

  _formatMessage = this.props.intl.formatMessage

  _messages = defineMessages({
    all: {
      id: 'gb.printAll',
      defaultMessage: 'Recommendations'
    }
  })

  getStyles = (color) => {
    //Dynamic styles set via ConfigureMenu
    const styles = reactCSS({
      default: {
        textColor: {
          color: color
        }
      }
    })
    return styles
  }

  qrcodeRefs = {}

  renderCoverPage = (cover) => {
    switch (cover) {
      case 2:
        return (
          <CoverPage2
            guidebook={this.props.guidebook}
            theme={this.props.guidebook.theme}
            printPreferences={this.props.printPreferences}
            isLoading={this.props.isLoading}
            intl={this.props.intl}
            dispatch={this.props.dispatch}
            styles={this.getStyles(this.props.printPreferences.color)}
          />
        )
      case 3:
        return (
          <CoverPage3
            guidebook={this.props.guidebook}
            theme={this.props.guidebook.theme}
            printPreferences={this.props.printPreferences}
            isLoading={this.props.isLoading}
            intl={this.props.intl}
            dispatch={this.props.dispatch}
            styles={this.getStyles(this.props.printPreferences.color)}
          />
        )
      case 4:
        return (
          <CoverPage4
            guidebook={this.props.guidebook}
            theme={this.props.guidebook.theme}
            color={this.props.printPreferences.color}
            printPreferences={this.props.printPreferences}
            isLoading={this.props.isLoading}
            intl={this.props.intl}
            dispatch={this.props.dispatch}
            styles={this.getStyles(this.props.printPreferences.color)}
          />
        )
      default:
        return (
          <CoverPage
            guidebook={this.props.guidebook}
            theme={this.props.guidebook.theme}
            printPreferences={this.props.printPreferences}
            isLoading={this.props.isLoading}
            intl={this.props.intl}
            dispatch={this.props.dispatch}
            styles={this.getStyles(this.props.printPreferences.color)}
          />
        )
    }
  }

  renderRecommendations = (selectedSections) => {
    //Of the selected recommendation categories, which have data on this guidebook?
    const allRecommendations = this.props.guidebook.recommendationsByCategory
    const showFullRecs =
      this.props.printPreferences.settings.fullRecommendations &&
      this.props.printPreferences.settings.fullRecommendations.selected
    const shortenRecLinks =
      this.props.printPreferences.settings.shortenRecommendationLinks &&
      this.props.printPreferences.settings.shortenRecommendationLinks.selected
    const showQR =
      this.props.printPreferences.settings.showQRCode &&
      this.props.printPreferences.settings.showQRCode.selected
    let recommendationsToRender = []
    if (Object.keys(allRecommendations).length) {
      if (this.props.printPreferences.settings.combineMaps.selected) {
        const allRecs = allRecommendations.all.recommendations.filter((rec) => {
          const recCatName = rec.category.name.toLowerCase().replace(/ /g, '_')
          // console.log(recCatName);
          // console.log(selectedSections);
          if (typeof selectedSections[recCatName] === 'undefined') return false
          return selectedSections[recCatName] === true
        })
        recommendationsToRender.push(
          <RecommendationSection
            key="recs-all"
            categoryName="all"
            label={this._formatMessage(this._messages['all'])}
            recommendations={allRecs}
            guidebook={this.props.guidebook}
            color={this.props.printPreferences.color}
            intl={this.props.intl}
            styles={this.getStyles(this.props.printPreferences.color)}
            fullRecommendations={showFullRecs}
            shortenRecommendationLinks={shortenRecLinks}
            showQR={showQR}
          />
        )
      } else {
        for (var section in selectedSections) {
          if (allRecommendations[section] && selectedSections[section]) {
            const sectionCat = allRecommendations[section].category
            const sectionRecs = allRecommendations[section].recommendations
            recommendationsToRender.push(
              <RecommendationSection
                key={'recs-' + section}
                categoryName={section}
                label={translateString(
                  this.props.intl.locale,
                  sectionCat.name,
                  sectionCat.name_txn
                )}
                recommendations={sectionRecs}
                guidebook={this.props.guidebook}
                color={this.props.printPreferences.color}
                styles={this.getStyles(this.props.printPreferences.color)}
                intl={this.props.intl}
                fullRecommendations={showFullRecs}
                shortenRecommendationLinks={shortenRecLinks}
                showQR={showQR}
              />
            )
          }
        }
      }
    }

    return recommendationsToRender
  }

  renderHouseManualTabs = (selectedSections) => {
    //Of the selected recommendation categories, which have data on this guidebook?
    const { guidebook } = this.props
    const allInformations = guidebook.informationsByCategory
    let houseManualsToRender = []
    const showQR =
      this.props.printPreferences.settings.showQRCode &&
      this.props.printPreferences.settings.showQRCode.selected
    if (Object.keys(allInformations).length) {
      for (var section in selectedSections) {
        if (
          allInformations[section] &&
          selectedSections[section] &&
          section !== 'house_manual'
        ) {
          const sectionCat = allInformations[section].category
          const sectionCards = allInformations[section].informations
          const token_data =
            guidebook.token_data && guidebook.token_data.data
              ? guidebook.token_data.data
              : {}
          const gbData = { key: guidebook.key }
          // console.log(token_data);
          houseManualsToRender.push(
            <HouseManual
              key={'house-manual-' + section}
              sectionTitle={translateString(
                this.props.intl.locale,
                sectionCat.name,
                sectionCat.name_txn
              )}
              informations={sectionCards}
              guidebook={gbData}
              styles={this.getStyles(this.props.printPreferences.color)}
              token={token_data}
              showQR={showQR}
            />
          )
        }
      }
    }

    return houseManualsToRender
  }

  downloadQR = (whichRef) => {
    this.qrcodeRefs[whichRef].download(`QR_${whichRef}.png`)
  }

  render() {
    const { guidebook, intl } = this.props
    //Which sections should be printed?
    let selectedSections = []
    const availableTabs = getAvailableTabs(this.potentialTabs, false)
    if (this.props.guest) {
      selectedSections = this.props.printPreferences.guestSections
        .sort((a, b) => {
          return availableTabs.indexOf(a.name) - availableTabs.indexOf(b.name)
        })
        .reduce(function (map, obj) {
          map[obj.name] = obj.selected
          return map
        }, {})
    } else {
      selectedSections = this.props.printPreferences.sections
        .sort((a, b) => {
          return availableTabs.indexOf(a.name) - availableTabs.indexOf(b.name)
        })
        .reduce(function (map, obj) {
          map[obj.name] = obj.selected
          return map
        }, {})
    }

    //dynamic font
    const id = this.props.printPreferences.font
      ? 'hf-print-' + this.props.printPreferences.font
      : 'hf-print'

    let disableLinkRefs = ''
    if (
      this.props.printPreferences &&
      this.props.printPreferences.settings &&
      this.props.printPreferences.settings.printLinkHrefs
    ) {
      disableLinkRefs = this.props.printPreferences.settings.printLinkHrefs
        .selected
        ? ''
        : 'disable-auto-link'
    }
    const paperStyle = { position: 'relative' }
    const showQR =
      this.props.printPreferences.settings.showQRCode &&
      this.props.printPreferences.settings.showQRCode.selected
    let includeMargin = true
    let imgProps = {
      src: '/android-chrome-192x192.png',
      top: 80,
      left: 80,
      width: 40,
      height: 40
    }
    imgProps = null
    includeMargin = false

    const gbQRref = stringToId(this.props.guidebook.name)
    const domain =
      this.props.guidebook.domain &&
      this.props.guidebook.domain.status === 'approved'
        ? this.props.guidebook.domain.domain
        : null
    const qrNode = showQR ? (
      <div
        className="hf-qr-code"
        onClick={() => {
          this.downloadQR(gbQRref)
        }}
      >
        <QRCode
          renderAs="svg"
          size={96}
          level="H"
          fgColor={this.props.printPreferences.color}
          ref={(ref) => (this.qrcodeRefs[gbQRref] = ref)}
          value={buildGuidebookUrl(
            this.props.guidebook.key,
            null,
            null,
            true,
            domain,
            this.props.guidebook.is_published
          )}
          includeMargin={includeMargin}
          img={imgProps}
        />
      </div>
    ) : null

    const defaultInformations = this.props.guidebook.informations.filter(
      (info) => {
        return info.category === null
      }
    )

    let houseManualLabel = this._formatMessage(labelMessages.house_manual)

    if (guidebook.theme && guidebook.theme.home_label) {
      houseManualLabel =
        guidebook.theme.home_label_txn[intl.locale] ||
        guidebook.theme.home_label
    }
    const token_data =
      guidebook.token_data && guidebook.token_data.data
        ? guidebook.token_data.data
        : {}
    return (
      <div>
        <Paper id={id} className={this.props.className} style={paperStyle}>
          {qrNode}
          <div className={disableLinkRefs}>
            {/* Map and Host Information */}
            {this.renderCoverPage(this.props.printPreferences.cover)}

            {selectedSections.welcome ? (
              <HostIntro
                styles={this.getStyles(this.props.printPreferences.color)}
                host_intro={this.props.guidebook.host_intro}
                token={token_data}
              />
            ) : null}

            {selectedSections.arrival ? (
              <Arrival
                styles={this.getStyles(this.props.printPreferences.color)}
                guidebook={this.props.guidebook}
                showQR={showQR}
              />
            ) : null}

            {selectedSections.departure ? (
              <Departure
                styles={this.getStyles(this.props.printPreferences.color)}
                guidebook={this.props.guidebook}
                showQR={showQR}
              />
            ) : null}

            {selectedSections.house_manual ? (
              <HouseManual
                sectionTitle={houseManualLabel}
                informations={defaultInformations}
                guidebook={guidebook}
                styles={this.getStyles(this.props.printPreferences.color)}
                token={token_data}
                showQR={showQR}
              />
            ) : null}

            {this.renderHouseManualTabs(selectedSections)}

            {this.renderRecommendations(selectedSections)}
          </div>
        </Paper>
      </div>
    )
  }
}

PrintHTMLContainer.propTypes = propTypes
PrintHTMLContainer.defaultProp = defaultProp

export default PrintHTMLContainer
