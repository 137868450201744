import { getUser } from './Auth'

const currentUser = function () {
  const guestUser = {
    roles: []
  }
  const guestMembership = {
    name: 'guest'
  }
  const user = getUser() || guestUser

  // translate the array of memberships to a single membership for simplicity. Later we can support multiple memberships if needed
  const membership =
    user.memberships && user.memberships.length > 0
      ? user.memberships[0]
      : guestMembership
  const isPro = membership && membership.name === 'pro'
  const isEnterprise = membership && membership.name === 'enterprise'

  const userObject = {
    user: user,
    id: user.id,
    displayName: user.display_name,
    isAdmin: user.roles.indexOf('admin') > -1,
    isPro: isPro,
    isEnterprise: isEnterprise,
    isPaidHost: isPro || isEnterprise,
    switchedTo: user.switched,
    switchedFrom: user.switched_from_email,
    safelyUser: user.safely_user,
    canCopy: membership.can_copy,
    canTranslate: membership.can_translate,
    canCustomizeUrls: membership.can_change_url,
    canCreateCategory: membership.can_create_category,
    canAccessApi: membership.can_access_api,
    canTheme: membership.can_theme,
    canUpsell: membership.can_upsell,
    canUseSecurity: membership.can_use_security,
    canUseDomain:
      user.roles.indexOf('admin') > -1 ||
      isEnterprise ||
      membership.can_use_domain,
    canGeocode: membership.can_geocode,
    guidebookLimit: membership.guidebooks,
    membership: membership
  }
  return userObject
}

export default currentUser
