import React from 'react'

const CardTravel = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`card_travel`}</title>
    <path d="M20.016 14.016v-6h-3v1.969H15V8.016H9v1.969H6.984V8.016h-3v6h16.031zm0 4.968v-1.969H3.985v1.969h16.031zM9 3.984V6h6V3.984H9zM20.016 6c1.125 0 1.969.891 1.969 2.016v10.969c0 1.125-.844 2.016-1.969 2.016H3.985c-1.125 0-1.969-.891-1.969-2.016V8.016C2.016 6.891 2.86 6 3.985 6h3V3.984c0-1.125.891-1.969 2.016-1.969h6c1.125 0 2.016.844 2.016 1.969V6h3z" />
  </svg>
)

export default CardTravel
