import React from 'react'
import Marker from 'components/Map/Marker'
import { resizeImage } from 'utils/Image'
import { customColor } from 'utils/Color'

export default function GuidebookMarker(
  guidebook,
  size,
  showPopup,
  fillColor,
  clickHandler,
  theme
) {
  const rpos = {
    lat: guidebook.address.lat,
    lng: guidebook.address.lng
  }
  const clickHandlerFn = clickHandler
    ? (gb) => {
        clickHandler(gb)
      }
    : null

  let markerColor = fillColor || '#F44336'
  markerColor = customColor('arrival', theme, markerColor)

  const image = resizeImage(guidebook.image, size, size, true)
  const marker = (
    <Marker
      key={'gbmarker-' + guidebook.id}
      id={'g_' + guidebook.id}
      name={guidebook.name}
      imageUrl={image}
      clickable={true}
      fillColor={markerColor}
      onClick={clickHandlerFn}
      draggable={false}
      height={size}
      width={size}
      size={size}
      position={rpos}
      type="guidebook"
    />
  )

  return marker
}
