import React from 'react'

const PlaylistAddCheck = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`playlist_add_check`}</title>
    <path d="M21.516 11.484l1.5 1.5-6.984 7.031-4.547-4.5 1.5-1.5 3.047 3zm-19.5 4.5v-1.969h7.969v1.969H2.016zm12-9.984v2.016h-12V6h12zm0 3.984V12h-12V9.984h12z" />
  </svg>
)

export default PlaylistAddCheck
