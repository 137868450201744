import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { FontIcon, injectTooltip } from 'react-md'

const TooltipFontIcon = injectTooltip(
  ({
    children,
    iconClassName,
    className,
    color,
    tooltip,
    disabled,
    ...props
  }) => (
    <div {...props} className={classnames(className, 'inline-rel-container')}>
      {tooltip}
      <FontIcon className="hf-icon-color" disabled={disabled}>
        {children}
      </FontIcon>
    </div>
  )
)

TooltipFontIcon.propTypes = {
  children: PropTypes.node,
  iconClassName: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.node,
  color: PropTypes.string
}

export default TooltipFontIcon
