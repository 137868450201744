import React, { Component } from 'react'
import { Button, Paper, Toolbar } from 'react-md'
import { connect } from 'react-redux'

//todo meli - only show paid content ot paid hosts and hook up function to create cards
class ReferralProgram extends Component {
  showPartnerStack = () => {
    window.open('https://hostfully.partnerstack.com/?group=customers', '_blank')
  }

  render() {
    const liStyle = { listStyle: 'disc' }
    const stepStyle = { maxWidth: '200px' }

    return (
      <div className="hf-main-container">
        <Toolbar
          colored
          title="Customer Referral Program"
          className="hf-host-toolbar"
        />
        <Paper className="hf-form-wrapper">
          <h1 className="md-headline">Earn rewards when you share Hostfully</h1>
          <p className="md-subheading-1">
            Our referral program helps our valued clients recommend Hostfully to
            other short-term rental operators in a simple and transparent way.
            Earn a 15% share of the subscription for 12 months for each friend
            you refer to Hostfully. Here’s how to get started:
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              columnGap: '16px',
              margin: '48px 0px 36px 0px'
            }}
          >
            <div className="md-text-center" style={stepStyle}>
              <img
                src="https://storage.googleapis.com/hostfully-wp-1/step1.png"
                alt=""
              />
              <div className="md-subheading-1">
                <b>Step 1:</b>
                <br /> Create a PartnerStack referral account
              </div>
            </div>
            <div className="md-text-center" style={stepStyle}>
              <img
                src="https://storage.googleapis.com/hostfully-wp-1/step2.png"
                alt=""
              />
              <br />
              <div className="md-subheading-1">
                <b>Step 2:</b>
                <br /> Copy your unique referral link
              </div>
            </div>
            <div className="md-text-center" style={stepStyle}>
              <img
                src="https://storage.googleapis.com/hostfully-wp-1/step3.png"
                alt=""
              />
              <br />
              <div className="md-subheading-1">
                <b>Step 3:</b>
                <br /> Share your link & earn rewards
              </div>
            </div>
          </div>
          <div className="md-text-center" style={{ marginBottom: '36px' }}>
            <Button onClick={this.showPartnerStack} primary raised>
              Create Your Referral Account
            </Button>
          </div>
          <div className="md-subheading-1">
            <ol>
              <li style={liStyle}>
                Rewards are sent after a referral has been a paying customer for
                three months.
              </li>
              <li style={liStyle}>
                See the full terms and conditions of the referral program in
                your PartnerStack account.
              </li>
              <li style={liStyle}>
                Participating Hostfully users will need a PayPal or Stripe
                account to collect rewards.
              </li>
            </ol>
          </div>
        </Paper>
      </div>
    )
  }
}

ReferralProgram.propTypes = {}

export default connect()(ReferralProgram)
