import React from 'react'

const Iron = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`iron`}</title>
    <path d="M21.8 10.7c.5-.7 1-1.3.8-2.2-.1-.6-.5-1.2-1-1.5-.5-.3-1-.6-1.6-.7-1.7-.3-3.5-.3-5.2-.1s-3.6.1-5.3-.2-2.3-.8-3-1.5L6 3.8l-.3-.3v.3c0 .3.3.8.7 1S8.2 6 9.5 6.3s3.7.3 5.5.2 3.3-.2 5 .2l1.5.5c.4.3.7.7.8 1.2 0 .7-.3 1.3-.8 1.8l-2.7 3h-5.7v-.5H9.8v.5H8.3l.7-1.7h8.2c.3 0 .5-.2.5-.5 0-.4-.3-.7-.7-.7H9.7l-.4-.3h-.8l-.3.3h-.4c-.4 0-.7.2-.8.5L3.3 17v.2c0 .3.2.5.5.5H4l-2.2 1h16.7c.3 0 .5-.2.5-.5v-.3l1.7-.7h.2c-.2-.5-1-2.2-1.5-3.3l2.8-3-.4-.2z" />
  </svg>
)

export default Iron
