import React from 'react'

import NavDrawer from 'components/NavDrawer'

import BubblesImage from './assets/bg-bubbles.svg'
import WavesImage from './assets/bg-waves.svg'
import WomanImage from './assets/bg-woman.png'

export function PublicView({ children, containerClass, showNavDrawer }) {
  return (
    <NavDrawer showNavDrawer={showNavDrawer}>
      <div className={`hf-public-view ${containerClass}`}>
        <div className="hf-public-view-background-1" />
        <img
          id="bgBubbles"
          src={BubblesImage}
          alt="background bubbles"
          className="hf-public-view-background-2"
        />
        <img
          id="bgWaves"
          src={WavesImage}
          alt="background waves"
          className="hf-public-view-background-2"
        />
        <img
          src={WomanImage}
          alt="woman looking at a smartphone"
          className="hf-public-view-woman"
        />
        {children}
      </div>
    </NavDrawer>
  )
}
