import React from 'react'

const Fireplace = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`fireplace`}</title>
    <path d="M11.8 17.3c1.2 0 2.2-1 2.2-2.2v-.3c0-.5-.1-1-.2-1.5-.5.7-1.3 1-2 1.2s-1.2.7-1.2 1.3c0 .7.6 1.3 1.3 1.3h.2l-.3.2zm.9-8c1.7 1.4 2.8 3.5 2.8 5.8 0 1.9-1.6 3.5-3.5 3.5S8.5 17 8.5 15.1c0-1.5.6-2.8 1.5-3.8 0 .9.7 1.7 1.7 1.7.8 0 1.5-.7 1.5-1.7 0-.8-.1-1.5-.3-2.2v.1l-.2.1z" />
    <path d="M1.3 2v19.7h4V12a6.7 6.7 0 0113.4 0v9.7h4V2H1.3z" />
    <path d="M8.8 19.7l6.1-1.5c.3-.1.5.1.6.4v.2c.1.3-.1.5-.4.6L9 20.9c-.3.1-.5-.1-.6-.4v-.2c0-.3.1-.6.4-.6z" />
    <path d="M14.2 20.7l-6.1-1.5c-.3-.1-.4-.3-.4-.6v-.2c.1-.3.3-.4.6-.4l6.1 1.5c.3.1.4.3.4.6v.2c0 .3-.3.5-.6.4z" />
  </svg>
)

export default Fireplace
