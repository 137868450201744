import React from 'react'

const LocalLibrary = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`local_library`}</title>
    <path d="M12 8.016c-1.641 0-3-1.359-3-3s1.359-3 3-3 3 1.359 3 3-1.359 3-3 3zm0 3.515c2.344-2.203 5.531-3.516 9-3.516v10.969a13.136 13.136 0 00-9 3.563 13.141 13.141 0 00-9-3.563V8.015c3.469 0 6.656 1.313 9 3.516z" />
  </svg>
)

export default LocalLibrary
