import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Breakjs from 'breakjs'
import { connect } from 'react-redux'
import { replace } from 'react-router-redux'
import { actions } from 'react-redux-form'
import { checkStatus, generateRecs } from 'redux/modules/recsWizard'
import {
  Button,
  CardActions,
  CircularProgress,
  DialogContainer,
  FontIcon,
  Tabs,
  Tab,
  TabsContainer
} from 'react-md'
import ManageSelectedCards from '../ManageSelectedCards'
import ManageRecommendationsSelectedTab from './ManageRecommendationsSelectedTab'
// import {trackEvent, getHostIdAndKey} from 'utils/Segment';

const breakLayout = Breakjs({
  mobile: 0,
  phablet: 550,
  desktop: 1024,
  superwide: 1500
})

class ManageRecommendations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabIndex: 1,
      filter: '',
      recWizardPrompt: this.getDefaultWizardPrompt(),
      recsLoading: this.getDefaultWizardLoading(),
      recsLoaded: 0,
      countdownId: null,
      statusCountdown: 10 * 60 // 5 minutes
    }
    this.statusInterval = null
    this.onTabChange = this.onTabChange.bind(this)
    this.tabContentChanged = this.tabContentChanged.bind(this)
  }

  getDefaultWizardPrompt = () => {
    // console.log(this.props.defaultOpen);
    if (this.props.defaultOpen) {
      return true
    }
    return false
  }

  getDefaultWizardLoading = () => {
    if (this.props.defaultOpen) {
      return true
    }
    return false
  }

  componentWillReceiveProps(nextProps) {
    const { selected_recommendations } = this.props
    //if selected items have loaded setState, otherwise this is the user changes to the model (ie activeTabIndex is 0)
    if (
      selected_recommendations &&
      nextProps.selected_recommendations &&
      selected_recommendations !== nextProps.selected_recommendations &&
      this.state.activeTabIndex === 1
    ) {
      let initialTabIndex =
        nextProps.selected_recommendations.length > 0 ? 1 : 0
      this.setState({
        activeTabIndex: initialTabIndex
      })
    }
  }

  componentDidMount() {
    if (this.props.defaultOpen) {
      this.generateRecommendations()
    }
  }

  createCard(type) {
    const { dispatch, item_id, pluralName, singularName } = this.props
    // if no id set, they must be creating a guidebook
    const id = item_id ? item_id : 'create'
    // BA. bit of a hack for when they edit a card and return with a hash in the URL, then refresh the whole browser
    dispatch(actions.change('edit_' + singularName + '.noReload', true))
    dispatch(replace(`/host/${pluralName}/${id}/${type}/create`))
  }

  onTabChange = (newTabIndex, event) => {
    this.setState({
      activeTabIndex: newTabIndex
    })
  }

  /*
   BA a bit of a hack to workaround an issue in react-md tabs. Let the tab notify the parent that it's content has changed.
   This function just sets a new state to force a recalc of heights etc
   */
  tabContentChanged = () => {
    this.setState({
      tabContentChanged: new Date()
    })
  }

  hasUsedWizard = () => {
    const { item_id, recommendations } = this.props
    if (recommendations.data.length) {
      for (var i = 0, len = recommendations.data.length; i < len; i++) {
        const rec = recommendations.data[i]
        if (rec.batch_ref === `wizard_${item_id}`) return true
      }
    }
    return false
  }

  openWizard = () => {
    const self = this
    // OLD SEGMENT CODE
    // let eventData = getHostIdAndKey(this.props.edit_item);
    // eventData.category = 'Host';
    // trackEvent('openRecWizard', eventData, {}, function(){
    self.setState({ recWizardPrompt: true })
    // });
  }

  closeWizard = () => {
    this.setState({ recWizardPrompt: false })
  }

  refreshRecs = () => {
    this.setState({ recWizardPrompt: false, recsLoading: false, recsLoaded: 0 })
  }

  generateRecommendations = () => {
    const self = this
    // run onWizardClick callback
    this.props.onWizardClick()
    // set interval timer to check status every 5 seconds
    const intervalId = setInterval(this.timer, 10000)
    this.setState({ recsLoading: true, statusInterval: intervalId }, () => {
      const guidebook_id = parseInt(self.props.item_id, 10)
      // OLD SEGMENT CODE
      // let eventData = getHostIdAndKey(self.props.edit_item);
      // eventData.category = 'Host';
      // trackEvent('generateRecommendations', eventData, {}, function(){
      generateRecs(guidebook_id).then((response) => {
        let rec_count = 0
        if (response.data.length) {
          rec_count = response.data.length
        }
        self.setState({ recsLoading: false, recsLoaded: rec_count })
      })
      // });
    })
  }

  refreshPage = () => {
    const refreshUrl = `/host/guidebooks/${this.props.item_id}#recommendations#recommendation`
    window.location.href = refreshUrl
    window.location.reload()
  }

  timer = () => {
    const self = this
    const newCount = this.state.statusCountdown - 5
    if (newCount >= 0) {
      const guidebook_id = parseInt(self.props.item_id, 10)
      this.setState({ statusCountdown: newCount }, () => {
        checkStatus(guidebook_id).then((response) => {
          if (response.data && response.data.status === 'Complete') {
            const recs_generated = response.data.data.recs_generated
            clearInterval(this.state.intervalId)
            self.setState({
              statusInterval: null,
              statusCountdown: 10 * 60,
              recsLoading: false,
              recsLoaded: recs_generated
            })
          }
        })
      })
    } else {
      clearInterval(this.state.intervalId)
      // TODO something to say  "hey,  maybe wait longer"
      console.log('time is up')
      this.setState({ statusInterval: null })
    }
  }

  renderRecWizardContent = () => {
    if (this.state.recsLoaded) {
      const iconStyle = {
        fontSize: '14px',
        lineHeight: '20px',
        verticalAlign: 'bottom'
      }
      return (
        <div className="md-grid">
          <div className="md-cell md-cell--12">
            Congratulations! {this.state.recsLoaded} recommendations have been
            added to your guidebook.
            <br />
            <br />
            By clicking close below, you will refresh your browser window and be
            able to view your fresh recommendations.
            <br />
            <br />
            <p>
              <FontIcon style={iconStyle}>info</FontIcon> Protip: To easily find
              the recommendations that the Wizard created, type "wizard" into
              the filter box.
            </p>
          </div>
        </div>
      )
    } else if (!this.state.recsLoading) {
      return (
        <div className="md-grid">
          <div className="md-cell md-cell--12">
            Welcome to the Hostfully Recommendations Wizard! We've analyzed tens
            of thousands of guidebooks to understand what kind of
            recommendations work best. <br />
            <br />
            When you use the Recommendations Wizard, you'll receive a set of
            recommendations tailored to the specific location of your guidebook.
            <br />
            <br />
            The Wizard will give you recommendations across the most commonly
            used categories: Places to Eat, Bars, Shopping, Attractions, Grocery
            Stores, and Activities.
            <br />
            <br />
            Please note that our Wizard likes to be thoughtful - it can take up
            to 5 minutes to generate recommendations for your guidebook.
          </div>
        </div>
      )
    } else {
      return (
        <div className="md-grid">
          <div className="md-cell md-cell--12">
            Your recommendations are being generated.
            <br />
            <br />
            Please be patient, this may take up to 5 minutes.
          </div>
          <div className="md-cell md-cell--12">
            <CircularProgress id="progressWizard" />
          </div>
        </div>
      )
    }
  }

  render() {
    const { pluralName, singularName } = this.props
    // console.log(this.state);
    let wizardButton = null
    if (this.hasUsedWizard()) {
      wizardButton = (
        <Button
          disabled
          flat
          primary
          tooltipLabel="Wizard already used"
          tooltipPosition="top"
        >
          Wizard already used
        </Button>
      )
    } else if (this.props.itemId !== 'create') {
      wizardButton = (
        <Button
          onClick={(e) => {
            this.openWizard()
          }}
          raised
          secondary
          iconChildren="flare"
        >
          Recommendations Wizard
        </Button>
      )
    }

    const layout = breakLayout.current()
    return (
      <div id="recommendation">
        <TabsContainer
          className="hf-editable-card-tabs"
          onTabChange={this.onTabChange}
          activeTabIndex={this.state.activeTabIndex}
        >
          <Tabs tabId="tab">
            <Tab key="select" label="Select/Edit">
              <ManageSelectedCards
                ownerPluralName={pluralName}
                ownerSingularName={singularName}
                pluralName="recommendations"
                singularName="recommendation"
                active={this.state.activeTabIndex === 0}
                onTabContentChanged={this.tabContentChanged}
                showHeading={false}
              />
            </Tab>
            <Tab key="order" label="Sort">
              <ManageRecommendationsSelectedTab
                ownerPluralName={pluralName}
                ownerSingularName={singularName}
                active={this.state.activeTabIndex === 1}
                {...this.props}
              />
            </Tab>
          </Tabs>
        </TabsContainer>
        <CardActions>
          <Button
            onClick={(e) => {
              this.createCard('recommendations')
            }}
            flat
            primary
          >
            Create Recommendation Card
          </Button>
          {wizardButton}
        </CardActions>
        <DialogContainer
          id="recommendationWizard"
          title="Recommendations Wizard"
          visible={this.state.recWizardPrompt}
          width={layout === 'mobile' ? '90vw' : 500}
          onHide={this.closeWizard}
          focusOnMount={false}
          closeOnEsc={false}
          modal={true}
          actions={
            this.state.recsLoaded
              ? [
                  {
                    onClick: this.refreshPage,
                    secondary: true,
                    raised: true,
                    children: 'Close'
                  }
                ]
              : [
                  {
                    onClick: this.closeWizard,
                    disabled: this.state.recsLoading,
                    children: 'Cancel'
                  },
                  {
                    onClick: this.generateRecommendations,
                    secondary: true,
                    disabled: this.state.recsLoading,
                    children: 'Generate Recommendations'
                  }
                ]
          }
        >
          {this.renderRecWizardContent()}
        </DialogContainer>
      </div>
    )
  }
}

ManageRecommendations.propTypes = {
  defaultOpen: PropTypes.bool,
  singularName: PropTypes.string.isRequired,
  onWizardClick: PropTypes.func
}

ManageRecommendations.defaultProps = {
  defaultOpen: false,
  onWizardClick: () => {}
}

function mapStateToProps(state, props) {
  const { singularName } = props
  const edit_item = state['edit_' + singularName]
  const selected_recommendations = edit_item.recommendations || []
  return {
    item_id: edit_item.id,
    selected_recommendations: selected_recommendations
  }
}

export default connect(mapStateToProps)(ManageRecommendations)
