import React from 'react'

const RollerCoaster = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`roller_coaster`}</title>
    <path d="M9.5 2C5.398 2 2 5.398 2 9.5S5.398 17 9.5 17c3.7 0 6.188-1.21 6.188-1.313L22 13v-2.188l-7.188 3.094S12.602 15 9.5 15C6.5 15 4 12.5 4 9.5S6.5 4 9.5 4c1.352 0 2.621.512 3.656 1.375l1.281-1.531C13.075 2.707 11.352 2 9.5 2zM21 5c-.55 0-1 .45-1 1 0 .363.207.668.5.844l-1.094.437a.949.949 0 00.094-.375c0-.55-.45-1-1-1s-1 .45-1 1c0 .414.266.754.625.907l-.813.375.875 2.218c.102.301.512.383.813.281l3-1.28v-3.22l-.063.032c.02-.074.063-.137.063-.219 0-.55-.45-1-1-1zm-6.313 2.5c-.55 0-1 .45-1 1 0 .46.329.82.75.938l-1.187.53c.078-.144.156-.292.156-.468 0-.55-.449-1-1-1-.55 0-1 .45-1 1 0 .469.317.828.75.938L12 10.5c-1 .398-1.398 1.602-1 2.5l.094.313c.199.398.601.574 1 .374l4.812-2.093c.301-.102.383-.489.282-.688l-.782-2.312-.843.375a.955.955 0 00.124-.469c0-.55-.449-1-1-1zM2 13.406V22h2v-6.094c-.8-.699-1.5-1.5-2-2.5zm20 .688l-2 .812V22h2zm-9 3.5c-.602.199-1.3.312-2 .312V22h2z" />
  </svg>
)

export default RollerCoaster
