import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ListingName extends Component {
  render() {
    const listingName = this.props.guidebook.name

    return (
      <div>
        <div className="hf-print-title-wrapper">
          <h2
            className="hf-print-gb-title"
            style={this.props.styles ? this.props.styles.textColor : null}
          >
            {listingName}
          </h2>
        </div>
        <div className="hf-print-footer"></div>
      </div>
    )
  }
}

ListingName.propTypes = {
  guidebook: PropTypes.object,
  styles: PropTypes.object
}

export default ListingName
