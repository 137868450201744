import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { fetchItems } from 'redux/modules/crud'
import RentalList from './RentalList'
import {
  Button,
  Card,
  CardTitle,
  CardText,
  DialogContainer,
  FontIcon,
  List,
  Toolbar
} from 'react-md'
import TooltipButton from 'components/TooltipButton'

class BookingSyncContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      helpVisible: false
    }
    this.showHelp = this.showHelp.bind(this)
    this.closeHelp = this.closeHelp.bind(this)
    this.upgrade = this.upgrade.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchItems('guidebooks'))
    dispatch(fetchItems('bookingsyncrentals'))
  }

  upgrade = () => {
    window.location.href =
      process.env.REACT_APP_WORDPRESS_URL + '/pricing#prime'
  }

  showHelp = () => {
    this.setState({ helpVisible: true })
  }

  closeHelp = () => {
    this.setState({ helpVisible: false })
  }

  render() {
    const { guidebooks, rentals } = this.props

    const actions = []
    actions.push(
      <TooltipButton
        key="help"
        onClick={this.showHelp}
        tooltipLabel="Help"
        tooltipPosition="bottom"
        flat
      >
        <FontIcon>help</FontIcon>
      </TooltipButton>
    )
    actions.push(
      <TooltipButton
        key="upgrade"
        onClick={this.upgrade}
        tooltipLabel="Upgrade"
        tooltipPosition="bottom"
        className="hf-bs-upgrade"
        flat
      >
        <p>Upgrade</p>
      </TooltipButton>
    )
    return (
      <div className="hf-main-container">
        <Toolbar
          colored
          prominent
          className={'hf-host-toolbar hf-bookingsyncrentals'}
          title={'BookingSync Imports'}
          actions={actions}
        />
        <div className={'md-grid hf-host-paper hf-bookingsyncrentals-paper'}>
          <div className="md-cell md-cell--2 md-cell--tablet-hidden md-cell--phone-hidden"></div>
          <div className="md-cell md-cell--8 hf-paper">
            <List>
              <RentalList
                guidebooks={guidebooks}
                rentals={rentals}
                {...this.props}
              />
            </List>
          </div>
          <div className="md-cell md-cell--2 md-cell--tablet-hidden md-cell--phone-hidden"></div>
        </div>
        <DialogContainer
          id="bs_help"
          fullPage
          portal={true}
          lastChild={true}
          renderNode={document.body}
          disableScrollLocking={true}
          visible={this.state.helpVisible}
          aria-label="BookingSync Imports Help"
          onHide={this.closeHelp}
          className="hf-guest-modal"
        >
          <Toolbar
            fixed
            actions={[
              <Button icon onClick={this.closeHelp}>
                close
              </Button>
            ]}
          />
          <div className="md-toolbar-relative list-item-modal-content">
            <Card>
              <CardTitle title="BookingSync Imports Help">
                <Button
                  className="md-cell--right"
                  icon
                  onClick={this.closeHelp}
                >
                  close
                </Button>
              </CardTitle>
              <CardText>
                <h2>BookingSync Integration</h2>
                <p>
                  This list of your BookingSync properties has been imported
                  from your BookingSync account.
                </p>
                <p>Use it to import the property to a Hostfully guidebook.</p>
                <p>
                  When you change the information about a property in
                  BookingSync you need to re-import it here to update the
                  related guidebook.
                </p>
                <p>&nbsp;</p>
                <h2>Getting Started with Hostfully</h2>
                <p>
                  Hostfully offers all users one free guidebook, so as a
                  BookingSync user you can get started by selecting one of your
                  properties to import.
                </p>
                <p>
                  Once imported you can see your guidebook for this property and
                  add content to it and share it with guests if you like.
                </p>
                <p>
                  You need to upgrade to a paid plan to create more than one
                  guidebook.
                </p>
                <p>
                  We offer a variety of plans to suit individuals and businesses
                  and you can compare them before selecting the one that suits
                  you when you upgrade.
                </p>
              </CardText>
            </Card>
          </div>
        </DialogContainer>
      </div>
    )
  }
}

BookingSyncContainer.propTypes = {
  dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state, props) {
  const listData = state['list']
  const rentals = listData['bookingsyncrentals']

  return {
    rentals: rentals,
    guidebooks: listData['guidebooks']
  }
}

export default connect(mapStateToProps)(injectIntl(BookingSyncContainer))
