import React from 'react'

const Anchor = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`anchor`}</title>
    <path d="M22.8 16l-2.7-3.8-2.6 3.8H19c0 2.5-2.7 4.7-6.3 5v-6.2H14v-1.3h-1.3v-1.3H14v-1.3h-1.3V7.5C14 7.1 15 6 15 4.5c0-1.7-1.3-3-3-3s-3 1.3-3 3c0 1.4 1 2.6 2.3 2.9v3.3h-1.2V12h1.3v1.3h-1.3v1.3h1.3V21c-3.7-.2-6.7-2.3-6.7-5h1.7l-2.7-3.8L1.2 16h1.3c0 3.7 4.2 6.5 9.5 6.5s9.5-3 9.5-6.5h1.3zM10.2 4.5c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2z" />
  </svg>
)

export default Anchor
