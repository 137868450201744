import React from 'react'
import Marker from 'components/Map/Marker'

export default function AddressMarker(
  id,
  address,
  image,
  size,
  fillColor,
  moveHandler
) {
  const rpos = {
    lat: address.lat,
    lng: address.lng
  }

  const moveHandlerFn = moveHandler
    ? (location) => {
        moveHandler(location)
      }
    : null

  const markerColor = fillColor || '#03A9F4'
  const imageUrl = image || '/mstile-144x144.png'

  const marker = (
    <Marker
      key={id}
      id={'a_' + id}
      name={id}
      imageUrl={imageUrl}
      draggable={true}
      fillColor={markerColor}
      onMove={moveHandlerFn}
      height={size}
      width={size}
      size={size}
      position={rpos}
    />
  )

  return marker
}
