import React from 'react'
import { Card, CardText, CardTitle, FontIcon } from 'react-md'

import imagePlaceholder from 'assets/marketplace-placeholder.jpeg'
import TooltipButton from 'components/TooltipButton'
import { stripTags } from 'utils/Strings'

const MarketplaceListItem = ({
  title,
  subtitle,
  imageSrc,
  description,
  type,
  editItem,
  deleteItem,
  canDelete
}) => {
  return (
    <Card className="hf-marketplace-list-item" onClick={editItem} raise>
      <img
        src={imageSrc || imagePlaceholder}
        alt="marketplace item"
        className={imageSrc ? '' : `hf-marketplace-${type}`}
      />
      <CardTitle title={title} subtitle={subtitle}>
        {canDelete && (
          <TooltipButton
            key="export"
            tooltipLabel="Delete item"
            tooltipPosition="bottom"
            onClick={deleteItem}
            icon
          >
            <FontIcon>delete</FontIcon>
          </TooltipButton>
        )}
      </CardTitle>
      <CardText className="md-body-1">{stripTags(description)}</CardText>
    </Card>
  )
}

export default MarketplaceListItem
