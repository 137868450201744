import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'

import NavDrawer from './NavDrawer'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  recsOnly: PropTypes.bool
}

const defaultProps = {
  recsOnly: false
}

const NavComponent = (props, showNav) => {
  return (
    <NavDrawer {...props} showNav={showNav}>
      {props.children}
    </NavDrawer>
  )
}

class NavDrawerContainer extends Component {
  render() {
    // allow setting initial
    return (
      <Switch>
        <Route exact path="/" render={() => NavComponent(this.props, false)} />
        <Route
          exact
          path="/login"
          render={() => NavComponent(this.props, false)}
        />
        <Route
          exact
          path="/logout"
          render={() => NavComponent(this.props, false)}
        />
        <Route
          exact
          path="/signup"
          render={() => NavComponent(this.props, false)}
        />
        <Route
          exact
          path="/getstarted"
          render={() => NavComponent(this.props, false)}
        />
        <Route path="*" render={() => NavComponent(this.props, true)} />
      </Switch>
    )
  }
}

NavDrawerContainer.propTypes = propTypes
NavDrawerContainer.defaultProps = defaultProps

function mapStateToProps(state, props) {
  return {
    auth: state.auth,
    guidebook: state.guidebook.data
  }
}

export default connect(mapStateToProps)(injectIntl(NavDrawerContainer))
