import React from 'react'

const LightbulbOutline = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`lightbulb_outline`}</title>
    <path d="M14.859 13.078A4.924 4.924 0 0017.015 9c0-2.766-2.25-5.016-5.016-5.016S6.983 6.234 6.983 9c0 1.641.797 3.141 2.156 4.078l.844.609v2.297h4.031v-2.297zM12 2.016c3.844 0 6.984 3.141 6.984 6.984 0 2.391-1.172 4.453-3 5.719v2.297A.96.96 0 0115 18H9a.96.96 0 01-.984-.984v-2.297c-1.828-1.266-3-3.328-3-5.719 0-3.844 3.141-6.984 6.984-6.984zM9 21v-.984h6V21a.96.96 0 01-.984.984H9.985A.96.96 0 019.001 21z" />
  </svg>
)

export default LightbulbOutline
