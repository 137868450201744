export const sortData = function (
  data,
  sortBy,
  direction,
  sortByTwo,
  directionTwo
) {
  // primary sort key
  const primarySort = sortBy || 'id'
  const primaryDirection = direction || 'asc'
  // tiebreaker sort key
  const secondarySort = sortByTwo || 'id'
  const secondaryDirection = directionTwo || 'asc'
  const sorted = data.sort(function (a, b) {
    var nameA = a[primarySort]
      ? typeof a[primarySort] === 'string'
        ? a[primarySort].toUpperCase()
        : a[primarySort]
      : '' // ignore upper and lowercase
    var nameB = b[primarySort]
      ? typeof b[primarySort] === 'string'
        ? b[primarySort].toUpperCase()
        : b[primarySort]
      : '' // ignore upper and lowercase
    var idA = a[secondarySort] ? a[secondarySort] : 0
    var idB = b[secondarySort] ? b[secondarySort] : 0
    if (nameA < nameB) {
      if (primaryDirection === 'asc') {
        return -1
      } else {
        return 1
      }
    }
    if (nameA > nameB) {
      if (primaryDirection === 'asc') {
        return 1
      } else {
        return -1
      }
    }
    // compared field must be equal - use secondary field as a tiebreaker
    if (idA < idB) {
      if (secondaryDirection === 'asc') {
        return -1
      } else {
        return 1
      }
    }
    if (idA > idB) {
      if (secondaryDirection === 'asc') {
        return 1
      } else {
        return -1
      }
    }
    return 0
  })
  return sorted
}

export const fuzzyFilter = function (items, pluralName, filterFields, filter) {
  if (typeof filter !== 'undefined' && filter !== null) {
    // split up filter by spaces
    const filterParts = filter.split(' ').filter((item) => item.length > 0)
    // console.log(filterParts);
    let all_matches = items
    for (let i = 0, len = filterParts.length; i < len; i++) {
      const matches = filterData(
        items,
        pluralName,
        filterFields,
        filterParts[i]
      )
      // if we have matches,  pare down all_matches to just what we've found
      if (matches) {
        // get keys so we can do a cheap comparison
        if (pluralName === 'Icons') {
          const match_fields = matches.map((item) => item.field)
          all_matches = all_matches.filter(
            (item) => match_fields.indexOf(item.field) !== -1
          )
        } else if (pluralName === 'marketplaceItems') {
          const match_uids = matches.map((item) => item.uid)
          all_matches = all_matches.filter(
            (item) => match_uids.indexOf(item.uid) !== -1
          )
        } else {
          const match_ids = matches.map((item) => item.id)
          all_matches = all_matches.filter(
            (item) => match_ids.indexOf(item.id) !== -1
          )
        }
      } else {
        // we've narrowed it down to nothing
        all_matches = []
        break
      }
    }
    return all_matches
  } else {
    return items
  }
}

export const filterData = function (items, pluralName, filterFields, filter) {
  return typeof filter !== 'undefined' && filter !== null
    ? items.filter(function (item) {
        return filterFields.find(function (field) {
          let val, rawVal
          if (
            field.indexOf('address.') === 0 &&
            (pluralName === 'guidebooks' || pluralName === 'recommendations')
          ) {
            // filter on address fields
            const addressFields = field.split('.')
            rawVal = item.address ? item.address[addressFields[1]] : ''
            val = rawVal ? rawVal.toLowerCase() : ''
          } else if (
            field.indexOf('category.') === 0 &&
            (pluralName === 'recommendations' || pluralName === 'informations')
          ) {
            // filter on category fields
            const categoryFields = field.split('.')
            rawVal = item.category ? item.category[categoryFields[1]] : ''
            val = rawVal ? rawVal.toLowerCase() : ''
          } else if (
            field.indexOf('guidebook.') === 0 &&
            pluralName === 'tokens'
          ) {
            const guidebookFields = field.split('.')
            rawVal = item.guidebook ? item.guidebook[guidebookFields[1]] : ''
            val = rawVal ? rawVal.toLowerCase() : ''
          } else if (field.indexOf('data.') === 0 && pluralName === 'tokens') {
            const dataFields = field.split('.')
            rawVal = item.data ? item.data[dataFields[1]] : ''
            val = rawVal ? rawVal.toLowerCase() : ''
          } else if (
            typeof filter === 'boolean' ||
            typeof filter === 'number'
          ) {
            // special case for boolean filters
            return typeof item[field] !== 'undefined' && item[field] === filter
          } else {
            val = item[field] ? item[field].toLowerCase() : null
          }
          return val && val.indexOf(filter.toLowerCase()) > -1
        })
      })
    : items
}

export const jsonEqual = function (a, b) {
  return JSON.stringify(a) === JSON.stringify(b)
}

export const findKeyByValue = function (object, value) {
  const object_keys = Object.keys(object)
  return object_keys.find((key) => object[key] === value)
}

export const matchData = function (items, pluralName, filterFields, key) {
  const possibleMatches = filterData(items, pluralName, filterFields, key)
  // console.log({possibleMatches: possibleMatches});
  const actualMatch =
    possibleMatches.length > 0
      ? possibleMatches.find(function (item) {
          return item.field === key || item.v1_name === key
        })
      : null
  return actualMatch
}
