import React from 'react'

const Picnic = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`picnic`}</title>
    <path d="M18 13.2v.7h-.8l-1.8-4.7h1.3v-.7H7.3v.7h1.3l-1.8 4.7H6v-.7H1.3v1h5.3l-1.3 3.7H6l1.3-3.7h9.2l1.3 3.7h.7L17 14.2h5.5v-1H18zm-10.5.6l1.8-4.7h5.3l1.8 4.7H7.5zM10.7 8.3h2.7c.3 0 .7-2 .7-2 0-.4-.3-.7-.7-.7h-1V4.5h-.7v1.2h-1c-.4 0-.7.3-.7.6 0 0 .3 2 .7 2z" />
  </svg>
)

export default Picnic
