import React from 'react'

const Thermostat = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`thermostat`}</title>
    <path d="M17.7.9H6.3c-1.4 0-2.5.8-2.5 1.9V21c0 1.1 1.1 1.9 2.5 1.9h11.3c1.4 0 2.5-.8 2.5-1.9V2.8c.1-1-1-1.9-2.4-1.9zm-6.4 20.7c-2.1 0-3.8-1.2-3.8-2.8 0-1.6.8-1.9 1.9-2.4v-12c0-.8.9-1.4 1.9-1.4s1.9.7 1.9 1.4V16c1.1.5 1.9 1.4 1.9 2.4s-1.7 3.2-3.8 3.2zm6.6-8.7h-1.6v-.7H18l-.1.7zm0-1.2h-1.6V11H18l-.1.7zm0-1.3h-1.6v-.6H18l-.1.6zm0-1.2h-1.6v-.7H18l-.1.7zm0-1.3h-1.6v-.6H18l-.1.6zm0-1.3h-1.6V6H18l-.1.6zm0-1.2h-1.6v-.7H18l-.1.7zm0-1.2h-1.6v-.7H18l-.1.7zm0-1.4h-1.6v-.5H18l-.1.5z" />
    <path d="M12.2 18V6.7h-1.7v11.5c-.4.3-.6.7-.7 1.2 0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-.1c0-.6-.2-1-.6-1.3z" />
  </svg>
)

export default Thermostat
