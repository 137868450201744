import React from 'react'

const Balloons = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`balloons`}</title>
    <path d="M9 2C5.69 2 3 4.73 3 8.09c0 2.866 2.05 6.979 5.002 7.765L8 21h2l.002-5.145c2.95-.788 4.998-4.9 4.998-7.765C15 4.73 12.31 2 9 2zm7.4 3.02c.39.95.6 1.99.6 3.07 0 2.56-1.25 5.85-3.41 7.93.42.364.894.637 1.408.804L14.996 22h2l.002-5.16C19.434 16.07 21 12.619 21 10.24c0-2.75-2.03-5.01-4.6-5.22z" />
  </svg>
)

export default Balloons
