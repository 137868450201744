import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'
import { stringToId } from 'utils/Strings'

const propTypes = {
  title: PropTypes.node.isRequired,
  avoidBreak: PropTypes.bool,
  styles: PropTypes.object,
  showQR: PropTypes.bool,
  QRLink: PropTypes.string
}

const defaultProps = {
  avoidBreak: true,
  breakBefore: false,
  breakAfter: false,
  showQR: false,
  QRLink: ''
}

class PrintContentBlock extends PureComponent {
  qrcodeRefs = {}

  downloadQR = (whichRef) => {
    this.qrcodeRefs[whichRef].download(`QR_${whichRef}.png`)
  }

  render() {
    const avoid = this.props.avoidBreak ? ' content-block-avoid' : ''
    const before = this.props.breakBefore ? ' page-break' : ''
    const after = this.props.breakAfter ? ' page-break-after' : ''

    const gbQRref = stringToId(this.props.title)
    const qrColor =
      this.props.styles && this.props.styles.textColor
        ? this.props.styles.textColor.color
        : null
    const qrNode = this.props.showQR ? (
      <div
        className="header-qr-code"
        onClick={() => {
          this.downloadQR(gbQRref)
        }}
        title={this.props.QRLink}
      >
        <QRCode
          renderAs="svg"
          size={60}
          level="H"
          fgColor={qrColor}
          ref={(ref) => (this.qrcodeRefs[gbQRref] = ref)}
          value={this.props.QRLink}
        />
      </div>
    ) : null

    return (
      <div
        className={'content-block md-cell md-cell--12' + avoid + before + after}
      >
        <div className="md-grid md-grid--no-spacing">
          <div
            className="md-cell md-cell--10 md-cell--6-tablet md-cell--3-phone content-title"
            style={this.props.styles ? this.props.styles.textColor : null}
          >
            {this.props.title}
          </div>
          <div className="md-cell md-cell--2 md-cell--1-phone md-text-right">
            {qrNode}
          </div>
        </div>
        {this.props.children}
      </div>
    )
  }
}

PrintContentBlock.propTypes = propTypes
PrintContentBlock.defaultProps = defaultProps

export default PrintContentBlock
