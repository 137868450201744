import React, { Component } from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'
import PrintMap from 'components/PrintMap'
import GuidebookMarker from 'components/MarkerHelpers/GuidebookMarker'
import RecommendationsMarkers from 'components/MarkerHelpers/RecommendationsMarkers'
import formatAddress from 'components/Address/FormatAddress'
import { Media } from 'react-md'
import { resizeImage } from 'utils/Image'
import { renderHours } from 'utils/Dates'
import { fieldHasContent } from 'utils/ValidationHelpers'
import { customColor } from 'utils/Color'
import AsHtml from 'components/Translator/AsHtml'
import Translator from 'components/Translator'
import PrintReadyImage from 'components/Print/PrintReadyImage'
import { buildShortLink, buildGuidebookUrl, removeProtocol } from 'utils/Urls'
import { stringToId } from 'utils/Strings'

class Recommendation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shortLinksById: {}
    }
  }

  qrcodeRefs = {}

  downloadQR = (whichRef) => {
    this.qrcodeRefs[whichRef].download(`QR_${whichRef}.png`)
  }

  getRecommendationLink = (colData) => {
    if (!colData.website && !colData.short_link) return null
    let link = colData.website
    if (colData.short_link && this.props.shortenRecommendationLinks) {
      // if we're on production, we want to do
      const { domain } = this.props.guidebook
      const useDomain = domain && domain.domain ? domain.domain : null
      const short_link = removeProtocol(
        buildShortLink(colData.short_link, useDomain)
      )
      if (short_link) {
        link = short_link
      }
    }
    return (
      <div className="contact">
        <em>{link}</em>
      </div>
    )
  }

  renderRecommendationCol = (colData) => {
    const { theme } = this.props.guidebook
    if (!colData) {
      return <td></td>
    } else {
      let addressData = null
      if (colData.address !== null) {
        const { addressPrimary, addressSecondary } = formatAddress(
          colData.address
        )
        addressData = (
          <div className="contact">
            {addressPrimary} {addressSecondary}
          </div>
        )
      }
      const recImg = resizeImage(colData.image, 132, 99, true)
      const recColor = customColor(
        colData.category.name.toLowerCase(),
        theme,
        colData.category.color
      )
      const colBorderStyle = { borderColor: recColor }

      const recName = (
        <Translator text={colData.name} translations={colData.name_txn} />
      )
      const website = this.getRecommendationLink(colData)
      const websiteAndAddress = (
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--12">{addressData}</div>
          <div className="md-cell md-cell--12">
            {colData.phone_number ? (
              <div className="contact">
                <em>{colData.phone_number}</em>
              </div>
            ) : null}
          </div>
          <div className="md-cell md-cell--12">{website}</div>
        </div>
      )
      return (
        <td className="hf-rec-table-data">
          <div key={colData.id} className="md-grid content-block-avoid">
            <div
              className={
                'md-cell--2 md-cell--2-tablet md-cell--1-phone rec-image'
              }
            >
              <Media aspectRatio="1-1">
                {recImg ? (
                  <PrintReadyImage
                    imageId={colData.category.name + '-' + colData.id}
                    className="hf-print-rec-img"
                    imageUrl={recImg}
                    imageRole="presentation"
                    imageStyle={colBorderStyle}
                  />
                ) : null}
              </Media>
            </div>
            <div className="md-cell--10 md-cell--6-tablet md-cell--3-phone rec-content-cell">
              <div className="hf-print-rec-name-why">
                <span className="hf-print-rec-name">{recName}: </span>
                <br />
                <AsHtml
                  text={colData.why_recommended}
                  translations={colData.why_recommended_txn}
                />
              </div>
              {websiteAndAddress}
            </div>
            {this.renderHoursColumn(colData)}
            {this.renderFullTextColumn(colData.content, colData.content_txn)}
          </div>
        </td>
      )
    }
  }

  renderFullRecommendation = (col) => {
    return <tr key={col.id}>{this.renderRecommendationCol(col)}</tr>
  }

  // TODO - make this use a live call to hours instead of using cached hours
  renderHoursColumn = (rec) => {
    if (this.props.fullRecommendations && rec.hours) {
      return <div className="md-cell md-cell--12">{renderHours(rec, true)}</div>
    } else {
      return null
    }
  }

  renderFullTextColumn = (content, content_txn) => {
    if (this.props.fullRecommendations && fieldHasContent(content)) {
      return (
        <div className="md-cell md-cell--12">
          <AsHtml text={content} translations={content_txn} />
        </div>
      )
    } else {
      return null
    }
  }

  renderRecommendationRow = (col1, col2) => {
    return (
      <tr key={col1.id}>
        {this.renderRecommendationCol(col1)}
        {this.renderRecommendationCol(col2)}
      </tr>
    )
  }

  renderMarkers = (guidebook, recommendations) => {
    // place markers for recommendations
    const recColor = null
    const gbColor = this.props.color ? this.props.color : null

    let markers = RecommendationsMarkers(
      recommendations,
      30,
      true,
      recColor,
      null,
      guidebook.theme
    )
    markers.push(
      GuidebookMarker(guidebook, 50, true, gbColor, null, guidebook.theme)
    )

    return markers
  }

  render() {
    const { recommendations, categoryName, label } = this.props
    // console.log(recommendations);
    //render recs content in two columns below the map
    const children = []
    if (this.props.fullRecommendations) {
      for (var r = 0; r < recommendations.length; r++) {
        children.push(this.renderFullRecommendation(recommendations[r]))
      }
    } else {
      for (var i = 0; i < recommendations.length; i += 2) {
        const col1 = recommendations[i]
        const col2 =
          i === recommendations.length ? null : recommendations[i + 1]
        children.push(this.renderRecommendationRow(col1, col2))
      }
    }
    const mapKey = 'rec-map-' + categoryName
    const markers = this.renderMarkers(this.props.guidebook, recommendations)

    const gbQRref = stringToId(label)
    const qrColor =
      this.props.styles && this.props.styles.textColor
        ? this.props.styles.textColor.color
        : null
    const catSlug = categoryName.toLowerCase().replace(/ /g, '_')
    const domain =
      this.props.guidebook.domain &&
      this.props.guidebook.domain.status === 'approved'
        ? this.props.guidebook.domain.domain
        : null
    const qrLink = `${buildGuidebookUrl(this.props.guidebook.key, null, null, false, domain, this.props.guidebook.is_published)}/recommendations/${catSlug}`
    const qrNode = this.props.showQR ? (
      <div
        className="header-qr-code"
        onClick={() => {
          this.downloadQR(gbQRref)
        }}
        title={qrLink}
      >
        <QRCode
          renderAs="svg"
          size={60}
          level="H"
          fgColor={qrColor}
          ref={(ref) => (this.qrcodeRefs[gbQRref] = ref)}
          value={qrLink}
        />
      </div>
    ) : null

    return (
      <div key={'container-' + categoryName} className="content-block">
        <div className="page-break" key={'pb-' + categoryName}></div>
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--10 md-cell--6-tablet md-cell--3-phone">
            <h3
              className="content-title"
              key={'h3-' + categoryName}
              style={this.props.styles ? this.props.styles.textColor : null}
            >
              {label}
            </h3>
          </div>
          <div className="md-cell md-cell--2 md-cell--1-phone md-text-right">
            {qrNode}
          </div>
        </div>
        <PrintMap
          key={mapKey}
          className="hf-print-map-container-full"
          guidebook={this.props.guidebook}
          mapMarkers={markers}
          mapId={mapKey}
          onTilesLoad
        />
        <table className="hf-recs-table" ref="recs-table">
          <tbody>{children}</tbody>
        </table>
      </div>
    )
  }
}

Recommendation.propTypes = {
  categoryName: PropTypes.string,
  label: PropTypes.string,
  recommendations: PropTypes.array,
  fullRecommendations: PropTypes.bool,
  shortenRecommendationLinks: PropTypes.bool,
  guidebook: PropTypes.object,
  styles: PropTypes.object,
  showQR: PropTypes.bool,
  color: PropTypes.string
}

Recommendation.defaultProps = {
  showQR: false
}

export default Recommendation
