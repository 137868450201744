// placeholder - I suspect we'll need some custom logic here
export const buildGuidebookUrl = (
  key,
  locale,
  recsOnly,
  disableShortLinks,
  domain,
  published
) => {
  if (!key) return null
  let urlBase = process.env.REACT_APP_BASE_URL
  if (domain && published) {
    urlBase = `https://${domain}`
  } else if (urlBase === 'https://v2.hostfully.com' && !disableShortLinks) {
    urlBase = 'https://hostful.ly'
  }
  if (recsOnly) key = `recs/${key}`
  if (locale) {
    return `${urlBase}/${locale.substring(0, 2)}/${key}`
  }
  return `${urlBase}/${key}`
}

export const buildShortLink = (slug, domain) => {
  if (!slug) return null
  let urlBase = process.env.REACT_APP_API_URL
  if (urlBase === 'https://v2api.hostfully.com') {
    urlBase = 'https://hostful.ly'
  }
  if (domain) {
    urlBase = `https://${domain}`
  }
  return `${urlBase}/s/${slug}`
}

export const removeProtocol = (link) => {
  return link.replace(/^https?:\/\//, '')
}

export const buildTokenUrl = (key, token, locale, domain) => {
  if (!key || !token) return null
  let gbUrl = buildGuidebookUrl(key, locale, false, false, domain)
  return `${gbUrl}?st=${token}`
}

export const parseBaseDomain = (url) => {
  return url.replace('https://', '').replace('http://', '').split('/').shift()
}

export const checkDomain = (guidebook) => {
  const defaultUrl = parseBaseDomain(process.env.REACT_APP_BASE_URL)
  const thisUrl = parseBaseDomain(window.location.href)
  const matches = thisUrl === defaultUrl
  const gbMatches =
    guidebook && guidebook.domain && guidebook.domain.domain === thisUrl
  // if the domain being used does not match the base domain
  // then we're using a custom domain
  if (!matches && !gbMatches) {
    const redirectUrl = window.location.href
      .replace('http://', '')
      .replace('https://', '')
      .replace(thisUrl, process.env.REACT_APP_BASE_URL)
    window.location.href = redirectUrl
  }
  // if we have a custom domain for this guidebook but it wasn't used, redirect
  if (
    !gbMatches &&
    matches &&
    guidebook &&
    guidebook.is_published &&
    guidebook.domain &&
    guidebook.domain.status === 'approved'
  ) {
    let urlObj = new URL(window.location.href)
    // force protocol to https:
    urlObj.protocol = 'https:'
    // clear custom port (for localhost:3000)
    urlObj.port = ''
    urlObj.host = guidebook.domain.domain
    const updatedUrl = urlObj.href
    // console.log(updatedUrl);
    window.location.href = updatedUrl
  }
}

// find the guidebook url and whether or not it is a recs-only link
export const findGuidebookUrl = (domain) => {
  let urlBase = process.env.REACT_APP_BASE_URL
  let customBase = `https://${domain}`
  const url = window.location.href
  if (url.substring(0, urlBase.length) === urlBase) {
    //base url matches, now find guidebook key or recs only key
    let urlNoBase = url.substring(urlBase.length + 1)
    if (urlNoBase.substring(0, 5) === 'recs/') {
      // if the relative url starts with r/ than it's recs-only
      urlNoBase = urlNoBase.substring(5)
      let recsKeySplit = urlNoBase.split('/', 1)
      if (recsKeySplit.length) {
        return {
          guidebookUrl: `${urlBase}/recs/${recsKeySplit[0]}`,
          recsOnly: true
        }
      }
    } else {
      // otherwise we're in a regular guidebook
      let keySplit = urlNoBase.split('/', 1)
      if (keySplit.length) {
        return { guidebookUrl: `${urlBase}/${keySplit[0]}`, recsOnly: false }
      }
    }
  } else if (domain && url.substring(0, customBase.length) === customBase) {
    let customNoBase = url.substring(customBase.length + 1)
    if (customNoBase.substring(0, 5) === 'recs/') {
      // if the relative url starts with r/ than it's recs-only
      customNoBase = customNoBase.substring(5)
      let recsKeySplit = customNoBase.split('/', 1)
      if (recsKeySplit.length) {
        return {
          guidebookUrl: `${customBase}/recs/${recsKeySplit[0]}`,
          recsOnly: true
        }
      }
    } else {
      // otherwise we're in a regular guidebook
      let keySplit = customNoBase.split('/', 1)
      if (keySplit.length) {
        return { guidebookUrl: `${customBase}/${keySplit[0]}`, recsOnly: false }
      }
    }
  }
  // if none of this worked, return object with null values
  return { guidebookUrl: null, recsOnly: null }
}

export const updateVideoUrl = (video) => {
  let newURL = video
  if (video) {
    if (video.indexOf('?') === -1) {
      newURL += '?playsinline=1&fs=0&rel=0'
    } else {
      newURL += '&playsinline=1&fs=0&rel=0'
    }
  }
  return newURL
}

export const parseBool = (value, defaultValue) => {
  const parseValue = typeof value === 'string' ? value.toLowerCase() : value
  return (
    (['true', 'false', true, false].includes(parseValue) &&
      JSON.parse(parseValue)) ||
    defaultValue
  )
}

export const parseQueryString = (queryString) => {
  // strip off ? if present
  if (queryString.indexOf('?') !== -1) {
    queryString = queryString.substring(1)
  }
  let params = {}
  let temp = null
  // Split into key/value pairs
  const queries = queryString.split('&')
  // Convert the array of strings into an object
  for (let i = 0, l = queries.length; i < l; i++) {
    temp = queries[i].split('=')
    params[temp[0]] = temp[1]
  }
  return params
}

export const removeURLParameter = (url, parameter) => {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split('?')
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter)
    var pars = urlparts[1].split(/[&;]/g)

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1)
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '')
  }
  return url
}

export const updateViatorLink = (
  url,
  partnerId,
  host_user_id,
  guidebook_id
) => {
  let website = url
  const brandSubBrand = `h${host_user_id}-g${guidebook_id}`
  if (partnerId) {
    //  ?eap=h7-g231292-78955&aid=vba78955en
    //  needs to become
    //  ?pid=P00017344&mcid=42383&medium=link&campaign=HF-campaignname
    const paramIndex = url.indexOf('?')
    console.log(`injecting partnerID: ${partnerId} into ${url}`)
    if (paramIndex !== -1) {
      website = `${url.slice(0, paramIndex)}?pid=${partnerId}&mcid=42383&medium=link&campaign=HF-${brandSubBrand}`
    }
  } else {
    const brandSubBrand = `h${host_user_id}-g${guidebook_id}`
    website = url.replace('host_id-guidebook_id', brandSubBrand)
    // console.log(website);
  }
  // console.log(website);
  return website
}
