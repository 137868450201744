import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import { replace } from 'react-router-redux'
import { Button, CardActions, Tabs, Tab, TabsContainer } from 'react-md'
import ManageSelectedCards from '../ManageSelectedCards'
import ManageMarketplaceSelectedTab from './ManageMarketplaceSelectedTab'

class ManageMarketplace extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabIndex: this.setActiveTab(),
      filter: '',
      tabContentChanged: new Date()
    }
    this.onTabChange = this.onTabChange.bind(this)
    this.tabContentChanged = this.tabContentChanged.bind(this)
  }

  createCard = (type) => {
    const { dispatch, item_id, singularName, pluralName } = this.props
    // if no id set, they must be creating a guidebook or template
    const id = item_id ? item_id : 'create'

    // BA. bit of a hack for when they edit a card and return with a hash in the URL, then refresh the whole browser
    dispatch(actions.change('edit_' + singularName + '.noReload', true))
    dispatch(replace(`/host/${pluralName}/${id}/marketplaceItems/create`))
  }

  setActiveTab = () => {
    return this.props.selectedServices.length > 0 ? 1 : 0
  }

  onTabChange = (newTabIndex, event) => {
    this.setState({
      activeTabIndex: newTabIndex
    })
  }

  handleSelectionChanged = (selection) => {
    if (typeof this.props.onSelectionChanged === 'function') {
      this.props.onSelectionChanged(selection)
    }
  }
  /*
    BA a bit of a hack to workaround an issue in react-md tabs. Let the tab notify the parent that it's content has changed.
    This function just sets a new state to force a recalc of heights etc
   */
  tabContentChanged = (thing) => {
    this.setState({
      tabContentChanged: new Date()
    })
  }

  render() {
    const { pluralName, singularName } = this.props
    const self = this
    const initialItems = { data: this.props.allServices }
    return (
      <div id="marketplace" className="hf-editable-card-tabs">
        <TabsContainer
          onTabChange={this.onTabChange}
          activeTabIndex={this.state.activeTabIndex}
        >
          <Tabs tabId="tab">
            <Tab key="select" label="Select">
              <ManageSelectedCards
                ownerPluralName={pluralName}
                ownerSingularName={singularName}
                pluralName="marketplaceItems"
                cardType="marketplaceItem"
                manuallyManageSelection={true}
                initialSelection={this.props.selectedServices}
                initialItems={initialItems}
                onSelectionChanged={(selection) =>
                  self.handleSelectionChanged(selection)
                }
                active={this.state.activeTabIndex === 0}
                onTabContentChanged={this.tabContentChanged}
                showHeading={false}
              />
            </Tab>
            <Tab key="order" label="Sort">
              <ManageMarketplaceSelectedTab
                ownerPluralName={pluralName}
                ownerSingularName={singularName}
                selectedItems={this.props.selectedServices}
                active={this.state.activeTabIndex === 1}
                onSort={this.props.onSort}
                {...this.props}
              />
            </Tab>
          </Tabs>
        </TabsContainer>
        <CardActions>
          <Button
            onClick={(e) => {
              this.createCard('informations')
            }}
            flat
            primary
          >
            Create Marketplace Item
          </Button>
        </CardActions>
      </div>
    )
  }
}

ManageMarketplace.propTypes = {
  pluralName: PropTypes.string.isRequired,
  singularName: PropTypes.string.isRequired,
  selectedServices: PropTypes.array,
  allServices: PropTypes.array,
  onSelectionChanged: PropTypes.func,
  onSort: PropTypes.func
}

function mapStateToProps(state, props) {
  const { singularName } = props
  const edit_item = state['edit_' + singularName]
  return {
    item_id: edit_item.id
  }
}

export default connect(mapStateToProps)(ManageMarketplace)
