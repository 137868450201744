import { getToken } from 'utils/Auth'

import 'whatwg-fetch'

export function fixRotation(filestackUrl) {
  const apiKey = process.env.REACT_APP_FILESTACK_KEY
  const apiUrl = 'https://process.filestackapi.com/'

  // get filestack handle for image
  const fileParts = filestackUrl.split('/')
  const slug = fileParts[fileParts.length - 1]

  // concat everything together as the url
  const url = apiUrl + apiKey + '/store=location:gcs/rotate=deg:exif/' + slug
  const options = {
    method: 'POST'
  }
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
}

/**
 * calls to elixir which converts the google places image to the googleusercontent url
 * then sends to filestack to store so we can use it forever
 */
export const convertAndStoreImage = function (imageUrl) {
  const path = '/api/v1/recommendations/convert_place_image/'
  const method = 'GET'

  const url =
    process.env.REACT_APP_API_URL + path + encodeURIComponent(imageUrl)
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
}
