import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import UAParser from 'ua-parser-js'
import cn from 'classnames'
import { Button, FontIcon } from 'react-md'
import formatAddress from 'components/Address/FormatAddress'
import LocaleSwitcher from 'components/LocaleSwitcher'
import Translator from 'components/Translator'
// import { trackEvent, getHostIdAndKey } from 'utils/Segment';
import { openMap } from 'utils/Map'
import { checkDomain, removeURLParameter } from 'utils/Urls'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  guidebook: PropTypes.object.isRequired,
  // helper for internationalization
  intl: intlShape.isRequired
}

const defaultProps = {}

class LandingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      localesShown: false
    }
  }

  componentDidMount() {
    // track this event in segment
    // OLD SEGMENT CODE
    if (
      this.props.guidebook.key &&
      this.props.guidebook.key !== 'loading' &&
      this.props.guidebook.host_user_id
    ) {
      // let eventData = getHostIdAndKey(this.props.guidebook);
      // if (eventData.key) eventData.label = eventData.key;
      // eventData.category = 'Guidebook';
      // trackEvent('landingPageViewed', eventData);
    }
  }

  setLandingCookie = () => {
    const { cookies } = this.props
    const cookieName = `hf_landing_${this.props.guidebook.key}`
    const cookieValue = this.props.guidebook.key
    // remember preference with cookie
    const expiresDate = new Date()
    expiresDate.setMonth(expiresDate.getMonth() + 6)
    const cookieOptions = {
      path: '/',
      expires: expiresDate,
      secure: true
    }
    cookies.set(cookieName, cookieValue, cookieOptions)
  }

  localesShown = (value) => {
    this.setState({ localesShown: value })
  }

  handleSubmit = (value) => {
    let redirectUrl = removeURLParameter(window.location.href, 'showlanding')
    this.setLandingCookie()
    setTimeout(() => {
      window.location.href = redirectUrl
    }, 250)
  }

  render() {
    checkDomain(this.props.guidebook)
    const self = this
    const ua = new UAParser()
    let device_type = ua.getDevice().type
    if (device_type !== 'mobile' && device_type !== 'tablet') {
      device_type = 'desktop'
    }
    const classNames = cn('landing-page', device_type)

    // let's just gather the data first
    const hi = this.props.guidebook.host_intro

    const infoStyle = { color: hi.landing_header_text_color }
    const buttonStyle = { backgroundColor: hi.landing_button_color }

    let header = this.props.guidebook.name
    if (hi.landing_header_text) {
      // TODO add tranlsation
      header = (
        <Translator
          text={hi.landing_header_text}
          translations={hi.landing_header_text_txn}
        />
      )
    }
    let subheader = hi.landing_subheader_text ? (
      <Translator
        text={hi.landing_subheader_text}
        translations={hi.landing_subheader_text_txn}
      />
    ) : (
      ''
    )

    let logo = null
    if (hi.landing_logo_image) {
      logo = (
        <img className="lp-logo-img" alt="logo" src={hi.landing_logo_image} />
      )
    }
    let bg = null
    if (hi.landing_background_image) {
      bg = (
        <img
          className="lp-bg"
          alt="background"
          src={hi.landing_background_image}
        />
      )
    }
    let contact = null
    if (hi.landing_show_contact) {
      let email = null
      if (hi.host_email) {
        email = (
          <div className="lp-email">
            <a href={`mailto:${hi.host_email}`} style={infoStyle}>
              <FontIcon>send</FontIcon> {hi.host_email}
            </a>
          </div>
        )
      }
      let phone = null
      if (hi.host_phone.length) {
        phone = (
          <div className="lp-phone">
            <a href={`tel:${hi.host_phone[0]}`} style={infoStyle}>
              <FontIcon>call</FontIcon> {hi.host_phone[0]}
            </a>
          </div>
        )
      }
      contact = (
        <div className="lp-contact">
          {email}
          {phone}
        </div>
      )
    }
    let addressNode = null
    if (hi.landing_show_address) {
      const { address } = this.props.guidebook
      const { addressPrimary, addressSecondary } = formatAddress(address)

      addressNode = (
        <div
          className="lp-address"
          onClick={(event) => openMap(event, address, false)}
        >
          <FontIcon>room</FontIcon> {addressPrimary}
          <br />
          {addressSecondary}
        </div>
      )
    }

    const localeAdjustClass = this.state.localesShown ? 'lp-locale-offset' : ''
    const overlayClass = cn('lp-overlay', localeAdjustClass)
    return (
      <div className={classNames}>
        <LocaleSwitcher
          onShown={(e) => {
            self.localesShown(true)
          }}
          onHidden={(e) => {
            self.localesShown(false)
          }}
        />
        <div className={overlayClass}></div>
        <div className="lp-wrapper" style={infoStyle}>
          <div className="lp-info" style={infoStyle}>
            <div className="lp-header">{header}</div>
            <div className="lp-subheader">{subheader}</div>
            <div className="lp-logo">{logo}</div>
            {contact}
            {addressNode}
          </div>
          <Button
            className="lp-button"
            primary
            raised
            style={buttonStyle}
            onClick={this.handleSubmit}
          >
            Get Started!
          </Button>
        </div>
        {bg}
      </div>
    )
  }
}

LandingPage.propTypes = propTypes
LandingPage.defaultProps = defaultProps

export default withCookies(connect()(injectIntl(LandingPage)))
