import React from 'react'

const CheckBox = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`check_box`}</title>
    <path d="M9.984 17.016l9-9-1.406-1.453-7.594 7.594-3.563-3.563L5.015 12zm9-14.016C20.109 3 21 3.938 21 5.016v13.969c0 1.078-.891 2.016-2.016 2.016H5.015c-1.125 0-2.016-.938-2.016-2.016V5.016C2.999 3.938 3.89 3 5.015 3h13.969z" />
  </svg>
)

export default CheckBox
