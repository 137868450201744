import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Avatar, Card, CardText, CardTitle } from 'react-md'

import AsHtml from 'components/Translator/AsHtml'

class TextCard extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = (e) => {
    this.props.clickCallBack(e)
  }

  render() {
    const { titleString, leadPara, leadParaTxn, icon } = this.props
    let showIcon = null
    if (icon) {
      const IconComponent = icon.icon
      showIcon = <IconComponent fill="#ffffff" />
    }
    const avatarStyle = {
      background: this.props.custom_color,
      color: '#ffffff'
    }
    const cardAvatar = (
      <Avatar icon={showIcon} role="presentation" style={avatarStyle} />
    )
    const cardText = leadPara ? (
      <CardText dir="auto">
        <AsHtml text={leadPara} translations={leadParaTxn} />
      </CardText>
    ) : null

    return (
      <Card
        key={'TextCard' + titleString}
        raise={true}
        className="hf-clickable"
        onClick={(e) => this.handleClick(e)}
      >
        <CardTitle avatar={cardAvatar} title={titleString} />
        {cardText}
      </Card>
    )
  }
}

TextCard.propTypes = {
  iconSuffix: PropTypes.string,
  titleString: PropTypes.string,
  leadPara: PropTypes.string,
  leadParaTxn: PropTypes.object,
  icon: PropTypes.object,
  clickCallBack: PropTypes.func,
  custom_color: PropTypes.string
}

TextCard.defaultProps = {
  custom_color: 'rgb(255, 87, 34)'
}

export default TextCard
