import React from 'react'

const Audiotrack = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`audiotrack`}</title>
    <path d="M12 3h6.984v3H15v11.016h-.047C14.719 19.266 12.797 21 10.5 21 8.016 21 6 18.984 6 16.5S8.016 12 10.5 12a4.07 4.07 0 011.5.281V3z" />
  </svg>
)

export default Audiotrack
