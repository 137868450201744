import React from 'react'

const Compost = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`compost`}</title>
    <path d="M4.7 20.6c0 1.3 1.1 2.4 2.4 2.4h9.8c1.3 0 2.4-1.1 2.4-2.4V5.9H4.7v14.7zm12-12c.1.8.2 1.6.2 2.4.1.6.1 1.2.1 1.8-.1 1.9-1.4 3.7-3.1 4.4-.8.3-1.6.5-2.4.6-1 .2-2 .4-3.1.5 3-2.6 6.2-5.6 8.3-9.7 0 0 0 .1 0 0zM6.4 19.4c.3-.3.6-.8.8-1.2.2-.4.1-.9.1-1.4 0-1.7.3-3.4 1-5 .4-.9.8-1.7 1.5-2.4 1.8-1.6 4.3-.7 6.5-.9-.7 1.3-1.7 2.6-2.6 3.7-1 1.2-2 2.4-3.1 3.5-.9.9-1.9 1.8-2.8 2.8-.3.3-.5.7-.8 1-.1.1-.2.3-.3.4-.2.1-.4 0-.4-.3 0 0 .1-.1.1-.2zM16.3 2.2L15.1 1H8.9L7.7 2.2H3.4v2.5h17.1V2.2h-4.2z" />
  </svg>
)

export default Compost
