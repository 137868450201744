import React from 'react'

const Lifejacket = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`lifejacket`}</title>
    <path d="M19.6 14.9h-4.7c0 .5-.4.9-.9.9h-.7v1.3h.7c.5 0 .9.3.9.8h4.7v-3zM9 14.2c.1-.5.5-.8.9-.8h.7V11c-1.4-.6-2.4-1.9-2.4-3.5 0-2.1 1.7-3.8 3.8-3.8s3.8 1.7 3.8 3.8c0 1.6-1 2.9-2.3 3.5v2.3h.7c.5 0 .9.3.9.8h4.7V9.5 9v-.2C19.8 4.5 16.3 1 12 1 7.8 1 4.4 4.4 4.2 8.5V14l4.8.2zM14.9 18.7c0 .5-.4.9-.9.9h-.7V23h4.6c1 0 1.8-.8 1.8-1.8v-2.6h-4.8zM9.9 19.5c-.5 0-.9-.4-.9-.9H4.2v2.6c0 1 .8 1.8 1.8 1.8h4.5v-3.4h-.6zM9.9 17.1h.7v-1.3h-.7c-.5 0-.9-.4-.9-.9H4.2V18H9c0-.6.4-.9.9-.9z" />
  </svg>
)

export default Lifejacket
