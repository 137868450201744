import { getAll } from 'firebase/remote-config'

import { remoteConfig } from '../firebaseConfig'

export const getFeatureFlags = async (keys) => {
  const flags = {}

  await new Promise((resolve, reject) => {
    let counter = 0
    const timer = setInterval(async () => {
      try {
        if (remoteConfig.lastFetchStatus === 'success' || counter > 100) {
          clearInterval(timer)
          resolve()
        }
        counter++
      } catch (error) {
        clearInterval(timer)
        reject(error)
      }
    }, 500)
  })

  const remoteFlagValues = getAll(remoteConfig)

  keys.forEach((key) => {
    const stringValue = remoteFlagValues[key].asString()

    try {
      flags[key] = JSON.parse(stringValue)
    } catch (e) {
      flags[key] = stringValue
    }
  })

  return flags
}
