import React from 'react'

const Nature = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`nature`}</title>
    <path d="M12.984 16.125v3.891h6v1.969H5.015v-1.969h6v-3.938c-3.328-.563-5.859-3.422-5.859-6.891 0-3.891 3.141-7.031 7.031-7.031s6.984 3.141 6.984 7.031c0 3.609-2.719 6.516-6.188 6.938z" />
  </svg>
)

export default Nature
