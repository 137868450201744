import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import LocaleForm from './LocaleForm'
import { actions } from 'react-redux-form'
import { Button, DialogContainer, FontIcon } from 'react-md'
import currentUser from 'utils/CurrentUser'

class LocaleFormContainer extends Component {
  constructor(props) {
    super(props)
    this.localeSelection = this.localeSelection.bind(this)
    this.state = { visible: false }
    this.showDialog = this.showDialog.bind(this)
    this.hideDialog = this.hideDialog.bind(this)
    this.activate = this.activate.bind(this)
  }

  showDialog = () => {
    this.setState({ visible: true })
  }

  hideDialog = () => {
    this.setState({ visible: false })
  }

  activate() {
    this.hideDialog()
    window.location.href =
      process.env.REACT_APP_WORDPRESS_URL + '/upgrade-power'
  }

  localeSelection = (checked, e) => {
    const { dispatch, locales, editModel } = this.props
    const locale = e.target.name
    // TODO BA - this is a bit ugly, I think because react-redux-form uses immutable.js
    const spliced = locales.reduce(function (arr, l) {
      if (l !== locale) {
        arr.push(l)
      }
      return arr
    }, [])
    const locs = checked ? locales.concat([locale]) : spliced
    const id = editModel + '.locales'
    dispatch(actions.change(id, locs))
  }

  render() {
    const { visible } = this.state
    const user = currentUser()
    const disableLocaleForm = user.canTranslate ? false : true

    const localeWrapperClass = disableLocaleForm
      ? 'md-cell md-cell--9 md-cell--5-tablet md-cell--middle hf-disabled-input'
      : 'md-cell md-cell--9 md-cell--5-tablet md-cell--middle'

    const sectionWrapperClass = disableLocaleForm
      ? 'md-grid md-grid--no-spacing hf-disabled-input'
      : 'md-grid md-grid--no-spacing'

    const localeCta = disableLocaleForm ? (
      <Button
        onClick={this.showDialog}
        iconChildren="account_circle"
        flat
        primary
      >
        Activate
      </Button>
    ) : null

    const localIconClass = disableLocaleForm
      ? 'md-inline-block md-btn md-btn--icon hf-float-right md-text--disabled'
      : 'md-inline-block md-btn md-btn--icon hf-float-right'

    const actions = []
    actions.push(
      <Button flat primary onClick={this.hideDialog}>
        Cancel
      </Button>
    )
    actions.push(
      <Button flat primary onClick={this.activate}>
        Continue
      </Button>
    )

    return (
      <div className={sectionWrapperClass} id="locale">
        <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
          <FontIcon className={localIconClass}>language</FontIcon>
        </div>
        <div className={localeWrapperClass}>
          <LocaleForm disabled={disableLocaleForm} {...this.props} />
        </div>
        <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--middle">
          {localeCta}
        </div>
        <DialogContainer
          id="simple-action-dialog"
          visible={visible}
          onHide={this.hideDialog}
          actions={actions}
          title="Upgrade your plan?"
        >
          <p>
            You will be redirected to another page, save your work before
            continuing
          </p>
        </DialogContainer>
      </div>
    )
  }
}

LocaleFormContainer.propTypes = {
  editModel: PropTypes.string,
  locales: PropTypes.array,
  disabled: PropTypes.bool
}

function mapStateToProps(state, props) {
  const model = state[props.editModel]
  const locales = model.locales
  return {
    locales
  }
}

export default connect(mapStateToProps)(injectIntl(LocaleFormContainer))
