import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import { intlShape } from 'react-intl'
import { Button, DialogContainer, Media, Toolbar } from 'react-md'

import PrintHtmlContainer from 'components/Print/PrintHtmlContainer'
import { editItem } from 'redux/modules/crud'
import { addToast } from 'redux/modules/toast'

import ConfigureMenu from './ConfigureMenu.js'

class Print extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPrinting: false,
      modalVisible: props.modalVisible
    }
    this.back = this.back.bind(this)
    this.showHelp = this.showHelp.bind(this)
    this.hideHelp = this.hideHelp.bind(this)
    this.handlePrintClick = this.handlePrintClick.bind(this)
    this.doPrint = this.doPrint.bind(this)
  }

  back = (id, e) => {
    const { dispatch } = this.props

    dispatch(editItem('guidebooks', id, e))
  }

  showHelp = () => {
    this.setState({ modalVisible: true })
  }

  hideHelp = () => {
    this.setState({ modalVisible: false })
  }

  renderHelpToolbar = () => {
    const action = (
      <Button icon onClick={this.hideHelp}>
        close
      </Button>
    )
    return (
      <Toolbar actions={action} title="How to print your guidebook" fixed />
    )
  }

  renderHelpContent = () => {
    return (
      <div className="md-toolbar-relative list-item-modal-content">
        <div className="md-grid md-grid--40-16">
          <div className="markdown-container md-text-container md-cell md-cell--12">
            <h1>Print from your browser</h1>
            <p>
              Your browser has a built in print feature which we use to print
              your guidebook. We can't control exactly what your printed
              guidebook will look like because every browser and device has
              slightly different implementations of print. For best results we
              recommend using Chrome with A4 paper and the default margins.
            </p>
            <p>
              Our print feature allows you to preview and configure your
              guidebook before printing it. On the left is a preview of your
              printable content, while the panel on the right allows you to
              choose which sections are printed and modify the appearance of the
              printed version:
            </p>
            <div className="hf-help-screenshot">
              <Media aspectRatio="21-15">
                <img
                  src="https://storage.googleapis.com/v2-static/printfeature.png"
                  alt="Screenshot of Hostfully print feature"
                />
              </Media>
            </div>
            <p>
              When you are ready to print, click the print button in the
              toolbar, at the top of the screen, to launch your browser's print
              dialog. From here you'll be able to preview and/or change the
              print settings which we can't control (like paper size, margins
              and dynamic page breaks).
            </p>
            <h2>Select your printer</h2>
            <p>
              Use the destination section of the print dialog to select your
              printer or to save the print job as a PDF. Click the Change...
              button to switch destinations:
            </p>
            <div className="hf-help-screenshot">
              <Media aspectRatio="20-13">
                <img
                  src="https://storage.googleapis.com/v2-static/print-destination.png"
                  alt="Screenshot of default Chrome print dialog"
                />
              </Media>
            </div>
            <h2>Save as a PDF</h2>
            <p>
              Most browsers allow you to save your print as a PDF by either
              choosing it as the destination, as shown here:
            </p>
            <div className="hf-help-screenshot">
              <Media aspectRatio="20-13">
                <img
                  src="https://storage.googleapis.com/v2-static/saveaspdf.png"
                  alt="Screenshot of default Chrome print destination dialog"
                />
              </Media>
            </div>
            <h2>Print cover page edge-to-edge</h2>
            <p>
              Some of the cover page designs look good printed edge-to-edge. You
              can do this by changing the margins in the print dialog to "none".
              In Chrome you need to expand the More settings section in the
              print dialog to change the margins:
            </p>
            <div className="hf-help-screenshot">
              <Media aspectRatio="20-13">
                <img
                  src="https://storage.googleapis.com/v2-static/moresettings.png"
                  alt="Screenshot of default Chrome print destination dialog"
                />
              </Media>
            </div>
            <p>
              This allows you to change the margins to none or to set custom
              margins:
            </p>
            <div className="hf-help-screenshot">
              <Media aspectRatio="20-13">
                <img
                  src="https://storage.googleapis.com/v2-static/nomargins.png"
                  alt="Screenshot of default Chrome print dialog showing no margins"
                />
              </Media>
            </div>
            <p>
              Please note that this will affect the margins on all pages, so
              enter 1 in the pages field so that only the first page is printed.
              We don't recommend printing the other pages in your guidebook
              without margins, so once you print the cover page, reset the
              margins and enter 2-n (where n is the highest page number) in the
              pages field, before printing the other pages.
            </p>
            <h2>More control over print settings</h2>
            <p>
              You might find that your device allows you to use your printer's
              dialog to print from your browser. This could allow you to access
              more of the features of your printer. In Chrome the link labelled
              "Print using system dialog" will open the dialog for your printer:
            </p>
            <div className="hf-help-screenshot">
              <Media aspectRatio="20-13">
                <img
                  src="https://storage.googleapis.com/v2-static/printsystemdialog.png"
                  alt="Screenshot of default Chrome print system dialog"
                />
              </Media>
            </div>
            <h2>Collapse the navigator for more room</h2>
            <p>
              We recommend you collapse the host left hand navigator when
              printing so you can maximise the available screen space:
            </p>
            <div className="hf-help-screenshot">
              <Media aspectRatio="20-13">
                <img
                  src="https://storage.googleapis.com/v2-static/collapse-nav.png"
                  alt="Screenshot of Hostfully host nav collapse icon"
                />
              </Media>
            </div>
            <h2>Print problems?</h2>
            <p>
              We can't control exactly what your printed guidebook will look
              like because every browser and device has slightly different
              implementations of print. For best results we recommend using
              Chrome. However if you can't use Chrome or you are using chrome
              but still have issues, reach out so we can try to resolve them.
              The best way to get help with print issues is in the{' '}
              <a href="https://www.facebook.com/groups/hostfullyhosts/">
                Hostfully User Group
              </a>{' '}
              on Facebook. Alternatively you can email support@hostfully.com
            </p>
          </div>
        </div>
      </div>
    )
  }

  handlePrintClick = (event) => {
    const { dispatch, printReadyState } = this.props
    if (printReadyState.ready) {
      this.doPrint()
    } else {
      //show dialog saying images not loaded yet
      const message =
        "Please wait, we're just waiting for images to load so they will print properly!"
      const action = {
        children: 'Print Anyway',
        raised: true,
        flat: false,
        style: {
          backgroundColor: '#03A9F4',
          height: 'auto',
          marginRight: '24px'
        },
        onClick: () => {
          this.doPrint()
        }
      }
      dispatch(addToast(message, action, false))
    }
  }

  doPrint = () => {
    window.print()
  }

  render() {
    //Toolbar actions
    const nav = (
      <Button
        onClick={(e) => {
          this.back(this.props.guidebook.id, e)
        }}
        icon
      >
        keyboard_backspace
      </Button>
    )
    const actions = [
      <Button onClick={this.showHelp} icon>
        help
      </Button>,
      <Button onClick={this.handlePrintClick} icon>
        print
      </Button>
    ]
    const documentTitle =
      this.props.guidebook && this.props.guidebook.name
        ? this.props.guidebook.name
        : 'Print Guidebook'
    return (
      <DocumentTitle title={documentTitle}>
        <div className="hf-print-container">
          <Toolbar
            colored
            className="hf-guidebooks"
            title="Print Guidebook"
            actions={actions}
            nav={nav}
          />
          <div id="print-preview" className="hf-print-preview-sheet">
            <PrintHtmlContainer
              id="paper"
              guidebook={this.props.guidebook}
              intl={this.props.intl}
              printPreferences={this.props.printPreferences}
              dispatch={this.props.dispatch}
              className={
                this.props.printPreferences.settings.hideImages.selected
                  ? 'md-paper--1 hf-print-hide-images'
                  : 'md-paper--1'
              }
            />
          </div>
          <div className="hf-print-config-sheet">
            <ConfigureMenu
              guidebook={this.props.guidebook}
              printPreferences={this.props.printPreferences}
              isLoading={this.props.isLoading}
              dispatch={this.props.dispatch}
            />
          </div>
          <DialogContainer
            id="print-help"
            visible={this.state.modalVisible}
            aria-label="Full page dialog containing help instructions"
            onHide={this.hideHelp}
            focusOnMount={this.props.focusOnMount}
            fullPage
            portal={true}
            lastChild={true}
            renderNode={document.body}
            disableScrollLocking={true}
            className="hf-host-modal"
          >
            {this.renderHelpToolbar()}
            {this.renderHelpContent()}
          </DialogContainer>
        </div>
      </DocumentTitle>
    )
  }
}

Print.propTypes = {
  guidebook: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  // properties used for the modal dialog
  modalVisible: PropTypes.bool,
  focusOnMount: PropTypes.bool,
  containerRef: PropTypes.object
}

Print.defaultProps = {
  modalVisible: false,
  focusOnMount: false,
  leftIcon: '',
  iconColor: 'hfprimaryavatar'
}

export default Print
