import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import { actions } from 'react-redux-form'
import { Button, CircularProgress, SelectField } from 'react-md'
import MultilangWysiwyg from 'components/MultilangWysiwyg'
import { checkVCSStatus, fetchTags } from 'redux/modules/vcs'
import TagChip from './TagChip'

class VoiceForm extends Component {
  constructor(props) {
    super(props)
    this.handleShowVoice = this.handleShowVoice.bind(this)
    this.handleSelectTag = this.handleSelectTag.bind(this)
    this.state = {
      voiceEnabled: this.getShowVoiceDefault() || false,
      showVoice: this.getShowVoiceDefault(),
      voiceTags: null,
      selectedTags: []
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }
  async componentDidMount() {
    this.mounted = true
    fetchTags().then((data) => {
      if (this.mounted) {
        this.setState({
          voiceTags: data,
          selectedTags: this.props.voice_tags || []
        })
      }
    })
    if (!this.state.voiceEnabled) {
      checkVCSStatus().then((data) => {
        if (this.mounted) {
          // if this check came back and we have an email, then this user has connected with VCS
          if (data.email) {
            this.setState({ voiceEnabled: true })
          }
        }
      })
    }
  }

  // read cookie to determine user's preference if any
  getShowVoiceDefault = () => {
    const { cookies } = this.props
    const cookieVal = cookies.get('hf_showVoice')
    const showVoice = cookieVal && cookieVal === 'true'
    return showVoice
  }

  // show hide the lat/lng control and save preference in a cookie
  handleShowVoice = () => {
    const { cookies } = this.props
    const setShowVoiceTo = this.state.showVoice ? !this.state.showVoice : true
    // remember preference with cookie
    const cookieOptions = {
      path: '/',
      expires: 0,
      sameSite: 'strict'
    }
    cookies.set('hf_showVoice', setShowVoiceTo, cookieOptions)
    this.setState({ showVoice: setShowVoiceTo })
  }

  updateTags = (tags) => {
    const { dispatch, editModel } = this.props
    this.setState({ selectedTags: tags }, () => {
      dispatch(actions.change(editModel + '.voice_tags', tags))
    })
  }

  handleSelectTag = (value, _index, _event) => {
    if (this.state.selectedTags.indexOf(value) === -1) {
      const updatedTags = this.state.selectedTags.slice(0)
      updatedTags.push(value)
      this.updateTags(updatedTags)
    }
  }

  removeTag = (removeValue) => {
    if (this.state.selectedTags.indexOf(removeValue) !== -1) {
      const updatedTags = this.state.selectedTags.filter((value) => {
        return value !== removeValue
      })
      this.updateTags(updatedTags)
    }
  }

  renderVoiceTags = () => {
    const self = this
    if (!this.state.voiceTags || this.state.voiceTags.length === 0) {
      return (
        <div>
          <CircularProgress id="loading-tags" />
        </div>
      )
    } else {
      const { selectedTags } = this.state
      const chips = selectedTags.map((tag) => {
        return (
          <TagChip tagName={tag} key={`tag-${tag}`} onClick={self.removeTag} />
        )
      })
      return (
        <div className="md-cell md-cell--12">
          <h3>What voice tag(s) should this card use?</h3>
          <p>These tags help Virtual Concierge better classify this content.</p>
          {chips}
          <div>
            <SelectField
              id="voice-tags"
              menuItems={this.state.voiceTags}
              label="Choose tag(s) to add"
              placeholder="Select a tag"
              className="md-cell"
              onChange={this.handleSelectTag}
            />
          </div>
        </div>
      )
    }
  }

  render() {
    const self = this

    // for now we only do voice in english
    const localeOverride = []
    if (!this.state.voiceEnabled) return null

    if (this.state.showVoice) {
      return (
        <div className="md-grid hf-headline-margin">
          <div className="md-cell md-cell--12">
            <h1 className="md-headline">
              How should this card be read by voice services?
            </h1>
            <p>
              For guidebooks configured to work with a voice service, this field
              will be the main content read by the voice software.
            </p>
            <MultilangWysiwyg
              simpleEditor
              suppressEnter
              max_length={-1}
              rows={4}
              model={this.props.editModel}
              field="voice_content"
              txn_field="voice_content_txn"
              locales={localeOverride}
            />
          </div>
          {this.renderVoiceTags()}
          <div className="md-cell md-cell--12">
            <Button
              raised
              primary
              type="button"
              onClick={(e) => {
                self.handleShowVoice()
              }}
            >
              Hide Voice Fields
            </Button>
          </div>
        </div>
      )
    } else {
      return (
        <div className="md-grid">
          <div className="md-cell md-cell--12 hf-headline-margin">
            <Button
              raised
              primary
              className="hf-button-margin"
              type="button"
              onClick={(e) => {
                self.handleShowVoice()
              }}
            >
              Show Voice Fields
            </Button>
          </div>
        </div>
      )
    }
  }
}

VoiceForm.propTypes = {
  editModel: PropTypes.string.isRequired,
  locales: PropTypes.array
}

VoiceForm.defaultProps = {
  locales: []
}

VoiceForm.contextTypes = {
  model: PropTypes.any // could be a string or tracking function, choose what works in your use case
}

function mapStateToProps(state, props) {
  const { editModel } = props
  const voice_content = state[editModel].voice_content
  const voice_content_txn = state[editModel].voice_content_txn
  const voice_tags = state[editModel].voice_tags
  return {
    voice_content,
    voice_content_txn,
    voice_tags
  }
}

export default withCookies(connect(mapStateToProps)(VoiceForm))
