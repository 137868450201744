import { combineReducers } from 'redux'
import { createForms } from 'react-redux-form'
import { intlReducer } from 'react-intl-redux'
import { routerReducer } from 'react-router-redux'
import auth from './auth'
import guidebook from './guidebook'
import { listReducer, itemReducer } from './crud'
import blankEditStates from './crud/blankEditStates'
import map from './map'
import printPreferences from './printPreferences'
import printReadyState from './printReadyState'
import orbiStatusState from './orbirental/status'
import sponsoredRecs from './sponsoredRecs'
import toast from './toast'

const initSignup = {
  email: '',
  password: ''
}
const initLogin = {
  email: '',
  password: ''
}
const initialLists = {
  bookingsyncrentals: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  orbirentalproperties: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  categories: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  checkins: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  checkouts: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  destinations: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  directions: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  domains: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  guidebooks: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  hostintros: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  informations: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  parkings: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  recommendations: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  templates: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  themes: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  tokens: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  user_settings: {
    isLoading: false,
    hasErrored: false,
    data: []
  },
  wifis: {
    isLoading: false,
    hasErrored: false,
    data: []
  }
}

export default combineReducers({
  auth,
  guidebook,
  map,
  printPreferences,
  printReadyState,
  orbiStatusState,
  sponsoredRecs,
  toast,
  list: listReducer(initialLists),
  edit_item: itemReducer(blankEditStates),
  ...createForms({
    signup: initSignup,
    login: initLogin,
    edit_bookingsyncrental: {
      locales: [],
      address: {},
      informations: [],
      recommendations: []
    },
    edit_category: {},
    edit_checkin: { locales: [], guidebooks: [] },
    edit_checkout: { locales: [], guidebooks: [] },
    edit_directions: { locales: [], guidebooks: [] },
    edit_domain: {},
    edit_guidebook: {
      is_template: false,
      locales: [],
      address: {},
      informations: [],
      recommendations: []
    },
    edit_hostintro: { locales: [], guidebooks: [] },
    edit_information: { locales: [], template: 3, guidebooks: [] },
    edit_orbirentalproperty: {
      locales: [],
      address: {},
      informations: [],
      recommendations: []
    },
    edit_parking: { locales: [], guidebooks: [] },
    edit_recommendation: { locales: [], address: {}, guidebooks: [] },
    edit_template: {
      is_template: true,
      locales: [],
      address: {},
      informations: [],
      recommendations: []
    },
    edit_theme: { locales: [], guidebooks: [] },
    edit_token: { locales: [], guidebooks: [] },
    edit_user_setting: {},
    edit_wifi: { locales: [], guidebooks: [] }
  }),
  intl: intlReducer,
  router: routerReducer
})
