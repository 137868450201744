import React from 'react'

const Public = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`public`}</title>
    <path d="M17.906 17.391A7.912 7.912 0 0020.015 12a7.983 7.983 0 00-5.016-7.406v.422c0 1.078-.938 1.969-2.016 1.969h-1.969v2.016c0 .563-.469.984-1.031.984H8.014v2.016h6a.96.96 0 01.984.984v3h.984c.891 0 1.641.609 1.922 1.406zm-6.89 2.531V18C9.938 18 9 17.062 9 15.984V15l-4.781-4.781A7.404 7.404 0 003.985 12c0 4.078 3.094 7.453 7.031 7.922zM12 2.016c5.531 0 9.984 4.453 9.984 9.984S17.531 21.984 12 21.984 2.016 17.531 2.016 12 6.469 2.016 12 2.016z" />
  </svg>
)

export default Public
