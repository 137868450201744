import React from 'react'

const Fireextinguisher = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`fireextinguisher`}</title>
    <path d="M11.3 14c0 .3.2.5.5.5.5 0 .8-.4.8-.8 0-.2-.1-.5-.2-.7l-.8.5c-.1.2-.3.3-.3.5z" />
    <path d="M15.3 7.7V2.3l-2 2H9.2c-.3 0-.5.2-.5.5v.5c0 .3.2.5.5.5h1.5v.8c-.8.3-1.3 1-1.3 1.8v12.7c0 .3.2.5.5.5h4.3c.2 0 .3-.1.3-.3V8.2c0-.8-.6-1.6-1.3-1.8v-.7l2.1 2zM12 15.2c-.7 0-1.3-.6-1.3-1.3v-.1c0-.5.2-1.1.5-1.4 0 .4.3.7.7.7.3 0 .5-.2.5-.5v-.2c0-.3-.1-.6-.2-.9.6.5 1 1.3 1 2.2v.2c0 .6-.5 1.2-1.2 1.3z" />
  </svg>
)

export default Fireextinguisher
