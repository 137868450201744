import React from 'react'

const Spa = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`spa`}</title>
    <path d="M15.469 9.609A14.591 14.591 0 0012 12.281a14.591 14.591 0 00-3.469-2.672c.234-2.766 1.359-5.438 3.516-7.594 2.109 2.109 3.234 4.781 3.422 7.594zm-13.453.375c4.461 0 8.051 2.542 9.984 5.484 1.936-2.946 5.524-5.484 9.984-5.484 0 5.297-3.328 9.797-8.016 11.484-.656.234-1.266.375-1.969.516a8.66 8.66 0 01-1.969-.516C5.342 19.78 2.014 15.28 2.014 9.984z" />
  </svg>
)

export default Spa
