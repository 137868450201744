import React from 'react'

const Bedroom = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`bedroom`}</title>
    <path d="M23 11.4v-1h-1.3v-1h.8l-.5-1h-1l-.5 1h.8v.9H20V8.9c0-.3-.2-.5-.5-.5H4.7c-.3 0-.5.2-.5.5v1.4H2.8v-1h.8l-.5-1H2l-.5 1h.8v1H1v1h3.1v.5h-.5V16h.5v1h.5v-1h14.7v1h.5v-1h.5v-4.2h-.5v-.5H23zm-3.8.7H4.7c-.2 0-.4-.2-.4-.4s.2-.4.4-.4h.8c-.2-.1-.3-.3-.3-.5 0-.4.3-.7.7-.7h5c.4 0 .7.3.7.7 0 .2-.1.4-.3.5h1.4c-.2-.1-.3-.3-.3-.5 0-.4.3-.7.7-.7h4.8c.4 0 .7.3.7.7 0 .2-.1.4-.3.5h.8c.2 0 .4.2.4.4s-.2.4-.4.4h.1z" />
  </svg>
)

export default Bedroom
