import 'whatwg-fetch'
import querystring from 'querystring'

export function airportSearch(location) {
  const params = {
    query: location
  }
  const url =
    process.env.REACT_APP_MOZIO_API_URL +
    '/v2/locations/airports?' +
    querystring.stringify(params)
  // const url = `https://api.mozio.com/v2/locations/airports?${querystring.stringify(params)}`;
  const apiKey = process.env.REACT_APP_MOZIO_API_KEY
  // const apiKey = `076006a9ef274e408f58723c0f1412a1`;
  const options = {
    headers: {
      'API-KEY': apiKey
    }
  }
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
}
