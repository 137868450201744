import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import HostNavDrawer from './HostNavDrawer'

const propTypes = {
  dispatch: PropTypes.func.isRequired
}

const defaultProps = {}

class HostNavDrawerContainer extends Component {
  render() {
    // allow setting initial
    return <HostNavDrawer {...this.props}>{this.props.children}</HostNavDrawer>
  }
}

HostNavDrawerContainer.propTypes = propTypes
HostNavDrawerContainer.defaultProps = defaultProps

function mapStateToProps(state, props) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(injectIntl(HostNavDrawerContainer))
