/*eslint no-unused-vars: ["off", { "ignoreRestSiblings": true }]*/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import { Checkbox } from 'react-md'
import { Control } from 'react-redux-form'

class CheckBoxForm extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(e) {
    let { model, dispatch } = this.props
    dispatch(actions.change(model, e))
  }
  render() {
    const checkbox = (props) => {
      const { model, dispatch, ...rest } = props
      return <Checkbox id={props.name} {...rest} />
    }

    return (
      <Control.checkbox
        label={this.props.label}
        model={this.props.model}
        className={this.props.className}
        component={checkbox}
      />
    )
  }
}
export default connect()(CheckBoxForm)
