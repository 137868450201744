import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, injectIntl, intlShape } from 'react-intl'
import PrintContentBlock from 'components/Print/PrintContentBlock'
import Checkout from '../../../pages/Guidebook/pages/MainView/components/Checkout'
import { buildGuidebookUrl } from 'utils/Urls'

class Departure extends Component {
  _formatMessage = this.props.intl.formatMessage

  _messages = defineMessages({
    checkingOut: {
      id: 'print.checkingOut',
      defaultMessage: 'Checking Out'
    }
  })

  render() {
    const gbShape = {
      key: this.props.guidebook.key || 'loading',
      is_secure: this.props.guidebook.is_secure,
      token: this.props.guidebook.token_data
    }

    const domain =
      this.props.guidebook.domain &&
      this.props.guidebook.domain.status === 'approved'
        ? this.props.guidebook.domain.domain
        : null

    return (
      <div>
        {this.props.guidebook.checkout ? (
          <PrintContentBlock
            title={this._formatMessage(this._messages.checkingOut)}
            leftIcon="playlist_add_check"
            styles={this.props.styles ? this.props.styles : null}
            avoidBreak={true}
            showQR={this.props.showQR}
            QRLink={`${buildGuidebookUrl(this.props.guidebook.key, null, null, false, domain, this.props.guidebook.is_published)}/departure`}
          >
            <Checkout
              guidebook={gbShape}
              checkout={this.props.guidebook.checkout}
              viewMode="print"
            />
          </PrintContentBlock>
        ) : null}
      </div>
    )
  }
}

Departure.propTypes = {
  guidebook: PropTypes.object,
  styles: PropTypes.object,
  intl: intlShape.isRequired,
  showQR: PropTypes.bool
}

Departure.defaultProps = {
  showQR: false
}

export default injectIntl(Departure)
