import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { intlShape } from 'react-intl'
import { Button, DialogContainer, Media, SelectField, Toolbar } from 'react-md'
import { goBack } from 'react-router-redux'

import PrintHtmlContainer from 'components/Print/PrintHtmlContainer'
import { setGuestPrintSections } from 'redux/modules/printPreferences'
import { addToast } from 'redux/modules/toast'

class Print extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPrinting: false,
      modalVisible: props.modalVisible
    }
    this.back = this.back.bind(this)
    this.setGuestPrintSettings = this.setGuestPrintSettings.bind(this)
    this.showHelp = this.showHelp.bind(this)
    this.hideHelp = this.hideHelp.bind(this)
    this.handlePrintClick = this.handlePrintClick.bind(this)
    this.doPrint = this.doPrint.bind(this)
  }

  back = () => {
    const { dispatch } = this.props
    dispatch(goBack())
  }

  setGuestPrintSettings = (value) => {
    const { dispatch, printPreferences } = this.props
    const guestSections = printPreferences.guestSections

    if (value === 'arrival') {
      guestSections.map(function (section) {
        section.selected = section.name === 'arrival' ? true : false
        return dispatch(setGuestPrintSections({ guestSections }))
      })
    } else {
      guestSections.map(function (section) {
        section.selected = true
        return dispatch(setGuestPrintSections({ guestSections }))
      })
    }
  }

  showHelp = () => {
    this.setState({ modalVisible: true })
  }

  hideHelp = () => {
    this.setState({ modalVisible: false })
  }

  renderHelpToolbar = () => {
    const action = (
      <Button icon onClick={this.hideHelp}>
        close
      </Button>
    )
    return (
      <Toolbar
        transparent
        actions={action}
        title="How to print your guidebook"
        fixed
      />
    )
  }

  renderHelpContent = () => {
    return (
      <div className="md-toolbar-relative list-item-modal-content">
        <div className="md-grid md-grid--40-16">
          <div className="markdown-container md-text-container md-cell md-cell--12">
            <h1>Print from your browser</h1>
            <p>
              Your browser has a built in print feature which we use to print
              your guidebook. We can't control exactly what your printed
              guidebook will look like because every browser and device has
              slightly different implementations of print. For best results we
              recommend using Chrome with A4 paper and the default margins.
            </p>
            <p>
              Our print feature allows you to preview and configure your
              guidebook before printing it. In the toolbar above the preview use
              the dropdown to select the content to print, either all or just
              the arrival information:
            </p>
            <div className="hf-help-screenshot">
              <Media aspectRatio="20-13">
                <img
                  src="https://storage.googleapis.com/v2-static/guest-print-overview.png"
                  alt="Screenshot of Hostfully print feature"
                />
              </Media>
            </div>
            <p>
              When you are ready to print, click the print button in the
              toolbar, at the top of the screen, to launch your browser's print
              dialog. From here you'll be able to preview and/or change the
              print settings which we can't control (like paper size, margins
              and dynamic page breaks).
            </p>
            <h2>Select your printer</h2>
            <p>
              Use the destination section of the print dialog to select your
              printer or to save the print job as a PDF. Click the Change...
              button to switch destinations:
            </p>
            <div className="hf-help-screenshot">
              <Media aspectRatio="20-13">
                <img
                  src="https://storage.googleapis.com/v2-static/print-destination.png"
                  alt="Screenshot of default Chrome print dialog"
                />
              </Media>
            </div>
            <h2>Save as a PDF</h2>
            <p>
              Most browsers allow you to save your print as a PDF by either
              choosing it as the destination, as shown here:
            </p>
            <div className="hf-help-screenshot">
              <Media aspectRatio="20-13">
                <img
                  src="https://storage.googleapis.com/v2-static/saveaspdf.png"
                  alt="Screenshot of default Chrome print destination dialog"
                />
              </Media>
            </div>
            <h2>More control over print settings</h2>
            <p>
              You might find that your device allows you to use your printer's
              dialog to print from your browser. This could allow you to access
              more of the features of your printer. In Chrome the link labelled
              "Print using system dialog" will open the dialog for your printer:
            </p>
            <div className="hf-help-screenshot">
              <Media aspectRatio="20-13">
                <img
                  src="https://storage.googleapis.com/v2-static/printsystemdialog.png"
                  alt="Screenshot of default Chrome print system dialog"
                />
              </Media>
            </div>
            <h2>Print problems?</h2>
            <p>
              We can't control exactly what your printed guidebook will look
              like because every browser and device has slightly different
              implementations of print. For best results we recommend using
              Chrome. However if you can't use Chrome or you are using chrome
              but still have issues, reach out so we can try to resolve them:
              email support@hostfully.com
            </p>
          </div>
        </div>
      </div>
    )
  }

  handlePrintClick = (event) => {
    const { dispatch, printReadyState } = this.props
    if (printReadyState.ready) {
      this.doPrint()
    } else {
      //show dialog saying images not loaded yet
      const message =
        "Please wait, we're just waiting for images to load so they will print properly!"
      const action = {
        children: 'Print Anyway',
        raised: true,
        flat: false,
        style: {
          backgroundColor: '#03A9F4',
          height: 'auto',
          marginRight: '24px'
        },
        onClick: () => {
          this.doPrint()
        }
      }
      dispatch(addToast(message, action, false))
    }
  }

  doPrint = () => {
    window.print()
  }

  render() {
    const self = this
    const { printPreferences } = this.props

    //Title menu
    const TITLES = [
      { label: 'Print All', value: 'all' },
      { label: 'Print Arrival Only', value: 'arrival' }
    ]
    const titleMenu = (
      <SelectField
        key="titleMenu"
        id="titles"
        menuItems={TITLES}
        defaultValue={TITLES[0].value}
        onChange={(value) => {
          self.setGuestPrintSettings(value)
        }}
      />
    )
    //Toolbar actions
    const nav = (
      <Button
        onClick={(e) => {
          this.back()
        }}
        icon
      >
        keyboard_backspace
      </Button>
    )
    const actions = [
      <Button onClick={this.showHelp} icon>
        help
      </Button>,
      <Button onClick={this.handlePrintClick} icon>
        print
      </Button>
    ]

    return (
      <div className="hf-print-container">
        <Toolbar
          nav={nav}
          actions={actions}
          titleMenu={titleMenu}
          className="hf-toolbar-guest"
        />
        <div id="guest-print-preview" className="hf-print-preview-sheet">
          <PrintHtmlContainer
            id="paper"
            guest={true}
            guidebook={this.props.guidebook}
            intl={this.props.intl}
            printPreferences={printPreferences}
            dispatch={this.props.dispatch}
            className={
              printPreferences.settings.hideImages.selected
                ? 'md-paper--1 hf-print-hide-images'
                : 'md-paper--1'
            }
          />
        </div>
        <DialogContainer
          id="print-help"
          visible={this.state.modalVisible}
          aria-label="Full page dialog containing help instructions"
          onHide={this.hideHelp}
          focusOnMount={this.props.focusOnMount}
          fullPage
          className="hf-host-modal"
        >
          {this.renderHelpToolbar()}
          {this.renderHelpContent()}
        </DialogContainer>
      </div>
    )
  }
}

Print.propTypes = {
  printPreferences: PropTypes.object,
  guidebook: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  // properties used for the modal dialog
  modalVisible: PropTypes.bool,
  focusOnMount: PropTypes.bool,
  containerRef: PropTypes.object
}

Print.defaultProps = {
  modalVisible: false,
  focusOnMount: false,
  leftIcon: '',
  iconColor: 'hfprimaryavatar'
}

export default Print
