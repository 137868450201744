import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Avatar, FontIcon, ListItem } from 'react-md'

class WifiListItem extends Component {
  render() {
    const { item, editItem, deleteItem, pluralName } = this.props
    const link = (
      <Link
        to={'/host/' + pluralName + '/' + item.id}
        onClick={(e) => {
          e.stopPropagation()
        }}
        className="md-tile-text--primary md-text hf-fake-link"
      >
        {item.label}
      </Link>
    )

    return (
      <ListItem
        onClick={editItem}
        className={'hf-list-item hf-' + pluralName + 'list-item'}
        leftAvatar={
          <Link
            to={'/host/' + pluralName + '/' + item.id}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <Avatar
              role="presentation"
              className={'hf-' + pluralName + 'list-item'}
            >
              {item.label.charAt(0).toUpperCase()}
            </Avatar>
          </Link>
        }
        primaryText={link}
        secondaryText={item.network_name || ' '}
        rightIcon={<FontIcon onClick={deleteItem}>delete</FontIcon>}
      />
    )
  }
}

WifiListItem.propTypes = {
  item: PropTypes.object.isRequired
}
export default WifiListItem
