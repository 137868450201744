import { createBrowserHistory } from 'history'
import queryString from 'query-string'
import React, { Component } from 'react'
import { CookiesProvider } from 'react-cookie'
import { addLocaleData } from 'react-intl'
import arLocaleData from 'react-intl/locale-data/ar'
import daLocaleData from 'react-intl/locale-data/da'
import deLocaleData from 'react-intl/locale-data/de'
import elLocaleData from 'react-intl/locale-data/el'
import enLocaleData from 'react-intl/locale-data/en'
import esLocaleData from 'react-intl/locale-data/es'
import frLocaleData from 'react-intl/locale-data/fr'
import heLocaleData from 'react-intl/locale-data/he'
import itLocaleData from 'react-intl/locale-data/it'
import jaLocaleData from 'react-intl/locale-data/ja'
import nlLocaleData from 'react-intl/locale-data/nl'
import plLocaleData from 'react-intl/locale-data/pl'
import ptLocaleData from 'react-intl/locale-data/pt'
import ruLocaleData from 'react-intl/locale-data/ru'
import viLocaleData from 'react-intl/locale-data/vi'
import zhLocaleData from 'react-intl/locale-data/zh'
import { Provider } from 'react-intl-redux'
import { Redirect, Route, Switch } from 'react-router'
import { ConnectedRouter } from 'react-router-redux'
import WebFont from 'webfontloader'

import OpenBetaBanner from './components/OpenBetaBanner'
import ScrollToTop from './components/Scroll/ScrollToTop'
import { messagesForLocale } from './components/Translator/localeMessages'
import UserIsAuthenticated from './components/UserIsAuthenticated'
import { nativeLanguageNames } from './constants/UIConstants'
import InitUser from './InitUser'
import NotAuthorized from './pages/ErrorPages/NotAuthorized'
import NotFound from './pages/ErrorPages/NotFound'
import ExternalLogin from './pages/ExternalLogin'
import GetStarted from './pages/GetStarted'
import Guidebook from './pages/Guidebook'
import HealthCheck from './pages/HealthCheck'
import HostDashboard from './pages/Host'
import Login from './pages/Login'
import Logout from './pages/Logout'
import Signup from './pages/Signup'
import Store from './redux/create'
import { findDomain } from './redux/modules/domains'
import { parseBaseDomain } from './utils/Urls'

import './App.scss'

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700', 'Material Icons']
  }
})

addLocaleData([
  ...arLocaleData,
  ...daLocaleData,
  ...deLocaleData,
  ...elLocaleData,
  ...enLocaleData,
  ...esLocaleData,
  ...frLocaleData,
  ...heLocaleData,
  ...itLocaleData,
  ...jaLocaleData,
  ...nlLocaleData,
  ...plLocaleData,
  ...ptLocaleData,
  ...ruLocaleData,
  ...viLocaleData,
  ...zhLocaleData
])

const apiUrl = process.env.REACT_APP_API_URL
const initialLocale = localStorage.getItem('hf_locale')
const initialMessages = messagesForLocale(initialLocale)
const initialState = {
  guidebook: {
    data: {
      key: 'loading',
      name: 'Hostfully',
      address: {},
      directions: {},
      checkin: {},
      checkout: {},
      host_intro: {},
      wifi: {
        wifi_provided: false
      },
      informations: [],
      informationsByCategory: {},
      recommendations: [],
      recommendationsByCategory: {}
    }
  },
  sponsoredRecs: {},
  intl: {
    defaultLocale: initialLocale || 'en-US',
    locale: initialLocale || 'en-US',
    messages: initialMessages
  },
  toast: []
}
const browserHistory = createBrowserHistory()
const StoreInstance = Store(initialState, browserHistory)

const localeRoutes = () => {
  // return nativeLanguageNames.map()
  return Object.keys(nativeLanguageNames).map((item) => {
    const short_locale = item.substring(0, 2)
    return (
      <Route
        path={`/${short_locale}/:guidebookKey`}
        component={Guidebook}
        key={`${short_locale}-route`}
      />
    )
  })
}

// for some reason
// <Redirect from"/guidebooks/:guidebookKey" to="/:guidebookKey"/>
// isn't working as advertized
const RedirectGuidebook = ({ match }) => {
  if (match && match.params && match.params.guidebookKey) {
    return <Redirect to={'/' + match.params.guidebookKey} />
  }
  return <Redirect to="/" />
}

const RedirectHostDashboard = ({ match }) => {
  return <Redirect to="/host/guidebooks" />
}

const RedirectLegacyExternal = ({ match }) => {
  if (match && match.params && match.params.partnerName) {
    return (
      <Redirect
        to={'/partner/' + match.params.partnerName + window.location.search}
      />
    )
  }
  return <Redirect to="/" />
}

class Main extends Component {
  componentDidMount() {
    browserHistory.listen((location) => {
      this.checkDomain()
    })
  }

  // this basically prevents people from using the backend with a custom domain
  checkDomain = (guidebook) => {
    const defaultUrl = parseBaseDomain(process.env.REACT_APP_BASE_URL)
    const thisUrl = parseBaseDomain(window.location.href)
    const matches = thisUrl === defaultUrl
    // if the domain being used does not match the base domain
    // then we're using a custom domain
    if (!matches) {
      const path = window.location.href.split(thisUrl).pop()
      const nonGuidebookPaths = [
        '/_ah/health',
        '/404',
        '/403',
        '/428',
        '/host',
        '/admin',
        '/signup',
        '/login',
        '/logout',
        '/api/v1/auth/token/',
        '/partner/'
      ]
      let prefixCheck = false
      for (var i = 0; i < nonGuidebookPaths.length; i++) {
        if (path.startsWith(nonGuidebookPaths[i])) {
          prefixCheck = true
          break
        }
      }

      if (prefixCheck) {
        // TODO - need to leverage the domain's 'default path'
        findDomain(thisUrl).then((response) => {
          // by default, redirect back to v2.hostfully.com main page
          let redirectUrl = window.location.href
            .replace('http://', '')
            .replace('https://', '')
            .replace(thisUrl, process.env.REACT_APP_BASE_URL)
          // if we found a custom domain, look for a default path
          if (
            response.data &&
            response.data.default_type === 1 &&
            response.data.default_path.trim().length > ''
          ) {
            redirectUrl = response.data.default_path.trim()
          } else if (
            response.data &&
            response.data.default_type === 0 &&
            response.data.default_path.trim().length > ''
          ) {
            redirectUrl = `${thisUrl}/${response.data.default_path}`
          } else {
            redirectUrl = window.location.href
              .replace('http://', '')
              .replace('https://', '')
              .replace(thisUrl, process.env.REACT_APP_BASE_URL)
          }
          if (
            redirectUrl.indexOf('https://') === -1 &&
            redirectUrl.indexOf('https://') === -1
          ) {
            redirectUrl = `https://${redirectUrl}`
          }
          window.location.href = redirectUrl
        })
      }
    }
  }

  render() {
    this.checkDomain()

    return (
      <Provider store={StoreInstance}>
        <CookiesProvider>
          <InitUser>
            <ConnectedRouter history={browserHistory}>
              <div id="mainContainer">
                <OpenBetaBanner />
                <ScrollToTop>
                  <Switch>
                    <Route exact path="/" component={RedirectHostDashboard} />
                    <Route exact path="/_ah/health" component={HealthCheck} />
                    <Route exact path="/404" component={NotFound} />
                    <Route exact path="/403" component={NotAuthorized} />
                    <Route exact path="/428" component={NotAuthorized} />
                    <Route
                      path="/host"
                      component={UserIsAuthenticated(HostDashboard)}
                    />
                    <Route
                      path="/admin"
                      component={UserIsAuthenticated(HostDashboard)}
                    />
                    <Route
                      exact
                      path="/getstarted"
                      component={UserIsAuthenticated(GetStarted)}
                    />
                    <Route exact path="/signup" component={Signup} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/logout" component={Logout} />
                    <Route
                      exact
                      path="/api/v1/auth/token/:partnerName"
                      component={RedirectLegacyExternal}
                    />
                    <Route
                      exact
                      path="/partner/:partnerName"
                      render={({ match }) => {
                        const { partnerName } = match.params
                        const parsed = queryString.parse(window.location.search)
                        const { token } = parsed
                        const guidebookRef = parsed.ref
                        return (
                          <ExternalLogin
                            partnerName={partnerName}
                            token={token}
                            guidebookRef={guidebookRef}
                          />
                        )
                      }}
                    />
                    <Route
                      path="/guidebooks/:guidebookKey"
                      component={RedirectGuidebook}
                    />
                    <Route
                      path="/(r|recs)/:recsOnlyKey"
                      render={({ match }) => {
                        return (
                          <Guidebook
                            match={match}
                            recsOnly={true}
                            recsOnlyKey={match.params.recsOnlyKey}
                          />
                        )
                      }}
                    />
                    {localeRoutes()}
                    <Route
                      path="/s/:shortLink"
                      render={({ match }) => {
                        window.location.href = `${apiUrl}${match.url}`
                        return null
                      }}
                    />
                    <Route path="/:guidebookKey" component={Guidebook} />
                  </Switch>
                </ScrollToTop>
              </div>
            </ConnectedRouter>
          </InitUser>
        </CookiesProvider>
      </Provider>
    )
  }
}

export default Main
