import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Paper } from 'react-md'
import { externalLoginUser } from 'redux/modules/auth'
import { fetchGuidebookDataForProvider } from 'redux/modules/guidebook'
import { push } from 'react-router-redux'

class ExternalLogin extends Component {
  constructor(props) {
    super(props)
    // keep the login status in this state, in case they were already logged in as another user.
    this.state = {
      authComplete: false
    }
  }

  componentDidMount() {
    // call redux to attempt auto login
    const { token, partnerName, dispatch } = this.props
    dispatch(externalLoginUser(partnerName, token))
  }

  componentWillReceiveProps(nextProps, nextState) {
    const { partnerName, guidebookRef, dispatch } = this.props
    const nextAuth = nextProps.auth
    // if login has succeeded, then redirect to the appropriate guidebook, or dashboard if no ref specified
    if (nextAuth.isAuthenticated && !this.state.authComplete) {
      this.setState({ authComplete: true })
      if (partnerName === 'bookingsync') {
        dispatch(push('/host/bookingsyncrentals'))
      } else if (guidebookRef) {
        // redirect to guidebook
        fetchGuidebookDataForProvider(partnerName, guidebookRef)
          .then(function (guidebookData) {
            if (guidebookData && guidebookData.id) {
              dispatch(push('/host/guidebooks/' + guidebookData.id))
            } else {
              if (partnerName === 'orbirental') {
                dispatch(push('/host/orbirentalproperties'))
              } else {
                dispatch(push('/host'))
              }
            }
          })
          .catch(function (err) {
            if (partnerName === 'orbirental') {
              dispatch(push('/host/orbirentalproperties'))
            } else {
              dispatch(push('/host'))
            }
          })
      } else {
        if (partnerName === 'orbirental') {
          dispatch(push('/host/orbirentalproperties'))
        } else {
          dispatch(push('/host'))
        }
      }
    }
  }

  render() {
    const { externalAuthMessage } = this.props.auth
    const heading = externalAuthMessage ? 'Unable to log in' : 'Please wait...'
    const message = externalAuthMessage
      ? externalAuthMessage
      : 'Logging you in...'
    return (
      <Paper
        key="notfound"
        className="hf-box-wrapper hf-container-full hf-page-not-found"
      >
        <div className="hf-box md-grid">
          <div className="md-cell md-cell--12">
            <h1>{heading}</h1>
          </div>
          <div className="md-cell md-cell--12">
            <h6 className="md-title hf-white-text hf-wrap-title">{message}</h6>
          </div>
        </div>
      </Paper>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    auth: state.auth,
    guidebook: state.guidebook
  }
}

export default connect(mapStateToProps)(ExternalLogin)
