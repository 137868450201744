import React from 'react'

const ChromeReaderMode = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`chrome_reader_mode`}</title>
    <path d="M21 18.984V6h-9v12.984h9zm0-15c1.078 0 2.016.938 2.016 2.016v12.984C23.016 20.062 22.078 21 21 21H3c-1.078 0-2.016-.938-2.016-2.016V6c0-1.078.938-2.016 2.016-2.016h18zm-8.016 10.5h7.031v1.5h-7.031v-1.5zm0-4.968h7.031v1.5h-7.031v-1.5zm0 2.484h7.031v1.5h-7.031V12z" />
  </svg>
)

export default ChromeReaderMode
