import React from 'react'

const PanTool = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`pan_tool`}</title>
    <path d="M23.016 5.484v14.531c0 2.203-1.828 3.984-4.031 3.984h-7.266a4.042 4.042 0 01-2.859-1.172L.985 14.811s1.266-1.219 1.313-1.219a1.27 1.27 0 01.797-.281c.234 0 .422.047.609.141.047 0 4.313 2.438 4.313 2.438V3.984c0-.844.656-1.5 1.5-1.5s1.5.656 1.5 1.5v7.031h.984V1.499c0-.844.656-1.5 1.5-1.5s1.5.656 1.5 1.5v9.516h.984V2.484c0-.844.656-1.5 1.5-1.5s1.5.656 1.5 1.5v8.531h1.031V5.484c0-.844.656-1.5 1.5-1.5s1.5.656 1.5 1.5z" />
  </svg>
)

export default PanTool
