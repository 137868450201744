import React from 'react'

const LocalFlorist = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`local_florist`}</title>
    <path d="M12 5.484c-1.359 0-2.484 1.172-2.484 2.531s1.125 2.484 2.484 2.484 2.484-1.125 2.484-2.484S13.359 5.484 12 5.484zm-6.422 4.782c0-.984.609-1.828 1.453-2.25-.844-.422-1.453-1.266-1.453-2.25 0-1.359 1.172-2.531 2.531-2.531.516 0 .984.188 1.406.469v-.188c0-1.359 1.125-2.531 2.484-2.531s2.484 1.172 2.484 2.531v.188c.422-.281.891-.469 1.406-.469 1.359 0 2.531 1.172 2.531 2.531 0 .984-.609 1.828-1.453 2.25.844.422 1.453 1.266 1.453 2.25 0 1.359-1.172 2.484-2.531 2.484a2.48 2.48 0 01-1.406-.422v.188c0 1.359-1.125 2.484-2.484 2.484s-2.484-1.125-2.484-2.484v-.188a2.483 2.483 0 01-1.406.422c-1.359 0-2.531-1.125-2.531-2.484zM12 21.984c-4.969 0-9-4.031-9-9 4.969 0 9 4.031 9 9zm0 0c0-4.969 4.031-9 9-9 0 4.969-4.031 9-9 9z" />
  </svg>
)

export default LocalFlorist
