import React from 'react'

const VpnKey = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`vpn_key`}</title>
    <path d="M6.984 14.016C8.062 14.016 9 13.078 9 12s-.938-2.016-2.016-2.016-1.969.938-1.969 2.016.891 2.016 1.969 2.016zm5.672-4.032h10.359v4.031h-2.016v3.984h-3.984v-3.984h-4.359c-.797 2.344-3.047 3.984-5.672 3.984-3.328 0-6-2.672-6-6s2.672-6 6-6c2.625 0 4.875 1.641 5.672 3.984z" />
  </svg>
)

export default VpnKey
