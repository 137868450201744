import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { Avatar, Card, CardTitle, FontIcon, List } from 'react-md'
import { connect } from 'react-redux'

import ListItemModal from 'components/ListItemModal'
import AsHtml from 'components/Translator/AsHtml'
import { checkinDefaults } from 'constants/SharedPropDefaults'
import {
  checkinShape,
  hostInfoShape,
  wifiShape
} from 'constants/SharedPropTypes'
import { checkinMessages } from 'constants/UIConstants'
import { translateString } from 'utils/I18nHelper'
import { cleanUpTextOnly, transformCustomVariables } from 'utils/Strings'

import WifiListItemModal from './WifiListItemModal'

import PrintSubsection from '../../../PrintView/PrintSubsection'

const key = <FontIcon>vpn_key</FontIcon>
const propTypes = {
  viewMode: PropTypes.string,
  checkin: checkinShape,
  wifi: wifiShape,
  host_intro: hostInfoShape,
  guidebook: PropTypes.shape({
    id: PropTypes.number,
    host_user_id: PropTypes.number,
    key: PropTypes.string,
    is_secure: PropTypes.bool,
    token: PropTypes.object
  }),
  custom_color: PropTypes.string,
  intl: intlShape.isRequired
}

const defaultProps = {
  viewMode: 'web',
  checkin: checkinDefaults
}

class Checkin extends PureComponent {
  formatMessage = this.props.intl.formatMessage

  buildTextString = (currentString, newString) => {
    if (currentString.length > 0) {
      if (currentString.trim().slice(-1) !== '.') {
        currentString = currentString.trim() + '. '
      } else {
        currentString = currentString.trim() + ' '
      }
      currentString += newString
    } else {
      currentString = newString
    }
    return currentString
  }

  render() {
    const { checkin, guidebook } = this.props
    let checkInTime = ''
    if (checkin && !checkin.use_checkin_end_time && checkin.checkin_time) {
      checkInTime =
        this.formatMessage(checkinMessages.checkInTime, {
          timeValue: checkin.checkin_time
        }) + ' '
    } else if (
      checkin &&
      checkin.use_checkin_end_time &&
      checkin.checkin_time &&
      checkin.checkin_end_time
    ) {
      checkInTime =
        this.formatMessage(checkinMessages.checkInRange, {
          startTime: checkin.checkin_time,
          endTime: checkin.checkin_end_time
        }) + ' '
    }

    let checkInDate = ''
    if (
      guidebook.token &&
      guidebook.token.data &&
      guidebook.token.data.checkin_date
    ) {
      checkInDate =
        this.formatMessage(checkinMessages.checkInDate, {
          dateValue: guidebook.token.data.checkin_date
        }) + ' '
    }
    const earlyCheckinNever =
      checkin && checkin.early_checkin_never
        ? this.formatMessage(checkinMessages.earlyCheckinNever) + ' '
        : null

    const earlyCheckinSometimes =
      checkin && checkin.early_checkin_sometimes
        ? this.formatMessage(checkinMessages.earlyCheckinSometimes) + ' '
        : null

    const earlyCheckinAlternative =
      checkin && checkin.early_checkin_alternative
        ? this.formatMessage(checkinMessages.earlyCheckinAlternative) + ' '
        : null

    let earlyCheckinCustomHTML = null
    let earlyCheckinCustomText = null
    if (checkin && checkin.early_checkin_text) {
      earlyCheckinCustomHTML = (
        <AsHtml
          text={checkin.early_checkin_text}
          translations={checkin.early_checkin_text_txn}
        />
      )
      earlyCheckinCustomText = translateString(
        this.props.intl.locale,
        checkin.early_checkin_text,
        checkin.early_checkin_text_txn
      )
      //strip out HTML for listText
      earlyCheckinCustomText = cleanUpTextOnly(earlyCheckinCustomText)
    }

    const bagStorageAllowed =
      checkin && checkin.bag_storage_allowed
        ? this.formatMessage(checkinMessages.bagStorageAllowed)
        : null

    const selfAccessLockbox =
      checkin && checkin.self_access_lockbox
        ? this.formatMessage(checkinMessages.selfAccessLockbox) + ' '
        : null

    const selfAccessKeyless =
      checkin && checkin.self_access_keyless
        ? this.formatMessage(checkinMessages.selfAccessKeyless) + ' '
        : null

    let accessPhoto = null
    if (checkin && checkin.access_image) {
      const containerStyle = { width: '80%' }
      accessPhoto = (
        <div style={containerStyle}>
          <img className="access-image" src={checkin.access_image} alt="" />
        </div>
      )
    }

    const selfAccessMeet =
      checkin && checkin.self_access_meet
        ? this.formatMessage(checkinMessages.selfAccessMeet) + ' '
        : null

    const selfAccessCodes =
      checkin && checkin.self_access_codes
        ? this.formatMessage(checkinMessages.selfAccessCodes) + ' '
        : null

    const selfAccessEmail =
      checkin && checkin.self_access_email
        ? this.formatMessage(checkinMessages.selfAccessEmail) + ' '
        : null

    const isSecure = guidebook.is_secure
    let checkinHTML = null
    let checkinText = null
    const token_data =
      guidebook.token && guidebook.token.data ? guidebook.token.data : {}
    const variableData = { token: token_data }

    if (checkin && (checkin.checkin_text || checkin.checkin_text_secure)) {
      if (
        isSecure &&
        checkin.checkin_has_secure_info &&
        checkin.checkin_text_secure
      ) {
        checkinHTML = (
          <AsHtml
            text={checkin.checkin_text_secure}
            translations={checkin.checkin_text_secure_txn}
            variableData={variableData}
          />
        )
        checkinText = translateString(
          this.props.intl.locale,
          checkin.checkin_text_secure,
          checkin.checkin_text_secure_txn
        )
        //strip out HTML for listText
        checkinText = cleanUpTextOnly(checkinText)
        checkinText = transformCustomVariables(checkinText, variableData)
      } else {
        checkinHTML = (
          <AsHtml
            text={checkin.checkin_text}
            translations={checkin.checkin_text_txn}
            variableData={variableData}
          />
        )
        checkinText = translateString(
          this.props.intl.locale,
          checkin.checkin_text,
          checkin.checkin_text_txn
        )
        //strip out HTML for listText
        checkinText = cleanUpTextOnly(checkinText)
        checkinText = transformCustomVariables(checkinText, variableData)
      }
    }

    let secondaryCheckin = ''
    if (earlyCheckinNever) secondaryCheckin += earlyCheckinNever + ' '
    if (earlyCheckinSometimes)
      secondaryCheckin = this.buildTextString(
        secondaryCheckin,
        earlyCheckinSometimes
      )
    if (earlyCheckinAlternative)
      secondaryCheckin = this.buildTextString(
        secondaryCheckin,
        earlyCheckinAlternative
      )
    if (earlyCheckinCustomText)
      secondaryCheckin = this.buildTextString(
        secondaryCheckin,
        earlyCheckinCustomText
      )

    const secondaryCheckinHtml =
      checkInTime ||
      earlyCheckinNever ||
      earlyCheckinSometimes ||
      earlyCheckinAlternative ||
      earlyCheckinCustomText
        ? [
            <div key="checkinhtml">
              {checkInDate ? <p className="md-body-1">{checkInDate}</p> : null}
              {checkInTime ? <p className="md-body-1">{checkInTime}</p> : null}
              {earlyCheckinNever ? (
                <p className="md-body-1">{earlyCheckinNever}</p>
              ) : null}
              {earlyCheckinSometimes ? (
                <p className="md-body-1">{earlyCheckinSometimes}</p>
              ) : null}
              {earlyCheckinAlternative ? (
                <p className="md-body-1">{earlyCheckinAlternative}</p>
              ) : null}
              {earlyCheckinCustomHTML ? (
                <p className="md-body-1">{earlyCheckinCustomHTML}</p>
              ) : null}
            </div>
          ]
        : null // needs to be in an array for some reason

    const matchParams = this.context.router.route.match.params
    const deepLinkedCard = matchParams.card_title
      ? matchParams.card_title
      : null
    const modalAvatarStyle = this.props.custom_color
      ? { background: this.props.custom_color }
      : {}

    const checkinListItem =
      secondaryCheckin || secondaryCheckinHtml ? (
        <ListItemModal
          avatarStyle={modalAvatarStyle}
          baseLocation={`/${this.props.guidebook.key}/arrival`}
          cardTitle="check-in"
          guidebook={this.props.guidebook}
          leftIcon={<FontIcon>access_time</FontIcon>}
          modalVisible={deepLinkedCard === 'check-in'}
          primaryText={checkInTime}
          secondaryText={secondaryCheckin}
          tabName="arrival"
          threeLines
          title={this.formatMessage(checkinMessages.Checkin)}
          trackLabel="checkin time"
        >
          {secondaryCheckinHtml}
        </ListItemModal>
      ) : null

    const bagStorageListItem = bagStorageAllowed ? (
      <ListItemModal
        avatarStyle={modalAvatarStyle}
        baseLocation={`/${this.props.guidebook.key}/arrival`}
        cardTitle="bag-storage"
        guidebook={this.props.guidebook}
        leftIcon={<FontIcon>work</FontIcon>}
        modalVisible={deepLinkedCard === 'bag-storage'}
        primaryText={this.formatMessage(checkinMessages.bagStorage)}
        secondaryText={bagStorageAllowed}
        showMore={false}
        tabName="arrival"
        title={this.formatMessage(checkinMessages.bagStorage)}
        trackLabel="bag storage"
      >
        {bagStorageAllowed}
      </ListItemModal>
    ) : null

    let access = ''
    if (selfAccessLockbox) access += selfAccessLockbox + ' '
    if (selfAccessMeet) access = this.buildTextString(access, selfAccessMeet)
    if (selfAccessKeyless)
      access = this.buildTextString(access, selfAccessKeyless)
    if (selfAccessCodes) access = this.buildTextString(access, selfAccessCodes)
    if (selfAccessEmail) access = this.buildTextString(access, selfAccessEmail)
    if (checkinText) access = this.buildTextString(access, checkinText)

    const accessHtml =
      selfAccessLockbox ||
      selfAccessMeet ||
      selfAccessKeyless ||
      selfAccessCodes ||
      selfAccessEmail ||
      accessPhoto ||
      checkinText
        ? [
            <div key="accesshtml">
              {selfAccessLockbox ? <p>{selfAccessLockbox}</p> : null}
              {selfAccessMeet ? <p>{selfAccessMeet}</p> : null}
              {selfAccessKeyless ? <p>{selfAccessKeyless}</p> : null}
              {selfAccessCodes ? <p>{selfAccessCodes}</p> : null}
              {selfAccessEmail ? <p>{selfAccessEmail}</p> : null}
              {accessPhoto ? <div>{accessPhoto}</div> : null}
              {checkinHTML ? <p className="md-body-1">{checkinHTML}</p> : null}
            </div>
          ]
        : null // needs to be in an array for some reason

    const accessListItem =
      access || accessHtml ? (
        <ListItemModal
          avatarStyle={modalAvatarStyle}
          baseLocation={`/${this.props.guidebook.key}/arrival`}
          cardTitle="access"
          guidebook={this.props.guidebook}
          leftIcon={<FontIcon>lock_open</FontIcon>}
          modalVisible={deepLinkedCard === 'access'}
          primaryText={this.formatMessage(checkinMessages.gainingAccess)}
          secondaryText={access}
          tabName="arrival"
          threeLines
          trackLabel="gaining access"
        >
          {accessHtml}
        </ListItemModal>
      ) : null

    const wifiItem = this.props.wifi ? (
      <WifiListItemModal
        isSecure={isSecure}
        avatarStyle={modalAvatarStyle}
        wifi={this.props.wifi}
        host_intro={this.props.host_intro}
        viewMode={this.props.viewMode}
        guidebook={this.props.guidebook}
      />
    ) : null

    if (this.props.viewMode === 'print') {
      const printTimeHeader = checkInDate
        ? checkInDate
        : this.formatMessage(checkinMessages.Checkin)
      return (
        <div>
          <PrintSubsection header={printTimeHeader}>
            {checkInTime}
            {earlyCheckinNever ? <p>{earlyCheckinNever}</p> : null}
            {earlyCheckinSometimes ? <p>{earlyCheckinSometimes}</p> : null}
            {earlyCheckinAlternative ? <p>{earlyCheckinAlternative}</p> : null}
            {earlyCheckinCustomHTML ? <p>{earlyCheckinCustomHTML}</p> : null}
            {bagStorageAllowed ? <p>{bagStorageAllowed}</p> : null}
          </PrintSubsection>
          <PrintSubsection
            header={this.formatMessage(checkinMessages.addtlCheckinInfo)}
          >
            {selfAccessLockbox ? <p>{selfAccessLockbox}</p> : null}
            {selfAccessMeet ? <p>{selfAccessMeet}</p> : null}
            {selfAccessKeyless ? <p>{selfAccessKeyless}</p> : null}
            {selfAccessCodes ? <p>{selfAccessCodes}</p> : null}
            {selfAccessEmail ? <p>{selfAccessEmail}</p> : null}
            {accessPhoto ? <div>{accessPhoto}</div> : null}
            {checkinHTML ? <p>{checkinHTML}</p> : null}
          </PrintSubsection>
        </div>
      )
    } else {
      const avatarStyle = { background: this.props.custom_color || '#f44336' }
      let secondaryText = this.formatMessage(checkinMessages.Arrival)
      if (checkInDate) {
        secondaryText = checkInDate
      }
      return (
        <Card className="md-cell md-cell--12 arrival arrival-card" raise={true}>
          <CardTitle
            avatar={<Avatar icon={key} style={avatarStyle} alt="" />}
            title={this.formatMessage(checkinMessages.Checkin)}
            subtitle={secondaryText}
          />
          <List>
            {checkinListItem}
            {accessListItem}
            {bagStorageListItem}
            {wifiItem}
          </List>
        </Card>
      )
    }
  }
}

Checkin.propTypes = propTypes
Checkin.defaultProps = defaultProps
Checkin.contextTypes = {
  router: PropTypes.object
}

export default connect()(injectIntl(Checkin))
