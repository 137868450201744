import Breakjs from 'breakjs'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import {
  Avatar,
  CardTitle,
  Divider,
  FontIcon,
  ListItem,
  Media,
  MediaOverlay,
  NavigationDrawer,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import AccountDrawer from 'components/AccountDrawer'
import formatAddress from 'components/Address/FormatAddress'
import GuidebookStatusBar from 'components/GuidebookStatusBar'
import logo from 'components/Header/logo.svg'
import logoWhite from 'components/Header/logo_white.svg'
import HostInfo from 'components/HostInfo'
import LocaleSwitcher from 'components/LocaleSwitcher'
import Search from 'components/Search'
import Toast from 'components/Toast'
import { labelMessages } from 'constants/UIConstants'
import currentUser from 'utils/CurrentUser'
import { debounce } from 'utils/Debounce'
import { resizeImage } from 'utils/Image'

import GuestNavItems from './GuestNavItems'

const propTypes = {
  dispatch: PropTypes.func,
  showNavDrawer: PropTypes.bool,
  onClose: PropTypes.func,
  recsOnly: PropTypes.bool
}

const defaultProps = {
  recsOnly: false
}

class NavDrawer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      accountDrawerVisible: !!this.props.showNavDrawer,
      navDrawerVisible: this.getNavDrawerVisible(),
      navDrawerType: this.getNavDrawerType(),
      localesShown: false,
      forceLocales: false,
      logoShown: true,
      avatarShown: true
    }
    this.toggleNavDrawer = this.toggleNavDrawer.bind(this)
    this.toggleAccountDrawer = this.toggleAccountDrawer.bind(this)
    this.drawerClosed = this.drawerClosed.bind(this)
    this.print = this.print.bind(this)
    this.handleWindowResize = debounce(this.handleWindowResize.bind(this), 100)
    this.forceLocales = this.forceLocales.bind(this)
    this.localesShown = this.localesShown.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showNavDrawer !== this.state.accountDrawerVisible) {
      this.setState({
        accountDrawerVisible: nextProps.showNavDrawer
      })
    }
  }

  componentDidMount() {
    // listen to window resize (debounced) and add a handler
    window.addEventListener('resize', this.handleWindowResize, 100)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize, 100)
  }

  handleWindowResize = () => {
    const shouldBeVisible = this.getNavDrawerVisible()
    if (shouldBeVisible !== this.state.navDrawerVisible) {
      this.setState({
        navDrawerVisible: shouldBeVisible
      })
    }

    const shouldHaveDrawerType = this.getNavDrawerType()
    if (shouldHaveDrawerType !== this.state.navDrawerType) {
      this.setState({
        navDrawerType: shouldHaveDrawerType
      })
    }
  }

  getFirstVisit() {
    const firstVisit = localStorage.getItem('hf_firstVisit')

    if (!firstVisit) {
      localStorage.setItem('hf_firstVisit', false)
    }

    return !firstVisit
  }

  getNavDrawerType() {
    var breakLayout = Breakjs({
      mobile: 0,
      phablet: 550,
      superwide: 1500
    })
    if (breakLayout.atLeast('superwide'))
      return NavigationDrawer.DrawerTypes.CLIPPED
    if (breakLayout.atMost('mobile'))
      return NavigationDrawer.DrawerTypes.TEMPORARY
    return NavigationDrawer.DrawerTypes.TEMPORARY
  }

  getNavDrawerVisible() {
    var breakLayout = Breakjs({
      mobile: 0,
      phablet: 550,
      wide: 1368
    })
    const showWelcome = window.location.search.indexOf('welcome') !== -1
    // only show on sizes where this is not always open if this is the first visit to this guidebook
    return (
      showWelcome ||
      breakLayout.atLeast('wide') ||
      (breakLayout.atMost('wide') && this.getFirstVisit())
    )
  }

  toggleNavDrawer() {
    this.setState({
      navDrawerVisible: !this.state.navDrawerVisible
    })
  }

  toggleAccountDrawer(state) {
    this.setState({
      accountDrawerVisible: state
    })
  }

  toggleSearch = (value) => {
    // this makes room for search on mobile
    var logobreak = Breakjs({
      hideStuff: 0,
      showStuff: 500
    })
    if (logobreak.atMost('hideStuff')) {
      this.setState({ logoShown: !value, avatarShown: !value })
    }
  }

  drawerClosed(isOpen) {
    if (this.props.onClose) {
      this.props.onClose(isOpen)
    }
  }

  print = () => {
    this.props.dispatch(push('/' + this.props.guidebook.key + '/print'))
  }

  getGuidebookName = () => {
    const { guidebook, recsOnly } = this.props
    if (recsOnly) {
      if (guidebook.recs_only_name) {
        return guidebook.recs_only_name
      } else {
        return ''
      }
    } else if (guidebook) {
      if (guidebook.name) {
        return guidebook.name
      } else if (guidebook.address) {
        const { addressPrimary } = formatAddress(guidebook.address)
        return addressPrimary
      }
    }
    return null
  }

  getLogo(isTitle) {
    const { guidebook } = this.props
    const hfLogo = guidebook.id ? logo : logoWhite
    const logoImg = guidebook.theme?.logo || hfLogo

    return (
      <div
        key="logo"
        className={`hf-logo-container ${isTitle ? 'hf-logo-title' : ''}`}
      >
        <a
          href={guidebook.theme?.website || null}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logoImg} alt="logo" className="hf-logo" />
        </a>
        {!guidebook.id && (
          <div className="hf-logo-subtitle">Digital Guidebooks</div>
        )}
      </div>
    )
  }

  localesShown = (value) => {
    // if forceLocales is true, and value is true, then stay as true
    const forceLocales = this.state.forceLocales && value
    this.setState({ localesShown: value, forceLocales: forceLocales })
  }

  forceLocales = () => {
    this.setState({ forceLocales: true })
  }

  render() {
    const self = this
    const { avatarShown } = this.state
    const title = this.getLogo(true)
    const {
      auth: { isAuthenticated: isHost },
      guidebook,
      recsOnly,
      showNav,
      intl: { formatMessage }
    } = this.props
    const user = currentUser()
    const locales = guidebook?.locales || ['en-US']
    const hasLanguages = locales.length > 1
    const toolbarActions = []
    const printMsg = formatMessage(labelMessages.print)
    const navItems = []
    // if locales menu or guidebook status bar is shown, we need some extra offset classes
    const toolbarClass =
      this.state.localesShown ||
      !guidebook.is_published ||
      guidebook.is_template
        ? 'hf-toolbar-guest md-toolbar--locale-offset'
        : 'hf-toolbar-guest'
    const childrenClass =
      this.state.localesShown ||
      !guidebook.is_published ||
      guidebook.is_template
        ? 'md-locale-offset'
        : ''
    const drawerClass =
      this.state.localesShown ||
      !guidebook.is_published ||
      guidebook.is_template
        ? 'hf-drawer-guest md-drawer--locale-offset drawer'
        : 'hf-drawer-guest drawer'
    const useOverlay = this.state.navDrawerType === 'temporary'
    let listingMedia = null
    let hostIntro = null

    const drawerHeaderChildren = [this.getLogo()]

    // add search to toolbar
    // but only if we're looking at a guidebook
    if (guidebook?.key !== 'loading') {
      toolbarActions.push(<Search onOpenClose={this.toggleSearch} />)
    }

    // make sure the host has an avatar that is NOT the legacy placeholder
    if (isHost && avatarShown) {
      const stylePointer = { cursor: 'pointer' }

      toolbarActions.push(
        <Avatar
          id="menu"
          src={user.user?.avatar}
          icon={<FontIcon>account_circle</FontIcon>}
          role="presentation"
          onClick={() =>
            this.toggleAccountDrawer(!this.state.accountDrawerVisible)
          }
          style={stylePointer}
        />
      )
    } else if (!isHost && avatarShown && hasLanguages) {
      const styleLanguage = { cursor: 'pointer', marginBottom: '0' }
      const fontColorGrey = { color: 'rgba(0,0,0,0.54)', fontSize: '36px' }

      toolbarActions.push(
        <Avatar
          id="menu"
          suffix="white"
          icon={<FontIcon style={fontColorGrey}>language</FontIcon>}
          onClick={this.forceLocales}
          style={styleLanguage}
        />
      )
    }

    if (!recsOnly && guidebook?.host_intro) {
      const token_data = guidebook?.token_data?.data || {}

      hostIntro = (
        <HostInfo
          host_intro={guidebook.host_intro}
          is_secure={guidebook.is_secure}
          token={token_data}
        />
      )
    }

    if (guidebook) {
      let image = null
      let imageAlt = null

      if (recsOnly && guidebook.recs_only_image) {
        image = resizeImage(guidebook.recs_only_image, 320, 240, true)
        imageAlt = guidebook.recs_only_name || 'recommendations'
      } else {
        image = resizeImage(guidebook.image, 320, 240, true)
        imageAlt = 'Featured view of the listing'
      }

      listingMedia = (
        <Media aspectRatio="4-3">
          <img src={image} alt={imageAlt} role="presentation" />
          <MediaOverlay>
            <CardTitle title={this.getGuidebookName()}></CardTitle>
          </MediaOverlay>
        </Media>
      )
    }

    if (guidebook) {
      navItems.push(
        <li
          key="listingMedia"
          className="md-list-item md-list-item--nested-container"
        >
          {listingMedia}
        </li>
      )

      if (!recsOnly) {
        navItems.push(<div key="hostnav">{hostIntro}</div>)
      }

      navItems.push(<Divider key="host_divider" />)
      navItems.push(
        <GuestNavItems
          key="guestnav"
          guidebook={guidebook}
          recsOnly={recsOnly}
        />
      )

      if (!recsOnly) {
        navItems.push(
          <ListItem
            key="print"
            onClick={() => this.print()}
            leftIcon={<FontIcon>print</FontIcon>}
            primaryText={printMsg}
          />
        )
      }
    } else {
      navItems.push(<div key="404">No Guidebook to Display</div>)
    }

    // hide "about hostfully" if using custom domain
    if (!this.props.guidebook.domain) {
      navItems.push(
        <a
          key="hfLink"
          target="_blank"
          href="https://www.hostfully.com/pricing/digital-guidebooks/"
          rel="noopener noreferrer"
          className="hf-link hf-external-link hf-about"
        >
          {formatMessage(labelMessages.makeOwn)}
        </a>
      )
    } else {
      navItems.push(
        <div key="aboutHf" className="hf-about md-body-1">
          {formatMessage(labelMessages.poweredBy)} Hostfully
        </div>
      )
    }

    if (showNav) {
      return (
        <NavigationDrawer
          drawerHeaderChildren={drawerHeaderChildren}
          navItems={navItems}
          drawerChildren={null}
          drawerTransitionDuration={0}
          transitionDuration={0}
          drawerClassName={drawerClass}
          drawerType={this.state.navDrawerType}
          constantDrawerType={true}
          overlay={useOverlay}
          visible={this.state.navDrawerVisible}
          defaultVisible={false}
          onVisibilityChange={this.toggleNavDrawer}
          toolbarActions={toolbarActions}
          contentId="main-desktop"
          toolbarThemeType="default"
          toolbarClassName={toolbarClass}
          toolbarTitle={title}
        >
          <GuidebookStatusBar />
          <LocaleSwitcher
            forceShown={this.state.forceLocales}
            onShown={(e) => {
              self.localesShown(true)
            }}
            onHidden={(e) => {
              self.localesShown(false)
            }}
          />
          <div className={childrenClass}>
            <AccountDrawer
              guidebook={guidebook}
              visible={this.state.accountDrawerVisible}
              onVisibilityChange={this.toggleAccountDrawer}
              onLanguageClick={this.forceLocales}
              onClose={this.toggleAccountDrawer}
            />
            {this.props.children}
          </div>
          <Toast />
        </NavigationDrawer>
      )
    }

    return (
      <div id="main-desktop">
        <Toolbar
          actions={toolbarActions}
          title={title}
          className="hf-toolbar hf-toolbar-public"
          fixed
        />
        <AccountDrawer
          guidebook={guidebook}
          visible={this.state.accountDrawerVisible}
          onVisibilityChange={this.toggleAccountDrawer}
          onClose={this.toggleAccountDrawer}
        />
        <div className="md-toolbar-relative">{this.props.children}</div>
        <Toast />
      </div>
    )
  }
}

NavDrawer.propTypes = propTypes
NavDrawer.defaultProps = defaultProps

export default connect()(NavDrawer)
