export const SET_PRINT_READY_STATE = 'SET_PRINT_READY_STATE'
export const CLEAR_PRINT_READY_STATE = 'CLEAR_PRINT_READY_STATE'

const initialState = {
  ready: false,
  items: {}
}

/* Reducers */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PRINT_READY_STATE:
      const printReadyState = state
      const item = {}
      item[action.id] = action.state
      const items = Object.assign(printReadyState.items, item)
      // iterate each item to check if all are ready
      var ready = true
      const keys = Object.keys(items)
      for (var k in keys) {
        if ({}.hasOwnProperty.call(keys, k)) {
          const key = keys[k]
          const val = items[key]
          if (val !== true) {
            ready = false
            break
          }
        }
      }
      const newPrintReadyState = {
        ready,
        items
      }
      const newState = Object.assign({}, state, newPrintReadyState)
      return newState
    case CLEAR_PRINT_READY_STATE:
      state.printReadyState.ready = false
      state.printReadyState.items = {}
      return state
    default:
      return state
  }
}

/* Actions */
export function setPrintReadyState(id, state) {
  return {
    type: SET_PRINT_READY_STATE,
    id,
    state
  }
}

export function clearPrintReadyState(state) {
  return {
    type: CLEAR_PRINT_READY_STATE,
    state
  }
}
