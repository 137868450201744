import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import { push } from 'react-router-redux'
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc'
import { fetchItems } from 'redux/modules/crud'
import { saveOrder } from 'redux/modules/categories'
import { addToast } from 'redux/modules/toast'
import { Avatar, FontIcon, ListItem, Paper, Toolbar } from 'react-md'
import { sortData, matchData } from 'utils/Data'
import { allIcons } from 'constants/UIIcons'

import TooltipButton from 'components/TooltipButton'

class ReorderCategories extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      categories: []
    }
    this.onSortEnd = this.onSortEnd.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(
      fetchItems('categories', (response) => {
        this.setState({
          categories: sortData(response, 'order'),
          loading: false
        })
      })
    )
  }

  goBack = () => {
    const { dispatch } = this.props
    dispatch(
      fetchItems('categories', (response) => {
        dispatch(push('/host/categories'))
      })
    )
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { dispatch } = this.props
    const { categories } = this.state
    const ordered_cats = arrayMove(categories, oldIndex, newIndex)
    this.setState({ categories: ordered_cats }, function () {
      const new_order = this.createOrderParams(ordered_cats)
      saveOrder(new_order).then((response) => {
        dispatch(addToast('Category order saved!'))
      })
    })
  }

  createOrderParams = (ordered_cats) => {
    // console.log(ordered_cats);
    if (ordered_cats.length === 0) return []
    const category_params = ordered_cats.map((cat, idx) => {
      return { category_id: cat.id, category_order: idx }
    })
    return category_params
  }

  renderLoading = () => {
    return <div className="md-cell md-cell--12">Loading...</div>
  }

  renderCategorySorter = () => {
    const SortableItem = SortableElement(({ category }) => {
      let avatarIcon = null
      let iconMatch = matchData(
        allIcons,
        'Icons',
        ['field', 'v1_name'],
        category.icon
      )
      if (iconMatch) {
        const IconComponent = iconMatch.icon
        avatarIcon = <IconComponent fill="#ffffff" />
      }
      const category_color = category.color || '#cccccc'
      const avatarStyle = { backgroundColor: category_color }

      return (
        <ListItem
          key={category.id}
          className="hf-list-item hf-categorieslist-item"
          leftAvatar={
            <Avatar
              className="hf-categorieslist-item"
              style={avatarStyle}
              alt=""
            >
              <FontIcon>{avatarIcon}</FontIcon>
            </Avatar>
          }
          primaryText={category.name}
          secondaryText={category.description || ' '}
          rightIcon={<FontIcon>reorder</FontIcon>}
          threeLines
        />
      )
    })

    const SortableList = SortableContainer(({ categories }) => {
      return (
        <div>
          {categories.map((category, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              category={category}
            />
          ))}
        </div>
      )
    })

    return (
      <div className="md-cell md-cell--12">
        <SortableList
          categories={this.state.categories}
          onSortEnd={this.onSortEnd}
        />
      </div>
    )
  }

  render() {
    const self = this
    const title = 'Re-order Categories'
    const nav = (
      <TooltipButton
        key="nav"
        onClick={self.goBack}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        flat
      >
        <FontIcon>keyboard_backspace</FontIcon>
      </TooltipButton>
    )
    const actions = []
    const containerStyle = { minHeight: '400px', paddingBottom: '100px' }
    const paperStyle = { minHeight: '400px' }

    let categoryForm = null
    if (this.state.loading) {
      categoryForm = this.renderLoading()
    } else {
      categoryForm = this.renderCategorySorter()
    }

    return (
      <div>
        <DocumentTitle title={title}>
          <div className="hf-categories-paper" style={containerStyle}>
            <Toolbar
              colored
              className="hf-categories"
              title={title}
              nav={nav}
              actions={actions}
            />
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--8-desktop md-cell--2-desktop-offset md-cell--8-tablet md-cell--4-phone">
                <Paper
                  key="category"
                  className="hf-form-wrapper"
                  style={paperStyle}
                >
                  <h4>
                    Drag the categories into the desired order. They will save
                    automatically.
                  </h4>
                  {categoryForm}
                </Paper>
              </div>
            </div>
          </div>
        </DocumentTitle>
      </div>
    )
  }
}

export default connect()(ReorderCategories)
