import React from 'react'

const Toys = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`toys`}</title>
    <path d="M12 12c0 3-2.484 5.484-5.484 5.484S.985 15 .985 12h11.016zm0 0c-3 0-5.484-2.484-5.484-5.484S9 .985 12 .985v11.016zm0 0c3 0 5.484 2.484 5.484 5.484S15 23.015 12 23.015V11.999zm0 0c0-3 2.484-5.484 5.484-5.484S23.015 9 23.015 12H11.999z" />
  </svg>
)

export default Toys
