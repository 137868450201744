import React from 'react'

const MusicFestival = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`music_festival`}</title>
    <path d="M18.594 3L12.25 9.344c.023.86.129 1.777.75 2.094L19.406 5H22V3zm-8.532 4.219a1 1 0 00-.5.093c-1.136.516-1.984 1.602-2 2.875-.007.61-.457 1.126-1.062 1.126-2.145 0-5.316 2.128-4.281 5.374C2.566 17.777 4.598 21 7.563 21a4.319 4.319 0 004.312-4.313c0-.597.434-1.066 1.031-1.062.969.008 1.79-.648 2.063-1.531.097-.313-.14-.637-.469-.656-1.406-.083-2.156-.473-2.625-1.063-.727-.91-.86-2.012-.875-4.156a1.022 1.022 0 00-.938-1zM9 12l2 2-1 1-2-2zm-2 2l2 2-1 1-2-2z" />
  </svg>
)

export default MusicFestival
