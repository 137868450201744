import React from 'react'

const Info = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`info`}</title>
    <path d="M12.984 9V6.984h-1.969V9h1.969zm0 8.016v-6h-1.969v6h1.969zm-.984-15c5.531 0 9.984 4.453 9.984 9.984S17.531 21.984 12 21.984 2.016 17.531 2.016 12 6.469 2.016 12 2.016z" />
  </svg>
)

export default Info
