import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PrintMap from 'components/PrintMap'
import GuidebookMarker from 'components/MarkerHelpers/GuidebookMarker'
import { resizeImage } from 'utils/Image'
import { defineMessages, intlShape } from 'react-intl'
import PrintReadyImage from 'components/Print/PrintReadyImage'

class GuidebookMapWOverlay extends Component {
  _formatMessage = this.props.intl.formatMessage

  _messages = defineMessages({
    imageAlt: {
      id: 'gb.imageAlt',
      defaultMessage: 'Featured View of Listing'
    }
  })

  renderHeaderMapMarkers = (newGb) => {
    if (!newGb.id) {
      return []
    }
    const gbColor =
      this.props.printPreferences && this.props.printPreferences.color
        ? this.props.printPreferences.color
        : null
    let markers = GuidebookMarker(newGb, 50, true, gbColor, null, newGb.theme)

    return markers
  }

  render() {
    if (this.props.isLoading || !this.props.guidebook.id) {
      // only return once props are loaded,
      // otherwise the map gets stuck at san francisco
      return null
    } else {
      if (
        this.props.guidebook.address.lat &&
        this.props.guidebook.address.lng
      ) {
        const marker = this.renderHeaderMapMarkers(this.props.guidebook)

        const listingImage = resizeImage(
          this.props.guidebook.image,
          260,
          260,
          true
        )
        // add a default in case we don't have an image
        const altImage = this._formatMessage(this._messages.imageAlt)

        const overlay = this.props.guidebook.image ? (
          <div className="md-grid md-grid--no-spacing overlay-anchor">
            <div className="md-text-center hf-map-img-overlay-wrap">
              <div className="map-circle-overlay">
                <PrintReadyImage
                  className="hf-map-img-overlay"
                  imageId="gb-feature-image"
                  imageUrl={listingImage}
                  imageAlt={altImage}
                />
              </div>
            </div>
          </div>
        ) : null

        return (
          <div>
            {overlay}
            <PrintMap
              className="hf-print-map-container-listing"
              guidebook={this.props.guidebook}
              initialZoom={15}
              fitType={null}
              mapMarkers={marker}
              mapId="title-map"
            />
          </div>
        )
      }
    }
  }
}

GuidebookMapWOverlay.propTypes = {
  guidebook: PropTypes.object,
  intl: intlShape.isRequired,
  printPreferences: PropTypes.object
}

export default GuidebookMapWOverlay
