import React from 'react'

const Extension2 = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`extension2`}</title>
    <path d="M20.484 11.016c1.359 0 2.531 1.125 2.531 2.484s-1.172 2.484-2.531 2.484h-1.5v4.031a1.981 1.981 0 01-1.969 1.969h-3.797v-1.5c0-1.5-1.219-2.672-2.719-2.672S7.78 18.984 7.78 20.484v1.5H3.983a1.981 1.981 0 01-1.969-1.969v-3.797h1.5c1.5 0 2.672-1.219 2.672-2.719S5.014 10.78 3.514 10.78h-1.5V6.983c0-1.078.891-1.969 1.969-1.969h4.031v-1.5c0-1.359 1.125-2.531 2.484-2.531s2.484 1.172 2.484 2.531v1.5h4.031c1.078 0 1.969.891 1.969 1.969v4.031h1.5z" />
  </svg>
)

export default Extension2
