/* eslint import/no-webpack-loader-syntax: off */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

class SvgIcon extends Component {
  render() {
    let styles = {
      fill: this.props.fillColor,
      verticalAlign: 'middle',
      width: this.props.size, // CSS instead of the width attr to support non-pixel units
      height: this.props.size // Prevents scaling issue in IE
    }
    return (
      <svg
        viewBox={this.props.viewBox}
        preserveAspectRatio="xMidYMid meet"
        style={styles}
      >
        <g>
          <ellipse
            fill={this.props.fillColor}
            cx="100"
            cy="100"
            rx="88"
            ry="88.2"
          />
          {this.props.children}
        </g>
      </svg>
    )
  }
}

SvgIcon.propTypes = {
  size: PropTypes.string.isRequired,
  fillColor: PropTypes.string.isRequired,
  viewBox: PropTypes.string.isRequired
}

export default SvgIcon
