import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Avatar, FontIcon, ListItem } from 'react-md'
import { filterData } from 'utils/Data'
import { allIcons } from 'constants/UIIcons'

class InformationListItem extends Component {
  render() {
    const { item, editItem, deleteItem, pluralName } = this.props
    const link = (
      <Link
        to={'/host/' + pluralName + '/' + item.id}
        onClick={(e) => {
          e.stopPropagation()
        }}
        className="md-tile-text--primary md-text hf-fake-link"
      >
        {item.name ? item.name : item.title}
      </Link>
    )
    const secondary = item.title ? item.title : ' '
    let catSpan = null
    if (item.category) {
      const catName =
        item.category.name.charAt(0).toUpperCase() + item.category.name.slice(1)
      const catColor = item.category.color
        ? {
            backgroundColor: item.category.color,
            color: '#ffffff',
            padding: '2px 6px',
            borderRadius: '5px'
          }
        : {}
      catSpan = (
        <span className={`rec-list-category ${catName}`} style={catColor}>
          {catName}
        </span>
      )
    }
    let secureSpan = item.secure_access_only ? (
      <span className="item-list-secure">
        <FontIcon>security</FontIcon> Secure
      </span>
    ) : null
    const fullSecondary = (
      <div>
        <span>{secondary}</span>
        <br />
        {secureSpan} {catSpan}
      </div>
    )
    const selectedIcon = item.icon
      ? filterData(allIcons, 'Icons', ['field', 'v1_name'], item.icon)
      : []
    let showIcon = null
    if (selectedIcon.length > 0) {
      const Icon = selectedIcon[0].icon
      showIcon = <Icon fill="#ffffff" />
    }

    return (
      <ListItem
        onClick={editItem}
        className={'hf-list-item hf-' + pluralName + 'list-item'}
        leftAvatar={
          <Link
            to={'/host/' + pluralName + '/' + item.id}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <Avatar icon={showIcon} role="presentation" />
          </Link>
        }
        primaryText={link}
        secondaryText={fullSecondary}
        rightIcon={<FontIcon onClick={deleteItem}>delete</FontIcon>}
      />
    )
  }
}

InformationListItem.propTypes = {
  item: PropTypes.object.isRequired
}
export default InformationListItem
