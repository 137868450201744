import React from 'react'

const Scooter = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`scooter`}</title>
    <path d="M17.5 3A1.5 1.5 0 0016 4.5c0 .176.036.343.092.5H14v2h2.31l.21.56-3.9 3.44H9V9h3V7H2v2h2.24l-.51.45C2.58 10.44 1 11.8 1 14c0 .27.11.52.29.71l.157.16a3.448 3.448 0 00-.424 1.63c0 1.919 1.572 3.5 3.489 3.5 1.746 0 3.19-1.316 3.437-3h8.125c.247 1.684 1.691 3 3.438 3C21.429 20 23 18.419 23 16.5c0-1.466-.92-2.73-2.209-3.246L20.695 13H23v-2h-3.055L18.07 6H20V3h-2.5zM4.512 15A1.48 1.48 0 016 16.5c0 .843-.657 1.5-1.488 1.5a1.48 1.48 0 01-1.489-1.5c0-.843.658-1.5 1.489-1.5zm15 0A1.48 1.48 0 0121 16.5c0 .843-.657 1.5-1.488 1.5a1.48 1.48 0 01-1.489-1.5c0-.843.658-1.5 1.489-1.5z" />
  </svg>
)

export default Scooter
