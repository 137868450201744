import PickerOverlay from 'filestack-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ChromePicker } from 'react-color'
import DocumentTitle from 'react-document-title'
import {
  Avatar,
  Button,
  Card,
  CardText,
  CardTitle,
  Checkbox,
  DialogContainer,
  FontIcon,
  Paper,
  SelectField,
  TextField,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'
import { actions, Errors, Form } from 'react-redux-form'
import { push } from 'react-router-redux'

import CheckBoxForm from 'components/CheckBoxForm'
import CustomTabColor from 'components/CustomTabColor'
import InputTextField from 'components/InputTextField'
import LocaleForm from 'components/LocaleForm'
import MultilangWysiwyg from 'components/MultilangWysiwyg'
import TooltipButton from 'components/TooltipButton'
import TooltipFontIcon from 'components/TooltipFontIcon'
import { hostUIMessages } from 'constants/UIConstants'
import { localeMessages } from 'constants/UIConstants'
import { copyItem, saveItem, showItems } from 'redux/modules/crud'
import blankEditStates from 'redux/modules/crud/blankEditStates'
import { fixRotation } from 'redux/modules/filestack'
import currentUser from 'utils/CurrentUser'
import { stringToId } from 'utils/Strings'
import { fieldHasContent, requiredIf } from 'utils/ValidationHelpers'

import FormCardActions from './FormCardActions'
import ManageSelectedGuidebooks from './ManageSelectedCards'
import ManageGuidebooksAndTemplates from './ManageSelectedCards/ManageGuidebooksAndTemplates'

import TabOrder from '../TabOrder'

class ThemeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collectEmailChecked: false,
      requireEmailChecked: false,
      confirmVisible: false,
      customFieldFormVisible: false,
      customFieldEditIdx: null,
      confirmDeleteField: null,
      customFieldValue: '',
      customFieldTranslations: {},
      customFieldRequired: false,
      customFieldError: '',
      confirmDeleteVisible: false,
      customFieldTranlsationsVisible: false
    }
    this.imageUploaded = this.imageUploaded.bind(this)
    this.goBack = this.goBack.bind(this)
    this.copyCard = this.copyCard.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
    this.handlePrimaryColorChange = this.handlePrimaryColorChange.bind(this)
    this.saveAndAddAnother = this.saveAndAddAnother.bind(this)
    this.handleRequireEmailChange = this.handleRequireEmailChange.bind(this)
    this.handleCollectEmailChange = this.handleCollectEmailChange.bind(this)
    this.handleColorChange = this.handleColorChange.bind(this)
    this.handleResetColors = this.handleResetColors.bind(this)
    this.confirmResetColors = this.confirmResetColors.bind(this)
    this.cancelResetColors = this.cancelResetColors.bind(this)
    this.defaultColors = {}
    this.customFieldRef = null
  }

  componentDidMount() {
    const { dispatch, itemId, guidebookId } = this.props
    this.setDefaultColors()
    if (itemId === 'create') {
      let newItem = blankEditStates.theme.data
      // also if we're making a new one and our "owner" has locales, default to those
      if (this.props.owner_data && this.props.owner_data.locales.length > 0) {
        newItem = Object.assign({}, newItem, {
          locales: this.props.owner_data.locales
        })
      }
      dispatch(actions.load('edit_theme', newItem))
      if (guidebookId) {
        // if creating/editing from a guidebook, select the guidebook by default
        const cardSettings = {}
        cardSettings['edit_theme'] = {}
        cardSettings['edit_theme'][guidebookId] = true
        dispatch(actions.change('card_guidebooks', cardSettings))
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // host enabled the splash screen
    if (
      prevProps.edit_theme &&
      prevProps.edit_theme.show_splash_screen === false &&
      this.props.edit_theme.show_splash_screen === true
    ) {
      // let eventData = {
      //   category: 'Host',
      //   label: this.props.itemId
      // }
      // trackEvent('splashScreenEnabled', eventData);
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    // ugly hack as we don't store a boolean for show_early_checkin_text
    const { edit_theme } = nextProps
    if (
      typeof this.props.edit_theme.splash_require_email === 'undefined' &&
      edit_theme.splash_require_email
    ) {
      this.setState({ requireEmailChecked: edit_theme.splash_require_email })
    }
    if (
      typeof this.props.edit_theme.splash_collect_email === 'undefined' &&
      edit_theme.splash_collect_email
    ) {
      this.setState({ collectEmailChecked: edit_theme.splash_collect_email })
    }
  }

  formatMessage = this.props.intl.formatMessage

  goBack = () => {
    const { dispatch, pluralName, guidebookId, ownerPluralName } = this.props
    if (guidebookId) {
      const path = '#themes#theme'
      dispatch(push(`/host/${ownerPluralName}/${guidebookId}${path}`))
    } else if (window.isIframe) {
      window.history.back()
    } else {
      dispatch(showItems(pluralName))
    }
  }

  copyCard = (id) => {
    const { dispatch, pluralName } = this.props
    dispatch(copyItem(pluralName, id))
  }

  handleEnter = (e) => {
    const { dispatch } = this.props
    e.preventDefault()
    dispatch(actions.submit('edit_theme'))
  }

  handleSubmit(item) {
    const { itemId, dispatch, guidebookId, copy } = this.props
    const id = copy ? null : itemId
    const addAnother = this.addAnother
    this.addAnother = false
    const currentGuidebook = guidebookId ? this.props.owner_data : null
    dispatch(
      saveItem('themes', 'theme', id, item, addAnother, currentGuidebook)
    )
  }

  saveAndAddAnother = (e) => {
    const { dispatch } = this.props
    this.addAnother = true
    dispatch(actions.submit('edit_theme'))
  }

  imageUploaded(uploadResult) {
    const { dispatch } = this.props
    if (uploadResult.filesUploaded && uploadResult.filesUploaded.length > 0) {
      const file = uploadResult.filesUploaded[0]
      const filestackUrl = file.url
      fixRotation(filestackUrl).then((data) => {
        if (data.url && data.url.length > 0) {
          dispatch(actions.change('edit_theme.logo', data.url))
        }
      })
    }
  }

  removeImage = () => {
    const { dispatch } = this.props
    dispatch(actions.change('edit_theme.logo', null))
  }

  itineraryImageUploaded = (uploadResult) => {
    const { dispatch } = this.props
    console.log('here?')
    if (uploadResult.filesUploaded && uploadResult.filesUploaded.length > 0) {
      const file = uploadResult.filesUploaded[0]
      const filestackUrl = file.url
      fixRotation(filestackUrl).then((data) => {
        if (data.url && data.url.length > 0) {
          dispatch(actions.change('edit_theme.itinerary_image', data.url))
        }
      })
    }
  }

  removeItineraryImage = () => {
    const { dispatch } = this.props
    dispatch(
      actions.change(
        'edit_theme.itinerary_image',
        'https://cdn.filestackcontent.com/m2gfGcCtS2mkoXBFD49v'
      )
    )
  }

  handlePrimaryColorChange = (color) => {
    const { dispatch } = this.props
    dispatch(actions.change('edit_theme.primary_color', color.hex))
  }

  handleSponsoredChange = (value) => {
    const { dispatch } = this.props
    dispatch(actions.change('edit_theme.sponsored_count', value))
  }

  handleRadiusChange = (value) => {
    const { dispatch } = this.props
    dispatch(actions.change('edit_theme.sponsored_rec_radius', value))
  }

  formHasErrors = (formErrors) => {
    const edit_form = this.props.edit_form
    if (edit_form.$form.touched) {
      for (var field in formErrors) {
        if (edit_form[field] && !edit_form[field].valid) {
          return false
        }
      }
    }
    return true
  }

  handleRequireEmailChange = (checked) => {
    const { dispatch, edit_theme } = this.props
    this.setState({ requireEmailChecked: checked })
    dispatch(actions.change('edit_theme.splash_require_email', checked))
    // if checking the require emails checkbox,  also check the collect email box
    if (checked && !edit_theme.splash_collect_email) {
      this.setState({ collectEmailChecked: true })
      dispatch(actions.change('edit_theme.splash_collect_email', true))
    }
  }

  handleCollectEmailChange = (checked) => {
    const { dispatch, edit_theme } = this.props
    this.setState({ collectEmailChecked: checked })
    dispatch(actions.change('edit_theme.splash_collect_email', checked))
    // if unchecking the collect email box, also uncheck the require email box
    if (!checked && edit_theme.splash_require_email) {
      this.setState({ requireEmailChecked: false })
      dispatch(actions.change('edit_theme.splash_require_email', false))
    }
  }

  handleColorChange = (key, color) => {
    this.props.dispatch(actions.change(`edit_theme.color_${key}`, color))
  }

  confirmResetColors = () => {
    this.setState({ confirmVisible: true })
  }

  cancelResetColors = () => {
    this.setState({ confirmVisible: false })
  }

  setDefaultColors = () => {
    this.defaultColors = {
      arrival: '#f44336',
      house_manual: '#ff5722',
      departure: '#ff9800',
      marketplace: '#ffc107',
      ai: '#74aa9c',
      places_to_eat: '#ffeb3b',
      activities: '#4caf50',
      attractions: '#03a9f4',
      bars: '#009688',
      other: '#9c27b0',
      shopping: '#3f51b5',
      all: '#e91e63'
    }
  }

  handleResetColors = () => {
    const self = this
    this.setState({ confirmVisible: false }, () => {
      this.props.dispatch(
        actions.change('edit_theme.color_arrival', self.defaultColors.arrival)
      )
      this.props.dispatch(
        actions.change(
          'edit_theme.color_house_manual',
          self.defaultColors.house_manual
        )
      )
      this.props.dispatch(
        actions.change(
          'edit_theme.color_departure',
          self.defaultColors.departure
        )
      )
      this.props.dispatch(
        actions.change(
          'edit_theme.color_marketplace',
          self.defaultColors.marketplace
        )
      )
      this.props.dispatch(
        actions.change('edit_theme.color_ai', self.defaultColors.ai)
      )
      this.props.dispatch(
        actions.change(
          'edit_theme.color_places_to_eat',
          self.defaultColors.places_to_eat
        )
      )
      this.props.dispatch(
        actions.change(
          'edit_theme.color_activities',
          self.defaultColors.activities
        )
      )
      this.props.dispatch(
        actions.change(
          'edit_theme.color_attractions',
          self.defaultColors.attractions
        )
      )
      this.props.dispatch(
        actions.change('edit_theme.color_bars', self.defaultColors.bars)
      )
      this.props.dispatch(
        actions.change('edit_theme.color_other', self.defaultColors.other)
      )
      this.props.dispatch(
        actions.change('edit_theme.color_shopping', self.defaultColors.shopping)
      )
      this.props.dispatch(
        actions.change('edit_theme.color_all', self.defaultColors.all)
      )
    })
  }

  saveCustomField = () => {
    const currentFields = this.props.edit_theme.splash_custom_fields
    const {
      customFieldValue,
      customFieldRequired,
      customFieldEditIdx,
      customFieldTranslations
    } = this.state
    if (customFieldValue.length > 0) {
      this.toggleCustomField()
      let allFields = []
      let nextOrder = 0
      if (currentFields && currentFields.data && currentFields.data.length) {
        nextOrder = currentFields.data.length
        allFields = Object.assign([], currentFields.data)
      }
      let customField = {
        name: customFieldValue,
        name_txn: customFieldTranslations,
        required: customFieldRequired
      }
      if (customFieldEditIdx !== null) {
        customField.order = customFieldEditIdx
        allFields[customFieldEditIdx] = customField
      } else {
        customField.order = nextOrder
        allFields.push(customField)
      }
      const newData = {
        data: allFields
      }
      this.setState(
        {
          customFieldValue: '',
          customFieldTranslations: {},
          customFieldEditIdx: null
        },
        () => {
          this.props.dispatch(
            actions.change('edit_theme.splash_custom_fields', newData)
          )
        }
      )
    } else {
      this.setState({ customFieldError: 'please enter a field name' })
    }
  }
  deleteCustomField = () => {
    const self = this
    const currentFields = this.props.edit_theme.splash_custom_fields
    const { confirmDeleteField } = this.state
    const newFields = []
    for (var i = 0; i < currentFields.data.length; i++) {
      if (i < confirmDeleteField) {
        newFields.push(currentFields.data[i])
      } else if (i > confirmDeleteField) {
        const newField = Object.assign({}, currentFields.data[i])
        newField.order = i - 1
        newFields.push(newField)
      }
    }
    let newData = {}
    if (newFields.length) {
      newData.data = newFields
    }
    this.hideConfirmDeleteField(() => {
      self.props.dispatch(
        actions.change('edit_theme.splash_custom_fields', newData)
      )
    })
  }

  editCustomField = (idx) => {
    const self = this
    const itemToEdit = self.props.edit_theme.splash_custom_fields.data[idx]
    this.setState({
      customFieldValue: itemToEdit.name,
      customFieldTranslations: itemToEdit.name_txn,
      customFieldRequired: itemToEdit.required,
      customFieldEditIdx: idx,
      customFieldFormVisible: true
    })
  }

  reOrderField = (fieldIndex, direction) => {
    const self = this
    const newData = {
      data: []
    }
    let objMoved = null,
      objReplaced = null
    const currentFields = this.props.edit_theme.splash_custom_fields
    if (currentFields.data && currentFields.data.length) {
      for (var i = 0; i < currentFields.data.length; i++) {
        if (i === fieldIndex) {
          objMoved = Object.assign({}, currentFields.data[i])
          objMoved.order = i + direction
        } else if (i === fieldIndex + direction) {
          objReplaced = Object.assign({}, currentFields.data[i])
          objReplaced.order = i - direction
        } else {
          const newObj = Object.assign({}, currentFields.data[i])
          newData.data[i] = newObj
        }
      }
      newData.data[fieldIndex] = objReplaced
      newData.data[fieldIndex + direction] = objMoved
      self.props.dispatch(
        actions.change('edit_theme.splash_custom_fields', newData)
      )
    }
  }

  handleShowTranslations = () => {
    this.setState({
      customFieldTranlsationsVisible: !this.state.customFieldTranlsationsVisible
    })
  }
  toggleCustomField = (a) => {
    if (this.state.customFieldFormVisible) {
      // if closing, clear the fields
      this.setState({
        customFieldFormVisible: false,
        customFieldValue: '',
        customFieldTranslations: {},
        customFieldEditIdx: null
      })
    } else {
      this.setState({ customFieldFormVisible: true })
    }
  }
  handleCustomFieldValueChange = (value) => {
    const error = value.length ? '' : 'please enter a field name'
    this.setState({ customFieldValue: value, customFieldError: error })
  }
  handleTranslationFieldValueChange = (locale, value) => {
    if (locale && typeof value !== 'undefined') {
      const updatedTranslations = Object.assign(
        {},
        this.state.customFieldTranslations
      )
      updatedTranslations[locale] = value
      this.setState({ customFieldTranslations: updatedTranslations })
    }
  }
  handleCustomFieldRequiredChange = (value) => {
    this.setState({ customFieldRequired: value })
  }
  confirmDeleteCustomField = (idx) => {
    this.setState({ confirmDeleteField: idx, confirmDeleteVisible: true })
  }
  hideConfirmDeleteField = (cb) => {
    this.setState(
      { confirmDeleteField: null, confirmDeleteVisible: false },
      () => {
        if (typeof cb === 'function') {
          cb()
        }
      }
    )
  }

  renderCurrentCustomFields = () => {
    const self = this
    const customFields = this.props.edit_theme.splash_custom_fields
    if (customFields && customFields.data && customFields.data.length) {
      const fieldRows = customFields.data.map((item, idx) => {
        const evenOdd = idx % 2 === 1 ? 'even' : 'odd'
        return [
          <div
            className={`md-cell md-cell--4 md-cell--3-tablet md-cell--2-phone row-${evenOdd}`}
            key={`cell-${stringToId(item.name)}-value`}
          >
            {item.name}
          </div>,
          <div
            className={`md-cell md-cell--4 md-cell--2-tablet md-cell--2-phone row-${evenOdd}`}
            key={`cell-${stringToId(item.name)}-required`}
          >
            {item.required ? 'yes' : 'no'}
          </div>,
          <div
            data-order={item.order}
            className={`md-cell md-cell--4 md-cell--3-tablet md-cell--4-phone md-text-right row-${evenOdd}`}
            key={`cell-${stringToId(item.name)}-spacer`}
          >
            {item.order > 0 ? (
              <TooltipFontIcon
                className="action-icon"
                tooltipLabel="Move Up"
                tooltipPosition="top"
                onClick={() => {
                  self.reOrderField(idx, -1)
                }}
              >
                arrow_upward
              </TooltipFontIcon>
            ) : (
              <FontIcon
                className="action-icon disabled"
                onClick={() => {
                  /* nada */
                }}
              >
                arrow_upward
              </FontIcon>
            )}
            {item.order < customFields.data.length - 1 ? (
              <TooltipFontIcon
                className="action-icon"
                tooltipLabel="Move Down"
                tooltipPosition="top"
                onClick={() => {
                  self.reOrderField(idx, 1)
                }}
              >
                arrow_downward
              </TooltipFontIcon>
            ) : (
              <FontIcon
                className="action-icon disabled"
                onClick={() => {
                  /* nada */
                }}
              >
                arrow_downward
              </FontIcon>
            )}
            <TooltipFontIcon
              className="action-icon"
              tooltipLabel="Edit"
              tooltipPosition="top"
              onClick={() => {
                self.editCustomField(idx)
              }}
            >
              edit
            </TooltipFontIcon>
            <TooltipFontIcon
              className="action-icon"
              tooltipLabel="Delete Custom Field"
              tooltipPosition="top"
              onClick={() => {
                self.confirmDeleteCustomField(idx)
              }}
            >
              delete
            </TooltipFontIcon>
          </div>
        ]
      })
      return (
        <div className="md-cell md-cell--12 splash-field-data">
          <div className="md-grid md-grid--no-spacing">
            <div className="md-cell md-cell--4 md-cell--3-tablet md-cell--2-phone row-header">
              <strong>Field Name</strong>
            </div>
            <div className="md-cell md-cell--4 md-cell--2-tablet md-cell--1-phone row-header">
              <strong>Required</strong>
            </div>
            <div className="md-cell md-cell--4 md-cell--3-tablet md-cell--1-phone row-header" />
            {fieldRows}
          </div>
        </div>
      )
    } else {
      return (
        <div className="md-cell md-cell--12">
          You have not defined any custom fields.
        </div>
      )
    }
  }

  renderTranslationFields = () => {
    const self = this
    const visible = this.state.customFieldTranlsationsVisible
    const locales = this.props.edit_theme.locales
    if (visible) {
      const translationFields = locales.map((locale) => {
        const fieldValue =
          self.state.customFieldTranslations &&
          self.state.customFieldTranslations.hasOwnProperty(locale)
            ? self.state.customFieldTranslations[locale]
            : ''
        return (
          <TextField
            id={`customFieldTxn_${locale}`}
            name={`customFieldTxn_${locale}`}
            key={`customFieldTxn_${locale}`}
            label={`Field Name (${self.formatMessage(localeMessages[locale])})`}
            value={fieldValue}
            onChange={(value) => {
              self.handleTranslationFieldValueChange(locale, value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
              }
            }}
          />
        )
      })
      return (
        <div className="md-cell md-cell--12">
          <div>{translationFields}</div>
          <Button flat primary onClick={self.handleShowTranslations}>
            {self.state.customFieldTranlsationsVisible
              ? 'Hide Translations'
              : 'Edit Translations'}
          </Button>
        </div>
      )
    } else if (locales.length > 0) {
      return (
        <div className="md-cell md-cell--12">
          <Button flat primary onClick={self.handleShowTranslations}>
            {self.state.customFieldTranlsationsVisible
              ? 'Hide Translations'
              : 'Edit Translations'}
          </Button>
        </div>
      )
    }
    return null
  }

  renderCustomFields = () => {
    const self = this
    const topMargin = { marginTop: '12px' }
    const addFieldActions = [
      <Button
        key="btn-save-field"
        raised
        primary
        onClick={self.saveCustomField}
      >
        Save
      </Button>,
      <Button
        key="btn-cancel-field"
        raised
        secondary
        onClick={self.toggleCustomField}
      >
        Cancel
      </Button>
    ]

    const addFieldDialog = (
      <DialogContainer
        id="addFieldDialog"
        visible={self.state.customFieldFormVisible}
        actions={addFieldActions}
        onHide={self.toggleCustomField}
        title={self.state.customFieldEditIdx ? 'Edit Field' : 'Add a new Field'}
      >
        <div className="md-grid">
          <TextField
            id="customFieldName"
            name="customFieldName"
            label="Field Name"
            className="md-cell md-cell--12"
            value={self.state.customFieldValue}
            onChange={(value) => {
              self.handleCustomFieldValueChange(value)
            }}
            error={self.state.customFieldError ? true : false}
            errorText={self.state.customFieldError}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
                return self.saveCustomField()
              }
            }}
          />
          {self.renderTranslationFields()}
          <div className="md-cell md-cell--12">
            <Checkbox
              id="customFieldRequired"
              name="customFieldRequired"
              label="required"
              className="hf-taller-selection-control-container hf-wider-selection-control-container"
              onChange={(value) => {
                self.handleCustomFieldRequiredChange(value)
              }}
              checked={self.state.customFieldRequired}
            />
          </div>
        </div>
      </DialogContainer>
    )

    const confirmDeleteActions = [
      <Button
        key="btn-save-field"
        raised
        primary
        onClick={self.deleteCustomField}
      >
        Yes, Delete it.
      </Button>,
      <Button
        key="btn-cancel-field"
        raised
        secondary
        onClick={self.hideConfirmDeleteField}
      >
        Cancel
      </Button>
    ]

    const deleteFieldDialog = (
      <DialogContainer
        id="confirmDeleteField"
        visible={this.state.confirmDeleteVisible}
        actions={confirmDeleteActions}
        onHide={this.hideConfirmDeleteField}
        title="Delete Custom Field?"
      >
        Are you sure you wish to delete this custom field?
      </DialogContainer>
    )

    return (
      <div className="md-grid md-grid--no-spacing" style={topMargin}>
        <div className="md-cell md-cell--12">
          <h3>Custom Fields</h3>
        </div>
        {this.renderCurrentCustomFields()}
        {addFieldDialog}
        {deleteFieldDialog}
        <div className="md-cell md-cell--12" style={topMargin}>
          <Button raised primary onClick={this.toggleCustomField}>
            Add a field
          </Button>
        </div>
      </div>
    )
  }

  renderCustomColors = () => {
    const self = this
    const tabs = [
      {
        name: 'arrival',
        label: 'arrival',
        icon: 'VpnKey',
        color: self.defaultColors.arrival
      },
      {
        name: 'house_manual',
        label: 'house_manual',
        icon: 'Book',
        color: self.defaultColors.house_manual
      },
      {
        name: 'departure',
        label: 'departure',
        icon: 'Assignment',
        color: self.defaultColors.departure
      },
      {
        name: 'ai',
        label: 'ai planner',
        icon: 'Power',
        color: self.defaultColors.ai
      },
      {
        name: 'marketplace',
        label: 'marketplace',
        icon: 'ShoppingCart',
        color: self.defaultColors.marketplace
      },
      {
        name: 'places_to_eat',
        label: 'places_to_eat',
        icon: 'Restaurant',
        color: self.defaultColors.places_to_eat
      },
      {
        name: 'activities',
        label: 'activities',
        icon: 'Activity',
        color: self.defaultColors.activities
      },
      {
        name: 'bars',
        label: 'bars',
        icon: 'LocalBar',
        color: self.defaultColors.bars
      },
      {
        name: 'attractions',
        label: 'attractions',
        icon: 'PhotoCamera',
        color: self.defaultColors.attractions
      },
      {
        name: 'shopping',
        label: 'shopping',
        icon: 'ShoppingBasket',
        color: self.defaultColors.shopping
      },
      {
        name: 'other',
        label: 'other',
        icon: 'Loyalty',
        color: self.defaultColors.other
      },
      { name: 'all', label: 'all', icon: 'Apps', color: self.defaultColors.all }
    ]

    const colorItems = tabs.map((tab) => {
      let avatarColor = tab.color
      if (this.props.edit_theme && this.props.edit_theme[`color_${tab.name}`]) {
        avatarColor = this.props.edit_theme[`color_${tab.name}`]
      }

      return (
        <CustomTabColor
          key={`tab-${tab.name}`}
          name={tab.label}
          icon={tab.icon}
          color={avatarColor}
          onColorChange={(color) => this.handleColorChange(tab.name, color)}
        />
      )
    })
    const resetStyle = { marginTop: '18px' }
    const actions = [
      <Button flat secondary onClick={this.cancelResetColors}>
        CANCEL
      </Button>,
      <Button flat primary onClick={this.handleResetColors}>
        OK
      </Button>
    ]
    return (
      <div className="md-cell md-cell--12 hf-headline-margin">
        <h2 className="md-headline">Adjust the default tab colors</h2>
        <p>
          Click to change color. Only the default tabs and recomendation
          categories will appear here. Any custom categories will have their own
          custom colors already; These can be adjusted using the{' '}
          <a href="/host/categories">categories menu</a>.
        </p>
        <div className="md-grid md-grid--no-spacing">
          {colorItems}
          <div className="md-cell--3 md-cell--2-tablet md-cell--2-phone md-text-center">
            <Button
              onClick={this.confirmResetColors}
              iconChildren="cached"
              flat
              secondary
              style={resetStyle}
            >
              Restore
              <br />
              defaults
            </Button>
            <DialogContainer
              id="confirmResetDialog"
              visible={this.state.confirmVisible}
              onHide={this.cancelResetColors}
              actions={actions}
              title="Restore Defaults?"
            >
              Are you sure you wish to restore the default tab/icon colors?
            </DialogContainer>
          </div>
        </div>
      </div>
    )
  }

  handleTabOrderChange = (tabOrders) => {
    const { dispatch } = this.props
    dispatch(actions.change('edit_theme.tab_orders', tabOrders))
  }

  render() {
    const self = this
    const { edit_theme, itemId, copy, guidebookId, ownerPluralName } =
      this.props
    const logo = edit_theme.logo
    const itinerary_image = edit_theme.itinerary_image
    const locales = edit_theme.locales
    const primary_color = edit_theme.primary_color || '#03a9f4'
    const buttonStyle = { background: primary_color }

    const title = copy
      ? 'Copy Theme'
      : itemId === 'create'
        ? 'New Theme'
        : 'Edit Theme'
    const nav = (
      <TooltipButton
        key="nav"
        onClick={self.goBack}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>keyboard_backspace</FontIcon>
      </TooltipButton>
    )
    const actions = []
    const formErrors = []
    const errorMessages = {
      formwide: {
        valid: 'Oops! Unable to save:'
      },
      name: {
        required: 'Please provide a name for this card'
      },
      website: {
        valid:
          'Please enter a valid website address. It must begin with http or https'
      },
      sponsored_count: {
        required: 'Please select a number of sponsored recommendations'
      },
      home_label: {
        maxLength: 'Maximum length for House Manual override is 14 characters'
      },
      home_label_txn: {
        maxLength:
          'Maximum length for House Manual override is 14 characters for all languages'
      },
      mktpl_label: {
        maxLength:
          'Maximum length for Marketplace label override is 14 characters'
      },
      mktpl_label_txn: {
        maxLength:
          'Maximum length for Marketplace label override is 14 characters for all languages'
      },
      splash_button_text: {
        required: 'Please enter a value for the "I agree" button'
      },
      ai_label: {
        required: 'Please enter a label for the AI Planner tab',
        maxLength:
          'Maximum length for AI Planner label override is 14 characters'
      },
      ai_label_txn: {
        maxLength:
          'Maximum length for AI Planner label override is 14 characters for all languages'
      },
      itinerary_title: {
        required: 'Please enter a title for the itinerary card',
        maxLength:
          'Maximum length for Itinerary Planner card title is 40 characters'
      },
      itinerary_title_txn: {
        maxLength:
          'Maximum length for Itinerary Planner card title is 40 characters'
      },
      itinerary_desc: {
        required: 'Please enter a description for the itinerary card',
        maxLength:
          'Maximum length for Itinerary Planner card description is 140 characters'
      },
      itinerary_desc_txn: {
        maxLength:
          'Maximum length for Itinerary Planner card title is 30 characters'
      }
    }
    formErrors['formwide'] = (
      <Errors
        model="edit_theme"
        messages={errorMessages.formwide}
        wrapper="h2"
        className="md-text-field-message title hf-error"
        show={(form) => form.submitFailed}
      />
    )
    formErrors['name'] = (
      <Errors
        model=".name"
        messages={errorMessages.name}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['website'] = (
      <Errors
        model=".website"
        messages={errorMessages.website}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['sponsored_count'] = (
      <Errors
        model=".sponsored_count"
        messages={errorMessages.sponsored_count}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['home_label'] = (
      <Errors
        model=".home_label"
        messages={errorMessages.home_label}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['home_label_txn'] = (
      <Errors
        model=".home_label_txn"
        messages={errorMessages.home_label_txn}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['mktpl_label'] = (
      <Errors
        model=".mktpl_label"
        messages={errorMessages.mktpl_label}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['mktpl_label_txn'] = (
      <Errors
        model=".mktpl_label_txn"
        messages={errorMessages.mktpl_label_txn}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['splash_button_text'] = (
      <Errors
        model=".splash_button_text"
        messages={errorMessages.splash_button_text}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['ai_label'] = (
      <Errors
        model=".ai_label"
        messages={errorMessages.ai_label}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['ai_label_txn'] = (
      <Errors
        model=".ai_label_txn"
        messages={errorMessages.ai_label_txn}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['itinerary_title'] = (
      <Errors
        model=".itinerary_title"
        messages={errorMessages.itinerary_title}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['itinerary_title_txn'] = (
      <Errors
        model=".itinerary_title_txn"
        messages={errorMessages.itinerary_title_txn}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['itinerary_desc'] = (
      <Errors
        model=".itinerary_desc"
        messages={errorMessages.itinerary_desc}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['itinerary_desc_txn'] = (
      <Errors
        model=".itinerary_desc_txn"
        messages={errorMessages.itinerary_desc_txn}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    const user = currentUser()
    if (edit_theme.id && user.canCopy && !copy && !guidebookId) {
      actions.push(
        <TooltipButton
          key="copy"
          onClick={() => {
            self.copyCard(edit_theme.id)
          }}
          tooltipLabel="Copy"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>content_copy</FontIcon>
        </TooltipButton>
      )
    }
    const logoStyle = { height: 'auto', width: '100%' }
    const preview_image = logo ? (
      <img src={logo} alt="" style={logoStyle} />
    ) : (
      <img
        src="https://storage.googleapis.com/hostfully-wp-1/blank_state_logo.png"
        alt=""
        style={logoStyle}
      />
    )
    const removeStyle = { marginLeft: '12px' }
    const removeButton = logo ? (
      <Button style={removeStyle} onClick={this.removeImage} raised secondary>
        Remove Image
      </Button>
    ) : null

    const preview_itinerary_image = itinerary_image ? (
      <img src={itinerary_image} alt="" style={logoStyle} />
    ) : (
      <img
        src="https://cdn.filestackcontent.com/m2gfGcCtS2mkoXBFD49v"
        alt=""
        style={logoStyle}
      />
    )
    const removeItineraryImageButton = itinerary_image ? (
      <Button onClick={this.removeItineraryImage} raised secondary>
        Remove Image
      </Button>
    ) : null

    const filestackOptions = {
      accept: 'image/*',
      maxFiles: 1,
      fromSources: ['local_file_system', 'url', 'imagesearch'],
      storeTo: {
        location: 'gcs'
      },
      imageMax: [800, 600],
      transformations: {
        crop: true,
        rotate: true
      }
    }

    const filestackOotionsItinerary = {
      accept: 'image/*',
      maxFiles: 1,
      fromSources: ['local_file_system', 'url', 'imagesearch'],
      storeTo: {
        location: 'gcs'
      },
      imageMax: [1600, 1200],
      transformations: {
        crop: {
          aspectRatio: 4 / 3,
          force: true
        },
        rotate: true
      }
    }

    const sponsoredCountOptions = [
      { label: '-- NONE -- ', value: 0, key: '0' },
      { label: '2', value: 2, key: '2' },
      { label: '4', value: 4, key: '4' },
      { label: '6', value: 6, key: '6' },
      { label: '8', value: 8, key: '8' },
      { label: '10', value: 10, key: '10' },
      { label: '12', value: 12, key: '12' },
      { label: '18', value: 18, key: '18' },
      { label: '24', value: 24, key: '24' },
      { label: '48', value: 48, key: '48' }
    ]

    const sponsoredRadiusOptions = [
      { label: '0.5 miles (0.8 km)', value: 0.5, key: '0.5' },
      { label: '1 mile (1.6 km)', value: 1.0, key: '1.0' },
      { label: '2.5 miles (4.0 km)', value: 2.5, key: '2.5' },
      { label: '5 miles (8.0 km)', value: 5.0, key: '5.0' },
      { label: '10 miles (16.1 km)', value: 10.0, key: '10.0' },
      { label: '15 miles (24.1 km)', value: 15.0, key: '15.0' },
      { label: '20 miles (32.2 km)', value: 20.0, key: '20.0' },
      { label: '25 miles (40.2 km)', value: 25.0, key: '25.0' },
      { label: '30 miles (48.3 km)', value: 30.0, key: '30.0' },
      { label: '40 miles (64.4 km)', value: 40.0, key: '40.0' },
      { label: '50 miles (80.5 km)', value: 50.0, key: '50.0' },
      { label: '60 miles (96.6 km)', value: 60.0, key: '60.0' },
      { label: '75 miles (120.7 km)', value: 75.0, key: '75.0' },
      { label: '100 miles (160.9 km)', value: 100.0, key: '100.0' },
      { label: '150 miles (241.4 km)', value: 150.0, key: '150.0' },
      { label: '200 miles (321.9 km)', value: 200.0, key: '200.0' }
    ]

    const filestackKey = process.env.REACT_APP_FILESTACK_KEY

    const topMargin = { marginTop: '12px' }
    const splashScreenFields = edit_theme.show_splash_screen
      ? [
          <div className="md-cell md-cell--12" key="splash_email">
            <Checkbox
              id="splash_collect_email"
              name="splash_collect_email"
              label="Request user emails (will be optional for guest)"
              className="hf-taller-selection-control-container hf-wider-selection-control-container"
              onChange={this.handleCollectEmailChange}
              checked={this.state.collectEmailChecked}
            />
          </div>,
          <div className="md-cell md-cell--12" key="splash_email_required">
            <Checkbox
              id="splash_require_email"
              name="splash_require_email"
              label="Make email required"
              className="hf-taller-selection-control-container hf-wider-selection-control-container"
              onChange={this.handleRequireEmailChange}
              checked={this.state.requireEmailChecked}
            />
          </div>,
          <div className="md-cell md-cell--12" key="splash_custom_fields">
            {this.renderCustomFields()}
          </div>,
          <div
            className="md-cell md-cell--12"
            key="splash_content"
            style={topMargin}
          >
            <h3>Terms to be agreed to</h3>
            <MultilangWysiwyg
              model="edit_theme"
              field="splash_content_text"
              txn_field="splash_content_text_txn"
              locales={locales}
            />
          </div>,
          <div
            className="md-cell md-cell--12"
            key="splash_button"
            style={topMargin}
          >
            <h3>Label for 'I agree' button</h3>
            <MultilangWysiwyg
              simpleEditor
              model="edit_theme"
              field="splash_button_text"
              txn_field="splash_button_text_txn"
              max_length={20}
              locales={locales}
            />
            <div>{formErrors['splash_button_text']}</div>
          </div>,
          <div
            className="md-cell md-cell--12 hf-headline-margin"
            key="primary_color"
          >
            <h3>Choose a main color for your splash screen</h3>
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--8">
                <ChromePicker
                  color={primary_color}
                  onChangeComplete={this.handlePrimaryColorChange}
                  disableAlpha
                />
              </div>
              <div className="md-cell md-cell--4">
                <label>Button preview:</label>
                <br />
                <Button raised primary style={buttonStyle}>
                  {edit_theme.splash_button_text}
                </Button>
              </div>
            </div>
          </div>
        ]
      : null

    const formValidators = {
      '': {
        // Form-level validator
        valid: () => this.formHasErrors(formErrors)
      },
      name: {
        required: (val) => val && val.length
      },
      home_label: {
        maxLength: (val) => {
          if (val) {
            // max 14 chars
            return val.length < 15
          } else {
            return true
          }
        }
      },
      home_label_txn: {
        maxLength: (txn) => {
          if (txn) {
            for (var key in txn) {
              if (
                {}.hasOwnProperty.call(txn, key) &&
                txn[key] &&
                txn[key].length > 14
              ) {
                return false
              }
            }
          }
          return true
        }
      },
      mktpl_label: {
        maxLength: (val) => {
          if (val) {
            // max 14 chars
            return val.length < 15
          } else {
            return true
          }
        }
      },
      mktpl_label_txn: {
        maxLength: (txn) => {
          if (txn) {
            for (var key in txn) {
              if (
                {}.hasOwnProperty.call(txn, key) &&
                txn[key] &&
                txn[key].length > 14
              ) {
                return false
              }
            }
          }
          return true
        }
      },
      ai_label: {
        required: (val) =>
          requiredIf(
            edit_theme.show_itinerary_builder,
            val && fieldHasContent(val)
          ),
        maxLength: (val) => {
          if (val) {
            // max 14 chars
            return val.length < 15
          } else {
            return true
          }
        }
      },
      ai_label_txn: {
        maxLength: (txn) => {
          if (txn) {
            for (var key in txn) {
              if (
                {}.hasOwnProperty.call(txn, key) &&
                txn[key] &&
                txn[key].length > 14
              ) {
                return false
              }
            }
          }
          return true
        }
      },
      itinerary_title: {
        required: (val) =>
          requiredIf(
            edit_theme.show_itinerary_builder,
            val && fieldHasContent(val)
          ),
        maxLength: (val) => {
          if (val) {
            // max 40 chars
            return val.length <= 40
          } else {
            return true
          }
        }
      },
      itinerary_title_txn: {
        maxLength: (txn) => {
          if (txn) {
            for (var key in txn) {
              if (
                {}.hasOwnProperty.call(txn, key) &&
                txn[key] &&
                txn[key].length > 40
              ) {
                return false
              }
            }
          }
          return true
        }
      },
      itinerary_desc: {
        required: (val) =>
          requiredIf(
            edit_theme.show_itinerary_builder,
            val && fieldHasContent(val)
          ),
        maxLength: (val) => {
          if (val) {
            // max 140 chars
            return val.length <= 140
          } else {
            return true
          }
        }
      },
      itinerary_desc_txn: {
        maxLength: (txn) => {
          if (txn) {
            for (var key in txn) {
              if (
                {}.hasOwnProperty.call(txn, key) &&
                txn[key] &&
                txn[key].length > 140
              ) {
                return false
              }
            }
          }
          return true
        }
      },
      website: {
        valid: (val) => {
          if (val) {
            if (
              val.substring(0, 7) !== 'http://' &&
              val.substring(0, 8) !== 'https://'
            ) {
              return false
            }
          }
          return true
        }
      },
      splash_button_text: {
        required: (val) =>
          requiredIf(edit_theme.show_splash_screen, fieldHasContent(val))
      }
    }

    return (
      <DocumentTitle title="Host Theme">
        <div className="hf-themes-paper">
          <Toolbar
            colored
            className="hf-edit-toolbar hf-themes"
            title={title}
            nav={nav}
            actions={actions}
          />
          <Paper key="category" className="hf-form-wrapper">
            <Form
              model="edit_theme"
              onSubmit={(v) => this.handleSubmit(v)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  return this.handleEnter(e)
                }
              }}
              validators={formValidators}
            >
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {this.formatMessage(hostUIMessages['languageHeading'])}
                  </h2>
                  <p className="md-body-1">
                    {this.formatMessage(hostUIMessages['languageSubHeading'])}
                  </p>
                  <LocaleForm editModel="edit_theme" />
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {this.formatMessage(hostUIMessages['nameHeading'])}
                  </h2>
                  <InputTextField model=".name" id="name" label="Card name *" />
                  {formErrors['name']}
                </div>
                <div className="md-cell md-cell--12">
                  <h2 className="md-headline">Upload your custom logo</h2>
                </div>
                <div className="md-cell md-cell--3">{preview_image}</div>
                <div className="md-cell md-cell--4">
                  <PickerOverlay
                    apikey={filestackKey}
                    componentDisplayMode={{
                      type: 'button',
                      customText: 'Select Image',
                      customClass:
                        'md-inline-block md-btn md-btn--raised md-background--primary md-background--primary-hover md-pointer--hover md-btn--text md-btn--raised-pressed'
                    }}
                    actionOptions={filestackOptions}
                    onSuccess={this.imageUploaded}
                  />
                  {removeButton}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    Would you like your logo to link to an external website?
                  </h2>
                  <p>Enter that website URL here.</p>
                  <InputTextField
                    model=".website"
                    id="website"
                    label="Website URL"
                  />
                  {formErrors['website']}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    Do you want a different label for the 'House Manual' tab?
                  </h2>
                  <p>
                    Enter that label here. (Maximum length is 14 characters)
                  </p>
                  <MultilangWysiwyg
                    key="home_label_input"
                    simpleEditor
                    model="edit_theme"
                    field="home_label"
                    txn_field="home_label_txn"
                    max_length={14}
                    locales={locales}
                  />
                  {formErrors['home_label']}
                  {formErrors['home_label_txn']}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    Do you want a different label for the 'Marketplace' tab?
                  </h2>
                  <p>
                    Enter that label here. (Maximum length is 14 characters)
                  </p>
                  <MultilangWysiwyg
                    key="mktpl_label_input"
                    simpleEditor
                    model="edit_theme"
                    field="mktpl_label"
                    txn_field="mktpl_label_txn"
                    max_length={14}
                    locales={locales}
                  />
                  {formErrors['mktpl_label']}
                  {formErrors['mktpl_label_txn']}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    Do you want your guests to accept terms before viewing?
                  </h2>
                  <Card className="hf-wider-selection-control-container">
                    <CardTitle
                      avatar={
                        <Avatar
                          icon={<FontIcon>thumbs_up_down</FontIcon>}
                          alt=""
                          suffix="hfprimaryavatar"
                        />
                      }
                      title="Create a splash screen"
                    />
                    <CardText>
                      <div className="md-grid md-grid--no-spacing">
                        <div className="md-cell md-cell--12">
                          <CheckBoxForm
                            id="show_splash_screen"
                            name="show_splash_screen"
                            model="edit_theme.show_splash_screen"
                            label="Show splash screen and require guest approval of terms"
                            className="hf-taller-selection-control-container hf-wider-selection-control-container"
                          />
                        </div>
                        {splashScreenFields}
                      </div>
                    </CardText>
                  </Card>
                </div>
                {this.renderCustomColors()}
                <TabOrder
                  defaultOrder={edit_theme.tab_orders}
                  onOrderChange={this.handleTabOrderChange}
                />
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    Are there any other settings you need to change?
                  </h2>
                  <Card className="hf-wider-selection-control-container">
                    <CardTitle
                      avatar={
                        <Avatar
                          icon={<FontIcon>settings</FontIcon>}
                          alt=""
                          suffix="hfpurple"
                        />
                      }
                      title="Apply theme settings"
                    />
                    <CardText>
                      <div className="md-grid md-grid--no-spacing">
                        <div className="md-cell md-cell--12">
                          <CheckBoxForm
                            id="hide_arrival"
                            name="hide_arrival"
                            model="edit_theme.hide_arrival"
                            label="Hide Arrival Tab Entirely"
                            className="hf-taller-selection-control-container hf-wider-selection-control-container"
                          />
                        </div>
                        <div className="md-cell md-cell--12">
                          <CheckBoxForm
                            id="hide_departure"
                            name="hide_departure"
                            model="edit_theme.hide_departure"
                            label="Hide Departure Tab Entirely"
                            className="hf-taller-selection-control-container hf-wider-selection-control-container"
                          />
                        </div>
                        <div className="md-cell md-cell--12">
                          <CheckBoxForm
                            id="hide_all"
                            name="hide_all"
                            model="edit_theme.hide_all"
                            label="Hide 'All Recommendations' Tab Entirely"
                            className="hf-taller-selection-control-container hf-wider-selection-control-container"
                          />
                        </div>
                        <div className="md-cell md-cell--12">
                          <hr />
                        </div>
                        <div className="md-cell md-cell--12">
                          <CheckBoxForm
                            id="show_babyquip"
                            name="show_babyquip"
                            model="edit_theme.show_babyquip"
                            label="Show BabyQuip Form on Arrival Card"
                            className="hf-taller-selection-control-container hf-wider-selection-control-container"
                          />
                        </div>
                        <div className="md-cell md-cell--12">
                          <CheckBoxForm
                            id="show_ground_transport"
                            name="show_ground_transport"
                            model="edit_theme.show_ground_transport"
                            label="Show Ground Transport Form on Arrival/Departure Cards"
                            className="hf-taller-selection-control-container hf-wider-selection-control-container"
                          />
                        </div>
                        {/*  weather card disabled at the moment */}
                        {/*
                              <div className="md-cell md-cell--12">
                                <CheckBoxForm
                                  id="show_weather"
                                  name="show_weather"
                                  model="edit_theme.show_weather"
                                  label="Show Weather Card on Arrival Card"
                                  className="hf-taller-selection-control-container hf-wider-selection-control-container"
                                />
                              </div> */}
                        <div className="md-cell md-cell--12">
                          <div className="md-grid">
                            <SelectField
                              id="sponsored_count"
                              className="md-cell md-cell--6 md-cell--4-tablet"
                              label="Sponsored Recommendations to show:"
                              menuItems={sponsoredCountOptions}
                              value={this.props.edit_theme.sponsored_count}
                              onChange={this.handleSponsoredChange}
                              itemLabel="label"
                              itemValue="value"
                            >
                              {sponsoredCountOptions}
                            </SelectField>
                            {formErrors['sponsored_count']}

                            <SelectField
                              id="sponsored_rec_radius"
                              className="md-cell md-cell--6 md-cell--4-tablet"
                              label="Sponsored Recommendation radius:"
                              menuItems={sponsoredRadiusOptions}
                              value={this.props.edit_theme.sponsored_rec_radius}
                              onChange={this.handleRadiusChange}
                              itemLabel="label"
                              itemValue="value"
                            >
                              {sponsoredCountOptions}
                            </SelectField>
                            {formErrors['sponsored_count']}
                          </div>
                        </div>
                        <div className="md-cell md-cell--12">
                          <hr />
                        </div>
                        <div className="md-cell md-cell--12">
                          <CheckBoxForm
                            id="hide_all"
                            name="hide_all"
                            model="edit_theme.show_itinerary_builder"
                            label="Show AI Planner Category"
                            className="hf-taller-selection-control-container hf-wider-selection-control-container"
                          />
                        </div>
                        {edit_theme.show_itinerary_builder ? (
                          <div className="md-cell md-cell--12 hf-headline-margin">
                            <div className="md-grid md-grid--no-spacing">
                              <div className="md-cell md-cell--12 hf-headline-margin">
                                <h4>
                                  Label for AI Planner Tab. (Maximum length is
                                  14 characters)
                                </h4>
                                <MultilangWysiwyg
                                  key="ai_label_input"
                                  simpleEditor
                                  model="edit_theme"
                                  field="ai_label"
                                  defaultValue="AI Planner"
                                  txn_field="ai_label_txn"
                                  max_length={14}
                                  locales={locales}
                                />
                                {formErrors['ai_label']}
                                {formErrors['ai_label_txn']}
                              </div>
                              <div className="md-cell md-cell--12 hf-headline-margin">
                                <h4>Title for Itinerary Planner Card.</h4>
                                <MultilangWysiwyg
                                  key="itinerary_title_input"
                                  simpleEditor
                                  model="edit_theme"
                                  field="itinerary_title"
                                  defaultValue="Itinerary Generator"
                                  txn_field="itinerary_title_txn"
                                  max_length={40}
                                  locales={locales}
                                />
                                {formErrors['itinerary_title']}
                                {formErrors['itinerary_title_txn']}
                              </div>
                              <div className="md-cell md-cell--12 hf-headline-margin">
                                <h4>Description for Itinerary Planner Card.</h4>
                                <MultilangWysiwyg
                                  key="itinerary_desc_input"
                                  simpleEditor
                                  model="edit_theme"
                                  field="itinerary_desc"
                                  defaultValue="Let AI plan your trip."
                                  txn_field="itinerary_desc_txn"
                                  max_length={140}
                                  locales={locales}
                                />
                                {formErrors['itinerary_desc']}
                                {formErrors['itinerary_desc_txn']}
                              </div>
                              <div className="md-cell md-cell--12">
                                <h2 className="md-headline">
                                  Change the itinerary planner image
                                </h2>
                              </div>
                              <div className="md-cell md-cell--4">
                                {preview_itinerary_image}
                              </div>
                              <div className="md-cell md-cell--8">
                                <div className="md-cell md-cell--12">
                                  <PickerOverlay
                                    apikey={filestackKey}
                                    componentDisplayMode={{
                                      type: 'button',
                                      customText: 'Select Image',
                                      customClass:
                                        'md-inline-block md-btn md-btn--raised md-background--primary md-background--primary-hover md-pointer--hover md-btn--text md-btn--raised-pressed'
                                    }}
                                    actionOptions={filestackOotionsItinerary}
                                    onSuccess={this.itineraryImageUploaded}
                                  />
                                </div>
                                <div className="md-cell md-cell--12">
                                  {removeItineraryImageButton}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </CardText>
                  </Card>
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  {user.isEnterprise ? (
                    <ManageGuidebooksAndTemplates
                      ownerPluralName="themes"
                      ownerSingularName="theme"
                      guidebookId={guidebookId}
                      cardType="Theme"
                      activeTab={ownerPluralName}
                      replaceWarning={true}
                      active={true}
                    />
                  ) : (
                    <ManageSelectedGuidebooks
                      ownerPluralName="themes"
                      ownerSingularName="theme"
                      guidebookId={guidebookId}
                      cardType="Theme"
                      replaceWarning={true}
                      active={true}
                    />
                  )}
                </div>
              </div>
              <div className="md-grid hf-headline-margin">
                <div className="md-cell md-cell--12">
                  <div>{formErrors['formwide']}</div>
                  <div>{formErrors['name']}</div>
                  <div>{formErrors['website']}</div>
                  <div>{formErrors['sponsored_count']}</div>
                  <div>{formErrors['home_label']}</div>
                  <div>{formErrors['home_label_txn']}</div>
                  <div>{formErrors['mktpl_label']}</div>
                  <div>{formErrors['mktpl_label_txn']}</div>
                  <div>{formErrors['ai_label']}</div>
                  <div>{formErrors['ai_label_txn']}</div>
                  <div>{formErrors['itinerary_title']}</div>
                  <div>{formErrors['itinerary_title_txn']}</div>
                  <div>{formErrors['itinerary_desc']}</div>
                  <div>{formErrors['itinerary_desc_txn']}</div>
                  <div>{formErrors['splash_button_text']}</div>
                </div>
              </div>
              <FormCardActions
                guidebookId={guidebookId}
                saveAndAdd={this.saveAndAddAnother}
              />
            </Form>
          </Paper>
        </div>
      </DocumentTitle>
    )
  }
}

ThemeForm.propTypes = {
  ownerSingularName: PropTypes.string,
  ownerPluralName: PropTypes.string
}

function mapStateToProps(state, props) {
  const { ownerSingularName } = props
  const edit_theme = state.edit_theme
  const owner_data = state[`edit_${ownerSingularName}`]
  const edit_form = state.forms.edit_theme
  return {
    edit_theme,
    owner_data,
    edit_form
  }
}

export default connect(mapStateToProps)(ThemeForm)
