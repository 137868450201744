import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ChromePicker } from 'react-color'
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  DialogContainer,
  ListItemControl,
  Media,
  MediaOverlay,
  SelectField
} from 'react-md'
import { connect } from 'react-redux'
import reactCSS from 'reactcss'

import Activate from 'components/Activate'
import {
  getAvailableTabs,
  updatePotentialTabs
} from 'components/NavDrawer/PotentialTabs'
import Blurb from 'components/Print/Blurb'
import { allIcons } from 'constants/UIIcons'
import {
  initPrintPreferences,
  loadPrintPreference,
  resetPrintPreferencesState,
  setPrintPreferences,
  setPrintSection,
  setPrintSetting
} from 'redux/modules/printPreferences'
import { customColor } from 'utils/Color'
import currentUser from 'utils/CurrentUser'
import { matchData } from 'utils/Data'
import { ucFirst } from 'utils/Strings'
// import { trackEvent } from 'utils/Segment';

class ConfigureMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      enableCovers: false,
      displayColorPicker: false,
      color: {
        r: '0',
        g: '145',
        b: '243',
        a: '1'
      }
    }
    this.potentialTabs = updatePotentialTabs(props.guidebook)
    this.showDialog = this.showDialog.bind(this)
    this.hideDialog = this.hideDialog.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.activate = this.activate.bind(this)
    this.setCover = this.setCover.bind(this)
    this.setSectionCheckbox = this.setSectionCheckbox.bind(this)
    this.setSettingsCheckbox = this.setSettingsCheckbox.bind(this)
    this.setSections = this.setSections.bind(this)
    this.buildMediaOverlay = this.buildMediaOverlay.bind(this)
    this.resetPreferences = this.resetPreferences.bind(this)
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  showDialog = () => {
    const self = this
    // OLD SEGMENT CODE
    // let eventData = {
    //   category: 'Host',
    //   label: this.props.guidebook.key,
    //   host_user_id: this.props.guidebook.host_user_id,
    //   key: this.props.guidebook.key
    // }
    // trackEvent('printUpgradeClicked', eventData, {}, function() {
    self.setState({ visible: true })
    // });
  }

  hideDialog = () => {
    this.setState({ visible: false })
  }

  activate = () => {
    this.hideDialog()
    window.location.href =
      process.env.REACT_APP_WORDPRESS_URL + '/upgrade-power'
  }

  setCover = (cover) => {
    const { dispatch } = this.props
    const { enableCovers } = this.state
    if (enableCovers) {
      // OLD SEGMENT CODE
      // let eventData = {
      //   category: 'Host',
      //   label: cover,
      //   host_user_id: this.props.guidebook.host_user_id,
      //   key: this.props.guidebook.key
      // }
      // trackEvent('printCoverSelected', eventData, {});
      dispatch(loadPrintPreference({ cover: cover }))
      dispatch(setPrintPreferences())
    }
  }

  setFont = (font) => {
    const { dispatch } = this.props
    const { enableCovers } = this.state
    if (enableCovers) {
      // OLD SEGMENT CODE
      // let eventData = {
      //   category: 'Host',
      //   label: font,
      //   host_user_id: this.props.guidebook.host_user_id,
      //   key: this.props.guidebook.key
      // }
      // trackEvent('printFontSelected', eventData, {});
      dispatch(loadPrintPreference({ font: font }))
      dispatch(setPrintPreferences())
    }
  }

  setColor = (color) => {
    const { dispatch } = this.props
    const { enableCovers } = this.state
    if (enableCovers) {
      // OLD SEGMENT CODE
      // let eventData = {
      //   category: 'Host',
      //   label: color.hex,
      //   host_user_id: this.props.guidebook.host_user_id,
      //   key: this.props.guidebook.key
      // }
      // trackEvent('printColorSelected', eventData, {});
      this.setState({ color: color.rgb })
      dispatch(loadPrintPreference({ color: color.hex }))
      dispatch(setPrintPreferences())
    }
  }

  setSections = (sectionName) => {
    const { dispatch } = this.props
    // var index = printPreferences.sections.map(function(obj){ return obj.name; }).indexOf(sectionName);
    // var checked = printPreferences.sections[index].selected;
    // OLD SEGMENT CODE
    // let eventData = {
    //   category: 'Host',
    //   label: sectionName,
    //   host_user_id: this.props.guidebook.host_user_id,
    //   key: this.props.guidebook.key
    // }
    // // if it 'was' checked, we're disabling it
    // if (checked) {
    //   trackEvent('printSectionDisabled', eventData, {});
    // } else {
    //   trackEvent('printSectionEnabled', eventData, {});
    // }
    dispatch(setPrintSection({ sectionName }))
    dispatch(setPrintPreferences())
  }

  setSectionCheckbox = (sectionName) => {
    const { printPreferences } = this.props
    var index = printPreferences.sections
      .map(function (obj) {
        return obj.name
      })
      .indexOf(sectionName)
    return printPreferences.sections[index].selected
  }

  setSettingsCheckbox = (settingName) => {
    const { printPreferences } = this.props
    return typeof printPreferences.settings[settingName] !== 'undefined'
      ? printPreferences.settings[settingName].selected
      : false
  }

  setSettings = (settingName) => {
    const { dispatch } = this.props
    // const checked = printPreferences.settings[settingName].selected;
    // OLD SEGMENT CODE
    // let eventData = {
    //   category: 'Host',
    //   label: settingName,
    //   host_user_id: this.props.guidebook.host_user_id,
    //   key: this.props.guidebook.key
    // }
    // // if it 'was' checked, we're disabling it
    // if (checked) {
    //   trackEvent('printSettingDisabled', eventData, {});
    // } else {
    //   trackEvent('printSettingEnabled', eventData, {});
    // }
    dispatch(setPrintSetting({ settingName }))
    dispatch(setPrintPreferences())
  }

  resetPreferences = () => {
    const { dispatch } = this.props
    dispatch(resetPrintPreferencesState())
    dispatch(initPrintPreferences())
    dispatch(setPrintPreferences())
  }

  buildMediaOverlay = (cover, printPreferences) => {
    const { enableCovers } = this.state
    let clz = 'hf-print-active'
    if (enableCovers) {
      const paidCover = cover !== 1
      //show which cover is selected
      if (enableCovers) {
        if (printPreferences.cover === cover) clz += ' selected'
      } else {
        if (!paidCover) clz += ' selected'
      }
    }
    return <MediaOverlay className={clz}></MediaOverlay>
  }

  componentDidMount = () => {
    const user = currentUser()
    this.setState({ enableCovers: user.canTheme })
  }

  render() {
    const self = this
    const { printPreferences } = this.props
    const { theme } = this.props.guidebook
    const { visible, enableCovers } = this.state
    const styles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: printPreferences.color
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer'
        },
        popover: {
          position: 'absolute',
          zIndex: '2'
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }
      }
    })
    const popover = {
      position: 'absolute',
      zIndex: '2'
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px'
    }
    const actions = []
    actions.push(
      <Button flat primary onClick={this.hideDialog}>
        Cancel
      </Button>
    )
    actions.push(
      <Button flat primary onClick={this.activate}>
        Continue
      </Button>
    )
    const fontOptions = [
      {
        label: 'Lato',
        value: 'lato',
        disable: true
      },
      {
        label: 'Dosis',
        value: 'dosis',
        disable: true
      },
      {
        label: 'PT Serif',
        value: 'ptserif',
        disable: true
      },
      {
        label: 'Old Standard TT',
        value: 'oldstandardtt',
        disable: true
      },
      {
        label: 'Ubuntu',
        value: 'ubuntu',
        disable: true
      }
    ]
    if (this.state.enableCovers) {
      for (var i in fontOptions) {
        if (fontOptions[i].value !== 'lato') {
          fontOptions[i].disable = true
        }
      }
    }
    const availableTabs = getAvailableTabs(this.potentialTabs, false)
    printPreferences.sections.sort((a, b) => {
      return availableTabs.indexOf(a.name) - availableTabs.indexOf(b.name)
    })
    // console.log(orderedprintPreferences);

    const contentOptions = printPreferences.sections.map(function (section) {
      let icon = null
      let iconMatch = matchData(
        allIcons,
        'Icons',
        ['field', 'v1_name'],
        section.icon
      )
      if (iconMatch) {
        const IconComponent = iconMatch.icon
        icon = <IconComponent fill="#ffffff" />
      }

      const useColor = customColor(
        section.name.toLowerCase(),
        theme,
        section.color
      )
      const avatarStyle = { backgroundColor: useColor, border: 0 }

      return (
        <ListItemControl
          key={section.name.toLowerCase()}
          id={section.group + '-' + section.name.toLowerCase()}
          className="hf-informationslist-item md-cell md-cell--12"
          leftAvatar={
            <Avatar
              alt=""
              className="hf-informationslist-item hf-hideOnLessThanPhablet"
              icon={icon}
              style={avatarStyle}
            />
          }
          secondaryAction={
            <Checkbox
              id={'s_' + section.name.toLowerCase()}
              name={section.name.toLowerCase()}
              label={ucFirst(section.label)}
              labelBefore
              checked={self.setSectionCheckbox(section.name.toLowerCase())}
              onChange={() => {
                self.setSections(section.name.toLowerCase())
              }}
            />
          }
          primaryText={section.label}
        />
      )
    })
    const pointerStyle = { cursor: 'pointer' }
    const resetStyle = { marginTop: '24px' }
    return (
      <div id="print-configure">
        <Blurb />
        <h6 className="hf-control-padding">Cover</h6>
        <Card className="hf-wider-selection-control-container">
          <div className=" hf-editable-grid-lists">
            <div className="md-grid md-grid--no-spacing">
              <div
                key="cover-1"
                className="md-cell md-cell--3 md-cell--2-tablet md-cell--2-phone hf-images-grid"
                onClick={() => {
                  self.setCover(1)
                }}
                style={pointerStyle}
              >
                <Media aspectRatio="6-8">
                  <img
                    src="https://storage.googleapis.com/v2-static/coverpage.png"
                    alt=""
                  />
                  {this.buildMediaOverlay(1, printPreferences)}
                </Media>
              </div>
              <Activate
                featureEnabled={enableCovers}
                buttonOnClick={self.showDialog}
                cellCount="9"
              >
                <div
                  key="cover-2"
                  className="md-cell md-cell--4 md-cell--2-tablet md-cell--2-phone hf-images-grid"
                  onClick={() => {
                    self.setCover(2)
                  }}
                  style={pointerStyle}
                >
                  <Media aspectRatio="6-8">
                    <img
                      src="https://storage.googleapis.com/v2-static/coverpage2.png"
                      alt=""
                    />
                    {this.buildMediaOverlay(2, printPreferences)}
                  </Media>
                </div>
                <div
                  key="cover-3"
                  className="md-cell md-cell--4 md-cell--2-tablet md-cell--2-phone hf-images-grid"
                  onClick={() => {
                    self.setCover(3)
                  }}
                  style={pointerStyle}
                >
                  <Media aspectRatio="6-8">
                    <img
                      src="https://storage.googleapis.com/v2-static/coverpage3.png"
                      alt=""
                    />
                    {this.buildMediaOverlay(3, printPreferences)}
                  </Media>
                </div>
                <div
                  key="cover-4"
                  className="md-cell md-cell--4 md-cell--2-tablet md-cell--2-phone hf-images-grid"
                  onClick={() => {
                    self.setCover(4)
                  }}
                  style={pointerStyle}
                >
                  <Media aspectRatio="6-8">
                    <img
                      src="https://storage.googleapis.com/v2-static/coverpage4.png"
                      alt=""
                    />
                    {this.buildMediaOverlay(4, printPreferences)}
                  </Media>
                </div>
              </Activate>
            </div>
          </div>
        </Card>
        <h6 className="hf-control-padding">Style</h6>
        <Card className="hf-wider-selection-control-container">
          <div id="choose-color" className="md-grid">
            <Activate
              featureEnabled={enableCovers}
              buttonOnClick={self.showDialog}
            >
              <div className="md-cell md-cell--12 hf-print-active">
                <div style={styles.swatch}>
                  <div style={styles.color} />
                </div>
                <Button onClick={self.handleClick} flat primary>
                  Change Color
                </Button>
                {this.state.displayColorPicker ? (
                  <div style={popover}>
                    <div style={cover} onClick={this.handleClose} />
                    <ChromePicker
                      color={this.props.printPreferences.color}
                      disableAlpha
                      onChangeComplete={(value) => {
                        self.setColor(value)
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <SelectField
                id="choose-font"
                label="Font"
                defaultValue="Lato"
                className="md-cell md-cell--12"
                menuItems={fontOptions}
                value={
                  this.props.printPreferences.font
                    ? this.props.printPreferences.font
                    : 'lato'
                }
                onChange={(value) => {
                  self.setFont(value)
                }}
              />
            </Activate>
          </div>
        </Card>
        <h6 className="hf-control-padding">Content</h6>
        <Card className="hf-wider-selection-control-container hf-editable-card-tabs">
          <div id="choose-sections" className="md-grid md-grid--no-spacing">
            {contentOptions}
          </div>
        </Card>
        <p className="hf-control-padding">Presentation</p>
        <Card className="hf-wider-selection-control-container">
          <div id="choose-settings" className="md-grid md-grid--no-spacing">
            <div className="md-col md-col-12">
              <Checkbox
                id="hide_images"
                name="hide_images"
                label="Convert images to thumbnails"
                checked={self.setSettingsCheckbox('hideImages')}
                onChange={() => {
                  self.setSettings('hideImages')
                }}
              />
            </div>
            <div className="md-col md-col-12">
              <Checkbox
                id="combine_maps"
                name="combine_maps"
                label="Combine all recommendations on a single map"
                checked={self.setSettingsCheckbox('combineMaps')}
                onChange={() => {
                  self.setSettings('combineMaps')
                }}
              />
            </div>
            <div className="md-col md-col-12">
              <Checkbox
                id="full_recommendations"
                name="full_recommendations"
                label="Show full copy of recommendations"
                checked={self.setSettingsCheckbox('fullRecommendations')}
                onChange={() => {
                  self.setSettings('fullRecommendations')
                }}
              />
            </div>
            <div className="md-col md-col-12">
              <Checkbox
                id="show_rec_links"
                name="show_rec_links"
                label="Shorten Recommendation Links"
                checked={self.setSettingsCheckbox('shortenRecommendationLinks')}
                onChange={() => {
                  self.setSettings('shortenRecommendationLinks')
                }}
              />
            </div>
            <div className="md-col md-col-12">
              <Checkbox
                id="print_link_href"
                name="print_link_href"
                label="Print URL of links after text"
                checked={self.setSettingsCheckbox('printLinkHrefs')}
                onChange={() => {
                  self.setSettings('printLinkHrefs')
                }}
              />
            </div>
            <div className="md-col md-col-12">
              <Checkbox
                id="show_qr"
                name="show_qr"
                label="Show QR Codes on Cover and in Headers"
                checked={self.setSettingsCheckbox('showQRCode')}
                onChange={() => {
                  self.setSettings('showQRCode')
                }}
              />
            </div>
          </div>
        </Card>
        <div
          id="reset-settings"
          className="md-grid md-grid--no-spacing"
          style={resetStyle}
        >
          <div className="md-col md-col-12">
            <Button
              raised
              primary
              onClick={() => {
                self.resetPreferences()
              }}
            >
              Reset Settings
            </Button>
          </div>
        </div>
        <DialogContainer
          id="simple-action-dialog"
          visible={visible}
          onHide={this.hideDialog}
          actions={actions}
          title="Upgrade your plan?"
          zDepth={5}
        >
          <p>
            You will be redirected to another page and will lose any print
            configuration choices you've made
          </p>
        </DialogContainer>
      </div>
    )
  }
}

ConfigureMenu.propTypes = {
  guidebook: PropTypes.object,
  printPreferences: PropTypes.object
}

function mapStateToProps(state, props) {
  const printPreferences = state.printPreferences

  return {
    printPreferences
  }
}

export default connect(mapStateToProps)(ConfigureMenu)
