import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactList from 'react-list'
import {
  Avatar,
  Checkbox,
  FontIcon,
  ListItemControl,
  SelectionControl,
  TextField
} from 'react-md'
import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import { replace } from 'react-router-redux'

import TooltipButton from 'components/TooltipButton'
import { allIcons } from 'constants/UIIcons'
import { fetchItems } from 'redux/modules/crud'
import { fuzzyFilter, jsonEqual, matchData, sortData } from 'utils/Data'
import { resizeImage } from 'utils/Image'
import { randomString } from 'utils/Strings'

import EmptyState from '../../EmptyState'

class ManageSelectedCards extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: '',
      filteredItems: null,
      toggle: false
    }
    this.changeSelection = this.changeSelection.bind(this)
    this.editItemFromGuidebook = this.editItemFromGuidebook.bind(this)
    this.filter = this.filter.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.toggleSelect = this.toggleSelect.bind(this)
    this.selectVisible = this.selectVisible.bind(this)
    this.deselectVisible = this.deselectVisible.bind(this)
    this.renderItem = this.renderItem.bind(this)
    this.renderInformation = this.renderInformation.bind(this)
    this.renderRecommendation = this.renderRecommendation.bind(this)
    this.renderGuidebook = this.renderGuidebook.bind(this)
  }

  componentDidMount() {
    const { dispatch, pluralName } = this.props
    if (pluralName !== 'marketplaceItems') {
      dispatch(fetchItems(pluralName))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      dispatch,
      ownerSingularName,
      active,
      items,
      onTabContentChanged,
      pluralName,
      ownerId,
      guidebookId
    } = this.props
    if (active && !jsonEqual(prevProps.items, items)) {
      if (onTabContentChanged) {
        onTabContentChanged()
      }
      // if this is a new item and they have only one guidebook, auto select it
      // let's not autoselect any templates though.
      // unless this IS a template, then don't autoselect the guidebook
      // also don't do this for marketplace items
      if (
        !ownerId &&
        ownerSingularName !== 'template' &&
        ownerSingularName !== 'marketplaceItem' &&
        pluralName === 'guidebooks' &&
        items.loaded &&
        items.data.length === 1
      ) {
        dispatch(
          actions.push('edit_' + ownerSingularName + '.' + pluralName, {
            id: Number(items.data[0].id)
          })
        )
      }
      // if this is a card being created from a parent guidebook/template initiall select the guidebook/template
      if (
        !ownerId &&
        guidebookId &&
        guidebookId !== 'create' &&
        (pluralName === 'guidebooks' || pluralName === 'templates') &&
        items.loaded
      ) {
        dispatch(
          actions.push('edit_' + ownerSingularName + '.' + pluralName, {
            id: Number(guidebookId)
          })
        )
      }
    }
  }

  renderRecommendation(item, selectedIds) {
    const self = this
    const resizedImage = resizeImage(item.image, 50, 50, true)
    let secondary = ''
    if (
      item.address &&
      item.address.locality &&
      item.address.street &&
      item.address.post_code
    ) {
      secondary = `${item.address.street_number ? item.address.street_number + ' ' : ''}${item.address.street}, ${item.address.locality} (${item.address.post_code})`
    } else if (item.address && item.address.locality && item.address.street) {
      secondary = `${item.address.street}, ${item.address.locality}`
    } else if (item.address && item.address.locality) {
      secondary = item.address.locality
    } else {
      secondary = 'Locationless Recommendation'
    }

    const catName =
      item.category.name.charAt(0).toUpperCase() + item.category.name.slice(1)
    const catColor = item.category.color
      ? {
          backgroundColor: item.category.color,
          color: '#ffffff',
          padding: '2px 6px',
          borderRadius: '5px'
        }
      : {}
    const wizColor = {
      backgroundColor: '#cccccc',
      color: '#ffffff',
      padding: '2px 6px',
      borderRadius: '5px'
    }
    const wizardSpan =
      item.batch_ref && item.batch_ref.startsWith('wizard') ? (
        <span>
          &nbsp;&nbsp;&nbsp;
          <span className="rec-list-category" style={wizColor}>
            wizard
          </span>
        </span>
      ) : null
    const fullSecondary = (
      <div>
        <span>{secondary}</span>
        <br />
        <span className={`rec-list-category ${catName}`} style={catColor}>
          {catName}
        </span>
        {wizardSpan}
      </div>
    )
    const checked = selectedIds[item.id] ? true : false
    const avatarStyle = item.category.color
      ? { borderColor: item.category.color, cursor: 'pointer' }
      : {}
    return (
      <ListItemControl
        key={'r_' + item.id}
        className="hf-recommendationslist-item md-cell md-cell--12"
        leftAvatar={
          <Avatar
            src={resizedImage}
            alt=""
            onClick={(e) => {
              self.editItemFromGuidebook(item.id)
            }}
            className="hf-recommendationslist-item hf-hideOnLessThanPhablet"
            style={avatarStyle}
            title="Click to edit"
          />
        }
        secondaryAction={
          <Checkbox
            id={item.id}
            name={item.key}
            label={item.name}
            labelBefore
            checked={checked}
            onChange={self.changeSelection}
          />
        }
        primaryText={item.name}
        secondaryText={fullSecondary}
        threeLines
      />
    )
  }

  renderInformation(item, selectedIds) {
    const self = this
    const checked = selectedIds[item.id] ? true : false
    let showIcon = null
    if (item.icon) {
      let iconMatch = matchData(
        allIcons,
        'Icons',
        ['field', 'v1_name'],
        item.icon
      )
      if (iconMatch) {
        const IconComponent = iconMatch.icon
        showIcon = <IconComponent fill="#ffffff" />
      }
    }
    const secondary = item.title ? item.title : ' '
    let catSpan = null
    if (item.category) {
      const catName =
        item.category.name.charAt(0).toUpperCase() + item.category.name.slice(1)
      const catColor = item.category.color
        ? {
            backgroundColor: item.category.color,
            color: '#ffffff',
            padding: '2px 6px',
            borderRadius: '5px'
          }
        : {}
      catSpan = (
        <span className={`rec-list-category ${catName}`} style={catColor}>
          {catName}
        </span>
      )
    }
    let secureSpan = item.secure_access_only ? (
      <span className="item-list-secure">
        <FontIcon>security</FontIcon> Secure
      </span>
    ) : null
    const fullSecondary = (
      <div>
        <span>{secondary}</span>
        <br />
        {secureSpan} {catSpan}
      </div>
    )
    const pointerStyle = { cursor: 'pointer' }
    return (
      <ListItemControl
        key={'i_' + item.id}
        className="hf-informationslist-item md-cell md-cell--12"
        leftAvatar={
          <Avatar
            alt=""
            className="hf-informationslist-item"
            icon={showIcon}
            onClick={(e) => {
              self.editItemFromGuidebook(item.id)
            }}
            style={pointerStyle}
            title="Click to edit"
          />
        }
        secondaryAction={
          <Checkbox
            id={item.id}
            name={item.title}
            label={item.name}
            labelBefore
            checked={checked}
            onChange={self.changeSelection}
          />
        }
        primaryText={item.name}
        secondaryText={fullSecondary}
      />
    )
  }

  renderMarketplaceItem(item, selectedIds) {
    const self = this
    const resizedImage = resizeImage(item.defaultPicture, 50, 50, true)
    const checked = Boolean(selectedIds[item.uid])
    const pointerStyle = { cursor: 'pointer' }
    // console.log(item);
    return (
      <ListItemControl
        key={item.uid}
        className="hf-marketplacelist-item md-cell md-cell--12"
        leftAvatar={
          <Avatar
            alt=""
            src={resizedImage}
            className="hf-marketplacelist-item hf-hideOnLessThanPhablet"
            onClick={(e) => {
              self.editItemFromGuidebook(item.uid)
            }}
            style={pointerStyle}
            title={'Click to edit'}
          />
        }
        secondaryAction={
          <Checkbox
            id={item.uid}
            name={item.uid || 'unknown'}
            label={item.title}
            labelBefore
            checked={checked}
            onChange={self.changeSelection}
          />
        }
        primaryText={item.title}
        secondaryText={item.description}
      />
    )
  }

  renderGuidebook(item, selectedIds) {
    const self = this
    const resizedImage = resizeImage(item.image, 50, 50, true)
    const checked = Boolean(selectedIds[item.id])
    // console.log(item.id);
    return (
      <ListItemControl
        key={item.id}
        className="hf-guidebookslist-item md-cell md-cell--12"
        leftAvatar={
          <Avatar
            src={resizedImage}
            alt=""
            className="hf-guidebookslist-item hf-hideOnLessThanPhablet"
            title={item.name}
          />
        }
        secondaryAction={
          <Checkbox
            id={item.id}
            name={item.key || 'unknown'}
            label={item.name}
            labelBefore
            checked={checked}
            onChange={self.changeSelection}
          />
        }
        primaryText={item.name}
        secondaryText={
          item.address && item.address.locality
            ? item.address.locality
            : item.key
        }
      />
    )
  }

  renderItem(index, key) {
    const self = this
    const { selected_items, items, pluralName } = this.props
    const selectedIds = selected_items
      ? selected_items.reduce(function (map, item) {
          if (item.id) {
            map[item.id] = item
          } else if (item.uid) {
            map[item.uid] = item
          }
          return map
        }, {})
      : []
    const filteredItems = this.state.filteredItems || items.data
    const sortedItems = sortData(filteredItems, 'name', 'asc')
    const item = sortedItems[index]
    let rendered = null
    if (item) {
      rendered =
        pluralName === 'informations'
          ? self.renderInformation(item, selectedIds)
          : pluralName === 'recommendations'
            ? self.renderRecommendation(item, selectedIds)
            : pluralName === 'marketplaceItems'
              ? self.renderMarketplaceItem(item, selectedIds)
              : self.renderGuidebook(item, selectedIds)
    }
    return rendered
  }

  filter = (filter) => {
    const { items, pluralName } = this.props

    let filterFields = []
    if (pluralName === 'guidebooks' || pluralName === 'templates') {
      filterFields = [
        'name',
        'key',
        'address.formatted_address',
        'address.street',
        'address.locality',
        'address.state',
        'address.post_code',
        'address.country_code'
      ]
    } else if (pluralName === 'informations') {
      filterFields = ['name', 'title', 'content']
    } else if (pluralName === 'marketplaceItems') {
      filterFields = [
        'type',
        'title',
        'description',
        'longDescription',
        'providerName'
      ]
    } else {
      filterFields = [
        'name',
        'key',
        'why_recommended',
        'content',
        'address.formatted_address',
        'address.street',
        'address.locality',
        'address.state',
        'address.post_code',
        'address.country_code',
        'category.name',
        'batch_ref'
      ]
    }

    const filteredItems = fuzzyFilter(
      items.data,
      pluralName,
      filterFields,
      filter
    )
    this.setState({ filter, filteredItems })
    const { onTabContentChanged } = this.props
    if (onTabContentChanged) {
      onTabContentChanged('here')
    }
  }

  clearFilter = () => {
    this.setState({ filter: '', filteredItems: null })
    const { onTabContentChanged } = this.props
    if (onTabContentChanged) {
      onTabContentChanged()
    }
  }

  toggleSelect = (checked) => {
    if (checked) {
      // this.setState({toggle: true});
      this.selectVisible()
    } else {
      // this.setState({toggle: false});
      this.deselectVisible()
    }
  }

  selectVisible = () => {
    const {
      selected_items,
      dispatch,
      items,
      pluralName,
      ownerSingularName,
      manuallyManageSelection,
      onSelectionChanged
    } = this.props
    const filteredItems = this.state.filteredItems || items.data
    const selectedIds = selected_items.map((item) => {
      if (item.uid) {
        return item.uid
      }
      return item.id
    })
    const newItems = filteredItems.filter((item) => {
      if (item.uid) {
        return selectedIds.indexOf(item.uid) === -1
      }
      return selectedIds.indexOf(item.id) === -1
    })
    const finalItems = selected_items.concat(newItems)
    if (manuallyManageSelection && typeof onSelectionChanged === 'function') {
      onSelectionChanged(finalItems)
    } else {
      dispatch(
        actions.change(
          'edit_' + ownerSingularName + '.' + pluralName,
          finalItems
        )
      )
    }
  }

  deselectVisible = () => {
    const {
      selected_items,
      items,
      dispatch,
      pluralName,
      ownerSingularName,
      manuallyManageSelection,
      onSelectionChanged
    } = this.props
    const filteredItems = this.state.filteredItems || items.data
    const filteredIds = filteredItems.map((item) => {
      if (item.uid) {
        return item.uid
      }
      return item.id
    })
    const newItems = selected_items.filter((item) => {
      if (item.uid) {
        return filteredIds.indexOf(item.uid) === -1
      }
      return filteredIds.indexOf(item.id) === -1
    })
    if (manuallyManageSelection && typeof onSelectionChanged === 'function') {
      onSelectionChanged(newItems)
    } else {
      dispatch(
        actions.change('edit_' + ownerSingularName + '.' + pluralName, newItems)
      )
    }
  }

  editItemFromGuidebook(id) {
    const {
      dispatch,
      ownerId,
      selected_items,
      singularName,
      pluralName,
      ownerPluralName
    } = this.props
    // BA. make sure that we don't reload/refresh the guidebook/template when going off to edit a card
    dispatch(actions.change(`edit_${singularName}.noReload`, true))
    const selected = selected_items.filter((item) => {
      if (item.uid) {
        return item.uid === id
      }
      return item.id === id
    })
    let url = `/host/${ownerPluralName}/${ownerId}/${pluralName}/${id}`
    if (pluralName === 'recommendations' || pluralName === 'informations') {
      url = url + '?guidebookSelected=' + (selected ? true : false)
    }
    dispatch(replace(url))
  }

  changeSelection(checked, e) {
    const {
      selected_items,
      items,
      dispatch,
      pluralName,
      ownerSingularName,
      manuallyManageSelection,
      onSelectionChanged
    } = this.props
    const id_str = e.target.id
    let id = id_str
    if (pluralName !== 'marketplaceItems') {
      id = Number(id_str)
    }

    let newItems
    if (checked) {
      // add
      const toAdd = items.data.filter((item) => {
        if (item.uid) {
          return item.uid === id
        }
        return item.id === id
      })
      newItems = selected_items.concat(toAdd)
    } else {
      // remove
      newItems = selected_items.filter((item) => {
        if (item.uid) {
          return item.uid !== id
        }
        return item.id !== id
      })
    }
    // console.log({checked: checked, id_str: id_str, id: id, selected_items: selected_items, newItems: newItems});
    if (manuallyManageSelection && typeof onSelectionChanged === 'function') {
      onSelectionChanged(newItems)
    } else {
      dispatch(
        actions.change('edit_' + ownerSingularName + '.' + pluralName, newItems)
      )
    }
  }

  render() {
    const self = this
    const { active, items, pluralName } = this.props
    // console.log({active: active, items: items, pluralName: pluralName});
    const { filter } = this.state
    const filteredItems = this.state.filteredItems || items.data
    // console.log(filteredItems);

    const clearFilterButton = (
      <TooltipButton
        className="hf-mini-btn"
        tooltipLabel="Clear Search / Filter"
        tooltipPosition="left"
        onClick={self.clearFilter}
        icon
      >
        <FontIcon>close</FontIcon>
      </TooltipButton>
    )

    let pluralLabel = pluralName
    if (pluralName === 'informations') {
      pluralLabel = 'house manual cards'
    } else if (pluralName === 'marketplaceItems') {
      pluralLabel = 'marketplace items'
    }
    return (
      <div className="md-grid">
        {active &&
          (items.data.length > 0 ? (
            <div className="md-cell md-cell--12">
              <div className="hf-editable-card-tabs">
                <div className="md-grid md-grid--no-spacing">
                  <div className="md-cell md-cell--8 md-cell--6-tablet md-cell--4-phone md-cell--bottom">
                    <TextField
                      id={`filter_${randomString(4)}`}
                      value={filter}
                      label={'Search / Filter ' + pluralLabel}
                      type="text"
                      leftIcon={<FontIcon>search</FontIcon>}
                      inlineIndicator={clearFilterButton}
                      onChange={self.filter}
                    />
                  </div>
                  <div className="md-cell md-cell--4 md-cell--2-tablet md-cell--4-phone md-cell--bottom md-cell--right">
                    <SelectionControl
                      id={'select-all-' + pluralName}
                      name={'select-all-' + pluralName}
                      label="Select/deselect all"
                      type="checkbox"
                      className="hf-selectall"
                      labelBefore
                      onChange={(e) => self.toggleSelect(e)}
                    />
                  </div>
                </div>
                <div className="hf-card-select-list">
                  <ReactList
                    itemRenderer={self.renderItem}
                    length={filteredItems.length}
                    type="uniform"
                    className="hf-guidebookslist-item"
                  />
                </div>
              </div>
            </div>
          ) : (
            <EmptyState type="cards" />
          ))}
      </div>
    )
  }
}

ManageSelectedCards.propTypes = {
  active: PropTypes.bool.isRequired,
  onTabContentChanged: PropTypes.func,
  singularName: PropTypes.string.isRequired,
  pluralName: PropTypes.string.isRequired,
  ownerSingularName: PropTypes.string.isRequired,
  ownerPluralName: PropTypes.string.isRequired,
  guidebookId: PropTypes.string,
  cardType: PropTypes.string,
  heading: PropTypes.string, // allows override of the heading
  manuallyManageSelection: PropTypes.bool, // defaults to true - false to manage selection outside of redux state
  onSelectionChanged: PropTypes.func,
  initialSelection: PropTypes.array,
  initialItems: PropTypes.object
}
ManageSelectedCards.defaultProps = {
  manuallyManageSelection: false
}

function mapStateToProps(state, props) {
  // console.log(props);
  const { pluralName, ownerSingularName } = props
  const listData = state.list
  // get the card which is the parent of the relationship
  const owner = state['edit_' + ownerSingularName] || null
  const ownerId = owner ? owner.id : null
  // get the current selected children
  // if we're managing selections by state in redux grab them there
  // otherwise just use the passed in prop for initialSelection
  let selected_items = []
  if (!props.manuallyManageSelection && owner && owner[pluralName]) {
    selected_items = owner[pluralName]
  } else if (props.manuallyManageSelection && props.initialSelection) {
    selected_items = props.initialSelection
  }

  let items = []
  if (props.manuallyManageSelection && props.initialItems) {
    items = props.initialItems
  } else {
    items = listData[pluralName]
  }

  return {
    ownerId: ownerId,
    selected_items: selected_items,
    items: items
  }
}

export default connect(mapStateToProps)(ManageSelectedCards)
