import React from 'react'

const Health = (props) => (
  <svg width={24} height={24} viewBox="0 0 192 192" {...props}>
    <title>{`health`}</title>
    <path d="M166 174H26c-7 0-12-7-12-15V33c0-8 5-15 12-15h140c7 0 12 7 12 15v126c0 8-5 15-12 15zm-13-93h-41V39H80v42H39v31h41v41h31v-41h41z" />
  </svg>
)

export default Health
