import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Map from 'components/Map'
import AddressMarker from 'components/MarkerHelpers/AddressMarker'

const propTypes = {
  onMapBoundsChange: PropTypes.func,
  fitType: PropTypes.string,
  initialZoom: PropTypes.number,
  fixToPosition: PropTypes.bool,
  outerContainer: PropTypes.string,
  address: PropTypes.object
}

const defaultProps = {
  fitType: 'smart',
  initialZoom: 13,
  fixToPosition: false,
  outerContainer: 'map-outer-container'
}

class AddressMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      recenterMap: true
    }
  }

  componentDidUpdate() {
    this.fixMapPosition()
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { address, image } = this.props
    const nextAddr = nextProps.address
    if (
      address.lat === nextAddr.lat &&
      address.lng === nextAddr.lng &&
      nextProps.image === image
    ) {
      return false
    }
    return true
  }

  fixMapPosition = () => {
    if (this.props.fixToPosition) {
      // get width of the md-cell--6
      const container = document.getElementById(this.props.outerContainer)
      const containerWidth = container.offsetWidth
      //const containerTop = container.offsetTop;
      const mapElement = document.getElementsByClassName(
        'map-container-fixed'
      )[0]
      mapElement.style.position = 'fixed'
      mapElement.style.width = containerWidth + 'px'
      // set width to the calculated width
    }
  }

  handleMarkerMove = (thing) => {
    // keep the map from going nuts when
    this.setState({ recenterMap: false }, () => {
      this.props.onMarkerMove(thing)
    })
  }

  render() {
    const { image, markerColor } = this.props
    const defaultLat = 37.7749,
      defaultLng = -122.4194
    const address =
      this.props.address && this.props.address.lat && this.props.address.lng
        ? this.props.address
        : { lat: defaultLat, lng: defaultLng }
    const lat = address.lat
    const lng = address.lng
    const markers = [
      AddressMarker(1, address, image, 50, markerColor, this.handleMarkerMove)
    ]
    return (
      <div className="address-map">
        <Map
          className={this.props.className}
          lat={lat}
          lng={lng}
          zoom={this.props.initialZoom}
          onClick={this.onMapClick}
          fitType={this.state.recenterMap ? this.props.fitType : 'center-only'}
          onBoundsChanged={this.props.onBoundsChanged}
          onWindowResized={this.fixMapPosition}
        >
          {markers}
        </Map>
      </div>
    )
  }
}

AddressMap.propTypes = propTypes
AddressMap.defaultProps = defaultProps

export default AddressMap
