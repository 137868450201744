import React from 'react'

const Wheelchair = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`wheelchair`}</title>
    <path d="M13 2a2 2 0 00-2 2 2 2 0 002 2 2 2 0 002-2 2 2 0 00-2-2zm-1 6a2 2 0 00-2 2v4a2 2 0 002 2h4.434l2.709 4.514 1.714-1.028-3-5A1 1 0 0017 14h-2v-4a2 2 0 00-2-2h-1zm-4 2.35c-2.327.826-4 3.043-4 5.65 0 3.309 2.691 6 6 6 2.607 0 4.824-1.673 5.65-4h-2.207c-.694 1.19-1.969 2-3.443 2-2.206 0-4-1.794-4-4 0-1.474.81-2.75 2-3.443V10.35z" />
  </svg>
)

export default Wheelchair
