export const fieldHasContent = (val) => {
  const emptyStrings = [
    '',
    '<p></p>',
    '<p><br></p>',
    '<p><br /></p>',
    '<p> </p>',
    '<p>  </p>',
    '<p>null</p>',
    '<p class="ql-direction-rtl ql-align-right"><br></p>'
  ]

  return val && emptyStrings.indexOf(val) === -1
}

export const requiredIf = (requiredCondition, requiredValue) => {
  if (requiredCondition && !requiredValue) return false
  return true
}

export const focusErrors = () => {
  const errorFields = document.getElementsByClassName('hf-error')
  if (errorFields.length) {
    for (var i = 0; i < errorFields.length; i++) {
      let scrollToY = errorFields[i].offsetTop - 180
      window.scrollTo(0, scrollToY)
      break
    }
  }
}
