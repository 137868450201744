import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl, intlShape } from 'react-intl'
import { fetchGuidebookData, guidebookReset } from 'redux/modules/guidebook'
import {
  initPrintPreferences,
  resetPrintPreferencesState
} from 'redux/modules/printPreferences'
import { fetchItems } from 'redux/modules/crud'
import { replace } from 'react-router-redux'
import Print from './Print.js'
import { CircularProgress } from 'react-md'
import currentUser from 'utils/CurrentUser'
import { sortData } from 'utils/Data'

class PrintContainer extends Component {
  componentWillReceiveProps(nextProps) {
    const { guidebook, guidebookKey, dispatch } = this.props
    if (guidebook.isLoading && !nextProps.guidebook.isLoading) {
      dispatch(
        fetchItems('categories', (response) => {
          dispatch(initPrintPreferences())
        })
      )
    }
    if (guidebookKey !== nextProps.guidebookKey) {
      dispatch(fetchGuidebookData(guidebookKey, null, false, true))
    }
    if (
      guidebook.data.key === 'loading' &&
      nextProps.guidebook.data.key !== 'loading'
    ) {
      const user = currentUser()
      if (user.id !== nextProps.guidebook.data.host_user_id) {
        dispatch(replace('/403'))
      }
    }
  }

  componentDidMount() {
    const { guidebookKey } = this.props
    this.props.dispatch(fetchGuidebookData(guidebookKey, null, false, true))
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    this.props.dispatch(guidebookReset())
    dispatch(resetPrintPreferencesState())
  }

  render() {
    const { dispatch, intl, guidebook, printPreferences, printReadyState } =
      this.props
    if (guidebook.data.id) {
      return (
        <Print
          guidebook={guidebook.data}
          printPreferences={printPreferences}
          printReadyState={printReadyState}
          intl={intl}
          dispatch={dispatch}
        />
      )
    }

    return <CircularProgress id="loader" />
  }
}

PrintContainer.propTypes = {
  guidebookKey: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired
}

function mapStateToProps(state, props) {
  const guidebook = state.guidebook
  const printPreferences = state.printPreferences
  const printReadyState = state.printReadyState
  const listData = state.list
  return {
    guidebook,
    categories: listData.categories.data
      ? sortData(listData.categories.data, 'order')
      : [],
    printPreferences,
    printReadyState
  }
}

export default connect(mapStateToProps)(injectIntl(PrintContainer))
