import React from 'react'

const Cat = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`cat`}</title>
    <path d="M19.6 2.5h-.4c-.2.2-.2.6 0 .8h.8c1.6.6 1.8 3.5 1.4 4.9-.4 1.4-.4 1.2-1 1.6h-10l-6-4.6v1.6L2.8 8.4 2 10.1s.4 1 .8 1 2.4-.8 2.4-.8l2.4 2.2v7.7c0 .4.4.8.8.8s.8-.4.8-.8v-4.7H19v5.1c0 .4.4.8.8.8.5 0 .8-.4.8-.8v-9.8c.6-.4 1-1.2 1.2-2 .5-.8 0-5.5-2.2-6.3z" />
  </svg>
)

export default Cat
