import React from 'react'

const Grapes = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`grapes`}</title>
    <path d="M15.5 1c-3.375 4 0 7.5 0 7.5s3.375-3.5 0-7.5zm0 7.5s3.5 3.375 7.5 0c-4-3.375-7.5 0-7.5 0zM12 6c-1.06 0-1.993.566-2.527 1.406A2.949 2.949 0 008 7c-1.645 0-3 1.355-3 3 0 .536.155 1.035.406 1.473A3.003 3.003 0 004 14c0 .536.155 1.035.406 1.473A3.003 3.003 0 003 18c0 1.645 1.355 3 3 3 1.06 0 1.993-.566 2.527-1.406.438.25.937.406 1.473.406 1.06 0 1.993-.566 2.527-1.406.438.25.937.406 1.473.406 1.645 0 3-1.355 3-3 0-.536-.155-1.035-.406-1.473A3.003 3.003 0 0018 12c0-1.645-1.355-3-3-3 0-1.645-1.355-3-3-3zm0 2c.564 0 1 .436 1 1 0 .564-.436 1-1 1-.564 0-1-.436-1-1 0-.564.436-1 1-1zM8 9c.564 0 1 .436 1 1 0 .564-.436 1-1 1-.564 0-1-.436-1-1 0-.564.436-1 1-1zm7 2c.564 0 1 .436 1 1 0 .564-.436 1-1 1-.564 0-1-.436-1-1 0-.564.436-1 1-1zm-4 1c.564 0 1 .436 1 1 0 .564-.436 1-1 1-.564 0-1-.436-1-1 0-.564.436-1 1-1zm-4 1c.564 0 1 .436 1 1 0 .564-.436 1-1 1-.564 0-1-.436-1-1 0-.564.436-1 1-1zm7 2c.564 0 1 .436 1 1 0 .564-.436 1-1 1-.564 0-1-.436-1-1 0-.564.436-1 1-1zm-4 1c.564 0 1 .436 1 1 0 .564-.436 1-1 1-.564 0-1-.436-1-1 0-.564.436-1 1-1zm-4 1c.564 0 1 .436 1 1 0 .564-.436 1-1 1-.564 0-1-.436-1-1 0-.564.436-1 1-1z" />
  </svg>
)

export default Grapes
