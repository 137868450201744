import React from 'react'

const Pizzaoven = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`pizzaoven`}</title>
    <path d="M12 16.9c.6 0 1-.5 1-1 0-.3-.1-.6-.2-.9-.3.3-.6.4-1 .6s-.6.3-.6.7c0 .4.3.7.7.7l.1-.1zm.4-4c.9.7 1.4 1.7 1.4 2.9 0 1-.8 1.7-1.7 1.7s-1.7-.8-1.7-1.7c0-.7.3-1.4.7-1.9v.1c0 .4.3.7.7.7.4 0 .7-.3.7-.9.1-.3 0-.6-.1-.9z" />
    <path d="M20.7 17.5v-.6c0-4.5-3-8.1-6.9-8.1V1.3h-3.5v7.5c-3.8.1-6.9 3.8-6.9 8.1v.6H1v.6h22v-.6h-2.3zm-11.6 0v-1.3c0-1.9 1.3-3.5 3-3.5s3 1.6 3 3.5v1.3h-6z" />
  </svg>
)

export default Pizzaoven
