import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl, intlShape } from 'react-intl'
import { LinearProgress, FontIcon } from 'react-md'
import { OnAtMostPhablet, OnAtLeastTablet } from 'components/ScreenSize'
import MarketplaceCard from './MarketplaceCard'
import { fetchMktplItems } from 'redux/modules/marketplace'
import { stringToId } from 'utils/Strings'

const propTypes = {
  marketplace_items: PropTypes.array,
  guidebookKey: PropTypes.string,
  tabColor: PropTypes.string,
  // helper for internationalization
  recsOnly: PropTypes.bool,
  intl: intlShape.isRequired
}

class MarketplaceTab extends Component {
  constructor(props) {
    super(props)
    this.tabRef = null
    this.state = {
      servicesLoaded: false,
      servicesError: false,
      services: []
    }
  }

  componentDidMount() {
    fetchMktplItems(this.props.guidebookKey).then((data) => {
      if (data.length) {
        this.setState({
          services: data,
          servicesLoaded: true,
          servicesError: false
        })
      } else {
        this.setState({ servicesLoaded: true, servicesError: true })
      }
    })
  }

  buildMarketplaceItems = (items) => {
    const { guidebookKey, recsOnly } = this.props
    return items.map((item, idx) => {
      let initVisible = false
      const matchParams = this.context.router.route.match.params
      if (stringToId(`mktpl-${item.title}`, '-') === matchParams.card_title) {
        initVisible = true
      }

      return (
        <MarketplaceCard
          key={'mktpl_' + item.uid}
          marketplace_item={item}
          initialVisible={initVisible}
          guidebookKey={guidebookKey}
          recsOnly={recsOnly}
        />
      )
    })
  }

  columniseMarketplaceItems = (items) => {
    const grid = {
      0: [],
      1: []
    }
    items.forEach(function (item, idx) {
      const pos = idx % 2
      let gridArr = grid[pos]
      if (!gridArr) {
        gridArr = []
        grid[pos] = gridArr
      }
      gridArr.push(item)
    })
    return grid
  }

  renderCards = () => {
    if (!this.state.servicesLoaded) {
      const bgStyle = { background: '#cccccc' }
      const progressStyle = { background: this.props.tabColor }
      return (
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--12 md-text-center">
            <LinearProgress
              id="status_check"
              style={bgStyle}
              progressStyle={progressStyle}
            />
            <div>Loading Marketplace...</div>
          </div>
        </div>
      )
    } else if (this.state.servicesError) {
      const marginStyle = { marginTop: '24px' }
      return (
        <div className="md-grid md-grid--no-spacing" style={marginStyle}>
          <div className="md-cell md-cell--12 md-text-center">
            <FontIcon className="error" key="error">
              error_outline
            </FontIcon>
          </div>
          <div className="md-cell md-cell--12 md-text-center">
            Unfortunately, something went wrong.
            <br />
            Please try reloading the page.
          </div>
        </div>
      )
    } else {
      const marketplace_items = this.state.services
      const items = this.buildMarketplaceItems(marketplace_items)
      const columns = this.columniseMarketplaceItems(items)
      return (
        <div>
          <OnAtMostPhablet>
            <div className="md-grid">
              <div className="md-cell md-cell--4 md-cell--4-tablet md-cell--4-phone">
                {items}
              </div>
            </div>
          </OnAtMostPhablet>
          <OnAtLeastTablet>
            <div className="md-grid">
              <div className="md-cell md-cell--6 md-cell--4-tablet">
                {columns[0]}
              </div>
              <div className="md-cell md-cell--6 md-cell--4-tablet">
                {columns[1]}
              </div>
            </div>
          </OnAtLeastTablet>
        </div>
      )
    }
  }

  render() {
    const content = this.renderCards()
    return (
      <div
        className="md-cell md-cell--12"
        ref={(div) => {
          this.tabRef = div
        }}
      >
        {content}
      </div>
    )
  }
}

MarketplaceTab.propTypes = propTypes
MarketplaceTab.contextTypes = {
  router: PropTypes.object
}

export default connect(null)(injectIntl(MarketplaceTab))
