import React from 'react'

const MonetizationOn = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`monetization_on`}</title>
    <path d="M13.406 18.094c1.734-.328 3.141-1.313 3.141-3.141 0-2.531-2.203-3.422-4.219-3.938S9.703 9.937 9.703 9.093c0-.938.891-1.641 2.391-1.641 1.594 0 2.156.75 2.203 1.875h1.969c-.047-1.547-.984-2.906-2.859-3.375V3.983h-2.672v1.969c-1.734.375-3.094 1.453-3.094 3.188 0 2.063 1.688 3.047 4.172 3.656 2.25.516 2.672 1.313 2.672 2.156 0 .609-.422 1.594-2.391 1.594-1.828 0-2.578-.844-2.672-1.875H7.453c.094 1.922 1.594 3 3.281 3.375v1.969h2.672v-1.922zM12 2.016c5.531 0 9.984 4.453 9.984 9.984S17.531 21.984 12 21.984 2.016 17.531 2.016 12 6.469 2.016 12 2.016z" />
  </svg>
)

export default MonetizationOn
