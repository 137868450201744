import React from 'react'

const DirectionsBoat = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`directions_boat`}</title>
    <path d="M6 6v3.984l6-1.969 6 1.969V6H6zM3.938 18.984l-1.875-6.656c-.224-.672.187-1.109.656-1.266l1.266-.422V5.999c0-1.078.938-2.016 2.016-2.016h3v-3h6v3h3c1.078 0 2.016.938 2.016 2.016v4.641l1.266.422c.47.157.881.592.656 1.266l-1.875 6.656h-.047c-1.594 0-3.047-.844-4.031-1.969-.984 1.125-2.391 1.969-3.984 1.969s-3-.844-3.984-1.969c-.984 1.125-2.438 1.969-4.031 1.969H3.94zM20.016 21h1.969v2.016h-1.969a8.824 8.824 0 01-4.031-.984 8.553 8.553 0 01-7.969 0 8.827 8.827 0 01-4.031.984H2.016V21h1.969c1.406 0 2.813-.469 4.031-1.313 2.438 1.688 5.531 1.688 7.969 0C17.204 20.531 18.61 21 20.016 21z" />
  </svg>
)

export default DirectionsBoat
