import React from 'react'

const Gift = (props) => (
  <svg width={24} height={24} viewBox="0 0 28 32" {...props}>
    <title>{`gift`}</title>
    <path d="M14 30h10V20H14v10zM2 30h10V20H2v10zm11.992-18.244c.983-.109 2.09-.231 2.73-.304 2.621-.292 4.98-2.652 5.271-5.272.291-2.617-1.594-4.504-4.209-4.21-2.045.229-3.915 1.718-4.785 3.604-.87-1.887-2.74-3.376-4.784-3.606-2.616-.292-4.504 1.595-4.21 4.212.292 2.62 2.652 4.979 5.271 5.272.642.072 1.748.196 2.731.304-.016.132-.008.244-.008.244h2s.008-.112-.008-.244zm3.363-7.36c1.414-.158 2.438.861 2.277 2.277-.156 1.416-1.434 2.692-2.848 2.851-1.418.157-2.438-.862-2.279-2.278s1.436-2.692 2.85-2.85zM9.216 9.524c-1.414-.158-2.689-1.435-2.848-2.851s.862-2.436 2.275-2.277c1.416.157 2.692 1.434 2.851 2.85s-.862 2.436-2.278 2.278zM14 12v6h12v-6H14zM0 18h12v-6H0v6z" />
  </svg>
)

export default Gift
