import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DocumentMeta from 'react-document-meta'
import LoginForm from './LoginForm'

class LoginWrapper extends Component {
  render() {
    const { auth } = this.props
    const inProgress = auth.checkingToken ? (
      <div>Please wait...</div>
    ) : (
      <LoginForm {...this.props} />
    )

    const title = 'Login'
    const description = 'Login to your Hostfully Guidebook Platform Account'
    const twitter_site = '@hostfully_'
    const fb_app_id = process.env.REACT_APP_FB_APP_ID
    let urlBase = process.env.REACT_APP_BASE_URL
    const url = `${urlBase}/login`
    const meta = {
      title: title,
      description: description,
      canonical: url,
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'guidebook,hostfully,vacation,rental,airbnb,login',
          'twitter:card': 'summary_large_image',
          'twitter:site': twitter_site,
          'twitter:image:alt': 'Login Form',
          'dc.title': title,
          'dc.description': description
        },
        property: {
          'fb:app_id': fb_app_id,
          'og:type': 'Website',
          'og:title': title,
          'og:description': description,
          'og:image':
            'https://storage.googleapis.com/hostfully-wp-1/hostfully-5-star-hospitality-sign-up.jpg',
          'og:image:alt': 'Login Form',
          'og:url': url,
          'og:site_name': 'Hostfully'
        }
      }
    }

    return (
      <DocumentMeta {...meta}>
        <div>{inProgress}</div>
      </DocumentMeta>
    )
  }
}

LoginWrapper.propTypes = {
  dispatch: PropTypes.func
}

function mapStateToProps(state, props) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(LoginWrapper)
