import QRCode from 'qrcode.react'
import React, { Component } from 'react'
import { Button, DialogContainer, FontIcon, SelectField } from 'react-md'
import { connect } from 'react-redux'

import { addToast } from 'redux/modules/toast'
import { downloadQRCode } from 'utils/QRCode'
import { buildGuidebookUrl } from 'utils/Urls'

import './SharePanel.scss'

class SharePanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showDialog: false,
      locales: ['Default', ...props.locales],
      defaultLanguage: props.language || 'Default',
      gbUrl: buildGuidebookUrl(
        props.gbKey,
        props.language,
        null,
        false,
        props.domain?.status === 'approved' ? props.domain.domain : null,
        props.isPublished
      )
    }
  }

  qrRef = {}

  copyUrl = async () => {
    await navigator.clipboard.writeText(this.state.gbUrl)
    this.props.dispatch(
      addToast(
        'The Guidebook URL has been copied to the clipboard.',
        null,
        true
      )
    )
  }

  downloadQR = () => {
    const fileName = `QR_${this.props.gbKey}.png`

    downloadQRCode(this.qrRef.firstChild, fileName)
  }

  setLanguage = (value) => {
    const { gbKey, domain, isPublished } = this.props
    const locale = value === 'Default' ? null : value

    this.setState({
      gbUrl: buildGuidebookUrl(
        gbKey,
        locale,
        null,
        false,
        domain?.status === 'approved' ? domain.domain : null,
        isPublished
      )
    })
  }

  openDialog = (e) => {
    e.stopPropagation()
    this.setState({ showDialog: true })
  }

  closeDialog = () => {
    this.setState({ showDialog: false })
  }

  render() {
    return (
      <div>
        <Button
          iconEl={
            <FontIcon>
              {navigator.userAgent.includes('like Mac') ? 'ios_share' : 'share'}
            </FontIcon>
          }
          onClick={this.openDialog}
          secondary
          flat
        >
          SHARE
        </Button>
        <DialogContainer
          id="shareDialog"
          title="Share your Guidebook"
          dialogClassName="hf-share-dialog"
          contentClassName="hf-share-content"
          titleClassName="hf-share-title"
          footerClassName="hf-share-footer"
          visible={this.state.showDialog}
          onHide={this.closeDialog}
          actions={[
            {
              onClick: this.closeDialog,
              primary: true,
              raised: true,
              flat: false,
              children: 'Close'
            }
          ]}
          portal
        >
          {this.state.locales.length > 1 ? (
            <div className="hf-share-content-language">
              <p className="md-subheading-1">Custom language link: </p>
              <SelectField
                id="languages"
                label="Languages"
                menuItems={this.state.locales}
                onChange={this.setLanguage}
                defaultValue={this.state.defaultLanguage}
                simplifiedMenu
              />
            </div>
          ) : null}
          <p id="sharetext" className="md-subheading-1">
            This is your guidebook link:{' '}
            <span className="md-font-bold">{this.state.gbUrl}</span>
          </p>
          <div className="hf-share-content-options">
            <Button
              id="copyLink"
              iconEl={<FontIcon>content_copy</FontIcon>}
              onClick={this.copyUrl}
              secondary
              raised
            >
              Copy link
            </Button>
            <Button
              id="goTo"
              iconEl={<FontIcon>visibility</FontIcon>}
              href={this.state.gbUrl}
              target="_blank"
              rel="noopener noreferrer"
              secondary
              raised
            >
              Go to Guidebook
            </Button>
          </div>
          <p className="md-subheading-1">
            You can also download and share the QR code for your Guidebook:
          </p>
          <div ref={(ref) => (this.qrRef = ref)}>
            <QRCode
              id="gbQRCode"
              renderAs="svg"
              size={192}
              level="M"
              fgColor="#000"
              bgColor="#fff"
              value={this.state.gbUrl}
              includeMargin={false}
            />
          </div>
          <Button
            id="downloadQR"
            iconEl={<FontIcon>download</FontIcon>}
            onClick={this.downloadQR}
            secondary
            raised
          >
            Download QR Code
          </Button>
          <p className="md-body-1">
            To learn more about best practices when sharing it with your guests,
            refer to{' '}
            <a
              target="_blank"
              className="hf-link"
              rel="noopener noreferrer"
              href="https://help.hostfully.com/en/articles/2108793-how-to-share-your-hostfully-guidebook-with-your-guests"
            >
              this article
            </a>
            .
          </p>
        </DialogContainer>
      </div>
    )
  }
}

export default connect()(SharePanel)
