import React from 'react'

const Kitchen = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`kitchen`}</title>
    <path d="M7 6V2.7H3.7V6L1.3 7.3h8L7 6zM1.3 12.8v8.5h8v-8.5h-8zm6.5.5c.3 0 .5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5c0-.2.3-.5.5-.5zm-2.5 0c.3 0 .5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5c0-.2.3-.5.5-.5zm-2.5 0c.3 0 .5.2.5.5s-.2.5-.5.5-.5-.2-.5-.5c0-.2.3-.5.5-.5zm5.5 6.4c0 .3-.2.6-.5.7H3c-.3-.1-.5-.3-.5-.6v-3.5c0-.3.2-.6.5-.6h4.7c.3.1.5.3.5.6l.1 3.4zM9.7 18h6v3.3h-6V18zM13 12.8h-.2v-1.2c.1-.2.3-.3.5-.3.3 0 .5.2.5.5h.2c0-.4-.3-.7-.7-.7s-.7.3-.7.7v1h-3v5h6v-5H13zM16 4.7v16.7h6.7V4.7H16zm.8 6.6h-.5v-2h.5v2z" />
  </svg>
)

export default Kitchen
