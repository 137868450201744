import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, Paper, TextField } from 'react-md'

import { createDefaultProvider } from 'redux/modules/orbirental/connect'
import currentUser from 'utils/CurrentUser'
import { debounce } from 'utils/Debounce'

const propTypes = {
  onConnect: PropTypes.func.isRequired
}
const defaultProps = {}

/**
 * Form to create default provider in orbirental
 * accept a first/last name
 * pass them along to api to create provider in orbirental
 */
class DefaultProviderForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showErrors: {
        fName: false,
        lName: false,
        email: false
      },
      connectEnabled: true
    }
    this.fNameRef = null
    this.lNameRef = null
    this.emailRef = null
    this.handleInputChange = debounce(this.handleInputChange.bind(this), 250)
  }

  handleConnectClick = () => {
    const fName = this.fNameRef.value
    const lName = this.lNameRef.value
    const email = this.emailRef.value
    // errors is the inverse of the return from validation
    const errors = {
      fName: !this.validateInput('fName', fName),
      lName: !this.validateInput('lName', lName),
      email: !this.validateInput('email', email)
    }
    if (errors['fName'] || errors['lName'] || errors['email']) {
      // errors - update error state
      this.setState({ showErrors: errors })
    } else {
      // no errors - disable button and update error state, then call function to create agency
      this.setState({ connectEnabled: false, showErrors: errors }, () => {
        createDefaultProvider(fName, lName, email).then((data) => {
          // call onConnect callback
          this.props.onConnect(data)
        })
      })
    }
  }

  handleInputChange = (inputName, value) => {
    const valid = this.validateInput(inputName, value)
    const errors = Object.assign({}, this.state.showErrors)
    errors[inputName] = !valid
    this.setState({ showErrors: errors })
  }

  validateInput = (inputName, value) => {
    return value.length > 0
  }

  render() {
    const user = currentUser()

    return (
      <Paper className="hf-card hf-marketplace-dashboard-card">
        <h3 className="hf-secondary-title">
          Setup default Marketplace Provider
        </h3>
        <p className="md-body-1">
          You need at least one Marketplace Provider to create an offer for your
          Marketplace Company. Please confirm your name and email address - this
          will be the default Marketplace Provider for your Marketplace Company.
        </p>
        <div className="hf-input-container">
          <TextField
            id="first_name"
            ref={(input) => {
              this.fNameRef = input
            }}
            placeholder="First name"
            defaultValue={user.user.first_name ? user.user.first_name : ''}
            error={this.state.showErrors.fName}
            errorText="Please enter a first name."
            className="hf-input"
            onChange={(value) => {
              this.handleInputChange('fName', value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                return this.handleConnectClick()
              }
            }}
          />
        </div>
        <div className="hf-input-container">
          <TextField
            id="last_name"
            ref={(input) => {
              this.lNameRef = input
            }}
            placeholder="Last name"
            defaultValue={user.user.last_name ? user.user.last_name : ''}
            error={this.state.showErrors.lName}
            errorText="Please enter a last name."
            className="hf-input"
            onChange={(value) => {
              this.handleInputChange('lName', value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                return this.handleConnectClick()
              }
            }}
          />
        </div>
        <div className="hf-input-container">
          <TextField
            id="email"
            ref={(input) => {
              this.emailRef = input
            }}
            placeholder="Email address"
            defaultValue={user.user.email ? user.user.email : ''}
            error={this.state.showErrors.email}
            errorText="Please enter an email address."
            className="hf-input"
            onChange={(value) => {
              this.handleInputChange('email', value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                return this.handleConnectClick()
              }
            }}
          />
        </div>
        <Button
          disabled={!this.state.connectEnabled}
          onClick={this.handleConnectClick}
          primary
          raised
        >
          Save
        </Button>
      </Paper>
    )
  }
}

DefaultProviderForm.propTypes = propTypes
DefaultProviderForm.defaultProps = defaultProps

export default DefaultProviderForm
