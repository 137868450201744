import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { actions } from 'react-redux-form'
import { allLocales, localeMessages } from 'constants/UIConstants'
import Checkbox from 'react-md/lib/SelectionControls/Checkbox'

class LocaleForm extends Component {
  constructor(props) {
    super(props)
    this._localeSelection = this._localeSelection.bind(this)
  }

  _localeSelection = (checked, e) => {
    const { dispatch, editModel } = this.props
    const locales = this.props.locales || []
    const locale = e.target.name
    // TODO BA - this is a bit ugly, I think because react-redux-form uses immutable.js
    const spliced = locales.reduce(function (arr, l) {
      if (l !== locale) {
        arr.push(l)
      }
      return arr
    }, [])
    const locs = checked ? locales.concat([locale]) : spliced
    const id = editModel + '.locales'
    dispatch(actions.change(id, locs))
  }

  render() {
    const locales = this.props.locales || []
    const { disabled } = this.props
    const { formatMessage } = this.props.intl
    const self = this
    const isDisabled = disabled ? true : false
    const localeInputs = allLocales.map(function (locale) {
      const checked = locales.indexOf(locale) > -1
      const label = formatMessage(localeMessages[locale])
      return (
        <div key={locale}>
          <Checkbox
            className="md-cell md-cell--2"
            name={locale}
            id={locale}
            label={label}
            onChange={self._localeSelection}
            checked={checked}
            disabled={isDisabled}
          />
        </div>
      )
    })
    return <div className="md-grid">{localeInputs}</div>
  }
}

LocaleForm.propTypes = {
  editModel: PropTypes.string
}

export default LocaleForm
