import React from 'react'

const Hiking2 = (props) => (
  <svg viewBox="0 0 24 24" width={24} height={24} {...props}>
    <title>{`hiking_2`}</title>
    <path d="M13 2a1.999 1.999 0 100 4 1.999 1.999 0 100-4zM8.625 6.094C7.273 6.25 6.043 7.148 5.594 8.5L5 10.5c-.3.898.29 1.887 1.188 2.188 1 .3 1.917-.188 2.218-1.188L10 6.187a3.643 3.643 0 00-1.375-.093zm2.281.25l-.594 1.906.063.031-1.188 4.031c-.199.801.118 1.594.72 2.094l2.937 2.375c.105.227.156.473.156.719V22h2v-4.5c0-.82-.27-1.633-.813-2.313l-.03-.03-1.938-2.25.969-3.407.093.094.813.812c.636.637 1.562.836 2.312.688L18 10.78V22h1V7h-1v1.719l-2 .406c-.25.05-.336.04-.5-.125l-.063-.063-.718-.75a5.505 5.505 0 00-2.531-1.437h-.032zm-1.5 9.25L7.094 22h2.093L11 17z" />
  </svg>
)

export default Hiking2
