import React from 'react'

const Brightness3 = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`brightness_3`}</title>
    <path d="M9 2.016c5.531 0 9.984 4.453 9.984 9.984S14.531 21.984 9 21.984c-1.031 0-2.063-.141-3-.422 4.078-1.266 6.984-5.063 6.984-9.563S10.078 3.702 6 2.436a10.558 10.558 0 013-.422z" />
  </svg>
)

export default Brightness3
