import React from 'react'

const Cleaning = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`cleaning`}</title>
    <path d="M12.2 7.6c.2-.1.3-.3.3-.5v-.6c0-.4-.3-.7-.6-.7.3-.1.5-.3.5-.6v-.5c0-.2-.1-.4-.3-.5l1.5-.3L16 7.6V3.4l1.5-.9-1.5-.7V1H8.4L6.7 5.1l1.4-.3L6.9 8c-.3.3-.5.8-.5 1.2v11.9c0 1 .8 1.8 1.8 1.8h7.3c1 0 1.8-.8 1.8-1.8v-7c.1-3.1-2.1-5.8-5.1-6.5z" />
  </svg>
)

export default Cleaning
