import React from 'react'

const Gate = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`gate`}</title>
    <path d="M1 7.4h1.2v14.7H1V7.4zM2.8 7.4H4v14.7H2.8V7.4zM4.7 7.4h1.2v14.7H4.7V7.4zM6.5 7.4h1.2v14.7H6.5V7.4zM21.8 7.4H23v14.7h-1.2V7.4zM8.3 7.4h1.2v14.7H8.3V7.4z" />
    <path d="M20.7 11.2h1.2c.3 0 .5.2.5.5v.1c0 .3-.2.5-.5.5h-1.2c-.3 0-.5-.2-.5-.5v-.1c0-.3.3-.5.5-.5zM20.9 19h1.2c.3 0 .5.2.5.5v.1c0 .3-.2.5-.5.5h-1.2c-.3 0-.5-.2-.5-.5v-.1c0-.3.2-.5.5-.5z" />
    <path d="M21.2 6.8c0-.3-.3-.6-.6-.6l-.5.3c-.2.3-.5.5-.9.5L18 6.7c-2.9-1.1-4.9-2.1-6.6-3.5v-.7c0-.3-.3-.6-.6-.6s-.6.3-.6.6v4.3h-.8c-.3 0-.5.2-.5.5s.2.5.5.5h.8v13.8H20v-1.2h-1.2V7.7H20v13.9h1.2V6.8zm-8.6 13.5h-1.2V9.7h1.2v10.6zm-.9-12.4v-.1c0-.3.2-.5.5-.5s.5.2.5.5c0 .2-.1.4-.3.4v.3h-.5v-.2c-.1-.1-.2-.2-.2-.4zm.9-1.1h-1.2V4.2l1.2.9v1.7zm2.5 13.5h-1.2V9.7h1.2v10.6zm0-13.5h-1.2v-.9l1.2.6v.3zm2.4.6v12.8h-1.2V7l1.4.5h-.2z" />
  </svg>
)

export default Gate
