import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'

import { OnAtLeastTablet, OnAtMostPhablet } from 'components/ScreenSize'

import AiItineraryCard from './AiItineraryCard'

const propTypes = {
  tabColor: PropTypes.string,
  // helper for internationalization
  intl: intlShape.isRequired
}

class AiTab extends Component {
  constructor(props) {
    super(props)
    this.tabRef = null
    this.state = {}
  }

  // componentDidMount() {}

  buildAICards = () => {
    let initVisible = false
    const matchParams = this.context.router.route.match.params
    if ('itinerary' === matchParams.card_title) {
      initVisible = true
    }

    return [
      <AiItineraryCard
        key={'ai-itinerary'}
        initialVisible={initVisible}
      />
    ]
  }

  columniseItems = (items) => {
    const grid = {
      0: [],
      1: []
    }
    items.forEach(function (item, idx) {
      const pos = idx % 2
      let gridArr = grid[pos]
      if (!gridArr) {
        gridArr = []
        grid[pos] = gridArr
      }
      gridArr.push(item)
    })
    return grid
  }

  renderCards = () => {
    const items = this.buildAICards()
    const columns = this.columniseItems(items)
    return (
      <div>
        <OnAtMostPhablet>
          <div className="md-grid">
            <div className="md-cell md-cell--4 md-cell--4-tablet md-cell--4-phone">
              {items}
            </div>
          </div>
        </OnAtMostPhablet>
        <OnAtLeastTablet>
          <div className="md-grid">
            <div className="md-cell md-cell--6 md-cell--4-tablet">
              {columns[0]}
            </div>
            <div className="md-cell md-cell--6 md-cell--4-tablet">
              {columns[1]}
            </div>
          </div>
        </OnAtLeastTablet>
      </div>
    )
  }

  render() {
    const content = this.renderCards()
    return (
      <div
        className="md-cell md-cell--12"
        ref={(div) => {
          this.tabRef = div
        }}
      >
        {content}
      </div>
    )
  }
}

AiTab.propTypes = propTypes
AiTab.contextTypes = {
  router: PropTypes.object
}

export default connect(null)(injectIntl(AiTab))
