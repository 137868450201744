import React from 'react'

const Terrain = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`terrain`}</title>
    <path d="M14.016 6l9 12H.985l6-8.016 4.5 6 1.594-1.172-2.813-3.797z" />
  </svg>
)

export default Terrain
