import React from 'react'

const Towels = (props) => (
  <svg width={24} height={24} viewBox="0 0 31 32" {...props}>
    <title>{`towels`}</title>
    <path d="M31.162 17.592c-.168-1.675-.67-3.686-2.01-4.021s-2.681 2.178-5.194 5.361c-.67.838-1.34 1.34-3.351 1.508-6.869.168-11.728.335-18.597.168l-.67.67a2.843 2.843 0 00-1.004 2.526l-.001-.013c.335 2.01 1.508 3.016 3.518 3.183 4.859.168 9.885-.168 14.743 0 .838 0 1.508-.503 1.508-1.173s-.335-1.34-1.34-1.34H3.853c-.335 0-1.005.168-1.005-.838s.67-.67 1.005-.67h14.911c1.675 0 2.513 1.34 2.513 2.681s-.838 2.513-2.681 2.513H2.68c-.838 0-1.34.335-1.34 1.173s.335 1.508 1.173 1.508h15.581c2.01 0 3.686-.67 5.026-2.178l.838-1.005 5.864-6.366c.838-1.173 1.34-2.346 1.34-3.686z" />
    <path d="M10.89 16.586h7.874c1.675 0 2.681-1.173 2.681-2.513s-.838-2.681-2.513-2.681H3.853c-.335 0-1.005 0-1.005.67s.67.67 1.005.838h14.743c1.005 0 1.34.838 1.34 1.34s-.838 1.34-1.508 1.173c-4.859-.168-9.885 0-14.743 0C1.842 15.245.502 14.24.167 12.23a2.841 2.841 0 011.001-2.51l.004-.004 6.031-5.361 2.178-1.843a5.178 5.178 0 013.659-1.508H25.8c2.848 0 5.194 2.178 5.361 5.194s-.503 2.513-1.34 3.518l-5.864 6.367-.838 1.005c-1.34 1.508-3.016 2.178-5.026 2.178H2.512c-.838 0-1.173-.503-1.173-1.508s.503-1.173 1.34-1.173z" />
  </svg>
)

export default Towels
