import React from 'react'

const Hairdryer = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`hairdryer`}</title>
    <path d="M17.7 6l1.3.3 2.7 1V1.8L17.3 3 11 2H4.3v.7c-.3 0-.6.3-.6.6v3.3c0 .4.3.7.6.7V8H7l.7.7v8l.7.7h.3c0 .2.1.3.3.3v2.2c0 .7.2 1.5.7 2h1c1-.2 2-.7 3-1.3s1.5-1 2.3-1.2c.2-.1.5-.1.8-.1 1 0 2 .4 2.7 1.1h.2c-.7-.7-1.7-1.1-2.8-1.1-.4 0-.7 0-1 .1-.8.2-1.7.7-2.3 1.2s-1.8 1.2-2.8 1.3H10c-.5-.3-.5-.8-.5-1.5l.3-.4v-2.2c.2 0 .3-.1.3-.3h.3l.7-.7V12h.2c.2 0 .3-.1.3-.3V8.8l.7-.7L17.7 6zm-12.4.7H5v-3h.3v3zm.7 0h-.3v-3H6v3zm.7 0h-.4v-3h.3v3z" />
  </svg>
)

export default Hairdryer
