export const ADD_TOAST = 'ADD_TOAST'
export const CLEAR_TOAST = 'CLEAR_TOAST'

const initialState = {
  toast: []
}

/* Reducers */
var toastId = 0
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_TOAST:
      return {
        id: toastId++,
        text: action.text,
        action: action.action,
        autohide: action.autohide
      }
    case CLEAR_TOAST:
      return null
    default:
      return state
  }
}

/* Actions */
export function addToast(text, action, autohide) {
  return {
    type: ADD_TOAST,
    text: text,
    action: action,
    autohide: autohide
  }
}

// This should only be dispatched from the Toast component.
// You should not need to call this.
export function clearToast() {
  return {
    type: CLEAR_TOAST
  }
}
