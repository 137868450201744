import React from 'react'

const Build = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`build`}</title>
    <path d="M22.688 18.984c.422.281.422.984-.094 1.406l-2.297 2.297c-.422.422-.984.422-1.406 0l-9.094-9.094c-2.297.891-4.969.422-6.891-1.5C.89 10.077.375 7.077 1.593 4.687L5.999 9l3-3-4.313-4.313C7.077.609 10.077.984 12.092 3c1.922 1.922 2.391 4.594 1.5 6.891z" />
  </svg>
)

export default Build
