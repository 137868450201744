import React from 'react'

const Power = (props) => (
  <svg width={24} height={24} viewBox="0 0 32 32" {...props}>
    <title>{`power`}</title>
    <path d="M12 0L0 16h12L4 32l28-20H16L28 0z" />
  </svg>
)

export default Power
