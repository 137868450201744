import React from 'react'
import { ExpansionList, ExpansionPanel, FontIcon } from 'react-md'

export const formatDate = (date, useDMY, langCode) => {
  const DMY = useDMY || false
  // console.log({dmy: DMY});
  var day = date.toLocaleString(langCode, { day: 'numeric' }) // DD
  var month = date.toLocaleString(langCode, { month: 'long' }) // MMM
  var year = date.toLocaleString(langCode, { year: 'numeric' }) // YYYY
  if (DMY) {
    return `${day} ${month} ${year}`
  } else {
    return `${month} ${day}, ${year}`
  }
}

// TODO - make this use a live call to hours instead of using cached hours
export const renderHours = (rec, print) => {
  if (rec && rec.hours && rec.hours.length > 0 && !rec.ignore_hours) {
    const hourSections = []
    for (var i = 0; i < rec.hours.length; i++) {
      // first colon separates the day of the week and the hours
      const firstColonIdx = rec.hours[i].indexOf(':')
      const dayOfWeek = rec.hours[i].substring(0, firstColonIdx).trim()
      const timeString = rec.hours[i].substring(firstColonIdx + 1).trim()
      // split the value here - the first part is the day of the week
      // the rest is the hours of operation
      const labelClass = print
        ? 'md-cell md-cell--4 md-cell--4-tablet md-cell--2-phone'
        : 'md-cell md-cell--3 md-cell--1-desktop-offset md-cell--4-tablet md-cell--2-phone hf-hour-label'
      const valueClass = print
        ? 'md-cell md-cell--8 md-cell--4-tablet md-cell--2-phone'
        : 'md-cell md-cell--7 md-cell--4-tablet md-cell--2-phone hf-hour-value'
      hourSections.push(
        <div key={`hour-label-${i}`} className={labelClass}>
          {dayOfWeek}
        </div>
      )
      hourSections.push(
        <div key={`hour-value-${i}`} className={valueClass}>
          {timeString}
        </div>
      )
    }
    const iconStyle = { color: 'rgba(0, 0, 0, 0.87)' }

    if (print) {
      return (
        <div className="md-grid md-grid--no-spacing content-block-avoid">
          <div className="md-cell md-cell--12">
            <strong>Hours of operation</strong>
          </div>
          {hourSections}
        </div>
      )
    } else {
      return (
        <div className="md-grid md-grid--no-spacing">
          <ExpansionList className="md-cell md-cell--12">
            <ExpansionPanel
              label={
                <FontIcon primary style={iconStyle}>
                  access_time
                </FontIcon>
              }
              secondaryLabel={
                <span className="hf-hours-expansion-label">
                  Hours of operation
                </span>
              }
              footer={null}
            >
              <div className="md-grid md-grid--no-spacing">{hourSections}</div>
            </ExpansionPanel>
          </ExpansionList>
        </div>
      )
    }
  }
  return null
}
