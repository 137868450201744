import { initializeApp } from 'firebase/app'
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
}

export const remoteConfig = getRemoteConfig(initializeApp(firebaseConfig))

remoteConfig.defaultConfig = {
  gbHostOpenBeta: '[]'
}

remoteConfig.settings = {
  minimumFetchIntervalMillis: 30 * 60 * 1000, // 30 min
  fetchTimeoutMillis: 60 * 1000 // 1 minute
}

export const initializeFirebase = async () => {
  try {
    await fetchAndActivate(remoteConfig)
  } catch (e) {}
}
