import React from 'react'

const Flag = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`flag`}</title>
    <path d="M14.391 6h5.625v9.984h-7.031l-.375-1.969H6.985v6.984H5.016V3.983h9z" />
  </svg>
)

export default Flag
