import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import { defineMessages, injectIntl, intlShape } from 'react-intl'
import { OnAtMostPhablet, OnAtLeastTablet } from 'components/ScreenSize'
import RecommendationCard from './RecommendationCard'
import { parseBool, parseQueryString } from 'utils/Urls'

const propTypes = {
  recommendations: PropTypes.array,
  hiddenRecommendations: PropTypes.array,
  recsOnly: PropTypes.bool,
  // helper for internationalization
  intl: intlShape.isRequired
}

const defaultProps = {
  recsOnly: false
}

class RecommendationsTab extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.tabRef = null
    this.state = {}
  }

  formatMessage = this.props.intl.formatMessage
  messages = defineMessages({
    mapMarkersNotVisible: {
      id: 'gb.MapMarkersNotVisible',
      defaultMessage:
        '{count} recommendation(s) are not shown. Move/Zoom the map to see more.'
    },
    sponsoredRecsHidden: {
      id: 'gb.SponsoredRecsHidden',
      defaultMessage: 'Some promotional tours and activities may be hidden.'
    },
    sponsoredRecsLinkText: {
      id: 'gb.SponsoredRecsLinkText',
      defaultMessage: 'Click here to view all recommendations.'
    }
  })

  buildRecommendations = (recommendations) => {
    const { guidebook, category, recsOnly } = this.props
    return recommendations.map((rec) => {
      return (
        <RecommendationCard
          key={'rec_' + rec.id}
          recommendation={rec}
          guidebook={guidebook}
          category={category}
          recsOnly={recsOnly}
        />
      )
    })
  }

  columniseRecommendations = (recs) => {
    const grid = {
      0: [],
      1: []
    }
    recs.forEach(function (rec, idx) {
      const pos = idx % 2
      let gridArr = grid[pos]
      if (!gridArr) {
        gridArr = []
        grid[pos] = gridArr
      }
      gridArr.push(rec)
    })
    return grid
  }

  checkSponsoredRecCookie = (guidebookKey) => {
    let showAll = false
    const qs = parseQueryString(this.context.router.route.location.search)
    const sponsoredCookie = this.checkSponsoredCookie(guidebookKey)
    if (sponsoredCookie) showAll = parseBool(sponsoredCookie, false)
    if (qs.hasOwnProperty('ss')) {
      showAll = parseBool(qs.ss, false)
    }
    return showAll
  }

  checkSponsoredCookie = (key) => {
    const { cookies } = this.props
    const cookieName = `hf_ss_${key}`
    const cookieVal = cookies.get(cookieName)
    return cookieVal
  }

  renderHiddenRecsDiv = () => {
    const { guidebook, category } = this.props
    const hiddenMessage = this.formatMessage(this.messages.sponsoredRecsHidden)
    const linkText = this.formatMessage(this.messages.sponsoredRecsLinkText)
    const linkHref = window.location.href.split('?')[0] + '?ss=true'

    const has_sponsored_recs =
      guidebook.sponsoredRecommendations &&
      guidebook.sponsoredRecommendations.length > 0

    if (
      !has_sponsored_recs ||
      ['attractions', 'activities'].indexOf(category.name) === -1
    ) {
      return null
    }
    return (
      <div className="md-grid">
        <div className="md-cell md-cell--12 md-text-center">
          {hiddenMessage}&nbsp;
          <a className="hf-link" href={linkHref}>
            {linkText}
          </a>
        </div>
      </div>
    )
  }

  renderCards = () => {
    const { isActive, hiddenRecommendations } = this.props
    if (!isActive) {
      return (
        <div>
          <OnAtMostPhablet>
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--4 md-cell--4-tablet md-cell--4-phone">
                <div className="hf-hidden-text">
                  Rendering hidden text to trick react-md
                </div>
              </div>
            </div>
          </OnAtMostPhablet>
          <OnAtLeastTablet>
            <div className="md-grid">
              <div className="md-cell md-cell--6 md-cell--4-tablet">
                <div className="hf-hidden-text">
                  Rendering hidden text to trick react-md
                </div>
              </div>
              <div className="md-cell md-cell--6 md-cell--4-tablet">
                <div className="hf-hidden-text">
                  Rendering hidden text to trick react-md
                </div>
              </div>
            </div>
          </OnAtLeastTablet>
        </div>
      )
    }

    const { guidebook, recommendations } = this.props
    const showAllRecs = this.checkSponsoredRecCookie(guidebook.key)

    const hiddenRecsDiv = showAllRecs ? null : this.renderHiddenRecsDiv()
    let hiddenMsg
    if (hiddenRecommendations && hiddenRecommendations.length > 0) {
      hiddenMsg = (
        <div
          className="md-text-center hf-vertically-centered"
          id="hf-hidden-markers"
        >
          <strong>
            {this.formatMessage(this.messages.mapMarkersNotVisible, {
              count: hiddenRecommendations.length
            })}
          </strong>
        </div>
      )
    }

    const recs = this.buildRecommendations(recommendations)
    const columns = this.columniseRecommendations(recs)
    return (
      <div>
        {hiddenMsg}
        <OnAtMostPhablet>
          <div className="md-grid">
            <div className="md-cell md-cell--4 md-cell--4-tablet md-cell--4-phone">
              {recs}
            </div>
          </div>
        </OnAtMostPhablet>
        <OnAtLeastTablet>
          <div className="md-grid">
            <div className="md-cell md-cell--6 md-cell--4-tablet">
              {columns[0]}
            </div>
            <div className="md-cell md-cell--6 md-cell--4-tablet">
              {columns[1]}
            </div>
          </div>
        </OnAtLeastTablet>
        {hiddenRecsDiv}
      </div>
    )
  }

  render() {
    const content = this.renderCards()
    return (
      <div
        className="md-cell md-cell--12"
        ref={(div) => {
          this.tabRef = div
        }}
      >
        {content}
      </div>
    )
  }
}

RecommendationsTab.propTypes = propTypes
RecommendationsTab.defaultProps = defaultProps

export default withCookies(connect(null)(injectIntl(RecommendationsTab)))
