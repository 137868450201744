import React from 'react'

const SettingsRemote = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`settings_remote`}</title>
    <path d="M12 0c3.047 0 5.813 1.219 7.781 3.234L18.375 4.64C16.734 2.999 14.484 2.015 12 2.015s-4.734.984-6.375 2.625L4.219 3.234A11.018 11.018 0 0112 0zM7.031 6.047C8.297 4.781 10.078 3.984 12 3.984s3.703.797 4.969 2.063l-1.406 1.406C14.672 6.562 13.36 6 12 6s-2.672.563-3.563 1.453zM12 15c1.078 0 2.016-.938 2.016-2.016s-.938-1.969-2.016-1.969-2.016.891-2.016 1.969S10.922 15 12 15zm3-6a.96.96 0 01.984.984v12c0 .563-.422 1.031-.984 1.031H9c-.563 0-.984-.469-.984-1.031v-12A.96.96 0 019 9h6z" />
  </svg>
)

export default SettingsRemote
