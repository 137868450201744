let urlBase = process.env.REACT_APP_BASE_URL
// placeholder - I suspect we'll need some custom logic here
const baseManifest = {
  name: 'Hostfully Guidebook',
  short_name: 'Hostfully',
  display: 'standalone',
  description: 'Beautiful Guidebooks',
  icons: [
    {
      src: urlBase + '/android-chrome-192x192.png',
      sizes: '192x192',
      type: 'image/png'
    },
    {
      src: urlBase + '/android-chrome-512x512.png',
      sizes: '512x512',
      type: 'image/png'
    },
    {
      src: urlBase + '/apple-touch-icon.png',
      sizes: '512x512',
      type: 'image/png'
    },
    {
      src: urlBase + '/babyquip.png',
      sizes: '400x105',
      type: 'image/png'
    }
  ],
  theme_color: '#ffffff',
  background_color: '#ffffff'
}

export const updateManifest = function (data) {
  // merge new data with base manifest
  const newManifest = Object.assign({}, baseManifest, data)
  // stringify
  const stringManifest = JSON.stringify(newManifest)
  // save string as blob
  const blob = new Blob([stringManifest], { type: 'application/json' })
  // create url for blob
  const manifestURL = URL.createObjectURL(blob)
  // replace this url as href of manifest link
  document
    .querySelector('#manifest-placeholder')
    .setAttribute('href', manifestURL)
}
