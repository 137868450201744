import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Card, CardText } from 'react-md'

class IconCard extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = (e) => {
    this.props.clickCallBack(e)
  }

  render() {
    const { titleString, icon } = this.props
    let showIcon = null
    if (icon) {
      const IconComponent = icon.icon
      showIcon = <IconComponent width={72} height={72} fill="#ffffff" />
    }
    const spanStyle = { backgroundColor: this.props.custom_color || '#ff5722' }
    return (
      <Card
        key={'IconCard' + titleString}
        raise={true}
        className="hf-clickable"
        onClick={(e) => this.handleClick(e)}
      >
        <CardText className="icon-template">
          <h2 className="md-display-1">{titleString}</h2>
          <span className="hf-lge-icon" style={spanStyle}>
            {showIcon}
          </span>
        </CardText>
      </Card>
    )
  }
}

IconCard.propTypes = {
  titleString: PropTypes.string,
  icon: PropTypes.object,
  clickCallBack: PropTypes.func,
  custom_color: PropTypes.string
}

IconCard.defaultProps = {
  custom_color: 'rgb(255, 87, 34)'
}

export default IconCard
