import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Avatar, FontIcon, ListItem } from 'react-md'
import CopyToClipboard from 'react-copy-to-clipboard'
import { addToast } from 'redux/modules/toast'
import TooltipSpan from 'components/TooltipSpan'
import { buildTokenUrl } from 'utils/Urls'
import { resizeImage } from 'utils/Image'
import { formatDate } from 'utils/Dates'

class TokenListItem extends Component {
  copyUrl = (e) => {
    this.props.dispatch(addToast('The URL has been copied to the clipboard.'))
  }

  render() {
    const self = this
    const { item, editItem, deleteItem, pluralName } = this.props

    const primaryText = (
      <div>
        <strong>{item.label}</strong> ({item.token})
      </div>
    )
    const link = (
      <Link
        to={'/host/' + pluralName + '/' + item.id}
        onClick={(e) => {
          e.stopPropagation()
        }}
        className="md-tile-text--primary md-text hf-fake-link"
      >
        {primaryText}
      </Link>
    )
    const usCodes = [
      'us',
      'usa',
      'united states',
      'unitedtates',
      'united states of america',
      'america'
    ]
    let useDMY = true
    if (
      item.guidebook.address &&
      item.guidebook.address.country_code &&
      usCodes.indexOf(item.guidebook.address.country_code.toLowerCase()) !== -1
    ) {
      useDMY = false
    }

    let secondary = item.token
    let secureUrl = null
    if (item.guidebook && item.guidebook.key) {
      const domain =
        item.guidebook.domain && item.guidebook.domain.status === 'approved'
          ? item.guidebook.domain.domain
          : null
      const gbUrl = buildTokenUrl(item.guidebook.key, item.token, null, domain)
      const initCreatedDate = new Date(item.inserted_at)
      const createdDate = new Date(
        initCreatedDate.getFullYear(),
        initCreatedDate.getMonth(),
        initCreatedDate.getDate()
      )
      secureUrl = (
        <span>
          Secure URL:&nbsp;&nbsp;
          <TooltipSpan
            tooltipLabel="Click to copy URL to clipboard"
            tooltipPosition="right"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <CopyToClipboard
              className="show-pointer hf-copy-link"
              text={gbUrl}
              onCopy={(e) => {
                self.copyUrl(e)
              }}
            >
              <span>{gbUrl}</span>
            </CopyToClipboard>
          </TooltipSpan>
          &nbsp;&nbsp; Created:&nbsp;
          <span className="hf-token-time">
            {formatDate(createdDate, useDMY)}
          </span>
        </span>
      )
    }
    let avatarColor = {
      border: '2px solid #9e9e9e',
      backgroundColor: '#9e9e9e'
    }
    const gbLabel = <strong>{item.guidebook.name}</strong>
    if (item.is_enabled) {
      const initStartDate = new Date(item.start_date)
      const startDate = new Date(
        initStartDate.getFullYear(),
        initStartDate.getMonth(),
        initStartDate.getDate()
      )
      const initEndDate = new Date(item.end_date)
      const endDate = new Date(
        initEndDate.getFullYear(),
        initEndDate.getMonth(),
        initEndDate.getDate()
      )
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

      const formattedStartDate = (
        <span className="hf-token-time">{formatDate(startDate, useDMY)}</span>
      )
      const formattedEndDate = (
        <span className="hf-token-time">{formatDate(endDate, useDMY)}</span>
      )

      if (today > endDate) {
        const expiredSpan = (
          <span className="hf-token-expired">
            Expired for guidebook {gbLabel} on {formattedEndDate}
          </span>
        )
        secondary = <div className={`primary-${item.id}`}>{expiredSpan}</div>
      } else if (today < startDate) {
        const notYetSpan = (
          <span className="hf-token-expired">
            Will be enabled for guidebook {gbLabel} from {formattedStartDate} to{' '}
            {formattedEndDate}
          </span>
        )
        secondary = (
          <div className={`primary-${item.id}`}>
            {secureUrl}
            <br />
            {notYetSpan}
          </div>
        )
      } else {
        const enabledSpan = (
          <span className="hf-token-enabled">
            Enabled for guidebook {gbLabel} from {formattedStartDate} to{' '}
            {formattedEndDate}
          </span>
        )
        avatarColor = {
          border: '2px solid #66BB6A',
          backgroundColor: '#66BB6A'
        }
        secondary = (
          <div className={`primary-${item.id}`}>
            {secureUrl}
            <br />
            {enabledSpan}
          </div>
        )
      }
    } else {
      const disabledSpan = (
        <span className="hf-token-disabled">
          Disabled for guidebook {gbLabel}
        </span>
      )
      avatarColor = { border: '2px solid #E57373', backgroundColor: '#E57373' }
      secondary = <div className={`primary-${item.id}`}>{disabledSpan}</div>
    }
    let avatar = (
      <Avatar
        role="presentation"
        className={'hf-' + pluralName + 'list-item'}
        style={avatarColor}
      >
        {item.label.charAt(0).toUpperCase()}
      </Avatar>
    )
    if (item.guidebook && item.guidebook.image) {
      const image = resizeImage(item.guidebook.image, 50, 50, true)
      avatar = <Avatar src={image} role="presentation" style={avatarColor} />
    }

    return (
      <ListItem
        onClick={editItem}
        className={'hf-list-item hf-' + pluralName + 'list-item'}
        leftAvatar={
          <Link
            to={'/host/' + pluralName + '/' + item.id}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            {avatar}
          </Link>
        }
        primaryText={link}
        secondaryText={secondary}
        threeLines
        rightIcon={<FontIcon onClick={deleteItem}>delete</FontIcon>}
      />
    )
  }
}

TokenListItem.propTypes = {
  item: PropTypes.object.isRequired
}

function mapStateToProps(state, props) {
  return {
    item: props.item
  }
}

export default connect(mapStateToProps)(TokenListItem)
