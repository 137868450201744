import React from 'react'

const Oven = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`oven`}</title>
    <path d="M19 2.8c0-.2-.1-.3-.3-.3h-4.3c-.2 0-.3.1-.3.3h-2.2c0-.2-.1-.3-.3-.3H5c-.2 0-.3.1-.3.3H3.5v18.3h17V2.8H19zm-.7 2.4c-.1.5-.5.8-1 .8-.6 0-1-.4-1-1 0-.5.4-1 1-1 .6.1 1 .6 1 1.2zm-5.3 0c-.1.4-.5.8-1 .8-.6 0-1-.4-1-1 0-.5.4-1 1-1 .6.1 1 .6 1 1.2zM6.7 4h.2C7.5 4 8 4.5 8 5.2s-.5 1.1-1.2 1.1c-.6 0-1.2-.5-1.2-1.2.1-.5.5-1 1.1-1.1zm11.6 13.5c0 .7-.5 1.2-1.2 1.3H6.8c-.7-.1-1.2-.6-1.2-1.3V9.8c0-.7.5-1.2 1.2-1.3h10.3c.7.1 1.2.6 1.2 1.3v7.7z" />
    <path d="M16.3 9.8H7.7c-.2 0-.3.1-.3.3s.1.3.3.3h8.7c.2 0 .3-.1.3-.3s-.2-.3-.4-.3z" />
  </svg>
)

export default Oven
