import React from 'react'

const BugReport = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`bug_report`}</title>
    <path d="M14.016 12V9.984H9.985V12h4.031zm0 3.984v-1.969H9.985v1.969h4.031zm6-7.968v1.969h-2.109c.047.328.094.703.094 1.031V12h2.016v2.016h-2.016V15c0 .328-.047.656-.094.984h2.109V18h-2.813c-1.031 1.781-3 3-5.203 3s-4.172-1.219-5.203-3H3.984v-2.016h2.109A6.97 6.97 0 015.999 15v-.984H3.983V12h2.016v-.984c0-.328.047-.703.094-1.031H3.984V8.016h2.813a6.133 6.133 0 011.828-1.969L6.984 4.406 8.39 3l2.203 2.156a7.141 7.141 0 012.812 0L15.608 3l1.406 1.406-1.641 1.641a6.133 6.133 0 011.828 1.969h2.813z" />
  </svg>
)

export default BugReport
