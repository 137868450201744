import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import rootReducer from './modules/reducer'
import { createTracker } from 'redux-segment'

const tracker = createTracker()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function create(initialState, history) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(thunk),
      applyMiddleware(routerMiddleware(history)),
      applyMiddleware(tracker)
    )
  )
}
