import React from 'react'

const Map = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <title>{`map`}</title>
    <path d="M15 18.984V7.125L9 5.016v11.859zM20.484 3a.52.52 0 01.516.516V18.61a.506.506 0 01-.375.469L15 21.001l-6-2.109-5.344 2.063-.141.047a.52.52 0 01-.516-.516V5.392c0-.234.188-.422.375-.469l5.625-1.922 6 2.109 5.344-2.063z" />
  </svg>
)

export default Map
