/*eslint no-unused-vars: ["off", { "ignoreRestSiblings": true }]*/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions, Control } from 'react-redux-form'
import TimePicker from 'rc-time-picker'
import Moment from 'moment'

class TimePickerForm extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(timeMoment) {
    let { editModel, model, dispatch } = this.props
    const time = timeMoment.format('h:mm A')
    dispatch(actions.change(editModel + '.' + model, time))
  }

  render() {
    const { id, label, value, defaultValue } = this.props
    const self = this
    const timePicker = (props) => {
      const { model, dispatch, ...rest } = this.props
      const dateValue = Moment(value, 'h:mm A')
      const def = Moment(defaultValue, 'h:mm A')
      const defaultDate = dateValue.isValid() ? dateValue : def
      const lastResort = Moment().hour(12).minute(0)
      const date = defaultDate.isValid() ? defaultDate : lastResort
      return (
        <TimePicker
          id={id}
          showSecond={false}
          format="h:mm A"
          use12Hours
          label={label}
          onChange={self.handleChange}
          value={date}
        />
      )
    }
    const mapProps = {
      value: (props) => props.value
    }
    return (
      <Control.custom
        mapProps={mapProps}
        model={this.props.model}
        component={timePicker}
      />
    )
  }
}

export default connect()(TimePickerForm)
