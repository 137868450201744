import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Avatar, FontIcon, ListItem } from 'react-md'
import currentUser from 'utils/CurrentUser'
import { formatDate } from 'utils/Dates'

class DomainListItem extends Component {
  render() {
    const { item, editItem, deleteItem, pluralName } = this.props
    // use first letter of name if no icon
    let avatarIcon = item.domain.charAt(0).toUpperCase()
    const avatarStyle = { backgroundColor: '#3f51b5' }
    const user = currentUser()
    const threeLines = user.isAdmin ? true : false
    let secondaryText = <span className="domain-status">{item.status}</span>
    const initUpdatedDate = new Date(item.updated_at)
    const updatedDate = new Date(
      initUpdatedDate.getFullYear(),
      initUpdatedDate.getMonth(),
      initUpdatedDate.getDate()
    )
    if (user.isAdmin) {
      secondaryText = (
        <div>
          <span className="domain-status">
            {item.status}&nbsp;&nbsp;(last update:{' '}
            {formatDate(updatedDate, false)})
          </span>
          <br />
          <span className="domain-requester">
            Requested by user: {item.user_id}
          </span>
        </div>
      )
    }

    const domainItem = (
      <ListItem
        onClick={editItem}
        className={'hf-list-item hf-' + pluralName + 'list-item'}
        leftAvatar={
          <Avatar
            className={'hf-' + pluralName + 'list-item'}
            style={avatarStyle}
            alt=""
          >
            {avatarIcon}
          </Avatar>
        }
        primaryText={item.domain}
        secondaryText={secondaryText}
        rightIcon={<FontIcon onClick={deleteItem}>delete</FontIcon>}
        threeLines={threeLines}
      />
    )

    return domainItem
  }
}

DomainListItem.propTypes = {
  item: PropTypes.object.isRequired
}
export default DomainListItem
