import PropTypes from 'prop-types'
import React from 'react'
import { Button, CircularProgress, Paper, TextField } from 'react-md'
import { connect } from 'react-redux'
import { Control, Errors, Form } from 'react-redux-form'
import { isEmail } from 'validator'

import Alert from 'components/Alert'
import { PublicView } from 'components/PublicView'
import { signupUser } from 'redux/modules/auth'

const validators = {
  firstName: {
    required: (val) => val && val.length
  },
  lastName: {
    required: (val) => val && val.length
  },
  email: {
    required: (val) => val && val.length,
    isEmail: (val) => {
      if (val.length > 0) {
        return isEmail(val)
      } else {
        return true
      }
    },
    isAvailable: (val) => {
      return true
    }
  },
  password: {
    required: (val) => val && val.length,
    length: (val) => val.length === 0 || val.length > 7
  }
}
const errorMessages = {
  firstName: {
    required: 'Please provide a last name'
  },
  lastName: {
    required: 'Please provide a last name'
  },
  email: {
    required: 'Please provide an email address.',
    isEmail: (val) => `${val} is not a valid email.`
  },
  password: {
    required: 'Please create a password.',
    length: 'Your password must be at least 8 characters.'
  },
  emailCombo: {
    isAvailable: (val) => `${val} has already been used to create an account.`
  }
}

const FirstNameComponent = (props) => (
  <TextField
    {...props}
    id="firstName"
    type="text"
    placeholder="John"
    className="hf-public-view-input"
  />
)

const LastNameComponent = (props) => (
  <TextField
    {...props}
    id="lastName"
    type="text"
    placeholder="Doe"
    className="hf-public-view-input"
  />
)

const CompanyComponent = (props) => (
  <TextField
    {...props}
    id="company"
    type="text"
    placeholder="Acme Inc."
    className="hf-public-view-input"
  />
)

const EmailComponent = (props) => (
  <TextField
    {...props}
    id="em"
    type="email"
    placeholder="name@mail.com"
    className="hf-public-view-input"
  />
)

const PasswordComponent = (props) => (
  <TextField
    {...props}
    id="pwd"
    type="password"
    placeholder="Create a password"
    className="hf-public-view-input"
  />
)

const ErrorAlert = (props) => {
  return (
    <Alert
      {...props}
      type="error"
      message="Email already in use"
      description={props.children}
    />
  )
}

function Signup({ dispatch, auth }) {
  const handleSubmit = (signup) => {
    dispatch(signupUser(signup))
  }

  return (
    <PublicView containerClass="hf-public-view" showNavDrawer={false}>
      <div className="hf-public-view-welcome">
        <p className="hf-public-view-message">
          Give your guests 5-star{' '}
          <span role="img" aria-label="stars">
            ⭐⭐⭐⭐⭐
          </span>{' '}
          hospitality with a Hostfully Guidebook.
        </p>
      </div>
      <Paper className="hf-public-view-card hf-signup-card">
        <div>
          <h4 className="hf-public-view-title">
            Get started with a free account
          </h4>
          <p className="hf-public-view-subtitle">
            Create a free Hostfully account to share beautiful guidebooks with
            your guests. Already have a Hostfully account?{' '}
            <a href="/login" className="hf-public-view-link">
              Login here
            </a>
          </p>
        </div>
        <Form
          model="signup"
          onSubmit={handleSubmit}
          validators={validators}
          className="hf-public-view-form"
        >
          <div className="hf-public-view-form-container">
            <div className="hf-public-view-input-group">
              <div className="hf-public-view-input-container">
                <label className="md-text">First name*</label>
                <Control.text
                  model=".firstName"
                  component={FirstNameComponent}
                />
                <Errors
                  model=".firstName"
                  messages={errorMessages.firstName}
                  className="hf-public-view-input-error"
                  show={(field) => field.touched && !field.focus}
                />
              </div>
              <div className="hf-public-view-input-container">
                <label className="md-text">Last name*</label>
                <Control.text model=".lastName" component={LastNameComponent} />
                <Errors
                  model=".lastName"
                  messages={errorMessages.lastName}
                  className="hf-public-view-input-error"
                  show={(field) => field.touched && !field.focus}
                />
              </div>
            </div>
            <div className="hf-public-view-input-container">
              <label className="md-text">Company</label>
              <Control.text model=".company" component={CompanyComponent} />
            </div>
            <div className="hf-public-view-input-container">
              <label className="md-text">Email*</label>
              <Control.text model=".email" component={EmailComponent} />
              <Errors
                model=".email"
                messages={errorMessages.email}
                className="hf-public-view-input-error"
                show={(field) => field.touched && !field.focus}
              />
            </div>
            <div className="hf-public-view-input-container">
              <label className="md-text">Password*</label>
              <Control.text model=".password" component={PasswordComponent} />
              <Errors
                model=".password"
                messages={errorMessages.password}
                className="hf-public-view-input-error"
                show={(field) => field.touched && !field.focus}
              />
            </div>
          </div>
          <p className="hf-public-view-sup-link">
            By clicking this button, you agree to Hostfully's{' '}
            <a
              target="blank"
              href="https://hostfully.com/terms-of-service"
              className="hf-public-view-link hf-public-view-link--small"
            >
              Terms of Service
            </a>{' '}
            &{' '}
            <a
              target="blank"
              href="https://hostfully.com/privacy-policy"
              className="hf-public-view-link hf-public-view-link--small"
            >
              Privacy Policy
            </a>
            .
          </p>
          <Errors
            model=".email"
            messages={errorMessages.emailCombo}
            component={ErrorAlert}
          />
          <div className="hf-public-view-actions">
            <Button
              type="submit"
              className="hf-public-view-button"
              swapTheming
              primary
              flat
            >
              Get started
            </Button>
            {auth.isFetching && (
              <CircularProgress key="progress" id="loader" scale={1.25} />
            )}
          </div>
        </Form>
      </Paper>
    </PublicView>
  )
}

Signup.propTypes = {
  dispatch: PropTypes.func,
  booking_com_id: PropTypes.string
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(Signup)
